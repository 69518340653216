import { useContext, useState } from "react";
import * as XLSX from "xlsx"; // 엑셀 파일을 읽기 위한 라이브러리
import { AxiosResponse, HttpStatusCode } from "axios";

import { downloadExcel } from "../../../utils/fileUtils";

import { PopupContext } from "../../../contexts/Popup";
import { useUserContext } from "../../../contexts/User";
import { useModal } from "../../../contexts/Modal";

import DefaultButton from "../../DefaultButton";
import FormHeader from "../../Form/Header";
import FormRow from "../../Form/Row";
import FormRowLabel from "../../Form/RowLabel";
import SingleFileUploader from "../SingleFileUploader";
import DefaultLabel from "../../Input/DefaultLabel";
import Spinner from "../../Spinner";

type Props = {
  title: string;
  getExcelDataApi: () => Promise<AxiosResponse<Blob>>;
  postExcelDataApi?: (formData: FormData) => Promise<AxiosResponse<void>>;
  onJsonResult?: (data: any) => Promise<AxiosResponse<void>>; // JSON 데이터를 받도록 타입 변경
  onClose?: () => void;
};

export default function ExcelFileUploaderForm({
  title,
  getExcelDataApi,
  postExcelDataApi,
  onJsonResult,
  onClose,
}: Props) {
  const { showAlert, showConfirm, handleError } = useModal();

  const [file, setFile] = useState<File>();
  const [isLoading, setIsLoading] = useState(false);

  const handleFileChange = (value: File) => {
    setFile(value);
  };

  const { userInfo } = useUserContext();
  const { closePopup: closePopupFunction, refreshAndClosePopup } =
    useContext(PopupContext); // 변수 이름 수정

  const handleClose = () => {
    if (onClose) {
      onClose();
    } else {
      closePopupFunction();
    }
  };

  const handleDownload = () => {
    setIsLoading(true);
    getExcelDataApi()
      .then((data) => {
        downloadExcel(data, `${title.replaceAll(" ", "_")}_양식.xlsx`);
      })
      .catch((err: any) => {
        handleError(err, "엑셀 양식 다운로드");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleUpload = () => {
    // 둘 중에 하나만 호출되어야 함
    if (postExcelDataApi && onJsonResult) {
      showAlert("업로드 함수가 중복되어 있습니다.");
      return;
    }

    if (postExcelDataApi) {
      handleExcelUpload();
    } else if (onJsonResult) {
      handleExcelUploadJson();
    }
  };

  const handleExcelUpload = () => {
    if (!file) {
      showAlert("선택된 파일이 없습니다.");
      return;
    }
    if (postExcelDataApi === undefined) {
      showAlert("업로드 함수가 없습니다.");
      return;
    }

    showConfirm("업로드 하시겠습니까?", async () => {
      const formData = new FormData();
      formData.append("uploadFile", file);

      setIsLoading(true);
      postExcelDataApi(formData)
        .then(() => {
          showAlert(`${title} 일괄 업로드에 성공했습니다.`);
          if (onClose) {
            onClose();
          } else {
            refreshAndClosePopup();
          }
        })
        .catch((err: any) => {
          handleError(err, `${title} 일괄 업로드`);
        })
        .finally(() => {
          setIsLoading(false);
        });
    });
  };

  const handleExcelUploadJson = () => {
    if (!file) {
      showAlert("선택된 파일이 없습니다.");
      return;
    }
    if (onJsonResult === undefined) {
      showAlert("업로드 함수가 없습니다.");
      return;
    }

    // 파일을 JSON으로 변환하는 로직
    const reader = new FileReader();
    reader.onload = (e) => {
      if (e.target) {
        const data = new Uint8Array(e.target.result as ArrayBuffer);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const json = XLSX.utils.sheet_to_json(sheet); // 엑셀 데이터를 JSON으로 변환

        showConfirm("업로드 하시겠습니까?", async () => {
          setIsLoading(true);
          try {
            // JSON 데이터를 API로 전송
            console.log(json);
            const result = await onJsonResult(json);
            if (result.status === HttpStatusCode.Ok) {
              showAlert(`${title} 일괄 업로드에 성공했습니다.`);
              handleClose();
            } else {
              throw new Error(`${title} 일괄 업로드에 실패했습니다.`);
            }
          } catch (err: any) {
            handleError(err, `${title} 일괄 업로드`);
          } finally {
            setIsLoading(false);
          }
        });
      }
    };
    reader.readAsArrayBuffer(file);
  };

  return (
    <div className="flex flex-col min-w-[980px] min-h-[300px]">
      {isLoading && <Spinner />}
      <FormHeader
        title={title}
        right={
          <DefaultButton
            size="tableTop"
            color="default"
            onClick={handleDownload}
          >
            양식 다운로드
          </DefaultButton>
        }
      />
      <div className="flex">
        <div className="flex flex-col w-full">
          <FormRow>
            <FormRowLabel title="파일명">
              <SingleFileUploader
                onChange={handleFileChange}
                accept=".xlsx, .xls"
              />
            </FormRowLabel>
          </FormRow>

          <FormRow>
            <FormRowLabel title="등록자">
              <DefaultLabel text={`${userInfo?.name}`} />
            </FormRowLabel>
          </FormRow>
        </div>

        <div>
          <FormRow>
            <DefaultButton
              size="square"
              className="font-semibold !text-white !bg-[#333F48]"
              onClick={handleUpload}
            >
              업로드
            </DefaultButton>
          </FormRow>
        </div>
      </div>
      <div className="px-[30px] py-[10px] text-xs font-medium text-[#BCBCBC]">
        ※ 엑셀 양식은 [양식 다운로드]를 통해서 다운받으실 수 있으며 정해진
        양식에 맞게 정확하게 작성하셔야 합니다.
        <br />※ 엑셀은 최대 10,000건만 다운로드 됩니다.
      </div>
      <div className="flex justify-center pt-5 pb-[20px]">
        <DefaultButton className="text-sm font-medium" onClick={handleClose}>
          닫기
        </DefaultButton>
      </div>
    </div>
  );
}
