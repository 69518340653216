import { HttpStatusCode } from "axios";
import { useContext, useEffect, useState } from "react";
import { useModal } from "../../../../../contexts/Modal";
import { PopupContext } from "../../../../../contexts/Popup";
import {
  validationFunctions,
  validationResultMessage,
} from "../../../../../libs/validations";
import { getBuildingFloor } from "../../../../../services/buildingService";
import { getSpace, putSpace } from "../../../../../services/spaceService";
import { ValueType } from "../../../../../types/common";
import { FileScale } from "../../../../../types/file";
import { ComboBoxType } from "../../../../../types/search";
import {
  RESERVATION_SPACE_OPTIONS,
  RESERVATION_SPACE_TYPES,
  RentalDevice,
  SPACE_GENDER_OPTIONS,
  Space,
  SpaceConnection,
  WeekDay,
} from "../../../../../types/space";
import { toComboBoxType } from "../../../../../utils/comboBoxUtils";
import {
  CONNECT_OPTIONS,
  PAYMENT_OPTIONS,
  PROVIDE_OPTIONS,
  USAGE_OPTIONS,
  BOOLEAN_TYPES,
  SPACE_CONCURRENT_OPTIONS,
  make_use_auto_confirm_options,
} from "../../../../../types/comboBoxOption";
import { getImageByScale } from "../../../../../utils/fileUtils";
import BuildingCompanyTable from "../../../../CustomTables/BuildingCompanyTable";
import DefaultButton from "../../../../DefaultButton";
import DefaultTextArea from "../../../../DefaultTextArea";
import SingleImageUploader from "../../../../FileUploader/ImageFileUploader/SingleImageFileUploader";
import FormHeader from "../../../../Form/Header";
import FormRow from "../../../../Form/Row";
import FormRowLabel from "../../../../Form/RowLabel";
import DefaultInput from "../../../../Input/DefaultInput";
import DefaultLabel from "../../../../Input/DefaultLabel";
import RadioButtonGroup from "../../../../Input/RadioButtonGroup";
import RangeInput from "../../../../Input/RangeInput";
import DefaultSelect from "../../../../SelectBox/DefaultSelect";
import SpaceMultiSelectTable from "../../../../SelectTable/MultiSelectTable/SpaceMultiSelectTable";
import Spinner from "../../../../Spinner";
import SpaceDeviceList, { initRentalDevice } from "../DeviceList";
import SelectTimeDays, {
  dayType,
  getWeekDaysSelectedOption,
  initWeekValue,
} from "../SelectTimeDays";
import { convertTimeToDate, formatDate } from "../../../../../utils/dateUtils";
import { getEtcCategoryTermsId } from "../../../../../services/etcService";
import PermissionWrapper from "../../../../PermissionWrapper";
import Checkbox from "@/components/Input/Checkbox";
import DateTimePickerRange from "@/components/DateTime/DateTimePickerRange";

type Props = { reservationSpaceId: string };

export const SPACE_ETC_CATEGORY_ID = 9;

const SPACE_NAME_MAX_LENGTH = 20;
const SPACE_FEATURE_MAX_LENGTH = 100;
const SPACE_NODE_MAX_LENGTH = 500;

export default function SpaceDetailView({ reservationSpaceId }: Props) {
  const { closePopup, refreshAndClosePopup } = useContext(PopupContext);
  const { showAlert, showConfirm, handleError } = useModal();
  const [isLoading, setIsLoading] = useState(false);

  const [space, setSpace] = useState<Partial<Space>>({});
  const [buildingFloor, setBuildingFloor] = useState<ComboBoxType[]>([]);
  const [connectionData, setConnectionData] = useState<SpaceConnection[]>([]);
  const [imageFile, setImageFile] = useState<File>();
  const [etcCategory, setEtcCategory] = useState<ComboBoxType[]>([]);

  const [selectedOperate, setSelectedOperate] = useState(dayType.weekday);
  const [selectedPay, setSelectedPay] = useState(dayType.weekday);
  const [selectedConnect, setSelectedConnect] = useState(false);

  useEffect(() => {
    const fetchData = async (reservationSpaceId: string) => {
      try {
        setIsLoading(true);

        const [resSpace, resCategory] = await Promise.all([
          getSpace(reservationSpaceId),
          getEtcCategoryTermsId(SPACE_ETC_CATEGORY_ID),
        ]);
        if (
          resSpace.status === HttpStatusCode.Ok &&
          resCategory.status === HttpStatusCode.Ok
        ) {
          const {
            buildingId,
            companyList,
            rentalDeviceList,
            connectionInfo,
            availableDays,
            defaultPriceDays,
          } = resSpace.data;

          setSpace({
            ...resSpace.data,
            companyIds: companyList.map((c) => c.companyId),
            isRentalDevice: rentalDeviceList.length > 0,
            rentalDeviceModifyRequestList: [...rentalDeviceList],
          });
          setEtcCategory(toComboBoxType(resCategory.data, "termsId", "name"));

          setSelectedOperate(getWeekDaysSelectedOption(availableDays));
          setSelectedPay(getWeekDaysSelectedOption(defaultPriceDays));

          if (connectionInfo?.childReservationSpaceIdList?.length > 0) {
            setSelectedConnect(true);
          }

          fetchBuildingFloor(buildingId);
        }
      } catch (err: any) {
        handleError(err, "조회");
      } finally {
        setIsLoading(false);
      }
    };

    fetchData(reservationSpaceId);
  }, [reservationSpaceId]);

  const handleSpaceChange =
    (key: keyof Space, valueType: ValueType = "any") =>
    (
      value:
        | string
        | WeekDay
        | number
        | boolean
        | undefined
        | string[]
        | RentalDevice[]
    ) => {
      if (valueType === "number") {
        value = value === "" ? undefined : Number(value);
      } else if (valueType === "boolean") {
        value = value === BOOLEAN_TYPES.TRUE;
      } else {
        // nothing to do
      }
      setSpace((prev) => ({ ...prev, [key]: value }));
    };

  const handleSpaceChangeObject = (data: Partial<Space>) => {
    setSpace((prev) => ({ ...prev, ...data }));
  };

  const updateData = async () => {
    const message = "수정을 실패했습니다.";

    try {
      const response = await putSpace(reservationSpaceId, createFormData());
      if (response.status === HttpStatusCode.Ok) {
        showAlert("수정 되었습니다.");
        refreshAndClosePopup();
      } else {
        throw new Error(message);
      }
    } catch (err: any) {
      handleError(err, "수정");
    }
  };

  const createFormData = () => {
    const formData = new FormData();

    const data: Partial<Space> = {
      useAutoConfirm: space.useAutoConfirm,
      reservationSpaceType: space.reservationSpaceType,
      reservationSpaceName: space.reservationSpaceName,
      useBlockTime: space.useBlockTime,
      blockTimeStart: space.blockTimeStart ?? undefined,
      blockTimeEnd: space.blockTimeEnd ?? undefined,
      buildingId: space.buildingId,
      buildingFloorId: space.buildingFloorId,
      availableGender: space.availableGender,
      companyIds: !space.isConnectedAllCompany ? space.companyIds : [],
      availableDays: space.availableDays,
      availableTimeStart: space.availableTimeStart,
      availableTimeEnd: space.availableTimeEnd,
      availablePeopleCountStart: space.availablePeopleCountStart,
      availablePeopleCountEnd: space.availablePeopleCountEnd,
      usableTimeStart: space.usableTimeStart,
      usableTimeEnd: space.usePayment
        ? space.usableTimeStart
        : space.usableTimeEnd,
      dailyLimit: space.dailyLimit,
      contactInfo: space.contactInfo,
      contactNumber: space.contactNumber,
      reservationSpaceFeatures: space.reservationSpaceFeatures,
      reservationSpaceNote: space.reservationSpaceNote,
      usageTermId: space.usageTermId,
      usePayment: space.usePayment,
      defaultPrice: space.defaultPrice ?? 0,
      additionalPrice: space.additionalPrice ?? 0,
      defaultPriceDays: space.defaultPriceDays,
      defaultPriceTimeStart: space.defaultPriceTimeStart,
      defaultPriceTimeEnd: space.defaultPriceTimeEnd,
      rentalDeviceModifyRequestList: space.rentalDeviceModifyRequestList,
      childReservationSpaceIdList: selectedConnect
        ? connectionData.map((item) => item.reservationSpaceId)
        : [],
      isActive: space.isActive,
      isConnectedAllCompany: space.isConnectedAllCompany,
      isConcurrentLimit: space.isConcurrentLimit,
      concurrentLimit: space.concurrentLimit,
    };

    imageFile && formData.append("img", imageFile);
    formData.append("reservationSpaceModifyRequestDto", JSON.stringify(data));

    return formData;
  };

  const fetchBuildingFloor = async (buildingId: string) => {
    if (!buildingId) {
      return;
    }

    try {
      setIsLoading(true);

      const response = await getBuildingFloor(buildingId);
      if (response.status === HttpStatusCode.Ok) {
        const arr = response.data.map((item) => ({
          value: item.buildingFloorId,
          label: item.floorName,
        }));

        setBuildingFloor(arr);
      }
    } catch (err: any) {
      handleError(err, "층 정보 조회");
    } finally {
      setIsLoading(false);
    }
  };

  const handlePost = () => {
    const validations = [
      validationFunctions.required(space.buildingId, "건물"),
      validationFunctions.required(space.buildingFloorId, "층"),
      ...(!space.isConnectedAllCompany && (space.companyIds?.length ?? 0) === 0
        ? [validationFunctions.required(space.companyIds, "회사 연결")]
        : []),

      ...(selectedConnect && connectionData.length === 0
        ? [validationFunctions.required(connectionData, "회의실 연결")]
        : []),

      validationFunctions.required(space.reservationSpaceName, "공간명"),
      ...(selectedOperate === dayType.select
        ? Object.values(space.availableDays ?? {}).every(
            (value) => value === false
          )
          ? [validationFunctions.required(null, "운영 반복 요일")]
          : []
        : []),
      ...(space.useBlockTime
        ? [
            validationFunctions.required(
              space.blockTimeStart,
              "이용 제한 시간대"
            ),
            validationFunctions.required(
              space.blockTimeEnd,
              "이용 제한 시간대"
            ),
          ]
        : []),
      ...(space.useBlockTime && space.blockTimeStart === space.blockTimeEnd
        ? [
            {
              isValid: false,
              errorMessage:
                "이용 제한 시간의 시작 시간과 끝 시간이 같을 수 없습니다.",
            },
          ]
        : []),
      validationFunctions.required(
        space.availablePeopleCountStart,
        "이용자 제한"
      ),
      validationFunctions.required(
        space.availablePeopleCountEnd,
        "이용자 제한"
      ),
      validationFunctions.required(space.usableTimeStart, "최소 이용 시간"),
      ...(!space.usePayment
        ? [
            validationFunctions.required(space.usableTimeEnd, "최대 이용 시간"),
            validationFunctions.required(
              space.dailyLimit,
              "일일 예약 제한 횟수"
            ),
          ]
        : []),
      validationFunctions.required(
        space.contactInfo,
        "담당자 연락처 안내 문구"
      ),
      validationFunctions.required(space.contactNumber, "담당자 연락처"),
      validationFunctions.required(
        space.reservationSpaceFeatures,
        "공간시설 및 규모"
      ),
      ...(space.usePayment
        ? [
            ...(selectedPay === dayType.select &&
            Object.values(space.defaultPriceDays ?? {}).every(
              (value) => value === false
            )
              ? [validationFunctions.required(null, "정가 반복 요일")]
              : []),
          ]
        : []),
      ...(space.isRentalDevice
        ? space.rentalDeviceModifyRequestList &&
          space.rentalDeviceModifyRequestList.length > 0
          ? space.rentalDeviceModifyRequestList.some(
              (item) =>
                item.deviceName === "" ||
                (space.usePayment === true && item.price === undefined)
            )
            ? [validationFunctions.required(null, "기기 서비스")]
            : []
          : []
        : []),
      ...(space.isConcurrentLimit && !((space.concurrentLimit ?? -1) > 0)
        ? [
            validationFunctions.required(
              space.concurrentLimit,
              "이용자 다중예약 제한"
            ),
          ]
        : []),
    ];

    const { usePayment, defaultPrice, additionalPrice } = space;
    if (usePayment) {
      validations.push({
        isValid: (defaultPrice ?? -1) >= 0,
        errorMessage: "최소 이용시간 요금 입력값이 없거나 0원 이상이 아닙니다.",
      });

      validations.push({
        isValid: (additionalPrice ?? -1) >= 0,
        errorMessage:
          "최소 이용시간 이후 요금 입력값이 없거나 0원 이상이 아닙니다.",
      });
    }

    const errorMessage = validationResultMessage(validations);
    if (errorMessage) {
      showAlert(errorMessage);
    } else {
      const usableTimeStartChecked = checkUsableTime(space.usableTimeStart);
      const usableTimeEndChecked = checkUsableTime(space.usableTimeEnd);

      if (
        !usableTimeStartChecked ||
        (!space.usePayment && !usableTimeEndChecked)
      ) {
        showAlert("이용시간은 30분 단위로만 입력 가능합니다.");
      } else if (
        !space.usePayment &&
        !compare(space.usableTimeStart, space.usableTimeEnd)
      ) {
        showAlert("최대이용시간보다 기본이용시간이 더 클 수 없습니다.");
      } else {
        showConfirm("수정 하시겠습니까?", updateData);
      }
    }
  };

  const compare = (value1: number = -1, value2: number = -1) => {
    if (value1 < 0 || value2 < 0) {
      return false;
    }
    return value1 <= value2;
  };

  const checkUsableTime = (value: number = -1) => {
    if (value <= 0) {
      return false;
    }

    return value % 30 === 0;
  };

  const handleDeviceChange = (data: RentalDevice[]) => {
    handleSpaceChangeObject({ rentalDeviceModifyRequestList: data });
  };

  const handleDataChange = (items: SpaceConnection[]) => {
    setConnectionData(items);
  };

  const handlePaymentChange = (value: string) => {
    const data: Partial<Space> = {
      usePayment: value === "true",
      usableTimeEnd: undefined,
      dailyLimit: undefined,
      ...(value === "false" && {
        defaultPriceDays: { ...initWeekValue },
        defaultPrice: undefined,
        additionalPrice: undefined,
      }),
    };
    if (space.reservationSpaceType === RESERVATION_SPACE_TYPES.DEFAULT) {
      handleSpaceChangeObject({ useAutoConfirm: !(value === "true") });
    }
    handleSpaceChangeObject(data);
  };

  const handleDeviceRadioChange = (value: string) => {
    const isRentalDevice = value === "true";
    let data: Partial<Space> = { isRentalDevice };

    if (isRentalDevice) {
      data.rentalDeviceModifyRequestList = space.rentalDeviceList?.length
        ? space.rentalDeviceList
        : [{ ...initRentalDevice }];
    } else {
      const rentalDeviceIdExistList =
        space.rentalDeviceModifyRequestList?.filter(
          (item) => item.rentalDeviceId
        );

      data.rentalDeviceModifyRequestList = rentalDeviceIdExistList?.map(
        (item) => ({
          ...item,
          isActive: false,
        })
      );
    }

    handleSpaceChangeObject(data);
  };

  return (
    <div className="flex flex-col gap-5">
      {isLoading && <Spinner />}
      <div className="min-w-[900px] max-h-[700px] overflow-y-auto">
        <FormHeader title="기준 정보" />
        <FormRow>
          <FormRowLabel title="위치" isRequired>
            <div className="flex flex-col gap-1.5">
              <DefaultLabel text={space.buildingName} />
              <DefaultSelect
                value={space.buildingFloorId ?? ""}
                optionList={buildingFloor}
                onChange={handleSpaceChange("buildingFloorId")}
                placeholder="층 선택"
              />
            </div>
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="회사 연결" isRequired>
            <BuildingCompanyTable
              buildingId={space.buildingId}
              companyIds={space.companyIds ?? []}
              isConnectedAllCompany={space.isConnectedAllCompany}
              onCompanyIdsChange={(ids: string[], selectedValue: string) => {
                handleSpaceChangeObject({
                  companyIds: ids,
                  isConnectedAllCompany: selectedValue === BOOLEAN_TYPES.TRUE,
                });
              }}
            />
          </FormRowLabel>
        </FormRow>
        {space.reservationSpaceType === RESERVATION_SPACE_TYPES.DEFAULT && (
          <FormRow>
            <FormRowLabel title="회의실 연결">
              {space.connectionInfo?.isConnection &&
              space.connectionInfo?.level === "CHILD" ? (
                <DefaultLabel text="이 회의실은 이미 다른 회의실에 연결되어 있습니다." />
              ) : (
                <div className="flex flex-col gap-1.5">
                  <RadioButtonGroup
                    value={selectedConnect.toString()}
                    onChange={(value: string) => {
                      setSelectedConnect(value === BOOLEAN_TYPES.TRUE);
                    }}
                    options={CONNECT_OPTIONS}
                  />
                  {selectedConnect && (
                    <SpaceMultiSelectTable
                      reservationSpaceId={space.reservationSpaceId ?? ""}
                      selectedData={connectionData}
                      setSelectedData={handleDataChange}
                      setData={setConnectionData}
                      defaultIds={
                        space.connectionInfo?.childReservationSpaceIdList ?? []
                      }
                    />
                  )}
                </div>
              )}
            </FormRowLabel>
          </FormRow>
        )}
        <FormRow>
          <FormRowLabel title="이용자 다중예약 제한 적용">
            <div className="flex gap-4">
              <RadioButtonGroup
                value={space.isConcurrentLimit?.toString()}
                onChange={(value: string) => {
                  const isInclude = value === BOOLEAN_TYPES.TRUE;
                  handleSpaceChangeObject({
                    isConcurrentLimit: isInclude,
                    ...(!isInclude && { concurrentLimit: 0 }),
                  });
                }}
                options={SPACE_CONCURRENT_OPTIONS}
              />
              {space.isConcurrentLimit && (
                <DefaultInput
                  type="number"
                  value={space.concurrentLimit?.toString()}
                  onChange={(value: string) => {
                    handleSpaceChangeObject({ concurrentLimit: Number(value) });
                  }}
                  minWidth="w-[100px]"
                  min={0}
                  maxLength={3}
                  hideMaxLength
                  unitLabel="회"
                />
              )}
            </div>
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="사용여부">
            <RadioButtonGroup
              value={space.isActive?.toString()}
              options={USAGE_OPTIONS}
              onChange={(value: string) =>
                handleSpaceChangeObject({
                  isActive: value === BOOLEAN_TYPES.TRUE,
                })
              }
            />
          </FormRowLabel>
        </FormRow>

        <FormHeader title="공간 정보" />
        <FormRow>
          <FormRowLabel title="공간명" isRequired>
            <div className="flex flex-col gap-1">
              <DefaultInput
                value={space.reservationSpaceName}
                onChange={handleSpaceChange("reservationSpaceName")}
                placeholder="공간명 입력"
                maxLength={SPACE_NAME_MAX_LENGTH}
              />
              <DefaultLabel
                text="* 가급적 다른 층의 공간과 구분할 수 있도록 입력하세요"
                color="summary"
              />
            </div>
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="공간 분류" isRequired>
            <DefaultSelect
              value={space.reservationSpaceType}
              optionList={RESERVATION_SPACE_OPTIONS}
              disabled={true}
              dataTestId={"space-type"}
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="공간 운영 정보" isRequired>
            <div className="flex flex-col gap-1.5">
              {(space.availableTimeStart?.length ?? 0) > 0 &&
                (space.availableTimeEnd?.length ?? 0) > 0 && (
                  <SelectTimeDays
                    label="운영시간"
                    selectedItem={selectedOperate}
                    weekDay={space.availableDays ?? initWeekValue}
                    startTime={space.availableTimeStart ?? ""}
                    endTime={space.availableTimeEnd ?? ""}
                    minuteStep={30}
                    onSelectedChange={setSelectedOperate}
                    onWeekDayChange={handleSpaceChange("availableDays")}
                    onStartTimeChange={handleSpaceChange("availableTimeStart")}
                    onEndTimeChange={handleSpaceChange("availableTimeEnd")}
                  />
                )}
              <div className="flex flex-row items-center justify-start gap-2">
                <Checkbox
                  checked={space.useBlockTime}
                  className="h-[30px] w-[200px] flex items-center justify-start"
                  onClick={() => {
                    if (space.useBlockTime) {
                      // 체크 후 체크 해제 케이스
                      handleSpaceChangeObject({
                        blockTimeStart: undefined,
                        blockTimeEnd: undefined,
                      });
                    }
                    handleSpaceChangeObject({
                      useBlockTime: !space.useBlockTime,
                    });
                  }}
                  label={"이용 제한 시간대 설정"}
                  testId={"useBlockTime"}
                />
                {space.useBlockTime && (
                  <DateTimePickerRange
                    startPeriod={
                      space.blockTimeStart
                        ? convertTimeToDate(space.blockTimeStart)
                        : convertTimeToDate("08:00")
                    }
                    endPeriod={
                      space.blockTimeEnd
                        ? convertTimeToDate(space.blockTimeEnd)
                        : convertTimeToDate("17:00")
                    }
                    onlyTime
                    minuteStep={30}
                    startDate={undefined}
                    endDate={undefined}
                    onStartPeriodChange={(value: Date) => {
                      handleSpaceChangeObject({
                        blockTimeStart: formatDate(value, "HH:mm"),
                      });
                    }}
                    onEndPeriodChange={(value: Date) => {
                      handleSpaceChangeObject({
                        blockTimeEnd: formatDate(value, "HH:mm"),
                      });
                    }}
                    testId={"useBlockTime"}
                  />
                )}
              </div>
              <DefaultSelect
                value={space.availableGender}
                optionList={SPACE_GENDER_OPTIONS}
                onChange={handleSpaceChange("availableGender")}
                label="이용가능 성별"
              />
              <RangeInput
                type="number"
                placeholder="0"
                leftValue={space.availablePeopleCountStart?.toString()}
                rightValue={space.availablePeopleCountEnd?.toString()}
                onLeftValueChange={handleSpaceChange(
                  "availablePeopleCountStart",
                  "number"
                )}
                onRightValueChange={handleSpaceChange(
                  "availablePeopleCountEnd",
                  "number"
                )}
                leftLabel="이용자 제한"
                unitLabel="명"
              />
              <div className="flex gap-2 items-center">
                <RangeInput
                  type="number"
                  placeholder="0"
                  leftValue={space.usableTimeStart?.toString()}
                  rightValue={space.usableTimeEnd?.toString()}
                  onLeftValueChange={handleSpaceChange(
                    "usableTimeStart",
                    "number"
                  )}
                  onRightValueChange={handleSpaceChange(
                    "usableTimeEnd",
                    "number"
                  )}
                  leftLabel="최소 이용시간"
                  rightLabel="최대 이용시간"
                  unitLabel="분"
                  rightDisabled={space.usePayment}
                />
                <DefaultInput
                  type="number"
                  placeholder="0"
                  value={space.dailyLimit?.toString()}
                  onChange={handleSpaceChange("dailyLimit", "number")}
                  label="일일 예약 제한 횟수"
                  unitLabel="회"
                  disabled={space.usePayment}
                />
              </div>
              <DefaultLabel
                text="* 이용시간은 30분 단위로 입력 가능합니다."
                color="summary"
              />
            </div>
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="공간 상세 정보">
            <div className="flex flex-col gap-1.5 w-full">
              <div className="flex gap-2 items-end">
                <DefaultInput
                  value={space.contactInfo}
                  onChange={handleSpaceChange("contactInfo")}
                  label="담당자 정보"
                  placeholder="연락처 안내 문구"
                  required
                />
                <DefaultInput
                  type="tel"
                  value={space.contactNumber}
                  onChange={handleSpaceChange("contactNumber")}
                  placeholder="연락처를 입력하세요"
                  maxLength={20}
                  hideMaxLength
                />
              </div>
              <DefaultInput
                value={space.reservationSpaceFeatures}
                onChange={handleSpaceChange("reservationSpaceFeatures")}
                label="공간시설 및 규모"
                placeholder="시설 및 정보를 입력하세요 ex) 프로젝트, 대쉬보드 / [VIP] 20석"
                minWidth="w-full"
                maxLength={SPACE_FEATURE_MAX_LENGTH}
                required
              />
              <DefaultTextArea
                value={space.reservationSpaceNote ?? ""}
                label="공간특징"
                cols={65}
                rows={6}
                placeholder="공간특징을 입력하세요 ex) 당일,휴일대관 불가/장기예약 가능/취식금지"
                onChange={handleSpaceChange("reservationSpaceNote")}
                maxLength={SPACE_NODE_MAX_LENGTH}
              />
              <SingleImageUploader
                label="공간 대표 사진"
                image={imageFile}
                onChange={setImageFile}
                defaultImage={getImageByScale(
                  space.fileInfo?.items ?? [],
                  FileScale.MEDIUM
                )}
                onDelete={() =>
                  handleSpaceChangeObject({ fileInfo: undefined })
                }
                rightLabel="* 단건 등록"
              />
            </div>
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="요금 정보" isRequired>
            <div className="flex flex-col gap-1.5">
              <div className="flex gap-2">
                <DefaultSelect
                  value={space.usePayment?.toString()}
                  onChange={handlePaymentChange}
                  optionList={PAYMENT_OPTIONS}
                  label="요금정책"
                  disabled={
                    space.reservationSpaceType ===
                    RESERVATION_SPACE_TYPES.SHOWER
                  }
                  dataTestId={"payment"}
                />
                {space.usePayment && (
                  <>
                    <DefaultInput
                      label="최소 이용시간 요금(30분당)"
                      type="number"
                      placeholder="0"
                      value={space.defaultPrice?.toString()}
                      onChange={handleSpaceChange("defaultPrice", "number")}
                      unitLabel="원"
                      min={0}
                    />
                    <DefaultInput
                      label="최소 이용시간 이후 요금(30분당)"
                      type="number"
                      placeholder="0"
                      value={space.additionalPrice?.toString()}
                      onChange={handleSpaceChange("additionalPrice", "number")}
                      unitLabel="원"
                      min={0}
                    />
                  </>
                )}
              </div>
              {space.usePayment && (
                <SelectTimeDays
                  label="정가 구간"
                  selectedItem={selectedPay}
                  weekDay={space.defaultPriceDays ?? initWeekValue}
                  enabledWeekDay={space.availableDays}
                  startTime={space.defaultPriceTimeStart ?? ""}
                  endTime={space.defaultPriceTimeEnd ?? ""}
                  startDate={convertTimeToDate(space.availableTimeStart ?? "")}
                  endDate={convertTimeToDate(space.availableTimeEnd ?? "")}
                  minuteStep={30}
                  onSelectedChange={setSelectedPay}
                  onWeekDayChange={handleSpaceChange("defaultPriceDays")}
                  onStartTimeChange={handleSpaceChange("defaultPriceTimeStart")}
                  onEndTimeChange={handleSpaceChange("defaultPriceTimeEnd")}
                />
              )}
            </div>
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="자동 승인 여부" isRequired>
            <RadioButtonGroup
              value={space.useAutoConfirm?.toString()}
              onChange={handleSpaceChange("useAutoConfirm")}
              options={make_use_auto_confirm_options(
                space.reservationSpaceType ?? RESERVATION_SPACE_TYPES.DEFAULT
              )}
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="기기 서비스" isRequired>
            <div className="flex flex-col">
              <RadioButtonGroup
                value={space.isRentalDevice?.toString()}
                onChange={handleDeviceRadioChange}
                options={PROVIDE_OPTIONS}
              />
              {space.isRentalDevice && (
                <SpaceDeviceList
                  rentalDevice={space.rentalDeviceModifyRequestList ?? []}
                  usePayment={space.usePayment}
                  onChange={handleDeviceChange}
                />
              )}
            </div>
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="예약 사용 규정" isRequired>
            <DefaultSelect
              value={space.usageTermId?.toString()}
              onChange={handleSpaceChange("usageTermId", "number")}
              optionList={etcCategory}
              width="w-full"
            />
          </FormRowLabel>
        </FormRow>
      </div>

      <div className="flex gap-2 justify-center">
        <DefaultButton onClick={closePopup}>닫기</DefaultButton>
        <PermissionWrapper>
          <DefaultButton color="primary" onClick={handlePost}>
            수정
          </DefaultButton>
        </PermissionWrapper>
      </div>
    </div>
  );
}
