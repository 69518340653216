type Props = {};

export default function ToggleOnTrueIcon(props: Props) {
  return (
    <svg
      width="30"
      height="17"
      viewBox="0 0 30 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.0266113" width="29.3333" height="16" rx="8" fill="black" />
      <circle cx="21.3333" cy="8.02661" r="7.5" fill="white" stroke="black" />
    </svg>
  );
}
