import { FiEdit2 } from "react-icons/fi";
import DefaultButton from "../../../components/DefaultButton";
import ListPage from "../../../components/ListPage";
import { TableColumnType } from "../../../components/Table";
import {
  getTemplateList,
  getTemplateListExcel,
} from "../../../services/templateService";
import { ComponentType, DASH } from "../../../types/search";
import { Template, templateServiceLabels } from "../../../types/template";
import { addDefaultOption } from "../../../utils/comboBoxUtils";
import {
  TEMPLATE_SERVICE_OPTIONS,
  usageYnLabels,
  USAGE_OPTIONS,
} from "../../../types/comboBoxOption";
import TemplateListTopRight from "../../../components/PageComponents/Service/TemplateList/ListTopRight";
import TemplateDetailView from "../../../components/PageComponents/Service/TemplateList/DetailView";
import DefaultLabel from "../../../components/Input/DefaultLabel";

type Props = {};

export default function TemplateList(props: Props) {
  const columnInfo: TableColumnType<Template>[] = [
    { header: "ID", name: "serviceTemplateId", width: "id" },
    {
      header: "서비스 구분",
      render: (item: Template) => templateServiceLabels[item.target] ?? DASH,
      width: "70",
    },
    { header: "서비스명", name: "serviceTemplateName" },
    {
      header: "노출순서",
      render: (item: Template) => item.sortOrder,
      width: "number",
    },
    {
      header: "서비스 사용 수",
      render: (item: Template) => item.usedCount?.toLocaleString() ?? DASH,
      width: "number",
    },
    { header: "등록일시", name: "createdAt", width: "date" },
    { header: "수정일시", name: "modifiedAt", width: "date" },
    {
      header: "사용여부",
      render: (item: Template) => (
        <div className="flex items-center justify-center">
          <DefaultLabel
            text={item.isActive ? usageYnLabels.TRUE : usageYnLabels.FALSE}
            color={item.isActive ? "blue" : "gray"}
          />
        </div>
      ),
      width: "yesOrNo",
    },
    {
      header: "활동",
      render: (item: Template, handler) => (
        <div className="w-full h-full flex gap-2 justify-center items-center">
          <DefaultButton
            size="tableIcon"
            color="blue"
            onClick={() => {
              if (handler?.popup) {
                handler.popup({
                  header: "템플릿 서비스 상세",
                  content: (
                    <TemplateDetailView
                      serviceTemplateId={item.serviceTemplateId}
                    />
                  ),
                });
              }
            }}
          >
            <FiEdit2 />
          </DefaultButton>
        </div>
      ),
      width: "icon",
    },
  ];

  const componentList: ComponentType<Template>[] = [
    {
      labelName: "등록일자",
      typeName: "dateRange",
      keyName: "createdAt",
      category: "date",
    },
    {
      labelName: "서비스 구분",
      typeName: "comboBox",
      keyName: "target",
      comboBoxOptions: addDefaultOption(TEMPLATE_SERVICE_OPTIONS),
      category: "typeSearch",
    },
    {
      labelName: "사용 여부",
      typeName: "comboBox",
      keyName: "isActive",
      comboBoxOptions: addDefaultOption(USAGE_OPTIONS),
      category: "typeSearch",
    },
    {
      labelName: "포함 검색",
      typeName: "tagFilter",
      keyName: "tagFilter",
      category: "typeSearch",
    },
  ];

  return (
    <ListPage
      getDataApi={getTemplateList}
      keyId={(item: Template) => item.serviceTemplateId}
      componentList={componentList}
      columnInfo={columnInfo}
      tableTitle="템플릿 서비스 목록"
      excelDownloadApi={getTemplateListExcel}
      renderTopRight={<TemplateListTopRight />}
    />
  );
}
