import { HttpStatusCode } from "axios";
import { useContext, useEffect, useState } from "react";

import {
  Building,
  BuildingGroup,
  TagSelectedItem,
  buildingGroupCategoryCodeValue,
} from "../../../../../types/building";
import { ComboBoxType } from "../../../../../types/search";
import {
  RESERVATION_SPACE_OPTIONS,
  RESERVATION_SPACE_TYPES,
  RentalDevice,
  SPACE_GENDER_OPTIONS,
  Space,
  WeekDay,
  reservationSpaceType,
} from "../../../../../types/space";
import {
  PAYMENT_OPTIONS,
  PROVIDE_OPTIONS,
  BOOLEAN_TYPES,
  SPACE_CONCURRENT_OPTIONS,
  make_use_auto_confirm_options,
} from "../../../../../types/comboBoxOption";
import { ValueType } from "../../../../../types/common";

import { toComboBoxType } from "../../../../../utils/comboBoxUtils";
import {
  validationFunctions,
  validationResultMessage,
} from "../../../../../libs/validations";
import { convertTimeToDate, formatDate } from "../../../../../utils/dateUtils";

import { useModal } from "../../../../../contexts/Modal";
import { PopupContext } from "../../../../../contexts/Popup";

import { postSpace } from "../../../../../services/spaceService";
import { getBuildingFloor } from "../../../../../services/buildingService";
import { getEtcCategoryTermsId } from "../../../../../services/etcService";

import DefaultButton from "../../../../DefaultButton";
import DefaultTextArea from "../../../../DefaultTextArea";
import FormHeader from "../../../../Form/Header";
import FormRow from "../../../../Form/Row";
import FormRowLabel from "../../../../Form/RowLabel";
import DefaultInput from "../../../../Input/DefaultInput";
import RadioButtonGroup from "../../../../Input/RadioButtonGroup";
import DefaultSelect from "../../../../SelectBox/DefaultSelect";
import Spinner from "../../../../Spinner";
import TagSingleFilter from "../../../../TagFilter/TagSingleFilter";
import SelectTimeDays, {
  dayType,
  initWeekValue,
  weekValueOptions,
} from "../SelectTimeDays";
import SingleImageUploader from "../../../../FileUploader/ImageFileUploader/SingleImageFileUploader";
import BuildingCompanyTable from "../../../../CustomTables/BuildingCompanyTable";
import RangeInput from "../../../../Input/RangeInput";
import SpaceDeviceList, { initRentalDevice } from "../DeviceList";
import { SPACE_ETC_CATEGORY_ID } from "../DetailView";
import DefaultLabel from "../../../../Input/DefaultLabel";
import PermissionWrapper from "../../../../PermissionWrapper";
import DateTimePickerRange from "@/components/DateTime/DateTimePickerRange";
import Checkbox from "@/components/Input/Checkbox";

type Props = {};

export default function SpaceCreateForm(props: Props) {
  const { closePopup, refreshAndClosePopup } = useContext(PopupContext);
  const { showAlert, showConfirm, handleError } = useModal();

  const [space, setSpace] = useState<Partial<Space>>({
    availableTimeStart: "08:00",
    availableTimeEnd: "17:00",
    defaultPriceTimeStart: "08:00",
    defaultPriceTimeEnd: "17:00",
    availableDays: { ...weekValueOptions.weekday },
    defaultPriceDays: { ...weekValueOptions.weekday },
    usePayment: false,
    availableGender: "ALL",
    isRentalDevice: false,
    isActive: true,
    isConnectedAllCompany: true,
    isConcurrentLimit: false,
    concurrentLimit: 0,
    reservationSpaceType: RESERVATION_SPACE_TYPES.DEFAULT,
    useAutoConfirm: true, // 기본이 무료 이므로.
    useBlockTime: false,
  });
  const [selectedTag, setSelectedTag] = useState<TagSelectedItem>();
  const [buildingFloor, setBuildingFloor] = useState<ComboBoxType[]>([]);
  const [etcCategory, setEtcCategory] = useState<ComboBoxType[]>([]);
  const [selectedOperate, setSelectedOperate] = useState(dayType.weekday);
  const [selectedPay, setSelectedPay] = useState(dayType.weekday);
  const [imageFile, setImageFile] = useState<File>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchCategory = async () => {
      try {
        const res = await getEtcCategoryTermsId(SPACE_ETC_CATEGORY_ID);
        if (res.status === HttpStatusCode.Ok) {
          setEtcCategory(toComboBoxType(res.data, "termsId", "name"));
          if (res.data.length > 0) {
            setSpace((prev) => ({
              ...prev,
              usageTermId: res.data[0].termsId,
            }));
          }
        }
      } catch (err: any) {
        handleError(err, "예약 사용 규정 목록 조회");
      }
    };

    fetchCategory();
  }, []);

  const handleSpaceChange =
    (key: keyof Space, valueType: ValueType = "any") =>
    (
      value:
        | string
        | WeekDay
        | number
        | boolean
        | undefined
        | string[]
        | RentalDevice[]
    ) => {
      if (valueType === "number") {
        value = value === "" ? undefined : Number(value);
      } else if (valueType === "boolean") {
        value = value === BOOLEAN_TYPES.TRUE;
      } else {
        // nothing to do
      }
      setSpace((prev) => ({ ...prev, [key]: value }));
    };

  const handleSpaceChangeObject = (data: Partial<Space>) => {
    setSpace((prev) => ({ ...prev, ...data }));
  };

  const postData = async () => {
    const message = "등록을 실패했습니다.";

    try {
      const response = await postSpace(createFormData());
      if (response.status === HttpStatusCode.Ok) {
        showAlert("등록 되었습니다.");
        refreshAndClosePopup();
      } else {
        throw new Error(message);
      }
    } catch (err: any) {
      handleError(err, "등록");
    }
  };

  const createFormData = () => {
    const formData = new FormData();
    imageFile && formData.append("img", imageFile);
    const data: Partial<Space> = {
      ...space,
      usableTimeEnd: space.usePayment
        ? space.usableTimeStart
        : space.usableTimeEnd,
    };

    formData.append("reservationSpaceCreateRequestDto", JSON.stringify(data));
    return formData;
  };

  const fetchBuildingFloor = async (buildingId: string) => {
    if (!buildingId) {
      return;
    }

    try {
      setIsLoading(true);

      const response = await getBuildingFloor(buildingId);
      if (response.data) {
        const arr = response.data.map((item) => ({
          value: item.buildingFloorId,
          label: item.floorName,
        }));

        setBuildingFloor(arr);
      }
    } catch (err: any) {
      handleError(err, "층 정보 조회");
    } finally {
      setIsLoading(false);
    }
  };

  const handlePost = () => {
    const validations = [
      validationFunctions.required(space.buildingId, "건물"),
      validationFunctions.required(space.buildingFloorId, "층"),
      ...(!space.isConnectedAllCompany && (space.companyIds?.length ?? 0) < 1
        ? [validationFunctions.required("", "회사 연결")]
        : []),
      ...(space.isConcurrentLimit && !((space.concurrentLimit ?? -1) > 0)
        ? [
            validationFunctions.required(
              space.concurrentLimit,
              "이용자 다중예약 제한"
            ),
          ]
        : []),
      validationFunctions.required(space.reservationSpaceName, "공간명"),
      ...(selectedOperate === dayType.select
        ? Object.values(space.availableDays ?? {}).every(
            (value) => value === false
          )
          ? [validationFunctions.required(null, "운영 반복 요일")]
          : []
        : []),
      ...(space.useBlockTime
        ? [
            validationFunctions.required(
              space.blockTimeStart,
              "이용 제한 시간대"
            ),
            validationFunctions.required(
              space.blockTimeEnd,
              "이용 제한 시간대"
            ),
          ]
        : []),
      ...(space.useBlockTime && space.blockTimeStart === space.blockTimeEnd
        ? [
            {
              isValid: false,
              errorMessage:
                "이용 제한 시간의 시작 시간과 끝 시간이 같을 수 없습니다.",
            },
          ]
        : []),
      validationFunctions.required(
        space.availablePeopleCountStart,
        "이용자 제한"
      ),
      validationFunctions.required(
        space.availablePeopleCountEnd,
        "이용자 제한"
      ),
      validationFunctions.required(space.usableTimeStart, "최소 이용 시간"),
      ...(!space.usePayment
        ? [
            validationFunctions.required(space.usableTimeEnd, "최대 이용 시간"),
            validationFunctions.required(
              space.dailyLimit,
              "일일 예약 제한 횟수"
            ),
          ]
        : []),
      validationFunctions.required(
        space.contactInfo,
        "담당자 연락처 안내 문구"
      ),
      validationFunctions.required(space.contactNumber, "담당자 연락처"),
      validationFunctions.required(
        space.reservationSpaceFeatures,
        "공간시설 및 규모"
      ),
      ...(space.usePayment && selectedPay === dayType.select
        ? Object.values(space.defaultPriceDays ?? {}).every(
            (value) => value === false
          )
          ? [validationFunctions.required(null, "정가 반복 요일")]
          : []
        : []),
      ...(space.isRentalDevice
        ? space.rentalDeviceCreateRequestList &&
          space.rentalDeviceCreateRequestList.length > 0
          ? space.rentalDeviceCreateRequestList.some(
              (item) =>
                item.deviceName === "" ||
                (space.usePayment && item.price === undefined)
            )
            ? [validationFunctions.required(null, "기기 서비스")]
            : []
          : []
        : []),
    ];

    const { usePayment, defaultPrice, additionalPrice } = space;
    if (usePayment) {
      validations.push({
        isValid: (defaultPrice ?? -1) >= 0,
        errorMessage: "최소 이용시간 요금 입력값이 없거나 0원 이상이 아닙니다.",
      });

      validations.push({
        isValid: (additionalPrice ?? -1) >= 0,
        errorMessage:
          "최소 이용시간 이후 요금 입력값이 없거나 0원 이상이 아닙니다.",
      });
    }

    const errorMessage = validationResultMessage(validations);
    if (errorMessage) {
      showAlert(errorMessage);
    } else {
      const usableTimeStartChecked = checkUsableTime(space.usableTimeStart);
      const usableTimeEndChecked = checkUsableTime(space.usableTimeEnd);

      if (
        !usableTimeStartChecked ||
        (!space.usePayment && !usableTimeEndChecked)
      ) {
        showAlert("이용시간은 30분 단위로만 입력 가능합니다.");
      } else if (
        !space.usePayment &&
        !compare(space.usableTimeStart, space.usableTimeEnd)
      ) {
        showAlert("이용종료시간보다 이용시작시간이 더 클 수 없습니다.");
      } else {
        showConfirm("등록 하시겠습니까?", postData);
      }
    }
  };

  const compare = (value1: number = -1, value2: number = -1) => {
    if (value1 < 0 || value2 < 0) {
      return false;
    }
    return value1 <= value2;
  };

  const checkUsableTime = (value: number = -1) => {
    if (value <= 0) {
      return false;
    }

    return value % 30 === 0;
  };

  const handleBuildingCompany = (
    companyIds: string[],
    selectedValue: string
  ) => {
    handleSpaceChangeObject({
      companyIds,
      isConnectedAllCompany: selectedValue === BOOLEAN_TYPES.TRUE,
    });
  };

  return (
    <div className="flex flex-col gap-5">
      {isLoading && <Spinner />}
      <div className="min-w-[900px] max-h-[700px] overflow-y-auto">
        <FormHeader title="기준 정보" />
        <FormRow>
          <FormRowLabel title="위치" isRequired>
            <div className="flex flex-col gap-1.5">
              <TagSingleFilter
                selectType="building"
                selectedItem={selectedTag}
                onChangeBuilding={(item: BuildingGroup) => {
                  setSelectedTag(item);
                  if (item.buildingId) {
                    handleSpaceChange("buildingId")(item.buildingId);
                    fetchBuildingFloor(item.buildingId);
                  } else {
                    console.error("buildingId is not exist while selecting");
                  }
                }}
                categoryCode={buildingGroupCategoryCodeValue.SPACE}
              />
              <DefaultSelect
                value={space.buildingFloorId ?? ""}
                optionList={buildingFloor}
                onChange={handleSpaceChange("buildingFloorId")}
                placeholder="층 선택"
              />
            </div>
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="회사 연결" isRequired>
            <BuildingCompanyTable
              buildingId={(selectedTag as Building)?.buildingId}
              companyIds={space.companyIds ?? []}
              onCompanyIdsChange={handleBuildingCompany}
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="이용자 다중예약 제한 적용">
            <div className="flex gap-4">
              <RadioButtonGroup
                value={space.isConcurrentLimit?.toString()}
                onChange={(value: string) => {
                  const isInclude = value === BOOLEAN_TYPES.TRUE;
                  handleSpaceChangeObject({
                    isConcurrentLimit: isInclude,
                    ...(!isInclude && { concurrentLimit: 0 }),
                  });
                }}
                options={SPACE_CONCURRENT_OPTIONS}
              />
              {space.isConcurrentLimit && (
                <DefaultInput
                  type="number"
                  value={space.concurrentLimit?.toString()}
                  onChange={(value: string) => {
                    handleSpaceChangeObject({ concurrentLimit: Number(value) });
                  }}
                  minWidth="w-[100px]"
                  min={0}
                  maxLength={3}
                  hideMaxLength
                  unitLabel="회"
                />
              )}
            </div>
          </FormRowLabel>
        </FormRow>

        <FormHeader title="공간 정보" />
        <FormRow>
          <FormRowLabel title="공간명" isRequired>
            <div className="flex flex-col gap-1">
              <DefaultInput
                value={space.reservationSpaceName}
                onChange={handleSpaceChange("reservationSpaceName")}
                placeholder="공간명 입력"
                maxLength={20}
              />
              <DefaultLabel
                text="* 가급적 다른 층의 공간과 구분할 수 있도록 입력하세요"
                color="summary"
              />
            </div>
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="공간 분류" isRequired>
            <DefaultSelect
              value={space.reservationSpaceType}
              optionList={RESERVATION_SPACE_OPTIONS}
              onChange={(value: string) => {
                if (value === RESERVATION_SPACE_TYPES.SHOWER) {
                  // 유료로 강제로 세팅
                  const data: Partial<Space> = {
                    usePayment: true,
                    ...{
                      defaultPriceDays: { ...weekValueOptions.weekday },
                      defaultPrice: undefined,
                      additionalPrice: undefined,
                      usableTimeEnd: undefined,
                      dailyLimit: undefined,
                    },
                  };

                  handleSpaceChangeObject(data);
                } else {
                  // 기본(회의실) 로 바꿨을때 -> 무료, 자동 승인으로 변경
                  handleSpaceChangeObject({
                    usePayment: false,
                    useAutoConfirm: true,
                  });
                }
                handleSpaceChangeObject({
                  reservationSpaceType: value as reservationSpaceType,
                });
              }}
              dataTestId={"space-type"}
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="공간 운영 정보" isRequired>
            <div className="flex flex-col gap-1.5">
              <SelectTimeDays
                label="운영시간"
                selectedItem={selectedOperate}
                weekDay={space.availableDays ?? initWeekValue}
                startTime={space.availableTimeStart ?? ""}
                endTime={space.availableTimeEnd ?? ""}
                minuteStep={30}
                onSelectedChange={setSelectedOperate}
                onWeekDayChange={handleSpaceChange("availableDays")}
                onStartTimeChange={(value: string) => {
                  handleSpaceChange("availableTimeStart")(value);
                  handleSpaceChange("defaultPriceTimeStart")(value);
                }}
                onEndTimeChange={(value: string) => {
                  handleSpaceChange("availableTimeEnd")(value);
                  handleSpaceChange("defaultPriceTimeEnd")(value);
                }}
              />
              <div className="flex flex-row items-center justify-start gap-2">
                <Checkbox
                  checked={space.useBlockTime}
                  className="h-[30px] w-[200px] flex items-center justify-start"
                  onClick={() => {
                    if (space.useBlockTime) {
                      // 체크 후 체크 해제 케이스
                      handleSpaceChangeObject({
                        blockTimeStart: undefined,
                        blockTimeEnd: undefined,
                      });
                    }
                    handleSpaceChangeObject({
                      useBlockTime: !space.useBlockTime,
                    });
                  }}
                  label={"이용 제한 시간대 설정"}
                  testId={"useBlockTime"}
                />
                {space.useBlockTime && (
                  <DateTimePickerRange
                    startPeriod={
                      space.blockTimeStart
                        ? convertTimeToDate(space.blockTimeStart)
                        : convertTimeToDate("08:00")
                    }
                    endPeriod={
                      space.blockTimeEnd
                        ? convertTimeToDate(space.blockTimeEnd)
                        : convertTimeToDate("17:00")
                    }
                    onlyTime
                    minuteStep={30}
                    startDate={undefined}
                    endDate={undefined}
                    onStartPeriodChange={(value: Date) => {
                      handleSpaceChangeObject({
                        blockTimeStart: formatDate(value, "HH:mm"),
                      });
                    }}
                    onEndPeriodChange={(value: Date) => {
                      handleSpaceChangeObject({
                        blockTimeEnd: formatDate(value, "HH:mm"),
                      });
                    }}
                    testId={"useBlockTime"}
                  />
                )}
              </div>
              <DefaultSelect
                value={space.availableGender}
                optionList={SPACE_GENDER_OPTIONS}
                onChange={handleSpaceChange("availableGender")}
                label="이용가능 성별"
              />
              <RangeInput
                type="number"
                placeholder="0"
                leftValue={space.availablePeopleCountStart?.toString()}
                rightValue={space.availablePeopleCountEnd?.toString()}
                onLeftValueChange={handleSpaceChange(
                  "availablePeopleCountStart",
                  "number"
                )}
                onRightValueChange={handleSpaceChange(
                  "availablePeopleCountEnd",
                  "number"
                )}
                leftLabel="이용자 제한"
                unitLabel="명"
              />
              <div className="flex gap-2">
                <RangeInput
                  type="number"
                  placeholder="0"
                  leftValue={space.usableTimeStart?.toString()}
                  rightValue={space.usableTimeEnd?.toString()}
                  onLeftValueChange={handleSpaceChange(
                    "usableTimeStart",
                    "number"
                  )}
                  onRightValueChange={handleSpaceChange(
                    "usableTimeEnd",
                    "number"
                  )}
                  leftLabel="최소 이용시간"
                  rightLabel="최대 이용시간"
                  unitLabel="분"
                  rightDisabled={space.usePayment}
                />
                <DefaultInput
                  type="number"
                  placeholder="0"
                  value={space.dailyLimit?.toString()}
                  onChange={handleSpaceChange("dailyLimit", "number")}
                  label="일일 예약 제한 횟수"
                  unitLabel="회"
                  disabled={space.usePayment}
                />
              </div>
              <DefaultLabel
                text="* 이용시간은 30분 단위로 입력 가능합니다."
                color="summary"
              />
            </div>
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="공간 상세 정보">
            <div className="flex flex-col gap-1.5 w-full">
              <div className="flex gap-2 items-end">
                <DefaultInput
                  value={space.contactInfo}
                  onChange={handleSpaceChange("contactInfo")}
                  label="담당자 정보"
                  placeholder="연락처 안내 문구"
                  maxLength={100}
                  hideMaxLength
                  required
                />
                <DefaultInput
                  type="tel"
                  value={space.contactNumber}
                  onChange={handleSpaceChange("contactNumber")}
                  placeholder="연락처를 입력하세요"
                  maxLength={20}
                  hideMaxLength
                />
              </div>
              <DefaultInput
                value={space.reservationSpaceFeatures}
                onChange={handleSpaceChange("reservationSpaceFeatures")}
                label="공간시설 및 규모"
                placeholder="시설 및 정보를 입력하세요 ex) 프로젝트, 대쉬보드 / [VIP] 20석"
                minWidth="w-full"
                maxLength={100}
                required
              />
              <DefaultTextArea
                value={space.reservationSpaceNote}
                label="공간특징"
                cols={65}
                rows={6}
                placeholder="공간특징을 입력하세요 ex) 당일,휴일대관 불가/장기예약 가능/취식금지"
                onChange={handleSpaceChange("reservationSpaceNote")}
                maxLength={500}
              />
              <SingleImageUploader
                label="공간 대표 사진"
                image={imageFile}
                onChange={setImageFile}
              />
            </div>
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="요금 정보" isRequired>
            <div className="flex flex-col gap-1.5">
              <div className="flex gap-2">
                <DefaultSelect
                  value={space.usePayment?.toString()}
                  onChange={(value: string) => {
                    const data: Partial<Space> = {
                      usePayment: value === "true",
                      ...(value === "true" && {
                        defaultPriceDays: { ...weekValueOptions.weekday },
                        defaultPrice: undefined,
                        additionalPrice: undefined,
                        usableTimeEnd: undefined,
                        dailyLimit: undefined,
                      }),
                    };
                    if (
                      space.reservationSpaceType ===
                      RESERVATION_SPACE_TYPES.DEFAULT
                    ) {
                      handleSpaceChangeObject({
                        useAutoConfirm: !(value === "true"),
                      });
                    }

                    handleSpaceChangeObject(data);
                  }}
                  optionList={PAYMENT_OPTIONS}
                  label="요금정책"
                  dataTestId={"payment"}
                  disabled={
                    space.reservationSpaceType ===
                    RESERVATION_SPACE_TYPES.SHOWER
                  }
                />
                {space.usePayment && (
                  <>
                    <DefaultInput
                      label="최소 이용시간 요금(30분당)"
                      type="number"
                      placeholder="0"
                      value={space.defaultPrice?.toString()}
                      onChange={handleSpaceChange("defaultPrice", "number")}
                      unitLabel="원"
                      min={0}
                    />
                    <DefaultInput
                      label="최소 이용시간 이후 요금(30분당)"
                      type="number"
                      placeholder="0"
                      value={space.additionalPrice?.toString()}
                      onChange={handleSpaceChange("additionalPrice", "number")}
                      unitLabel="원"
                      min={0}
                    />
                  </>
                )}
              </div>
              {space.usePayment && (
                <SelectTimeDays
                  label="정가 구간"
                  selectedItem={selectedPay}
                  weekDay={space.defaultPriceDays ?? initWeekValue}
                  enabledWeekDay={space.availableDays}
                  startTime={space.defaultPriceTimeStart ?? ""}
                  endTime={space.defaultPriceTimeEnd ?? ""}
                  startDate={convertTimeToDate(space.availableTimeStart ?? "")}
                  endDate={convertTimeToDate(space.availableTimeEnd ?? "")}
                  minuteStep={30}
                  onSelectedChange={setSelectedPay}
                  onWeekDayChange={handleSpaceChange("defaultPriceDays")}
                  onStartTimeChange={handleSpaceChange("defaultPriceTimeStart")}
                  onEndTimeChange={handleSpaceChange("defaultPriceTimeEnd")}
                />
              )}
            </div>
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="자동 승인 여부" isRequired>
            <RadioButtonGroup
              value={space.useAutoConfirm?.toString()}
              onChange={handleSpaceChange("useAutoConfirm")}
              options={make_use_auto_confirm_options(
                space.reservationSpaceType ?? RESERVATION_SPACE_TYPES.DEFAULT
              )}
              dataTestId={"auto-confirm"}
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="기기 서비스" isRequired>
            <div className="flex flex-col">
              <RadioButtonGroup
                value={space.isRentalDevice?.toString()}
                onChange={(value: string) => {
                  const isRentalDevice = value === "true";
                  const data = {
                    isRentalDevice,
                    rentalDeviceCreateRequestList: isRentalDevice
                      ? [{ ...initRentalDevice }]
                      : undefined,
                  };
                  handleSpaceChangeObject(data);
                }}
                options={PROVIDE_OPTIONS}
              />
              {space.isRentalDevice && (
                <SpaceDeviceList
                  rentalDevice={space.rentalDeviceCreateRequestList ?? []}
                  usePayment={space.usePayment}
                  onChange={handleSpaceChange("rentalDeviceCreateRequestList")}
                />
              )}
            </div>
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="예약 사용 규정" isRequired>
            <DefaultSelect
              value={space.usageTermId?.toString()}
              onChange={handleSpaceChange("usageTermId", "number")}
              optionList={etcCategory}
              width="w-full"
            />
          </FormRowLabel>
        </FormRow>
      </div>

      <div className="flex gap-2 justify-center">
        <DefaultButton onClick={closePopup}>닫기</DefaultButton>
        <PermissionWrapper>
          <DefaultButton color="primary" onClick={handlePost}>
            등록
          </DefaultButton>
        </PermissionWrapper>
      </div>
    </div>
  );
}
