type Props = {};

export default function RequiredIcon(props: Props) {
  return (
    <svg
      width="10"
      height="20"
      viewBox="0 0 10 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="required-icon"
    >
      <path
        d="M4.296 10.162L3.414 9.518L4.338 7.922L2.686 7.166L3.022 6.13L4.8 6.536L4.982 4.688H6.088L6.27 6.536L8.048 6.13L8.384 7.166L6.704 7.922L7.656 9.518L6.774 10.162L5.542 8.748L4.296 10.162Z"
        fill="#E94948"
      />
    </svg>
  );
}
