import {
  BOOLEAN_TYPES,
  DEFAULT_SELECT_ALL_VALUE,
} from "../types/comboBoxOption";
import { ComboBoxType } from "../types/search";

export const getFirstOptionValue = (options: ComboBoxType[]) =>
  options[0]?.value || "";

export const addDefaultOption = (
  options: ComboBoxType[],
  defaultLabel: string = "전체"
) => {
  const defaultOption = {
    value: DEFAULT_SELECT_ALL_VALUE,
    label: defaultLabel,
  };
  return [defaultOption, ...options];
};

export const createBooleanOptions = (
  trueLabel: string,
  falseLabel: string,
  reverseOrder: boolean = false
): ComboBoxType[] => {
  const options = [
    { value: BOOLEAN_TYPES.TRUE, label: trueLabel },
    { value: BOOLEAN_TYPES.FALSE, label: falseLabel },
  ];
  return reverseOrder ? options.reverse() : options;
};

export function toComboBoxType<T>(
  items: T[],
  valueKey: keyof T,
  labelKey: keyof T
): ComboBoxType[] {
  return items.map((item) => ({
    value: String(item[valueKey]),
    label: String(item[labelKey]),
  }));
}

type EnumType = { [key: string]: string };

export function toEnumValueOrDefault(
  inputValue: string,
  enumObject: EnumType,
  defaultValue: string
): string {
  const upperValue = inputValue.toUpperCase();
  const enumValues = Object.values(enumObject);

  if (enumValues.includes(upperValue)) {
    return upperValue;
  }

  return defaultValue;
}

export const findComboBoxItem = (options: ComboBoxType[], value: string) => {
  return options.find((option) => option.value === value);
};

export const transformToComboBoxTypeArray = (
  data: string[]
): ComboBoxType[] => {
  return data.map((value) => ({ value: value, label: value }));
};
