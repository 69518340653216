import { HttpStatusCode } from "axios";
import { useContext, useEffect, useState } from "react";
import { PopupContext } from "../../../../../../contexts/Popup";
import {
  getBuilding,
  getBuildingFloor,
  getToilet,
  putToilet,
} from "../../../../../../services/buildingService";
import {
  BuildingGroup,
  TagSelectedItem,
  Toilet,
  ToiletCompartment,
  buildingGroupCategoryCodeValue,
} from "../../../../../../types/building";
import { ComboBoxType } from "../../../../../../types/search";
import {
  USAGE_OPTIONS,
  GENDER_OPTIONS,
  BOOLEAN_TYPES,
  DEFAULT_SELECT_ALL_VALUE,
} from "../../../../../../types/comboBoxOption";
import DefaultButton from "../../../../../DefaultButton";
import FormHeader from "../../../../../Form/Header";
import FormRow from "../../../../../Form/Row";
import FormRowLabel from "../../../../../Form/RowLabel";
import DefaultInput from "../../../../../Input/DefaultInput";
import RadioButtonGroup from "../../../../../Input/RadioButtonGroup";
import ListPage from "../../../../../ListPage";
import DefaultSelect from "../../../../../SelectBox/DefaultSelect";
import Spinner from "../../../../../Spinner";
import { TableColumnType } from "../../../../../Table";
import TagSingleFilter from "../../../../../TagFilter/TagSingleFilter";
import {
  validationFunctions,
  validationResultMessage,
} from "../../../../../../libs/validations";
import ToggleButton from "../../../../../Input/ToggleButton";
import { useModal } from "../../../../../../contexts/Modal";
import BuildingCompanyTable from "../../../../../CustomTables/BuildingCompanyTable";
import MinusButtonIcon from "../../../../../../svgs/icons/Common/MinusButton";
import PlusButtonIcon from "../../../../../../svgs/icons/Common/PlusButton";
import PermissionWrapper from "../../../../../PermissionWrapper";

type Props = { spaceToiletId: string };

const MAX_TOILET_NAME_LENGTH = 20;
const TOILET_NAME_DESCRIPTION =
  "※ 화장실 명은 가급적 다른 층의 화장실과 구분할 수 있도록 입력하세요";

export default function ToiletDetailView({ spaceToiletId }: Props) {
  const { refreshAndClosePopup, closePopup } = useContext(PopupContext);
  const { showAlert, showConfirm, handleError } = useModal();
  const [toilet, setToilet] = useState<Partial<Toilet>>({});
  const [floorOptions, setFloorOptions] = useState<ComboBoxType[]>([]);
  const [selectedTag, setSelectedTag] = useState<TagSelectedItem>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchData(spaceToiletId);
  }, [spaceToiletId]);

  const fetchData = async (spaceToiletId: string) => {
    try {
      setIsLoading(true);

      const response = await getToilet(spaceToiletId);
      if (!response.data) {
        throw new Error("Toilet data not found");
      }

      const data = response.data;
      if (!data.buildingId) {
        throw new Error("Building ID not found");
      }

      const buildingResponse = await getBuilding(data.buildingId);
      if (!buildingResponse.data) {
        throw new Error("Building data not found");
      }

      setToilet({
        ...data,
        ...(data.spaceToiletCompartment.length === 0
          ? {
              spaceToiletCompartmentModifyRequests: [
                {
                  appendId: 1,
                  toiletCompartmentName: "",
                  isActive: true,
                } as ToiletCompartment,
              ],
            }
          : {
              spaceToiletCompartmentModifyRequests: data.spaceToiletCompartment,
            }),
        buildingName: buildingResponse.data.buildingName,
        companyIds: data.companyList.map((company) => company.companyId),
      });
      setSelectedTag({ ...buildingResponse.data });
      fetchFloor(buildingResponse.data.buildingId);
    } catch (err: any) {
      handleError(err, "조회");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchFloor = async (buildingId: string) => {
    try {
      setIsLoading(true);

      const response = await getBuildingFloor(buildingId);

      if (response.data) {
        const options = response.data.map((item) => ({
          value: item.buildingFloorId,
          label: item.floorName,
        }));

        setFloorOptions(options);
      }
    } catch (err: any) {
      handleError(err, "층 정보 조회");
    } finally {
      setIsLoading(false);
    }
  };

  const createToiletData = async () => {
    const spaceToiletCompartmentModifyRequests =
      toilet.spaceToiletCompartmentModifyRequests?.filter(
        (item) => item.toiletCompartmentName
      );

    const data = {
      spaceToiletId: toilet.spaceToiletId,
      toiletName: toilet.toiletName,
      buildingId: toilet.buildingId,
      buildingFloorId: toilet.buildingFloorId,
      gender: toilet.gender,
      isActive: toilet.isActive,
      spaceToiletCompartmentModifyRequests,
      companyIds: !toilet.isConnectedAllCompany ? toilet.companyIds : [],
      isConnectedAllCompany: toilet.isConnectedAllCompany,
    } as Partial<Toilet>;

    try {
      const responseUpdate = await putToilet(data);
      if (responseUpdate.status === HttpStatusCode.Ok) {
        showAlert("수정 되었습니다.");
        refreshAndClosePopup();
      }
    } catch (err: any) {
      handleError(err, "수정");
    }
  };

  const handleUpdate = () => {
    const names =
      toilet.spaceToiletCompartmentModifyRequests
        ?.filter((item) => Boolean(item.toiletCompartmentId))
        .map((item) => item.toiletCompartmentName) ?? [];

    const validations = [];
    validations.push(validationFunctions.required(toilet.buildingId, "위치"));

    if (toilet.isActive === true) {
      validations.push(
        validationFunctions.required(toilet.buildingFloorId, "층")
      );

      if (
        !toilet.isConnectedAllCompany &&
        (toilet.companyIds?.length ?? 0) < 1
      ) {
        validations.push(validationFunctions.required("", "회사 연결"));
      }
    }

    validations.push(
      validationFunctions.required(toilet.toiletName, "화장실명")
    );
    validations.push(
      validationFunctions.required(
        toilet.gender === DEFAULT_SELECT_ALL_VALUE ? "" : toilet.gender,
        "성별"
      )
    );

    if (names.length > 0) {
      validations.push(validationFunctions.required(names, "칸명"));
    }

    const errorMessage = validationResultMessage(validations);
    if (errorMessage) {
      showAlert(errorMessage);
    } else {
      showConfirm("수정 하시겠습니까?", createToiletData);
    }
  };

  const handleBuildingChange = (item: BuildingGroup) => {
    if (item.buildingId) {
      setSelectedTag(item);
      handleToiletChange("buildingId")(item.buildingId);
      handleToiletChange("buildingFloorId")("");
      handleToiletChange("companyIds")([]);
      fetchFloor(item.buildingId);
    } else {
      console.error("buildingId is null while changing building");
    }
  };

  const handleToiletChange =
    (key: keyof Toilet) => (value: string | string[] | boolean) => {
      setToilet((prev) => ({ ...prev, [key]: value }));
    };

  const handleToiletChangeObject = (data: Partial<Toilet>) => {
    setToilet((prev) => ({ ...prev, ...data }));
  };

  const compartmentColumnInfo: TableColumnType<ToiletCompartment>[] = [
    { header: "No", defaultNo: { ascending: true } },
    {
      header: "칸 ID",
      render: (item: ToiletCompartment) =>
        item.toiletCompartmentId ?? "자동 생성",
    },
    {
      header: "칸명",
      render: (item: ToiletCompartment) => (
        <div className="flex w-full px-2">
          <DefaultInput
            value={item.toiletCompartmentName}
            placeholder="칸명을 입력해주세요"
            onChange={(value: string) =>
              handleCompartmentChange(
                value,
                item.toiletCompartmentId
                  ? item.toiletCompartmentId
                  : item.appendId
              )
            }
            minWidth="w-full"
            maxLength={50}
            hideMaxLength
          />
        </div>
      ),
    },
    {
      header: "사용여부",
      render: (item: ToiletCompartment) => (
        <div className="flex justify-center items-center">
          {item.toiletCompartmentId && (
            <ToggleButton
              value={item.isActive}
              onChange={(value: boolean) =>
                handleCompartmentActiveChange(
                  value,
                  item.toiletCompartmentId ?? ""
                )
              }
            />
          )}
        </div>
      ),
    },
    {
      header: "상세",
      render: (item: ToiletCompartment) => {
        const appendCount =
          toilet.spaceToiletCompartmentModifyRequests?.filter(
            (c) => !c.toiletCompartmentId
          ).length ?? 0;
        const laseCompartmentId =
          toilet.spaceToiletCompartmentModifyRequests?.[
            toilet.spaceToiletCompartmentModifyRequests?.length - 1
          ].toiletCompartmentId;

        return (
          <div className="flex items-center justify-center min-w-icon">
            {(toilet.spaceToiletCompartmentModifyRequests?.length ?? 0) > 1 &&
              !item.toiletCompartmentId && (
                <DefaultButton
                  size="icon"
                  onClick={() => handleCompartmentRemove(item.appendId)}
                >
                  <MinusButtonIcon />
                </DefaultButton>
              )}
            {((appendCount === 0 &&
              item.toiletCompartmentId === laseCompartmentId) ||
              (appendCount > 0 && !item.toiletCompartmentId)) && (
              <DefaultButton
                size="icon"
                onClick={() => handleCompartmentAdd(appendCount)}
              >
                <PlusButtonIcon />
              </DefaultButton>
            )}
          </div>
        );
      },
    },
  ];

  const handleCompartmentActiveChange = (
    value: boolean,
    toiletCompartmentId: string
  ) => {
    setToilet((prev) => ({
      ...prev,
      spaceToiletCompartmentModifyRequests:
        prev.spaceToiletCompartmentModifyRequests?.map((item) =>
          item.toiletCompartmentId === toiletCompartmentId
            ? { ...item, isActive: value }
            : item
        ),
    }));
  };

  const handleCompartmentChange = (value: string, id: string | number) => {
    setToilet((prev) => ({
      ...prev,
      spaceToiletCompartmentModifyRequests:
        prev.spaceToiletCompartmentModifyRequests?.map((item) =>
          item.toiletCompartmentId === String(id) ||
          item.appendId === Number(id)
            ? { ...item, toiletCompartmentName: value }
            : item
        ),
    }));
  };

  const handleCompartmentRemove = (id: number) => {
    setToilet((prev) => {
      const filteredData = prev.spaceToiletCompartmentModifyRequests?.filter(
        (item) => item.appendId !== id
      );
      const fixedDataCount =
        prev.spaceToiletCompartmentModifyRequests?.filter(
          (item) => item.toiletCompartmentId
        ).length ?? 0;

      const fixedData = filteredData?.slice(0, fixedDataCount) ?? [];
      const updatedData =
        filteredData?.slice(fixedDataCount).map((item, index) => ({
          ...item,
          appendId: index + 1,
        })) ?? [];

      return {
        ...prev,
        spaceToiletCompartmentModifyRequests: [...fixedData, ...updatedData],
      };
    });
  };

  const handleCompartmentAdd = (appendCount: number) => {
    const item: ToiletCompartment = {
      appendId: appendCount + 1,
      toiletCompartmentName: "",
      isActive: true,
    };

    setToilet((prev) => ({
      ...prev,
      spaceToiletCompartmentModifyRequests: [
        ...(prev.spaceToiletCompartmentModifyRequests ?? []),
        item,
      ],
    }));
  };

  return (
    <div className="flex flex-col gap-5">
      {isLoading && <Spinner />}
      <div className="flex flex-col min-w-[900px] max-h-[700px] overflow-y-auto">
        <FormHeader title="건물 선택" />
        <FormRow>
          <FormRowLabel title="위치" isRequired>
            <div className="flex flex-col gap-1.5">
              <TagSingleFilter
                selectType="building"
                selectedItem={selectedTag}
                categoryCode={buildingGroupCategoryCodeValue.IOT_DEVICE}
                onChangeBuilding={handleBuildingChange}
              />
              <DefaultSelect
                value={toilet.buildingFloorId}
                placeholder="층 선택"
                optionList={floorOptions}
                onChange={handleToiletChange("buildingFloorId")}
                disabled={
                  toilet.buildingId === undefined || floorOptions.length === 0
                }
              />
            </div>
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="회사 연결" isRequired>
            <BuildingCompanyTable
              buildingId={toilet.buildingId}
              companyIds={toilet.companyIds ?? []}
              isConnectedAllCompany={toilet.isConnectedAllCompany}
              onCompanyIdsChange={(ids: string[], selectedValue: string) => {
                handleToiletChangeObject({
                  companyIds: ids,
                  isConnectedAllCompany: selectedValue === BOOLEAN_TYPES.TRUE,
                });
              }}
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="사용 여부">
            <RadioButtonGroup
              value={toilet.isActive?.toString()}
              options={USAGE_OPTIONS}
              onChange={(value: string) =>
                handleToiletChange("isActive")(value === "true")
              }
            />
          </FormRowLabel>
        </FormRow>

        <FormHeader title="화장실 정보" />
        <FormRow>
          <FormRowLabel title="화장실명" isRequired>
            <div className="flex flex-col gap-1">
              <DefaultInput
                value={toilet.toiletName}
                onChange={handleToiletChange("toiletName")}
                maxLength={MAX_TOILET_NAME_LENGTH}
                placeholder="화장실명을 입력해주세요"
              />
              <div className="text-xs">{TOILET_NAME_DESCRIPTION}</div>
            </div>
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="성별" isRequired>
            <DefaultSelect
              placeholder="성별 선택"
              value={toilet.gender}
              onChange={handleToiletChange("gender")}
              optionList={GENDER_OPTIONS}
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="칸 목록" isRequired>
            <div className="w-[700px]">
              <ListPage<ToiletCompartment>
                getDataApi={Promise.resolve}
                columnInfo={compartmentColumnInfo}
                selectedMode
                selectedData={toilet.spaceToiletCompartmentModifyRequests}
                hidePagination
                isTableScroll
                onlyRenderTable
              />
            </div>
          </FormRowLabel>
        </FormRow>
      </div>

      <div className="flex justify-center gap-2">
        <DefaultButton onClick={closePopup}>닫기</DefaultButton>
        <PermissionWrapper>
          <DefaultButton color="primary" onClick={handleUpdate}>
            수정
          </DefaultButton>
        </PermissionWrapper>
      </div>
    </div>
  );
}
