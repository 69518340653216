import { useContext, useEffect, useState } from "react";
import { useModal } from "../../../../../../contexts/Modal";
import { PopupContext } from "../../../../../../contexts/Popup";
import {
  GoodsRequest,
  GoodsServiceItem,
  GoodsRequestStatus,
  goodsRequestStatusValue,
  goodsRequestTypeValue,
} from "../../../../../../types/applyService";
import {
  getGoodsRequest,
  patchGoodsRequestCompleted,
  patchGoodsRequestNotes,
} from "../../../../../../services/applyServiceService";
import { HttpStatusCode } from "axios";
import Spinner from "../../../../../Spinner";
import FormHeader from "../../../../../Form/Header";
import FormRow from "../../../../../Form/Row";
import FormRowLabel from "../../../../../Form/RowLabel";
import DefaultLabel from "../../../../../Input/DefaultLabel";
import FormRowLabelItem from "../../../../../Form/RowLabelItem";
import DefaultTextArea from "../../../../../DefaultTextArea";
import ListPage from "../../../../../ListPage";
import { TableColumnType } from "../../../../../Table";
import DefaultButton from "../../../../../DefaultButton";
import Satisfaction from "../../../../../Satisfaction";

const GOODS_TYPE_TEXT = "사무용품 신청하기";

type Props = { goodsServiceId: string };

export default function GoodsRequestDetailView({ goodsServiceId }: Props) {
  const { showAlert, showConfirm, handleError } = useModal();
  const { closePopup, refreshAndClosePopup } = useContext(PopupContext);
  const [goodsRequest, setGoodsRequest] = useState<Partial<GoodsRequest>>({});
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const res = await getGoodsRequest(goodsServiceId);
      if (res.status === HttpStatusCode.Ok) {
        setGoodsRequest(res.data);
      }
    } catch (err: any) {
      handleError(err, "조회");
    } finally {
      setIsLoading(false);
    }
  };

  const handleGoodsChange =
    (key: keyof GoodsRequest) => (value: string | boolean | File) => {
      setGoodsRequest((prev) => ({ ...prev, [key]: value }));
    };

  useEffect(() => {
    fetchData();
  }, []);

  const handleUpdate = (status: GoodsRequestStatus) => {
    const message = status === "REQUESTED" ? "처리 완료" : "수정";

    showConfirm(`${message} 하시겠습니까?`, async () => {
      const api =
        status === "REQUESTED"
          ? patchGoodsRequestCompleted
          : patchGoodsRequestNotes;

      try {
        const res = await api(goodsServiceId, goodsRequest.adminNotes ?? "");
        if (res.status === HttpStatusCode.Ok) {
          showAlert(`${message} 되었습니다.`);
          refreshAndClosePopup();
        }
      } catch (err: any) {
        handleError(err, message);
      }
    });
  };

  const getRequestLocation = () => {
    let location = goodsRequest.requestBuildingFloorName ?? "";
    if (!goodsRequest.requestType) {
      return location;
    }

    const requestTypeInfo = goodsRequestTypeValue[goodsRequest.requestType];
    return (
      location + ` ${requestTypeInfo.location} / ${requestTypeInfo.requestName}`
    );
  };

  const renderRequestGoodsTable = () => {
    const columnInfo: TableColumnType<GoodsServiceItem>[] = [
      { header: "품명", name: "goodsName" },
      { header: "신청 개수", name: "requestCount" },
    ];

    return (
      <ListPage
        getDataApi={Promise.resolve}
        columnInfo={columnInfo}
        onlyRenderTable
        selectedMode
        selectedData={goodsRequest.goodsServiceItemList}
      />
    );
  };

  const renderByStatus = () => {
    if (goodsRequest.status === "CANCELED") {
      return (
        <>
          <FormHeader title="신청 내역" />
          <FormRow>
            <FormRowLabel title="취소 일시">
              <DefaultLabel text={goodsRequest.canceledAt} />
            </FormRowLabel>
          </FormRow>
        </>
      );
    } else {
      return (
        <>
          <FormHeader title="작업/결과" />
          {goodsRequest.status === "COMPLETED" && (
            <FormRow>
              <FormRowLabel title="처리 완료 일시">
                <DefaultLabel text={goodsRequest.completedAt} />
              </FormRowLabel>
            </FormRow>
          )}
          <FormRow>
            <FormRowLabel title="비고">
              <DefaultTextArea
                value={goodsRequest.adminNotes ?? ""}
                onChange={handleGoodsChange("adminNotes")}
                rows={5}
                maxLength={500}
                placeholder="기록에 필요한 내용을 입력해주세요"
              />
            </FormRowLabel>
          </FormRow>

          {goodsRequest.status === "COMPLETED" && (
            <>
              <FormHeader title="만족도 평가" />
              <FormRow>
                <Satisfaction
                  serviceName="goodsRequest"
                  serviceId={goodsRequest.goodsServiceId ?? ""}
                />
              </FormRow>
            </>
          )}
        </>
      );
    }
  };

  return (
    <div className="flex flex-col gap-5">
      {isLoading && <Spinner />}
      <div className="max-h-[700px] min-w-[900px] overflow-y-auto">
        <FormHeader title="기본 정보" />
        <FormRow>
          <FormRowLabel title="서비스 번호">
            <DefaultLabel text={goodsRequest.goodsServiceId} />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <div className="flex w-full">
            <FormRowLabel title="접수일시">
              <DefaultLabel text={goodsRequest.createdAt} />
            </FormRowLabel>
            <FormRowLabel title="최종 수정일시">
              <DefaultLabel text={goodsRequest.modifiedAt} />
            </FormRowLabel>
          </div>
        </FormRow>

        <FormHeader title="신청 내역" />
        <FormRow>
          <div className="flex w-full">
            <FormRowLabel title="분류">
              <DefaultLabel text={GOODS_TYPE_TEXT} />
            </FormRowLabel>
            <FormRowLabel title="처리 상태">
              <DefaultLabel
                text={
                  goodsRequest.status &&
                  goodsRequestStatusValue[goodsRequest.status]
                }
              />
            </FormRowLabel>
          </div>
        </FormRow>
        <FormRow>
          <FormRowLabel title="신청자 정보">
            <div className="flex flex-col">
              <FormRowLabelItem>
                <DefaultLabel
                  text={`건물 : ${goodsRequest.buildingName ?? ""}`}
                />
              </FormRowLabelItem>
              <FormRowLabelItem>
                <DefaultLabel
                  text={`회사 : ${goodsRequest.companyName ?? ""}`}
                />
              </FormRowLabelItem>
              <FormRowLabelItem>
                <DefaultLabel
                  text={`신청 부서 : ${goodsRequest.usedDepartment ?? ""}`}
                />
              </FormRowLabelItem>
              <FormRowLabelItem>
                <DefaultLabel
                  text={goodsRequest.createdByName ?? ""}
                  masking="BO_GOODS_REQUEST_DETAIL_CREATED_BY"
                  targetId={goodsRequest.createdBy ?? -1}
                  property="NAME"
                  prefix="신청자 : "
                />
              </FormRowLabelItem>
            </div>
          </FormRowLabel>
        </FormRow>

        <FormRow>
          <FormRowLabel title="요청 사항">
            <div className="flex flex-col">
              <FormRowLabelItem>
                <DefaultLabel text={`요청 장소 : ${getRequestLocation()}`} />
              </FormRowLabelItem>
              <FormRowLabelItem>
                <DefaultLabel
                  text={`기타 요청 : ${goodsRequest.extraRequest ?? ""}`}
                />
              </FormRowLabelItem>
            </div>
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="요청 품목">
            {renderRequestGoodsTable()}
          </FormRowLabel>
        </FormRow>

        {renderByStatus()}
      </div>

      <div className="flex justify-center gap-2">
        <DefaultButton onClick={closePopup}>닫기</DefaultButton>

        {goodsRequest.status === "REQUESTED" ? (
          <DefaultButton
            color="primary"
            onClick={() => handleUpdate("REQUESTED")}
          >
            처리 완료
          </DefaultButton>
        ) : goodsRequest.status === "COMPLETED" ? (
          <DefaultButton
            color="primary"
            onClick={() => handleUpdate("COMPLETED")}
          >
            수정
          </DefaultButton>
        ) : null}
      </div>
    </div>
  );
}
