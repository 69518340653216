import { getLocalStorage } from "./localStorage";
import axios, {
  AxiosHeaders,
  HttpStatusCode,
  InternalAxiosRequestConfig,
} from "axios";
import { Pageable, initPageable } from "../types/pageable";
import { SearchCondition } from "../components/ListPage";
import { logoutUser } from "../services/userServices";

const request = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export const authRequest = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const requestSuccess = async (config: InternalAxiosRequestConfig<any>) => {
  const tokenString = getLocalStorage("jwtToken");
  if (!tokenString) return config;

  const jwtToken = JSON.parse(tokenString);
  if (jwtToken && jwtToken.accessToken) {
    config.headers.Authorization = `Bearer ${jwtToken.accessToken}`;
  }
  return config;
};

export const responseError = async (error: any) => {
  if (navigator.onLine === false) {
    const networkError = {
      ...error,
      response: { data: { error: "네트워크 연결이 필요합니다." } },
    };
    return Promise.reject(networkError);
  }

  if (
    error.response.status === HttpStatusCode.Unauthorized &&
    !error.config.url.includes("/oauth2/sign-out")
  ) {
    // 토큰이 만료된 경우 로그인 페이지로 이동합니다.
    // 로그아웃 요청에 대한 경우에는 로그아웃을 재요청 하지 않도록 한다.(루프 방지)
    logoutUser();

    return Promise.reject(error);
  }

  return Promise.reject(error);
};
// 요청 인터셉터를 설정하여 요청 전에 액세스 토큰을 추가합니다.
request.interceptors.request.use(requestSuccess, (error) => {
  return Promise.reject(error);
});
// 응답 인터셉터를 설정하여 응답 중 액세스 토큰 만료 시 리프레시 토큰을 사용해 재시도합니다.
request.interceptors.response.use((response) => {
  return response;
}, responseError);

export type ListResponse<T> = { items: T[]; pageable: Pageable };

export type ErrorResponse = {
  code: number;
  error: string;
};

export const generateAxiosListResponse = <T>(
  params: SearchCondition<T>,
  items: T[]
) => {
  return {
    data: {
      items,
      pageable: { ...initPageable, page: params?.page ?? 1, totalPages: 1 },
    },
    status: HttpStatusCode.Ok,
    statusText: "OK",
    headers: {},
    config: {
      headers: {} as AxiosHeaders,
    },
  };
};

export const generateAxiosResponse = <T>(items: T[]) => {
  return {
    data: items,
    status: HttpStatusCode.Ok,
    statusText: "OK",
    headers: {},
    config: { headers: {} as AxiosHeaders },
  };
};

export const objectToFormData = (obj: Record<string, any>): FormData => {
  const formData = new FormData();

  const appendFormData = (key: string, value: any) => {
    if (Array.isArray(value)) {
      value.forEach((item, index) => appendFormData(`${key}[${index}]`, item));
    } else if (
      typeof value === "object" &&
      value !== null &&
      !(value instanceof File)
    ) {
      formData.append(
        key,
        new Blob([JSON.stringify(value)], { type: "application/json" })
      );
    } else {
      formData.append(key, value);
    }
  };

  Object.keys(obj).forEach((key) => appendFormData(key, obj[key]));

  return formData;
};

export default request;
