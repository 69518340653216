export type LoggingLogin = {
  historyId: string;
  email: string;
  name: string;
  channel: LoginChannelType;
  isSucceed: boolean;
  ip: string;
  loggedAt: string;
};

export type LoggingPermission = {
  historyId: number;
  targetEmail: string;
  targetName: string;
  before: string;
  after: string;
  createdByEmail: string;
  createdByName: string;
  createdByIp: string;
  createdAt: string;
  loggedAt: string;
};

export type LoginChannelType = "PC" | "REISSUE" | "MOBILE";

export const loginChannelLabels: Record<LoginChannelType, string> = {
  PC: "PC",
  MOBILE: "모바일",
  REISSUE: "갱신",
};
