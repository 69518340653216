import { DEFAULT_SELECT_CUSTOM } from "./comboBoxOption";
import { ComboBoxType } from "./search";

export type Incommodity = {
  channel: string;
  channelName: string;
  vocReceiptId: string;
  status: string;
  statusName: string;
  atgBuildingId: string;
  atgBuildingFloorId: string;
  atgBuildingVocId: string;
  atgVocTypeCode: string;
  atgVocEquipmentTypeCode: string;
  companyId: string;
  isSatisfaction: boolean;
  reservationStatus: string;
  buildingId: string;
  isFile: boolean;
  vocId: string;
  completedAt: string;
  completedBy: number;
  completedByName: string;
  s3PreFile: ImageType[];
  zadaraPrePath: string;
  zadaraPrePathList: ImageType[];
  s3PostFile: ImageType[];
  zadaraPostPath: string;
  zadaraPostPathList: ImageType[];
  companyName: string;
  centerIncommodityId: number;
  centerIncommodityCategoryId: string;
  categoryName: string;
  buildingName: string;
  buildingFloorId: string;
  floorName: string;
  buildingSpaceId: string;
  description: string;
  deadLine: string;
  createdAt: string;
  createdBy: number;
  createdByName: string;
  createdByCompany: string;
  createdByMobileNumber: string;
  modifiedAt: string;
  receiptedAt: string;
  receiptedBy: number;
  receiptedByName: string;
  location: string;
  userFile: ImageType;
  feedback: string;
  resource: string;
  resourceStr: string; // 투입자재 직접입력
  worker: string;
  satisfactionId: number;
  satisfaction: number;
  satisfactionCreatedAt: string;
  satisfactionComment: string;
  satisfactionReplyId: number;
  satisfactionReplyComment: string;
  satisfactionReplyCreatedBy: number;
  satisfactionReplyCreatedByName: string;
  satisfactionReplyCreatedAt: string;
  satisfactionCreatedByName: string;
  satisfactionCreatedBy: number;
  uploadFiles: File;
  deleteFiles: number[];
  deleteZadaraFiles: number[];
  isScheduled: boolean;
  canceledAt: string;
  tagFilter: string;
  equipmentTypeCodeName: string;
  serviceTemplateId: number;
  pushTypeCode: string;
};

export type ImageType = {
  fileId: number;
  fileUrl: string;
  fileZadaraId: number;
  filePath: string;
};

export const incommodityErrorCode = {
  SUCCESS: "S",
  ERROR: "E",
};

export type IncommodityCreateDto = {
  buildingId: string;
  buildingFloorId: string | null;
  buildingSpaceId: string | null;
  serviceTemplateId: number;
  location: string | null;
  requestDetail: string | null;
  description: string | null;
  deadLine: string | null;
  userId: number | null;
};

export type CenterIncommodityProcessDto = {
  completedAt: string;
  feedback: string;
  worker: string[];
  resource: IncommodityResource[];
  resourceStr: string;
};

export type IncommodityResource = {
  materialCode: string;
  storageCode: string;
  centerCode: string;
  useQuantity: string;
  materialName: string;
  standardName: string;
};

export type IncommodityCategoryList = Record<string, IncommodityCategory[]>;

export type IncommodityCategory = {
  serviceId: string;
  serviceName: string;
  sortOrder: number;
  serviceItemType: string;
  serviceItemTypeName: string;
  vocPlacePlaceholder: string;
  vocDetailPlaceholder: string;
  vocRequestTypeArray: string[];
  isRequiredVocPlace: boolean;
  isRequiredVocDetail: boolean;
  vocTypeCode: string;
  equipmentTypeCode: string;
  serviceTemplateId: number;
};

export type IncommodityFormData = {
  beforeFiles: File[];
  afterFiles: File[];
  s3PreFile: ImageType[];
  s3PostFile: ImageType[];
  existingBeforeImages: ImageType[];
  existingAfterImages: ImageType[];
  centerIncommodityProcessDto: { feedback?: string; deleteFileId?: number[] };
};

export type IncommodityStatus =
  | "receipt"
  | "confirm"
  | "schedule"
  | "complete"
  | "cancel";

interface StatusInfo {
  name: string;
  value: string;
}

export const ResourcePushTypeCode = {
  COMMUNICATION: "COMMUNICATION", // 통신
  FACILITY: "FACILITY", // 시설
  CLEANING: "CLEANING", // 미화
};

export const INCOMMODITY_STATUS_VALUE = {
  RECEIPT: "12",
  CONFIRM: "13",
  SCHEDULE: "14",
  COMPLETE: "15",
  COMPLETE2: "16",
  CANCEL: "17",
};

export const incommodityStatusMap: Record<IncommodityStatus, StatusInfo> = {
  receipt: { name: "접수", value: INCOMMODITY_STATUS_VALUE.RECEIPT },
  confirm: { name: "처리중", value: INCOMMODITY_STATUS_VALUE.CONFIRM }, // confirm, schedule은 isSchedule 컬럼 여부로 인해 구분. 원래 confirm value는 14지만, 검색 및 구분을 위해 13으로 수정.
  schedule: { name: "처리예정", value: INCOMMODITY_STATUS_VALUE.SCHEDULE },
  complete: { name: "처리완료", value: INCOMMODITY_STATUS_VALUE.COMPLETE },
  cancel: { name: "취소", value: INCOMMODITY_STATUS_VALUE.CANCEL },
};

export const feedbackOptions: ComboBoxType[] = [
  {
    value: "1",
    label:
      "안녕하세요. 공간매니저입니다. 요청하신 사항에 대하여 처리완료하였습니다. 쾌적한 공간에서 오늘도 즐거운 하루 보내세요!",
  },
  {
    value: "2",
    label:
      "안녕하세요. 공간매니저입니다. 요청하신 불편사항 확인결과, 현재 이상없이 사용가능한 것을 확인하였습니다. 추후 동일현상 발생시 재접수부탁드립니다. 오늘도 즐거운 하루 보내세요!",
  },
  {
    value: "3",
    label:
      "안녕하세요. 공간매니저입니다. 요청하신 사항은 자재구입/외부업체 등을 통해 추후 처리될 예정입니다. 빠른 시일 내에 처리하겠습니다. 감사합니다.",
  },
  {
    value: "4",
    label:
      "안녕하세요. 공간매니저입니다. 요청하신 사항에 대해 예약접수가 완료되었습니다. 즐거운 하루 보내세요.",
  },
  {
    value: "5",
    label:
      "안녕하세요. 공간매니저입니다. 불편을 드려 죄송합니다. 요청하신건은 재확인하여 처리하도록 하겠습니다. 소중한 의견 감사합니다.",
  },
  {
    value: DEFAULT_SELECT_CUSTOM,
    label: "직접입력",
  },
];
