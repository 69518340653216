import { AxiosResponse } from "axios";
import { useContext, useState } from "react";

import { PopupContext } from "../../../contexts/Popup";

import DefaultButton from "../../DefaultButton";
import PermissionWrapper from "../../PermissionWrapper";
import ExcelFileUploaderForm from "../ExcelFileUploaderForm";
import CommonPopup from "@/components/Popup/CommonPopup";

type Props = {
  title: string;
  actionButtonText?: string;
  getExcelDataApi: () => Promise<AxiosResponse<Blob>>;
  postExcelDataApi?: (formData: FormData) => Promise<AxiosResponse<void>>;
  onJsonResult?: (data: any) => Promise<AxiosResponse<void>>; // 수정된 부분: JSON 데이터를 받도록 변경
  anotherPopup?: boolean;
  header?: React.ReactNode;
};

export default function ExcelFileUploaderButton({
  title,
  getExcelDataApi,
  postExcelDataApi,
  onJsonResult,
  anotherPopup,
  actionButtonText = "일괄 업로드",
  header,
}: Props) {
  const { openPopup } = useContext(PopupContext);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <PermissionWrapper>
      <DefaultButton
        color="default"
        size="tableTop"
        onClick={() => {
          if (!anotherPopup) {
            openPopup({
              header: header ?? "파일업로드",
              content: (
                <ExcelFileUploaderForm
                  title={`${title} 일괄 등록`}
                  getExcelDataApi={getExcelDataApi}
                  postExcelDataApi={postExcelDataApi}
                  onJsonResult={onJsonResult}
                />
              ),
            });
          } else {
            setIsOpen(true);
          }
        }}
      >
        {actionButtonText}
      </DefaultButton>
      {anotherPopup && isOpen && (
        <CommonPopup
          isOpen
          header={header ?? "파일업로드"}
          content={
            <ExcelFileUploaderForm
              title={`${title} 일괄 등록`}
              getExcelDataApi={getExcelDataApi}
              postExcelDataApi={postExcelDataApi}
              onJsonResult={onJsonResult}
              onClose={() => setIsOpen(false)}
            />
          }
          closePopup={() => setIsOpen(false)}
        />
      )}
    </PermissionWrapper>
  );
}
