import { useContext } from "react";
import { PopupContext } from "../../../../../contexts/Popup";
import DefaultButton from "../../../../DefaultButton";
import SpaceCreateForm from "../CreateForm";
import PermissionWrapper from "../../../../PermissionWrapper";

type Props = {};

export default function SpaceListTopRight({}: Props) {
  const { openPopup } = useContext(PopupContext);

  return (
    <PermissionWrapper>
      <DefaultButton
        size="tableTop"
        color="default"
        onClick={() =>
          openPopup({
            header: "공간 등록",
            content: <SpaceCreateForm />,
          })
        }
      >
        신규등록
      </DefaultButton>
    </PermissionWrapper>
  );
}
