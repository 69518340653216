import { useState } from "react";
import { useModal } from "../../../../../contexts/Modal";
import { RESERVATION_SPACE_TYPES, WeekDay } from "../../../../../types/space";
import {
  RESERVATION_STATUS_TYPES,
  SpaceReservationData,
  reservationStatusValue,
} from "../../../../../types/spaceReservation";
import DateTimeInput from "../../../../DateTime/DateTimeInput";
import Checkbox from "../../../../Input/Checkbox";
import CheckboxDays from "../../../../Input/CheckboxDays";
import CheckboxTimes from "../../../../Input/CheckboxTimes";
import { initWeekValue } from "../../SpaceList/SelectTimeDays";
import DefaultLabel from "../../../../Input/DefaultLabel";
import { calculateTime } from "../../../../../utils/dateUtils";

const START_DAY_TIME = "00:00";
const END_DAY_TIME = "24:00";

type Props = {
  reservationDate: string;
  reservationInfo: Partial<SpaceReservationData>;
  longTermReservationDateStart: string;
  longTermReservationDateEnd: string;
  longTermReservationDayOfWeek: WeekDay;
  spaceReservationDetailId?: string;
  reservationStatus?: reservationStatusValue;
  longTerm: boolean;
  hideLongTerm?: boolean;
  enabledWeekDay?: WeekDay;
  onLongTermChange: (value: boolean) => void;
  onChange: (data: Partial<SpaceReservationData>) => void;
};

export default function AvailableTime({
  reservationDate,
  reservationInfo,
  longTermReservationDateStart,
  longTermReservationDateEnd,
  longTermReservationDayOfWeek,
  spaceReservationDetailId = "",
  reservationStatus,
  longTerm,
  hideLongTerm = false,
  enabledWeekDay,
  onLongTermChange,
  onChange,
}: Props) {
  const { showAlert } = useModal();
  const [allTime, setAllTime] = useState(false);
  const [timeInfo, setTimeInfo] = useState("");

  const isShowerRoom =
    reservationInfo.reservationSpaceType === RESERVATION_SPACE_TYPES.SHOWER;

  const isAvailableTimeUpdate = (
    [
      RESERVATION_STATUS_TYPES.AVAILABLE,
      RESERVATION_STATUS_TYPES.WAITING,
      RESERVATION_STATUS_TYPES.CONFIRMED,
      RESERVATION_STATUS_TYPES.IN_USE,
      undefined,
    ] as (reservationStatusValue | undefined)[]
  ).includes(reservationStatus);

  const handleAllTimeChange = (value: boolean) => {
    if (!isAvailableTimeUpdate) {
      return;
    }

    const list =
      reservationInfo.reservationList?.filter(
        (item) => item.spaceReservationDetailId !== spaceReservationDetailId
      ) ?? [];

    if (list.length > 0) {
      showAlert("예약된 시간이 있어 종일 사용할 수 없습니다.");
    } else {
      setAllTime(value);
      if (value === false) {
        handleTimes("", "");
      }
    }
  };

  const getTimeInfo = (
    reservationTimeStart: string,
    reservationTimeEnd: string
  ) => {
    const [startHour, startMinute] = reservationTimeStart
      ? reservationTimeStart.split(":").map(Number)
      : [0, 0];
    const [endHour, endMinute] = reservationTimeEnd
      ? reservationTimeEnd.split(":").map(Number)
      : [0, 0];
    const diffTime = endHour * 60 + endMinute - (startHour * 60 + startMinute);

    const selectHour = Math.floor(diffTime / 60);
    const selectMinute = diffTime % 60;

    const selectTime = `${selectHour
      .toString()
      .padStart(2, "0")}시간 ${selectMinute
      .toString()
      .padStart(2, "0")}분 선택`;

    const usableTime = `(${reservationInfo.usableTimeStart ?? ""}~${
      reservationInfo.usableTimeEnd ?? ""
    }분 선택 가능)`;

    return `${selectTime}${usableTime}`;
  };

  const handleTimes = (
    reservationTimeStart: string,
    reservationTimeEnd: string
  ) => {
    let calcBeforeCleanTime: string;
    let calcAfterCleanTime: string;

    if (isShowerRoom) {
      calcBeforeCleanTime = reservationTimeStart;
      calcAfterCleanTime = reservationTimeEnd;
    } else {
      calcBeforeCleanTime = calculateTime(reservationTimeStart, -30);
      calcAfterCleanTime = calculateTime(reservationTimeEnd, 30);
    }

    const beforeCleanTime =
      reservationTimeStart === START_DAY_TIME
        ? reservationInfo.availableTimeStart
        : calcBeforeCleanTime;
    const afterCleanTime =
      reservationTimeEnd === END_DAY_TIME
        ? reservationInfo.availableTimeEnd
        : calcAfterCleanTime;

    const cleanData: Partial<SpaceReservationData> = {
      beforeCleanTime,
      afterCleanTime,
    };

    setTimeInfo(getTimeInfo(reservationTimeStart, reservationTimeEnd));

    onChange({
      ...(reservationStatus !== RESERVATION_STATUS_TYPES.CANCELED && {
        reservationTimeStart,
        reservationTimeEnd,
      }),
      ...(reservationInfo.usePayment ? cleanData : {}),
    });
  };

  const handleLongTerm = (value: boolean) => {
    onLongTermChange(value);
    onChange({
      longTermReservationDateStart: undefined,
      longTermReservationDateEnd: undefined,
      longTermReservationDayOfWeek: undefined,
    });
  };

  const handleLongTermDayChange =
    (key: keyof SpaceReservationData) => (value: string | WeekDay) => {
      const updatedValues: Partial<SpaceReservationData> = {
        longTermReservationDateStart: longTermReservationDateStart,
        longTermReservationDateEnd: longTermReservationDateEnd,
        longTermReservationDayOfWeek: longTermReservationDayOfWeek,
        [key]: value,
      };

      onChange({ ...updatedValues });
    };

  return (
    <div className="w-full max-w-screen-md">
      <div className="flex flex-col gap-2">
        <div className="flex gap-2 items-center">
          <DateTimeInput date={reservationDate} disabled />

          {!isShowerRoom && (
            <Checkbox
              label={`종일 사용 여부(${
                reservationInfo.availableTimeStart ?? ""
              }~${reservationInfo.availableTimeEnd ?? ""})`}
              checked={allTime}
              onClick={handleAllTimeChange}
              disabled={!isAvailableTimeUpdate}
              labelTestId={"all-day"}
            />
          )}
        </div>
        <DefaultLabel text={timeInfo} />
        <CheckboxTimes
          spaceReservationDetailId={spaceReservationDetailId}
          usePayment={reservationInfo.usePayment}
          reservationList={reservationInfo.reservationList ?? []}
          availableTimeStart={reservationInfo.availableTimeStart ?? ""}
          availableTimeEnd={reservationInfo.availableTimeEnd ?? ""}
          reservationStatus={reservationStatus}
          allTime={allTime}
          disabled={!isAvailableTimeUpdate}
          onAllTimeChange={setAllTime}
          onTimesChange={handleTimes}
          reservationSpaceType={reservationInfo.reservationSpaceType}
          useBlockTime={reservationInfo.useBlockTime as boolean}
          blockTimeStart={reservationInfo.blockTimeStart}
          blockTimeEnd={reservationInfo.blockTimeEnd}
        />
        {!hideLongTerm && (
          <>
            <Checkbox
              label={`장기예약하기`}
              checked={longTerm}
              onClick={handleLongTerm}
              labelTestId={"longTerm"}
            />
            {longTerm && (
              <div className="flex gap-2 items-center">
                <DateTimeInput
                  date={longTermReservationDateStart}
                  min={reservationDate}
                  max={longTermReservationDateEnd}
                  onDateTimeChange={handleLongTermDayChange(
                    "longTermReservationDateStart"
                  )}
                />
                ~
                <DateTimeInput
                  date={longTermReservationDateEnd}
                  min={longTermReservationDateStart || reservationDate}
                  onDateTimeChange={handleLongTermDayChange(
                    "longTermReservationDateEnd"
                  )}
                />
                <CheckboxDays
                  weekDay={longTermReservationDayOfWeek ?? initWeekValue}
                  enabledWeekDay={enabledWeekDay}
                  onWeekDayChange={handleLongTermDayChange(
                    "longTermReservationDayOfWeek"
                  )}
                />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}
