import { SearchCondition } from "../components/ListPage";
import request, { ListResponse } from "../libs/request";
import {
  Incommodity,
  IncommodityCategoryList,
  incommodityStatusMap,
} from "../types/incommodity";
import { getExcelDownloadApi } from "./commonService";

const INCOMMODITY_API_PREFIX = "/v2/admin/pinecenter/incommodity";

export const getIncommodityList = (params: SearchCondition<Incommodity>) => {
  const { buildingId, companyId, status, ...otherParams } = params;

  const isScheduled = status === incommodityStatusMap.schedule.value;
  const newStatus =
    status === incommodityStatusMap.confirm.value
      ? incommodityStatusMap.schedule.value
      : status;

  const newParams = {
    ...otherParams,
    ...(status && { isScheduled }),
    status: newStatus,
    ...(buildingId && { buildingId: buildingId.join(",") }),
    ...(companyId && { companyId: companyId.join(",") }),
  };

  return request.get<ListResponse<Incommodity>>(INCOMMODITY_API_PREFIX, {
    params: newParams,
  });
};

export const getIncommodityListExcel = (
  params: SearchCondition<Incommodity>
) => {
  const { buildingId, companyId, ...otherParams } = params;

  const newParams = {
    ...otherParams,
    ...(buildingId && { buildingId: buildingId.join(",") }),
    ...(companyId && { companyId: companyId.join(",") }),
  };

  return getExcelDownloadApi(INCOMMODITY_API_PREFIX + "/excel", newParams);
};

export const getIncommodityListByUser = (
  params: SearchCondition<Incommodity>,
  userId: number
) =>
  request.get<ListResponse<Incommodity>>(
    INCOMMODITY_API_PREFIX + `/${userId}/user`,
    { params }
  );

export const getIncommodity = (centerIncommodityId: number) =>
  request.get<Incommodity>(INCOMMODITY_API_PREFIX + `/${centerIncommodityId}`);

export const getIncommodityByBuildingSpace = (
  params: SearchCondition<Incommodity>,
  buildingSpaceId: string
) =>
  request.get<Incommodity>(
    INCOMMODITY_API_PREFIX + `/buildingSpace/${buildingSpaceId}`,
    { params }
  );

export const getIncommodityByBuilding = (
  params: SearchCondition<Incommodity>,
  buildingId: string
) =>
  request.get<Incommodity>(INCOMMODITY_API_PREFIX + `/building/${buildingId}`, {
    params,
  });

export const getIncommodityCategory = (searchWord: string) =>
  request.get<IncommodityCategoryList>(
    INCOMMODITY_API_PREFIX + "/category/list",
    {
      params: searchWord,
    }
  );

export const getBuildingCategoryList = (
  buildingId: string,
  searchWord?: string
) =>
  request.get<IncommodityCategoryList>(
    INCOMMODITY_API_PREFIX + "/category/list/active",
    { params: { searchWord, buildingId } }
  );

export const postIncommodity = (data: FormData) =>
  request.post<Incommodity>(INCOMMODITY_API_PREFIX, data);

export const postIncommoditySatisfaction = (
  centerIncommodityId: number,
  comment: string
) =>
  request.post(
    INCOMMODITY_API_PREFIX + `/satisfaction/${centerIncommodityId}`,
    { comment }
  );

export const patchIncommodityWorking = (centerIncommodityId: number) =>
  request.patch(INCOMMODITY_API_PREFIX + `/${centerIncommodityId}/working`, {
    params: centerIncommodityId,
  });

export const patchIncommoditySchedule = (
  centerIncommodityId: number,
  feedback: string
) =>
  request.patch(INCOMMODITY_API_PREFIX + `/${centerIncommodityId}/scheduled`, {
    feedback,
  });
export const patchIncommodityComplete = (
  centerIncommodityId: number,
  data: FormData
) =>
  request.patch(
    INCOMMODITY_API_PREFIX + `/${centerIncommodityId}/complete`,
    data
  );

export const patchIncommoditySatisfaction = (
  satisfactionReplyId: number,
  comment: string
) =>
  request.patch(
    INCOMMODITY_API_PREFIX + `/satisfaction/reply/${satisfactionReplyId}`,
    { comment }
  );

export const patchIncommodity = (centerIncommodityId: number, data: FormData) =>
  request.patch(INCOMMODITY_API_PREFIX + `/${centerIncommodityId}`, data);

export const getIncommodityVirtualNumber = (centerIncommodityId: number) =>
  request.get(
    INCOMMODITY_API_PREFIX + `/${centerIncommodityId}/creator/virtual-number`
  );
