import React from "react";
import { TextInputType } from "../../../types/search";

type Props = {
  type?: TextInputType;
  placeholder?: string;
  label?: string;
  value?: string;
  checked?: boolean;
  onChange?: (value: string) => void;
  autoComplete?: string;
  disabled?: boolean;
  onKeyPress?: (key: string) => void;
};

export default function LoginInput({
  onChange,
  autoComplete,
  disabled = false,
  type = "text",
  placeholder = "",
  label = "",
  value = "",
  checked = false,
  onKeyPress
}: Props) {
  return (
    <div className="flex flex-col gap-1.5">
      {label && <span className="block text-label">{label}</span>}
      <input
        type={type === "number" ? "text" : type}
        className="w-full border-b-2 border-neutral-15 focus:border-brand-primary-magenta   py-[19px] focus:outline-none"
        placeholder={placeholder}
        value={value}
        checked={checked}
        onChange={(e) => {
          let value = e.target.value;
          if (type === "number") {
            value = e.target.value.replace(/[^0-9]/g, "");
          }
          if (onChange) onChange(value);
        }}
        autoComplete={autoComplete}
        disabled={disabled}
        onKeyUp={ event => onKeyPress && onKeyPress(event.key) }
      />
    </div>
  );
}
