import { TextInputType } from "../../../types/search";
import DefaultInput from "../DefaultInput";

type Props = {
  type?: TextInputType;
  placeholder?: string;
  leftValue?: string;
  rightValue?: string;
  leftLabel?: string;
  rightLabel?: string;
  label?: string;
  unitLabel?: string;
  leftDisabled?: boolean;
  rightDisabled?: boolean;
  onLeftValueChange?: (value: string) => void;
  onRightValueChange?: (value: string) => void;
};

export default function RangeInput({
  type = "text",
  placeholder = "",
  leftValue = "",
  rightValue = "",
  leftLabel = "",
  rightLabel = "",
  unitLabel = "",
  leftDisabled = false,
  rightDisabled = false,
  onLeftValueChange,
  onRightValueChange,
}: Props) {
  return (
    <div className="flex w-full gap-2 items-end">
      <div className="flex flex-col gap-2 ">
        {leftLabel && <span className="block text-label">{leftLabel}</span>}
        <DefaultInput
          type={type}
          value={leftValue}
          unitLabel={unitLabel}
          placeholder={placeholder}
          disabled={leftDisabled}
          onChange={onLeftValueChange}
        />
      </div>
      <span className="flex pb-2">-</span>
      <div className="flex flex-col gap-2 ">
        {rightLabel && <span className="block text-label">{rightLabel}</span>}
        <DefaultInput
          type={type}
          value={rightValue}
          unitLabel={unitLabel}
          placeholder={placeholder}
          disabled={rightDisabled}
          onChange={onRightValueChange}
        />
      </div>
    </div>
  );
}
