import { TERMS_GRADE_TYPES } from "./comboBoxOption";

export type EtcCategory = {
  termsCategoryId: number;
  name: string;
};

export type EtcCategoryItem = {
  termsId: number;
  termsCategoryId: number;
  categoryName: string;
  name: string;
  description: string;
  content: string;
  postAt: string;
  version: string;
  isUse: boolean;
  purpose: string;
  createdAt: string;
  createdBy: number;
  modifiedAt: string;
  modifiedBy: number;
  deletedAt: string;
  deletedBy: number;
};

export type EtcSatisfactionList = {
  average: number;
  satisfaction: EtcSatisfaction[];
};

export type EtcSatisfactionComment = {
  serviceName: string;
  comment: string;
};

export type EtcSatisfaction = {
  etcSatisfactionId: number;
  satisfaction: number;
  comment: string;
  createdAt: string;
  createdBy: number;
  createdByName: string;
  createdByNickName: string;
  createdByEmail: string;
  reply: EtcSatisfaction;
};

export type SatisfactionServiceName =
  | "incommodity"
  | "rental"
  | "space"
  | "cafeteria"
  | "goodsRequest";

export type EtcTerms = {
  termsId: number;
  termsCategoryId: number;
  categoryName: string;
  name: string;
  description: string;
  content: string;
  postAt: string;
  version: string;
  isUse: boolean;
  purpose: string;
  grade: TermsGrade;
  createdAt: string;
  createdBy: number;
  createdByName: string;
  modifiedAt: string;
  modifiedBy: number;
  deletedAt: string;
  deletedBy: number;
};

export type TermsGrade = keyof typeof TERMS_GRADE_TYPES;

export const termsGradeLabels: Record<TermsGrade, string> = {
  MANDATORY: "필수",
  SELECTIVE: "선택",
};
