import { WeekDay } from "../types/space";
import {
  ReservationInfo,
  ReservationLongTerm,
  SpaceReservationData,
} from "../types/spaceReservation";
import { formatDate, getTimeInMinutes } from "../utils/dateUtils";

type DayName = keyof WeekDay;

const RESERVATION_TIME_UNIT = 30; // 공간예약 기본 시간단위
const PREMIUM_RATE = 1.2; // 할증요금 비율

const DEFAULT_DAY_NAMES: DayName[] = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];

export const getLongTermItemList = (
  reservationInfo: Partial<SpaceReservationData>,
  hideLongTerm: boolean = false
): ReservationLongTerm[] => {
  const {
    longTermInfo = reservationInfo.longTermInfo,
    rentalDeviceList = [],
    reservationTimeStart = "",
    reservationTimeEnd = "",
    defaultPriceDays,
    spaceInfo = reservationInfo.spaceInfo,
  } = reservationInfo;

  const {
    longTermReservationDateStart:
      startDate = longTermInfo?.longTermReservationDateStart,
    longTermReservationDateEnd:
      endDate = longTermInfo?.longTermReservationDateEnd,
    longTermReservationDayOfWeek:
      weekDays = longTermInfo?.longTermReservationDayOfWeek,
  } = reservationInfo;

  if (!startDate || !endDate || !weekDays) return [];

  const sTime = getTimeInMinutes(reservationTimeStart);
  const eTime = getTimeInMinutes(reservationTimeEnd);
  const totalTime = (eTime - sTime) / RESERVATION_TIME_UNIT;

  const devicePrice = rentalDeviceList
    .filter((item) => item.checked)
    .reduce((acc, item) => acc + (item.price ?? 0) * totalTime, 0);

  const createReservation = (date: Date): ReservationLongTerm => {
    const dayName = DEFAULT_DAY_NAMES[date.getDay()];
    const isDefaultPrice =
      defaultPriceDays?.[dayName] || spaceInfo?.defaultPriceDays?.[dayName];
    const spacePrice = calculatePrice(reservationInfo, !isDefaultPrice);
    const formattedDate = formatDate(date, "yyyy-MM-dd");

    return {
      reservationDate: formattedDate,
      totalDeviceDefaultPrice: devicePrice.toString(),
      totalSpaceDefaultPrice: spacePrice.toString(),
      vatDefaultPrice: Math.round((devicePrice + spacePrice) * 0.1).toString(),
    };
  };

  if (hideLongTerm) {
    return (
      longTermInfo?.reservationList?.map((item) => {
        const date = new Date(item.reservationDate);
        const reservation = createReservation(date);
        reservation.spaceReservationDetailId = item.spaceReservationDetailId;
        return reservation;
      }) || []
    );
  } else {
    const result: ReservationLongTerm[] = [];
    for (
      let day = new Date(startDate);
      day <= new Date(endDate);
      day.setDate(day.getDate() + 1)
    ) {
      if (weekDays[DEFAULT_DAY_NAMES[day.getDay()]]) {
        result.push(createReservation(day));
      }
    }
    return result;
  }
};

const calculatePrice = (
  reservationInfo: Partial<SpaceReservationData>,
  isPremiumAllDay: boolean = false
) => {
  const spaceInfo = reservationInfo.spaceInfo;
  const sDate = reservationInfo.reservationTimeStart ?? "";
  const eDate = reservationInfo.reservationTimeEnd ?? "";
  const defaultSDate =
    reservationInfo.defaultPriceTimeStart ??
    spaceInfo?.defaultPriceTimeStart ??
    "";
  const defaultEDate =
    reservationInfo.defaultPriceTimeEnd ?? spaceInfo?.defaultPriceTimeEnd ?? "";
  const usableTime =
    reservationInfo.usableTimeStart ?? spaceInfo?.usableTimeStart ?? 0;
  const defaultPrice =
    reservationInfo.defaultPrice ?? spaceInfo?.defaultPrice ?? 0;
  const additionalPrice =
    reservationInfo.additionalPrice ?? spaceInfo?.additionalPrice ?? 0;

  const sTime = getTimeInMinutes(sDate);
  const eTime = getTimeInMinutes(eDate);
  const defaultSTime = getTimeInMinutes(defaultSDate);
  const defaultETime = getTimeInMinutes(defaultEDate);

  let defaultTime = 0;
  let additionalTime = 0;
  let premiumTime = 0;
  let premiumDefaultTime = 0;

  for (let time = sTime; time < eTime; time += RESERVATION_TIME_UNIT) {
    if (!isPremiumAllDay && time >= defaultSTime && time < defaultETime) {
      if (defaultTime + premiumDefaultTime + additionalTime < usableTime) {
        defaultTime += RESERVATION_TIME_UNIT;
      } else {
        additionalTime += RESERVATION_TIME_UNIT;
      }
    } else {
      if (defaultTime + premiumDefaultTime + premiumTime < usableTime) {
        premiumDefaultTime += RESERVATION_TIME_UNIT;
      } else {
        premiumTime += RESERVATION_TIME_UNIT;
      }
    }
  }

  const defaultCost = (defaultTime / RESERVATION_TIME_UNIT) * defaultPrice;
  const additionalCost =
    (additionalTime / RESERVATION_TIME_UNIT) * additionalPrice;
  const premiumDefaultCost =
    (premiumDefaultTime / RESERVATION_TIME_UNIT) * defaultPrice * PREMIUM_RATE;
  const premiumCost =
    (premiumTime / RESERVATION_TIME_UNIT) * additionalPrice * PREMIUM_RATE;

  const totalCost =
    defaultCost + additionalCost + premiumCost + premiumDefaultCost;

  return totalCost;
};

// 가능한 시간 범위 + 시간밖의 예약범위로 출력 필요한 시간 범위 획득
export const getPrintTimeRange = (
  availableTimeRange: string[],
  reservationList: ReservationInfo[]
) => {
  let printTimeRange = availableTimeRange;

  reservationList.forEach((reservation) => {
    const startTime = getTimeInMinutes(reservation.reservationTimeStart);
    const endTime = getTimeInMinutes(reservation.reservationTimeEnd);

    if (getTimeInMinutes(printTimeRange[0]) > startTime) {
      printTimeRange[0] = reservation.reservationTimeStart;
    }
    if (getTimeInMinutes(printTimeRange[1]) < endTime) {
      printTimeRange[1] = reservation.reservationTimeEnd;
    }
  });

  return printTimeRange;
};

export const isDisabledCheckbox = (
  printTimeRange: string[],
  availableTimeRange: string[],
  index: number,
  maxLength: number,
  useBlockTime: boolean = false,
  blockTimeStart?: string,
  blockTimeEnd?: string
) => {
  const currentTime = getTimeInMinutes(printTimeRange[0]) + index * 30;

  if (
    useBlockTime && 
    blockTimeStart && 
    blockTimeEnd &&
    currentTime >= getTimeInMinutes(blockTimeStart) &&
    currentTime < getTimeInMinutes(blockTimeEnd)
  ) {
    return true;
  }

  if (currentTime < getTimeInMinutes(availableTimeRange[0])) {
    return true;
  } else if (
    getTimeInMinutes(printTimeRange[1]) - (maxLength - index - 1) * 30 >
    getTimeInMinutes(availableTimeRange[1])
  ) {
    return true;
  }
  return false;
};
