import { HttpStatusCode } from "axios";
import { useContext, useEffect, useState } from "react";

import { ComboBoxType } from "../../../../../../types/search";
import {
  TAG_ID_REGEX,
  TAG_ID_REGEX_COMMENT,
  Tag,
} from "../../../../../../types/tag";
import {
  BuildingGroup,
  TagSelectedItem,
  buildingGroupCategoryCodeValue,
} from "../../../../../../types/building";
import { USAGE_OPTIONS } from "../../../../../../types/comboBoxOption";

import {
  validationFunctions,
  validationResultMessage,
} from "../../../../../../libs/validations";
import { toComboBoxType } from "../../../../../../utils/comboBoxUtils";

import { PopupContext } from "../../../../../../contexts/Popup";
import { useModal } from "../../../../../../contexts/Modal";

import {
  getSensorList,
  getTag,
  patchTag,
  putTag,
} from "../../../../../../services/tagService";
import {
  getBuilding,
  getBuildingFloor,
  getTempHumList,
} from "../../../../../../services/buildingService";

import FormHeader from "../../../../../Form/Header";
import FormRow from "../../../../../Form/Row";
import FormRowLabel from "../../../../../Form/RowLabel";
import DefaultInput from "../../../../../Input/DefaultInput";
import TagSingleFilter from "../../../../../TagFilter/TagSingleFilter";
import DefaultSelect from "../../../../../SelectBox/DefaultSelect";
import RadioButtonGroup from "../../../../../Input/RadioButtonGroup";
import DefaultButton from "../../../../../DefaultButton";
import Spinner from "../../../../../Spinner";
import DefaultLabel from "../../../../../Input/DefaultLabel";

type Props = {
  id: number;
};

type OptionsType = {
  floorOptions: ComboBoxType[];
  spaceOptions: ComboBoxType[];
};

export default function TempHumTagDetailView({ id }: Props) {
  const { refreshAndClosePopup } = useContext(PopupContext);
  const { showAlert, showConfirm, handleError } = useModal();
  const [tempHumTag, setTempHumTag] = useState<Partial<Tag>>({
    isActive: true,
  });
  const [options, setOptions] = useState<OptionsType>({
    floorOptions: [],
    spaceOptions: [],
  });
  const [selectedTag, setSelectedTag] = useState<TagSelectedItem>();
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async (id: number) => {
    try {
      setIsLoading(true);

      const tagResponse = await getTag(id);

      if (tagResponse.status === HttpStatusCode.Ok) {
        const data = tagResponse.data;
        setTempHumTag(data);

        if (data.buildingSpaceId) {
          const buildingResponse = await getBuilding(data.buildingId);
          if (buildingResponse.status === HttpStatusCode.Ok) {
            setSelectedTag({
              buildingId: data.buildingId,
              buildingName: buildingResponse.data.buildingName,
            } as BuildingGroup);
          }

          if (data.buildingId) {
            fetchFloor(data.buildingId);
            fetchSpace(data);
          }
        }
      }
    } catch (err: any) {
      handleError(err, "조회");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData(id);
  }, [id]);

  const handleUpdate = async () => {
    const errorMessage = validationResultMessage([
      validationFunctions.required(tempHumTag.buildingId, "건물"),
      validationFunctions.required(tempHumTag.buildingSpaceId, "공간"),
      validationFunctions.required(tempHumTag.tagId, "태그 ID"),
      validationFunctions.matchesRegex(
        tempHumTag.tagId,
        TAG_ID_REGEX,
        "태그 ID",
        TAG_ID_REGEX_COMMENT
      ),
    ]);

    if (errorMessage) {
      showAlert(errorMessage);
    } else {
      showConfirm("수정 하시겠습니까?", updateData);
    }
  };

  const handleDisconnect = () => {
    showConfirm("연결을 해제 하시겠습니까?", async () => {
      try {
        const response = await patchTag(id);
        if (response.status === HttpStatusCode.Ok) {
          showAlert("연결 해제가 적용되었습니다.");
          refreshAndClosePopup();
        }
      } catch (err: any) {
        handleError(err, "연결 해제");
      }
    });
  };

  const updateData = async () => {
    try {
      setIsLoading(true);

      const response = await putTag(tempHumTag);

      if (response.status === HttpStatusCode.Ok) {
        showAlert("수정 되었습니다.");
        refreshAndClosePopup();
      }
    } catch (err: any) {
      handleError(err, "수정");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchFloor = async (buildingId: string) => {
    try {
      setIsLoading(true);

      const response = await getBuildingFloor(buildingId);

      if (response.data) {
        const options = toComboBoxType(
          response.data,
          "buildingFloorId",
          "floorName"
        );

        setOptions((prev) => ({
          ...prev,
          floorOptions: options,
        }));
      }
    } catch (err: any) {
      handleError(err, "층 정보 조회");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchSpace = async (tag: Partial<Tag>) => {
    try {
      setIsLoading(true);

      if (!tag.buildingId) {
        throw new Error("Invalid Parameter");
      }

      const params = {
        buildingId: tag.buildingId,
        buildingFloorId: tag.buildingFloorId,
      };

      const [spaceList, tagList] = await Promise.all([
        getTempHumList(params),
        getSensorList({
          ...params,
          tagGroupTypeId: tag.tagGroupTypeId,
          tagTypeId: tag.tagTypeId,
        }),
      ]);

      if (spaceList.status === HttpStatusCode.Ok) {
        const options = toComboBoxType(
          spaceList.data.items,
          "buildingSpaceId",
          "spaceName"
        );

        setOptions((prev) => ({
          ...prev,
          spaceOptions: options.map((option) => ({
            value: option.value,
            label: option.label,
            disabled: tagList.data.items.some(
              (item) => option.value === item.buildingSpaceId
            ),
          })),
        }));
      } else {
        throw new Error("공간 조회 실패");
      }
    } catch (err: any) {
      handleError(err, "공간 조회");
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange =
    (key: keyof Tag, isBool: boolean = false) =>
    (value: string) => {
      setTempHumTag((prev) => ({
        ...prev,
        [key]: !isBool ? value : value === "true" ? true : false,
      }));
    };

  const handleIdChange = (value: string) => {
    setTempHumTag((prev) => ({
      ...prev,
      buildingId: value,
      buildingFloorId: undefined,
      buildingSpaceId: undefined,
    }));

    fetchFloor(value);
  };

  const handleFloorChange = (value: string) => {
    setTempHumTag((prev) => ({
      ...prev,
      buildingFloorId: value,
      buildingSpaceId: undefined,
    }));

    fetchSpace({
      ...tempHumTag,
      buildingId: tempHumTag.buildingId,
      buildingFloorId: value,
    });
  };

  return (
    <div className="flex flex-col gap-5">
      {isLoading && <Spinner />}
      <div className="flex flex-col min-w-[800px] max-h-[700px] overflow-y-auto">
        <FormHeader title="기본정보" />
        <FormRow>
          <div className="flex gap-2 w-full">
            <FormRowLabel title="등록일시">
              <DefaultLabel text={tempHumTag.createdAt} />
            </FormRowLabel>
            <FormRowLabel title="최종 수정일시">
              <DefaultLabel text={tempHumTag.modifiedAt} />
            </FormRowLabel>
          </div>
        </FormRow>

        <FormHeader title="기준정보" />
        <FormRow>
          <FormRowLabel title="위치" isRequired>
            <div className="flex flex-col gap-1.5">
              <TagSingleFilter
                selectType="building"
                selectedItem={selectedTag}
                onChangeBuilding={(item: BuildingGroup) => {
                  setSelectedTag(item);
                  if (item.buildingId) {
                    handleIdChange(item.buildingId);
                  } else {
                    console.error(
                      "Invalid Building ID while changing building"
                    );
                  }
                }}
                categoryCode={buildingGroupCategoryCodeValue.IOT_DEVICE}
              />
              <DefaultSelect
                value={tempHumTag.buildingFloorId}
                placeholder="층 선택"
                optionList={options.floorOptions}
                onChange={handleFloorChange}
                disabled={
                  tempHumTag.buildingId === undefined ||
                  options.floorOptions.length === 0
                }
              />
            </div>
          </FormRowLabel>
        </FormRow>

        <FormRow>
          <FormRowLabel title="공간" isRequired>
            <DefaultSelect
              value={tempHumTag.buildingSpaceId}
              placeholder="선택"
              optionList={options.spaceOptions}
              onChange={handleChange("buildingSpaceId")}
              disabled={
                tempHumTag.buildingFloorId === undefined ||
                options.spaceOptions.length === 0
              }
            />
          </FormRowLabel>
        </FormRow>

        <FormRow>
          <FormRowLabel title="사용여부">
            <RadioButtonGroup
              value={tempHumTag.isActive?.toString()}
              options={USAGE_OPTIONS}
              onChange={handleChange("isActive", true)}
            />
          </FormRowLabel>
        </FormRow>

        <FormHeader title="태그정보" />
        <FormRow>
          <FormRowLabel title="센서 유형">
            <DefaultLabel text={tempHumTag.tagGroupTypeName} />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="태그 유형">
            <DefaultLabel text={tempHumTag.tagTypeName} />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="태그 ID" isRequired>
            <DefaultInput
              value={tempHumTag.tagId}
              onChange={handleChange("tagId")}
              maxLength={20}
              placeholder="태그 ID를 입력해주세요"
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="태그명" isRequired>
            <DefaultInput
              value={tempHumTag.tagName}
              onChange={handleChange("tagName")}
              minWidth="w-full"
              maxLength={200}
              placeholder="태그명을 입력해주세요"
            />
          </FormRowLabel>
        </FormRow>
      </div>

      <div className="flex justify-center gap-2">
        {tempHumTag.isConnected && (
          <DefaultButton onClick={handleDisconnect}>
            공간 연결 해제
          </DefaultButton>
        )}
        <DefaultButton onClick={handleUpdate} color="primary">
          수정
        </DefaultButton>
      </div>
    </div>
  );
}
