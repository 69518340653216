export type Parcel = {
  kioskName: string;
  receiveNo: string;
  receiveCount: string;
  serviceName: string;
  postType: string;
  receiveType: string;
  deliveryType: string;
  sendName: string;
  sendMobile: string;
  email: string;
  companyName: string;
  payType: string;
  payYN: string;
  totalPrice: string;
  receiveDate: string;
  receiveTime: string;
  pickUpYN: string;
  pickUpDate: string;
  pickUpTime: string;
  cancelYN: string;
  cancelDate: string;
  cancelTime: string;
  receipt: string;
  pudoCode: string;
  receiptStart: string;
  receiptEnd: string;
};

export type ParcelBuilding = {
  buildingName: string;
  buildingId: string;
  pudoCode: string;
};

export type ParcelList = {
  rtn_msg: string;
  data_single: number;
  data_list: Parcel[];
  error_code: string;
  error_msg: string;
};

export type GoodsRequest = {
  goodsServiceId: string;
  buildingId: string;
  buildingName: string;
  companyId: string;
  companyName: string;
  status: GoodsRequestStatus;
  goodsRequestStatus: string;
  goodsRequestType: string;
  requestType: GoodsRequestType;
  requestBuildingFloorId: string;
  requestBuildingFloorName: string;
  usedDepartment: string;
  extraRequest: string;
  goodsServiceItemList: GoodsServiceItem[];
  createdBy: number;
  createdByName: string;
  createdAt: string;
  modifiedBy: number;
  modifiedByName: string;
  modifiedAt: string;
  canceledBy: number;
  canceledByName: string;
  canceledAt: string;
  completedBy: number;
  completedByName: string;
  completedAt: string;
  adminNotes: string;
  isSatisfactionCompleted: boolean;
  goodsName: string;
  goodsId: string;
  tagFilter: string;
};

export type GoodsServiceItem = {
  goodsServiceItemId: number;
  goodsId: string;
  goodsName: string;
  goodsDescription: string;
  requestCount: number;
};

export type GoodsRequestType = "REFILL" | "PICK_UP";

export type GoodsRequestTypeInfo = {
  requestName: string;
  location: string;
};

export const goodsRequestTypeValue: Record<
  GoodsRequestType,
  GoodsRequestTypeInfo
> = {
  REFILL: { requestName: "리필", location: "OA실" },
  PICK_UP: { requestName: "요청", location: "방문수령" },
};

export type GoodsRequestStatus = "COMPLETED" | "CANCELED" | "REQUESTED";

export const goodsRequestStatusValue: Record<GoodsRequestStatus, string> = {
  COMPLETED: "처리 완료",
  CANCELED: "취소",
  REQUESTED: "예약",
};
