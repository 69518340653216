import { HttpStatusCode } from "axios";
import { useContext, useState } from "react";
import { useModal } from "../../../../../contexts/Modal";
import { PopupContext } from "../../../../../contexts/Popup";
import {
  validationFunctions,
  validationResultMessage,
} from "../../../../../libs/validations";
import { postGoodsRentalStock } from "../../../../../services/goodsService";
import { TagSelectedItem } from "../../../../../types/building";
import { GoodsRentalItem, GoodsRentalStock } from "../../../../../types/goods";
import {
  USAGE_OPTIONS,
  BOOLEAN_TYPES,
} from "../../../../../types/comboBoxOption";
import DefaultButton from "../../../../DefaultButton";
import FormHeader from "../../../../Form/Header";
import FormRow from "../../../../Form/Row";
import FormRowLabel from "../../../../Form/RowLabel";
import DefaultInput from "../../../../Input/DefaultInput";
import DefaultLabel from "../../../../Input/DefaultLabel";
import RadioButtonGroup from "../../../../Input/RadioButtonGroup";
import TagSingleFilter from "../../../../TagFilter/TagSingleFilter";
import GoodsRentalStockSelectGoods from "../SelectGoods";
import { Store } from "../../../../../types/store";

const GOODS_RENTAL_STOCK_SERVICE_NAME = "대여";

type Props = {};

export default function GoodsRentalStockCreateForm(props: Props) {
  const { showAlert, showConfirm, handleError } = useModal();
  const { closePopup, refreshAndClosePopup } = useContext(PopupContext);

  const [selectedTag, setSelectedTag] = useState<TagSelectedItem>();
  const [goods, setGoods] = useState<Partial<GoodsRentalStock>>({
    useStock: false,
    isActive: false,
  });
  const [selectedGoods, setSelectedGoods] = useState<GoodsRentalItem>();

  const handleGoodsChange =
    (key: keyof GoodsRentalStock) => (value: string | boolean | File) => {
      setGoods((prev) => ({ ...prev, [key]: value }));
    };

  const handleGoodsChangeObject = (data: Partial<GoodsRentalStock>) => {
    setGoods((prev) => ({ ...prev, ...data }));
  };

  const handlePost = () => {
    const validations = [
      validationFunctions.required(selectedTag, "상점"),
      validationFunctions.required(selectedGoods, "상세물품 선택"),
    ];

    const errorMessage = validationResultMessage(validations);
    if (errorMessage) {
      showAlert(errorMessage);
    } else {
      showConfirm("등록 하시겠습니까?", postData);
    }
  };

  const postData = async () => {
    const data: Partial<GoodsRentalStock> = {
      ...(selectedTag && { storeId: (selectedTag as Store).storeId }),
      ...(selectedGoods && { goodsId: selectedGoods.goodsId }),
      useStock: goods.useStock,
      ...(goods.useStock && { stockCount: goods.stockCount }),
      isActive: goods.isActive,
    };

    try {
      const res = await postGoodsRentalStock(data);
      if (res.status === HttpStatusCode.Ok) {
        showAlert("등록 되었습니다.");
        refreshAndClosePopup();
      }
    } catch (err: any) {
      handleError(err, "등록");
    }
  };

  return (
    <div className="flex flex-col gap-5 min-w-[900px]">
      <div className="flex flex-col max-h-[700px] overflow-y-auto">
        <FormHeader title="기준 정보" />
        <FormRow>
          <FormRowLabel title="서비스">
            <DefaultLabel text={GOODS_RENTAL_STOCK_SERVICE_NAME} />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="상점" isRequired>
            <TagSingleFilter
              selectType="store"
              selectedItem={selectedTag}
              onChangeStore={setSelectedTag}
            />
          </FormRowLabel>
        </FormRow>

        <FormHeader title="물품 정보" />
        <FormRow>
          <FormRowLabel title="상세물품 선택" isRequired>
            <GoodsRentalStockSelectGoods
              selectedItem={selectedGoods}
              onSelectedItem={setSelectedGoods}
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="재고 유무">
            <div className="flex flex-col gap-2">
              <RadioButtonGroup
                value={goods.useStock?.toString()}
                options={USAGE_OPTIONS}
                onChange={(value: string) =>
                  handleGoodsChangeObject({
                    useStock: value === BOOLEAN_TYPES.TRUE,
                    stockCount: value === BOOLEAN_TYPES.TRUE ? 1 : undefined,
                  })
                }
              />
              {goods.useStock && (
                <DefaultInput
                  type="number"
                  value={goods.stockCount?.toString()}
                  onChange={(value: string) =>
                    handleGoodsChangeObject({ stockCount: Number(value) })
                  }
                  unitLabel="개"
                />
              )}
            </div>
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="사용 여부">
            <RadioButtonGroup
              options={USAGE_OPTIONS}
              value={goods.isActive?.toString()}
              onChange={(value: string) =>
                handleGoodsChange("isActive")(value === BOOLEAN_TYPES.TRUE)
              }
            />
          </FormRowLabel>
        </FormRow>
      </div>

      <div className="flex justify-center gap-2">
        <DefaultButton onClick={closePopup}>닫기</DefaultButton>
        <DefaultButton color="primary" onClick={handlePost}>
          등록
        </DefaultButton>
      </div>
    </div>
  );
}
