import { useEffect, useState } from "react";
import { useModal } from "../../../contexts/Modal";
import {
  getBuildingFloor,
  getBuildingGroupList,
} from "../../../services/buildingService";
import { ComboBoxType, DataLoadedComponentEnum } from "../../../types/search";
import { toComboBoxType } from "../../../utils/comboBoxUtils";
import Spinner from "../../Spinner";
import DefaultSelect from "../DefaultSelect";
import { HttpStatusCode } from "axios";
import { useUserContext } from "../../../contexts/User";
import { DEFAULT_SELECT_ALL_VALUE } from "../../../types/comboBoxOption";
import SearchAndSelect from "../SearchAndSelect";

export type BuildingAndFloor = {
  buildingId?: string;
  buildingFloorId?: string;
};

type Props = {
  value?: BuildingAndFloor;
  showAllOption?: boolean;
  categoryCode?: string;
  onChange?: (value: BuildingAndFloor) => void;
  onInitialDataLoaded?: (componentKey: DataLoadedComponentEnum) => void;
};

export default function BuildingAndFloorSelect({
  value,
  showAllOption = true,
  categoryCode,
  onChange,
  onInitialDataLoaded,
}: Props) {
  const { getCategoryCodeByRole } = useUserContext();
  const { showAlert, handleError } = useModal();
  const [buildingOptions, setBuildingOptions] = useState<ComboBoxType[]>([]);
  const [buildingFloorOptions, setBuildingFloorOptions] = useState<
    ComboBoxType[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!value) {
      if (!showAllOption && buildingOptions.length > 0) {
        handleChange(buildingOptions[0].value);
      }
      setBuildingFloorOptions([]);
    }
  }, [value]);

  useEffect(() => {
    const fetchData = async () => {
      if (!categoryCode) {
        showAlert(
          "카테고리 코드 값이 존재하지 않습니다. 관리자에게 문의하세요."
        );
        return;
      }

      try {
        setIsLoading(true);

        const response = await getBuildingGroupList({
          categoryCode: getCategoryCodeByRole(categoryCode),
        });

        if (response.status === HttpStatusCode.Ok) {
          const options = toComboBoxType(
            response.data,
            "buildingId",
            "buildingName"
          );
          setBuildingOptions(options);

          if (!showAllOption && options.length > 0) {
            handleChange(options[0].value);
          }
          onInitialDataLoaded &&
            onInitialDataLoaded(DataLoadedComponentEnum.BuildingAndFloor);
        } else {
          // nothing to do
        }
      } catch (err: any) {
        handleError(err, "건물 조회");
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const fetchFloorData = async (buildingId: string) => {
    if (!buildingId) {
      showAlert("건물을 선택해주세요.");
      return;
    }

    try {
      setIsLoading(true);

      const response = await getBuildingFloor(buildingId);

      if (response.status === HttpStatusCode.Ok) {
        const options = toComboBoxType(
          response.data,
          "buildingFloorId",
          "floorName"
        );
        setBuildingFloorOptions(options);
      } else {
        // nothing to do
      }
    } catch (err: any) {
      handleError(err, "건물 층 정보 조회");
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (buildingId: string) => {
    if (onChange) {
      onChange({ buildingId });
    }

    if (buildingId === DEFAULT_SELECT_ALL_VALUE) {
      setBuildingFloorOptions([]);
    } else {
      fetchFloorData(buildingId);
    }
  };

  const handleFloorChange = (buildingFloorId: string) => {
    if (onChange) {
      onChange({ ...value, buildingFloorId });
    }
  };

  return (
    <div className="flex gap-1.5">
      {isLoading && <Spinner />}
      <SearchAndSelect
        label="건물명"
        value={value?.buildingId}
        onChange={handleChange}
        optionList={buildingOptions}
        placeholder="전체"
      />
      <DefaultSelect
        label="층명"
        value={value?.buildingFloorId}
        onChange={handleFloorChange}
        optionList={buildingFloorOptions}
        placeholder="전체"
      />
    </div>
  );
}
