import { FiEdit2 } from "react-icons/fi";
import DefaultButton from "../../../components/DefaultButton";
import ListPage from "../../../components/ListPage";
import { TableColumnType } from "../../../components/Table";
import { getAppVersionList } from "../../../services/systemService";
import { AppVersion } from "../../../types/system";
import AppVersionListTopRight from "../../../components/PageComponents/System/AppVersionList/ListTopRight";
import { ComponentType } from "../../../types/search";
import { addDefaultOption } from "../../../utils/comboBoxUtils";
import AppVersionDetailView from "../../../components/PageComponents/System/AppVersionList/DetailView";
import { OS_OPTIONS } from "../../../types/comboBoxOption";

type Props = {};

export default function AppVersionList(props: Props) {
  const columnInfo: TableColumnType<AppVersion>[] = [
    { header: "No.", defaultNo: true, width: "no" },
    { header: "앱 OS", name: "osType", width: "100" },
    { header: "버전", name: "version" },
    { header: "개선 내용", name: "releaseNote" },
    { header: "관리자 Email", name: "createdByEmail" },
    { header: "등록자 이름", name: "createdByName" },
    { header: "등록일시", name: "createdAt", width: "date" },
    {
      header: "활동",
      render: (item: AppVersion, handler) => (
        <div className="w-full h-full flex gap-2 justify-center items-center">
          <DefaultButton
            size="tableIcon"
            color="blue"
            onClick={() => {
              if (handler) {
                handler.popup({
                  header: "앱 버전 상세",
                  content: (
                    <AppVersionDetailView appVersionId={item.appVersionId} />
                  ),
                });
              }
            }}
          >
            <FiEdit2 />
          </DefaultButton>
        </div>
      ),
      width: "icon",
    },
  ];

  const componentList: ComponentType<AppVersion>[] = [
    {
      labelName: "등록일자",
      typeName: "dateRange",
      keyName: "createdAt",
      category: "date",
    },
    {
      labelName: "앱 OS",
      typeName: "comboBox",
      keyName: "osTypeEnum",
      category: "detailSearch",
      comboBoxOptions: addDefaultOption(OS_OPTIONS),
    },
    {
      labelName: "관리자 Email",
      typeName: "text",
      keyName: "createdByEmail",
      category: "detailSearch",
      placeholder: "Email을 입력해주세요",
    },
  ];

  return (
    <ListPage
      getDataApi={getAppVersionList}
      keyId={(item: AppVersion) => item.appVersionId}
      columnInfo={columnInfo}
      componentList={componentList}
      tableTitle="앱 버전 목록"
      renderTopRight={<AppVersionListTopRight />}
    />
  );
}
