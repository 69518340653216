import { useState } from "react";
import {
  Service,
  ServiceDetailType,
  ServiceType,
} from "../../../types/service";
import DefaultButton from "../../DefaultButton";
import TreeList from "../../TreeView/TreeList";
import ServiceDetailView from "../ServiceDetailView";
import { HttpStatusCode } from "axios";
import { useModal } from "../../../contexts/Modal";
import CommonPopup from "../../Popup/CommonPopup";
import TemplateServiceSelector from "../TemplateServiceSelector";
import DefaultInput from "../../Input/DefaultInput";
import PermissionWrapper from "../../PermissionWrapper";

type Props = {
  id: string;
  data: { [key: string]: Service[] };
  templateData: Service[];
  serviceType: ServiceType;
  refreshService: () => Promise<void>;
  patchService: (buildingId: string, data: Partial<Service>[]) => Promise<any>;
  getService: (id: string, serviceId: string) => Promise<any>;
  createService: (id: string, service: Partial<Service>) => Promise<any>;
  updateService: (
    id: string,
    serviceId: string,
    service: Partial<Service>
  ) => Promise<any>;
};

export default function ServiceForm({
  id,
  data,
  templateData,
  serviceType,
  refreshService,
  patchService,
  getService,
  createService,
  updateService,
}: Props) {
  const { showAlert, showConfirm, handleError } = useModal();
  const [services, setServices] = useState<{
    [serviceId: string]: Service;
  }>({});
  const [currentService, setCurrentService] = useState<Service>();
  const [selected, setSelected] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClosePopup = () => {
    setIsOpen(false);
  };

  const handleServiceChange = (service: Service) => {
    setServices((prevSortOrders) => ({
      ...prevSortOrders,
      [service.serviceId]: service,
    }));
  };

  const handleSelectedChange = (detailType: string) => {
    setSelected(detailType);
  };

  const getSortOrderData = () => {
    const data: Partial<Service>[] = [];

    for (const [serviceId, service] of Object.entries(services)) {
      data.push({
        serviceId: serviceId,
        sortOrder: service.sortOrder,
        isActive: service.isActive,
      });
    }

    return data;
  };

  const updateData = async () => {
    try {
      const response = await patchService(id, getSortOrderData());

      if (response.status === HttpStatusCode.Ok) {
        showAlert("수정 되었습니다.");
        refreshService();
      } else {
        throw new Error("Service active patch error");
      }
    } catch (err: any) {
      handleError(err, "수정");
    }
  };

  const handleUpdate = () => {
    if (Object.keys(services).length === 0) {
      showAlert("수정된 항목이 없습니다.");
    } else {
      showConfirm("수정 하시겠습니까?", updateData);
    }
  };

  const getServiceDetailType = (): ServiceDetailType | undefined => {
    if (!currentService) return undefined;

    const { serviceType, target } = currentService;
    if (serviceType === "MANAGER" || target === "COMPANY") {
      return "nameIcon";
    } else if (serviceType === "VOC") {
      return "name";
    } else {
      return "nameIconAuth";
    }
  };

  const renderTemplateService = () => {
    if (templateData.length > 0) {
      return templateData.map((template, index) => {
        return (
          <div
            key={index}
            className="flex justify-between text-sm items-center p-2.5 h-[46px] bg-white text-black"
          >
            {template.serviceName}
            <div className="flex gap-5 items-center w-[90px]">
              <DefaultInput
                type="number"
                className="text-center h-6 text-sm"
                minWidth="min-w-[90px]"
                value={
                  services[template.serviceId]?.sortOrder?.toString() ??
                  template.sortOrder?.toString()
                }
                onChange={(value: string) => {
                  const sortOrder = Number(value);
                  if (sortOrder === template.sortOrder) {
                    setServices((prevServices) => {
                      const updatedServices = { ...prevServices };
                      delete updatedServices[template.serviceId];
                      return updatedServices;
                    });
                  } else {
                    handleServiceChange({
                      ...template,
                      isActive: true,
                      sortOrder,
                    });
                  }
                }}
                maxLength={5}
                hideMaxLength
              />
            </div>
          </div>
        );
      });
    } else {
      return (
        <div className="flex items-center p-2.5 h-[36px] text-xs text-brand-summary-gray font-medium">
          추가 템플릿이 없습니다
        </div>
      );
    }
  };

  return (
    <div className="flex gap-4 min-h-[65vh] px-[15px] py-[10px]">
      <div className="flex flex-col min-w-[25vw]">
        <div className="flex justify-between items-center border w-full p-2.5 pr-5 text-white text-sm bg-opacity-80 font-bold bg-brand-primary-gray-100">
          <span className="text-sm font-bold pl-2.5">템플릿</span>
          <DefaultButton
            size="none"
            className="py-1 px-2 h-[22px] text-xs font-normal flex items-center"
            color="white"
            onClick={handleOpen}
          >
            템플릿 서비스 관리
          </DefaultButton>
        </div>
        {
          <div className="flex bg-[#F0F0F0] min-h-[56px] max-h-[164px] p-2.5 items-start justify-center overflow-y-auto">
            <div className="flex flex-col gap-0.5 justify-center w-full">
              {renderTemplateService()}
            </div>
          </div>
        }

        <div className="flex flex-col border h-full max-h-popup overflow-y-auto">
          <TreeList
            data={data}
            services={services}
            selected={selected}
            onContentChange={setCurrentService}
            onServiceChange={handleServiceChange}
            onSelectedChange={handleSelectedChange}
          />
        </div>
        <PermissionWrapper>
          <div className="flex pt-2 pb-2 justify-center border bg-gray-200">
            <DefaultButton
              size="popupDefault"
              color="primary"
              onClick={() => {
                handleUpdate();
              }}
            >
              사용여부 및 순서 수정
            </DefaultButton>
          </div>
        </PermissionWrapper>
      </div>
      <div className="flex flex-col min-w-[700px]">
        <div className="flex items-center border text-white text-sm w-full p-2.5 pl-5 font-bold bg-brand-primary-gray-100 bg-opacity-80">
          서비스 상세 입력
        </div>
        <div className="border bg-brand-default-gray-2 p-3 h-full">
          <ServiceDetailView
            serviceType={serviceType}
            id={id}
            defaultItem={currentService}
            serviceDetailType={getServiceDetailType()}
            refreshService={refreshService}
            getService={getService}
            createService={createService}
            updateService={updateService}
          />
        </div>
      </div>
      {isOpen && (
        <CommonPopup
          header="템플릿 등록"
          content={
            <TemplateServiceSelector
              templateService={templateData}
              id={id}
              serviceType={serviceType}
              refreshService={refreshService}
              closePopup={handleClosePopup}
            />
          }
          isOpen={isOpen}
          closePopup={handleClosePopup}
        />
      )}
    </div>
  );
}
