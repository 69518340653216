import { useEffect, useState } from "react";
import { HttpStatusCode } from "axios";

import {
  CategoryData,
  CategoryReturnField,
  StoreCategory,
} from "../../../types/category";
import { ComponentType } from "../../../types/search";
import { Store } from "../../../types/store";
import {
  USAGE_OPTIONS,
  CATEGORY_LEVEL_LABELS,
  usageYnLabels,
} from "../../../types/comboBoxOption";

import { addDefaultOption } from "../../../utils/comboBoxUtils";
import { transformCategoryData } from "../../../libs/category";

import { useModal } from "../../../contexts/Modal";

import {
  getStoreBulkExcelForm,
  getStoreCategoryList,
  getStoreList,
  getStoreListExcel,
  postStoreBulkExcel,
} from "../../../services/storeService";

import DefaultButton from "../../../components/DefaultButton";
import ListPage from "../../../components/ListPage";
import Spinner from "../../../components/Spinner";
import StoreListTopRight from "../../../components/Store/StoreList/ListTopRight";
import { TableColumnType } from "../../../components/Table";
import StoreDetailView from "../../../components/Store/StoreList/DetailView";
import ExcelFileUploaderButton from "../../../components/FileUploader/ExcelFileUploaderButton";
import DefaultLabel from "../../../components/Input/DefaultLabel";

import { FiEye } from "react-icons/fi";

type Props = {};

export default function StoreList(props: Props) {
  const { handleError } = useModal();
  const [categoryData, setCategoryData] = useState<CategoryData[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const columnInfo: TableColumnType<Store>[] = [
    { header: "상점 ID", name: "storeId", width: "id" },
    { header: "상점 유형", name: "upperCategoryName" },
    { header: "상세 유형", name: "midCategoryName" },
    { header: "상점명", name: "name" },
    { header: "대표 연락처", name: "tel", width: "tel" },
    { header: "기준 건물명", name: "buildingName" },
    { header: "위치", name: "location" },
    {
      header: "사용여부",
      render: (item: Store) => (
        <div className="flex items-center justify-center">
          <DefaultLabel
            text={item.isActive ? usageYnLabels.TRUE : usageYnLabels.FALSE}
            color={item.isActive ? "blue" : "gray"}
          />
        </div>
      ),
      width: "yesOrNo",
    },
    {
      header: "활동",
      render: (item: Store, handler) => (
        <div className="w-full h-full flex gap-2 justify-center items-center">
          <DefaultButton
            size="tableIcon"
            color="blue"
            onClick={() => {
              if (handler?.popup) {
                handler.popup({
                  header: "상점 상세",
                  content: (
                    <StoreDetailView
                      storeId={item.storeId}
                      categoryData={categoryData}
                    />
                  ),
                });
              }
            }}
          >
            <FiEye />
          </DefaultButton>
        </div>
      ),
      width: "icon",
    },
  ];

  useEffect(() => {
    getStoreCategory();
  }, []);

  const getStoreCategory = async () => {
    try {
      setIsLoading(true);

      const response = await getStoreCategoryList();

      if (response.status === HttpStatusCode.Ok) {
        const data: StoreCategory[] = response.data;

        setCategoryData(
          transformCategoryData(
            data,
            "storeCategoryId",
            undefined,
            "storeCategoryName",
            "subCategory",
            undefined,
            undefined
          )
        );
      } else {
        throw new Error("카테고리 정보를 불러오는 중 오류가 발생했습니다.");
      }
    } catch (err: any) {
      handleError(err, "카테고리 조회");
    } finally {
      setIsLoading(false);
    }
  };

  const componentList: ComponentType<Store>[] = [
    {
      labelName: "등록일자",
      typeName: "dateRange",
      keyName: "createdAt",
      category: "date",
    },
    {
      labelName: "상점유형",
      typeName: "categoryComboBox",
      keyName: "storeCategoryId",
      category: "typeSearch",
      categoryData: {
        searchField: CategoryReturnField.ID,
        optionData: categoryData,
        allOptionLabels: CATEGORY_LEVEL_LABELS,
      },
    },
    {
      labelName: "사용여부",
      typeName: "comboBox",
      keyName: "isActive",
      category: "typeSearch",
      comboBoxOptions: addDefaultOption(USAGE_OPTIONS),
    },
    {
      labelName: "상점 ID",
      typeName: "text",
      keyName: "storeId",
      category: "detailSearch",
      placeholder: "상점 ID를 입력해주세요",
    },
    {
      labelName: "상점명",
      typeName: "text",
      keyName: "name",
      category: "detailSearch",
      placeholder: "상점명을 입력해주세요",
    },
    {
      labelName: "위치",
      typeName: "text",
      keyName: "location",
      category: "detailSearch",
      placeholder: "위치를 입력해주세요",
    },
    {
      labelName: "대표 연락처",
      typeName: "text",
      keyName: "tel",
      category: "detailSearch",
      placeholder: "대표 연락처를 입력해주세요",
    },
    {
      labelName: "건물명",
      typeName: "text",
      keyName: "buildingName",
      category: "detailSearch",
      placeholder: "건물명을 입력해주세요",
    },
  ];

  return (
    <>
      {!isLoading && (
        <ListPage
          getDataApi={getStoreList}
          keyId={(item: Store) => item.storeId}
          columnInfo={columnInfo}
          componentList={componentList}
          excelDownloadApi={getStoreListExcel}
          excelUpload={
            <ExcelFileUploaderButton
              title="상점"
              getExcelDataApi={getStoreBulkExcelForm}
              postExcelDataApi={postStoreBulkExcel}
            />
          }
          tableTitle="상점 목록"
          renderTopRight={<StoreListTopRight categoryData={categoryData} />}
        />
      )}
      {isLoading && <Spinner />}
    </>
  );
}
