import { AxiosResponse } from "axios";
import { FileInfo } from "../types/file";

export const getImageByScale = (files: FileInfo[], scale: string) => {
  return files.find((file) => file.scale === scale);
};

export const getImageInfoByScale = (files: FileInfo[], scale: string) => {
  return files.filter((file) => file.scale === scale);
};

export const downloadExcel = (
  res: AxiosResponse,
  fileNameWithExtension?: string
) => {
  const url = window.URL.createObjectURL(res.data);
  const downloadFileName =
    fileNameWithExtension ||
    decodeURIComponent(res.headers["file-name"] ?? "list.xlsx");

  var a = document.createElement("a");
  a.download = downloadFileName;
  a.href = url;
  a.click();

  URL.revokeObjectURL(url);
};
