import { SearchCondition } from "../components/ListPage";
import request, { ListResponse } from "../libs/request";
import { LoggingLogin, LoggingPermission } from "../types/logging";
import { getExcelDownloadApi } from "./commonService";

const LOGGING_API_PREFIX = "/v2/admin/system";

export const getLoggingLoginList = (params: SearchCondition<LoggingLogin>) =>
  request.get<ListResponse<LoggingLogin>>(LOGGING_API_PREFIX + "/login", {
    params,
  });

export const getLoggingLoginListExcel = (
  params: SearchCondition<LoggingLogin>
) => getExcelDownloadApi(LOGGING_API_PREFIX + "/login/excel", params);

export const getLoggingPermissionList = (
  params: SearchCondition<LoggingPermission>
) =>
  request.get<ListResponse<LoggingPermission>>(
    LOGGING_API_PREFIX + "/permission",
    {
      params,
    }
  );

export const getLoggingPermissionListExcel = (
  params: SearchCondition<LoggingPermission>
) => getExcelDownloadApi(LOGGING_API_PREFIX + "/permission/excel", params);
