import BuildingListTopRight from "../../../components/Building/BuildingList/ListTopRight";
import DefaultButton from "../../../components/DefaultButton";
import ListPage from "../../../components/ListPage";
import { TableColumnType } from "../../../components/Table";
import {
  getBuildingList,
  getBuildingListExcel,
} from "../../../services/buildingService";
import { Building, BuildingType, buildingType } from "../../../types/building";
import BuildingServiceForm from "../../../components/Building/BuildingList/ServiceForm";
import { ComboBoxType, ComponentType, DASH } from "../../../types/search";
import BuildingDetailView from "../../../components/Building/BuildingList/DetailView";
import DefaultLabel from "../../../components/Input/DefaultLabel";
import {
  DEFAULT_SELECT_ALL_VALUE,
  usageYnLabels,
} from "../../../types/comboBoxOption";

type Props = {};

export default function BuildingList(props: Props) {
  const columnInfo: TableColumnType<Building>[] = [
    { header: "No.", defaultNo: true, width: "no" },
    { header: "건물 ID", name: "buildingId", width: "id" },
    { header: "건물명", name: "buildingName" },
    {
      header: "사용여부",
      render: (item: Building) => (
        <div className="flex items-center justify-center">
          <DefaultLabel
            text={item.isActive ? usageYnLabels.TRUE : usageYnLabels.FALSE}
            color={item.isActive ? "blue" : "gray"}
          />
        </div>
      ),
      width: "yesOrNo",
    },
    {
      header: "등록유형",
      render: (item: Building) => (
        <div className="flex justify-center items-center">
          {buildingType[item.type as BuildingType] ?? DASH}
        </div>
      ),
      width: "100",
    },
    { header: "건물주소", name: "address" },
    { header: "등록일시", name: "createdAt", width: "date" },
    {
      header: "서비스관리",
      render: (item: Building, handler) => (
        <div className="w-full h-full flex gap-2 justify-center items-center">
          <DefaultButton
            size="tableButton"
            color="blue"
            onClick={() => {
              if (handler?.popup) {
                handler.popup({
                  header: `서비스 관리 - ${item.buildingName ?? ""}`,
                  content: <BuildingServiceForm buildingId={item.buildingId} />,
                });
              }
            }}
          >
            관리
          </DefaultButton>
        </div>
      ),
      width: "button",
    },
    {
      header: "정보관리",
      render: (item: Building, handler) => (
        <div className="w-full h-full flex gap-2 justify-center items-center">
          <DefaultButton
            size="tableButton"
            color="blue"
            onClick={() => {
              if (handler?.popup) {
                handler.popup({
                  header: "정보관리",
                  content: <BuildingDetailView buildingId={item.buildingId} />,
                });
              }
            }}
          >
            관리
          </DefaultButton>
        </div>
      ),
      width: "button",
    },
  ];

  const comboboxIsUse: ComboBoxType[] = [
    { value: DEFAULT_SELECT_ALL_VALUE, label: "전체" },
    { value: "true", label: "사용" },
    { value: "false", label: "미사용" },
  ];

  const comboboxRegType: ComboBoxType[] = [
    { value: DEFAULT_SELECT_ALL_VALUE, label: "전체" },
    { value: "NORMAL", label: "일반등록건물" },
    { value: "ATG", label: "엣지등록건물" },
  ];

  const componentList: ComponentType<Building>[] = [
    {
      labelName: "등록일자",
      typeName: "dateRange",
      keyName: "createdAt",
      category: "date",
    },
    {
      labelName: "사용여부",
      typeName: "comboBox",
      keyName: "isActive",
      comboBoxOptions: comboboxIsUse,
      category: "typeSearch",
    },
    {
      labelName: "등록유형",
      typeName: "comboBox",
      keyName: "type",
      comboBoxOptions: comboboxRegType,
      category: "typeSearch",
    },
    {
      labelName: "건물명",
      typeName: "text",
      keyName: "buildingName",
      category: "detailSearch",
      placeholder: "건물명을 입력해주세요",
    },
    {
      labelName: "건물ID",
      typeName: "text",
      keyName: "buildingId",
      category: "detailSearch",
      placeholder: "건물ID를 입력해주세요",
    },
  ];

  return (
    <ListPage
      getDataApi={getBuildingList}
      excelDownloadApi={getBuildingListExcel}
      keyId={(item: Building) => item.buildingId}
      columnInfo={columnInfo}
      componentList={componentList}
      tableTitle="건물목록 전체"
      renderTopRight={<BuildingListTopRight />}
    />
  );
}
