type Props = {};

export default function ManagementMenuIcon(props: Props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.6">
        <path
          d="M15.5 16.75L8.5 16.75M15.5 13.75H14.5M12.5 13.75H11.5M9.5 13.75H8.5M15.5 11.75H14.5M12.5 11.75H11.5M9.5 11.75H8.5M12 9.75V5.25M18 9.75L6 9.75C5.44772 9.75 5 10.1977 5 10.75L5 17.75C5 18.3023 5.44772 18.75 6 18.75L18 18.75C18.5523 18.75 19 18.3023 19 17.75V10.75C19 10.1977 18.5523 9.75 18 9.75Z"
          stroke="white"
        />
      </g>
    </svg>
  );
}
