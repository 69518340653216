import clsx from "clsx";
import { ComboBoxType } from "../../../types/search";
import RequiredIcon from "../../../svgs/icons/Common/Required";
import { DEFAULT_SELECT_ALL_VALUE } from "../../../types/comboBoxOption";

type Props = {
  value?: string | null;
  onChange?: (value: string) => void;
  optionList?: ComboBoxType[];
  placeholder?: string;
  label?: string;
  disabled?: boolean;
  placeholderDisabled?: boolean;
  showAllOption?: boolean;
  width?: string;
  required?: boolean;
  dataTestId?: string;
};

export default function DefaultSelect({
  value,
  onChange,
  width,
  dataTestId,
  optionList = [],
  placeholder = "",
  label = "",
  disabled = false,
  placeholderDisabled = false,
  showAllOption = true,
  required = false,
}: Props) {
  const selectedValue = value ? value : DEFAULT_SELECT_ALL_VALUE;

  const getSelectClassName = clsx(
    "truncate h-[38px] border border-gray-200 pl-3 pr-1.5 py-[9px] text-label",
    width || "w-[200px]"
  );

  return (
    <div className="flex flex-col gap-1.5 min-w-fit">
      {label && (
        <div className="flex items-center">
          <span className="text-label">{label}</span>
          {required && <RequiredIcon />}
        </div>
      )}
      <select
        className={getSelectClassName}
        value={selectedValue}
        disabled={disabled}
        onChange={(e) => {
          if (onChange) onChange(e.target.value);
        }}
        data-testid={`${dataTestId}-default-select`}
      >
        {showAllOption && placeholder && (
          <option
            value={DEFAULT_SELECT_ALL_VALUE}
            disabled={placeholderDisabled}
          >
            {placeholder}
          </option>
        )}
        {optionList.map((item, index) => (
          <option
            key={index}
            value={item.value}
            disabled={item.disabled}
            data-testid={`${dataTestId}-default-select-option`}
          >
            {item.label}
          </option>
        ))}
      </select>
    </div>
  );
}
