import { ReactNode } from "react";

type Props = {
  title: string;
  right?: ReactNode;
};

export default function FormHeader({ title, right }: Props) {
  return (
    <>
      <div className="flex items-center justify-between h-[70px] px-5 py-6 text-xl font-semibold border-b border-neutral-15">
        <div className="flex items-center">
          <span>{title}</span>
        </div>
        <div className="flex items-center">{right}</div>
      </div>
    </>
  );
}
