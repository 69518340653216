import { MEMBER_ROLE_TYPES } from "./comboBoxOption";
import { FileInfo } from "./file";
import { DASH } from "./search";

import { SearchTagData } from "../components/TagFilter";

export type Member = {
  memberId: number;
  email: string;
  referrerEmail: string;
  name: string;
  nickname: string;
  status: string;
  birthday: string;
  createdAt: string;
  role: MemberRole;
  roles?: string[];
  lastLoggedAt: string;
  companyId: string;
  companyName: string;
  buildingId: string;
  buildingName: string;
  buildingFloorId: string;
  buildingFloorName: string;
  isAdvertiseAgreed: boolean;
  gender: string;
  mobileNumber: string;
  deviceType: string;
  appVersion: string;
  isSelfCertificated: boolean;
  isCompanyCertificated: boolean;
  fileInfo: {
    items: FileInfo[];
  };
  profileImage: {
    fileId: string;
    filePath: string;
  };
  tagFilterBuilding?: SearchTagData;
  tagFilterCompany?: SearchTagData;
  appSettings: {
    simpleLogin: boolean;
    maintainLogin: boolean;
  };
  alarm: {
    advertise: boolean;
    talkReply: boolean;
    incommodity: boolean;
    service: boolean;
    rent: boolean;
    reservation: boolean;
    marketing: boolean;
  };
  managerAlarm: {
    restaurant: boolean;
    incommodityCleaning: boolean;
    incommodityFacility: boolean;
    incommodityEtc: boolean;
    service: boolean;
    rent: boolean;
    reservation: boolean;
    monitoring: boolean;
    donSturb: boolean;
    donSturbStart: string;
    donSturbEnd: string;
    incommodityCommon: boolean;
    incommodityManage: boolean;
    incommodityCommunication: boolean;
  };
};

export type MemberSelect = {
  memberId: number;
  name: string;
  mobileNumber: string;
  existence: boolean;
};

export type MemberVirtualNumber = {
  virtualNumber: string;
};

export const ROLE_MANAGER_TYPES = {
  ROLE_MONITORING: "ROLE_MONITORING",
  ROLE_FIELD: "ROLE_FIELD",
  ROLE_MANAGER: "ROLE_MANAGER",
  ROLE_CALL_CENTER: "ROLE_CALL_CENTER",
  ROLE_CAFETERIA: "ROLE_CAFETERIA",
  ROLE_SPACE: "ROLE_SPACE",
  ROLE_FREE_LOCATION: "ROLE_FREE_LOCATION",
};

export const ROLE_TYPES = {
  ROLE_ADMIN: "ROLE_ADMIN",
  ROLE_USER: "ROLE_USER",
  ...ROLE_MANAGER_TYPES,
};

export const ROLE_EMERGENCY_TYPES = {
  ROLE_ADMIN: "ROLE_ADMIN",
  ROLE_USER: "ROLE_USER",
  ROLE_EV_MASTER: "ROLE_EV_MASTER",
  ROLE_EV_COM_ADMIN: "ROLE_EV_COM_ADMIN",
  ROLE_EV_DEP_ADMIN: "ROLE_EV_DEP_ADMIN",
};

export type MemberRole = keyof typeof ROLE_TYPES;
export type EmergencyMemberRole = keyof typeof ROLE_EMERGENCY_TYPES;

export type ServiceManagerRole = keyof typeof ROLE_MANAGER_TYPES;

export const memberRoleLabels: Record<MemberRole, string> = {
  ROLE_ADMIN: "샌디운영자",
  ROLE_USER: "일반 사용자",

  // 샌디 매니저
  ROLE_MONITORING: "모니터링",
  ROLE_FIELD: "현장",
  ROLE_MANAGER: "총무",
  ROLE_CALL_CENTER: "콜센터",
  ROLE_CAFETERIA: "공지",
  ROLE_SPACE: "계약",
  ROLE_FREE_LOCATION: "근무위치",
};

export const emergencyMemberRoleLabels: Record<EmergencyMemberRole, string> = {
  ROLE_ADMIN: "샌디운영자",
  ROLE_USER: "일반 사용자",

  // 비상 대피 관련
  ROLE_EV_MASTER: "비상대피 마스터",
  ROLE_EV_COM_ADMIN: "회사 관리자",
  ROLE_EV_DEP_ADMIN: "부서 관리자",
};

export const serviceManagerRoles: ServiceManagerRole[] = [
  "ROLE_MONITORING",
  "ROLE_FIELD",
  "ROLE_MANAGER",
  "ROLE_CALL_CENTER",
  "ROLE_CAFETERIA",
  "ROLE_SPACE",
];

const hasServiceManagerRole = (roles: string[]) => {
  return roles.some((role) =>
    serviceManagerRoles.includes(role as ServiceManagerRole)
  );
};

export const getMemberRoleName = (role: string): string => {
  if (!role) return DASH;

  const roles = role?.split(",");
  if (hasServiceManagerRole(roles)) {
    return "샌디 매니저";
  }

  const roleNames = roles.map((role) => memberRoleLabels[role as MemberRole]);
  return roleNames.join(", ");
};

export const getMemberRoleType = (role: string): string => {
  if (!role) return DASH;

  const roles = role.split(",");
  if (hasServiceManagerRole(roles)) {
    return MEMBER_ROLE_TYPES.ROLE_MANAGER;
  }

  return role;
};
