import { AxiosResponse } from "axios";
import { ListResponse } from "../../../libs/request";
import { ComponentType } from "../../../types/search";
import DefaultButton from "../../DefaultButton";
import ListPage, { SearchCondition } from "../../ListPage";
import { TableColumnType } from "../../Table";
import { TagSelectType } from "../../../types/building";

type Props<T> = {
  selectType: TagSelectType;
  getDataApi: (
    searchCondition: SearchCondition<T>
  ) => Promise<AxiosResponse<ListResponse<T>>>;
  componentList: ComponentType<T>[];
  columnInfo: TableColumnType<T>[];
  title: string;
  initSearch?: boolean;
  actionButtonText?: string;
  closePopup?: () => void;
  handleApply?: () => void;
};

export default function TagSingleSelector<T>({
  getDataApi,
  componentList,
  columnInfo,
  title,
  initSearch,
  closePopup,
  handleApply,
  selectType,
  actionButtonText = "적용",
}: Props<T>) {
  return (
    <div className="flex flex-col gap-5 w-[750px]">
      <ListPage
        getDataApi={getDataApi}
        columnInfo={columnInfo}
        componentList={componentList}
        tableTitle={title}
        isTableScroll={true}
        initSearch={initSearch}
        isSmallButton
      />
      <div className="flex justify-center gap-2">
        <DefaultButton onClick={closePopup}>닫기</DefaultButton>
        <DefaultButton
          color="primary"
          onClick={() => {
            if (handleApply) handleApply();
          }}
          testId={`${selectType}-action`}
        >
          {actionButtonText}
        </DefaultButton>
      </div>
    </div>
  );
}
