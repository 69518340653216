import Compressor from "compressorjs";
import { IMAGE_MAX_SIZE } from "../types/file";

export const compressImage = (file: File): Promise<File> => {
  return new Promise<File>((resolve, reject) => {
    const options: Compressor.Options = {
      quality: 0.8, // 0 to 1, default is 0.8
      maxWidth: IMAGE_MAX_SIZE,
      success(result: Blob) {
        const compressedFile: File = new File([result], file.name, {
          type: result.type,
        });
        resolve(compressedFile);
      },
      error(err) {
        console.error("Error compressing image:", err);
        reject(err);
      },
    };

    new Compressor(file, options);
  });
};
