import { AxiosResponse, HttpStatusCode } from "axios";
import { useCallback, useEffect, useState } from "react";
import { SearchCondition } from "..";
import { useModal } from "../../../contexts/Modal";
import { PopupProvider } from "../../../contexts/Popup";
import { ComponentType } from "../../../types/search";
import { SpaceReservationData } from "../../../types/spaceReservation";
import Popup from "../../Popup";
import SearchBox from "../../SearchBox";
import Spinner from "../../Spinner";
import TimeTable, { TimeTableRange } from "../../TimeTable";

export type TimeSearchCondition = SearchCondition<SpaceReservationData>;

type Props = {
  getDataApi: (
    searchCondition: TimeSearchCondition
  ) => Promise<AxiosResponse<SpaceReservationData[]>>;
  range: TimeTableRange;
  componentList: ComponentType<SpaceReservationData>[];
  initSearchCondition?: TimeSearchCondition;
  onTabClick?: (tabIndex: number, searchCondition: TimeSearchCondition) => void;
};

export default function TimeListPage({
  getDataApi,
  range,
  componentList,
  initSearchCondition = {},
  onTabClick,
}: Props) {
  const { handleError } = useModal();
  const [data, setData] = useState<SpaceReservationData[]>([]);
  const [searchCondition, setSearchCondition] = useState<TimeSearchCondition>(
    initSearchCondition ?? {}
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isInitialDataLoadComplete, setIsInitialDataLoadComplete] =
    useState(false);
  const [addSearchCondition, setAddSearchCondition] =
    useState<TimeSearchCondition>({});

  const handleInitialDataLoadComplete = () => {
    setIsInitialDataLoadComplete(true);
  };

  useEffect(() => {
    if (isInitialDataLoadComplete) {
      getDataFromServer(searchCondition);
    }
  }, [searchCondition, isInitialDataLoadComplete]);

  const getDataFromServer = useCallback(
    async (searchCondition: TimeSearchCondition) => {
      const { sortBy, ...condition } = searchCondition;
      try {
        setIsLoading(true);

        const response = await getDataApi(condition as TimeSearchCondition);
        if (response.status === HttpStatusCode.Ok) {
          setData(response.data);
        } else {
          throw new Error("TimeListPage data load fail");
        }
      } catch (err: any) {
        handleError(err, "목록 조회");
      } finally {
        setIsLoading(false);
      }
    },
    [getDataApi]
  );

  const refreshListPage = () => getDataFromServer(searchCondition);

  const handleTabClick = (
    tabIndex: number,
    searchCondition: TimeSearchCondition
  ) => {
    onTabClick && onTabClick(tabIndex, searchCondition);
    setAddSearchCondition((prev) => ({ ...prev, ...searchCondition }));
  };

  return (
    <PopupProvider>
      <div className="flex flex-col gap-4 w-full">
        {isLoading && <Spinner />}
        <SearchBox<SpaceReservationData>
          initSearchCondition={initSearchCondition}
          addSearchCondition={addSearchCondition}
          setSearchCondition={setSearchCondition}
          componentList={componentList}
          handleInitialDataLoadComplete={handleInitialDataLoadComplete}
        />
        <TimeTable
          range={range}
          date={new Date(searchCondition.reservationAt)}
          spaceReservationData={data}
          onTabClick={handleTabClick}
        />
      </div>
      <Popup refreshListPage={refreshListPage} />
    </PopupProvider>
  );
}
