import React, { useContext, useEffect } from "react";

import { PopupContext } from "../../contexts/Popup";
import { useLockScroll } from "../../contexts/LockScroll";

type Props = { refreshListPage: () => Promise<void> };

export default function Popup({ refreshListPage }: Props) {
  const { header, content, footer, isOpen, closePopup, refresh } =
    useContext(PopupContext);
  const { lockAndUnlockScroll } = useLockScroll();

  useEffect(() => {
    if (refresh) {
      refreshListPage().then(() => {
        closePopup();
      });
    }
  }, [refresh]);

  useEffect(() => {
    if (isOpen) {
      const contentElement = document.getElementById("popup-content");
      if (contentElement) {
        contentElement.scrollTop = 0;
      }
      return lockAndUnlockScroll();
    }
  }, [isOpen]);

  return (
    <>
      <div
        className={`fixed inset-0 z-10 flex justify-center items-center ${
          isOpen ? "" : "hidden"
        }`}
      >
        <div
          className="absolute inset-0 bg-black opacity-50 z-10"
          onClick={closePopup}
        />
        <div className="bg-white min-w-fit w-96 z-10">
          <div className="px-5 font-bold text-3xl flex items-center justify-between h-[100px] border-b border-neutral-50">
            {header}

            <button onClick={closePopup} className="focus:outline-none">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-6 h-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div
            id="popup-content"
            className="max-w-[80vw] max-h-[82vh] overflow-auto"
          >
            {content}
          </div>
          <div className="min-h-[22px]">{footer}</div>
        </div>
      </div>
    </>
  );
}
