import React from "react";

import { SearchDateRange } from "../../../types/search";
import { MAX_DATE, MIN_DATE } from "../../../constants/dateConstants";

type Props = {
  value: SearchDateRange;
  onChange: (data: SearchDateRange) => void;
  labelName?: string;
};

export default function DefaultDateRange({
  value,
  onChange,
  labelName,
}: Props) {
  return (
    <div className="flex flex-col gap-1.5">
      {labelName && (
        <div className="flex gap-1 items-center">
          <span className="min-w-[70px] text-sm">{labelName}</span>
        </div>
      )}
      <div className="flex gap-1.5 items-center">
        <input
          data-testid="date-input-start"
          className="border text-sm px-4 py-2 w-[200px] rounded-none border-gray-200 h-[38px]"
          type="date"
          value={value?.startDate ?? ""}
          max={value?.endDate || MAX_DATE}
          onChange={(e) => {
            onChange({
              startDate: e.target.value,
              endDate: value?.endDate,
            });
          }}
        />
        <span>~</span>
        <input
        data-testid="date-input-end"
          className="border text-sm px-4 py-2 w-[200px] rounded-none border-gray-200 h-[38px]"
          type="date"
          value={value?.endDate ?? ""}
          min={value?.startDate || MIN_DATE}
          max={MAX_DATE}
          onChange={(e) => {
            onChange({
              endDate: e.target.value,
              startDate: value?.startDate,
            });
          }}
        />
      </div>
    </div>
  );
}
