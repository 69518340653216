import React, { useState } from "react";
import * as XLSX from "xlsx";

export default function ExcelConverter() {
  const [jsonOutput, setJsonOutput] = useState<string | null>(null);

  const handleFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        if (e.target) {
          const data = new Uint8Array(e.target.result as ArrayBuffer);
          const workbook = XLSX.read(data, { type: "array" });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          const json = XLSX.utils.sheet_to_json(sheet);
          setJsonOutput(JSON.stringify(json, null, 2));
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  return (
    <div>
      <h1>Excel to JSON Converter</h1>
      <input type="file" onChange={handleFile} />
      {jsonOutput && <pre>{jsonOutput}</pre>}
    </div>
  );
}
