import React, { useCallback, useState } from "react";
import { ComponentType } from "../../../types/search";
import DefaultButton from "../../DefaultButton";
import DefaultInput from "../../Input/DefaultInput";
import { SearchCondition } from "../../ListPage";
import { useModal } from "../../../contexts/Modal";

type Props<T> = {
  setSearchCondition?: React.Dispatch<React.SetStateAction<SearchCondition<T>>>;
  componentList?: ComponentType<T>[];
  reset?: boolean;
};

export default function TableSearchBox<T>({
  setSearchCondition,
  componentList = [],
}: Props<T>): JSX.Element {
  const { showAlert } = useModal();
  const [search, setSearch] = useState<SearchCondition<T>>({});

  const getComponent = (component: ComponentType<T>) => {
    const { typeName, keyName, labelName, placeholder } = component;
    const value = search[keyName];

    const onChange = (newValue: string) => {
      setSearch((prev) => ({ ...prev, [keyName]: newValue }));
    };

    switch (typeName) {
      case "text":
        return (
          <DefaultInput
            type={typeName}
            value={value}
            onChange={onChange}
            label={labelName}
            isLabelHorizontal
            placeholder={placeholder}
            minWidth="min-w-[80px]"
          />
        );
      default:
        return null;
    }
  };

  const isValid = () => {
    for (const component of componentList) {
      const value = search[component.keyName];
      const validations = component.validation ?? [];
      const invalidValidation = validations.find(
        (validation) => !validation(value, component.labelName).isValid
      );
      if (invalidValidation) {
        showAlert(invalidValidation(value, component.labelName).errorMessage);
        return false;
      }
    }
    return true;
  };

  const handleSearch = useCallback(() => {
    if (isValid() === false) {
      return;
    }

    let newData = {} as SearchCondition<T>;

    Object.entries(search).forEach(([key, value]) => {
      if (value) {
        newData[key as keyof T] = value as any;
      }
    });

    if (setSearchCondition) {
      setSearchCondition((prev) => {
        const { pageSize } = prev;
        return { page: 1, pageSize, ...newData };
      });
    }
  }, [componentList, search]);

  return (
    <>
      {componentList.map((component, index) => (
        <div key={index} className="flex items-center gap-2">
          {getComponent(component)}
        </div>
      ))}

      <div className="min-w-fit">
        <DefaultButton className="text-sm h-[38px]" onClick={handleSearch}>
          조회
        </DefaultButton>
      </div>
    </>
  );
}
