import React, { useEffect, useState } from "react";

type Props = {};

export default function Referer({}: Props) {
  const [referer, setReferer] = useState("Loading...");

  useEffect(() => {
    // document.referrer를 사용하여 referer 정보를 가져옵니다.
    const refererValue = document.referrer || "No referer";
    setReferer(refererValue);
  }, [document.referrer]);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <div className="p-6 max-w-sm w-full bg-white shadow-md rounded-md">
        <h1 className="text-2xl font-bold mb-4 text-center text-gray-800">
          Referer Test
        </h1>
        <p className="text-gray-600">
          <span className="font-semibold">Referer:</span>
          <span className="ml-2 break-all">{referer}</span>
        </p>
      </div>
    </div>
  );
}
