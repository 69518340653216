import { shownEvacueeType } from "@/types/comboBoxOption";
import { Nullable } from "@/types/common";
import { EvacuationUser, SituationUserType } from "@/types/emergency";

export const getShownEvacueeType = (role?: string) => {
  if (!role) return "미식별 권한";

  return (
    shownEvacueeType.find((item) => item.value === role)?.label ?? "미식별 권한"
  );
};

export const renderEvacueeType = (item: Nullable<EvacuationUser>) => {
  if (!item) return "미식별";
  const type =
    item.type === "MANUAL" || item.type === undefined
      ? "(수동 등록)"
      : "(출입 이력 연동)"; // TODO : 타입 추가 및 확정에 따른 분기처리 필요
  const memberId = item.memberId;
  const isSandiMember = memberId && memberId !== undefined && memberId !== null;

  return (
    <div className="flex flex-col items-center">
      <div>{isSandiMember ? "회원" : "비회원"}</div>
      <div>{type}</div>
    </div>
  );
};
