import { useEffect, useMemo, useState } from "react";
import { useModal } from "../../../../../contexts/Modal";
import { getGoodsRentalItemList } from "../../../../../services/goodsService";
import { GOODS_RENTAL_OPTIONS } from "../../../../../types/comboBoxOption";
import {
  GoodsRentalItem,
  GoodsRentalItemType,
  goodsRentalItemLabels,
} from "../../../../../types/goods";
import { ComponentType, DASH } from "../../../../../types/search";
import { addDefaultOption } from "../../../../../utils/comboBoxUtils";
import DefaultButton from "../../../../DefaultButton";
import CommonRadio from "../../../../Input/CommonRadio";
import DefaultInput from "../../../../Input/DefaultInput";
import CommonPopup from "../../../../Popup/CommonPopup";
import { TableColumnType } from "../../../../Table";
import TagSingleSelector from "../../../../TagSelector/TagSingleSelector";
import { SearchCondition } from "../../../../ListPage";

type Props = {
  selectedItem?: GoodsRentalItem;
  onSelectedItem: (data: GoodsRentalItem) => void;
};

export default function GoodsRentalStockSelectGoods({
  selectedItem,
  onSelectedItem,
}: Props) {
  const { showAlert, showConfirm } = useModal();
  const [isOpen, setIsOpen] = useState(false);

  const [goodsRentalItem, setGoodsRentalItem] = useState<
    GoodsRentalItem | undefined
  >(selectedItem);

  useEffect(() => {
    setGoodsRentalItem(selectedItem);
  }, [selectedItem]);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClosePopup = () => {
    setIsOpen(false);
    setGoodsRentalItem(selectedItem);
  };

  const handleSelectedChange = (data: GoodsRentalItem) => {
    setGoodsRentalItem(data);
  };

  const handleApply = () => {
    if (!goodsRentalItem) {
      showAlert("선택된 항목이 없습니다.");
      return;
    }

    showConfirm("적용 하시겠습니까?", async () => {
      onSelectedItem(goodsRentalItem as GoodsRentalItem);
      handleClosePopup();
    });
  };

  const isEqual = (
    checkedItem: GoodsRentalItem | undefined,
    currentItem: GoodsRentalItem
  ) => {
    if (!checkedItem) {
      return;
    }

    return checkedItem.goodsId === currentItem.goodsId;
  };

  const goodsColumnInfo: TableColumnType<GoodsRentalItem>[] = [
    {
      header: "선택",
      render: (item: GoodsRentalItem) => {
        return (
          <div className="flex justify-center items-center min-w-[40px]">
            <CommonRadio
              checked={isEqual(goodsRentalItem, item)}
              onChange={() => {
                handleSelectedChange(item);
              }}
            />
          </div>
        );
      },
    },
    { header: "물품 ID", name: "goodsId" },
    { header: "물품명", name: "goodsName" },
    {
      header: "물품타입",
      render: (item: GoodsRentalItem) =>
        goodsRentalItemLabels[item.goodsTypeId as GoodsRentalItemType] ?? DASH,
    },
  ];

  const goodsComponentList: ComponentType<GoodsRentalItem>[] = [
    {
      labelName: "물품 ID",
      typeName: "text",
      keyName: "goodsId",
      category: "detailSearch",
    },
    {
      labelName: "물품명",
      typeName: "text",
      keyName: "goodsName",
      category: "detailSearch",
    },
    {
      labelName: "물품타입",
      typeName: "comboBox",
      keyName: "goodsTypeId",
      category: "detailSearch",
      comboBoxOptions: addDefaultOption(GOODS_RENTAL_OPTIONS),
    },
  ];

  const getGoodsRentalItemListWrapper = useMemo(
    () => (params: SearchCondition<GoodsRentalItem>) => {
      return getGoodsRentalItemList({ ...params, isActive: true });
    },
    []
  );

  return (
    <div className="flex gap-2 items-center">
      <DefaultInput
        placeholder="물품을 선택해주세요"
        value={selectedItem?.goodsName}
        disabled
      />
      <div className="min-w-fit">
        <DefaultButton
          size="tableButton"
          className="h-[38px]"
          onClick={handleOpen}
        >
          검색
        </DefaultButton>
      </div>

      {isOpen && (
        <CommonPopup
          header="상세 물품 선택"
          content={
            <TagSingleSelector
              getDataApi={getGoodsRentalItemListWrapper}
              componentList={goodsComponentList}
              columnInfo={goodsColumnInfo}
              title="상세 물품 목록"
              closePopup={handleClosePopup}
              handleApply={handleApply}
              selectType="store"
            />
          }
          isOpen={isOpen}
          closePopup={handleClosePopup}
        />
      )}
    </div>
  );
}
