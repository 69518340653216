import { HttpStatusCode } from "axios";
import { useCallback, useContext, useState } from "react";
import { ImArrowRight } from "react-icons/im";
import {
  getBuildingCompanyList,
  getBuildingCompanyServiceList,
  patchBuildingCompanyServiceList,
} from "../../../../../services/buildingService";
import {
  BuildingCompany,
  BuildingCompanyService,
} from "../../../../../types/building";
import DefaultButton from "../../../../DefaultButton";
import FormHeader from "../../../../Form/Header";
import FormRow from "../../../../Form/Row";
import FormRowLabel from "../../../../Form/RowLabel";
import ToggleButton from "../../../../Input/ToggleButton";
import ListPage, { SearchCondition } from "../../../../ListPage";
import { TableColumnType } from "../../../../Table";
import { useModal } from "../../../../../contexts/Modal";
import DefaultLabel from "../../../../Input/DefaultLabel";
import { PopupContext } from "../../../../../contexts/Popup";
import PermissionWrapper from "../../../../PermissionWrapper";

type LoadingId = {
  id: string;
  loading: boolean;
};

type Props = { buildingCompany: BuildingCompany };

export default function BuildingCompanyServiceManage({
  buildingCompany,
}: Props) {
  const { showAlert, showConfirm, handleError } = useModal();
  const { closePopup } = useContext(PopupContext);

  const [refresh, setRefresh] = useState(false);
  const [services, setServices] = useState<BuildingCompanyService[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<Partial<LoadingId>>(
    {}
  );

  const getBuildingCompanyServiceListParams = useCallback(
    async (searchCondition: SearchCondition<BuildingCompanyService>) => {
      setSelectedCompany((prev) => ({ ...prev, loading: true }));
      return await getBuildingCompanyServiceList(
        searchCondition,
        buildingCompany.buildingId,
        selectedCompany.id ?? ""
      ).finally(() => {
        setSelectedCompany((prev) => ({ ...prev, loading: false }));
      });
    },
    [selectedCompany.id, refresh]
  );

  const handleUpdate = () => {
    if (services.length === 0) {
      showAlert("변경된 내용이 없습니다.");
      return;
    }

    showConfirm("수정 하시겠습니까?", updateData);
  };

  const updateData = async () => {
    try {
      const response = await patchBuildingCompanyServiceList(
        buildingCompany.buildingId,
        (selectedCompany as any).id,
        services
      );

      if (response.status === HttpStatusCode.Ok) {
        showAlert("수정 되었습니다.");
        setRefresh(!refresh);
      } else {
        throw new Error("Update fail");
      }
    } catch (err: any) {
      handleError(err, "수정");
    }
  };

  const companyColumns: TableColumnType<BuildingCompany>[] = [
    { header: "회사 ID", name: "companyId" },
    { header: "회사명", name: "companyName" },
    {
      header: "활동",
      render: (item: BuildingCompany) => (
        <div className="flex justify-center items-center">
          {selectedCompany.id === item.companyId && selectedCompany.loading ? (
            <DefaultButton color="emerald">조회중</DefaultButton>
          ) : (
            <DefaultButton
              onClick={() => {
                setSelectedCompany({ id: item.companyId });
                setServices([]);
              }}
            >
              조회
            </DefaultButton>
          )}
        </div>
      ),
    },
  ];

  const getToggleValue = (serviceId: string, oriBoolean: boolean) => {
    const value = services.find((service) => service.serviceId === serviceId);
    return value ? value.isActive : oriBoolean;
  };

  const handleServiceChange = (selectedService: BuildingCompanyService) => {
    const service = services.find(
      (service) => service.serviceId === selectedService.serviceId
    );

    if (service) {
      setServices((prev) =>
        prev.filter(
          (prevItem) => prevItem.serviceId !== selectedService.serviceId
        )
      );
    } else {
      setServices((prev) => [
        ...prev,
        { ...selectedService, isActive: !selectedService.isActive },
      ]);
    }
  };

  const serviceColumns: TableColumnType<BuildingCompanyService>[] = [
    // 기획서에는 구분이 있으나, 현재 구분으로 표시할 컬럼값이 존재하지 않아 임시로 주석처리함
    // { header: "구분", name: "serviceTypeName" },
    { header: "서비스명", name: "serviceName" },
    {
      header: "사용여부",
      render: (item: BuildingCompanyService) => (
        <div className="flex justify-center items-center">
          <ToggleButton
            value={getToggleValue(item.serviceId, item.isActive)}
            onChange={() => {
              handleServiceChange(item);
            }}
          />
        </div>
      ),
    },
  ];

  const getBuildingCompanyListByBuildingId = useCallback(
    async (searchCondition: SearchCondition<BuildingCompany>) => {
      return await getBuildingCompanyList({
        ...searchCondition,
        buildingId: buildingCompany.buildingId,
      });
    },
    [selectedCompany]
  );

  return (
    <div className="flex flex-col gap-5">
      <div className="flex flex-col max-h-popup overflow-y-auto">
        <FormHeader title="건물 정보" />
        <FormRow>
          <FormRowLabel title="건물명">
            <DefaultLabel text={buildingCompany.buildingName} />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <div className="flex w-full">
            <FormRowLabel title="등록일시">
              <DefaultLabel text={buildingCompany.createdAt} />
            </FormRowLabel>
            <FormRowLabel title="최종 수정일시">
              <DefaultLabel text={buildingCompany.modifiedAt} />
            </FormRowLabel>
          </div>
        </FormRow>

        <FormHeader title="입주사별 계약 연결(관리기능 포함)" />
        <div className="flex gap-2">
          <div className="min-w-[500px]">
            <ListPage
              getDataApi={getBuildingCompanyListByBuildingId}
              columnInfo={companyColumns}
              tableTitle="회사 목록"
              isTableScroll
            />
          </div>
          <div className="flex items-center">
            <ImArrowRight size={46} className="text-gray-500" />
          </div>
          <div className="min-w-[500px]">
            <ListPage
              getDataApi={getBuildingCompanyServiceListParams}
              columnInfo={serviceColumns}
              tableTitle="서비스 목록"
              isTableScroll
              initSearch={selectedCompany.id ? true : false}
            />
          </div>
        </div>
      </div>
      <div className="flex gap-2 justify-center">
        <DefaultButton onClick={closePopup}>닫기</DefaultButton>
        <PermissionWrapper>
          <DefaultButton color="primary" onClick={handleUpdate}>
            수정
          </DefaultButton>
        </PermissionWrapper>
      </div>
    </div>
  );
}
