import { useEffect } from "react";
import {
  RouteObject,
  useLocation,
  useNavigate,
  useRoutes,
} from "react-router-dom";
import { HttpStatusCode } from "axios";

import {
  flattenProgramId,
  getVisibleRoutes,
  hideRoutesNotInProgramList,
  routes,
} from "./libs/routes";
import { getLocalStorage } from "./libs/localStorage";

import { ModalProvider } from "./contexts/Modal";
import { useUserContext } from "./contexts/User";
import { ScrollProvider } from "./contexts/LockScroll";
import { useRoutesContext } from "./contexts/Routes";

import { getMenu } from "./services/userServices";

import { useMaintenance } from "./hooks/useMaintenance";

import Modal from "./components/Modal";

import { Toaster } from "@/components/ui/toaster";

function App() {
  const navigate = useNavigate();
  const location = useLocation();

  const { setRouteContextInfo } = useRoutesContext();
  const { login } = useUserContext();
  const [, checkMaintenance] = useMaintenance();

  const authExceptionList = ["/maintenance", "/actuator", "/notFound"];

  useEffect(() => {
    const handleStorageChange = () => {
      if (getLocalStorage("jwtToken") === null) {
        navigate("/login");
      } else {
        console.error("token remove error");
      }
    };

    if (getLocalStorage("jwtToken")) {
      getMenu().then((res) => {
        if (res.status === HttpStatusCode.Ok) {
          const menuIds = flattenProgramId(res.data);
          const visibleRoutes = getVisibleRoutes(
            hideRoutesNotInProgramList(routes, menuIds)
          );

          setRouteContextInfo({
            routeInfo: visibleRoutes,
            menuInfo: menuIds,
          });
        }
      });
    }

    const userString = getLocalStorage("user");
    if (userString) {
      const user = JSON.parse(userString);

      if (user) {
        login(user);
      }
    }

    window.addEventListener("tokenRemove", handleStorageChange);
    return () => {
      window.removeEventListener("tokenRemove", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    if (authExceptionList.some((path) => location.pathname.startsWith(path)))
      return;

    const init = async () => {
      if (await checkMaintenance()) return;
      if (location.pathname.startsWith("/referer")) return;

      const sessionToken = getLocalStorage("user");
      if (
        sessionToken === null ||
        JSON.parse(sessionToken)?.email === undefined
      ) {
        // navigate("/login");
      }
    };

    init();
  }, [navigate]);

  const router = useRoutes(routes as RouteObject[]);

  return (
    <ScrollProvider>
      <ModalProvider>
        {router}
        <Modal />
        <Toaster />
      </ModalProvider>
    </ScrollProvider>
  );
}

export default App;
