import { useContext, useEffect, useMemo, useState } from "react";

import { Company } from "../../types/company";
import { ComponentType } from "../../types/search";

import { generateAxiosListResponse } from "../../libs/request";

import { PopupContext } from "../../contexts/Popup";
import { useModal } from "../../contexts/Modal";
import { useUserContext } from "../../contexts/User";

import { getCompanyList } from "../../services/companyService";
import {
  getBuildingCompanyCompanyList,
  getBuildingGroupSearch,
} from "../../services/buildingService";

import DefaultButton from "../DefaultButton";
import { TableColumnType } from "../Table";
import TagList, { TagData } from "../TagList";
import TagSelector from "../TagSelector";
import TextToggleButton from "../Input/TextToggleButton";
import { SearchCondition } from "../ListPage";

export type TagType = "building" | "company";

export type SearchTagData = {
  data?: TagData[];
  type?: TagType;
};

export type TagFilterType = "default" | "goodsRequest";

type TagFilterBuilding = {
  buildingId: string | null;
  buildingName: string | null;
};

type Props = {
  tags: SearchTagData;
  tagFilterType?: TagFilterType;
  searchType?: TagType;
  setTags: (value: SearchTagData) => void;
  label?: string;
  categoryCode?: string;
};

export default function TagFilter({
  tags,
  tagFilterType = "default",
  searchType,
  setTags,
  label = "",
  categoryCode,
}: Props) {
  const { userInfo, getCategoryCodeByRole } = useUserContext();
  const { showConfirm } = useModal();
  const { openPopup } = useContext(PopupContext);
  const [selectedCategory, setSelectedCategory] = useState<TagType>("building");

  const toggleButtons = ["건물", "회사"];

  const tagTypeName: Record<TagType, string> = {
    building: "건물",
    company: "회사",
  };

  const handleCategoryChange = (value: string) => {
    const toggle = value === "건물" ? "building" : "company";

    if ((tags?.data || []).length === 0) {
      setSelectedCategory(toggle);
    } else {
      showConfirm(
        "기존 선택한 키워드가 초기화 됩니다.\n초기화 하시겠습니까?",
        async () => {
          setTags({ data: [], type: "building" });
          setSelectedCategory(toggle);
        }
      );
    }
  };

  const handleTagDelete = (tagId: string | number) => {
    setTags({
      ...tags,
      data: (tags.data || []).filter((t) => t.id !== tagId),
    });
  };

  useEffect(() => {
    setSelectedCategory(searchType === "company" ? "company" : "building");
  }, [searchType]);

  useEffect(() => {
    setTags(tags);
  }, [tags]);

  const buildingComponents: ComponentType<TagFilterBuilding>[] = [
    {
      labelName: "건물 ID",
      typeName: "text",
      keyName: "buildingId",
      category: "detailSearch",
      placeholder: "건물 ID를 입력해주세요",
    },
    {
      labelName: "건물명",
      typeName: "text",
      keyName: "buildingName",
      category: "detailSearch",
      placeholder: "건물명을 입력해주세요",
    },
  ];

  const buildingColumns: TableColumnType<TagFilterBuilding>[] = [
    { header: "건물 ID", name: "buildingId" },
    { header: "건물명", name: "buildingName" },
  ];

  const companyComponents: ComponentType<Company>[] = [
    {
      labelName: "회사 ID",
      typeName: "text",
      keyName: "companyId",
      category: "detailSearch",
      placeholder: "회사 ID를 입력해주세요",
    },
    {
      labelName: "회사명",
      typeName: "text",
      keyName: "companyName",
      category: "detailSearch",
      placeholder: "회사명을 입력해주세요",
    },
  ];

  const companyColumns: TableColumnType<Company>[] = [
    { header: "회사 ID", name: "companyId" },
    { header: "회사명", name: "companyName" },
  ];

  const compareBuilding =
    (item: TagFilterBuilding) => (o: TagFilterBuilding) => {
      return item.buildingId === o.buildingId;
    };

  const getTagBuilding = (item: TagFilterBuilding) => {
    return {
      id: item.buildingId,
      name: item.buildingName,
      data: item,
    } as TagData;
  };

  const compareCompany = (item: Company) => (o: Company) => {
    return item.companyId === o.companyId;
  };

  const getTagCompany = (item: Company) => {
    return {
      id: item.companyId,
      name: item.companyName,
      data: item,
    } as TagData;
  };

  const getBuildingListWrapper = useMemo(
    () => async (params: SearchCondition<TagFilterBuilding>) => {
      if (tagFilterType === "default") {
        const newCategoryCode = getCategoryCodeByRole(categoryCode ?? "");
        if (!newCategoryCode) {
          return generateAxiosListResponse(params, []);
        }

        return getBuildingGroupSearch({
          ...params,
          categoryCode: newCategoryCode,
        });
      } else if (tagFilterType === "goodsRequest" && userInfo?.companyId) {
        return getBuildingCompanyCompanyList({
          ...params,
          companyId: [userInfo.companyId],
        });
      } else {
        return generateAxiosListResponse(params, []);
      }
    },
    []
  );

  const handlePopup = () => {
    const render =
      selectedCategory === "building" ? (
        <TagSelector
          getDataApi={getBuildingListWrapper}
          componentList={buildingComponents}
          columnInfo={buildingColumns}
          title="건물 목록"
          tagType="building"
          tags={tags}
          setTags={setTags}
          compareItem={compareBuilding}
          getTagItem={getTagBuilding}
        />
      ) : (
        <TagSelector
          getDataApi={getCompanyList}
          componentList={companyComponents}
          columnInfo={companyColumns}
          title="회사 목록"
          tagType="company"
          tags={tags}
          setTags={setTags}
          compareItem={compareCompany}
          getTagItem={getTagCompany}
        />
      );
    openPopup({
      header: tagTypeName[selectedCategory],
      content: render,
    });
  };

  return (
    <div className="flex flex-col gap-1.5">
      {label && <span className="block text-label">{label}</span>}
      <div className="flex justify-between gap-5">
        <div className="flex gap-2">
          {!searchType && (
            <TextToggleButton
              options={toggleButtons}
              onChange={handleCategoryChange}
            />
          )}
          <div className="flex gap-1">
            <DefaultButton
              onClick={handlePopup}
              className=" !bg-brand-primary-gray-100 !text-white whitespace-nowrap !bg-opacity-40 h-[38px] !w-[100px] rounded-none text-sm font-semibold"
            >
              조회
            </DefaultButton>
            <DefaultButton
              onClick={() => setTags({ ...tags, data: [] })}
              className=" !bg-brand-primary-gray-100 whitespace-nowrap !bg-opacity-10 h-[38px] !w-[100px] rounded-none text-sm font-semibold !text-brand-text-black-disabled"
            >
              초기화
            </DefaultButton>
          </div>
        </div>
        <div className="flex items-center">
          <TagList tags={tags?.data || []} onDelete={handleTagDelete} />
        </div>
      </div>
    </div>
  );
}
