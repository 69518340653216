import { HttpStatusCode } from "axios";
import { useContext, useMemo, useState } from "react";
import { useModal } from "../../../../contexts/Modal";
import {
  getAtgBuildingInfo,
  getAtgFloor,
} from "../../../../services/atgService";
import {
  getBuildingVocUnitList,
  postBuilding,
} from "../../../../services/buildingService";
import {
  AtgBuilding,
  AtgFloor,
  atgFloorActiveValue,
} from "../../../../types/atg";
import {
  Building,
  BuildingFloor,
  BuildingVoc,
  BuildingVocGet,
} from "../../../../types/building";
import DefaultButton from "../../../DefaultButton";
import ListPage, { SearchCondition } from "../../../ListPage";
import Spinner from "../../../Spinner";
import { PopupContext } from "../../../../contexts/Popup";
import { TableColumnType } from "../../../Table";
import { ComponentType } from "../../../../types/search";
import { validationFunctions } from "../../../../libs/validations";
import { generateAxiosResponse } from "../../../../libs/request";
import CommonRadio from "../../../Input/CommonRadio";

type AtgBuildingItem = {
  index: number;
  item: AtgBuilding;
};

type Props = {
  actionButtonText?: string;
  onClosePopup?: () => void;
  onChange?: (value: AtgBuilding) => void;
};

export default function AtgBuildingTagSingleFilter({
  actionButtonText = "적용",
  onClosePopup,
  onChange,
}: Props) {
  const { showAlert, showConfirm, handleError } = useModal();
  const { closePopup, refreshAndClosePopup } = useContext(PopupContext);

  const [checkedItem, setCheckedItem] = useState<Partial<AtgBuildingItem>>({});
  const [isLoading, setIsLoading] = useState(false);

  const handleCreate = async (atgBuilding: AtgBuilding) => {
    try {
      setIsLoading(true);
      const [resVocList, resFloorList] = await Promise.all([
        getBuildingVocUnitList(),
        getAtgFloor(atgBuilding.atgBuildingId),
      ]);

      if (
        resVocList.status === HttpStatusCode.Ok &&
        resFloorList.status === HttpStatusCode.Ok
      ) {
        const response = await postBuilding(
          createFormData(atgBuilding, resVocList.data, resFloorList.data)
        );

        if (response.status === HttpStatusCode.Ok) {
          showAlert("등록 되었습니다.");
          handleClosePopup();
        }
      }
    } catch (err: any) {
      handleError(err, "등록");
    } finally {
      setIsLoading(false);
    }
  };

  const createFormData = (
    atgBuilding: AtgBuilding,
    vocList: BuildingVocGet[],
    floorList: AtgFloor[]
  ) => {
    const formData = new FormData();

    const data: Partial<Building> = {
      buildingName: atgBuilding?.buildingName,
      address: atgBuilding?.address,
      lat: Number(atgBuilding?.latitude),
      lng: Number(atgBuilding?.longitude),
      gridX: Number(atgBuilding?.gridX),
      gridY: Number(atgBuilding?.gridY),
      atgGroupId: atgBuilding?.atgGroupId,
      atgDepartmentName: atgBuilding?.departmentName,
      atgBuildingId: atgBuilding?.atgBuildingId,
      atgCompanyName: atgBuilding.businessPlaceName,
      helpDeskTel: atgBuilding?.helpDeskTel,
      isActive: true,
      buildingVocList: vocList.map(
        (voc) =>
          ({
            vocUnitCode: voc.code,
            vocId: atgBuilding.vocManagementUnit,
            isActive: true,
          } as BuildingVoc)
      ),
      buildingFloorList: createBuildingFloorList(floorList),
    };

    formData.append("request", JSON.stringify(data));
    return formData;
  };

  const createBuildingFloorList = (floorList: AtgFloor[]): BuildingFloor[] => {
    const floorCodeMinValue =
      Math.min(
        ...floorList
          .map((item) => Number(item.floorCode))
          .filter((num) => !isNaN(num))
      ) - 1;

    const sortedFloorList = floorList
      .map((item) => {
        const num = Number(item.floorCode);
        const data = { ...item };

        if (isNaN(num)) {
          console.error(`Value is not a number : ${item.floorCode}`);
          data.floorCode = floorCodeMinValue.toString();
        }

        return data;
      })
      .sort((a, b) => Number(b.floorCode) - Number(a.floorCode));

    return sortedFloorList.map(
      (item, index) =>
        ({
          floorName: item.floorName,
          isActive: item.isActive === atgFloorActiveValue.TRUE,
          atgBuildingFloorId: item.floorCode,
          sortOrder: index + 1,
        } as BuildingFloor)
    );
  };

  const handleAction = () => {
    if (checkedItem.item) {
      showConfirm(`${actionButtonText} 하시겠습니까?`, handleApply);
    } else {
      showAlert("선택된 항목이 없습니다.");
    }
  };

  const handleApply = async () => {
    if (checkedItem.item) {
      if (onChange) {
        onChange(checkedItem.item);
        handleClosePopup();
      } else {
        handleCreate(checkedItem.item);
      }
    } else {
      showAlert("선택된 건물이 없습니다.");
    }
  };

  const handleClosePopup = () => {
    if (onClosePopup) {
      onClosePopup();
    } else if (onChange) {
      closePopup();
    } else {
      refreshAndClosePopup();
    }
  };

  const columnInfo: TableColumnType<AtgBuilding>[] = [
    {
      header: "선택",
      render: (item: AtgBuilding, _, rowIndex) => {
        return (
          <div className="min-w-short">
            <CommonRadio
              checked={rowIndex === checkedItem.index}
              onChange={() => {
                setCheckedItem({ index: rowIndex, item });
              }}
              dataTestId={`atg-building-${item.atgBuildingId}`}
            />
          </div>
        );
      },
    },
    { header: "부서명", name: "departmentName" },
    { header: "건물명", name: "buildingName" },
    { header: "사업장명", name: "businessPlaceName" },
    { header: "VOC 관리번호", name: "vocManagementUnit" },
  ];

  const componentList: ComponentType<AtgBuilding>[] = [
    {
      labelName: "부서명",
      typeName: "text",
      keyName: "departmentName",
      category: "detailSearch",
      validation: [validationFunctions.required],
      placeholder: "부서명을 입력해주세요",
    },
  ];

  const getAtgBuildingInfoByDepartmentName = useMemo(
    () => async (params: SearchCondition<AtgBuilding>) => {
      if (!params.departmentName) {
        return generateAxiosResponse([]);
      }
      return await getAtgBuildingInfo(params.departmentName);
    },
    []
  );

  return (
    <>
      {isLoading && <Spinner />}
      <div className="flex flex-col gap-5">
        <div
          className="flex flex-col min-w-[1000px] max-h-[700px] overflow-y-auto"
          data-testid="atg-building-list"
        >
          <ListPage
            getDataApi={getAtgBuildingInfoByDepartmentName}
            columnInfo={columnInfo}
            componentList={componentList}
            tableTitle="엣지 건물"
            isTableScroll={true}
            hidePageSize
            hidePagination
            isSmallButton
          />
        </div>
        <div className="flex justify-center gap-2">
          <DefaultButton
            className="text-base font-normal"
            onClick={handleClosePopup}
          >
            닫기
          </DefaultButton>
          <DefaultButton
            color="primary"
            className="text-base font-normal"
            onClick={handleAction}
          >
            {actionButtonText}
          </DefaultButton>
        </div>
      </div>
    </>
  );
}
