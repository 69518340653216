import { BuildingAndFloor } from "../components/SelectBox/BuildingAndFloorSelect";

export const TAG_ID_REGEX = /^[a-zA-Z0-9:_-]+$/;
export const TAG_ID_REGEX_COMMENT = "영문, 숫자, :, _, -";

export type Tag = {
  id: number;
  tagId: string;
  tagName: string;
  tagGroupTypeId: number;
  tagGroupTypeName: string;
  tagTypeId: number;
  tagTypeName: string;
  tagLocation: string;
  buildingSpaceId: string;
  spaceName: string;
  buildingFloorId: string;
  floorName: string;
  toiletCompartmentName: string;
  toiletName: string;
  buildingId: string;
  buildingName: string;
  tagValue: string;
  isUsed: string;
  isConnected: boolean;
  isActive: boolean;
  createdBy: number;
  createdAt: string;
  modifiedAt: string;
  modifiedBy: number;
  toiletCompartmentId: string;
  spaceToiletId: string;
  buildingAndFloor: string;
  deviceId: string;
  deviceAddr: string;
  macAddr: string;
};

export type TagType = {
  tagTypeId: number;
  tagGroupTypeId: number;
  tagTypeName: string;
};

export type TagGroupType = {
  tagGroupTypeId: number;
  tagGroupTypeName: string;
};

export const TAG_GROUP_TYPE_ID = {
  TEMP_HUM: 1,
  AWAIR: 2,
  TOILET: 3,
};

export enum TagGroupName {
  TEMP_HUM = "TEMP_HUM",
  TOILET = "TOILET",
}

export const tagGroupNameMap: Record<number, TagGroupName> = {
  1: TagGroupName.TEMP_HUM,
  2: TagGroupName.TEMP_HUM,
  3: TagGroupName.TOILET,
};

export type ToiletTag = {
  id: number;
  tagId: string;
  tagName: string;
  tagGroupTypeId: number;
  tagGroupTypeName: string;
  tagTypeId: number;
  tagTypeName: string;
  tagLocation: string;
  buildingSpaceId: string;
  spaceName: string;
  buildingFloorId: string;
  floorName: string;
  toiletCompartmentId: string;
  toiletCompartmentName: string;
  spaceToiletId: string;
  toiletName: string;
  buildingId: string;
  buildingName: string;
  tagValue: string;
  isConnected: boolean;
  isActive: boolean;
  createdAt: string;
  modifiedAt: string;
  buildingAndFloor: string;
  deviceId: string;
  deviceAddr: string;
  macAddr: string;
};

export type ToiletInfo = {
  id: number;
  tagId: string;
  tagName: string;
  buildingId: string;
  buildingName: string;
  buildingFloorId: string;
  floorName: string;
  gender: string;
  spaceToiletId: string;
  toiletName: string;
  toiletCompartmentId: string;
  toiletCompartmentName: string;

  status: string;
  referenceDate: string;
  referenceDayOfWeek: string;
  referenceHour: string;
  referenceTime: string;
  updatedAt: string;
  createdAt?: string;
  buildingAndFloor?: BuildingAndFloor;
};

export type AirInfo = {
  buildingId: string;
  buildingName: string;
  buildingSpaceId: string;
  spaceName: string;
  buildingFloorId: string;
  floorName: string;

  connectedStatus?: string;
  temperature?: string;
  humidity?: string;
  co2?: string;
  tvoc?: string;
  dust?: string;
  lux?: string;
  dba?: string;
  total?: string;
  updatedAt: string;
  createdAt?: string;
  buildingAndFloor?: BuildingAndFloor;
};
