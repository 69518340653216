import ToggleOffFalseIcon from "../../../svgs/icons/Toggle/ToggleOffFalseIcon.tsx";
import ToggleOffTrueIcon from "../../../svgs/icons/Toggle/ToggleOffTrueIcon.tsx";
import ToggleOnFalseIcon from "../../../svgs/icons/Toggle/ToggleOnFalseIcon.tsx";
import ToggleOnTrueIcon from "../../../svgs/icons/Toggle/ToggleOnTrueIcon.tsx";

type Props = {
  value: boolean;
  onChange: (value: boolean) => void;
  disabled?: boolean;
};

export default function ToggleButton({
  value,
  onChange,
  disabled = false,
}: Props) {
  const handleClick = () => {
    if (!disabled) {
      onChange(!value);
    }
  };

  const getImage = () => {
    if (value && disabled) {
      return <ToggleOnFalseIcon />;
    } else if (value && !disabled) {
      return <ToggleOnTrueIcon />;
    } else if (!value && disabled) {
      return <ToggleOffFalseIcon />;
    } else {
      return <ToggleOffTrueIcon />;
    }
  };

  return <div onClick={handleClick}>{getImage()}</div>;
}
