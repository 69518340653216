import { getSideRoute } from "../../libs/routes";

import { useRoutesContext } from "../../contexts/Routes";

import UpperMenu from "../UpperMenu";

type Props = {};

export default function SideBar(props: Props) {
  const { routesContextInfo } = useRoutesContext();
  const routes = getSideRoute(routesContextInfo.routeInfo ?? []);

  return (
    <div className="max-h-fit min-h-screen overflow-y-hidden min-w-sidebar">
      <div className="flex flex-col gap-1.5 pt-2.5">
        {routes?.map((route, index) => (
          <UpperMenu key={index} route={route} />
        ))}
      </div>
    </div>
  );
}
