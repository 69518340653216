import { HttpStatusCode } from "axios";
import { useCallback, useEffect, useState } from "react";
import { FiEdit } from "react-icons/fi";
import DefaultButton from "../../../../components/DefaultButton";
import ListPage, { SearchCondition } from "../../../../components/ListPage";
import Spinner from "../../../../components/Spinner";
import { TableColumnType } from "../../../../components/Table";
import { ComponentType } from "../../../../types/search";
import {
  Tag,
  TagGroupName,
  TagGroupType,
  TagType,
  tagGroupNameMap,
} from "../../../../types/tag";
import {
  addDefaultOption,
  toComboBoxType,
} from "../../../../utils/comboBoxUtils";
import {
  CONNECT_OPTIONS,
  USAGE_OPTIONS,
  usageYnLabels,
} from "../../../../types/comboBoxOption";
import {
  getSensorList,
  getTagBulkExcel,
  getTagGroupType,
  getTagListExcel,
  getTagType,
  patchTag,
  postTagBulk,
} from "../../../../services/tagService";
import TempHumTagListTopRight from "../../../../components/PageComponents/IotDevice/Device/TempHumTagList/ListTopRight";
import TempHumTagDetailView from "../../../../components/PageComponents/IotDevice/Device/TempHumTagList/DetailView";
import { useModal } from "../../../../contexts/Modal";
import ExcelFileUploaderButton from "../../../../components/FileUploader/ExcelFileUploaderButton";
import { buildingGroupCategoryCodeValue } from "../../../../types/building";
import DefaultLabel from "../../../../components/Input/DefaultLabel";
import { formatSortSearchCondition } from "../../../../utils/formatUtils";

type Props = {};

interface HasTagGroupTypeId {
  tagGroupTypeId: number;
}

const filteredByGroupTypeId = <T extends HasTagGroupTypeId>(
  items: T[],
  type: TagGroupName
) => {
  return items.filter((item) => tagGroupNameMap[item.tagGroupTypeId] === type);
};

export default function TempHumTagList(props: Props) {
  const { showAlert, showConfirm, handleError } = useModal();
  const [tagType, setTagType] = useState<TagType[]>([]);
  const [tagGroupType, setTagGroupType] = useState<TagGroupType[]>([]);
  const [refresh, setRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const columnInfo: TableColumnType<Tag>[] = [
    { header: "태그 ID", name: "tagId" },
    { header: "태그유형", name: "tagTypeName" },
    { header: "태그명", name: "tagName" },
    {
      header: "건물명",
      render: (item: Tag) => (item.buildingName ? item.buildingName : "-"),
    },
    {
      header: "층명",
      render: (item: Tag) => (item.floorName ? item.floorName : "-"),
    },
    {
      header: "공간명",
      render: (item: Tag) => (item.spaceName ? item.spaceName : "-"),
    },
    {
      header: "사용여부",
      render: (item: Tag) => (
        <div className="flex items-center justify-center">
          <DefaultLabel
            text={item.isActive ? usageYnLabels.TRUE : usageYnLabels.FALSE}
            color={item.isActive ? "blue" : "gray"}
          />
        </div>
      ),
      width: "yesOrNo",
    },
    { header: "등록일시", name: "createdAt", width: "date" },
    {
      header: "상세",
      render: (item: Tag, handler) => (
        <div className="w-full h-full flex gap-2 justify-center items-center">
          {item.isConnected && (
            <DefaultButton
              size="tableButton"
              color="blue"
              onClick={() => {
                patchData(item);
              }}
            >
              공간 연결 해제
            </DefaultButton>
          )}
          <DefaultButton
            size="tableIcon"
            color="blue"
            onClick={() => {
              if (handler?.popup) {
                handler.popup({
                  header: "태그 상세 정보",
                  content: <TempHumTagDetailView id={item.id} />,
                });
              }
            }}
          >
            <FiEdit />
          </DefaultButton>
        </div>
      ),
      width: "200",
    },
  ];

  const componentList: ComponentType<Tag>[] = [
    {
      labelName: "등록일자",
      typeName: "dateRange",
      keyName: "createdAt",
      category: "date",
    },
    {
      labelName: "태그 유형",
      typeName: "comboBox",
      keyName: "tagTypeId",
      comboBoxOptions: addDefaultOption(
        toComboBoxType(tagType, "tagTypeId", "tagTypeName")
      ),
      category: "typeSearch",
    },
    {
      labelName: "공간 연결 상태",
      typeName: "comboBox",
      keyName: "isConnected",
      comboBoxOptions: addDefaultOption(CONNECT_OPTIONS),
      category: "typeSearch",
    },
    {
      labelName: "사용 유무",
      typeName: "comboBox",
      keyName: "isActive",
      comboBoxOptions: addDefaultOption(USAGE_OPTIONS),
      category: "typeSearch",
    },
    {
      labelName: "태그 ID",
      typeName: "text",
      keyName: "tagId",
      category: "detailSearch",
      placeholder: "태그 ID를 입력해주세요",
    },
    {
      labelName: "태그명",
      typeName: "text",
      keyName: "tagName",
      category: "detailSearch",
      placeholder: "태그명을 입력해주세요",
    },
    {
      typeName: "buildingAndFloor",
      keyName: "buildingAndFloor",
      category: "detailSearch",
      buildingGroupCategoryCode: buildingGroupCategoryCodeValue.IOT_DEVICE,
    },
    {
      labelName: "공간명",
      typeName: "text",
      keyName: "spaceName",
      category: "detailSearch",
      placeholder: "공간명을 입력해주세요",
    },
  ];

  useEffect(() => {
    fetchInitData();
  }, []);

  const fetchInitData = async () => {
    try {
      setIsLoading(true);

      const [tagTypeResponse, tagGroupTypeResponse] = await Promise.all([
        getTagType(),
        getTagGroupType(),
      ]);

      if (tagTypeResponse.data) {
        const options = filteredByGroupTypeId<TagType>(
          tagTypeResponse.data,
          TagGroupName.TEMP_HUM
        );
        options.sort((a, b) => a.tagTypeId - b.tagTypeId);

        setTagType(options);
      }

      if (tagGroupTypeResponse.data) {
        const options = filteredByGroupTypeId<TagGroupType>(
          tagGroupTypeResponse.data,
          TagGroupName.TEMP_HUM
        );
        options.sort((a, b) => a.tagGroupTypeId - b.tagGroupTypeId);

        setTagGroupType(options);
      }
    } catch (err: any) {
      handleError(err, "조회");
    } finally {
      setIsLoading(false);
    }
  };

  const patchData = async (tag: Tag) => {
    const message = `${tag.buildingName ?? ""} ${tag.floorName ?? ""} ${
      tag.tagName ?? ""
    }의 ${tag.tagTypeName ?? ""}\n태그 연결을 해제하시겠습니까?`;

    showConfirm(message, async () => {
      try {
        const response = await patchTag(tag.id);
        if (response.status === HttpStatusCode.Ok) {
          showAlert("연결끊기가 적용되었습니다.");
          setRefresh(!refresh);
        }
      } catch (err: any) {
        handleError(err, "연결끊기");
      }
    });
  };

  const getSensorListWrapper = useCallback(
    async (params: SearchCondition<Tag>) => {
      const sortParams = formatSortSearchCondition<Tag>({
        sortBy: { name: "createdAt", order: "desc" },
      });

      return await getSensorList({ ...params, ...sortParams });
    },
    [refresh]
  );

  return (
    <>
      {isLoading && <Spinner />}
      <ListPage
        getDataApi={getSensorListWrapper}
        excelUpload={
          <ExcelFileUploaderButton
            title="온습도공기 태그"
            getExcelDataApi={getTagBulkExcel}
            postExcelDataApi={postTagBulk}
          />
        }
        excelDownloadApi={getTagListExcel}
        columnInfo={columnInfo}
        componentList={componentList}
        tableTitle="목록"
        renderTopRight={
          <TempHumTagListTopRight
            tagType={tagType}
            tagGroupType={tagGroupType}
          />
        }
      />
    </>
  );
}
