type Props = {};

export default function ServiceMenuIcon(props: Props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.6">
        <path d="M19.5 19H4.5M19.5 5H4.5" stroke="white" />
        <path d="M6 15.4764H18V8.52368H6V15.4764Z" stroke="white" />
      </g>
    </svg>
  );
}
