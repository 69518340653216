import { SearchCondition } from "../components/ListPage";

import request, { ListResponse } from "../libs/request";
import {
  SpaceReservation,
  SpaceReservationCreateDto,
  SpaceReservationData,
} from "../types/spaceReservation";
import { getExcelDownloadApi } from "./commonService";

const SPACE_RESERVATION_API_PREFIX = "/v2/admin/space/reservation";

export type SpaceReservationListTotal = {
  spacePrice: number;
  devicePrice: number;
  totalPrice: number;
  fixedCompletedPrice: number;
  fixedCanceledPrice: number;
};

type SpaceReservationListResponse = SpaceReservationListTotal & {
  pageableItems: ListResponse<SpaceReservation>;
};

export const getSpaceReservationList = (
  params: SearchCondition<SpaceReservation>
) => {
  return request.get<SpaceReservationListResponse>(
    SPACE_RESERVATION_API_PREFIX + "/list",
    { params }
  );
};
export const getSpaceReservationListExcel = (
  params: SearchCondition<SpaceReservation>
) => getExcelDownloadApi(SPACE_RESERVATION_API_PREFIX + "/list/excel", params);

export const getSpaceReservation = (
  reservationSpaceId: string,
  reservationDate: string
) =>
  request.get<SpaceReservationData>(
    SPACE_RESERVATION_API_PREFIX + `/${reservationSpaceId}/${reservationDate}`
  );

export const getSpaceReservationDetail = (reservationId: string) =>
  request.get<SpaceReservationData>(
    SPACE_RESERVATION_API_PREFIX + `/detail/${reservationId}`
  );

export const getSpaceReservationRange = (
  buildingId: string,
  buildingFloorId: string,
  usePayment: boolean,
  reservationAtStart: string,
  reservationAtEnd: string
) =>
  request.get<SpaceReservationData[]>(
    SPACE_RESERVATION_API_PREFIX + `/list/range`,
    {
      params: {
        buildingId,
        buildingFloorId,
        usePayment,
        reservationAtStart,
        reservationAtEnd,
      },
    }
  );

export const getSpaceReservationDaily = (
  buildingId: string,
  buildingFloorId: string,
  usePayment: boolean,
  reservationAt: string
) =>
  request.get<SpaceReservationData[]>(
    SPACE_RESERVATION_API_PREFIX + `/list/daily`,
    {
      params: { buildingId, buildingFloorId, usePayment, reservationAt },
    }
  );

export const postSpaceReservation = (
  data: Partial<SpaceReservationCreateDto>
) => request.post(SPACE_RESERVATION_API_PREFIX, data);

export const postSpaceReservationBulk = (formData: FormData) =>
  request.post(SPACE_RESERVATION_API_PREFIX + "/list", formData);

export const postSpaceReservationLongTerm = (
  data: Partial<SpaceReservationCreateDto>
) => request.post(SPACE_RESERVATION_API_PREFIX + "/longterm", data);

// 전달 파라미터 : spaceReservationDetailId
export const patchSpaceReservationCancel = (
  reservationId: string,
  cancelComment: string
) =>
  request.patch(SPACE_RESERVATION_API_PREFIX + `/${reservationId}/cancel`, {
    cancelComment,
  });

// 전달 파라미터: longTermReservationId
export const patchSpaceReservationLongTermCancel = (
  reservationId: string,
  cancelComment: string
) =>
  request.patch(
    SPACE_RESERVATION_API_PREFIX + `/${reservationId}/long/term/cancel`,
    { cancelComment }
  );

export const patchSpaceReservationModifyPrice = (
  reservationId: string,
  modifyPrice: number
) =>
  request.patch(
    SPACE_RESERVATION_API_PREFIX +
      `/${reservationId}/modify/price?modifyPrice=${modifyPrice}`
  );

export const patchSpaceReservationConfirm = (reservationId: string) =>
  request.patch(SPACE_RESERVATION_API_PREFIX + `/${reservationId}/confirm`);

export const patchSpaceReservationCheckIn = (reservationId: string) =>
  request.patch(SPACE_RESERVATION_API_PREFIX + `/${reservationId}/check/in`);

export const putSpaceReservation = (
  reservationId: string,
  data: Partial<SpaceReservationCreateDto>
) => request.put(SPACE_RESERVATION_API_PREFIX + `/${reservationId}`, data);

export const putSpaceReservationLongTerm = (
  longTermReservationId: string,
  data: Partial<SpaceReservationCreateDto>
) =>
  request.put(
    SPACE_RESERVATION_API_PREFIX + `/long/term/${longTermReservationId}`,
    data
  );
