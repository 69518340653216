import clsx from "clsx";
import React from "react";

type Props = {
  totalPages: number;
  currentPage: number;
  onPageChange: (page: number) => void;
  pageLimit?: number;
};

const DEFAULT_PAGE_LIMIT_SIZE = 10;

export default function Pagination({
  totalPages,
  currentPage,
  onPageChange,
  pageLimit = DEFAULT_PAGE_LIMIT_SIZE,
}: Props) {
  const handleFirst = () => {
    if (currentPage > 1) {
      onPageChange(1);
    }
  };

  const handleLast = () => {
    if (currentPage < totalPages) {
      onPageChange(totalPages);
    }
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  const handlePageClick = (page: number) => {
    if (page !== currentPage) {
      onPageChange(page);
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const currentPageGroup = Math.ceil(currentPage / pageLimit);
    const startPage = Math.max((currentPageGroup - 1) * pageLimit + 1, 1);
    const endPage = Math.min(startPage + pageLimit - 1, totalPages);

    if (startPage > 1) {
      pageNumbers.push(
        <span key="start-ellipsis" className="mx-1">
          ...
        </span>
      );
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          className={`${
            currentPage === i
              ? "bg-black text-white px-2 -mx-1 h-[22px] rounded-[3px] font-bold"
              : "bg-white text-black mx-1"
          }`}
          onClick={() => handlePageClick(i)}
        >
          {i}
        </button>
      );
    }

    if (endPage < totalPages) {
      pageNumbers.push(
        <span key="end-ellipsis" className="mx-1">
          ...
        </span>
      );
    }

    return pageNumbers.length > 0
      ? pageNumbers
      : [
          <button
            key={1}
            className={`mx-1 bg-black text-white w-[22px] h-[22px] rounded-[3px] font-bold`}
            onClick={() => handlePageClick(1)}
          >
            {1}
          </button>,
        ];
  };

  return (
    <div
      className="flex justify-center bg-white p-5 gap-2.5 items-center"
      role="navigation"
      aria-label="pagination"
    >
      <button
        className={clsx(["opacity-30 hover:opacity-100 transition-colors"])}
        onClick={handleFirst}
        data-testid={"double-left"}
      >
        <PaginationDoubleLeft />
      </button>
      <button
        className={clsx(["opacity-30 hover:opacity-100 transition-colors"])}
        onClick={handlePrevious}
        data-testid="left"
      >
        <PaginationLeft />
      </button>
      <div className="px-5 flex items-center gap-5 text-xs">
        {renderPageNumbers()}
      </div>
      <button
        className={clsx(["opacity-30 hover:opacity-100 transition-colors"])}
        onClick={handleNext}
        data-testid={"right"}
      >
        <PaginationRight />
      </button>
      <button
        className={clsx(["opacity-30 hover:opacity-100 transition-colors"])}
        onClick={handleLast}
        data-testid={"double-right"}
      >
        <PaginationDoubleRight />
      </button>
    </div>
  );
}

export const PaginationRight = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.79989 16.78L13.9099 12.67C14.0961 12.4827 14.2007 12.2292 14.2007 11.965C14.2007 11.7008 14.0961 11.4474 13.9099 11.26L9.90989 7.26003"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="square"
      strokeLinejoin="bevel"
    />
  </svg>
);

export const PaginationDoubleRight = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.79989 16.78L13.9099 12.67C14.0961 12.4827 14.2007 12.2292 14.2007 11.965C14.2007 11.7008 14.0961 11.4474 13.9099 11.26L9.90989 7.26003"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="square"
      strokeLinejoin="bevel"
    />
    <path
      d="M5.59921 16.78L9.70921 12.67C9.89546 12.4827 10 12.2292 10 11.965C10 11.7008 9.89546 11.4474 9.70921 11.26L5.70921 7.26003"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="square"
      strokeLinejoin="bevel"
    />
  </svg>
);

export const PaginationLeft = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.2001 7.21997L10.0901 11.33C9.90386 11.5173 9.79932 11.7708 9.79932 12.035C9.79932 12.2992 9.90386 12.5526 10.0901 12.74L14.0901 16.74"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="square"
      strokeLinejoin="bevel"
    />
  </svg>
);

export const PaginationDoubleLeft = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.2001 7.21997L10.0901 11.33C9.90386 11.5173 9.79932 11.7708 9.79932 12.035C9.79932 12.2992 9.90386 12.5526 10.0901 12.74L14.0901 16.74"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="square"
      strokeLinejoin="bevel"
    />
    <path
      d="M18.4008 7.21997L14.2908 11.33C14.1045 11.5173 14 11.7708 14 12.035C14 12.2992 14.1045 12.5526 14.2908 12.74L18.2908 16.74"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="square"
      strokeLinejoin="bevel"
    />
  </svg>
);
