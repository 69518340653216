import { useEffect } from "react";
import { WeekDay } from "../../../types/space";

type Props = {
  weekDay: WeekDay;
  enabledWeekDay?: WeekDay;
  onWeekDayChange: (newWeek: WeekDay) => void;
};

export default function CheckboxDays({
  weekDay,
  enabledWeekDay,
  onWeekDayChange,
}: Props) {
  const dayNames = ["월", "화", "수", "목", "금", "토", "일"];

  const handleChange = (day: keyof WeekDay) => {
    onWeekDayChange({
      ...weekDay,
      [day]: !weekDay[day],
    });
  };

  useEffect(() => {
    if (enabledWeekDay) {
      const newWeekDay = { ...weekDay };
      Object.entries(enabledWeekDay).forEach(([day, enabled]) => {
        if (!enabled && weekDay[day as keyof WeekDay]) {
          newWeekDay[day as keyof WeekDay] = false;
        }
      });
      onWeekDayChange(newWeekDay);
    }
  }, [enabledWeekDay]);

  return (
    <div className="flex gap-4 items-center">
      {Object.entries(weekDay).map(([day, checked], index) => (
        <label key={index} className="flex gap-1">
          <input
            type="checkbox"
            id={day}
            checked={checked}
            onChange={() => handleChange(day as keyof WeekDay)}
            disabled={
              enabledWeekDay ? !enabledWeekDay[day as keyof WeekDay] : false
            }
          />
          <span>{dayNames[index % dayNames.length]}</span>
        </label>
      ))}
    </div>
  );
}
