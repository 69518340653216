import { FiEdit2 } from "react-icons/fi";
import DefaultButton from "../../../../components/DefaultButton";
import ListPage from "../../../../components/ListPage";
import { TableColumnType } from "../../../../components/Table";
import { useModal } from "../../../../contexts/Modal";
import {
  getEtcCategory,
  getEtcTermsList,
  getEtcTermsListExcel,
} from "../../../../services/etcService";
import UseNoIcon from "../../../../svgs/icons/UseYn/UseNoIcon";
import UseYesIcon from "../../../../svgs/icons/UseYn/UseYesIcon";
import { EtcTerms } from "../../../../types/etc";
import { ComboBoxType, ComponentType } from "../../../../types/search";
import {
  addDefaultOption,
  toComboBoxType,
} from "../../../../utils/comboBoxUtils";
import { USAGE_OPTIONS } from "../../../../types/comboBoxOption";
import { useEffect, useState } from "react";
import Spinner from "../../../../components/Spinner";
import { HttpStatusCode } from "axios";
import TermsListTopRight from "../../../../components/Management/TermsList/ListTopRight";
import TermsDetailView from "../../../../components/Management/TermsList/DetailView";

type Props = {};
export default function TermsList(props: Props) {
  const { handleError } = useModal();
  const [category, setCategory] = useState<ComboBoxType[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const columnInfo: TableColumnType<EtcTerms>[] = [
    { header: "약관 번호", name: "termsId", width: "id" },
    { header: "약관 유형", name: "categoryName", width: "200" },
    { header: "약관명", name: "name" },
    { header: "약관 설명", name: "description" },
    { header: "약관 버전", name: "version", width: "100" },
    {
      header: "샤용 여부",
      render: (item: EtcTerms) => (
        <div className="flex justify-center items-center">
          {item.isUse ? <UseYesIcon /> : <UseNoIcon />}
        </div>
      ),
      width: "100",
    },
    { header: "등록자명", name: "createdByName", width: "name" },
    { header: "등록일시", name: "createdAt", width: "date" },
    {
      header: "활동",
      render: (item: EtcTerms, handler) => (
        <div className="flex justify-center items-center">
          <DefaultButton
            size="tableIcon"
            color="blue"
            onClick={() => {
              if (handler?.popup) {
                handler.popup({
                  header: "약관 상세",
                  content: <TermsDetailView termsId={item.termsId} />,
                });
              }
            }}
          >
            <FiEdit2 />
          </DefaultButton>
        </div>
      ),
      width: "icon",
    },
  ];

  const componentList: ComponentType<EtcTerms>[] = [
    {
      labelName: "약관 유형",
      typeName: "comboBox",
      keyName: "termsCategoryId",
      category: "typeSearch",
      comboBoxOptions: addDefaultOption(category),
    },
    {
      labelName: "사용 여부",
      typeName: "comboBox",
      keyName: "isUse",
      category: "typeSearch",
      comboBoxOptions: addDefaultOption(USAGE_OPTIONS),
    },
    {
      labelName: "약관명",
      typeName: "text",
      keyName: "name",
      category: "detailSearch",
      placeholder: "약관명을 입력해주세요",
    },
  ];

  useEffect(() => {
    const fetchCategoryData = async () => {
      try {
        setIsLoading(true);

        const res = await getEtcCategory();
        if (res.status === HttpStatusCode.Ok) {
          setCategory(toComboBoxType(res.data, "termsCategoryId", "name"));
        }
      } catch (err: any) {
        handleError(err, "카테고리 조회");
      } finally {
        setIsLoading(false);
      }
    };

    fetchCategoryData();
  }, []);

  return (
    <>
      {isLoading && <Spinner />}
      <ListPage
        getDataApi={getEtcTermsList}
        keyId={(item: EtcTerms) => item.termsId}
        columnInfo={columnInfo}
        componentList={componentList}
        tableTitle="약관 목록"
        excelDownloadApi={getEtcTermsListExcel}
        renderTopRight={<TermsListTopRight category={category} />}
        needExcelDownloadReason
      />
    </>
  );
}
