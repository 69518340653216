import { useContext, useEffect, useState } from "react";
import { SearchTagData, TagType } from "../TagFilter";
import { PopupContext } from "../../contexts/Popup";
import { TableColumnType, TableHeight } from "../Table";
import DefaultButton from "../DefaultButton";
import ListPage, { SearchCondition } from "../ListPage";
import { ListResponse } from "../../libs/request";
import { AxiosResponse } from "axios";
import { ComponentType } from "../../types/search";
import { TagData } from "../TagList";
import { useModal } from "../../contexts/Modal";
import clsx from "clsx";
import { PostCategory } from "@/types/post";
import { buildingSearchType } from "../Board/PostList/CreateForm";
import {
  BuildingServiceGroupType,
  ServiceGroupingForMulti,
  SmallBuildingType,
} from "@/types/building";

type Props<T> = {
  getDataApi: (
    searchCondition: SearchCondition<T>
  ) => Promise<AxiosResponse<ListResponse<T> | T[]>>;
  componentList: ComponentType<T>[];
  reftTableComponentList?: ComponentType<T>[];
  columnInfo: TableColumnType<T>[];
  title: string;
  tagType?: TagType;
  tags: SearchTagData;
  initSearchCondition?: SearchCondition<T>;
  tableHeight?: TableHeight;
  isHorizontal?: boolean;
  inline?: boolean;
  hidePagination?: boolean;
  hidePageSize?: boolean;
  setTags: (value: SearchTagData) => void;
  compareItem: (item: T) => (o: T) => boolean;
  getTagItem: (item: T) => TagData;
  postCategory?: PostCategory; // multi 에 대한 분기 처리용
  setBuildingSearch?: React.Dispatch<React.SetStateAction<buildingSearchType>>;
  selectedDataForMulti?: ServiceGroupingForMulti;
  setSelectedDataForMulti?: React.Dispatch<
    React.SetStateAction<ServiceGroupingForMulti>
  >;
};

export default function TagSelector<T>({
  getDataApi,
  componentList,
  reftTableComponentList,
  columnInfo,
  title,
  tagType,
  tags,
  initSearchCondition,
  tableHeight,
  isHorizontal = false,
  inline = false,
  hidePagination = false,
  hidePageSize = false,
  setTags,
  compareItem,
  getTagItem,
  postCategory,
  setBuildingSearch,
  selectedDataForMulti,
  setSelectedDataForMulti,
}: Props<T>) {
  const { closePopup } = useContext(PopupContext);
  const { showConfirm, showAlert } = useModal();
  const [selectedData, setSelectedData] = useState<T[]>(
    tags?.data?.map((item) => item.data) ?? []
  );
  const [addedList, setAddedList] = useState<BuildingServiceGroupType[]>([]); // 추가한 BuildingServiceGroupType 목록 -> 하위컴포넌트에 내려서 useEffect 에서 변화가 있을때마다 data state 에서 제거해준다.

  useEffect(() => {
    if (inline) {
      const left = tags.data?.map((item) => item.data);
      const right = selectedData;

      if (JSON.stringify(left) === JSON.stringify(right)) {
        return;
      }

      setTags({
        data: selectedData.map((item) => getTagItem(item)),
        type: tagType,
      });
    }
  }, [selectedData]);

  useEffect(() => {
    setSelectedData(tags?.data?.map((tag) => tag.data) ?? []);
  }, [tags]);

  const handleVisible = (item: T) => {
    return selectedData.some(compareItem(item));
  };

  const handleAdd = (item: T) => {
    setSelectedData((prev) => [item, ...prev]);
  };

  const handleRemove = (item: T) => {
    setSelectedData((prev) => prev.filter((o) => o !== item));
  };

  const renderAdd = (item: T) => {
    const visible = handleVisible(item);

    return (
      <div className="w-full h-3 flex gap-2 justify-center items-center">
        {!visible && (
          <DefaultButton
            onClick={() => {
              postCategory !== "multi"
                ? handleAdd(item)
                : handleAddForMulti(item as BuildingServiceGroupType);
            }}
          >
            추가
          </DefaultButton>
        )}
      </div>
    );
  };

  const renderRemove = (item: T) => (
    <div className="w-full h-3 flex gap-2 justify-center items-center">
      <DefaultButton
        onClick={() => {
          postCategory !== "multi"
            ? handleRemove(item)
            : handleRemoveForMulti(item as BuildingServiceGroupType);
        }}
      >
        삭제
      </DefaultButton>
    </div>
  );

  const headerAddAll = (data: T[]) => {
    return (
      <DefaultButton
        color="white"
        className="h-[22px] text-xs font-normal"
        onClick={
          postCategory !== "multi"
            ? () => {
                setSelectedData((prev) => {
                  const newData = data.filter(
                    (item) => !prev.some(compareItem(item))
                  );
                  return [...newData, ...prev];
                });
              }
            : () => headerAddAllForMulti(data as BuildingServiceGroupType[])
        }
      >
        전체 선택
      </DefaultButton>
    );
  };

  const headerRemoveAll = () => {
    return (
      <DefaultButton
        color="white"
        className="h-[22px] text-xs font-normal"
        onClick={() => setSelectedData([])}
      >
        전체 해제
      </DefaultButton>
    );
  };

  const headerAddAllForMulti = (items: BuildingServiceGroupType[]) => {
    if (setSelectedDataForMulti) {
      setSelectedDataForMulti((prev) => {
        const newData = { ...prev };
        const newAddedList: BuildingServiceGroupType[] = [];

        items.forEach((item) => {
          if (!newData[item.groupId]) {
            newData[item.groupId] = {
              groupName: item.groupName,
              buildingList: [],
            };
          }

          const existingItem = newData[item.groupId].buildingList.find(
            (building) => building.buildingId === item.buildingId
          );

          if (!existingItem) {
            newData[item.groupId].buildingList.push({
              buildingId: item.buildingId,
              buildingName: item.buildingName,
            });
            newAddedList.push(item);
          } else {
            showAlert("이미 추가된 건물 입니다.");
          }
        });

        setAddedList((prev) => [...newAddedList, ...prev]);

        return newData;
      });
    }
  };

  const handleAddForMulti = (item: BuildingServiceGroupType) => {
    if (setSelectedDataForMulti) {
      setSelectedDataForMulti((prev) => {
        const newData = { ...prev };

        // groupId가 없으면 초기화
        if (!newData[item.groupId]) {
          newData[item.groupId] = {
            groupName: item.groupName,
            buildingList: [],
          };
        }

        // buildingList에 해당 항목이 이미 있는지 확인
        const existingItem = newData[item.groupId].buildingList.find(
          (building) => building.buildingId === item.buildingId
        );

        // 존재하지 않을 때만 추가
        if (!existingItem) {
          newData[item.groupId].buildingList.push({
            buildingId: item.buildingId,
            buildingName: item.buildingName,
          });
          setAddedList((prev) => {
            // 중복 확인 한번 더 진행
            const existingAddedItem = prev.find(
              (added) =>
                added.buildingId === item.buildingId &&
                added.groupId === item.groupId
            );
            if (!existingAddedItem) {
              return [item, ...prev];
            }
            return prev;
          });
        } else {
          // 중복
          showAlert("이미 추가된 건물 입니다.");
        }

        return newData;
      });
    }
  };

  const handleRemoveForMulti = (item: SmallBuildingType) => {
    const groupId = item.groupId;
    const buildingId = item.buildingId;

    if (setSelectedDataForMulti) {
      setSelectedDataForMulti((prev) => {
        const newData = { ...prev };
        if (newData[groupId]) {
          newData[groupId].buildingList = newData[groupId].buildingList.filter(
            (building) => building.buildingId !== buildingId
          );
          if (newData[groupId].buildingList.length === 0) {
            delete newData[groupId];
          }
          setAddedList((prev) =>
            prev.filter(
              (o) => !(o.buildingId === buildingId && o.groupId === groupId)
            )
          );
        }
        return newData;
      });
    }
  };

  const divClassName = clsx("flex", {
    "flex-row": isHorizontal,
    "flex-col": !isHorizontal,
  });
  const leftDivClassName = clsx("min-w-[600px] w-full", {
    "pb-4": !isHorizontal,
  });

  return (
    <div className="flex flex-col gap-5">
      <div className={divClassName}>
        <div className="flex items-end">
          <div className={leftDivClassName}>
            <ListPage
              getDataApi={getDataApi}
              columnInfo={[
                ...columnInfo,
                {
                  header: "활동",
                  render: renderAdd,
                  headerRender: headerAddAll,
                },
              ]}
              componentList={componentList}
              tableTitle={title}
              isTableScroll={true}
              initSearchCondition={initSearchCondition}
              tableHeight={tableHeight}
              hidePagination={hidePagination}
              hidePageSize={hidePageSize}
              isSmallButton
              postCategory={postCategory}
              initSearch={false}
              setBuildingSearch={setBuildingSearch}
              addedList={addedList}
              tableComponentList={reftTableComponentList}
            />
          </div>
        </div>
        <div className="flex items-center justify-center  ">
          {isHorizontal ? <RightArrowIcon /> : <RightArrowIcon directionDown />}
        </div>
        <div className="flex items-end">
          <div className="min-w-[600px] w-full h-full">
            <ListPage
              getDataApi={getDataApi}
              selectedData={selectedData}
              columnInfo={[
                ...columnInfo,
                {
                  header: "활동",
                  render: renderRemove,
                  headerRender: headerRemoveAll,
                },
              ]}
              componentList={componentList}
              tableTitle={
                postCategory !== "multi"
                  ? `선택 ${title}`
                  : "선택 게시판 그룹 목록"
              }
              selectedMode={true}
              isTableScroll={true}
              tableHeight={tableHeight}
              hidePagination={hidePagination}
              hidePageSize={hidePageSize}
              isSmallButton
              postCategory={postCategory}
              selectedDataForMulti={selectedDataForMulti}
              handleRemoveForMulti={handleRemoveForMulti}
            />
          </div>
        </div>
      </div>
      {!inline && (
        <div className="flex justify-center gap-2">
          <DefaultButton onClick={closePopup}>닫기</DefaultButton>
          <DefaultButton
            color="primary"
            onClick={() => {
              showConfirm("적용 하시겠습니까?", async () => {
                setTags({
                  data: selectedData.map((item) => getTagItem(item)),
                  type: tagType,
                });
                closePopup();
              });
            }}
          >
            적용
          </DefaultButton>
        </div>
      )}
    </div>
  );
}

export const RightArrowIcon = ({
  directionDown = false,
}: {
  directionDown?: boolean;
}) => (
  <div
    className={clsx("inline-block", {
      "rotate-90": directionDown,
    })}
  >
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.7742 18.335L41.0916 30L32.7742 41.665L10.5947 41.665L10.5947 18.335L32.7742 18.335Z"
        fill="#D9D9D9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.0575 9L50.5696 30.0001L32.0575 51.0001L32.0575 9Z"
        fill="#D9D9D9"
      />
    </svg>
  </div>
);
