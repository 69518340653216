import { GOODS_HISTORY_TYPES } from "./comboBoxOption";
import { ImageType } from "./incommodity";

export type GoodsRequestItem = {
  goodsId: string;
  goodsTypeId: GoodsRequestItemType;
  goodsName: string;
  goodsDescription: string;
  buildingName: string;
  companyId: string;
  companyName: string;
  createdBy: number;
  createdAt: string;
  modifiedBy: number;
  modifiedAt: string;
  isActive: boolean;
  isDelete: boolean;
  uploadFile: File;
  image: ImageType;
};

export type GoodsRentalItem = {
  goodsId: string;
  goodsTypeId: GoodsRentalItemType;
  goodsName: string;
  createdBy: number;
  createdAt: string;
  modifiedBy: number;
  modifiedAt: string;
  isActive: boolean;
  isDelete: boolean;
  goodsDescription: string;
  uploadFile: File;
  image: ImageType;
};

export type GoodsRentalStock = {
  stockId: string;
  goodsId: string;
  serviceType: GoodsServiceType;
  goodsTypeId: GoodsRentalStockType;
  image: ImageType;
  goodsName: string;
  buildingId: string;
  buildingName: string;
  storeId: string;
  storeName: string;
  goodsDescription: string;
  useStock: boolean;
  stockCount: number;
  restoreCount: number;
  restorableCount: number;
  availableCount: number;
  createdBy: number;
  createdAt: string;
  modifiedBy: number;
  modifiedAt: string;
  isActive: boolean;
  uploadFile: File;
};

export type GoodsRentalStockHistory = {
  goodsStockHistoryId: string;
  stockId: string;
  goodsServiceItemId: string;
  goodsTypeId: GoodsRentalStockType;
  historyType: string;
  goodsId: string;
  goodsName: string;
  buildingId: string;
  buildingName: string;
  storeId: string;
  storeName: string;
  modifiedCount: number;
  lostDamagedCount: number;
  restoredCount: number;
  stockCount: number;
  createdBy: number;
  createdByName: string;
  leasedBy: string;
  leasedByName: string;
  leasedByMobileNumber: string;
  leasedByCompanyName: string;
  createdAt: string;
};

export type GoodsRental = {
  goodsServiceId: string;
  goodsId: string;
  goodsName: string;
  status: GoodsRentalStatus;
  goodsTypeId: GoodsRentalItemType;
  rentalBuildingId: string;
  rentalBuildingName: string;
  rentalBuildingFloorId: string;
  rentalBuildingFloorName: string;
  rentalStoreId: string;
  rentalStoreName: string;
  returnBuildingId: string;
  returnBuildingName: string;
  returnBuildingFloorId: string;
  returnBuildingFloorName: string;
  returnStoreId: string;
  returnStoreName: string;
  rentalAt: string;
  returnDueAt: string;
  actualReturnAt: string;
  lostDamagedAt: string;
  isReturnByAdmin: boolean;
  createdAt: string;
  canceledAt: string;
  createdBy: number;
  createdByName: string;
  createdByCompanyId: string;
  createdByCompanyName: string;
  createdByMobileNumber: string;

  buildingId: string;
  isLostDamage: boolean;
  userCompanyName: string;
  userName: string;
};

export type GoodsRentalStatus = keyof typeof GOODS_RENTAL_STATUS_TYPES;

export const GOODS_RENTAL_STATUS_TYPES = {
  REQUESTED: "REQUESTED",
  CANCELED: "CANCELED",
  RENTED: "RENTED",
  COMPLETED: "COMPLETED",
  LOST_DAMAGED: "LOST_DAMAGED",
  OVERDUE: "OVERDUE",
  DONE: "DONE",
};

export const goodsRentalStatusLabels: Record<GoodsRentalStatus, string> = {
  REQUESTED: "신청",
  CANCELED: "취소",
  RENTED: "대여중",
  COMPLETED: "반납",
  LOST_DAMAGED: "분실/손상",
  OVERDUE: "연체",
  DONE: "완료",
};

export type GoodsRequestItemType = "REQUEST" | "REFILL";

export const goodsRequestItemLabels: Record<GoodsRequestItemType, string> = {
  REFILL: "리필 품목",
  REQUEST: "요청 품목",
};

export type GoodsRentalItemType = "UMBRELLA" | "TOOLS" | "BOOK" | "ITEM";

export const goodsRentalItemLabels: Record<GoodsRentalItemType, string> = {
  UMBRELLA: "우산",
  TOOLS: "공구",
  BOOK: "책",
  ITEM: "물품",
};

export type GoodsRentalStockType = GoodsRequestItemType | GoodsRentalItemType;

export const goodsRentalStockLabels: Record<GoodsRentalStockType, string> = {
  ...goodsRequestItemLabels,
  ...goodsRentalItemLabels,
};

export type GoodsServiceType = "RENTAL" | "REQUEST";

export const goodsServiceLabels: Record<GoodsServiceType, string> = {
  RENTAL: "대여",
  REQUEST: "사무용품 신청",
};

export type GoodsHistoryType = keyof typeof GOODS_HISTORY_TYPES;

export const goodsHistoryLabels: Record<GoodsHistoryType, string> = {
  TOTAL_MODIFIED: "재고 변경",
  LOST_DAMAGED: "분실/손상 처리",
  RESTORED: "분실/손상 복원",
  MOVED: "타 상점 반납",
};
