import { useRoutesContext } from "../../contexts/Routes";

import { useMenuPermission } from "../../hooks/useMenuPermission";

type Props = { children: React.ReactNode; fallback?: React.ReactNode };

const MENU_PERMISSION = {
  ALL: "ALL",
  R: "R",
};

export default function PermissionWrapper({
  children,
  fallback = null,
}: Props) {
  const { routesContextInfo } = useRoutesContext();
  const scope = useMenuPermission(routesContextInfo.routeInfo ?? []);
  return scope === MENU_PERMISSION.ALL ? <>{children}</> : <>{fallback}</>;
}
