import { useEffect, useState } from "react";

import { IncommodityCategoryList } from "../../../types/incommodity";
import {
  DEFAULT_SELECT_ALL_VALUE,
  SUB_DEFAULT_SELECT_ALL_VALUE,
} from "../../../types/comboBoxOption";

import {
  addDefaultOption,
  toComboBoxType,
  transformToComboBoxTypeArray,
} from "../../../utils/comboBoxUtils";

import DefaultSelect from "../../SelectBox/DefaultSelect";

type Props = {
  data: IncommodityCategoryList;
  value?: string;
  label?: string;
  allOptionLabels?: string[];
  onChange: (value: string) => void;
};

export default function IncommodityCategorySelect({
  data,
  value,
  label = "",
  allOptionLabels = [],
  onChange,
}: Props) {
  const [main, setMain] = useState("");

  const handleMainChange = (mainValue: string) => {
    setMain(mainValue);
    onChange(mainValue);
  };

  const handleSubChange = (subValue: string) => {
    if (subValue === DEFAULT_SELECT_ALL_VALUE) {
      onChange(SUB_DEFAULT_SELECT_ALL_VALUE);
    } else {
      onChange(subValue);
    }
  };

  useEffect(() => {
    if (!value) {
      setMain("");
    }
  }, [value]);

  useEffect(() => {
    if (allOptionLabels.length === 0 && !main && Object.keys(data).length > 0) {
      const mainValue = Object.keys(data)[0];
      setMain(mainValue);
    }
  }, [data]);

  const mainCategories = transformToComboBoxTypeArray(Object.keys(data));
  const subCategories = toComboBoxType(
    main
      ? data[main] ?? []
      : allOptionLabels.length === 0
      ? data[mainCategories?.[0]?.value] ?? []
      : [],
    "serviceTemplateId",
    "serviceName"
  );

  return (
    <div className="flex flex-col gap-2 min-w-fit">
      {label && <span className="block text-label">{label}</span>}
      <div className="flex gap-2 min-w-fit">
        <DefaultSelect
          value={main}
          onChange={handleMainChange}
          optionList={
            allOptionLabels && allOptionLabels.length > 0
              ? addDefaultOption(mainCategories, allOptionLabels[0])
              : mainCategories
          }
        />
        <DefaultSelect
          value={value}
          onChange={handleSubChange}
          optionList={
            allOptionLabels && allOptionLabels.length > 1
              ? addDefaultOption(subCategories, allOptionLabels[1])
              : subCategories
          }
        />
      </div>
    </div>
  );
}
