import { AxiosResponse, HttpStatusCode } from "axios";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { PopupProvider } from "../../contexts/Popup";
import { ListResponse } from "../../libs/request";
import { initPageable, Pageable } from "../../types/pageable";
import { ComponentType } from "../../types/search";
import Pagination from "../Pagination";
import Popup from "../Popup";
import SearchBox from "../SearchBox";
import Table, { TableColumnType, TableHeight } from "../Table";
import Spinner from "../Spinner";
import TableSearchBox from "../SearchBox/TableSearchBox";
import { useModal } from "../../contexts/Modal";
import { formatSortSearchCondition } from "../../utils/formatUtils";
import { PostCategory } from "@/types/post";
import { buildingSearchType } from "../Board/PostList/CreateForm";
import {
  BuildingServiceGroupType,
  ServiceGroupingForMulti,
  SmallBuildingType,
} from "@/types/building";

interface ListPageContextType {
  refreshListPage: (onComplete?: () => void) => {};
}

const ListPageContext = createContext<ListPageContextType | undefined>(
  undefined
);

export function useListPageContext() {
  const context = useContext(ListPageContext);
  if (!context) {
    throw new Error("useListPage must be used within a ListPageProvider");
  }
  return context;
}

export type SearchCondition<T> = Partial<Record<keyof T, any>> & {
  page?: number;
  pageSize?: number;
  sort?: string;
  sortBy?: { name: keyof T; order: "asc" | "desc" };
};

type Props<T> = {
  getDataApi: (
    searchCondition: SearchCondition<T>
  ) => Promise<AxiosResponse<ListResponse<T> | T[]>>;
  excelDownloadApi?: (searchCondition: SearchCondition<T>) => Promise<void>;
  keyId?: (item: T) => string | number;
  excelUpload?: React.ReactNode;
  columnInfo: TableColumnType<T>[];
  componentList?: ComponentType<T>[];
  tableComponentList?: ComponentType<T>[];
  tableTitle?: string;
  renderTopRight?: React.ReactNode;
  initSearchCondition?: SearchCondition<T>;
  defaultPageSize?: number;
  defaultPageSizeList?: number[];
  pageLimit?: number;
  initSearch?: boolean;
  selectedData?: T[];
  selectedMode?: boolean;
  isTableScroll?: boolean;
  tableHeight?: TableHeight;
  hidePagination?: boolean;
  hidePageSize?: boolean;
  onlyRenderTable?: boolean;
  isSmallButton?: boolean;
  defaultContentText?: string;
  listInfo?: React.ReactNode;
  needExcelDownloadReason?: boolean;
  postCategory?: PostCategory;
  setBuildingSearch?: React.Dispatch<React.SetStateAction<buildingSearchType>>;
  selectedDataForMulti?: ServiceGroupingForMulti;
  handleRemoveForMulti?: (item: SmallBuildingType) => void;
  addedList?: BuildingServiceGroupType[];
  warningText?: string;
  refresh?: boolean;
  setRefresh?: React.Dispatch<React.SetStateAction<boolean>>;
  onChangeSearch?: (search: SearchCondition<T>) => void;
};

export default function ListPage<T>({
  getDataApi,
  excelDownloadApi,
  keyId,
  excelUpload,
  columnInfo,
  tableTitle,
  renderTopRight,
  tableHeight,
  listInfo,
  defaultContentText,
  needExcelDownloadReason = false,
  componentList = [],
  tableComponentList = [],
  initSearchCondition = {},
  defaultPageSize = 10,
  defaultPageSizeList = [10, 20, 30],
  pageLimit = 10,
  initSearch = true,
  selectedData = [], // selectedMode 모드 전용
  selectedMode = false,
  isTableScroll = false,
  hidePagination = false,
  hidePageSize = false,
  onlyRenderTable = false,
  isSmallButton = false,
  postCategory,
  setBuildingSearch,
  selectedDataForMulti,
  handleRemoveForMulti,
  addedList,
  warningText,
  refresh,
  setRefresh,
  onChangeSearch,
}: Props<T>) {
  const { handleError } = useModal();
  const [data, setData] = useState<T[]>([]);
  const [pageable, setPageable] = useState<Pageable>(initPageable);
  const [searchCondition, setSearchCondition] = useState<SearchCondition<T>>({
    ...initSearchCondition,
    ...({
      page: 1,
      pageSize: defaultPageSize ?? defaultPageSizeList[0],
    } as SearchCondition<T>),
  });
  const [isInitSearch, setIsInitSearch] = useState(initSearch);
  const [isLoading, setIsLoading] = useState(false);
  const [isInitialDataLoadComplete, setIsInitialDataLoadComplete] =
    useState(false);

  const handleInitialDataLoadComplete = () => {
    setIsInitialDataLoadComplete(true);
  };

  const getDataFromServer = useCallback(
    async (searchCondition: SearchCondition<T>) => {
      const condition = formatSortSearchCondition(searchCondition);
      try {
        setIsLoading(true);
        const response = await getDataApi(condition as SearchCondition<T>);

        if (response.status === HttpStatusCode.Ok) {
          if (isListResponse(response.data)) {
            setData((response.data as ListResponse<T>).items);
            setPageable(response.data.pageable);
          } else {
            setData(response.data);
            setPageable({
              page: 1,
              pageSize: response.data.length,
              total: response.data.length,
              totalPages: 1,
              offset: 0,
              sort: "",
            });
          }
        } else {
          throw new Error("ListPage data load fail");
        }
      } catch (err: any) {
        handleError(err, "목록 조회");
      } finally {
        setIsLoading(false);
      }
    },
    [getDataApi]
  );

  const refreshListPage = (onComplete?: () => void) =>
    getDataFromServer(searchCondition).then(onComplete);

  function isListResponse<T>(data: any): data is ListResponse<T> {
    return data.hasOwnProperty("items");
  }

  useEffect(() => {
    if (refresh && setRefresh) {
      setRefresh(false);
      getDataFromServer(searchCondition);
    }
  }, [refresh]);

  useEffect(() => {
    if (isInitSearch === false) {
      setIsInitSearch(true);
      return;
    }

    if (selectedMode) {
      if (postCategory === "multi" && selectedMode) {
        const page = searchCondition.page ?? 1;
        const pageSize = searchCondition.pageSize ?? defaultPageSize;
        const pageData = {
          page: page,
          pageSize: pageSize,
          total: selectedDataForMulti
            ? Object.keys(selectedDataForMulti).length
            : 0,
          totalPages: selectedDataForMulti
            ? Math.ceil(Object.keys(selectedDataForMulti).length / pageSize)
            : 1,
        } as Pageable;

        setPageable(pageData);
      } else {
        const page = searchCondition.page ?? 1;
        const pageSize = searchCondition.pageSize ?? defaultPageSize;
        const pageData = {
          page: page,
          pageSize: pageSize,
          total: selectedData.length,
          totalPages: Math.ceil(selectedData.length / pageSize),
        } as Pageable;

        setPageable(pageData);
      }
    } else {
      if (isInitialDataLoadComplete) {
        getDataFromServer(searchCondition);
      }
    }
  }, [
    searchCondition,
    getDataFromServer,
    isInitialDataLoadComplete,
    selectedData.length,
    selectedDataForMulti,
  ]);

  useEffect(() => {
    // addedList 에 있는건 data 에 없어야 함.
    if (
      addedList &&
      data.length > 0 &&
      postCategory &&
      postCategory === "multi"
    ) {
      let length = 0;
      setData((prev) => {
        const preData = prev as BuildingServiceGroupType[];
        const newData = preData.filter(
          (data) =>
            !addedList.some(
              (added) =>
                added.buildingId === data.buildingId &&
                added.groupId === data.groupId
            )
        );
        length = newData.length;
        return newData as unknown as T[];
      });

      setPageable((prev) => ({
        ...prev,
        total: length,
      }));
    }
  }, [addedList, isLoading]); // 조회 시 에도 목록을 정제하기 위함.

  const renderTableSearchBox = () => {
    if (tableComponentList.length > 0) {
      return (
        <TableSearchBox
          setSearchCondition={setSearchCondition}
          componentList={tableComponentList}
        />
      );
    } else {
      return null;
    }
  };

  useEffect(() => {
    onChangeSearch?.(searchCondition);
  }, [searchCondition]);

  return (
    <PopupProvider>
      <ListPageContext.Provider value={{ refreshListPage }}>
        {isLoading && <Spinner />}
        <div className="flex flex-col gap-4 w-full">
          <div className="sticky top-0">
            {!selectedMode && !onlyRenderTable && (
              <SearchBox
                initSearchCondition={initSearchCondition}
                setSearchCondition={setSearchCondition}
                componentList={componentList}
                handleInitialDataLoadComplete={handleInitialDataLoadComplete}
                isSmallButton={isSmallButton}
                onChange={onChangeSearch}
              />
            )}
          </div>
          <div className="bg-white rounded-[3px] shadow">
            <Table
              data={
                selectedMode
                  ? !(hidePagination || onlyRenderTable)
                    ? selectedData.slice(
                        (pageable.page - 1) *
                          (searchCondition.pageSize ?? defaultPageSize),
                        pageable.page *
                          (searchCondition.pageSize ?? defaultPageSize)
                      )
                    : selectedData
                  : data
              }
              columnInfo={columnInfo}
              keyId={keyId}
              tableTitle={tableTitle}
              searchCondition={searchCondition}
              setSearchCondition={setSearchCondition}
              renderTopRight={renderTopRight}
              defaultPageSizeList={defaultPageSizeList}
              excelDownloadApi={excelDownloadApi}
              excelUpload={excelUpload}
              total={pageable.total}
              listInfo={listInfo}
              hidePageSize={hidePageSize}
              pageable={pageable}
              isTableScroll={isTableScroll}
              tableHeight={tableHeight}
              onlyRenderTable={onlyRenderTable}
              renderTableSearchBox={renderTableSearchBox()}
              defaultContentText={defaultContentText}
              needExcelDownloadReason={needExcelDownloadReason}
              postCategory={postCategory}
              selectedMode={selectedMode}
              setBuildingSearch={setBuildingSearch}
              selectedDataForMulti={selectedDataForMulti}
              handleRemoveForMulti={handleRemoveForMulti}
              warningText={warningText}
            />
            {!hidePagination && !onlyRenderTable && (
              <Pagination
                totalPages={pageable.totalPages}
                currentPage={pageable.page}
                onPageChange={(page) =>
                  setSearchCondition((prev) => ({ ...prev, page }))
                }
                pageLimit={pageLimit}
              />
            )}
          </div>
          <Popup refreshListPage={refreshListPage} />
        </div>
      </ListPageContext.Provider>
    </PopupProvider>
  );
}
