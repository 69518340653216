import { FiEdit2 } from "react-icons/fi";
import DefaultButton from "../../../components/DefaultButton";
import ListPage from "../../../components/ListPage";
import GoodsRentalStockListTopRight from "../../../components/PageComponents/Service/GoodsRentalStockList/ListTopRight";
import { TableColumnType } from "../../../components/Table";
import {
  getGoodsRentalStockList,
  getGoodsRentalStockListExcel,
} from "../../../services/goodsService";
import {
  GOODS_RENTAL_OPTIONS,
  usageYnLabels,
  USAGE_OPTIONS,
} from "../../../types/comboBoxOption";
import {
  GoodsRentalStock,
  GoodsRentalStockType,
  GoodsServiceType,
  goodsRentalStockLabels,
  goodsServiceLabels,
} from "../../../types/goods";
import { ComponentType, DASH } from "../../../types/search";
import { addDefaultOption } from "../../../utils/comboBoxUtils";
import GoodsRentalStockDetailView from "../../../components/PageComponents/Service/GoodsRentalStockList/DetailView";
import DefaultLabel from "../../../components/Input/DefaultLabel";

type Props = {};

export default function GoodsRentalStockList(props: Props) {
  const columnInfo: TableColumnType<GoodsRentalStock>[] = [
    { header: "물품 연결 번호", name: "stockId", width: "id" },
    { header: "물품 번호", name: "goodsId", width: "id" },
    {
      header: "서비스 종류",
      render: (item: GoodsRentalStock) =>
        goodsServiceLabels[item.serviceType as GoodsServiceType] ?? DASH,
      width: "100",
    },
    {
      header: "물품 종류",
      render: (item: GoodsRentalStock) =>
        goodsRentalStockLabels[item.goodsTypeId as GoodsRentalStockType] ??
        DASH,
      width: "100",
    },
    { header: "물품명", name: "goodsName" },
    {
      header: "총 재고",
      render: (item: GoodsRentalStock) =>
        item.stockCount?.toLocaleString() ?? DASH,
      width: "number",
    },
    {
      header: "정상 재고\n(신청/대여 포함)",
      render: (item: GoodsRentalStock) =>
        item.availableCount?.toLocaleString() ?? DASH,
      width: "number",
    },
    {
      header: "분실/손상 재고",
      render: (item: GoodsRentalStock) =>
        item.restorableCount?.toLocaleString() ?? DASH,
      width: "number",
    },
    {
      header: "대상 정보",
      render: (item: GoodsRentalStock) => (
        <div>
          {item.buildingName && `[${item.buildingName}]`}
          <br />
          {item.storeName ?? DASH}
        </div>
      ),
    },
    { header: "등록일시", name: "createdAt", width: "date" },
    { header: "수정일시", name: "modifiedAt", width: "date" },
    {
      header: "재고유무",
      render: (item: GoodsRentalStock) => (
        <div className="flex items-center justify-center w-[88px]">
          <DefaultLabel
            text={item.useStock ? usageYnLabels.TRUE : usageYnLabels.FALSE}
            color={item.useStock ? "blue" : "gray"}
          />
        </div>
      ),
      width: "yesOrNo",
    },
    {
      header: "사용유무",
      render: (item: GoodsRentalStock) => (
        <div className="flex items-center justify-center w-[88px]">
          <DefaultLabel
            text={item.isActive ? usageYnLabels.TRUE : usageYnLabels.FALSE}
            color={item.isActive ? "blue" : "gray"}
          />
        </div>
      ),
      width: "yesOrNo",
    },
    {
      header: "활동",
      render: (item: GoodsRentalStock, handler) => (
        <div className="flex w-full h-full justify-center items-center">
          <DefaultButton
            size="tableIcon"
            color="blue"
            onClick={() => {
              if (handler?.popup) {
                handler.popup({
                  header: "대여 물품 재고 상세",
                  content: (
                    <GoodsRentalStockDetailView stockId={item.stockId} />
                  ),
                });
              }
            }}
          >
            <FiEdit2 />
          </DefaultButton>
        </div>
      ),
      width: "icon",
    },
  ];

  const componentList: ComponentType<GoodsRentalStock>[] = [
    {
      labelName: "등록일자",
      typeName: "dateRange",
      keyName: "createdAt",
      category: "date",
    },
    {
      labelName: "물품 종류",
      typeName: "comboBox",
      keyName: "goodsTypeId",
      comboBoxOptions: addDefaultOption(GOODS_RENTAL_OPTIONS),
      category: "typeSearch",
    },
    {
      labelName: "재고 유무",
      typeName: "comboBox",
      keyName: "useStock",
      comboBoxOptions: addDefaultOption(USAGE_OPTIONS),
      category: "typeSearch",
    },
    {
      labelName: "사용 유무",
      typeName: "comboBox",
      keyName: "isActive",
      comboBoxOptions: addDefaultOption(USAGE_OPTIONS),
      category: "typeSearch",
    },
    {
      labelName: "물품 번호",
      typeName: "text",
      keyName: "goodsId",
      category: "detailSearch",
      placeholder: "물품 번호를 입력해주세요",
    },
    {
      labelName: "물품명",
      typeName: "text",
      keyName: "goodsName",
      category: "detailSearch",
      placeholder: "물품명을 입력해주세요",
    },
    {
      labelName: "건물명",
      typeName: "text",
      keyName: "buildingName",
      category: "detailSearch",
      placeholder: "건물명을 입력해주세요",
    },
    {
      labelName: "상점명",
      typeName: "text",
      keyName: "storeName",
      category: "detailSearch",
      placeholder: "상점명을 입력해주세요",
    },
  ];

  return (
    <ListPage
      getDataApi={getGoodsRentalStockList}
      keyId={(item: GoodsRentalStock) => item.stockId}
      componentList={componentList}
      columnInfo={columnInfo}
      tableTitle="물품 목록"
      renderTopRight={<GoodsRentalStockListTopRight />}
      excelDownloadApi={getGoodsRentalStockListExcel}
    />
  );
}
