import { HttpStatusCode } from "axios";
import { useCallback, useEffect, useState } from "react";
import { FiEdit } from "react-icons/fi";
import { useModal } from "../../../../contexts/Modal";
import { TableColumnType } from "../../../../components/Table";
import DefaultButton from "../../../../components/DefaultButton";
import {
  getTagBulkExcel,
  getTagTypeList,
  getToiletTagList,
  getToiletTagListExcel,
  patchTag,
  postTagBulk,
} from "../../../../services/tagService";
import { ComponentType } from "../../../../types/search";
import {
  TagGroupName,
  TagType,
  ToiletTag,
  tagGroupNameMap,
} from "../../../../types/tag";
import {
  addDefaultOption,
  toComboBoxType,
} from "../../../../utils/comboBoxUtils";
import {
  CONNECT_OPTIONS,
  USAGE_OPTIONS,
  usageYnLabels,
} from "../../../../types/comboBoxOption";
import Spinner from "../../../../components/Spinner";
import ListPage, { SearchCondition } from "../../../../components/ListPage";
import ToiletTagListTopRight from "../../../../components/PageComponents/IotDevice/Device/ToiletTagList/ListTopRight";
import ToiletTagDetailView from "../../../../components/PageComponents/IotDevice/Device/ToiletTagList/DetailView";
import { buildingGroupCategoryCodeValue } from "../../../../types/building";
import ExcelFileUploaderButton from "../../../../components/FileUploader/ExcelFileUploaderButton";
import DefaultLabel from "../../../../components/Input/DefaultLabel";
import { formatSortSearchCondition } from "../../../../utils/formatUtils";

type Props = {};

export default function ToiletTagList(props: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const { showAlert, showConfirm, handleError } = useModal();
  const [refresh, setRefresh] = useState(false);
  const [tagType, setTagType] = useState<TagType[]>([]);

  const columnInfo: TableColumnType<ToiletTag>[] = [
    { header: "태그 ID", name: "tagId" },
    { header: "태그유형", name: "tagTypeName" },
    { header: "태그명", name: "tagName" },
    { header: "건물명", name: "buildingName" },
    { header: "층명", name: "floorName" },
    { header: "화장실명", name: "toiletName" },
    { header: "칸명", name: "toiletCompartmentName" },
    { header: "디바이스 ID", name: "deviceId" },
    { header: "디바이스 주소", name: "deviceAddr" },
    { header: "MAC주소", name: "macAddr" },
    {
      header: "사용여부",
      render: (item: ToiletTag) => (
        <div className="flex items-center justify-center">
          <DefaultLabel
            text={item.isActive ? usageYnLabels.TRUE : usageYnLabels.FALSE}
            color={item.isActive ? "blue" : "gray"}
          />
        </div>
      ),
      width: "yesOrNo",
    },
    { header: "등록일시", name: "createdAt", width: "date" },
    {
      header: "상세",
      render: (item: ToiletTag, handler) => (
        <div className="w-full h-full flex gap-2 justify-center items-center px-2">
          {item.isConnected && (
            <DefaultButton
              size="tableButton"
              color="blue"
              onClick={() => handleDisconnecting(item)}
            >
              공간 연결 해제
            </DefaultButton>
          )}
          <DefaultButton
            size="tableIcon"
            color="blue"
            onClick={() => {
              if (handler?.popup) {
                handler.popup({
                  header: "태그 상세 정보",
                  content: <ToiletTagDetailView id={item.id} />,
                });
              }
            }}
          >
            <FiEdit />
          </DefaultButton>
        </div>
      ),
      width: "200",
    },
  ];

  const fetchTagType = async () => {
    try {
      setIsLoading(true);

      const response = await getTagTypeList();

      if (response.data) {
        const options = response.data.filter(
          (item) => tagGroupNameMap[item.tagGroupTypeId] === TagGroupName.TOILET
        );

        setTagType(options);
      } else {
        throw new Error();
      }
    } catch (err: any) {
      handleError(err, "태그유형 조회");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTagType();
  }, []);

  const handleDisconnecting = (item: ToiletTag) => {
    const message = `${item.buildingName ?? ""} ${item.floorName ?? ""} ${
      item.toiletName ?? ""
    } ${item.tagName ?? ""}의 ${
      item.tagTypeName
    }\n태그 연결을 해제하시겠습니까?`;

    showConfirm(message, async () => {
      try {
        const response = await patchTag(item.id);

        if (response.status === HttpStatusCode.Ok) {
          showAlert("태그 연결 해제되었습니다.");
          setRefresh(!refresh);
        } else {
          throw new Error();
        }
      } catch (err: any) {
        handleError(err, "태그 연결 해제");
      }
    });
  };

  const componentList: ComponentType<ToiletTag>[] = [
    {
      labelName: "등록일자",
      typeName: "dateRange",
      keyName: "createdAt",
      category: "date",
    },
    {
      labelName: "태그 유형",
      typeName: "comboBox",
      keyName: "tagTypeId",
      comboBoxOptions: addDefaultOption(
        toComboBoxType(tagType, "tagTypeId", "tagTypeName")
      ),
      category: "typeSearch",
    },
    {
      labelName: "공간 연결 상태",
      typeName: "comboBox",
      keyName: "isConnected",
      comboBoxOptions: addDefaultOption(CONNECT_OPTIONS),
      category: "typeSearch",
    },
    {
      labelName: "사용 유무",
      typeName: "comboBox",
      keyName: "isActive",
      comboBoxOptions: addDefaultOption(USAGE_OPTIONS),
      category: "typeSearch",
    },
    {
      labelName: "태그 ID",
      typeName: "text",
      keyName: "tagId",
      category: "detailSearch",
      placeholder: "태그 ID를 입력해주세요",
    },
    {
      labelName: "태그명",
      typeName: "text",
      keyName: "tagName",
      category: "detailSearch",
      placeholder: "태그명을 입력해주세요",
    },
    {
      typeName: "buildingAndFloor",
      keyName: "buildingAndFloor",
      category: "detailSearch",
      buildingGroupCategoryCode: buildingGroupCategoryCodeValue.IOT_DEVICE,
    },
    {
      labelName: "화장실명",
      typeName: "text",
      keyName: "toiletName",
      category: "detailSearch",
      placeholder: "화장실명을 입력해주세요",
    },
  ];

  const getToiletTagListWrapper = useCallback(
    async (params: SearchCondition<ToiletTag>) => {
      const sortParam = formatSortSearchCondition<ToiletTag>({
        sortBy: { name: "createdAt", order: "desc" },
      });

      return await getToiletTagList({ ...params, ...sortParam });
    },
    [refresh]
  );

  return (
    <>
      {isLoading && <Spinner />}
      <ListPage
        getDataApi={getToiletTagListWrapper}
        excelUpload={
          <ExcelFileUploaderButton
            title="화장실 태그"
            getExcelDataApi={getTagBulkExcel}
            postExcelDataApi={postTagBulk}
          />
        }
        excelDownloadApi={getToiletTagListExcel}
        columnInfo={columnInfo}
        componentList={componentList}
        tableTitle="화장실 목록"
        renderTopRight={<ToiletTagListTopRight tagType={tagType} />}
      />
    </>
  );
}
