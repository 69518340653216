import { useCallback, useContext, useEffect, useState } from "react";
import { HttpStatusCode } from "axios";

import { API_ERROR_CODE } from "../../../../constants/apiConstants";
import { POST_HOT_COLD_ALIAS } from "../../../../constants/postConstants";

import {
  BuildingGroup,
  BuildingServiceGroupType,
  ServiceGroupingForMulti,
  TagSelectedItem,
  buildingGroupCategoryCodeValue,
} from "../../../../types/building";
import {
  CategoryData,
  CategoryReturnField,
  CategoryStoreData,
} from "../../../../types/category";
import {
  PostCategory,
  PostPostRequest,
  radioBoxPostIsTop,
  radioBoxPostUseComment,
} from "../../../../types/post";
import { ComboBoxType } from "../../../../types/search";
import { ServiceCategoryType } from "../../../../types/service";
import {
  BOOLEAN_TYPES,
  DEFAULT_SELECT_ALL_VALUE,
  VISIBLE_OPTIONS,
} from "../../../../types/comboBoxOption";

import {
  findCategoryIdByAlias,
  findCategoryValueById,
  transformCategoryData,
} from "../../../../libs/category";
import { generateAxiosListResponse } from "../../../../libs/request";
import {
  validationFunctions,
  validationResultMessage,
} from "../../../../libs/validations";

import { toComboBoxType } from "../../../../utils/comboBoxUtils";

import { useModal } from "../../../../contexts/Modal";
import { PopupContext } from "../../../../contexts/Popup";
import { useUserContext } from "../../../../contexts/User";

import { getServiceGroupMemberList } from "../../../../services/ServiceGroupService";
import { getBuildingGroupSearch, getBuildingListWithGroup } from "../../../../services/buildingService";
import {
  getCategoryList,
  postPost,
  postPostList,
} from "../../../../services/pinelifeService";

import DefaultButton from "../../../DefaultButton";
import DefaultTextArea from "../../../DefaultTextArea";
import MultiImageUploader from "../../../FileUploader/ImageFileUploader/MultiImageFileUploader";
import FormHeader from "../../../Form/Header";
import FormRow from "../../../Form/Row";
import FormRowLabel from "../../../Form/RowLabel";
import DefaultInput from "../../../Input/DefaultInput";
import DefaultLabel from "../../../Input/DefaultLabel";
import RadioButtonGroup from "../../../Input/RadioButtonGroup";
import { SearchCondition } from "../../../ListPage";
import CategorySelect from "../../../SelectBox/CategorySelect";
import DefaultSelect from "../../../SelectBox/DefaultSelect";
import Spinner from "../../../Spinner";
import { TableColumnType } from "../../../Table";
import { SearchTagData } from "../../../TagFilter";
import TagSingleFilter from "../../../TagFilter/TagSingleFilter";
import { TagData } from "../../../TagList";
import TagSelector from "../../../TagSelector";
import { CATEGORY_ID_MENU } from "../ListTopRight";
import PostDetailView from "../DetailView";

type Props = {
  categoryData?: CategoryData[];
  postCategory?: PostCategory;
  refresh: () => void;
  testBuilding?: BuildingGroup;
};

export type buildingSearchType = {
  groupName?: string,
  buildingName?: string,
}

export default function PostCreateForm({
  postCategory,
  refresh,
  testBuilding,
  categoryData = [],
}: Props) {
  const { updatePopup, closePopup, refreshAndClosePopup } =
    useContext(PopupContext);
  const { showAlert, showConfirm, handleError } = useModal();
  const { userInfo } = useUserContext();

  const [postPostRequest, setPostPostRequest] = useState<
    Partial<PostPostRequest>
  >({
    useComment: true,
    isTop: false,
    isDisplayHotCold: false,
    lifeCategoryId: postCategory === "menu" ? CATEGORY_ID_MENU : undefined,
  });
  const [selectedCategory, setSelectedCategory] = useState<number[]>([]);
  const [selectedTag, setSelectedTag] = useState<TagSelectedItem>();
  const [selectedService, setSelectedService] = useState("");
  const [storeCategory, setStoreCategory] = useState<ComboBoxType[]>([]);
  const [serviceOptions, setServiceOptions] = useState<ComboBoxType[]>([]);
  const [tags, setTags] = useState<SearchTagData>({});
  const [selectedDataForMulti, setSelectedDataForMulti] = useState<ServiceGroupingForMulti>( // 다중 건물 모달에서 보여주기용 데이터 + 전송때 사용
    {}
  );
  const [buildingId, setBuildingId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [buildingSearch, setBuildingSearch] = useState<buildingSearchType>({groupName : "", buildingName: ""});

  const isHotColdCategory = selectedCategory.includes(
    findCategoryIdByAlias(POST_HOT_COLD_ALIAS, categoryData) ?? -1
  );

  const handlePost = () => {
    if(postCategory === "multi" && Object.keys(selectedDataForMulti).length === 0){
      showAlert("대상 건물을 선택해 주세요");
      return;
    }
    const errorMessage = validationResultMessage([
      ...(postCategory === "menu" || postCategory === "building"
        ? [validationFunctions.isFalsy(buildingId, "건물 선택")]
        : []),
      ...(postCategory === "menu"
        ? [validationFunctions.required(postPostRequest.storeId, "식당 선택")]
        : [
            validationFunctions.required(
              postPostRequest.lifeCategoryId,
              "게시판 분류"
            ),
          ]),
      validationFunctions.required(postPostRequest.title, "제목"),
      validationFunctions.required(postPostRequest.content, "내용"),
    ]);

    if (errorMessage) {
      showAlert(errorMessage);
    } else {
      showConfirm("게시글을 등록 하시겠습니까?", async () => postData());
    }
  };

  const postData = async () => {
    const filteredIds: string[] =
      tags.data?.filter((tag) => tag.id !== buildingId).map((tag) => tag.id) ??
      [];
    filteredIds.unshift(buildingId);
    const buildingIds = filteredIds.join(",");

    const data: PostPostRequest = {
      title: postPostRequest.title as string, // handlePost에서 validationFunctions.required로 체크했기 때문에 undefined일 수 없음
      lifeCategoryId: postPostRequest.lifeCategoryId ?? selectedCategory[0],
      content: postPostRequest.content as string, // handlePost에서 validationFunctions.required로 체크했기 때문에 undefined일 수 없음
      useComment: postPostRequest.useComment,
      isTop: postPostRequest.isTop,
    };

    if(selectedDataForMulti && Object.keys(selectedDataForMulti).length > 0){
      const postGroupData = Object.keys(selectedDataForMulti).map((groupId, index) => {
        const buildingList = selectedDataForMulti[groupId].buildingList;
        const buildingIds = buildingList.map((building) => building.buildingId);
        const postGroup = {
          groupId: groupId,
          buildingId: buildingIds.join(",")
        }
        return postGroup;
      });

      if(postGroupData.length > 0){
        data.groupList = JSON.stringify(postGroupData);
      }
    }

    if(postPostRequest.imageFiles) {
      data.imageFiles = postPostRequest.imageFiles;
    }

    if (postPostRequest.storeId) {
      data.storeId = postPostRequest.storeId;
    }
    if (postPostRequest.status) {
      data.status = postPostRequest.status;
    }
    if (buildingIds && buildingIds.length > 0) {
      data.buildingId = buildingIds;
    }
    if (
      selectedService !== DEFAULT_SELECT_ALL_VALUE &&
      selectedService.length > 0
    ) {
      data.groupId = selectedService;
    }
    if (isHotColdCategory !== null && isHotColdCategory !== undefined) {
      data.isDisplayHotCold = postPostRequest.isDisplayHotCold;
    }

    try {
      let response;

      if(postCategory !== "multi") {
        response = await postPost(data);
      } else {
        response = await postPostList(data);
      }
      if (response.status === HttpStatusCode.Ok) {
        showAlert("글 등록이 완료됐습니다.");
        refreshAndClosePopup();
      }
    } catch (err: any) {
      const data = err.response?.data;

      if (
        data?.code === API_ERROR_CODE.FILE_UPLOAD_EXCEPTION &&
        data?.targetId
      ) {
        showAlert(err.response?.data?.error);

        const postId = Number(err.response?.data?.targetId);
        if (isNaN(postId)) {
          showAlert("등록된 게시글 ID를 가져오는 중 오류가 발생했습니다.");
        } else {
          refresh();
          updatePopup({
            header: "게시글 상세",
            content: <PostDetailView postId={Number(postId)} />,
          });
        }
      } else {
        handleError(err, "글 등록");
      }
    }
  };

  const handleCategory = (value: number[]) => {
    setSelectedCategory(value);
    setPostPostRequest((prev) => ({
      ...prev,
      lifeCategoryId: value.length > 0 ? value[value.length - 1] : undefined,
    }));
  };

  const handleStoreCategoryChange = (value: string) => {
    setPostPostRequest((prev) => ({ ...prev, storeId: value }));
  };

  const handleChange = (data: Partial<PostPostRequest>) => {
    setPostPostRequest((prev) => ({ ...prev, ...data }));
  };

  const getPostText = () => {
    const text =
      postCategory === "sandi"
        ? "샌디안내"
        : postCategory === "talk"
        ? "샌디톡"
        : "";

    return text;
  };

  const fetchCategory = async (buildingId: string) => {
    try {
      setIsLoading(true);

      const response = await getCategoryList(buildingId);
      if (response.status) {
        const transCategory = transformCategoryData(
          response.data,
          "lifeCategoryId",
          "alias",
          "name",
          "lifeCategoryList",
          "storeCategoryId",
          "storeList"
        );

        const findCategory = findCategoryValueById(
          CATEGORY_ID_MENU,
          transCategory,
          CategoryReturnField.STORE_LIST
        ) as CategoryStoreData[];

        setStoreCategory(toComboBoxType(findCategory, "storeId", "name"));
      } else {
        throw new Error("Failed to get category");
      }
    } catch (err: any) {
      handleError(err, "카테고리 조회");
    } finally {
      setIsLoading(false);
    }
  };

  const handleBuildingChange = (item: BuildingGroup) => {
    setSelectedTag(item);
    if (item.buildingId) {
      setBuildingId(item.buildingId);

      if (postCategory === "menu") {
        fetchCategory(item.buildingId);
        setSelectedCategory([]);
        fetchServiceGroup(item.buildingId, "CAFETERIA");
      } else if (postCategory === "building") {
        fetchServiceGroup(item.buildingId, "BUILDING_BBS");
      }
    } else {
      console.error("Building ID is not found while selecting building.");
    }
  };

  const fetchServiceGroup = async (
    buildingId: string,
    categoryCode: ServiceCategoryType
  ) => {
    try {
      setIsLoading(true);
      const res = await getServiceGroupMemberList(buildingId, categoryCode);
      if (res.status === HttpStatusCode.Ok) {
        setServiceOptions(toComboBoxType(res.data, "groupId", "groupName"));
        setSelectedService("");
      }
    } catch (err: any) {
      handleError(err, "서비스 그룹 조회");
    } finally {
      setIsLoading(false);
    }
  };

  const renderPostByCategory = () => {
    if (postCategory === "sandi" || postCategory === "talk") {
      return <DefaultLabel text={getPostText()} />;
    } else if (postCategory === "building" || postCategory === "menu") {
      return (
        <TagSingleFilter
          selectType="building"
          selectedItem={selectedTag}
          onChangeBuilding={handleBuildingChange}
          categoryCode={buildingGroupCategoryCodeValue.POST}
        />
      );
    }
    return null;
  };

  const buildingColumns: TableColumnType<BuildingGroup>[] = [
    { header: "건물 ID", name: "buildingId" },
    { header: "건물명", name: "buildingName" },
  ];

  const buildingColumnsForMulit: TableColumnType<BuildingServiceGroupType>[] = [
    { header: "서비스 그룹명", name: "groupName" },
    { header: "건물명", name: "buildingName" },
  ];

  const compareBuilding = (item: BuildingGroup) => (o: BuildingGroup) => {
    return item.buildingId === o.buildingId;
  };

  const compareBuildingForMulti = (item: BuildingServiceGroupType) => (o: BuildingServiceGroupType) => {
    return item.buildingId === o.buildingId;
  };

  const getTagBuilding = (item: BuildingGroup) => {
    return {
      id: item.buildingId,
      name: item.buildingName,
      data: item,
    } as TagData;
  };

  const getTagBuildingForMulti = (item: BuildingServiceGroupType) => {
    return {
      id: item.buildingId,
      name: item.buildingName,
      data: item,
    } as TagData;
  };

  const getBuildingListByGroupId = useCallback(
    async (params: SearchCondition<BuildingGroup>) => {
      if (selectedService === "") {
        return generateAxiosListResponse(params, []);
      }

      const categoryCode: ServiceCategoryType =
        postCategory === "menu" ? "CAFETERIA" : "BUILDING_BBS";

      return getBuildingGroupSearch({
        ...params,
        groupId: selectedService,
        categoryCode,
        isExcludedOrigin: true,
      });
    },
    [selectedService]
  );

  const getBuildingListWidthServiceGroups = useCallback(
    async (params: SearchCondition<BuildingServiceGroupType>) => {
      if(buildingSearch.groupName === "" && buildingSearch.buildingName === ""){
        return generateAxiosListResponse(params, []);
      }

      return getBuildingListWithGroup({
        ...params
      });
    },
    [buildingSearch.groupName, buildingSearch.buildingName]
  );

  useEffect(() => {
    if (testBuilding) {
      handleBuildingChange(testBuilding);
    }
  }, [testBuilding]);

  return (
    <div className="flex flex-col gap-5">
      {isLoading && <Spinner />}
      <div className="flex flex-col max-h-[700px] overflow-y-auto">
        <FormHeader title="작성자 정보" />
        <FormRow>
          <FormRowLabel title="작성자 ID">
            <DefaultLabel text={`${userInfo?.memberId}`} />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="별명(이름)">
            <DefaultLabel text={`${userInfo?.nickname}(${userInfo?.name})`} />
          </FormRowLabel>
        </FormRow>
        {postCategory === "multi" && (
          <>
            <FormHeader title="건물 선택" />
            <FormRow>
              <div className="flex flex-col gap-2">
                <TagSelector
                  getDataApi={getBuildingListWidthServiceGroups} // 여기가 바뀌어야 되네 -> getBuildingList 로 임시 테스트 중
                  componentList={[]}
                  columnInfo={buildingColumnsForMulit}
                  title="건물 목록"
                  tagType="building"
                  tags={tags}
                  selectedDataForMulti={selectedDataForMulti}
                  setTags={setTags}
                  setSelectedDataForMulti={setSelectedDataForMulti}
                  compareItem={compareBuildingForMulti}
                  getTagItem={getTagBuildingForMulti}
                  inline
                  isHorizontal
                  postCategory={postCategory}
                  setBuildingSearch={setBuildingSearch}
                />
              </div>
            </FormRow>
          </>
        )}
        <FormHeader title="게시글 내용" />
        {postCategory !== "multi" && (
          <FormRow>
            <FormRowLabel title="게시판" isRequired={postCategory === "menu"}>
              {renderPostByCategory()}
            </FormRowLabel>
          </FormRow>
        )}
        <FormRow>
          <FormRowLabel title="게시판 분류" isRequired>
            {postCategory === "menu" ? (
              <DefaultSelect
                optionList={storeCategory}
                value={postPostRequest.storeId}
                onChange={handleStoreCategoryChange}
                placeholder="식당 선택"
                dataTestId="store"
              />
            ) : (
              <CategorySelect
                value={selectedCategory}
                onChange={handleCategory}
                allOptionLabels={["분류 선택"]}
                categoryData={categoryData}
              />
            )}
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="제목" isRequired>
            <div className="w-full">
              <DefaultInput
                value={postPostRequest.title}
                placeholder="제목을 입력해주세요"
                minWidth="w-full"
                maxLength={200}
                onChange={(value: string) => handleChange({ title: value })}
              />
            </div>
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="내용" isRequired>
            <DefaultTextArea
              value={postPostRequest.content}
              onChange={(value: string) => handleChange({ content: value })}
              rows={10}
              cols={70}
              maxLength={2000}
              placeholder="내용을 입력해주세요"
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="첨부 파일">
            <MultiImageUploader
              maxImages={10}
              images={postPostRequest.imageFiles ?? []}
              onChange={(data: File[]) => handleChange({ imageFiles: data })}
              rightLabel="* 개당 10mb 이하, 최대 10개 등록 가능"
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="댓글 허용">
            <RadioButtonGroup
              value={postPostRequest.useComment?.toString() ?? ""}
              options={radioBoxPostUseComment}
              onChange={(value: string) =>
                handleChange({ useComment: value === "true" ? true : false })
              }
              dataTestId="use-comment"
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="상단 고정 여부">
            <div className="flex flex-col gap-1.5">
              <RadioButtonGroup
                value={postPostRequest.isTop?.toString() ?? ""}
                options={radioBoxPostIsTop}
                onChange={(value: string) =>
                  handleChange({ isTop: value === "true" ? true : false })
                }
                dataTestId="is-top"
              />
              <span>
                * 같은 분류에 고정된 게시글이 있을 경우 다른 게시글은 고정이
                취소 됩니다
              </span>
            </div>
          </FormRowLabel>
        </FormRow>
        {isHotColdCategory && (
          <FormRow dataTestId={"display-hot-cold"}>
            <FormRowLabel title="더워요/추워요 화면노출">
              <RadioButtonGroup
                value={postPostRequest.isDisplayHotCold?.toString() ?? ""}
                options={VISIBLE_OPTIONS}
                onChange={(value: string) =>
                  handleChange({
                    isDisplayHotCold: value === BOOLEAN_TYPES.TRUE,
                  })
                }
              />
            </FormRowLabel>
          </FormRow>
        )}
        {(postCategory === "building" || postCategory === "menu") && (
          <>
            <FormHeader title="게시글 추가 노출" />
            <FormRow>
              <FormRowLabel title="서비스 그룹">
                <div className="flex flex-col gap-2">
                  <DefaultSelect
                    optionList={serviceOptions}
                    onChange={setSelectedService}
                    value={selectedService}
                    placeholder="서비스 그룹 선택"
                    dataTestId="service-group"
                  />
                  <TagSelector
                    getDataApi={getBuildingListByGroupId}
                    componentList={[]}
                    columnInfo={buildingColumns}
                    title="건물 목록"
                    tagType="building"
                    tags={tags}
                    setTags={setTags}
                    compareItem={compareBuilding}
                    getTagItem={getTagBuilding}
                    inline
                    isHorizontal
                  />
                </div>
              </FormRowLabel>
            </FormRow>
          </>
        )}
      </div>

      <div className="flex gap-2 justify-center">
        <DefaultButton onClick={closePopup}>닫기</DefaultButton>
        <DefaultButton 
          color="primary" 
          onClick={handlePost}
          testId="post-submit"
        >
          등록
        </DefaultButton>
      </div>
    </div>
  );
}
