import { useState } from "react";

import { useModal } from "../../../contexts/Modal";

import DefaultButton from "../../DefaultButton";
import DefaultInput from "../../Input/DefaultInput";
import { SearchCondition } from "../../ListPage";
import SimpleModal from "../../Modal/SimpleModal";

type Props<T> = {
  searchCondition?: SearchCondition<T>;
  excelDownloadApi: (searchCondition: SearchCondition<T>) => Promise<void>;
  needReason?: boolean;
};

export default function ExcelDownloadButton<T>({
  searchCondition,
  excelDownloadApi,
  needReason = false,
}: Props<T>) {
  const { handleError } = useModal();
  const [reason, setReason] = useState<string | undefined>(undefined);
  const [isOpen, setIsOpen] = useState(false);

  const handleConfirm = async () => {
    if (needReason && !reason) {
      handleError(
        { response: { data: { error: "사유를 입력해주세요." } } },
        "사유 입력"
      );
      return;
    }

    const apiArg = needReason
      ? { ...searchCondition, reason }
      : searchCondition;
    try {
      await excelDownloadApi((apiArg as SearchCondition<T>) ?? {});
      setReason("");
      setIsOpen(false);
    } catch (e) {
      handleError(e, "엑셀 다운로드");
    }
  };

  return (
    <>
      <DefaultButton
        color="default"
        size="tableTop"
        onClick={() => setIsOpen(true)}
        testId="excel-download-popup"
      >
        Excel 다운로드
      </DefaultButton>
      <SimpleModal
        onConfirm={handleConfirm}
        isOpen={isOpen}
        onCancel={() => setIsOpen(false)}
        header="Excel 다운로드"
        content={
          <div className="flex flex-col">
            <span>Excel 파일을 다운로드 하시겠습니까?</span>
            {needReason && (
              <>
                <span>
                  개인정보가 포함된 Excel 파일은 다운로드시 사유를
                  입력해야합니다.
                </span>
                <DefaultInput
                  placeholder="다운로드 사유를 입력해주세요."
                  minWidth="w-full"
                  value={reason ?? ""}
                  onChange={setReason}
                />
              </>
            )}
          </div>
        }
      />
    </>
  );
}
