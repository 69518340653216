import MultiSelectTable from "..";
import { getBuildingList } from "../../../../services/buildingService";
import { Building } from "../../../../types/building";
import { ComponentType } from "../../../../types/search";
import { TableColumnType } from "../../../Table";

type Props = {
  selectedData: Building[];
  setSelectedData: (data: Building[]) => void;
};

const BUILDING_PAGE_LIMIT = 5;

export default function BuildingMultiSelectTable({
  selectedData,
  setSelectedData,
}: Props) {
  const columnInfo: TableColumnType<Building>[] = [
    { header: "건물 ID", name: "buildingId" },
    { header: "건물명", name: "buildingName" },
  ];

  const compareItem = (item: Building) => (o: Building) => {
    return item.buildingId === o.buildingId;
  };

  const tableComponentList: ComponentType<Building>[] = [
    {
      keyName: "buildingName",
      typeName: "text",
      placeholder: "건물명을 입력해주세요",
    },
    {
      keyName: "buildingId",
      typeName: "text",
      placeholder: "건물 ID를 입력해주세요",
    },
  ];

  return (
    <MultiSelectTable
      getDataApi={getBuildingList}
      columnInfo={columnInfo}
      title="건물 목록"
      selectedData={selectedData}
      onSelectedDataChange={setSelectedData}
      compareItem={compareItem}
      pageLimit={BUILDING_PAGE_LIMIT}
      tableComponentList={tableComponentList}
      hidePageSize
    />
  );
}
