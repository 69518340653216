import { HttpStatusCode } from "axios";
import { useContext, useState } from "react";

import {
  BuildingGroup,
  buildingGroupCategoryCodeValue,
  TagSelectedItem,
  TempHum,
} from "../../../../../../types/building";
import { ComboBoxType } from "../../../../../../types/search";
import { DEFAULT_SELECT_ALL_VALUE } from "../../../../../../types/comboBoxOption";

import {
  validationFunctions,
  validationResultMessage,
} from "../../../../../../libs/validations";

import { useModal } from "../../../../../../contexts/Modal";
import { PopupContext } from "../../../../../../contexts/Popup";

import {
  getBuildingFloor,
  postTempHum,
} from "../../../../../../services/buildingService";

import DefaultButton from "../../../../../DefaultButton";
import FormHeader from "../../../../../Form/Header";
import FormRow from "../../../../../Form/Row";
import FormRowLabel from "../../../../../Form/RowLabel";
import DefaultInput from "../../../../../Input/DefaultInput";
import DefaultSelect from "../../../../../SelectBox/DefaultSelect";
import Spinner from "../../../../../Spinner";
import TagSingleFilter from "../../../../../TagFilter/TagSingleFilter";
import PermissionWrapper from "../../../../../PermissionWrapper";
import SingleImageUploader from "../../../../../FileUploader/ImageFileUploader/SingleImageFileUploader";

type Props = {};

export default function TempHumCreateForm(props: Props) {
  const { refreshAndClosePopup, closePopup } = useContext(PopupContext);
  const { showAlert, showConfirm, handleError } = useModal();
  const [tempHum, setTempHum] = useState<Partial<TempHum>>({ isActive: true });
  const [floorOptions, setFloorOptions] = useState<ComboBoxType[]>([]);
  const [selectedTag, setSelectedTag] = useState<TagSelectedItem>();
  const [isLoading, setIsLoading] = useState(false);

  const fetchFloor = async (buildingId: string) => {
    try {
      setIsLoading(true);

      const response = await getBuildingFloor(buildingId);

      if (response.data) {
        const options = response.data.map((item) => ({
          value: item.buildingFloorId,
          label: item.floorName,
        }));

        setFloorOptions(options);
      }
    } catch (err: any) {
      handleError(err, "층 정보 조회");
    } finally {
      setIsLoading(false);
    }
  };

  const postData = async () => {
    try {
      const response = await postTempHum(createFormData());

      if (response.status === HttpStatusCode.Ok) {
        showAlert("등록 되었습니다.");
        refreshAndClosePopup();
      }
    } catch (err: any) {
      handleError(err, "등록");
    }
  };

  const createFormData = () => {
    const formData = new FormData();

    tempHum.imageFile && formData.append("uploadFile", tempHum.imageFile);
    formData.append("request", JSON.stringify(tempHum));

    return formData;
  };

  const handlePost = () => {
    const errorMessage = validationResultMessage([
      validationFunctions.required(tempHum.buildingId, "건물"),
      validationFunctions.required(tempHum.buildingFloorId, "층"),
      validationFunctions.required(tempHum.spaceName, "공간명"),
      validationFunctions.required(tempHum.sortOrder, "정렬순서"),
    ]);

    if (errorMessage) {
      showAlert(errorMessage);
    } else {
      showConfirm("등록 하시겠습니까?", postData);
    }
  };

  const handleTempHumChange = (data: Partial<TempHum>) => {
    setTempHum((prev) => ({ ...prev, ...data }));
  };

  const handleBuildingChange = (item: BuildingGroup) => {
    if (item.buildingId) {
      setSelectedTag(item);
      handleTempHumChange({ buildingId: item.buildingId });
      fetchFloor(item.buildingId);
    } else {
      console.error("Invalid buildingId");
    }
  };

  return (
    <div className="flex flex-col gap-5">
      {isLoading && <Spinner />}
      <div className="flex flex-col w-[700px] max-h-[700px] overflow-y-auto">
        <FormHeader title="건물 선택" />
        <FormRow>
          <FormRowLabel title="위치" isRequired>
            <div className="flex flex-col gap-1.5">
              <TagSingleFilter
                selectType="building"
                selectedItem={selectedTag}
                onChangeBuilding={handleBuildingChange}
                categoryCode={buildingGroupCategoryCodeValue.IOT_DEVICE}
              />
              <DefaultSelect
                value={tempHum.buildingFloorId}
                placeholder="층 선택"
                optionList={floorOptions}
                onChange={(value: string) => {
                  handleTempHumChange({
                    buildingFloorId:
                      value !== DEFAULT_SELECT_ALL_VALUE ? value : undefined,
                  });
                }}
                disabled={
                  tempHum.buildingId === undefined || floorOptions.length === 0
                }
              />
            </div>
          </FormRowLabel>
        </FormRow>

        <FormHeader title="공간정보" />
        <FormRow>
          <FormRowLabel title="공간명" isRequired>
            <DefaultInput
              value={tempHum.spaceName}
              onChange={(value: string) =>
                handleTempHumChange({ spaceName: value })
              }
              maxLength={20}
              placeholder="공간명을 입력해주세요"
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="정렬 순서" isRequired>
            <DefaultInput
              type="number"
              value={tempHum.sortOrder?.toString()}
              onChange={(value: string) =>
                handleTempHumChange({
                  sortOrder: value !== "" ? Number(value) : undefined,
                })
              }
              maxLength={10}
              hideMaxLength
              placeholder="정렬순서를 입력해주세요"
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="도면 추가">
            <SingleImageUploader
              image={tempHum.imageFile}
              onChange={(file: File) => {
                handleTempHumChange({ ...tempHum, imageFile: file });
              }}
            />
          </FormRowLabel>
        </FormRow>
      </div>

      <div className="flex justify-center gap-2">
        <DefaultButton onClick={closePopup}>닫기</DefaultButton>
        <PermissionWrapper>
          <DefaultButton color="primary" onClick={handlePost}>
            등록
          </DefaultButton>
        </PermissionWrapper>
      </div>
    </div>
  );
}
