import { TEMPLATE_SERVICE_TYPES } from "./comboBoxOption";
import { FileInfo } from "./file";

export type Template = {
  serviceTemplateId: number;
  serviceTemplateName: string;
  target: TemplateTarget;
  usedCount: number;
  isActive: boolean;
  createdAt: string;
  modifiedAt: string;
  sortOrder: number;
  useBtn: boolean;
  action: string;

  tagFilter: string;
  buildingId: string;
  companyId: string;
  useOnlyAuthorized: boolean;
  iconImage: File;
  iconFileInfo: { items: FileInfo[] };
  actionInfoImage: File;
  actionInfoFileInfo: { items: FileInfo[] };
  deleteActionInfoFileInfo: boolean;
  actionJson: TemplateActionJson[];
};

export type TemplateConnection = {
  target: string;
  targetId: string;
  targetName: string;
};

export type TemplateActionJson = {
  btn: string;
  target: string;
  targetName: string;
  action: string;
  actionName: string;
  link: string;
  storeAndroid: string;
  storeIos: string;
  schemeAndroid: string;
  schemeIos: string;
};

export type TemplateActionCategory = {
  id: number;
  alias: string;
  name: string;
  subList: TemplateActionCategory[];
};

export type TemplateParam = {
  code: string;
  codeName: string;
  codeType: string;
  comment: string;
};

export type TemplateTargetPost = {
  serviceTemplateIds: number[];
};

export type TemplateTarget = keyof typeof TEMPLATE_SERVICE_TYPES;

export const templateServiceLabels: Record<TemplateTarget, string> = {
  BUILDING: "건물",
  COMPANY: "회사",
};

export type TemplateAppJsonInfo = {
  value: keyof TemplateActionJson;
  name: string;
  maxLength: number;
};

export const templateAppJsonData: TemplateAppJsonInfo[] = [
  { value: "storeAndroid", name: "android 스토어 주소", maxLength: 100 },
  { value: "storeIos", name: "ios 스토어 주소", maxLength: 100 },
  { value: "schemeAndroid", name: "android 스킴 주소", maxLength: 100 },
  { value: "schemeIos", name: "ios 스킴 주소", maxLength: 100 },
];
