import React, { createContext, ReactNode, useState } from "react";

type PopupProps = {
  header?: ReactNode;
  content?: ReactNode;
  footer?: ReactNode;
};

export type PopupHandler = (popup: PopupProps) => void;

type PopupContextProps = {
  header: ReactNode;
  content: ReactNode;
  footer: ReactNode;
  isOpen: boolean;
  refresh: boolean;
  refreshAndClosePopup: () => void;
  updatePopup: (popup: PopupProps) => void;
  openPopup: (popup: PopupProps) => void;
  closePopup: () => void;
};

export const PopupContext = createContext<PopupContextProps>({
  header: null,
  content: null,
  footer: null,
  isOpen: false,
  refresh: false,
  refreshAndClosePopup: () => {},
  updatePopup: () => {},
  openPopup: () => {},
  closePopup: () => {},
});

type Props = {
  children: ReactNode;
};

export const PopupProvider = ({ children }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [header, setHeader] = useState<ReactNode>(null);
  const [content, setContent] = useState<ReactNode>(null);
  const [footer, setFooter] = useState<ReactNode>(null);
  const [refresh, setRefresh] = useState(false);

  const refreshAndClosePopup = () => {
    setRefresh(true);
  };

  const updatePopup = (popup: PopupProps) => {
    if (popup.header) {
      setHeader(popup.header);
    }

    if (popup.content) {
      setContent(popup.content);
    }

    if (popup.footer) {
      setFooter(popup.footer);
    }
  };

  const closePopup = () => {
    setHeader(null);
    setContent(null);
    setFooter(null);
    setIsOpen(false);
    setRefresh(false);
  };

  const openPopup = ({ header, content, footer }: PopupProps) => {
    setHeader(header);
    setContent(content);
    setFooter(footer);
    setIsOpen(true);
    setRefresh(false);
  };

  return (
    <PopupContext.Provider
      value={{
        isOpen,
        openPopup,
        closePopup,
        updatePopup,
        refreshAndClosePopup,
        refresh,
        header,
        content,
        footer,
      }}
    >
      {children}
    </PopupContext.Provider>
  );
};
