type Props = {};

export default function BuildingMenuIcon(props: Props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.6">
        <path
          d="M16.2666 9.14131L18.5113 9.14111C18.7812 9.14111 19.0001 9.45639 19.0001 9.84531V18.2957C19.0001 18.6846 18.7812 18.9999 18.5113 18.9999L16.2666 19.0001"
          stroke="white"
        />
        <path
          d="M8.28615 19L5.00008 19L5.00009 6C5.00009 5.44771 5.42043 5 5.93896 5L15.3277 5C15.8463 5 16.2666 5.44772 16.2666 6L16.2666 19L12.9805 19M12.5111 19L8.75559 19L8.75559 16C8.75559 15.4477 9.17594 15 9.69447 15L11.5722 15C12.0907 15 12.5111 15.4477 12.5111 16L12.5111 19Z"
          stroke="white"
        />
        <path
          d="M8.75537 8V11.2766"
          stroke="white"
          strokeMiterlimit="10"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
        <path
          d="M12.5112 8V11.2766"
          stroke="white"
          strokeMiterlimit="10"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}
