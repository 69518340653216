import { useEffect, useMemo, useState } from "react";
import { BuildingCompany } from "../../../types/building";
import DefaultInput from "../../Input/DefaultInput";
import RadioButtonGroup from "../../Input/RadioButtonGroup";
import ListPage, { SearchCondition } from "../../ListPage";
import { TableColumnType } from "../../Table";
import { getBuildingCompanyList } from "../../../services/buildingService";
import { BOOLEAN_TYPES } from "../../../types/comboBoxOption";
import { useModal } from "../../../contexts/Modal";
import { companyConnectOptions } from "../../PageComponents/IotDevice/Space/ToiletList/CreateForm";
import CommonCheckbox from "../../Input/Checkbox";

type Props = {
  buildingId?: string;
  companyIds: string[];
  isConnectedAllCompany?: boolean;
  onCompanyIdsChange: (ids: string[], selectedValue: string) => void;
};

export default function BuildingCompanyTable({
  buildingId = "",
  companyIds,
  isConnectedAllCompany = true,
  onCompanyIdsChange,
}: Props) {
  const { showAlert } = useModal();
  const [selectedConnect, setSelectedConnect] = useState("");

  useEffect(() => {
    setSelectedConnect(
      isConnectedAllCompany ? BOOLEAN_TYPES.TRUE : BOOLEAN_TYPES.FALSE
    );
  }, [buildingId]);

  const companyColumnInfo: TableColumnType<BuildingCompany>[] = [
    {
      header: "전체 선택",
      headerRender: (items: BuildingCompany[]) => {
        const allChecked = items.length
          ? items.every((item) => companyIds?.includes(item.companyId))
          : false;

        return (
          <div className="flex items-center">
            <CommonCheckbox
              checked={allChecked}
              onClick={() => handleCheckedAllChange(items, allChecked)}
            />
          </div>
        );
      },
      render: (item: BuildingCompany) => (
        <div className="flex justify-center items-center">
          <CommonCheckbox
            checked={companyIds?.includes(item.companyId)}
            onClick={() => handleCheckedClick(item.companyId)}
          />
        </div>
      ),
    },
    { header: "회사명", name: "companyName" },
    { header: "도메인", name: "mailDomain" },
  ];

  const handleCheckedAllChange = (
    items: BuildingCompany[],
    allChecked: boolean
  ) => {
    const newCompanyIds = !allChecked
      ? items.map((item) => item.companyId)
      : [];
    onCompanyIdsChange(newCompanyIds, selectedConnect);
  };

  const handleCheckedClick = (companyId: string) => {
    const isIncluded = companyIds.includes(companyId);

    let newCompanyIds: string[] = [];
    if (isIncluded) {
      newCompanyIds = companyIds.filter((ids) => ids !== companyId);
    } else {
      newCompanyIds = [...companyIds, companyId];
    }

    onCompanyIdsChange(newCompanyIds, selectedConnect);
  };

  const handleConnectChange = (value: string) => {
    if (value === BOOLEAN_TYPES.FALSE && !buildingId) {
      showAlert("건물을 선택해주세요");
      return;
    }

    setSelectedConnect(value);
    onCompanyIdsChange(companyIds, value);
  };

  const getBuildingCompanyListByBuildingId = useMemo(
    () => (searchCondition: SearchCondition<BuildingCompany>) =>
      getBuildingCompanyList({ ...searchCondition, buildingId }),
    [buildingId]
  );

  return (
    <>
      <div className="flex flex-col gap-1.5 w-full">
        <RadioButtonGroup
          options={companyConnectOptions}
          value={selectedConnect}
          onChange={handleConnectChange}
        />
        {selectedConnect === BOOLEAN_TYPES.FALSE && (
          <ListPage
            getDataApi={getBuildingCompanyListByBuildingId}
            columnInfo={companyColumnInfo}
            tableTitle="목록"
          />
        )}
      </div>
    </>
  );
}
