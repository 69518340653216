type Props = {};
export default function UseNoIcon(props: Props) {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        y="0.5"
        width="20"
        height="20"
        rx="2"
        fill="black"
        fillOpacity="0.05"
      />
      <path
        d="M13.0684 14H11.9834L8.04102 8.31738H7.97656V14H6.79492V6.22266H7.89062L11.833 11.916H11.9082V6.22266H13.0684V14Z"
        fill="#222222"
      />
    </svg>
  );
}
