type Props = {};

export default function EmergencyMenuIcon(props: Props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.6">
        <path
          d="M13.1848 19.9974L12.425 20.2936C12.151 20.3963 11.849 20.3963 11.575 20.2936L10.8152 19.9974C8.8906 19.2428 7.2375 17.9268 6.07077 16.2203C4.90403 14.5139 4.27762 12.4959 4.27295 10.4287V6.84851C5.73142 7.05636 7.21895 6.86701 8.57884 6.30039C9.93872 5.73377 11.1206 4.81087 12 3.62891C13.6098 5.97277 16.2756 7.08032 19.727 6.84851V10.4287C19.7224 12.4959 19.0959 14.5139 17.9292 16.2203C16.7625 17.9268 15.1094 19.2428 13.1848 19.9974V19.9974Z"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.6868 12.4589H14.4452C14.3664 12.4589 14.2914 12.4289 14.2314 12.3727L13.3649 11.5287C13.3649 11.5287 13.3274 11.5025 13.3049 11.495C13.2749 11.4875 13.0761 11.75 13.0499 11.8025C13.0123 11.8813 12.7535 12.2601 12.581 12.519C12.4947 12.6502 12.4872 12.819 12.566 12.9578C12.566 12.9578 12.566 12.9616 12.566 12.9653L14.5502 16.7463L14.7753 17.1739H13.83C13.6125 17.1739 13.4099 17.0726 13.2899 16.9038C13.2899 16.9038 13.2899 16.9038 13.2862 16.9L13.1924 16.7463L11.2569 13.5767C11.2344 13.5392 11.1744 13.5467 11.1631 13.5917L10.8668 14.852C10.8668 14.852 10.7018 15.5797 10.1954 15.5797H6.58325C6.58325 15.5797 6.70703 15.0771 7.29593 14.7583C7.34469 14.732 7.4047 14.717 7.46097 14.717H9.59525C9.59525 14.717 9.6215 14.717 9.63651 14.717C9.68902 14.7208 9.85406 14.717 9.86156 14.4619C9.86156 14.4619 9.87282 13.0366 10.0529 12.6652L10.9756 10.8573C10.9943 10.8235 10.9643 10.7823 10.9268 10.7823H9.97784C9.93283 10.7823 9.88782 10.8048 9.86156 10.8423L9.14889 11.8025C9.14889 11.8025 9.14138 11.81 9.14138 11.8138C9.11513 11.8513 8.89007 12.1589 8.46622 11.9226C8.46622 11.9226 8.13989 11.7125 8.31618 11.4199L9.37019 9.97208C9.4227 9.90081 9.50522 9.8558 9.59525 9.8558H12.5697C12.5697 9.8558 13.725 9.79203 14.6215 11.4387C14.6402 11.4724 14.6778 11.495 14.7153 11.495H15.6868C15.9493 11.495 16.1631 11.7088 16.1631 11.9713C16.1631 12.2339 15.9493 12.4477 15.6868 12.4477V12.4589Z"
          fill="white"
        />
        <path
          d="M13.2526 7.65967C13.8464 7.65967 14.334 8.11831 14.334 8.68798C14.334 9.25766 13.8512 9.7163 13.2526 9.7163C12.6539 9.7163 12.1711 9.25766 12.1711 8.68798C12.1711 8.11831 12.6539 7.65967 13.2526 7.65967Z"
          fill="white"
        />
      </g>
    </svg>
  );
}
