import { HttpStatusCode } from "axios";
import { useContext, useMemo, useState } from "react";

import {
  BuildingCompany,
  BuildingGroup,
  TagSelectedItem,
  Toilet,
  ToiletCompartment,
  buildingGroupCategoryCodeValue,
} from "../../../../../../types/building";
import { ComboBoxType } from "../../../../../../types/search";
import {
  GENDER_OPTIONS,
  BOOLEAN_TYPES,
} from "../../../../../../types/comboBoxOption";

import { createBooleanOptions } from "../../../../../../utils/comboBoxUtils";
import {
  validationFunctions,
  validationResultMessage,
} from "../../../../../../libs/validations";

import { PopupContext } from "../../../../../../contexts/Popup";

import {
  getBuildingCompanyList,
  getBuildingFloor,
  postToilet,
} from "../../../../../../services/buildingService";

import DefaultButton from "../../../../../DefaultButton";
import FormHeader from "../../../../../Form/Header";
import FormRow from "../../../../../Form/Row";
import FormRowLabel from "../../../../../Form/RowLabel";
import DefaultInput from "../../../../../Input/DefaultInput";
import RadioButtonGroup from "../../../../../Input/RadioButtonGroup";
import ListPage, { SearchCondition } from "../../../../../ListPage";
import DefaultSelect from "../../../../../SelectBox/DefaultSelect";
import Spinner from "../../../../../Spinner";
import { TableColumnType } from "../../../../../Table";
import TagSingleFilter from "../../../../../TagFilter/TagSingleFilter";
import { useModal } from "../../../../../../contexts/Modal";
import CommonCheckbox from "../../../../../Input/Checkbox";
import PermissionWrapper from "../../../../../PermissionWrapper";

import MinusButtonIcon from "../../../../../../svgs/icons/Common/MinusButton";
import PlusButtonIcon from "../../../../../../svgs/icons/Common/PlusButton";

type Props = {};

const MAX_TOILET_NAME_LENGTH = 20;
const TOILET_NAME_DESCRIPTION =
  "※ 화장실 명은 가급적 다른 층의 화장실과 구분할 수 있도록 입력하세요";

export const companyConnectOptions = createBooleanOptions("전체", "회사 지정");

export default function ToiletCreateForm(props: Props) {
  const { refreshAndClosePopup, closePopup } = useContext(PopupContext);
  const { showAlert, showConfirm, handleError } = useModal();
  const [toilet, setToilet] = useState<Partial<Toilet>>({
    isActive: true,
    spaceToiletCompartmentCreateRequests: [
      {
        appendId: 1,
        toiletCompartmentName: "",
        isActive: true,
      },
    ],
  });
  const [floorOptions, setFloorOptions] = useState<ComboBoxType[]>([]);
  const [selectedTag, setSelectedTag] = useState<TagSelectedItem>();
  const [isLoading, setIsLoading] = useState(false);
  const [companyConnect, setCompanyConnect] = useState(BOOLEAN_TYPES.TRUE);

  const fetchFloor = async (buildingId: string) => {
    try {
      setIsLoading(true);

      const response = await getBuildingFloor(buildingId);

      if (response.data) {
        const options = response.data.map((item) => ({
          value: item.buildingFloorId,
          label: item.floorName,
        }));

        setFloorOptions(options);
      }
    } catch (err: any) {
      handleError(err, "층 정보 조회");
    } finally {
      setIsLoading(false);
    }
  };

  const handlePost = () => {
    const names =
      toilet.spaceToiletCompartmentCreateRequests?.map(
        (item) => item.toiletCompartmentName
      ) ?? [];

    const errorMessage = validationResultMessage([
      validationFunctions.required(toilet.buildingId, "위치"),
      validationFunctions.required(toilet.buildingFloorId, "층"),
      validationFunctions.required(toilet.toiletName, "화장실명"),
      validationFunctions.required(toilet.gender, "성별"),
      ...(companyConnect === BOOLEAN_TYPES.FALSE
        ? [validationFunctions.required(toilet.companyIds, "회사 지정")]
        : []),
      ...(names.length > 1
        ? [validationFunctions.required(names, "칸명")]
        : []),
    ]);

    if (errorMessage) {
      showAlert(errorMessage);
    } else {
      showConfirm("등록 하시겠습니까?", createToiletData);
    }
  };

  const createToiletData = async () => {
    const spaceToiletCompartmentCreateRequests =
      toilet.spaceToiletCompartmentCreateRequests?.filter(
        (item) => item.toiletCompartmentName
      );

    const data = {
      toiletName: toilet.toiletName,
      buildingId: toilet.buildingId,
      buildingFloorId: toilet.buildingFloorId,
      gender: toilet.gender,
      isActive: toilet.isActive,
      spaceToiletCompartmentCreateRequests,
      companyIds: toilet.companyIds ?? [],
      isConnectedAllCompany: companyConnect === BOOLEAN_TYPES.TRUE,
    } as Partial<Toilet>;

    try {
      const responseCreate = await postToilet(data);
      if (responseCreate.status === HttpStatusCode.Ok) {
        showAlert("등록 되었습니다.");
        refreshAndClosePopup();
      }
    } catch (err: any) {
      handleError(err, "등록");
    }
  };

  const handleBuildingChange = (item: BuildingGroup) => {
    if (item.buildingId) {
      setSelectedTag(item);
      handleToiletChange("buildingId")(item.buildingId);
      handleToiletChange("buildingFloorId")("");
      handleToiletChange("companyIds")([]);
      fetchFloor(item.buildingId);
    } else {
      console.error("Invalid buildingId while changing building");
    }
  };

  const handleCompanyRadioChange = (value: string) => {
    if (value === BOOLEAN_TYPES.TRUE) {
      handleToiletChangeObject({ companyIds: [], isConnectedAllCompany: true });
      setCompanyConnect(value);
    } else if (value === BOOLEAN_TYPES.FALSE) {
      if (toilet.buildingId) {
        setCompanyConnect(value);
      } else {
        showAlert("건물이 선택되지 않았습니다.");
      }
    } else {
      console.error("radio button doesn't exist");
    }
  };

  const handleToiletChangeObject = (data: Partial<Toilet>) => {
    setToilet((prev) => ({ ...prev, ...data }));
  };

  const handleToiletChange =
    (key: keyof Toilet) => (value: string | string[]) => {
      setToilet((prev) => ({ ...prev, [key]: value }));
    };

  const compartmentColumnInfo: TableColumnType<ToiletCompartment>[] = [
    {
      header: "칸명",
      render: (item: ToiletCompartment) => (
        <div className="flex justify-center items-center px-2">
          <DefaultInput
            value={item.toiletCompartmentName}
            placeholder="칸명을 입력해주세요"
            onChange={(value: string) =>
              handleCompartmentChange(value, item.appendId)
            }
            minWidth="w-full"
            maxLength={50}
            hideMaxLength
          />
        </div>
      ),
    },
    {
      header: "상세",
      render: (item: ToiletCompartment) => {
        const appendCount =
          toilet.spaceToiletCompartmentCreateRequests?.filter(
            (c) => !c.toiletCompartmentId
          ).length ?? 0;

        return (
          <div className="flex items-center justify-center min-w-icon">
            {(toilet.spaceToiletCompartmentCreateRequests?.length ?? 0) > 1 && (
              <DefaultButton
                size="icon"
                onClick={() => handleCompartmentRemove(item.appendId)}
              >
                <MinusButtonIcon />
              </DefaultButton>
            )}

            <DefaultButton
              size="icon"
              onClick={() => handleCompartmentAdd(appendCount)}
            >
              <PlusButtonIcon />
            </DefaultButton>
          </div>
        );
      },
    },
  ];

  const handleCompartmentChange = (value: string, id: number) => {
    setToilet((prev) => ({
      ...prev,
      spaceToiletCompartmentCreateRequests:
        prev.spaceToiletCompartmentCreateRequests?.map((item) =>
          item.appendId === id
            ? { ...item, toiletCompartmentName: value }
            : item
        ),
    }));
  };

  const handleCompartmentRemove = (id: number) => {
    setToilet((prev) => {
      const filteredData = prev.spaceToiletCompartmentCreateRequests?.filter(
        (item) => item.appendId !== id
      );

      const updatedData = filteredData?.map((item, index) => ({
        ...item,
        appendId: index + 1,
      }));

      return {
        ...prev,
        spaceToiletCompartmentCreateRequests: updatedData,
      };
    });
  };

  const handleCompartmentAdd = (appendCount: number) => {
    const item: ToiletCompartment = {
      appendId: appendCount + 1,
      toiletCompartmentName: "",
      isActive: true,
    };

    setToilet((prev) => ({
      ...prev,
      spaceToiletCompartmentCreateRequests: [
        ...(prev.spaceToiletCompartmentCreateRequests ?? []),
        item,
      ],
    }));
  };

  const companyColumnInfo: TableColumnType<BuildingCompany>[] = [
    {
      header: "전체 선택",
      headerRender: (items: BuildingCompany[]) => {
        const allChecked = items.length
          ? items.every((item) => toilet.companyIds?.includes(item.companyId))
          : false;

        return (
          <div className="flex items-center">
            <CommonCheckbox
              checked={allChecked}
              onClick={() => handleCheckedAllChange(items)}
            />
          </div>
        );
      },
      render: (item: BuildingCompany) => (
        <div className="flex justify-center items-center">
          <CommonCheckbox
            checked={toilet.companyIds?.includes(item.companyId)}
            onClick={() => handleCheckedChange(item.companyId)}
          />
        </div>
      ),
    },
    { header: "회사명", name: "companyName" },
    { header: "도메인", name: "mailDomain" },
  ];

  const handleCheckedAllChange = (items: BuildingCompany[]) => {
    setToilet((prev) => {
      const allChecked = items.every((item) =>
        prev.companyIds?.includes(item.companyId)
      );
      const newCompanyIds = allChecked
        ? []
        : items.map((item) => item.companyId);

      return { ...prev, companyIds: newCompanyIds };
    });
  };

  const handleCheckedChange = (companyId: string) => {
    setToilet((prev) => {
      const isIncluded = prev.companyIds?.includes(companyId);

      let newCompanyIds: string[] = [];

      if (isIncluded) {
        newCompanyIds = prev.companyIds?.filter((id) => id !== companyId) ?? [];
      } else {
        newCompanyIds = [...(prev.companyIds ?? []), companyId];
      }

      return { ...prev, companyIds: newCompanyIds };
    });
  };

  const getBuildingCompanyListByBuildingId = useMemo(
    () => (searchCondition: SearchCondition<BuildingCompany>) =>
      getBuildingCompanyList({
        ...searchCondition,
        buildingId: toilet.buildingId,
      }),
    [toilet.buildingId]
  );

  return (
    <div className="flex flex-col gap-5">
      {isLoading && <Spinner />}
      <div className="flex flex-col max-w-[850px] max-h-[700px] overflow-y-auto">
        <FormHeader title="건물 선택" />
        <FormRow>
          <FormRowLabel title="위치" isRequired>
            <div className="flex flex-col gap-1.5">
              <TagSingleFilter
                selectType="building"
                selectedItem={selectedTag}
                onChangeBuilding={handleBuildingChange}
                categoryCode={buildingGroupCategoryCodeValue.IOT_DEVICE}
              />
              <DefaultSelect
                value={toilet.buildingFloorId}
                placeholder="층 선택"
                optionList={floorOptions}
                onChange={handleToiletChange("buildingFloorId")}
                disabled={
                  toilet.buildingId === undefined || floorOptions.length === 0
                }
              />
            </div>
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="회사 연결" isRequired>
            <div className="flex flex-col gap-1.5 w-[500px]">
              <RadioButtonGroup
                options={companyConnectOptions}
                value={companyConnect}
                onChange={handleCompanyRadioChange}
              />
              {companyConnect === BOOLEAN_TYPES.FALSE && (
                <ListPage
                  getDataApi={getBuildingCompanyListByBuildingId}
                  columnInfo={companyColumnInfo}
                  tableTitle="목록"
                />
              )}
            </div>
          </FormRowLabel>
        </FormRow>

        <FormHeader title="화장실 정보" />
        <FormRow>
          <FormRowLabel title="화장실명" isRequired>
            <div className="flex flex-col gap-1">
              <DefaultInput
                value={toilet.toiletName}
                onChange={handleToiletChange("toiletName")}
                maxLength={MAX_TOILET_NAME_LENGTH}
                placeholder="화장실명을 입력해주세요"
              />
              <div className="text-xs">{TOILET_NAME_DESCRIPTION}</div>
            </div>
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="성별" isRequired>
            <DefaultSelect
              placeholder="성별 선택"
              value={toilet.gender}
              onChange={handleToiletChange("gender")}
              optionList={GENDER_OPTIONS}
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="칸 목록">
            <div className="w-[500px]">
              <ListPage<ToiletCompartment>
                getDataApi={Promise.resolve}
                columnInfo={compartmentColumnInfo}
                selectedMode
                selectedData={toilet.spaceToiletCompartmentCreateRequests}
                hidePagination
                isTableScroll
                onlyRenderTable
              />
            </div>
          </FormRowLabel>
        </FormRow>
      </div>

      <div className="flex justify-center gap-2">
        <DefaultButton onClick={closePopup}>닫기</DefaultButton>
        <PermissionWrapper>
          <DefaultButton color="primary" onClick={handlePost}>
            등록
          </DefaultButton>
        </PermissionWrapper>
      </div>
    </div>
  );
}
