import { SearchCondition } from "../components/ListPage";
import request, { ListResponse } from "../libs/request";
import { Banner } from "../types/banner";

const BANNER_API_PREFIX = "/v2/admin/etc/banner";

export const getBannerList = (params: SearchCondition<Banner>) => {
  const { buildingId, ...otherParams } = params;

  const newParams = {
    ...otherParams,
    ...(buildingId && { buildingId: buildingId.join(",") }),
  };

  return request.get<ListResponse<Banner>>(BANNER_API_PREFIX, {
    params: newParams,
  });
};

export const postBanner = (data: FormData) =>
  request.post(BANNER_API_PREFIX, data);

export const getBanner = (bannerId: number) =>
  request.get<Banner>(BANNER_API_PREFIX + `/${bannerId}`);

export const putBanner = (bannerId: number, data: FormData) =>
  request.put(BANNER_API_PREFIX + `/${bannerId}`, data);
