import { useContext, useState } from "react";

import DefaultButton from "../../../components/DefaultButton";
import ListPage from "../../../components/ListPage";
import { TableColumnType } from "../../../components/Table";
import { ComponentType } from "../../../types/search";

import { FiEdit2 } from "react-icons/fi";
import {
  getSituationList,
  putSituationEnd,
  putSituationStart,
} from "@/services/emergencyService";

import { addDefaultOption } from "../../../utils/comboBoxUtils";
import SituationListTopRight from "@/components/PageComponents/Emergency/SituationList/ListTopRight";
import {
  radioBoxSituationStatus,
  radioBoxSituationType,
  Situation,
} from "@/types/emergency";
import { useModal } from "@/contexts/Modal";
import { PopupContext } from "@/contexts/Popup";
import SituationListDetailView from "@/components/PageComponents/Emergency/SituationList/DetailView";
import { useNavigate } from "react-router-dom";
import { HttpStatusCode } from "axios";
import Spinner from "@/components/Spinner";

type Props = {};

export default function SituationList(props: Props) {
  const { showAlert, showConfirm, handleError } = useModal();
  const { refreshAndClosePopup } = useContext(PopupContext);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const handleStartSituation = (item: Situation) => {
    showConfirm(
      `${item.evacuationName} ${item.evacuationCategoryName} ` +
        "상황 발생 하시겠습니까?",
      async () => postStart(item.evacuationId)
    );
  };

  const handleEndSituation = (item: Situation) => {
    showConfirm(
      `${item.evacuationName} ${item.evacuationCategoryName} ` +
        "상황 종료 하시겠습니까?",
      async () => postEnd(item.evacuationId)
    );
  };

  const postStart = async (situationId: string) => {
    try {
      setIsLoading(true);
      const res = await putSituationStart(situationId);
      if (res.status === HttpStatusCode.Ok) {
        showAlert("상황이 발생 되었습니다.");
        refreshAndClosePopup();
      }
    } catch (e: any) {
      handleError(e, "상황 발생");
    } finally {
      setIsLoading(false);
    }
  };

  const postEnd = async (situationId: string) => {
    try {
      setIsLoading(true);
      const res = await putSituationEnd(situationId);
      if (res.status === HttpStatusCode.Ok) {
        showAlert("상황이 종료 되었습니다.");
        refreshAndClosePopup();
      }
    } catch (e: any) {
      handleError(e, "상황 종료");
    } finally {
      setIsLoading(false);
    }
  };

  const changeButtonType = (status: Situation) => {
    return status.status === "CREATED" ? (
      <DefaultButton
        size="tableButton"
        color="red"
        onClick={() => handleStartSituation(status)}
      >
        상황 발생
      </DefaultButton>
    ) : status.status === "IN_PROGRESS" ? (
      <DefaultButton
        size="tableButton"
        color="emerald"
        onClick={() => handleEndSituation(status)}
      >
        상황 종료
      </DefaultButton>
    ) : (
      <DefaultButton size="tableButton" color="gray" disabled>
        상황 종료
      </DefaultButton>
    );
  };

  const columnInfo: TableColumnType<Situation>[] = [
    { header: "No.", defaultNo: true, width: "no" },
    { header: "상황명", name: "evacuationName" },
    { header: "상황 발생 주소", name: "incidentAddress" },
    { header: "상황 유형", name: "evacuationCategoryName" },
    {
      header: "상태",
      render: (item: Situation) =>
        item.status === "CREATED"
          ? "준비"
          : item.status === "IN_PROGRESS"
          ? "진행중"
          : "종료",
    },
    { header: "참여 인원 수", name: "evacueeCount" },
    { header: "비상대피 건물 그룹명", name: "evacuationGroupName" },
    { header: "등록자 이름", name: "createdByName" },
    { header: "등록일시", name: "createdAt" },
    {
      header: "상황 발생/종료",
      render: (item: Situation) => (
        <div className="w-full h-full flex gap-2 justify-center items-center">
          {changeButtonType(item)}
        </div>
      ),
      width: "button",
    },
    {
      header: "대시보드 이동",
      render: (item: Situation) => (
        <div className="w-full h-full flex gap-2 justify-center items-center">
          <DefaultButton
            size="tableButton"
            color="blue"
            onClick={() => {
              window.open("/" + item.evacuationId + "/dashboard", "_blank");
            }}
          >
            대시보드 이동
          </DefaultButton>
        </div>
      ),
      width: "button",
    },
    {
      header: "상세",
      render: (item: Situation, handler) => (
        <div className="w-full h-full flex gap-2 justify-center items-center">
          <DefaultButton
            size="tableIcon"
            onClick={() => {
              if (handler)
                handler.popup({
                  header: "비상대피 상황 상세",
                  content: (
                    <SituationListDetailView situationId={item.evacuationId} />
                  ),
                });
            }}
          >
            <FiEdit2 />
          </DefaultButton>
        </div>
      ),
      width: "icon",
    },
  ];

  const componentList: ComponentType<Situation>[] = [
    {
      labelName: "등록일자",
      typeName: "dateRange",
      keyName: "createdAt",
      category: "date",
    },
    {
      labelName: "상황명",
      typeName: "text",
      keyName: "evacuationName",
      category: "detailSearch",
      placeholder: "상황명을 입력해주세요",
    },
    {
      labelName: "등록자명",
      typeName: "text",
      keyName: "createdByName",
      category: "detailSearch",
      placeholder: "등록자명을 입력해주세요",
    },
    {
      labelName: "상황 유형",
      typeName: "comboBox",
      keyName: "evacuationCategoryCode",
      comboBoxOptions: addDefaultOption(radioBoxSituationType),
      category: "detailSearch",
    },
    {
      labelName: "상태",
      typeName: "comboBox",
      keyName: "status",
      comboBoxOptions: addDefaultOption(radioBoxSituationStatus),
      category: "detailSearch",
    },
  ];

  return (
    <>
      {!isLoading && (
        <ListPage
          getDataApi={getSituationList}
          keyId={(item: Situation) => item.evacuationId}
          columnInfo={columnInfo}
          componentList={componentList}
          tableTitle="비상대피 상황 목록"
          renderTopRight={<SituationListTopRight />}
          defaultPageSize={10}
          defaultPageSizeList={[10, 20, 30]}
        />
      )}
      {isLoading && <Spinner />}
    </>
  );
}
