import { FiEdit2 } from "react-icons/fi";
import ListPage from "../../../../components/ListPage";
import BannerDetailView from "../../../../components/Management/Banner/DetailView";
import BannerListTopRight from "../../../../components/Management/Banner/ListTopRight";
import { TableColumnType } from "../../../../components/Table";
import { getBannerList } from "../../../../services/managementService";
import UseNoIcon from "../../../../svgs/icons/UseYn/UseNoIcon";
import UseYesIcon from "../../../../svgs/icons/UseYn/UseYesIcon";
import {
  BANNER_LOCATION_OPTIONS,
  Banner,
  BannerLinkType,
  BannerLocation,
  bannerLinkTypeMap,
  bannerLocationMap,
} from "../../../../types/banner";
import { ComboBoxType, ComponentType } from "../../../../types/search";
import { addDefaultOption } from "../../../../utils/comboBoxUtils";
import {
  BOOLEAN_TYPES,
  DEFAULT_SELECT_ALL_VALUE,
} from "../../../../types/comboBoxOption";
import DefaultButton from "../../../../components/DefaultButton";

type Props = {};
export default function BannerList(props: Props) {
  const columnInfo: TableColumnType<Banner>[] = [
    { header: "No.", defaultNo: true, width: "no" },
    {
      header: "노출 위치",
      render: (item: Banner) =>
        bannerLocationMap[item.location as BannerLocation] || item.location,
      width: "100",
    },
    {
      header: "유형",
      render: (item: Banner) =>
        bannerLinkTypeMap[item.linkType as BannerLinkType] || item.linkType,
      width: "100",
    },
    { header: "제목", name: "title" },
    {
      header: "노출 상태",
      render: (item: Banner) => (
        <div className="flex justify-center items-center">
          {item.status ? <UseYesIcon /> : <UseNoIcon />}
        </div>
      ),
      width: "icon",
    },
    {
      header: "노출 기간",
      render: (item: Banner) => (
        <span>{`${item.exposeStartAt} ~ ${item.exposeEndAt}`}</span>
      ),
    },
    { header: "최초 등록일시", name: "createdAt", width: "date" },
    { header: "최초 등록자", name: "createdByName" },
    {
      header: "활동",
      render: (item: Banner, handler) => (
        <div className="w-full h-full flex gap-2 justify-center items-center">
          <DefaultButton
            size="tableIcon"
            color="blue"
            onClick={() => {
              if (handler)
                handler.popup({
                  header: "배너 상세",
                  content: <BannerDetailView bannerId={item.etcBannerId} />,
                });
            }}
          >
            <FiEdit2 />
          </DefaultButton>
        </div>
      ),
      width: "icon",
    },
  ];

  const comboBoxIsExpose: ComboBoxType[] = [
    { value: DEFAULT_SELECT_ALL_VALUE, label: "전체" },
    { value: BOOLEAN_TYPES.TRUE, label: "노출중" },
    { value: BOOLEAN_TYPES.FALSE, label: "미노출중" },
  ];

  const comboBoxDateRange: ComboBoxType[] = [
    { value: "createdAt", label: "등록일" },
    { value: "exposeEndAt", label: "노출종료일" },
  ];

  const componentList: ComponentType<Banner>[] = [
    {
      labelName: "날짜 조회",
      typeName: "dateRangeRadio",
      keyName: "dateRangeRadio",
      comboBoxOptions: comboBoxDateRange,
      category: "date",
    },
    {
      labelName: "노출 상태",
      typeName: "comboBox",
      keyName: "status",
      comboBoxOptions: comboBoxIsExpose,
      category: "typeSearch",
    },
    {
      labelName: "노출 위치",
      typeName: "comboBox",
      keyName: "location",
      category: "typeSearch",
      comboBoxOptions: addDefaultOption(BANNER_LOCATION_OPTIONS),
    },
    {
      labelName: "건물 검색",
      typeName: "tagFilter",
      keyName: "tagFilter",
      tagFilter: { tagFilterSearchType: "building" },
      category: "typeSearch",
    },
    {
      labelName: "제목",
      typeName: "text",
      keyName: "title",
      category: "detailSearch",
      placeholder: "제목을 입력해주세요",
    },
    {
      labelName: "최초 등록자",
      typeName: "text",
      keyName: "createdByName",
      category: "detailSearch",
      placeholder: "최초 등록자를 입력해주세요",
    },
  ];

  return (
    <ListPage
      getDataApi={getBannerList}
      keyId={(item: Banner) => item.etcBannerId}
      columnInfo={columnInfo}
      componentList={componentList}
      tableTitle="목록"
      defaultPageSize={100}
      defaultPageSizeList={[100, 200, 500]}
      renderTopRight={<BannerListTopRight />}
    />
  );
}
