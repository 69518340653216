import { SearchCondition } from "../components/ListPage";
import request, { ListResponse } from "../libs/request";
import { Space, SpaceConnection } from "../types/space";
import { getExcelDownloadApi } from "./commonService";

const SPACE_API_PREFIX = "/v2/admin/reservation/space";

export const getSpaceList = (params: SearchCondition<Space>) =>
  request.get<ListResponse<Space>>(SPACE_API_PREFIX + "/list", {
    params,
  });
export const getSpaceListExcel = (params: SearchCondition<Space>) =>
  getExcelDownloadApi(SPACE_API_PREFIX + "/list/excel", params);

export const getSpaceBulkExcel = () =>
  request.get(SPACE_API_PREFIX + "/excel/upload-form", {
    responseType: "blob",
  });

export const getSpaceCompanyBulkExcel = () =>
  request.get(SPACE_API_PREFIX + "/company/excel/upload-form", {
    responseType: "blob",
  });

export const getSpaceRentalDeviceBulkExcel = () =>
  request.get(SPACE_API_PREFIX + "/rental-device/excel/upload-form", {
    responseType: "blob",
  });

export const postSpaceBulkExcel = (formData: FormData) =>
  request.post(SPACE_API_PREFIX + "/list", formData);

export const postSpaceCompanyBulkExcel = (formData: FormData) =>
  request.post(SPACE_API_PREFIX + "/company/list", formData);

export const postSpaceRentalDeviceBulkExcel = (formData: FormData) =>
  request.post(SPACE_API_PREFIX + "/rental-device/list", formData);

export const getSpaceListConnection = (reservationSpaceId: string) =>
  request.get<SpaceConnection[]>(SPACE_API_PREFIX + "/list/connection", {
    params: { reservationSpaceId },
  });

export const postSpace = (formData: FormData) =>
  request.post(SPACE_API_PREFIX, formData);

export const putSpace = (reservationSpaceId: string, formData: FormData) =>
  request.put(SPACE_API_PREFIX + `/${reservationSpaceId}`, formData);

export const getSpace = (reservationSpaceId: string) =>
  request.get<Space>(SPACE_API_PREFIX + `/${reservationSpaceId}`);
