import { WeekDay } from "../types/space";

export const SECOND_UNIT = 1000;
export const MINUTE_UNIT = 60;
export const HOUR_UNIT = 60;
export const DAY_UNIT = 24;

export const formatDate = (date: Date, format: string): string => {
  if (isNaN(date.getTime())) return "";

  const map: { [key: string]: string } = {
    MM: (date.getMonth() + 1).toString().padStart(2, "0"),
    dd: date.getDate().toString().padStart(2, "0"),
    yyyy: date.getFullYear().toString(),
    yy: date.getFullYear().toString().slice(-2),
    HH: date.getHours().toString().padStart(2, "0"),
    mm: date.getMinutes().toString().padStart(2, "0"),
    ss: date.getSeconds().toString().padStart(2, "0"),
  };

  return format.replace(/MM|dd|yyyy|yy|HH|mm|ss/gi, (matched) => map[matched]);
};

export const getToday = (format: string = "yyyy/MM/dd") => {
  const today = new Date();
  return formatDate(today, format);
};

export const convertTimeToDate = (
  time: string,
  date: Date = new Date()
): Date => {
  const [hours, minutes] = time.split(":").map(Number);
  const result = new Date(date);
  result.setHours(hours, minutes, 0, 0);
  return result;
};

export const getWeekRange = (date: Date): { start: Date; end: Date } => {
  const start = new Date(date.getFullYear(), date.getMonth(), date.getDate());
  const end = new Date(start);

  const currentDay = start.getDay();
  const difference = currentDay === 0 ? 6 : currentDay - 1;

  start.setDate(start.getDate() - difference);
  end.setDate(end.getDate() + (6 - difference));

  return { start, end };
};

export const getMonthRange = (date: Date): { start: Date; end: Date } => {
  const start = new Date(date.getFullYear(), date.getMonth(), 1);
  const end = new Date(date.getFullYear(), date.getMonth() + 1, 0);

  return { start, end };
};

export const getYearRange = (date: Date): { start: Date; end: Date } => {
  const start = new Date(date.getFullYear(), 0, 1);
  const end = new Date(date.getFullYear(), 11, 31);

  return { start, end };
};

// 시간을 상대 비교하기위해서 분단위로 변환
export const getTimeInMinutes = (date: string) => {
  if (!date || typeof date !== "string" || !/\d{2}:\d{2}/.test(date)) {
    return 0; // 잘못된 형식의 문자열이거나 빈 문자열인 경우 0을 반환합니다.
  }
  const [hours, minutes] = date ? date.split(":").map(Number) : [0, 0];

  if (
    isNaN(hours) ||
    isNaN(minutes) ||
    hours < 0 ||
    hours >= HOUR_UNIT ||
    minutes < 0 ||
    minutes >= MINUTE_UNIT
  ) {
    return 0; // 잘못된 시간 형식이거나 시간 범위를 벗어난 경우 0을 반환합니다.
  }

  return hours * MINUTE_UNIT + minutes;
};

// "HH:mm"
export const calculateTime = (time: string, minutesToAdd: number) => {
  const timeParts = time.split(":");
  const date = new Date();

  date.setHours(parseInt(timeParts[0]));
  date.setMinutes(parseInt(timeParts[1]));

  date.setMinutes(date.getMinutes() + minutesToAdd);

  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");

  return `${hours}:${minutes}`;
};

export const compareDate = (date1: Date, date2: Date) => {
  if (date1 > date2) {
    return 1;
  } else if (date1 < date2) {
    return -1;
  } else {
    return 0;
  }
};

// "HH:mm"
export const parseTime = (time?: string): { hour: number; minute: number } => {
  if (!time) {
    return { hour: 0, minute: 0 };
  }
  const [hourString, minuteString] = time.split(":");
  const hour = Number(hourString);
  const minute = Number(minuteString);
  return { hour, minute };
};

export const isFalsyWeekDay = (weekDay?: WeekDay): boolean => {
  if (!weekDay) {
    return true;
  }
  return Object.values(weekDay).every((value) => value === false);
};

export const convertWeekDaysToString = (weekdays: WeekDay): string[] => {
  let result: string[] = [];

  if (weekdays.monday) result.push("월");
  if (weekdays.tuesday) result.push("화");
  if (weekdays.wednesday) result.push("수");
  if (weekdays.thursday) result.push("목");
  if (weekdays.friday) result.push("금");
  if (weekdays.saturday) result.push("토");
  if (weekdays.sunday) result.push("일");

  return result;
};

export const getHoursUntil = (dateTime?: string): number => {
  if (!dateTime) {
    return 0;
  }

  const now = new Date();
  const targetDate = new Date(dateTime);

  const diffMilliseconds = targetDate.getTime() - now.getTime();
  if (diffMilliseconds < 0) {
    return 0;
  }

  const diffHours = diffMilliseconds / (SECOND_UNIT * MINUTE_UNIT * HOUR_UNIT);
  return Math.floor(diffHours);
};

// "HH:mm"
export const getTimeDifference = (
  start: string,
  end: string,
  unit: "hour" | "minute" = "hour"
): number => {
  const startDate = new Date(`1970-01-01T${start}:00`);
  const endDate = new Date(`1970-01-01T${end}:00`);
  const diff = endDate.getTime() - startDate.getTime();

  switch (unit) {
    case "hour":
      return diff / (SECOND_UNIT * MINUTE_UNIT * HOUR_UNIT); // (1시간 = 60분 = 3600초 = 3600000밀리초)
    case "minute":
      return diff / (SECOND_UNIT * MINUTE_UNIT); // (1분 = 60초 = 60000밀리초)
    default:
      throw new Error(`Invalid time unit: ${unit}`);
  }
};

export const calculateTimeDifference = (
  pastTimeStr: string,
  utc: boolean = false
) => {
  const currentTime = new Date();
  const pastTime = new Date(pastTimeStr);

  let diffInMilliSeconds = Math.abs(
    currentTime.getTime() -
      (pastTime.getTime() + (utc ? 9 * 60 * 60 * 1000 : 0))
  );

  const hours = Math.floor(diffInMilliSeconds / (1000 * 60 * 60));
  diffInMilliSeconds -= hours * 1000 * 60 * 60;

  const minutes = Math.floor(diffInMilliSeconds / (1000 * 60));

  const stringArray = [];
  if (hours > 0) {
    stringArray.push(`${hours}시간`);
  }
  if (minutes > 0) {
    stringArray.push(`${minutes}분`);
  }

  return stringArray.join(" ");
};

// "yyyy/MM/dd hh:mm:ss"
export const getElapsedTime = (startedAt: string, endedAt: string) => {
  // 날짜 문자열을 Date 객체로 변환
  const startDate = new Date(startedAt);
  const endDate = new Date(endedAt);

  // 두 날짜 간의 차이를 밀리초 단위로 계산
  const diffInMs = endDate.getTime() - startDate.getTime();

  // 밀리초를 일, 시간, 분, 초로 변환
  const diffInSeconds = Math.floor(diffInMs / 1000);
  const days = Math.floor(diffInSeconds / (60 * 60 * 24));
  const hours = Math.floor((diffInSeconds % (60 * 60 * 24)) / (60 * 60));
  const minutes = Math.floor((diffInSeconds % (60 * 60)) / 60);
  const seconds = diffInSeconds % 60;

  return `${days > 1 ? days + "일 " : ""}${hours > 1 ? hours + "시간 " : ""}${
    minutes > 1 ? minutes + "분 " : ""
  }${seconds > 1 ? seconds + "초" : ""}`;
};
