import { useEffect, useState } from "react";
import { ComboBoxType } from "../../types/search";
import DefaultSelect from "../SelectBox/DefaultSelect";
import DefaultTextArea from "../DefaultTextArea";
import { DEFAULT_SELECT_CUSTOM } from "../../types/comboBoxOption";

type Props = {
  message?: string;
  handleTextArea: (value: string) => void;
  options: ComboBoxType[];
  maxLength?: number;
};

export default function Feedback({
  message,
  handleTextArea,
  options,
  maxLength = 1000,
}: Props) {
  const [selectedOption, setSelectedOption] = useState<ComboBoxType | null>(
    null
  );

  const handleSelectChange = (value: string) => {
    const option = options.find((o) => o.value === value);
    setSelectedOption(option ?? null);
    const message =
      option?.value === DEFAULT_SELECT_CUSTOM ? "" : option?.label ?? "";
    handleTextArea(message);
  };

  useEffect(() => {
    if (message) {
      setSelectedOption(options[options.length - 1]);
    }
  }, []);

  return (
    <div className="flex flex-col gap-2 w-full">
      <DefaultSelect
        value={selectedOption?.value}
        optionList={options}
        onChange={(value: string) => handleSelectChange(value)}
        placeholder="메시지를 선택해주세요"
        width="w-fit"
      />
      <DefaultTextArea
        value={message}
        onChange={handleTextArea}
        maxLength={maxLength}
        disabled={selectedOption?.value !== DEFAULT_SELECT_CUSTOM}
        placeholder="내용을 입력해주세요"
      />
    </div>
  );
}
