import { JwtPayload, jwtDecode } from "jwt-decode";
import { SECOND_UNIT } from "../utils/dateUtils";

export const getTokenExpirationDate = (token: string): Date | null => {
  try {
    const decodedToken = jwtDecode<JwtPayload>(token);

    if (decodedToken.exp) {
      // exp 값이 초 단위로 되어 있으므로, 밀리초 단위로 변환하여 Date 객체 생성
      return new Date(decodedToken.exp * SECOND_UNIT);
    } else {
      return null; // exp 값이 없으면 null 반환
    }
  } catch (error) {
    return null;
  }
};
