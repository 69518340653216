type Props = {};

export default function PlusButtonIcon(props: Props) {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="38" height="38" fill="white" />
      <path
        d="M24.6001 19H13.3999"
        stroke="black"
        strokeWidth="1.75"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M19 13.3999V24.6001"
        stroke="black"
        strokeWidth="1.75"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <rect
        x="0.5"
        y="0.5"
        width="37"
        height="37"
        stroke="#333F48"
        strokeOpacity="0.2"
      />
    </svg>
  );
}
