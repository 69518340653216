type Props = {};

export default function OffBadgeIcon(props: Props) {
  return (
    <svg
      width="33"
      height="21"
      viewBox="0 0 33 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.5518 11.1265C13.5518 11.9393 13.4014 12.6483 13.1006 13.2534C12.7998 13.855 12.3826 14.3169 11.8491 14.6392C11.3192 14.9614 10.7194 15.1226 10.0498 15.1226C9.37305 15.1226 8.7679 14.9614 8.23438 14.6392C7.70443 14.3133 7.28906 13.8496 6.98828 13.248C6.6875 12.6429 6.53711 11.9357 6.53711 11.1265C6.53711 10.3136 6.6875 9.60645 6.98828 9.00488C7.28906 8.39974 7.70443 7.93604 8.23438 7.61377C8.7679 7.2915 9.37305 7.13037 10.0498 7.13037C10.7194 7.13037 11.3192 7.2915 11.8491 7.61377C12.3826 7.93604 12.7998 8.39974 13.1006 9.00488C13.4014 9.60645 13.5518 10.3136 13.5518 11.1265ZM7.70801 11.1265C7.70801 11.7424 7.80827 12.2705 8.00879 12.7109C8.21289 13.1478 8.4904 13.479 8.84131 13.7046C9.1958 13.9302 9.59863 14.0412 10.0498 14.0376C10.4974 14.0412 10.8966 13.9302 11.2476 13.7046C11.6021 13.479 11.8796 13.1478 12.0801 12.7109C12.2806 12.2705 12.3809 11.7424 12.3809 11.1265C12.3809 10.5106 12.2806 9.98421 12.0801 9.54736C11.8796 9.10693 11.6021 8.77393 11.2476 8.54834C10.8966 8.32275 10.4974 8.21175 10.0498 8.21533C9.59863 8.21175 9.1958 8.32275 8.84131 8.54834C8.4904 8.77393 8.21289 9.10693 8.00879 9.54736C7.80827 9.98421 7.70801 10.5106 7.70801 11.1265ZM14.8838 7.23779H19.707V8.2583H16.0654V10.6108H19.3633V11.6206H16.0654V15.0151H14.8838V7.23779ZM21.0283 7.23779H25.8516V8.2583H22.21V10.6108H25.5078V11.6206H22.21V15.0151H21.0283V7.23779Z"
        fill="black"
        fillOpacity="0.4"
      />
      <rect
        x="0.5"
        y="1.01514"
        width="32"
        height="19"
        rx="1.5"
        stroke="black"
        strokeOpacity="0.4"
      />
    </svg>
  );
}
