import { useLocation } from "react-router-dom";

import { RouteInfo } from "../../libs/routes";

const getLastSegment = (path: string): string => {
  const segments = path.split("/");
  return segments[segments.length - 1];
};

const findRouteByPath = (
  routes: RouteInfo[],
  path: string
): RouteInfo | null => {
  const lastSegment = getLastSegment(path);

  for (let route of routes) {
    if (route.path === lastSegment) {
      return route;
    }

    if (route.children) {
      const childRoute = findRouteByPath(route.children, path);
      if (childRoute) {
        return childRoute;
      }
    }
  }

  return null;
};

export const useMenuPermission = (routes: RouteInfo[]) => {
  const location = useLocation();
  const matchedRoute = findRouteByPath(routes, location.pathname);
  return matchedRoute?.scope || null;
};
