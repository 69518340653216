export const IMAGE_MAX_SIZE = 2500;

export const FileScale = {
  SMALL: "small",
  MEDIUM: "medium",
  FULL: "full",
};

export type FileInfo = {
  fileId: number;
  scale: string;
  sortOrder: number;
  url: string;
};
