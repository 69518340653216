import { BuildingAndFloor } from "../components/SelectBox/BuildingAndFloorSelect";
import { CheckIconColor } from "../components/TimeTable";
import { FileInfo } from "./file";
import { DASH } from "./search";
import { RESERVATION_SPACE_TYPES, RentalDevice, SpaceGender, WeekDay, reservationSpaceType } from "./space";

export type SpaceReservation = {
  serviceId: string;
  reservationSpaceId: string;
  reservationSpaceName: string;
  reservationSpaceType: reservationSpaceType;
  usePayment: boolean;
  reservationStatus: reservationStatusValue;
  companyName: string;
  spaceType: string;
  spaceName: string;
  cancelType: string;
  realUserName: string;
  createdAt: string;
  reservationAt: string;
  isCancelledByAdmin: boolean;
  reservationDate: string;
  reservationTimeStart: string;
  reservationTimeEnd: string;
  usedDepartment: string;
  actualUserName: string;
  totalSpaceDefaultPrice: number;
  totalDeviceDefaultPrice: number;
  vatDefaultPrice: number;
  isSatisfactionCompleted: boolean;
  buildingAndFloor?: BuildingAndFloor;
  spaceReservationDetailId: string;
  SpaceReservationId: string;
  fixedPrice: number;
  modifyPrice: number;
  cancelInfo: ReservationCancelInfo;
};

export const reservationStatusMap: Record<reservationStatusValue, string> = {
  WAITING: "예약 완료",
  CONFIRMED: "승인 완료",
  CANCELED: "취소",
  IN_USE: "사용 중",
  COMPLETED: "사용 완료",
  CONNECTION: "공간연결에 의한 예약",
  CLEANING: "정리 중",
  OUT_OF_SERVICE: "운영시간 외",
  UNAVAILABLE: "예약 불가",
  AVAILABLE: "예약 가능",
  EMPTY: "",
};

export const RESERVATION_STATUS_TYPES = {
  WAITING: "WAITING", // 예약 대기
  CONFIRMED: "CONFIRMED", // 예약 확인
  CANCELED: "CANCELED", // 예약 취소
  IN_USE: "IN_USE", // 입실 확인
  COMPLETED: "COMPLETED", // 사용 완료
  CONNECTION: "CONNECTION", // 공간연결에 의한 예약
  CLEANING: "CLEANING", // 정리 중
  OUT_OF_SERVICE: "OUT_OF_SERVICE", // 운영시간 외
  UNAVAILABLE: "UNAVAILABLE", // 예약 불가
  AVAILABLE: "AVAILABLE", // 예약 가능
  EMPTY: "EMPTY", // 그 외
} as const;

export type reservationStatusValue = keyof typeof RESERVATION_STATUS_TYPES;

export interface ReservationStatusType {
  value: reservationStatusValue;
  label: string;
  checkColor?: CheckIconColor;
  isSlashed?: boolean;
  isChecked?: boolean;
  isCircle?: boolean;
  isLoading?: boolean;
  isDots?: boolean;
}

export type SpaceReservationData = {
  reservationSpaceId: string;
  reservationSpaceName: string;
  reservationSpaceType: reservationSpaceType;
  useAutoConfirm: boolean;
  useBlockTime: boolean;
  blockTimeStart?: string;
  blockTimeEnd?: string;
  buildingId: string;
  buildingName: string;
  buildingFloorId: string;
  buildingFloorName: string;
  companyList: [
    {
      companyId: string;
      companyName: string;
      isActive: boolean;
    }
  ];
  availableDays: WeekDay;
  availableGender: SpaceGender;
  availableTimeStart: string;
  availableTimeEnd: string;
  availablePeopleCountStart: number;
  availablePeopleCountEnd: number;
  usableTimeStart: number;
  usableTimeEnd: number;
  dailyLimit: number;
  contactInfo: string;
  contactNumber: string;
  reservationSpaceFeatures: string;
  reservationSpaceNote: string;
  usageTermId: number;
  usePayment: boolean;
  defaultPrice: number;
  additionalPrice: number;
  defaultPriceDays: WeekDay;
  defaultPriceTimeStart: string;
  defaultPriceTimeEnd: string;
  rentalDeviceList: RentalDevice[];
  reservationList: ReservationInfo[];
  fileInfo?: {
    items: FileInfo[];
  };
  isActive: boolean;
  createdBy: number;

  longTermReservationDayOfWeek?: WeekDay; // 장기 예약 요일
  longTermReservationDateStart?: string; // 장기 예약 시작일
  longTermReservationDateEnd?: string; // 장기 예약 종료일
  longTermSpaceReservationItemList?: ReservationLongTerm[];

  reservationTimeStart: string;
  reservationTimeEnd: string;

  buildingAndFloor?: BuildingAndFloor;
  reservationAt?: string;

  longTermReservationId: string;
  actualUserId: number;
  actualUserName: string;
  usedDepartment: string;
  userTel: string;
  purpose: string;
  userCount: string;
  totalSpaceDefaultPrice: string;
  totalDeviceDefaultPrice: string;
  vatDefaultPrice: string;
  reservationAtStart: string;
  reservationAtEnd: string;
  isSelfReservation: boolean;
  reservationDate: string;
  beforeCleanTime: string;
  afterCleanTime: string;
  spaceInfo: SpaceReservationData;
  reservationStatus: reservationStatusValue;
  createdAt: string;
  createdByName: string;
  modifiedAt: string;
  cancelComment: string;
  longTermInfo: LongTermReservationType;
  approvalTime: string; // 승인일시
  checkInTime: string; // 입실확인 일시
  isCheckInByAdmin: boolean; // 관리자 취소 여부
  spaceReservationDetailId: string;
  cancelInfo: ReservationCancelInfo;
  fixedPrice: number;
  modifyPrice: number;
};

export type ReservationCancelInfo = {
  spaceReservationDetailId: string;
  longTermReservationId: string;
  cancelType: string;
  isCancelledByAdmin: boolean;
  cancelComment: string;
  createdBy: number;
};

export type LongTermReservationType = {
  longTermReservationId: string;
  reservationSpaceId: string;
  longTermReservationDateStart: string;
  longTermReservationDateEnd: string;
  longTermReservationDayOfWeek: WeekDay;
  longTermReservationTimeStart: string;
  longTermReservationTimeEnd: string;
  beforeCleanTime: string;
  afterCleanTime: string;
  createdBy: number;
  modifiedBy: number;
  reservationList: ReservationInfo[];
};

export type SpaceReservationCreateDto = {
  reservationSpaceId: string;
  purpose: string;
  userCount: string;
  reservationDate: string;
  totalSpaceDefaultPrice: string;
  totalDeviceDefaultPrice: string;
  vatDefaultPrice: string;
  longTermReservationDateStart: string;
  longTermReservationDateEnd: string;
  reservationTimeStart: string;
  reservationTimeEnd: string;
  beforeCleanTime: string;
  afterCleanTime: string;
  isSelfReservation: boolean;
  actualUserId: number;
  actualUserName: string;
  usedDepartment: string;
  userTel: string;
  longTermReservationDayOfWeek: WeekDay;
  rentalDeviceList: number[];
  longTermSpaceReservationItemList: ReservationLongTerm[];
};

export type ReservationInfo = {
  spaceReservationDetailId: string;
  reservationStatus: reservationStatusValue;
  reservationDate: string;
  reservationHour: number;
  reservationTimeStart: string;
  reservationTimeEnd: string;
  beforeCleanTime: string;
  afterCleanTime: string;
  createdBy: number;
};

export type ReservationLongTerm = {
  reservationDate: string;
  totalSpaceDefaultPrice?: string;
  totalDeviceDefaultPrice?: string;
  vatDefaultPrice?: string;
  spaceReservationDetailId?: string;
};

export type ReservationTimeDetail = {
  hour: number;
  minute: number;
  second: number;
  nano: number;
};

export const getReservationDailyStatus = (
  reservationValue: reservationStatusValue
): string => {
  return (
    RESERVATION_DAILY_STATUS.find((status) => status.value === reservationValue)
      ?.label || DASH
  );
};

export const getReservationWeeklyMonthlyStatus = (
  reservationValue: reservationStatusValue
): string => {
  return (
    RESERVATION_WEEKLY_MONTHLY_STATUS.find(
      (status) => status.value === reservationValue
    )?.label || DASH
  );
};

export const RESERVATION_DAILY_STATUS: ReservationStatusType[] = [
  {
    value: "AVAILABLE",
    label: "예약 가능",
    isCircle: true,
  },
  {
    value: "WAITING",
    label: "예약 대기",
    checkColor: "primary",
    isChecked: true,
  },
  {
    value: "CONFIRMED",
    label: "예약 확인",
    isChecked: true,
  },
  {
    value: "IN_USE",
    label: "입실확인",
    isLoading: true,
  },
  {
    value: "CLEANING",
    label: "정리/준비",
    isDots: true,
  },
  {
    value: "COMPLETED",
    label: "사용완료",
    isChecked: true,
  },
  {
    value: "OUT_OF_SERVICE",
    label: "운영시간 외",
  },
  {
    value: "CONNECTION",
    label: "예약불가",
    checkColor: "gray",
    isChecked: true,
  },
];

export const RESERVATION_WEEKLY_MONTHLY_STATUS: ReservationStatusType[] = [
  {
    value: "AVAILABLE",
    label: "예약 가능",
    isCircle: true,
  },
  {
    value: "IN_USE",
    label: "입실 확인",
  },
  {
    value: "COMPLETED",
    label: "사용 완료",
  },
  {
    value: "UNAVAILABLE",
    label: "예약 불가",
    checkColor: "gray",
    isChecked: true,
  },
];

export const convertReservationTypeToLabel = (spaceType: reservationSpaceType) => {
  return spaceType === RESERVATION_SPACE_TYPES.DEFAULT ? "기본(회의실)" : "샤워실"
}

export const convertUseAutoConfirmToLabel = (bool: boolean) => {
  return bool ? "자동 승인" : "수동 승인";
}
