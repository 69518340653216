import { useNavigate } from "react-router-dom";
import DefaultButton from "../../components/DefaultButton";
import notFoundIcon from "../../assets/img/not-found.png";

type Props = {};

export default function NotFound(props: Props) {
  const navigate = useNavigate();

  return (
    <div className="min-w-[956px] h-full min-h-screen w-full bg-gray-100 flex items-center justify-center flex-col">
      <div className="w-[956px] h-[1014px] bg-bg-simbol flex flex-col items-center gap-[84px] pt-[345px]">
        <div className="flex flex-col items-center gap-14">
          <img src={notFoundIcon} alt="NotFound Icon" />
          <div className="flex flex-col items-center gap-6">
            <div className="text-3xl font-semibold bg-sandi-gradient text-transparent bg-clip-text h-[29px]">
              4xx Not Found
            </div>
            <div className="h-px w-[90px] bg-black" />
            <div className="flex flex-col items-center">
              <div className="flex flex-col items-center gap-3">
                <div className="text-2xl font-semibold h-[29px]">
                  요청하신 페이지를 찾을 수 없습니다.
                </div>
                <div className="text-sm font-light opacity-80 h-[29px]">
                  서비스 이용에 불편을 드려 죄송합니다.
                </div>
              </div>
            </div>
          </div>
        </div>
        <DefaultButton
          className="!bg-sandi-gradient !text-white !text-[16px] !w-[250px] h-[56px] rounded-none font-medium"
          onClick={() => {
            navigate("/");
          }}
        >
          메인화면 이동
        </DefaultButton>
      </div>
    </div>
  );
}
