type Props = {};

export default function CloseIcon(props: Props) {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.3">
        <path
          d="M12.5787 3.54953L3.37793 12.7503"
          stroke="black"
          strokeWidth="1.75"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
        <path
          d="M3.37793 3.54953L12.5787 12.7503"
          stroke="black"
          strokeWidth="1.75"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}
