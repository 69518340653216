type Props = {};

export default function ToggleOffFalseIcon(props: Props) {
  return (
    <svg
      width="30"
      height="17"
      viewBox="0 0 30 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.526611" width="29.3333" height="16" rx="8" fill="#CFCFCF" />
      <circle cx="8" cy="8.52661" r="7.5" fill="white" stroke="#CFCFCF" />
    </svg>
  );
}
