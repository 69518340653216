import clsx from "clsx";
import { ComboBoxType } from "../../../types/search";

import CommonRadio from "../CommonRadio";

type Props = {
  options: ComboBoxType[];
  value?: string;
  onChange: (value: string) => void;
  dataTestId?: string;
};

export default function RadioButtonGroup({
  options,
  onChange,
  dataTestId,
  value = "",
}: Props) {
  const handleChange = (value: string) => {
    onChange(value);
  };

  return (
    <div className="radio-button-group flex items-center gap-4 flex-wrap">
      {options.map((option, index) => (
        <label
          key={index}
          className="flex items-center gap-1 cursor-pointer"
          onClick={() => !option.disabled && handleChange(option.value)}
        >
          <CommonRadio
            value={option.value}
            checked={value === option.value}
            disabled={option.disabled}
            dataTestId={dataTestId}
          />
          <span
            className={clsx("text-sm", { "text-gray-400": option.disabled })}
          >
            {option.label}
          </span>
        </label>
      ))}
    </div>
  );
}
