import { TableColumnType } from "../../../../components/Table";
import { Parcel } from "../../../../types/applyService";
import { ComboBoxType, ComponentType, DASH } from "../../../../types/search";
import ListPage, { SearchCondition } from "../../../../components/ListPage";
import {
  getParcelBuildingList,
  getParcelList,
  getPudoListExcel,
} from "../../../../services/applyServiceService";
import UseYesIcon from "../../../../svgs/icons/UseYn/UseYesIcon";
import UseNoIcon from "../../../../svgs/icons/UseYn/UseNoIcon";
import { useEffect, useMemo, useState } from "react";
import Spinner from "../../../../components/Spinner";
import { useModal } from "../../../../contexts/Modal";
import { HttpStatusCode } from "axios";
import {
  addDefaultOption,
  toComboBoxType,
} from "../../../../utils/comboBoxUtils";
import { DATE_RANGE_TYPE } from "../../../../types/comboBoxOption";
import { generateAxiosResponse } from "../../../../libs/request";
import { validationFunctions } from "../../../../libs/validations";

type Props = {};

export default function ParcelList(props: Props) {
  const { handleError } = useModal();
  const [buildingOptions, setBuildingOptions] = useState<ComboBoxType[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const columnInfo: TableColumnType<Parcel>[] = [
    { header: "키오스크", name: "kioskName" },
    { header: "접수번호", name: "receiveNo" },
    {
      header: "접수건수",
      render: (item: Parcel) => {
        const count = Number(item.receiveCount);
        return !isNaN(count) ? count.toLocaleString() : DASH;
      },
      width: "number",
    },
    { header: "서비스", name: "serviceName" },
    { header: "우편구분", name: "postType" },
    { header: "접수구분", name: "receiveType" },
    { header: "접수타입", name: "deliveryType" },
    { header: "보내는사람", name: "sendName" },
    { header: "보내는사람연락처", name: "sendMobile" },
    { header: "샌디아이디", name: "email" },
    { header: "회사명", name: "companyName" },
    { header: "지불방식", name: "payType", width: "yesOrNo" },
    {
      header: "결제여부",
      render: (item: Parcel) => (
        <div className="flex justify-center items-center">
          {item.payYN === "Y" ? <UseYesIcon /> : <UseNoIcon />}
        </div>
      ),
      width: "icon",
    },
    {
      header: "결제금액",
      render: (item: Parcel) => {
        const totalPrice = Number(item.totalPrice);
        return !isNaN(totalPrice) ? totalPrice.toLocaleString() : DASH;
      },
      width: "number",
    },
    {
      header: "접수일시",
      render: (item: Parcel) =>
        renderDateTime(item.receiveDate, item.receiveTime),
      width: "date",
    },
    {
      header: "수거여부",
      render: (item: Parcel) => (
        <div className="flex justify-center items-center">
          {item.pickUpYN === "Y" ? <UseYesIcon /> : <UseNoIcon />}
        </div>
      ),
      width: "icon",
    },
    {
      header: "수거일시",
      render: (item: Parcel) =>
        renderDateTime(item.pickUpDate, item.pickUpTime),
      width: "date",
    },
    {
      header: "취소여부",
      render: (item: Parcel) => (
        <div className="flex justify-center items-center">
          {item.cancelYN === "Y" ? <UseYesIcon /> : <UseNoIcon />}
        </div>
      ),
      width: "icon",
    },
    {
      header: "취소일시",
      render: (item: Parcel) => (item.cancelDate ? item.cancelDate : DASH),
      width: "date",
    },
  ];

  const renderDateTime = (date: string, time: string) => {
    if (!(date && time)) {
      return DASH;
    }

    const formattedDate = `${date.slice(0, 4)}-${date.slice(4, 6)}-${date.slice(
      6,
      8
    )}`;
    const formattedTime = `${time.slice(0, 2)}:${time.slice(2, 4)}:${time.slice(
      4,
      6
    )}`;

    return (
      <div>
        {formattedDate}
        <br />
        {formattedTime}
      </div>
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const res = await getParcelBuildingList();
        if (res.status === HttpStatusCode.Ok) {
          setBuildingOptions(
            toComboBoxType(res.data, "pudoCode", "buildingName")
          );
        }
      } catch (err: any) {
        handleError(err, "건물 목록 조회");
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const componentList: ComponentType<Parcel>[] = [
    {
      typeName: "dateRange",
      keyName: "receipt",
      category: "date",
      validation: [validationFunctions.required],
      dateRangeType: DATE_RANGE_TYPE.TODAY.toString(),
    },
    {
      labelName: "건물 조회",
      typeName: "comboBox",
      keyName: "pudoCode",
      category: "typeSearch",
      comboBoxOptions: addDefaultOption(buildingOptions),
    },
  ];

  const getParcelListWrapper = useMemo(
    () => async (params: SearchCondition<Parcel>) => {
      if (!(params.receiptStart && params.receiptEnd)) {
        return generateAxiosResponse([]);
      }

      try {
        const res = await getParcelList(params);
        if (res.status === HttpStatusCode.Ok) {
          return generateAxiosResponse(res.data.data_list);
        }
      } catch (err: any) {
        handleError(err, "조회");
        return err;
      }
    },
    []
  );

  return (
    <>
      {isLoading && <Spinner />}
      <ListPage
        getDataApi={getParcelListWrapper}
        keyId={(item: Parcel) => `${item.kioskName}_${item.receiveNo}`}
        componentList={componentList}
        columnInfo={columnInfo}
        tableTitle="무인택배 목록"
        excelDownloadApi={getPudoListExcel}
        hidePageSize
        hidePagination
        needExcelDownloadReason
      />
    </>
  );
}
