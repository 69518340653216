import { HttpStatusCode } from "axios";
import { useMemo, useState } from "react";
import { FiEdit } from "react-icons/fi";
import DefaultButton from "../../../../components/DefaultButton";
import ListPage, { SearchCondition } from "../../../../components/ListPage";
import { TableColumnType } from "../../../../components/Table";
import {
  getToiletBulkExcel,
  getToiletCompanyBulkExcel,
  getToiletCompartmentBulkExcel,
  getToiletList,
  getToiletListExcel,
  patchToilet,
  postToiletCompanyBulk,
  postToiletCompartmentBulk,
  postToiletListBulk,
} from "../../../../services/buildingService";
import {
  Toilet,
  buildingGroupCategoryCodeValue,
} from "../../../../types/building";
import { ComponentType } from "../../../../types/search";
import { addDefaultOption } from "../../../../utils/comboBoxUtils";
import {
  CONNECT_OPTIONS,
  USAGE_OPTIONS,
  usageYnLabels,
} from "../../../../types/comboBoxOption";
import ToiletListTopRight from "../../../../components/PageComponents/IotDevice/Space/ToiletList/ListTopRight";
import ToiletDetailView from "../../../../components/PageComponents/IotDevice/Space/ToiletList/DetailView";
import { useModal } from "../../../../contexts/Modal";
import DefaultLabel from "../../../../components/Input/DefaultLabel";
import ExcelFileUploaderButton from "../../../../components/FileUploader/ExcelFileUploaderButton";
import PermissionWrapper from "../../../../components/PermissionWrapper";

type Props = {};

export default function ToiletList(props: Props) {
  const { showAlert, showConfirm, handleError } = useModal();
  const [refresh, setRefresh] = useState(false);

  const columnInfo: TableColumnType<Toilet>[] = [
    { header: "화장실 ID", name: "spaceToiletId", width: "id" },
    { header: "건물명", name: "buildingName" },
    { header: "층명", name: "buildingFloorName" },
    { header: "화장실명", name: "toiletName" },
    {
      header: "성별",
      render: (item: Toilet) =>
        item.gender === "M" ? "남성" : item.gender === "F" ? "여성" : "",
      width: "100",
    },
    { header: "칸수", name: "spaceToiletCompartmentCount", width: "number" },
    {
      header: "연결 입주사 수",
      render: (item: Toilet) =>
        item.isConnectedAllCompany ? "전체" : item.companyToiletMapCount,
      width: "number",
    },
    {
      header: "사용여부",
      render: (item: Toilet) => (
        <div className="flex items-center justify-center">
          <DefaultLabel
            text={item.isActive ? usageYnLabels.TRUE : usageYnLabels.FALSE}
            color={item.isActive ? "blue" : "gray"}
          />
        </div>
      ),
      width: "yesOrNo",
    },
    { header: "등록일시", name: "createdAt", width: "date" },
    {
      header: "상세",
      render: (item: Toilet, handler) => (
        <div className="w-full h-full flex gap-2 justify-center items-center">
          {Boolean(item.buildingFloorId) && (
            <PermissionWrapper>
              <DefaultButton
                size="tableButton"
                color="blue"
                onClick={() => patchData(item)}
              >
                층 연결 해제
              </DefaultButton>
            </PermissionWrapper>
          )}
          <DefaultButton
            size="tableIcon"
            color="blue"
            onClick={() => {
              if (handler)
                handler.popup({
                  header: "화장실 상세",
                  content: (
                    <ToiletDetailView spaceToiletId={item.spaceToiletId} />
                  ),
                });
            }}
          >
            <FiEdit />
          </DefaultButton>
        </div>
      ),
      width: "200",
    },
  ];

  const componentList: ComponentType<Toilet>[] = [
    {
      labelName: "등록일자",
      typeName: "dateRange",
      keyName: "createdAt",
      category: "date",
    },
    {
      labelName: "층 연결유무",
      typeName: "comboBox",
      keyName: "isFloorConnected",
      comboBoxOptions: addDefaultOption(CONNECT_OPTIONS),
      category: "typeSearch",
    },
    {
      labelName: "사용 유무",
      typeName: "comboBox",
      keyName: "isActive",
      comboBoxOptions: addDefaultOption(USAGE_OPTIONS),
      category: "typeSearch",
    },
    {
      labelName: "화장실 ID",
      typeName: "text",
      keyName: "spaceToiletId",
      category: "detailSearch",
      placeholder: "화장실 ID를 입력해주세요",
    },
    {
      labelName: "화장실명",
      typeName: "text",
      keyName: "toiletName",
      category: "detailSearch",
      placeholder: "화장실명을 입력해주세요",
    },
    {
      typeName: "buildingAndFloor",
      keyName: "buildingAndFloor",
      category: "detailSearch",
      buildingGroupCategoryCode: buildingGroupCategoryCodeValue.IOT_DEVICE,
    },
    {
      labelName: "회사명",
      typeName: "text",
      keyName: "companyName",
      category: "detailSearch",
      placeholder: "회사명을 입력해주세요",
    },
  ];

  const patchData = async (item: Toilet) => {
    showConfirm("연결을 끊으시겠습니까?", async () => {
      try {
        const response = await patchToilet(item.spaceToiletId);
        if (response.status === HttpStatusCode.Ok) {
          showAlert("연결끊기가 적용되었습니다.");
          setRefresh(!refresh);
        }
      } catch (err: any) {
        handleError(err, "연결끊기");
      }
    });
  };

  const getToiletListWrapper = useMemo(
    () => (params: SearchCondition<Toilet>) => {
      return getToiletList(params);
    },
    [refresh]
  );

  return (
    <ListPage
      getDataApi={getToiletListWrapper}
      excelUpload={
        <>
          <ExcelFileUploaderButton
            title="화장실"
            actionButtonText="화장실 일괄 등록"
            getExcelDataApi={getToiletBulkExcel}
            postExcelDataApi={postToiletListBulk}
          />
          <ExcelFileUploaderButton
            title="화장실 칸"
            actionButtonText="화장실 칸 일괄 등록"
            getExcelDataApi={getToiletCompartmentBulkExcel}
            postExcelDataApi={postToiletCompartmentBulk}
          />
          <ExcelFileUploaderButton
            title="화장실 이용사"
            actionButtonText="화장실 이용사 일괄 등록"
            getExcelDataApi={getToiletCompanyBulkExcel}
            postExcelDataApi={postToiletCompanyBulk}
          />
        </>
      }
      excelDownloadApi={getToiletListExcel}
      keyId={(item: Toilet) => item.spaceToiletId}
      columnInfo={columnInfo}
      componentList={componentList}
      tableTitle="화장실 목록"
      renderTopRight={<ToiletListTopRight />}
    />
  );
}
