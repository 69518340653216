import clsx from "clsx";
import React from "react";

type ButtonSize =
  | "none"
  | "small"
  | "medium"
  | "icon"
  | "tableTop"
  | "tableButton"
  | "popupSmall"
  | "popupDefault"
  | "popupContentButton"
  | "unmaskingButton"
  | "tableIcon"
  | "square";
type ButtonColor =
  | "yellow"
  | "blue"
  | "emerald"
  | "pink"
  | "brown"
  | "red"
  | "default"
  | "gray"
  | "gray_default"
  | "gray20"
  | "primary"
  | "white";

type Props = {
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  size?: ButtonSize;
  color?: ButtonColor;
  disabled?: boolean;
  children: React.ReactNode;
  className?: string;
  testId?: string;
};

const defaultColor = "gray_default";
const colorListUp: { [key: string]: string } = {
  yellow: "bg-yellow-400",
  blue: "bg-brand-blue",
  emerald: "bg-emerald-400",
  brown: "bg-yellow-800",
  pink: "bg-pink-400",
  red: "bg-red-500",
  gray_default: "bg-[#333F48] bg-opacity-20 !text-black",
  default: "bg-white",
  gray: "bg-black bg-opacity-5 !text-black",
  gray20: "bg-brand-primary-gray-100 bg-opacity-20 !text-black text-opacity-90",
  primary: "bg-sandi-gradient text-white",
  white:
    "!bg-white border border-brand-primary-gray-100 border-opacity-20 !text-black",
};

const defaultSize = "small";
const sizeMap: { [key: string]: string } = {
  none: "",
  small: "min-w-10 w-fit h-8 px-4 text-sm font-medium",
  medium: "w-fit h-10 px-6",
  icon: "w-fit h-fit",
  tableTop:
    "px-[15px] h-[30px] !text-brand-text-black-disabled rounded-none text-label border border-gray-200",
  tableButton: "px-4 py-[7px] rounded-none !text-sm font-medium",
  tableIcon:
    "h-[32px] w-[32px] rounded-none !text-sm font-medium flex items-center justify-center",
  popupSmall: "rounded-none px-4 py-[11px] text-sm font-medium",
  popupDefault: "rounded-none px-4 h-[38px] text-sm font-medium",
  popupContentButton:
    "rounded-none px-4 py-[6px] h-[28px] text-sm font-medium flex items-center justify-center",
  square: "w-[100px] h-[100px] text-sm",
  unmaskingButton:
    "rounded-none px-3 py-[6px] h-[26px] text-sm font-medium flex items-center justify-center",
};

export default function DefaultButton({
  onClick,
  children,
  className,
  testId,
  color = defaultColor,
  size = defaultSize,
  disabled = false,
}: Props) {
  return (
    <button
      className={clsx([
        `${
          colorListUp[color] ?? colorListUp[defaultColor]
        } text-white hover:brightness-105 whitespace-nowrap ${
          sizeMap[size] ?? sizeMap[defaultSize]
        }`,
        className,
      ])}
      onClick={(e) => {
        if (onClick) {
          onClick(e);
        }
      }}
      disabled={disabled}
      data-testid={`${testId}-default-button`}
    >
      {children}
    </button>
  );
}
