import { useContext } from "react";
import { PopupContext } from "../../../../../../contexts/Popup";
import DefaultButton from "../../../../../DefaultButton";
import PermissionWrapper from "../../../../../PermissionWrapper";
import ToiletCreateForm from "../CreateForm";

type Props = {};

export default function ToiletListTopRight(props: Props) {
  const { openPopup } = useContext(PopupContext);

  return (
    <PermissionWrapper>
      <DefaultButton
        size="tableTop"
        color="default"
        onClick={() =>
          openPopup({
            header: "화장실 등록",
            content: <ToiletCreateForm />,
          })
        }
      >
        등록
      </DefaultButton>
    </PermissionWrapper>
  );
}
