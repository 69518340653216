import { FileInfo } from "./file";
import { ComboBoxType } from "./search";

export type PostStatus = "PUBLIC" | "PRIVATE" | "REPORTED";

export type Post = {
  buildingId: string;
  buildingName: string;
  buildingInfo: [{ buildingId: string; buildingName: string }];
  commentCreatedAt: string;
  commentCount: number;
  companyId: string;
  content: string;
  createdAt: string;
  createdBy: number;
  groupId: string;
  category: string;
  lifeCategoryId: number;
  lifePostId: number;
  likeCount: number;
  modifiedAt: string;
  shareCount: number;
  status: PostStatus;
  title: string;
  useComment: boolean;
  email: string;
  userId: number;
  userName: string;
  nickname: string;
  viewCount: string;
  tagFilter: string;
  categoryNameL: string;
  categoryNameM: string;
  categoryNameS: string;
  storeId: string;
  imageFiles: File[];
  isTop: boolean;
  isDeleted: boolean;
  isDisplayHotCold: boolean;
  fileInfo: { items: FileInfo[] };
  deleteFileSortOrders: number[];
};

export type PostPostRequest = {
  title: string;
  lifeCategoryId: number;
  content: string;
  useComment?: boolean | null;
  storeId?: string | null;
  buildingId?: string | null;
  status?: string | null;
  isTop?: boolean | null;
  imageFiles?: File[] | null;
  groupId?: string | null;
  isDisplayHotCold?: boolean | null;
  groupList?: string | null;
};

export type PostCategory = "sandi" | "menu" | "talk" | "building" | "multi";

export const postCategoryMap: Record<number, PostCategory> = {
  1: "talk",
  2: "sandi",
  3: "building",
  31: "menu",
};

export const postCategoryNameMap: Record<PostCategory, string> = {
  talk: "샌디톡",
  sandi: "샌디안내",
  building: "건물",
  menu: "메뉴",
  multi: "다중 건물"
};

export type PostContentType = "POST" | "COMMENT";

export const postContentValue: Record<PostContentType, string> = {
  POST: "POST",
  COMMENT: "COMMENT",
};

export enum PostFormType {
  Default = "default",
}

export const radioBoxPostFormType: ComboBoxType[] = [
  { value: "default", label: "기본글" },
];

export const radioBoxPostVisible: ComboBoxType[] = [
  { value: "PUBLIC", label: "노출" },
  { value: "PRIVATE", label: "노출 안함" },
];

export const radioBoxPostUseComment: ComboBoxType[] = [
  { value: "true", label: "허용" },
  { value: "false", label: "허용하지 않음" },
];

export const radioBoxPostIsTop: ComboBoxType[] = [
  { value: "true", label: "고정" },
  { value: "false", label: "고정하지 않음" },
];

export type Report = {
  lifeReportId: number;
  lifeCommentId: number;
  reportCategory: string;
  createdBy: number;
  createdNickname: string;
  createdName: string;
  createdEmail: string;
  category: string;
  type: string;
  reportedId: number;
  reportedTitle: string;
  reportedBy: number;
  reportedNickname: string;
  reportedAt: string;
  reportedEmail: string;
  postCategoryId: string;
  postCategoryName: string;
  categoryNameL: string;
  categoryNameM: string;
  categoryNameS: string;
  hidden: boolean;
  isHidden: boolean;
  createdAt: string;
  tagFilter: string;
  buildingId: string;
  companyId: string;
};
