import { HttpStatusCode } from "axios";
import { useContext, useState } from "react";

import { CategoryData } from "../../../../types/category";
import { ComboBoxType } from "../../../../types/search";
import { Store, StoreCreateDto } from "../../../../types/store";
import {
  USAGE_OPTIONS,
  DEFAULT_SELECT_ALL_VALUE,
} from "../../../../types/comboBoxOption";
import {
  BuildingGroup,
  TagSelectedItem,
  buildingGroupCategoryCodeValue,
} from "../../../../types/building";

import {
  validationFunctions,
  validationResultMessage,
} from "../../../../libs/validations";

import { PopupContext } from "../../../../contexts/Popup";

import { getBuildingFloor } from "../../../../services/buildingService";
import { postStore } from "../../../../services/storeService";

import DefaultButton from "../../../DefaultButton";
import FormHeader from "../../../Form/Header";
import FormRow from "../../../Form/Row";
import FormRowLabel from "../../../Form/RowLabel";
import DefaultInput from "../../../Input/DefaultInput";
import CategorySelect from "../../../SelectBox/CategorySelect";
import DefaultSelect from "../../../SelectBox/DefaultSelect";
import Spinner from "../../../Spinner";
import TagSingleFilter from "../../../TagFilter/TagSingleFilter";
import SingleImageUploader from "../../../FileUploader/ImageFileUploader/SingleImageFileUploader";
import RadioButtonGroup from "../../../Input/RadioButtonGroup";
import { useModal } from "../../../../contexts/Modal";
import DefaultTextArea from "../../../DefaultTextArea";
import PermissionWrapper from "../../../PermissionWrapper";

type Props = { categoryData: CategoryData[] };

const DEFAULT_CATEGORY_OPTION_ID = 1; // 기본 선택 옵션: 식당/아케이드
export const RENTAL_CATEGORY_ID = 9;
export const MAX_LENGTH_STORE_NAME = 50;
export const MAX_LENGTH_STORE_TEL = 14;

export default function StoreCreateForm({ categoryData }: Props) {
  const { closePopup, refreshAndClosePopup } = useContext(PopupContext);
  const { showAlert, showConfirm, handleError } = useModal();
  const [store, setStore] = useState<Partial<Store>>({ isActive: true });
  const [selectedCategory, setSelectedCategory] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [buildingFloor, setBuildingFloor] = useState<ComboBoxType[]>([]);
  const [selectedTag, setSelectedTag] = useState<TagSelectedItem>();

  const handleChange = (data: Partial<Store>) => {
    setStore((prev) => ({ ...prev, ...data }));
  };

  const handlePost = () => {
    const errorMessage = validationResultMessage([
      validationFunctions.required(store?.buildingId, "기준 건물"),
      validationFunctions.required(
        store?.buildingFloorId !== DEFAULT_SELECT_ALL_VALUE
          ? store?.buildingFloorId
          : null,
        "건물 층"
      ),
      validationFunctions.boolean(store?.isActive, "사용 여부"),
      validationFunctions.required(store?.storeCategoryId, "상점 유형"),
      validationFunctions.required(store?.name, "상점명"),
    ]);

    if (errorMessage) {
      showAlert(errorMessage);
    } else {
      showConfirm("상점을 등록 하시겠습니까?", postData);
    }
  };

  const createFormData = (): FormData => {
    const formData = new FormData();

    if (store.uploadFiles) {
      formData.append("uploadFiles", store.uploadFiles);
    }

    const data: Partial<StoreCreateDto> = {
      buildingId: store.buildingId,
      buildingFloorId: store.buildingFloorId,
      location: store.location,
      storeCategoryId: store.storeCategoryId,
      name: store.name,
      tel: store.tel,
      subTel: store.subTel,
      ...(selectedCategory.includes(RENTAL_CATEGORY_ID) && {
        precaution: store.precaution,
      }),
      isActive: store.isActive,
    };

    formData.append("storeCreateDto", JSON.stringify(data));

    return formData;
  };

  const handleCategoryChange = (value: number[]) => {
    setSelectedCategory(value);
    handleChange({ storeCategoryId: value[value.length - 1] });
  };

  const postData = async () => {
    try {
      const response = await postStore(createFormData());
      if (response.status === HttpStatusCode.Ok) {
        showAlert("상점 등록이 완료됐습니다.");
        refreshAndClosePopup();
      }
    } catch (err: any) {
      handleError(err, "등록");
    }
  };

  const fetchBuildingFloor = async (buildingId: string) => {
    if (!buildingId) {
      return;
    }

    try {
      setIsLoading(true);
      const response = await getBuildingFloor(buildingId);

      if (response.data) {
        const arr = response.data.map((item) => ({
          value: item.buildingFloorId,
          label: item.floorName,
        }));

        setBuildingFloor(arr);

        if (arr.length > 0) {
          handleChange({ buildingFloorId: arr[0].value });
        }
      }
    } catch (err: any) {
      handleError(err, "층 정보 조회");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col gap-5">
      {isLoading && <Spinner />}
      <div className="flex flex-col max-h-[700px] overflow-y-auto">
        <FormHeader title="기준 정보" />
        <FormRow>
          <FormRowLabel title="기준 건물" isRequired>
            <TagSingleFilter
              selectType="building"
              selectedItem={selectedTag}
              onChangeBuilding={(item: BuildingGroup) => {
                if (item.buildingId) {
                  setSelectedTag(item);
                  handleChange({ buildingId: item.buildingId });
                  fetchBuildingFloor(item.buildingId);
                } else {
                  console.error(
                    "buildingId is not exist while change building."
                  );
                }
              }}
              categoryCode={buildingGroupCategoryCodeValue.POST}
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="상세 위치" isRequired>
            <div className="flex gap-2">
              <DefaultSelect
                value={store.buildingFloorId ?? DEFAULT_SELECT_ALL_VALUE}
                optionList={buildingFloor}
                onChange={(value: string) =>
                  handleChange({ buildingFloorId: value })
                }
                placeholder="층 선택"
              />
              <DefaultInput
                value={store?.location}
                onChange={(value: string) => handleChange({ location: value })}
                placeholder="상세 위치를 입력해주세요"
              />
            </div>
          </FormRowLabel>
        </FormRow>

        <FormHeader title="서비스 정보" />
        <FormRow>
          <FormRowLabel title="사용 여부" isRequired>
            <RadioButtonGroup
              options={USAGE_OPTIONS}
              value={store.isActive?.toString() ?? ""}
              onChange={(value: string) =>
                handleChange({ isActive: value === "true" })
              }
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="상점 유형" isRequired>
            <CategorySelect
              value={selectedCategory}
              onChange={handleCategoryChange}
              categoryData={categoryData}
              defaultOptions={[DEFAULT_CATEGORY_OPTION_ID]}
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="상점명" isRequired>
            <DefaultInput
              value={store?.name}
              onChange={(value: string) => handleChange({ name: value })}
              minWidth="w-full"
              maxLength={MAX_LENGTH_STORE_NAME}
              placeholder="상점명을 입력해주세요"
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="대표 전화">
            <DefaultInput
              type="tel"
              value={store?.tel}
              onChange={(value: string) => handleChange({ tel: value })}
              maxLength={MAX_LENGTH_STORE_TEL}
              hideMaxLength
              placeholder="대표 전화번호를 입력해주세요"
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="추가 연락처">
            <DefaultInput
              type="tel"
              value={store?.subTel}
              onChange={(value: string) => handleChange({ subTel: value })}
              maxLength={MAX_LENGTH_STORE_TEL}
              hideMaxLength
              placeholder="추가 연락처를 입력해주세요"
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="대표 이미지">
            <SingleImageUploader
              image={store.uploadFiles}
              onChange={(file: File) => handleChange({ uploadFiles: file })}
            />
          </FormRowLabel>
        </FormRow>
        {selectedCategory.includes(RENTAL_CATEGORY_ID) && (
          <FormRow>
            <FormRowLabel title="대여하기">
              <DefaultTextArea
                label="기타 안내"
                value={store.precaution}
                onChange={(value: string) =>
                  handleChange({ precaution: value })
                }
                rows={5}
                maxLength={200}
                placeholder="기타 안내 내용 및 휴무일 정보를 입력해주세요"
              />
            </FormRowLabel>
          </FormRow>
        )}
      </div>

      <div className="flex justify-center gap-2">
        <DefaultButton onClick={closePopup}>닫기</DefaultButton>
        <PermissionWrapper>
          <DefaultButton color="primary" onClick={handlePost}>
            등록
          </DefaultButton>
        </PermissionWrapper>
      </div>
    </div>
  );
}
