import DefaultButton from "@/components/DefaultButton";
import { PopupContext } from "@/contexts/Popup";
import { useContext } from "react";
import SituationListCreateForm from "../CreateForm";

type Props = {};

export default function SituationListTopRight(props: Props) {
  const { openPopup } = useContext(PopupContext);
  return (
    <>
      <DefaultButton
        size="tableTop"
        color="default"
        onClick={() =>
          openPopup({
            header: "비상대피 상황 등록",
            content: <SituationListCreateForm />,
          })
        }
      >
        상황 등록
      </DefaultButton>
    </>
  );
}
