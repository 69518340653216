import clsx from "clsx";
import { DASH } from "../../../types/search";
import MaskingData from "../../MaskingData";
import { MASKING_PROPERTY_TYPE, MaskingType } from "../../../types/masking";

type Props = {
  label?: string;
  text?: string;
  color?: TextColor;
  size?: TextSize;
  className?: string;
  masking?: MaskingType;
  targetId?: number;
  property?: MASKING_PROPERTY_TYPE;
  prefix?: string;
  unmaskButtonTestId?: string;
  testId?: string;
};

type TextColor = "default" | "gray" | "blue" | "summary" | "magenta";

const textColor: { [key: string]: string } = {
  default: "text-black",
  gray: "text-neutral-30 font-medium",
  blue: "text-brand-text-blue font-medium",
  summary: "text-brand-summary-gray font-medium",
  magenta: "text-brand-primary-magenta font-medium",
};

type TextSize = "default";

const textSize: { [key: string]: string } = {
  default: "text-sm",
};

export default function DefaultLabel({
  masking,
  property,
  targetId,
  prefix,
  text,
  label = "",
  color = "default",
  size = "default",
  className = "",
  unmaskButtonTestId,
  testId,
}: Props) {
  const getClassName = clsx(
    "w-full min-w-label",
    textColor[color],
    textSize[size],
    className
  );

  return (
    <div className="flex gap-1">
      {label && <span className="min-w-label text-sm">{label}</span>}
      {masking && text && text !== "" ? (
        <MaskingData
          defaultText={text ?? DASH}
          className={getClassName}
          type={masking}
          targetId={targetId}
          property={property}
          prefix={prefix}
          unmaskButtonTestId={unmaskButtonTestId}
        />
      ) : (
        <label className={getClassName} data-testid={`${testId}-default-label`}>
          {prefix ?? ""}
          {text && text !== "" ? text : DASH}
        </label>
      )}
    </div>
  );
}
