import { useCallback, useContext, useEffect, useState } from "react";
import { HttpStatusCode } from "axios";
import clsx from "clsx";

import { POST_HOT_COLD_ALIAS } from "../../../../constants/postConstants";

import {
  Post,
  PostCategory,
  PostStatus,
  postCategoryMap,
  postCategoryNameMap,
  radioBoxPostIsTop,
  radioBoxPostUseComment,
  radioBoxPostVisible,
} from "../../../../types/post";
import {
  CategoryData,
  CategoryReturnField,
  CategoryStoreData,
} from "../../../../types/category";
import {
  BOOLEAN_TYPES,
  DEFAULT_SELECT_ALL_VALUE,
  VISIBLE_OPTIONS,
} from "../../../../types/comboBoxOption";
import { ComboBoxType, DASH } from "../../../../types/search";
import { FileInfo, FileScale } from "../../../../types/file";
import { ImageType } from "../../../../types/incommodity";
import { ServiceCategoryType } from "../../../../types/service";
import { BuildingGroup } from "../../../../types/building";

import {
  validationFunctions,
  validationResultMessage,
} from "../../../../libs/validations";
import {
  findCategoryIdByAlias,
  findCategoryValueById,
  removeCategoryItemById,
  transformCategoryData,
} from "../../../../libs/category";

import { generateAxiosListResponse } from "../../../../libs/request";

import { toComboBoxType } from "../../../../utils/comboBoxUtils";
import { getImageInfoByScale } from "../../../../utils/fileUtils";

import { useModal } from "../../../../contexts/Modal";
import { PopupContext } from "../../../../contexts/Popup";

import {
  getCategoryList,
  getPost,
  putPost,
} from "../../../../services/pinelifeService";
import { getBuildingGroupSearch } from "../../../../services/buildingService";
import { getServiceGroupMemberList } from "../../../../services/ServiceGroupService";

import DefaultButton from "../../../DefaultButton";
import DefaultTextArea from "../../../DefaultTextArea";
import FormHeader from "../../../Form/Header";
import FormRow from "../../../Form/Row";
import FormRowLabel from "../../../Form/RowLabel";
import DefaultInput from "../../../Input/DefaultInput";
import RadioButtonGroup from "../../../Input/RadioButtonGroup";
import CategorySelect from "../../../SelectBox/CategorySelect";
import Spinner from "../../../Spinner";
import MultiImageUploader from "../../../FileUploader/ImageFileUploader/MultiImageFileUploader";
import CommentList from "../../../Comment/CommentList";
import DefaultLabel from "../../../Input/DefaultLabel";
import DefaultSelect from "../../../SelectBox/DefaultSelect";
import TagSelector from "../../../TagSelector";
import { TableColumnType } from "../../../Table";
import { TagData } from "../../../TagList";
import { SearchCondition } from "../../../ListPage";
import { SearchTagData } from "../../../TagFilter";
import { CATEGORY_ID_MENU } from "../ListTopRight";
import PermissionWrapper from "../../../PermissionWrapper";

import UseNoIcon from "../../../../svgs/icons/UseYn/UseNoIcon";
import UseYesIcon from "../../../../svgs/icons/UseYn/UseYesIcon";

type Props = { postId: number; categoryData?: CategoryData[] };

// TODO: 게시판 카테고리 alias로 검색하도록 개선 필요
const MENU_CATEGORY_ID = 31;

export default function PostDetailView({ postId, categoryData = [] }: Props) {
  const { showAlert, showConfirm, handleError } = useModal();
  const { closePopup, refreshAndClosePopup } = useContext(PopupContext);
  const [post, setPost] = useState<Partial<Post>>({
    useComment: true,
    isTop: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<number[]>([]);
  const [categoryType, setCategoryType] = useState<number>(0);
  const [selectedService, setSelectedService] = useState("");
  const [serviceOptions, setServiceOptions] = useState<ComboBoxType[]>([]);
  const [storeCategory, setStoreCategory] = useState<ComboBoxType[]>([]);
  const [buildingId, setBuildingId] = useState("");
  const [tags, setTags] = useState<SearchTagData>({});

  const isHotColdCategory = selectedCategory.includes(
    findCategoryIdByAlias(POST_HOT_COLD_ALIAS, categoryData) ?? -1
  );

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await getPost(postId);
      if (response.data) {
        setPost(response.data);
        setSelectedCategory([response.data.lifeCategoryId]);
        const categoryType =
          response.data.lifeCategoryId === MENU_CATEGORY_ID
            ? MENU_CATEGORY_ID
            : Math.floor(Number(response.data.lifeCategoryId / 10));
        setCategoryType(categoryType);

        const category: PostCategory =
          response.data.lifeCategoryId === MENU_CATEGORY_ID
            ? "menu"
            : postCategoryMap[categoryType];

        if (category === "building" || category === "menu") {
          if (response.data.buildingInfo.length > 1) {
            const defaultTags = response.data.buildingInfo.map(
              (info) =>
                ({
                  id: info.buildingId,
                  name: info.buildingName,
                  data: info,
                } as TagData)
            );
            setTags({ data: defaultTags, type: "building" });
          }

          setSelectedService(response.data.groupId);

          const id = response.data.buildingId?.split(",")?.[0];
          setBuildingId(id);

          const categoryCode: ServiceCategoryType =
            category === "building" ? "BUILDING_BBS" : "CAFETERIA";
          const res = await getServiceGroupMemberList(id, categoryCode);
          if (res.status === HttpStatusCode.Ok) {
            setServiceOptions(toComboBoxType(res.data, "groupId", "groupName"));
          }

          fetchCategory(id);
        }
      } else {
        throw new Error("정보를 불러오지 못했습니다.");
      }
    } catch (err: any) {
      handleError(err, "조회");
      closePopup();
    } finally {
      setIsLoading(false);
    }
  };

  const fetchCategory = async (buildingId: string) => {
    try {
      setIsLoading(true);

      const response = await getCategoryList(buildingId);
      if (response.status) {
        const transCategory = transformCategoryData(
          response.data,
          "lifeCategoryId",
          "alias",
          "name",
          "lifeCategoryList",
          "storeCategoryId",
          "storeList"
        );

        const findCategory = findCategoryValueById(
          CATEGORY_ID_MENU,
          transCategory,
          CategoryReturnField.STORE_LIST
        ) as CategoryStoreData[];

        setStoreCategory(toComboBoxType(findCategory, "storeId", "name"));
      } else {
        throw new Error("Failed to get category");
      }
    } catch (err: any) {
      handleError(err, "카테고리 조회");
    } finally {
      setIsLoading(false);
    }
  };

  const handleCategory = (value: number[]) => {
    setSelectedCategory(value);
  };

  const handleChange = (data: Partial<Post>) => {
    setPost((prev) => ({ ...prev, ...data }));
  };

  const handleUpdate = () => {
    const validationFunctionList = [
      validationFunctions.required(post.title, "제목"),
      validationFunctions.required(post.content, "내용"),
      validationFunctions.required(selectedCategory, "게시판 분류"),
    ];
    if (isHotColdCategory) {
      validationFunctionList.push(
        validationFunctions.radioChecked(
          post.isDisplayHotCold,
          "더워요/추워요 화면노출"
        )
      );
    }
    const errorMessage = validationResultMessage(validationFunctionList);

    if (errorMessage) {
      showAlert(errorMessage);
    } else {
      showConfirm("게시글을 수정하시겠습니까?", updateData);
    }
  };

  const updateData = async () => {
    const filteredIds: string[] =
      tags.data?.filter((tag) => tag.id !== buildingId).map((tag) => tag.id) ??
      [];

    filteredIds.unshift(buildingId);
    const buildingIds = filteredIds.join(",");

    const data: Partial<Post> = {
      title: post.title,
      lifeCategoryId: selectedCategory[0],
      content: post.content,
      useComment: post.useComment,
      status: post.status,
      buildingId: buildingIds,
      isTop: post.isTop,
      groupId:
        selectedService !== DEFAULT_SELECT_ALL_VALUE
          ? selectedService
          : undefined,
      deleteFileSortOrders: post.deleteFileSortOrders ?? [],
      ...(post.imageFiles ? { imageFiles: post.imageFiles } : {}),
      ...(post.storeId ? { storeId: post.storeId } : {}),
      ...(isHotColdCategory && { isDisplayHotCold: post.isDisplayHotCold }),
    };

    try {
      const response = await putPost(postId, data);
      if (response.status === HttpStatusCode.Ok) {
        showAlert(`수정 되었습니다.`);
        refreshAndClosePopup();
      }
    } catch (err: any) {
      handleError(err, "수정");
    }
  };

  const handleStatusChange = (value: string) => {
    setPost((prev) => ({ ...prev, status: value as PostStatus }));
  };

  const handleImageDelete = (image: FileInfo) => {
    setPost((prev) => {
      if (prev.fileInfo && prev.fileInfo.items) {
        return {
          ...prev,
          fileInfo: {
            ...prev.fileInfo,
            items: prev.fileInfo.items.filter(
              (item) => item.fileId !== image.fileId
            ),
          },
          deleteFileSortOrders: [
            ...(prev.deleteFileSortOrders ?? []),
            image.sortOrder,
          ],
        };
      }
      return prev;
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleServiceChange = (value: string) => {
    setSelectedService(value);
    setTags({});
  };

  const buildingColumns: TableColumnType<BuildingGroup>[] = [
    { header: "건물 ID", name: "buildingId" },
    { header: "건물명", name: "buildingName" },
  ];

  const compareBuilding = (item: BuildingGroup) => (o: BuildingGroup) => {
    return item.buildingId === o.buildingId;
  };

  const getTagBuilding = (item: BuildingGroup) => {
    return {
      id: item.buildingId,
      name: item.buildingName,
      data: item,
    } as TagData;
  };

  const getBuildingListByGroupId = useCallback(
    async (params: SearchCondition<BuildingGroup>) => {
      if (!selectedService) {
        return generateAxiosListResponse(params, []);
      }

      const categoryCode: ServiceCategoryType =
        categoryType === MENU_CATEGORY_ID ? "CAFETERIA" : "BUILDING_BBS";

      return getBuildingGroupSearch({
        ...params,
        groupId: selectedService,
        categoryCode,
        isExcludedOrigin: true,
      });
    },
    [selectedService]
  );

  const renderCategory = () => {
    if (postCategoryMap[categoryType] === "menu") {
      return (
        <DefaultSelect
          optionList={storeCategory}
          value={post.storeId}
          onChange={(value: string) => handleChange({ storeId: value })}
          placeholder="식당 선택"
        />
      );
    } else {
      const data =
        categoryData?.find((item) => item.id === categoryType)?.subList ?? [];
      const filteredData =
        postCategoryMap[categoryType] === "building"
          ? removeCategoryItemById(data, CATEGORY_ID_MENU)
          : data;
      return (
        <CategorySelect
          value={selectedCategory}
          onChange={handleCategory}
          allOptionLabels={["분류 선택"]}
          categoryData={filteredData}
        />
      );
    }
  };

  const renderCategoryName = () => {
    const isBuildingOrMenu = ["building", "menu"].includes(
      postCategoryMap[categoryType]
    );
    const title = isBuildingOrMenu ? "건물" : "게시판";
    const text = isBuildingOrMenu
      ? post.buildingInfo?.[0]?.buildingName
      : postCategoryNameMap[postCategoryMap[categoryType]];

    return (
      <FormRow>
        <FormRowLabel title={title}>
          <DefaultLabel text={text} />
        </FormRowLabel>
      </FormRow>
    );
  };

  const isBuildingOrMenu =
    postCategoryMap[categoryType] === "building" ||
    postCategoryMap[categoryType] === "menu";

  return (
    <div
      className={clsx(
        "flex flex-col gap-5",
        { "w-[1500px]": isBuildingOrMenu },
        { "w-[1100px]": !isBuildingOrMenu }
      )}
    >
      {isLoading && <Spinner />}
      <div className="flex flex-col max-h-[700px] overflow-y-auto">
        <FormHeader title="글 정보" />
        <FormRow>
          <FormRowLabel title="ID">
            <DefaultLabel text={post.lifePostId?.toString() ?? DASH} />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="작성일시">
            <DefaultLabel text={post.createdAt ?? DASH} />
          </FormRowLabel>
          <FormRowLabel title="최종 수정일시">
            <DefaultLabel text={post.modifiedAt ?? DASH} />
          </FormRowLabel>
        </FormRow>

        <FormHeader title="게시글" />
        <FormRow>
          <FormRowLabel title="노출 여부">
            <RadioButtonGroup
              value={post.status ?? ""}
              onChange={handleStatusChange}
              options={radioBoxPostVisible}
            />
          </FormRowLabel>
          <FormRowLabel title="삭제여부">
            {post.isDeleted ? <UseYesIcon /> : <UseNoIcon />}
          </FormRowLabel>
        </FormRow>
        {renderCategoryName()}
        <FormRow>
          <FormRowLabel title="게시판 분류" isRequired={true}>
            {renderCategory()}
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="제목" isRequired={true}>
            <div className="w-full">
              <DefaultInput
                value={post.title}
                placeholder="제목을 입력해주세요"
                maxLength={200}
                minWidth="w-full"
                onChange={(value: string) => handleChange({ title: value })}
              />
            </div>
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="내용" isRequired={true}>
            <DefaultTextArea
              value={post.content}
              onChange={(value: string) => handleChange({ content: value })}
              rows={10}
              cols={70}
              maxLength={2000}
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="첨부 파일">
            <MultiImageUploader
              maxImages={10}
              images={post.imageFiles ?? []}
              onChange={(data: File[]) => handleChange({ imageFiles: data })}
              rightLabel="* 개당 10mb 이하, 최대 10개 등록 가능"
              defaultImages={getImageInfoByScale(
                post.fileInfo?.items ?? [],
                FileScale.MEDIUM
              )}
              onDelete={(image: ImageType | FileInfo) =>
                handleImageDelete(image as FileInfo)
              }
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="댓글 허용">
            <RadioButtonGroup
              value={post.useComment?.toString() ?? ""}
              options={radioBoxPostUseComment}
              onChange={(value: string) =>
                handleChange({ useComment: value === "true" ? true : false })
              }
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="상단 고정 여부">
            <div className="flex flex-col gap-1.5">
              <RadioButtonGroup
                value={post.isTop?.toString() ?? ""}
                options={radioBoxPostIsTop}
                onChange={(value: string) =>
                  handleChange({ isTop: value === "true" ? true : false })
                }
              />
              <span className="text-sm">
                * 같은 분류에 고정된 게시글이 있을 경우 다른 게시글은 고정이
                취소 됩니다
              </span>
            </div>
          </FormRowLabel>
        </FormRow>
        {isHotColdCategory && (
          <FormRow>
            <FormRowLabel title="더워요/추워요 화면노출">
              <RadioButtonGroup
                value={post.isDisplayHotCold?.toString() ?? ""}
                options={VISIBLE_OPTIONS}
                onChange={(value: string) =>
                  handleChange({
                    isDisplayHotCold: value === BOOLEAN_TYPES.TRUE,
                  })
                }
              />
            </FormRowLabel>
          </FormRow>
        )}

        {(postCategoryMap[categoryType] === "building" ||
          postCategoryMap[categoryType] === "menu") && (
          <>
            <FormHeader title="게시글 추가 노출" />
            <FormRow>
              <FormRowLabel title="서비스 그룹">
                <div className="flex flex-col gap-2">
                  <DefaultSelect
                    optionList={serviceOptions}
                    onChange={handleServiceChange}
                    value={selectedService}
                    placeholder="서비스 그룹 선택"
                  />
                  <TagSelector
                    getDataApi={getBuildingListByGroupId}
                    componentList={[]}
                    columnInfo={buildingColumns}
                    title="건물 목록"
                    tagType="building"
                    tags={tags}
                    setTags={setTags}
                    compareItem={compareBuilding}
                    getTagItem={getTagBuilding}
                    inline
                    isHorizontal
                  />
                </div>
              </FormRowLabel>
            </FormRow>
          </>
        )}

        <FormHeader title="댓글/답글" />
        <CommentList lifePostId={postId} />
      </div>

      <div className="flex gap-2 justify-center">
        <DefaultButton onClick={closePopup}>닫기</DefaultButton>
        <PermissionWrapper>
          <DefaultButton color="primary" onClick={handleUpdate}>
            수정
          </DefaultButton>
        </PermissionWrapper>
      </div>
    </div>
  );
}
