import { useContext } from "react";
import { PopupContext } from "../../../../../contexts/Popup";
import DefaultButton from "../../../../DefaultButton";
import AppVersionCreateForm from "../CreateForm";

type Props = {};

export default function AppVersionListTopRight(props: Props) {
  const { openPopup } = useContext(PopupContext);

  return (
    <DefaultButton
      size="tableTop"
      color="default"
      onClick={() =>
        openPopup({
          header: "버전 등록",
          content: <AppVersionCreateForm />,
        })
      }
    >
      버전 등록
    </DefaultButton>
  );
}
