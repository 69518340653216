import { AxiosResponse } from "axios";

import { User, UserLogin } from "../types/user";

import request, { authRequest } from "../libs/request";
import { removeLocalStorage } from "../libs/localStorage";

const USER_API_PREFIX = "/v2/admin/member";
const CLIENT_ID = "PC";

const MEMBER_API_PREFIX = "/v2/admin/member";

export type TokenOnlyData = {
  accessToken: string;
  refreshToken: string;
};

export type TokenData = TokenOnlyData & {
  grantType: string;
  auth: string;
};

export type UserMenu = {
  programId: string;
  url: string;
  menuName: string;
  programType: string;
  scope: string;
  menuList: UserMenu[];
};

export const getToken = (user: UserLogin): Promise<AxiosResponse<TokenData>> =>
  request.post("/oauth2/admin/token", {
    username: user.email,
    password: user.password,
    clientId: CLIENT_ID,
  });

export const getTokenByRefresh = (refreshToken: string) =>
  authRequest.post(`/oauth2/admin/reissue`, null, {
    headers: {
      Authorization: "Bearer " + refreshToken,
    },
  });

export const postSignOut = () =>
  request.post(`/oauth2/sign-out`, { params: {} });

export const logoutUser = () => {
  postSignOut();
  removeLocalStorage("user");
  removeLocalStorage("jwtToken");
};

export const getUser = () => request.get<User>(USER_API_PREFIX + `/info`);

export const getMenu = () => request.get<UserMenu[]>(USER_API_PREFIX + `/menu`);

export const getMemberExistence = (name: string, mobileNumber: string) =>
  request.get(MEMBER_API_PREFIX + "/existence", {
    params: { name, mobileNumber },
  });

export type MaintenanceResponse = {
  isMaintenance: boolean;
  message?: string;
};

export const getMaintenanceFlag = () =>
  request.get<MaintenanceResponse>("/v2/app/maintenance");
