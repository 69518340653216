import { termsGradeLabels } from "./etc";
import {
  GOODS_RENTAL_STATUS_TYPES,
  goodsHistoryLabels,
  goodsRentalItemLabels,
  goodsRentalStatusLabels,
  goodsRequestItemLabels,
  goodsServiceLabels,
} from "./goods";
import { ComboBoxType, DataRangeButton } from "./search";
import { RESERVATION_SPACE_TYPES, reservationSpaceType } from "./space";

const createBooleanOptions = (
  trueLabel: string,
  falseLabel: string,
  reverseOrder: boolean = false
): ComboBoxType[] => {
  const options = [
    { value: BOOLEAN_TYPES.TRUE, label: trueLabel },
    { value: BOOLEAN_TYPES.FALSE, label: falseLabel },
  ];
  return reverseOrder ? options.reverse() : options;
};

const createComboBoxOptions = <T extends Record<string, string>>(
  types: T,
  labels: Record<keyof T, string>
): ComboBoxType[] => {
  return Object.keys(types).map((key) => ({
    value: types[key as keyof T],
    label: labels[key as keyof T],
  }));
};

export const DEFAULT_SELECT_ALL_VALUE = "defaultSelectAllValue";
export const DEFAULT_SELECT_CUSTOM = "custom";
// 카테고리 select처럼 상하위 관계가 있는 경우 사용
export const SUB_DEFAULT_SELECT_ALL_VALUE = "subDefaultSelectAllValue";

export const BOOLEAN_TYPES = {
  TRUE: "true",
  FALSE: "false",
};
export const BOOLEAN_YN_TYPES = {
  TRUE: "Y",
  FALSE: "N",
};

export const GENDER_TYPES = {
  FEMALE: "F",
  MALE: "M",
};

export const CHANGE_STATUS = {
  DEFAULT: "default",
  CHANGE: "change",
};

export const AUTH_STATUS = {
  NOT_NEEDED: "not_needed",
  NEEDED: "needed",
};

export const CATEGORY_LEVEL_TYPES = {
  ALL_MAIN: "대분류 전체",
  ALL_SUB: "중분류 전체",
  ALL_MINOR: "소분류 전체",
};

export const CATEGORY_LEVEL_INDEX = {
  MAIN_CATEGORY: 0,
  SUB_CATEGORY: 1,
  MINOR_CATEGORY: 2,
};

export const MEMBER_ROLE_TYPES = {
  ROLE_USER: "ROLE_USER",
  ROLE_MANAGER: "ROLE_MANAGER",
  ROLE_ADMIN: "ROLE_ADMIN",
};

export const OS_TYPES = {
  ANDROID: "android",
  IOS: "ios",
};

export const OS_OPTIONS: ComboBoxType[] = [
  { value: OS_TYPES.ANDROID, label: "안드로이드" },
  { value: OS_TYPES.IOS, label: "IOS" },
];

export const usageYnLabels = {
  TRUE: "사용",
  FALSE: "미사용",
};

export const USAGE_YN_OPTIONS: ComboBoxType[] = [
  { value: BOOLEAN_YN_TYPES.TRUE, label: usageYnLabels.TRUE },
  { value: BOOLEAN_YN_TYPES.FALSE, label: usageYnLabels.FALSE },
];

export const GOODS_RENTAL_TYPES = {
  UMBRELLA: "UMBRELLA",
  TOOLS: "TOOLS",
  BOOK: "BOOK",
  ITEM: "ITEM",
};

export const GOODS_RENTAL_OPTIONS: ComboBoxType[] = [
  { value: GOODS_RENTAL_TYPES.UMBRELLA, label: goodsRentalItemLabels.UMBRELLA },
  { value: GOODS_RENTAL_TYPES.TOOLS, label: goodsRentalItemLabels.TOOLS },
  { value: GOODS_RENTAL_TYPES.BOOK, label: goodsRentalItemLabels.BOOK },
  { value: GOODS_RENTAL_TYPES.ITEM, label: goodsRentalItemLabels.ITEM },
];

export const GOODS_REQUEST_TYPES = {
  REQUEST: "REQUEST",
  REFILL: "REFILL",
};

export const GOODS_REQUEST_OPTIONS: ComboBoxType[] = [
  { value: GOODS_REQUEST_TYPES.REQUEST, label: goodsRequestItemLabels.REQUEST },
  { value: GOODS_REQUEST_TYPES.REFILL, label: goodsRequestItemLabels.REFILL },
];

export const GOODS_RENTAL_REQUEST_OPTIONS: ComboBoxType[] = [
  ...GOODS_RENTAL_OPTIONS,
  ...GOODS_REQUEST_OPTIONS,
];

export const GOODS_HISTORY_TYPES = {
  TOTAL_MODIFIED: "TOTAL_MODIFIED",
  LOST_DAMAGED: "LOST_DAMAGED",
  RESTORED: "RESTORED",
  MOVED: "MOVED",
};

export const GOODS_RENTAL_STATUS_OPTIONS: ComboBoxType[] =
  createComboBoxOptions(GOODS_RENTAL_STATUS_TYPES, goodsRentalStatusLabels);

export const GOODS_HISTORY_OPTIONS: ComboBoxType[] = [
  {
    value: GOODS_HISTORY_TYPES.TOTAL_MODIFIED,
    label: goodsHistoryLabels.TOTAL_MODIFIED,
  },
  {
    value: GOODS_HISTORY_TYPES.LOST_DAMAGED,
    label: goodsHistoryLabels.LOST_DAMAGED,
  },
  { value: GOODS_HISTORY_TYPES.RESTORED, label: goodsHistoryLabels.RESTORED },
  { value: GOODS_HISTORY_TYPES.MOVED, label: goodsHistoryLabels.MOVED },
];

export const GOODS_SERVICE_TYPES = {
  RENTAL: "RENTAL",
  REQUEST: "REQUEST",
};

export const GOODS_SERVICE_OPTIONS: ComboBoxType[] = [
  { value: GOODS_SERVICE_TYPES.RENTAL, label: goodsServiceLabels.RENTAL },
  { value: GOODS_SERVICE_TYPES.REQUEST, label: goodsServiceLabels.REQUEST },
];

export const TEMPLATE_SERVICE_TYPES = {
  BUILDING: "BUILDING",
  COMPANY: "COMPANY",
} as const;

export const TEMPLATE_SERVICE_OPTIONS: ComboBoxType[] = [
  { value: TEMPLATE_SERVICE_TYPES.BUILDING, label: "건물" },
  { value: TEMPLATE_SERVICE_TYPES.COMPANY, label: "회사" },
];

export const TEMPLATE_ACTION_TYPES = {
  INFO: "INFO",
  LINK: "LINK",
};

export const TEMPLATE_ACTION_OPTIONS: ComboBoxType[] = [
  { value: TEMPLATE_ACTION_TYPES.INFO, label: "정보 안내" },
  { value: TEMPLATE_ACTION_TYPES.LINK, label: "즉시 연결" },
];

export const TERMS_GRADE_TYPES = {
  MANDATORY: "MANDATORY",
  SELECTIVE: "SELECTIVE",
} as const;

export const TERMS_GRADE_OPTIONS: ComboBoxType[] = [
  { value: TERMS_GRADE_TYPES.MANDATORY, label: termsGradeLabels.MANDATORY },
  { value: TERMS_GRADE_TYPES.SELECTIVE, label: termsGradeLabels.SELECTIVE },
];

export const CATEGORY_LEVEL_LABELS: string[] = [
  CATEGORY_LEVEL_TYPES.ALL_MAIN,
  CATEGORY_LEVEL_TYPES.ALL_SUB,
  CATEGORY_LEVEL_TYPES.ALL_MINOR,
];

export const MEMBER_ROLE_OPTIONS: ComboBoxType[] = [
  { value: MEMBER_ROLE_TYPES.ROLE_USER, label: "일반유저" },
  { value: MEMBER_ROLE_TYPES.ROLE_MANAGER, label: "샌디매니저" },
  { value: MEMBER_ROLE_TYPES.ROLE_ADMIN, label: "샌디운영자" },
];

export const CHANGE_OPTIONS: ComboBoxType[] = [
  { value: CHANGE_STATUS.DEFAULT, label: "기본" },
  { value: CHANGE_STATUS.CHANGE, label: "변경" },
];

export const GENDER_OPTIONS: ComboBoxType[] = [
  { value: GENDER_TYPES.FEMALE, label: "여자" },
  { value: GENDER_TYPES.MALE, label: "남자" },
];

export const make_use_auto_confirm_options = (
  spaceType: reservationSpaceType
) => {
  return [
    {
      value: BOOLEAN_TYPES.TRUE,
      label: "자동 승인",
      disabled: spaceType === RESERVATION_SPACE_TYPES.DEFAULT,
    },
    {
      value: BOOLEAN_TYPES.FALSE,
      label: "수동 승인",
      disabled: spaceType === RESERVATION_SPACE_TYPES.DEFAULT,
    },
  ] as ComboBoxType[];
};

export const VOC_APPLY_TYPES = {
  OWN: "own",
  DEPUTY: "deputy",
};

export const SCHEDULE_TYPES = {
  NOW: "now",
  CHANGE: "change",
};

export const SCHEDULE_TYPES_OPTIONS: ComboBoxType[] = [
  { value: SCHEDULE_TYPES.NOW, label: "즉시" },
  { value: SCHEDULE_TYPES.CHANGE, label: "일정 변경" },
];

export const VOC_APPLY_OPTIONS: ComboBoxType[] = [
  { value: VOC_APPLY_TYPES.OWN, label: "신청자와 동일" },
  { value: VOC_APPLY_TYPES.DEPUTY, label: "대신 접수" },
];

export const YES_NO_OPTIONS = createBooleanOptions("Y", "N");

export const NEED_OPTIONS = createBooleanOptions("필요", "필요 없음");

export const VISIBLE_OPTIONS = createBooleanOptions("노출", "노출 안함");

export const SATISFACTION_OPTIONS = createBooleanOptions("평가", "미평가");

export const USAGE_OPTIONS = createBooleanOptions("사용", "미사용");

export const CONNECT_OPTIONS = createBooleanOptions("연결", "미연결");

export const PAYMENT_OPTIONS = createBooleanOptions("유료", "무료", true);

export const PROVIDE_OPTIONS = createBooleanOptions("제공", "미제공", true);

export const USE_AUTO_CONFIRM_OPTIONS = createBooleanOptions(
  "자동 승인",
  "수동 승인"
);

export const EXIST_OPTIONS = createBooleanOptions("유", "무");

export const SPACE_CONCURRENT_OPTIONS = createBooleanOptions("포함", "미포함");

export const AUTH_COMPANY_OPTIONS = createBooleanOptions(
  "입주사 인증된 사용자만",
  "전체",
  true
);

export const DATE_RANGE_TYPE = {
  TODAY: 0,
  WEEKLY: 7,
  MONTHLY: 30,
  YEARLY: 365,
};

export const dateRangeButtonList: DataRangeButton[] = [
  { value: DATE_RANGE_TYPE.TODAY, label: "오늘" },
  { value: DATE_RANGE_TYPE.WEEKLY, label: "주간" },
  { value: DATE_RANGE_TYPE.MONTHLY, label: "월간" },
  { value: DATE_RANGE_TYPE.YEARLY, label: "연간" },
  { value: DEFAULT_SELECT_ALL_VALUE, label: "전체" },
];

export const evacueeType: ComboBoxType[] = [
  { value: "ROLE_EV_MASTER", label: "비상대피 마스터" },
  { value: "ROLE_EV_COM_ADMIN", label: "회사관리자" },
  { value: "ROLE_EV_DEP_ADMIN", label: "부서관리자" },
  { value: "ROLE_USER", label: "일반사용자" },
];

export const shownEvacueeType: ComboBoxType[] = [
  { value: "ROLE_ADMIN", label: "샌디 운영자" },
  ...evacueeType,
];
