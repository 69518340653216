import { TableColumnType } from "../../../../components/Table";
import { ToiletInfo } from "../../../../types/tag";

import { ComponentType } from "../../../../types/search";
import ListPage from "../../../../components/ListPage";
import {
  getTagStatusToiletCleaning,
  getTagStatusToiletCleaningExcel,
} from "../../../../services/tagService";
import { addDefaultOption } from "../../../../utils/comboBoxUtils";
import { GENDER_OPTIONS } from "../../../../types/comboBoxOption";
import { buildingGroupCategoryCodeValue } from "../../../../types/building";

type Props = {};

export default function Cleaning(props: Props) {
  const columnInfo: TableColumnType<ToiletInfo>[] = [
    { header: "기준일자", name: "referenceDate", width: "150" },
    { header: "요일", name: "referenceDayOfWeek", width: "100" },
    { header: "시간", name: "referenceHour", width: "100" },
    { header: "건물명", name: "buildingName" },
    { header: "층명", name: "floorName" },
    { header: "화장실명", name: "toiletName" },
    { header: "성별", name: "gender", width: "100" },
    { header: "상태", name: "status", width: "100" },
    { header: "측정 시간", name: "referenceTime", width: "150" },
  ];

  const componentList: ComponentType<ToiletInfo>[] = [
    {
      labelName: "기준일자",
      typeName: "dateRange",
      keyName: "createdAt",
      category: "referenceDate",
    },
    {
      typeName: "buildingAndFloor",
      keyName: "buildingAndFloor",
      category: "typeSearch",
      buildingGroupCategoryCode: buildingGroupCategoryCodeValue.IOT_DEVICE,
    },
    {
      labelName: "성별",
      typeName: "comboBox",
      keyName: "gender",
      comboBoxOptions: addDefaultOption(GENDER_OPTIONS),
      category: "typeSearch",
    },
    {
      labelName: "화장실명",
      typeName: "text",
      keyName: "toiletName",
      category: "detailSearch",
      placeholder: "화장실명을 입력해주세요",
    },
  ];

  return (
    <ListPage
      getDataApi={getTagStatusToiletCleaning}
      excelDownloadApi={getTagStatusToiletCleaningExcel}
      columnInfo={columnInfo}
      componentList={componentList}
      tableTitle="미화점검 이력"
    />
  );
}
