import ListPage from "../../../../components/ListPage";
import { TableColumnType } from "../../../../components/Table";
import {
  getLoggingLoginList,
  getLoggingLoginListExcel,
} from "../../../../services/loggingService";
import UseNoIcon from "../../../../svgs/icons/UseYn/UseNoIcon";
import UseYesIcon from "../../../../svgs/icons/UseYn/UseYesIcon";
import { LoggingLogin, loginChannelLabels } from "../../../../types/logging";
import { ComponentType, DASH } from "../../../../types/search";

type Props = {};

export default function LoggingLoginList(props: Props) {
  const columnInfo: TableColumnType<LoggingLogin>[] = [
    { header: "No.", defaultNo: true, width: "no" },
    { header: "사용자 Email", name: "email" },
    { header: "사용자명", name: "name" },
    {
      header: "로그인 채널",
      render: (item: LoggingLogin) => loginChannelLabels[item.channel] ?? DASH,
      width: "200",
    },
    {
      header: "로그인 성공 여부",
      render: (item: LoggingLogin) => (
        <div className="flex justify-center items-center">
          {item.isSucceed ? <UseYesIcon /> : <UseNoIcon />}
        </div>
      ),
      width: "200",
    },
    { header: "IP 주소", name: "ip", width: "200" },
    { header: "로그인 일시", name: "loggedAt", width: "date" },
  ];

  const componentList: ComponentType<LoggingLogin>[] = [
    {
      typeName: "dateRange",
      keyName: "loggedAt",
      category: "date",
      dateRangeType: "0",
    },
    {
      labelName: "사용자 Email",
      typeName: "text",
      keyName: "email",
      category: "detailSearch",
      placeholder: "사용자 Email을 입력해주세요",
    },
    {
      labelName: "사용자명",
      typeName: "text",
      keyName: "name",
      category: "detailSearch",
      placeholder: "사용자명을 입력해주세요",
    },
  ];

  return (
    <ListPage
      getDataApi={getLoggingLoginList}
      keyId={(item: LoggingLogin) => item.historyId}
      columnInfo={columnInfo}
      componentList={componentList}
      tableTitle="로그인 이력 목록"
      defaultPageSize={100}
      defaultPageSizeList={[100, 200, 300]}
      excelDownloadApi={getLoggingLoginListExcel}
      needExcelDownloadReason
    />
  );
}
