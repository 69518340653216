import { useLocation } from 'react-router-dom';
import { routes, RouteInfo } from '../../libs/routes';

const findProgramId = (routes: RouteInfo[], pathname: string, basePath = ''): string | undefined => {
  for (const route of routes) {
    const currentPath = basePath + (route.path ? `/${route.path}` : '');
    
    if (pathname === currentPath) {
      return route.programId;
    }

    if (route.children) {
      const childProgramId = findProgramId(route.children, pathname, currentPath);
      if (childProgramId) {
        return childProgramId;
      }
    }
  }
  return undefined;
};

export const useProgramId = () => {
  const location = useLocation();
  return findProgramId(routes, location.pathname);
};
