import { useState } from "react";
import { ImageType } from "../../../../types/incommodity";
import ImageThumbnail from "../ImageThumbnail";
import { FileInfo } from "../../../../types/file";
import { useModal } from "../../../../contexts/Modal";
import { compressImage } from "../../../../libs/file";

type Props = {
  images?: File[];
  onChange: (files: File[]) => void;
  defaultImages?: (ImageType | FileInfo)[];
  onDelete?: (item: ImageType | FileInfo) => void;
  label?: string;
  rightLabel?: string;
  maxImages?: number;
  maxFileSizeMB?: number;
  showDeleteButton?: boolean;
};

const BYTES_IN_KILOBYTE = 1024;

export default function MultiImageUploader({
  images = [],
  onChange,
  defaultImages,
  onDelete,
  label = "",
  rightLabel = "",
  maxImages = 1,
  maxFileSizeMB = 10, // 이미지 파일 기본 용량제한 10MB
  showDeleteButton = true,
}: Props) {
  const { showAlert } = useModal();
  const [fileNames, setFileNames] = useState<string[]>([]);

  const handleImageChange = async (files: FileList | null) => {
    if (!files) return;

    const maxFileSize = maxFileSizeMB * BYTES_IN_KILOBYTE * BYTES_IN_KILOBYTE; // MB를 바이트로 변환
    const selectedFiles = Array.from(files);

    if (!selectedFiles.every((file) => file.size <= maxFileSize)) {
      showAlert(
        `파일 크기가 ${maxFileSizeMB}MB를 초과한 이미지가 있습니다. 모든 선택한 이미지가 제외됩니다.`
      );
      return;
    }

    const count =
      selectedFiles.length + images.length + (defaultImages?.length || 0);

    if (count <= maxImages) {
      const compressedFilesPromises = selectedFiles.map((file) =>
        compressImage(file)
      );
      const compressedFiles = await Promise.all(compressedFilesPromises);

      onChange([...images, ...compressedFiles]);
      setFileNames(compressedFiles.map((file) => file.name));
    } else {
      showAlert(`최대 ${maxImages}개까지 이미지 선택이 가능합니다.`);
      setFileNames([]);
    }
  };

  const handleImageDelete = (deleteImage: File) => {
    onChange(images.filter((i) => i !== deleteImage));
    setFileNames((prev) => prev.filter((item) => item !== deleteImage.name));
  };

  const handleDefaultImageDelete = (image: ImageType | FileInfo) => {
    onDelete?.(image);
  };

  return (
    <div className="flex flex-col">
      <div className="flex flex-col gap-2">
        {label && (
          <label className="block text-label min-w-label">{label}</label>
        )}
        <div className="flex gap-2 items-center">
          <div className="flex w-[433px] h-[38px]">
            <input
              type="text"
              readOnly
              value={
                fileNames.length === 0
                  ? "선택된 파일 없음"
                  : fileNames.length === 1
                  ? fileNames[0]
                  : `파일 ${fileNames.length}개 선택`
              }
              className="text-[13px] border border-gray-200 pl-3 py-[9px] pr-1.5 min-w-[350px] text-brand-text-black-disabled"
              placeholder="선택한 파일 없음"
            />
            <label className="flex items-center border px-4 py-[11px] text-sm font-medium min-w-[84px] hover:cursor-pointer">
              파일선택
              <input
                type="file"
                accept=".jpg, .jpeg, .png"
                onChange={(e) => {
                  handleImageChange(e.target.files);
                  e.target.value = "";
                }}
                multiple
                style={{ display: "none" }}
                data-testid={"multi-image-uploader-input"}
              />
            </label>
          </div>
          {rightLabel && (
            <label className="ml-2 text-sm text-brand-additional-red">
              {rightLabel}
            </label>
          )}
        </div>
      </div>
      {(defaultImages?.length || images.length > 0) && (
        <div className="flex flex-wrap mt-2 gap-2 max-w-[700px]">
          {defaultImages?.map((image, index) => (
            <ImageThumbnail
              key={index}
              imageUrl={
                "filePath" in image
                  ? image.filePath
                  : "fileUrl" in image
                  ? (image.fileUrl as string)
                  : "url" in image
                  ? image.url
                  : ""
              }
              onDelete={() => {
                handleDefaultImageDelete(image);
              }}
              showDeleteButton={showDeleteButton}
            />
          ))}
          {images.map((image, index) => (
            <ImageThumbnail
              key={index}
              index={index}
              imageUrl={URL.createObjectURL(image)}
              onDelete={() => {
                handleImageDelete(image);
              }}
              showDeleteButton={true}
            />
          ))}
        </div>
      )}
    </div>
  );
}
