import { useState } from "react";

type Props = {
  onVersionChange?: (version: string) => void;
};

export default function VersionInput({ onVersionChange }: Props) {
  const [major, setMajor] = useState<string>("");
  const [minor, setMinor] = useState<string>("");
  const [patch, setPatch] = useState<string>("");

  const handleInputChange =
    (setter: React.Dispatch<React.SetStateAction<string>>) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      let value = e.target.value;
      if (value.length > 1 && /^0/.test(value)) {
        value = value.replace(/^0+/, "");
      }
      setter(value);
    };

  const handleBlur = () => {
    if (onVersionChange) {
      onVersionChange(`${major}.${minor}.${patch}`);
    }
  };

  const getClassName =
    "border px-2 py-1 max-w-[100px] h-[38px] text-center text-[13px]";

  return (
    <div className="flex items-end">
      <input
        className={getClassName}
        type="number"
        value={major}
        onChange={handleInputChange(setMajor)}
        onBlur={handleBlur}
        min={0}
        placeholder="0"
      />
      <span className="mx-1">.</span>
      <input
        className={getClassName}
        type="number"
        value={minor}
        onChange={handleInputChange(setMinor)}
        onBlur={handleBlur}
        min={0}
        placeholder="0"
      />
      <span className="mx-1">.</span>
      <input
        className={getClassName}
        type="number"
        value={patch}
        onChange={handleInputChange(setPatch)}
        onBlur={handleBlur}
        min={0}
        placeholder="0"
      />
    </div>
  );
}
