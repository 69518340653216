import { HttpStatusCode } from "axios";
import { useContext, useEffect, useRef, useState } from "react";

import {
  DEFAULT_LAT,
  DEFAULT_LNG,
  getDefaultPolygon,
  KOREAN_MAX_LATITUDE,
  KOREAN_MAX_LONGITUDE,
  KOREAN_MIN_LATITUDE,
  KOREAN_MIN_LONGITUDE,
  MAX_COORDINATE_COUNT,
  MIN_COORDINATE_COUNT,
} from "@/constants/geo";

import {
  validationFunctions,
  validationResultMessage,
} from "@/libs/validations";

import { EmergencyCoordinate, Shelter } from "@/types/emergency";

import { PopupContext } from "@/contexts/Popup";
import { useModal } from "@/contexts/Modal";

import { getShelter, putShelter } from "@/services/emergencyService";

import Spinner from "@/components/Spinner";
import DefaultButton from "@/components/DefaultButton";
import FormHeader from "@/components/Form/Header";
import RadioButtonGroup from "@/components/Input/RadioButtonGroup";
import { ComboBoxType } from "@/types/search";

type Props = {
  defaultLat: number | undefined;
  defaultLng: number | undefined;
  shelterId: string;
  stepMode?: boolean;
  onCancel?: () => void;
  onSuccess?: (shelterCoordinateListAndType: Partial<Shelter>) => void;
};

export default function NaverMapComponent({
  defaultLat,
  defaultLng,
  shelterId,
  onCancel,
  onSuccess,
  stepMode = false,
}: Props) {
  const { showAlert, showConfirm, handleError } = useModal();
  const { closePopup, refreshAndClosePopup } = useContext(PopupContext);

  const mapDivRef = useRef<HTMLDivElement | null>(null);
  const mapRef = useRef<naver.maps.Map | null>(null);
  const [_, setMarkerList] = useState<naver.maps.Marker[]>([]);
  const polygonRef = useRef<naver.maps.Polygon | null>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [shelter, setShelter] = useState<Partial<Shelter>>({
    isExternal: false, // 기본 = 내부
  });
  const [isCenter, setIsCenter] = useState(false);

  const shelterTypeOptions: ComboBoxType[] = [
    { value: "false", label: "영역 안쪽" },
    { value: "true", label: "영역 바깥쪽" },
  ];

  const addNewCoordinate = (e: any) => {
    const clickLatLng = e.coord as naver.maps.LatLng;

    if (
      shelter.shelterCoordinateList &&
      shelter.shelterCoordinateList.length >= MAX_COORDINATE_COUNT
    ) {
      showAlert(`최대 ${MAX_COORDINATE_COUNT}개까지 선택 가능합니다.`);
      return;
    }

    setShelter((prev) => {
      return {
        ...prev,
        shelterCoordinateList: [
          ...(prev.shelterCoordinateList ?? []),
          {
            lat: clickLatLng.lat(),
            lng: clickLatLng.lng(),
          },
        ],
      };
    });
  };

  const mapClear = () => {
    setShelter((prev) => {
      return {
        ...prev,
        shelterCoordinateList: [],
      };
    });
  };

  const mapUndo = () => {
    if (
      shelter.shelterCoordinateList &&
      shelter.shelterCoordinateList.length > 0
    ) {
      setShelter((prev) => {
        return {
          ...prev,
          shelterCoordinateList: prev.shelterCoordinateList?.slice(0, -1),
        };
      });
    }
  };

  const handleSave = () => {
    const errorMessage = validationResultMessage([
      validationFunctions.required(shelter.shelterCoordinateList, "폴리곤"),
    ]);

    if (errorMessage) {
      showAlert(errorMessage);
      // console.log("No polygon data to send.");
    } else {
      if (
        shelter.shelterCoordinateList &&
        shelter.shelterCoordinateList.length < MIN_COORDINATE_COUNT
      ) {
        showAlert(
          `최소 ${MIN_COORDINATE_COUNT}개 이상 지점을 선택해서 대피 영역을 지정해야합니다.`
        );
        return;
      }

      if (onSuccess) {
        // 이제는 쉘터 타입도 보내야하니까 shelter 자체를 넣어서 쉩터타입, 좌표리스트 두 값을 다 참조하도록 해야함
        onSuccess(shelter);
      } else {
        showConfirm("수정 하시겠습니까?", updateData);
      }
    }
  };

  const updateData = async () => {
    setIsLoading(true);
    try {
      const response = await putShelter(shelterId, createFormData());

      if (response.status === HttpStatusCode.Ok) {
        showAlert("수정이 완료 되었습니다.");
        refreshAndClosePopup();
      }
    } catch (err: any) {
      handleError(err, "지도 추가");
    } finally {
      setIsLoading(false);
    }
  };

  const createFormData = () => {
    const formData = new FormData();

    const postData: Partial<Shelter> = {
      ...shelter,
      lat: Number(shelter.lat),
      lng: Number(shelter.lng),
    };

    formData.append("request", JSON.stringify(postData));

    return formData;
  };

  useEffect(() => {
    // 마커 및 폴리곤 처리 로직
    const newMarkerList =
      shelter.shelterCoordinateList?.map((coord) => {
        return new naver.maps.Marker({
          position: new naver.maps.LatLng(coord.lat, coord.lng),
          map: mapRef.current as naver.maps.Map,
        });
      }) ?? [];
    setMarkerList((prev) => {
      prev.forEach((marker) => marker.setMap(null));
      return newMarkerList;
    });

    if ((shelter.shelterCoordinateList?.length ?? 0) === 0) {
      polygonRef.current?.setMap(null);
      if (mapRef.current) {
        polygonRef.current = new window.naver.maps.Polygon(
          getDefaultPolygon(mapRef.current)
        );
      }
    } else {
      polygonRef.current?.setPaths([
        shelter.shelterCoordinateList?.map(
          (coord) =>
            new window.naver.maps.LatLng(Number(coord.lat), Number(coord.lng))
        ) ?? [],
      ]);
    }
  }, [shelter.shelterCoordinateList]);

  useEffect(() => {
    // 클릭 이벤트 핸들러 관리
    if (mapRef.current) {
      const listener = naver.maps.Event.addListener(
        mapRef.current,
        "click",
        addNewCoordinate
      );

      return () => {
        naver.maps.Event.removeListener(listener);
      };
    }
  }, [mapRef.current, shelter.shelterCoordinateList]);

  useEffect(() => {
    // 네이버 지도에 오버레이되는 UI가 중첩되어 오류처럼 출력되는 문제 때문에, mapRef로 체크
    if (mapDivRef.current && !mapRef.current) {
      const mapOptions: naver.maps.MapOptions = {
        center: new window.naver.maps.LatLng(
          Number(defaultLat),
          Number(defaultLng)
        ),
        zoom: 16,
        zoomControl: true,
        zoomControlOptions: {
          position: window.naver.maps.Position.TOP_RIGHT,
          legendDisabled: true,
        },
      };

      mapRef.current = new window.naver.maps.Map(mapDivRef.current, mapOptions);

      polygonRef.current = new window.naver.maps.Polygon(
        getDefaultPolygon(mapRef.current)
      );
    } else {
      // console.log(
      //   "Naver Map is already loaded.",
      //   mapDivRef.current,
      //   mapRef.current
      // );
    }
  }, [mapDivRef.current]);

  // shelterId 물고 들어왔을때 이 코드 실행
  useEffect(() => {
    if (shelterId !== "") {
      const fetchData = async () => {
        try {
          setIsLoading(true);
          const response = await getShelter(shelterId);
          if (response.status === HttpStatusCode.Ok) {
            setShelter({
              ...response.data,
            });
            setIsCenter(false);
          } else {
            console.error("Failed to fetch shelter data.");
          }
        } catch (err) {
          handleError(err, "비상대피 영역 조회");
        } finally {
          setIsLoading(false);
        }
      };
      fetchData();
    }
  }, [shelterId]);

  useEffect(() => {
    if (isCenter === true) {
      // console.log("Map is already centered.");
      return;
    }

    if (mapRef.current) {
      // 첫 로드 이후, 마커 추가 시 페이지 이동을 제거하기 위해 isCenter 추가
      if (shelter.shelterCoordinateList) {
        mapRef.current?.setCenter(
          new naver.maps.LatLng(
            shelter.shelterCoordinateList.reduce((acc, cur) => {
              return acc + cur.lat;
            }, 0) / shelter.shelterCoordinateList.length,
            shelter.shelterCoordinateList.reduce((acc, cur) => {
              return acc + cur.lng;
            }, 0) / shelter.shelterCoordinateList.length
          )
        );

        setIsCenter(true);
      } else if (mapRef.current && defaultLat && defaultLng) {
        if (
          defaultLat >= KOREAN_MIN_LATITUDE &&
          defaultLat <= KOREAN_MAX_LATITUDE &&
          defaultLng >= KOREAN_MIN_LONGITUDE &&
          defaultLng <= KOREAN_MAX_LONGITUDE
        ) {
          mapRef.current?.setCenter(
            new naver.maps.LatLng(
              defaultLat ?? DEFAULT_LAT,
              defaultLng ?? DEFAULT_LNG
            )
          );
        } else {
          console.log("Default lat lng data is out of range.");
        }

        setIsCenter(true);
      } else {
        console.log("No default lat lng data.");
      }
    } else {
      console.log("Map is not ready yet.");
    }
  }, [mapRef.current, defaultLat, defaultLng, shelter]);

  return (
    <>
      {isLoading && <Spinner />}
      <div className="flex flex-col gap-5 min-w-[1000px]">
        <div>
          <FormHeader
            title="지도를 클릭하여 대피 영역을 지정해주세요."
            right={
              <span className="text-sm">
                ※ 팝업을 닫을 경우 기존 작성된 내용이 삭제됩니다.
              </span>
            }
          />
          <div className="flex justify-start gap-2 px-5 py-5">
            지도를 클릭하여 대피 영역을 지정해주세요. * 핀은 최대 10개까지 지정
            가능합니다.
          </div>
          <div className="flex justify-between gap-2 px-5 py-5">
            <div className="flex flex-row gap-2">
              <div className="flex items-center justify-center font-bold">
                대피완료 기준
              </div>
              <RadioButtonGroup
                options={shelterTypeOptions}
                value={String(shelter.isExternal)}
                onChange={(value: string) => {
                  setShelter((prev) => ({
                    ...prev,
                    isExternal: value === "true",
                  }));
                  mapClear();
                }}
              />
            </div>
            <div className="flex flex-row gap-2">
              <DefaultButton onClick={mapUndo}>되돌리기</DefaultButton>
              <DefaultButton onClick={mapClear}>획 초기화</DefaultButton>
            </div>
          </div>
          <div ref={mapDivRef} id="map" className="w-full h-[700px]"></div>
          <div className=" w-[1000px]">
            핀 리스트:{" "}
            {shelter.shelterCoordinateList
              ?.map((coord) => `(${coord.lat}, ${coord.lng})`)
              .join(", ")}
          </div>
          {shelter.shelterCoordinateList && (
            <div>
              중앙 지점:{" "}
              {`(${
                shelter.shelterCoordinateList.reduce((acc, cur) => {
                  return acc + cur.lat;
                }, 0) / shelter.shelterCoordinateList.length
              },
            ${
              shelter.shelterCoordinateList.reduce((acc, cur) => {
                return acc + cur.lng;
              }, 0) / shelter.shelterCoordinateList.length
            })`}
            </div>
          )}
        </div>
        <div className="flex justify-center gap-2 py-5">
          <DefaultButton
            onClick={() => {
              if (onCancel) {
                onCancel();
              } else {
                closePopup();
              }
            }}
          >
            {stepMode ? "이전" : "닫기"}
          </DefaultButton>
          <DefaultButton color="primary" onClick={handleSave}>
            {stepMode ? "등록" : "수정"}
          </DefaultButton>
        </div>
      </div>
    </>
  );
}
