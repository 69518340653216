import { HttpStatusCode } from "axios";
import { useEffect, useState } from "react";
import { useModal } from "../../contexts/Modal";
import {
  EtcSatisfaction,
  EtcSatisfactionComment,
  SatisfactionServiceName,
} from "../../types/etc";
import Spinner from "../Spinner";
import {
  getEtcSatisfaction,
  patchEtcSatisfaction,
  postEtcSatisfaction,
} from "../../services/etcService";
import ListPage from "../ListPage";
import { TableColumnType } from "../Table";
import DefaultButton from "../DefaultButton";
import DefaultTextArea from "../DefaultTextArea";
import StarPointTrueIcon from "../../svgs/icons/StarPoint/StarPointTrue";
import StarPointFalseIcon from "../../svgs/icons/StarPoint/StarPointFalse";
import { CrudProcessType } from "../../types/common";
import {
  validationFunctions,
  validationResultMessage,
} from "../../libs/validations";
import { DASH } from "../../types/search";

const SATISFACTION_REPLY_EMPTY_TEXT = "등록된 이용자 만족도 평가가 없습니다.";

type Props = {
  serviceName: SatisfactionServiceName;
  serviceId: string;
};

export default function Satisfaction({ serviceName, serviceId }: Props) {
  const { showAlert, showConfirm, handleError } = useModal();

  const [comment, setComment] = useState<Partial<EtcSatisfaction>>({});
  const [commentReply, setCommentReply] = useState<Partial<EtcSatisfaction>>({
    comment: "",
  });

  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    try {
      setIsLoading(true);

      const res = await getEtcSatisfaction(serviceName, serviceId);

      if (res.status === HttpStatusCode.Ok) {
        const satisfaction = res.data?.satisfaction?.[0];

        if (satisfaction) {
          setComment(satisfaction);

          const commentReplyData = satisfaction?.reply;

          if (commentReplyData) {
            setCommentReply(commentReplyData);
          }
        }
      }
    } catch (err: any) {
      handleError(err, "데이터 조회");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [serviceId]);

  const renderStarPoint = (point: number) => {
    return (
      <div className="flex items-center justify-center">
        {Array.from({ length: 5 }, (_, i) => (
          <span key={i}>
            {i < point ? <StarPointTrueIcon /> : <StarPointFalseIcon />}
          </span>
        ))}
      </div>
    );
  };

  const columnInfo: TableColumnType<Partial<EtcSatisfaction>>[] = [
    {
      header: "별점",
      render: (item: Partial<EtcSatisfaction>) =>
        renderStarPoint(item.satisfaction ?? 0),
    },
    { header: "작성일시", name: "createdAt" },
    {
      header: "별명(이름) 작성자이메일",
      render: (item: Partial<EtcSatisfaction>) => (
        <div>
          {`${item.createdByNickName ?? DASH}(${item.createdByName ?? DASH})`}
          <br />
          {`(${item.createdByEmail ?? DASH})`}
        </div>
      ),
    },
    {
      header: "내용",
      render: (item: Partial<EtcSatisfaction>) => (
        <div className="min-w-[200px]">{item.comment}</div>
      ),
    },
  ];

  const handleProcess = (process: CrudProcessType) => {
    const text = process === "CREATE" ? "등록" : "수정";

    const errorMessage = validationResultMessage([
      validationFunctions.required(commentReply?.comment, "댓글"),
      validationFunctions.numberRange(10, 500)(
        commentReply?.comment?.length ?? 0,
        "댓글"
      ),
    ]);

    if (errorMessage) {
      showAlert(errorMessage);
    } else {
      const data: Partial<EtcSatisfactionComment> = {
        ...(process === "CREATE" && { serviceName }),
        comment: commentReply?.comment,
      };

      showConfirm(`${text} 하시겠습니까?`, async () => {
        try {
          const res =
            process === "CREATE"
              ? await postEtcSatisfaction(serviceId, data)
              : await patchEtcSatisfaction(
                  commentReply?.etcSatisfactionId ?? -1,
                  data
                );
          if (res.status === HttpStatusCode.Ok) {
            showAlert(`${text} 되었습니다.`);
            fetchData();
          }
        } catch (err: any) {
          handleError(err, text);
        }
      });
    }
  };

  const renderButton = () => {
    const selectedButton = Boolean(commentReply?.etcSatisfactionId);

    return (
      <DefaultButton
        color="white"
        className="py-[11px] rounded-none h-full w-[92px] text-sm font-medium whitespace-nowrap"
        onClick={() => {
          selectedButton ? handleProcess("UPDATE") : handleProcess("CREATE");
        }}
      >
        댓글
        <br />
        {selectedButton ? "수정" : "등록"}
      </DefaultButton>
    );
  };

  const handleCommentReplyChange = (value: string) => {
    setCommentReply((prev) => ({ ...prev, comment: value }));
  };

  return (
    <>
      {isLoading && <Spinner />}
      <div className="flex flex-col gap-3 w-full">
        <ListPage<Partial<EtcSatisfaction>>
          getDataApi={Promise.resolve}
          columnInfo={columnInfo}
          selectedMode
          selectedData={Object.keys(comment).length > 0 ? [comment] : []}
          defaultContentText={SATISFACTION_REPLY_EMPTY_TEXT}
          onlyRenderTable
        />
        {Object.keys(comment).length > 0 && (
          <div className="flex h-[100px]">
            <DefaultTextArea
              value={commentReply?.comment}
              onChange={handleCommentReplyChange}
              maxLength={500}
              placeholder="사용자 평가에 대한 댓글을 남겨주세요 (10자 이상)"
            />
            {renderButton()}
          </div>
        )}
      </div>
    </>
  );
}
