import { HttpStatusCode } from "axios";
import { useContext, useEffect, useState } from "react";

import { ComboBoxType } from "../../../../../../types/search";
import {
  BuildingGroup,
  TagSelectedItem,
  Toilet,
  ToiletCompartment,
  buildingGroupCategoryCodeValue,
} from "../../../../../../types/building";
import {
  TAG_GROUP_TYPE_ID,
  TAG_ID_REGEX,
  TAG_ID_REGEX_COMMENT,
  Tag,
  TagType,
  ToiletTag,
} from "../../../../../../types/tag";

import {
  validationFunctions,
  validationResultMessage,
} from "../../../../../../libs/validations";
import {
  findComboBoxItem,
  toComboBoxType,
} from "../../../../../../utils/comboBoxUtils";

import { PopupContext } from "../../../../../../contexts/Popup";
import { useModal } from "../../../../../../contexts/Modal";
import {
  getBuildingFloor,
  getToilet,
  getToiletList,
} from "../../../../../../services/buildingService";
import {
  getToiletTagList,
  postTag,
} from "../../../../../../services/tagService";

import DefaultButton from "../../../../../DefaultButton";
import FormHeader from "../../../../../Form/Header";
import FormRow from "../../../../../Form/Row";
import FormRowLabel from "../../../../../Form/RowLabel";
import DefaultInput from "../../../../../Input/DefaultInput";
import RadioButtonGroup from "../../../../../Input/RadioButtonGroup";
import ListPage from "../../../../../ListPage";
import DefaultSelect from "../../../../../SelectBox/DefaultSelect";
import Spinner from "../../../../../Spinner";
import { TableColumnType } from "../../../../../Table";
import TagSingleFilter from "../../../../../TagFilter/TagSingleFilter";
import CommonRadio from "../../../../../Input/CommonRadio";
import { useUserContext } from "../../../../../../contexts/User";
import DefaultLabel from "../../../../../Input/DefaultLabel";

type Props = { tagType: TagType[] };

export const TOILET_DOOR_TAG_TYPE_ID = 13;
export const TOILET_COMPARTMENT_TAG_TYPE_ID = 14;
export const NOT_NUMBER = -1;

export const TAG_NAME_SUFFIX = "센서상태";
export const DELIMITER_TAG_NAME = "_";

export default function ToiletTagCreateForm({ tagType }: Props) {
  const { userInfo } = useUserContext();
  const { refreshAndClosePopup } = useContext(PopupContext);
  const { showAlert, showConfirm, handleError } = useModal();
  const [toiletTag, setToiletTag] = useState<Partial<Tag>>({});
  const [floorOptions, setFloorOptions] = useState<ComboBoxType[]>([]);
  const [tagTypeOptions, setTagTypeOptions] = useState<ComboBoxType[]>([]);
  const [toiletOptions, setToiletOptions] = useState<ComboBoxType[]>([]);
  const [selectedTag, setSelectedTag] = useState<TagSelectedItem>();
  const [isLoading, setIsLoading] = useState(false);
  const [toiletCompartments, setToiletCompartments] = useState<
    ToiletCompartment[]
  >([]);

  useEffect(() => {
    fetchTagType();
  }, []);

  const fetchToiletCompartment = async (spaceToiletId: string) => {
    try {
      setIsLoading(true);

      const response = await getToilet(spaceToiletId);
      const data: Partial<ToiletTag> = {
        buildingId: toiletTag.buildingId,
        buildingFloorId: toiletTag.buildingFloorId,
        spaceToiletId: toiletTag.spaceToiletId,
      };
      const responseTag = await getToiletTagList(data);

      if (response.data && "spaceToiletCompartment" in response.data) {
        const toiletCompartment = (
          response.data as Toilet
        ).spaceToiletCompartment.filter((item) => item.isActive);

        const options = toiletCompartment.map((item) => ({
          ...item,
          connected: responseTag.data?.items.find(
            (tag) => tag.toiletCompartmentId === item.toiletCompartmentId
          )
            ? true
            : false,
        }));

        setToiletCompartments(options);
      } else {
        showAlert("화장실 칸 정보를 불러오는 중 오류가 발생했습니다.");
      }
    } catch (err: any) {
      handleError(err, "화장실 칸 정보 조회");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchToilet = async (buildingId: string, buildingFloorId: string) => {
    try {
      setIsLoading(true);

      const response = await getToiletList({ buildingId, buildingFloorId });

      if (response.data) {
        const options = toComboBoxType(
          response.data.items,
          "spaceToiletId",
          "toiletName"
        );
        setToiletOptions(options);
      } else {
        showAlert("화장실 정보를 불러오는 중 오류가 발생했습니다.");
      }
    } catch (err: any) {
      handleError(err, "화장실 정보 조회");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchFloor = async (buildingId: string) => {
    try {
      setIsLoading(true);

      const response = await getBuildingFloor(buildingId);

      if (response.data) {
        const options = response.data.map((item) => ({
          value: item.buildingFloorId,
          label: item.floorName,
        }));

        setFloorOptions(options);
      }
    } catch (err: any) {
      handleError(err, "층 정보 조회");
    } finally {
      setIsLoading(false);
    }
  };

  const handlePost = () => {
    const errorMessage = validationResultMessage([
      validationFunctions.required(toiletTag.buildingId, "위치"),
      validationFunctions.required(toiletTag.buildingName, "위치"),
      validationFunctions.required(toiletTag.buildingFloorId, "층"),
      validationFunctions.required(toiletTag.spaceToiletId, "화장실"),
      validationFunctions.required(toiletTag.tagTypeId, "태그 유형"),
      validationFunctions.matchesRegex(
        toiletTag.tagId,
        TAG_ID_REGEX,
        "태그 ID",
        TAG_ID_REGEX_COMMENT
      ),
      ...(toiletTag.tagTypeId === TOILET_COMPARTMENT_TAG_TYPE_ID
        ? [
            validationFunctions.required(
              toiletTag.toiletCompartmentId,
              "화장실 칸"
            ),
          ]
        : []),
      validationFunctions.required(toiletTag.macAddr, "MAC 주소"),
    ]);

    if (errorMessage) {
      showAlert(errorMessage);
    } else {
      const tagName = getName();

      if (!tagName) {
        showAlert("태그명 생성에 실패했습니다.");
        console.error("Failed to create tagName");
        return;
      }

      showConfirm("등록 하시겠습니까?", () => postData(tagName));
    }
  };

  const postData = async (tagName: string) => {
    const data = {
      createdBy: userInfo?.memberId ?? 0,
      tagId: toiletTag.tagId,
      tagGroupTypeId: TAG_GROUP_TYPE_ID.TOILET,
      tagTypeId: toiletTag.tagTypeId,
      tagLocation: toiletTag.tagLocation,
      buildingSpaceId: toiletTag.buildingSpaceId,
      buildingFloorId: toiletTag.buildingFloorId,
      toiletCompartmentId: toiletTag.toiletCompartmentId,
      spaceToiletId: toiletTag.spaceToiletId,
      isActive: true,
      deviceId: toiletTag.deviceId ?? null,
      deviceAddr: toiletTag.deviceAddr ?? null,
      macAddr: toiletTag.macAddr,
      tagName,
    } as Partial<Tag>;

    try {
      const response = await postTag(data);
      if (response.status === HttpStatusCode.Ok) {
        showAlert("등록 되었습니다.");
        refreshAndClosePopup();
      }
    } catch (err: any) {
      handleError(err, "등록");
    }
  };

  const getName = () => {
    const items: string[] = [
      toiletTag.buildingName ?? "",
      findComboBoxItem(floorOptions, toiletTag.buildingFloorId ?? "")?.label ??
        "",
      findComboBoxItem(toiletOptions, toiletTag.spaceToiletId ?? "")?.label ??
        "",
      findComboBoxItem(tagTypeOptions, toiletTag.tagTypeId?.toString() ?? "")
        ?.label ?? "",
      TAG_NAME_SUFFIX,
    ];

    const valid = items.every((item) => item !== "");
    return valid ? items.join(DELIMITER_TAG_NAME) : "";
  };

  const handleBuildingChange = (item: BuildingGroup) => {
    if (item.buildingId && item.buildingName) {
      setToiletTag((prev) => ({
        ...prev,
        buildingId: item.buildingId as string,
        buildingName: item.buildingName as string,
        buildingFloorId: undefined,
        spaceToiletId: undefined,
      }));

      setSelectedTag(item);
      fetchFloor(item.buildingId);
      fetchTagType();
    } else {
      console.error("BuildingId or BuildingName is falsy");
    }
  };

  const handleFloorChange = (key: keyof Tag) => (value: string) => {
    if (!toiletTag.buildingId) {
      console.error("BuildingId is falsy");
      return;
    }

    let toiletItem: Partial<Tag> = { [key]: value };
    if (toiletTag.tagTypeId === TOILET_COMPARTMENT_TAG_TYPE_ID) {
      toiletItem = { ...toiletItem, tagTypeId: TOILET_DOOR_TAG_TYPE_ID };
      setToiletCompartments([]);
    }

    setToiletTag((prev) => ({
      ...prev,
      ...toiletItem,
      spaceToiletId: undefined,
    }));
    fetchToilet(toiletTag.buildingId, value);
    fetchTagType();
  };

  const handleDefaultChange = (key: keyof Tag) => (value: string) => {
    setToiletTag((prev) => ({ ...prev, [key]: value }));
  };

  const handleToiletChange = (key: keyof Tag) => (value: string) => {
    setToiletTag((prev) => ({ ...prev, [key]: value }));
    fetchTagType(value);

    if (toiletTag.tagTypeId === TOILET_COMPARTMENT_TAG_TYPE_ID) {
      fetchToiletCompartment(value);
    }
  };

  const fetchTagType = (spaceToiletId: string = "") => {
    if (tagTypeOptions.length === 0) {
      const options = toComboBoxType(tagType, "tagTypeId", "tagTypeName");
      setTagTypeOptions(options);
      return;
    }

    checkExistTag(spaceToiletId);
  };

  const updateDisabledOptions = (
    baseOptions: ComboBoxType[],
    comparisonData: ToiletTag[]
  ) => {
    const options = baseOptions.map((option) => {
      const tagTypeId = Number(option.value);
      const disabled =
        tagTypeId !== TOILET_COMPARTMENT_TAG_TYPE_ID &&
        comparisonData.find((item) => tagTypeId === item.tagTypeId)
          ? true
          : false;
      return { ...option, disabled: disabled };
    });
    return options;
  };

  const checkExistTag = async (spaceToiletId: string) => {
    try {
      setIsLoading(true);

      const data = {
        buildingId: toiletTag.buildingId,
        buildingFloorId: toiletTag.buildingFloorId,
        spaceToiletId: spaceToiletId,
      };

      const isIncomplete = Object.values(data).includes(undefined);

      const responseItems = isIncomplete
        ? []
        : (await getToiletTagList(data)).data?.items ?? [];

      const options = updateDisabledOptions(tagTypeOptions, responseItems);

      setTagTypeOptions(options);
      setToiletTag((prev) => ({ ...prev, tagTypeId: undefined }));
    } catch (err: any) {
      handleError(err, "태그 중복 체크");
    } finally {
      setIsLoading(false);
    }
  };

  const handleRadioChange = (key: keyof Tag) => (value: string) => {
    const tagTypeId = !Number.isNaN(Number(value)) ? Number(value) : NOT_NUMBER;

    if (tagTypeId === NOT_NUMBER) {
      return;
    }

    if (tagTypeId === TOILET_COMPARTMENT_TAG_TYPE_ID) {
      if (toiletTag.spaceToiletId) {
        fetchToiletCompartment(toiletTag.spaceToiletId);
      } else {
        showAlert("화장실을 선택해주세요");
        return;
      }
    }

    setToiletTag((prev) => ({
      ...prev,
      [key]: Number(value),
      toiletCompartmentId: undefined,
      tagId: undefined,
    }));
  };

  const handleToiletCompartmentChange = (value: string) => {
    setToiletTag((prev) => ({ ...prev, toiletCompartmentId: value }));
  };

  const compartmentColumnInfo: TableColumnType<ToiletCompartment>[] = [
    {
      header: "선택",
      render: (item: ToiletCompartment) => (
        <div className="flex w-full p-1 justify-center items-center">
          {!item.connected ? (
            <CommonRadio
              value={item.toiletCompartmentId}
              checked={
                item.toiletCompartmentId === toiletTag.toiletCompartmentId
              }
              onChange={handleToiletCompartmentChange}
            />
          ) : (
            <DefaultLabel text="연결 완료" color="gray" />
          )}
        </div>
      ),
    },
    { header: "칸 ID", name: "toiletCompartmentId" },
    { header: "칸 명", name: "toiletCompartmentName" },
  ];

  return (
    <div className="flex flex-col gap-5">
      {isLoading && <Spinner />}
      <div className="flex flex-col w-[900px]">
        <FormHeader title="기준 정보" />
        <FormRow>
          <FormRowLabel title="위치" isRequired>
            <div className="flex flex-col gap-1.5">
              <TagSingleFilter
                selectType="building"
                selectedItem={selectedTag}
                onChangeBuilding={handleBuildingChange}
                categoryCode={buildingGroupCategoryCodeValue.IOT_DEVICE}
              />
              <DefaultSelect
                value={toiletTag.buildingFloorId}
                placeholder="층 선택"
                optionList={floorOptions}
                onChange={handleFloorChange("buildingFloorId")}
                disabled={
                  toiletTag.buildingId === undefined ||
                  floorOptions.length === 0
                }
              />
            </div>
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="화장실" isRequired>
            <DefaultSelect
              optionList={toiletOptions}
              value={toiletTag.spaceToiletId}
              placeholder="화장실 선택"
              onChange={handleToiletChange("spaceToiletId")}
              disabled={
                toiletTag.buildingFloorId === undefined ||
                toiletOptions.length === 0
              }
            />
          </FormRowLabel>
        </FormRow>
        <FormHeader title="태그 정보" />
        <FormRow>
          <FormRowLabel title="태그 유형" isRequired>
            <div className="flex flex-col gap-1">
              <RadioButtonGroup
                options={tagTypeOptions}
                value={toiletTag.tagTypeId?.toString()}
                onChange={handleRadioChange("tagTypeId")}
              />
              {toiletTag.tagTypeId === TOILET_COMPARTMENT_TAG_TYPE_ID && (
                <div className="w-[700px]">
                  <ListPage<ToiletCompartment>
                    getDataApi={Promise.resolve}
                    columnInfo={compartmentColumnInfo}
                    selectedMode
                    selectedData={toiletCompartments}
                    hidePagination
                    isTableScroll
                  />
                </div>
              )}
            </div>
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="태그 ID" isRequired>
            <DefaultInput
              value={toiletTag.tagId}
              placeholder="태그 ID를 입력해주세요"
              onChange={handleDefaultChange("tagId")}
              disabled={
                toiletTag.tagTypeId === TOILET_COMPARTMENT_TAG_TYPE_ID &&
                !Boolean(toiletTag.toiletCompartmentId)
              }
              maxLength={20}
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="디바이스 ID">
            <DefaultInput
              value={toiletTag.deviceId}
              placeholder="디바이스 ID를 입력해주세요"
              onChange={handleDefaultChange("deviceId")}
              disabled={
                toiletTag.tagTypeId === TOILET_COMPARTMENT_TAG_TYPE_ID &&
                !Boolean(toiletTag.toiletCompartmentId)
              }
              maxLength={20}
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="디바이스 주소">
            <DefaultInput
              value={toiletTag.deviceAddr}
              placeholder="디바이스 주소를 입력해주세요"
              onChange={handleDefaultChange("deviceAddr")}
              disabled={
                toiletTag.tagTypeId === TOILET_COMPARTMENT_TAG_TYPE_ID &&
                !Boolean(toiletTag.toiletCompartmentId)
              }
              maxLength={20}
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="MAC 주소" isRequired>
            <DefaultInput
              value={toiletTag.macAddr}
              placeholder="MAC 주소를 입력해주세요"
              onChange={handleDefaultChange("macAddr")}
              disabled={
                toiletTag.tagTypeId === TOILET_COMPARTMENT_TAG_TYPE_ID &&
                !Boolean(toiletTag.toiletCompartmentId)
              }
              maxLength={20}
            />
          </FormRowLabel>
        </FormRow>
      </div>

      <div className="flex justify-center py-5">
        <DefaultButton color="primary" onClick={handlePost}>
          등록
        </DefaultButton>
      </div>
    </div>
  );
}
