import DefaultButton from "@/components/DefaultButton";
import FormHeader from "@/components/Form/Header";
import FormRow from "@/components/Form/Row";
import FormRowLabel from "@/components/Form/RowLabel";
import DefaultInput from "@/components/Input/DefaultInput";
import { TableColumnType } from "@/components/Table";
import { SearchTagData } from "@/components/TagFilter";
import { TagData } from "@/components/TagList";
import TagSelector from "@/components/TagSelector";
import { useModal } from "@/contexts/Modal";
import { PopupContext } from "@/contexts/Popup";
import {
  validationFunctions,
  validationResultMessage,
} from "@/libs/validations";
import { getBuildingList } from "@/services/buildingService";
import {
  getEmergencyBuildingGroupInfo,
  getMockEmergencyBuildingList,
  postEmergencyBuildingGroup,
  putEmergencyBuildingGroupInfo,
} from "@/services/emergencyService";
import { Building, BuildingGroup } from "@/types/building";
import {
  EmergencyBuilding,
  EmergencyBuildingGroupRequest,
} from "@/types/emergency";
import { HttpStatusCode } from "axios";
import { useContext, useEffect, useState } from "react";
import ShelterMapping from "../ShelterMapping";
import Spinner from "@/components/Spinner";
import { ComponentType } from "@/types/search";
import ListPage from "@/components/ListPage";

type Props = {
  groupId?: string;
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function EmergencyBuildingGroupCreateForm({
  groupId,
  setRefresh,
}: Props) {
  // props 로 선택적으로 groupId 를 받아서, 수정으로 열린 페이지 인지를 판단하기.
  const { showAlert, showConfirm, handleError } = useModal();
  const { closePopup, openPopup, refreshAndClosePopup } =
    useContext(PopupContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [groupName, setGroupName] = useState<string>("");
  const [lat, setLat] = useState<string>();
  const [lng, setLng] = useState<string>();
  const [buildingList, setBuildingList] = useState<EmergencyBuilding[]>([]);
  const [tags, setTags] = useState<SearchTagData>({}); // groupId 가 있는 경우에는 데이터를 불러온 뒤 역으로 이 tags 를 셋팅해주면, TagSelector 내의 useEffect 를 통해 오른쪽 값이 셋팅된다.
  const [isSituationMapped, setIsSituationMapped] = useState<boolean>(false); // 해당 그룹에 상황이 만들어져 있는지 여부
  const [propGroupId, setPropGroupId] = useState<string | undefined>(groupId);
  const [isValueChanged, setIsValueChanged] = useState<boolean>(false);
  const [userAction, setUserAction] = useState<boolean>(false);

  const buildingColumns: TableColumnType<BuildingGroup>[] = [
    { header: "건물 ID", name: "buildingId" },
    { header: "건물명", name: "buildingName" },
  ];

  const buildingColumnsForSimple: TableColumnType<EmergencyBuilding>[] = [
    { header: "건물 ID", name: "buildingId" },
    { header: "건물명", name: "buildingName" },
  ];

  const reftComponentList: ComponentType<Building>[] = [
    {
      keyName: "buildingName",
      typeName: "text",
      placeholder: "건물명을 입력해주세요",
    },
    {
      keyName: "buildingId",
      typeName: "text",
      placeholder: "건물 ID를 입력해주세요",
    },
  ];

  const compareBuilding = (item: BuildingGroup) => (o: BuildingGroup) => {
    return item.buildingId === o.buildingId;
  };

  const getTagBuilding = (item: BuildingGroup) => {
    return {
      id: item.buildingId,
      name: item.buildingName,
      data: item,
    } as TagData;
  };

  const getTagBuildingEmergency = (item: EmergencyBuilding) => {
    return {
      id: item.buildingId,
      name: item.buildingName,
      data: item,
    } as TagData;
  };

  const handleClickSaveAndNext = () => {
    // 1. validation check
    const errorMessage = validationResultMessage([
      validationFunctions.required(groupName, "그룹명"),
      validationFunctions.required(lat, "위도"),
      validationFunctions.required(lng, "경도"),
      validationFunctions.number(lat, "위도"),
      validationFunctions.number(lng, "경도"),
      validationFunctions.required(tags.data, "건물"),
    ]);

    if (errorMessage) {
      showAlert(errorMessage);
      return;
    } else {
      if (propGroupId) {
        // 수정
        showConfirm("변경 사항이 수정됩니다.", () => putGroup(propGroupId));
      } else {
        // 등록
        showConfirm("비상대피 건물 그룹을 등록하시겠습니까?", postGroup);
      }
    }
  };

  const postGroup = async () => {
    // 2. post data setting
    const postData = {
      shelterGroupName: groupName,
      lat: Number(lat),
      lng: Number(lng),
      isActive: true,
    } as EmergencyBuildingGroupRequest;
    if (tags.data && tags.data.length > 0) {
      // 항상 참
      const buildingIds: string[] = tags.data?.map((tag) => tag.id);
      postData.buildingIdList = buildingIds;
    }

    try {
      const response = await postEmergencyBuildingGroup(postData);

      if (response.status === HttpStatusCode.Ok) {
        const groupId = response.data;
        showAlert("등록 되었습니다.");
        setRefresh(true);
        if (groupId && typeof groupId === "string") {
          openPopup({
            header: (
              <div className="flex items-center">
                {"대피영역 지정"}
                <span className="text-sm text-[#D95050] ml-4 font-normal">
                  *팝업을 닫을 경우 기존 작성된 내용이 삭제됩니다.
                </span>
              </div>
            ),
            content: (
              <ShelterMapping
                evacuationGroupId={groupId}
                setRefresh={setRefresh}
              />
            ),
          });
        }
      } else {
        showAlert(`그룹 등록을 실패했습니다. + ${response.status}`);
      }
    } catch (err: any) {
      const error = err.response?.data?.error;

      if (error) {
        showAlert(err.response?.data?.error);
      } else {
        handleError(err, "비상대피 건물 그룹 등록");
      }
    }
  };

  const putGroup = async (propGroupId: string) => {
    const puyData = {
      shelterGroupName: groupName,
      lat: Number(lat),
      lng: Number(lng),
      isActive: true,
    } as EmergencyBuildingGroupRequest;
    if (tags.data && tags.data.length > 0) {
      // 항상 참
      const buildingIds: string[] = tags.data?.map((tag) => tag.id);
      puyData.buildingIdList = buildingIds;
    }

    try {
      const response = await putEmergencyBuildingGroupInfo(
        propGroupId,
        puyData
      );

      if (response.status === HttpStatusCode.Ok) {
        setRefresh(true);
        showAlert("수정 되었습니다.");
        refreshAndClosePopup();
      } else {
        showAlert(`그룹 수정을 실패했습니다. + ${response.status}`);
      }
    } catch (err: any) {
      const error = err.response?.data?.error;

      if (error) {
        showAlert(err.response?.data?.error);
      } else {
        handleError(err, "비상대피 건물 그룹 등록");
      }
    }
  };

  const fetchGroupInfo = async (evacuationGroupId: string) => {
    try {
      setIsLoading(true);
      const response = await getEmergencyBuildingGroupInfo(evacuationGroupId);
      if (response.status === HttpStatusCode.Ok) {
        const { evacuationGroupName, lat, lng, buildingList, situationCount } =
          response.data;

        if (situationCount) {
          setIsSituationMapped(situationCount > 0);
        }

        // 이론상 항상 참
        if (evacuationGroupName && lat && lng && buildingList) {
          setGroupName(evacuationGroupName);
          setLat(String(lat));
          setLng(String(lng));
          const tagData = buildingList.map((building) =>
            getTagBuildingEmergency(building)
          );
          setBuildingList(buildingList);
          setTags({
            data: tagData,
            // type: "building",
          });
        }
      } else {
        showAlert("그룹 조회에 실패 했습니다.");
      }
    } catch (error: any) {
      handleError(error, "비상 대피 그룹 조회");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (propGroupId) {
      // 생성이 아닌 수정 케이스.
      fetchGroupInfo(propGroupId);
    }
  }, [propGroupId]);

  const customSetTags = (value: SearchTagData) => {
    if (!isValueChanged && userAction) {
      setIsValueChanged(true);
    }
    setTags(value);
  };

  const handleClickForm = () => {
    if (!userAction) {
      setUserAction(true);
    }
  };

  return (
    <div className="flex flex-col gap-5" onClick={handleClickForm}>
      {isLoading && <Spinner />}
      <div className="flex flex-col max-h-[700px] overflow-y-auto">
        <FormHeader title="건물 정보" />
        <FormRow>
          <FormRowLabel title="그룹명 입력" isRequired>
            <DefaultInput
              value={groupName}
              placeholder="그룹명을 입력해주세요."
              onChange={(value: string) => {
                if (!isValueChanged) {
                  setIsValueChanged(true);
                }
                setGroupName(value);
              }}
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="건물 지도 좌표" isRequired>
            <div className="flex">
              <DefaultInput
                label="위도"
                value={lat}
                type="number"
                placeholder="위도 입력"
                onChange={(value: string) => {
                  if (!isValueChanged) {
                    setIsValueChanged(true);
                  }
                  setLat(value);
                }}
              />
              <div className="ml-2">
                <DefaultInput
                  label="경도"
                  value={lng}
                  type="number"
                  placeholder="경도 입력"
                  onChange={(value: string) => {
                    if (!isValueChanged) {
                      setIsValueChanged(true);
                    }
                    setLng(value);
                  }}
                />
              </div>
            </div>
          </FormRowLabel>
        </FormRow>
        <FormHeader title="건물 선택" />
        <FormRow>
          {isSituationMapped ? (
            <ListPage
              getDataApi={getMockEmergencyBuildingList} // ListPage 의 타입을 맞춰주기위한 더미 api
              selectedData={buildingList}
              columnInfo={buildingColumnsForSimple}
              componentList={[]}
              tableTitle={"선택 건물 목록"}
              selectedMode={true}
              isTableScroll={true}
              warningText={
                "* 해당 그룹은 상황에 등록되어 건물 추가, 삭제가 불가능합니다."
              }
              isSmallButton
            />
          ) : (
            <TagSelector
              getDataApi={getBuildingList} // 일단 건물 불러오는 api 를 붙여놓긴 했는데, 이걸 써도 되는건지 확인 필요
              componentList={[]}
              reftTableComponentList={reftComponentList}
              columnInfo={buildingColumns}
              title="건물 목록"
              tagType="building"
              tags={tags}
              setTags={customSetTags}
              compareItem={compareBuilding}
              getTagItem={getTagBuilding}
              inline
              isHorizontal
            />
          )}
        </FormRow>
      </div>
      <div className="flex gap-2 justify-center">
        <DefaultButton onClick={closePopup}>닫기</DefaultButton>
        <DefaultButton
          color="primary"
          onClick={() => handleClickSaveAndNext()}
          testId="save-and-next"
        >
          {propGroupId ? "수정" : "다음"}
        </DefaultButton>
      </div>
    </div>
  );
}
