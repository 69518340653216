import { SearchCondition } from "../components/ListPage";
import request, { ListResponse } from "../libs/request";
import { GeoLocationInfo } from "../types/geoLocation";

const LOG_API_PREFIX = "/v2/admin/log";

export const getGeoLocationInfoList = (params: SearchCondition<GeoLocationInfo>) =>
  request.get<ListResponse<GeoLocationInfo>>(LOG_API_PREFIX + "/location/list", { params });

export const postAccessProgramId = (programId: string) =>
  request.post<ListResponse<GeoLocationInfo>>(LOG_API_PREFIX + `/system-program/${programId}`);