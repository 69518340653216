import { HttpStatusCode } from "axios";
import { useContext, useEffect, useState } from "react";
import { useModal } from "../../../../../contexts/Modal";
import { PopupContext } from "../../../../../contexts/Popup";
import {
  validationFunctions,
  validationResultMessage,
} from "../../../../../libs/validations";
import {
  getGoodsRentalStock,
  putGoodsRentalStock,
} from "../../../../../services/goodsService";
import {
  GoodsRentalStock,
  GoodsRentalStockType,
  goodsRentalStockLabels,
} from "../../../../../types/goods";
import { ComboBoxType } from "../../../../../types/search";
import {
  USAGE_OPTIONS,
  BOOLEAN_TYPES,
  GOODS_HISTORY_TYPES,
} from "../../../../../types/comboBoxOption";
import DefaultButton from "../../../../DefaultButton";
import FormHeader from "../../../../Form/Header";
import FormRow from "../../../../Form/Row";
import FormRowLabel from "../../../../Form/RowLabel";
import DefaultInput from "../../../../Input/DefaultInput";
import DefaultLabel from "../../../../Input/DefaultLabel";
import RadioButtonGroup from "../../../../Input/RadioButtonGroup";
import Spinner from "../../../../Spinner";

const GOODS_RENTAL_STOCK_SERVICE_NAME = "대여";

type Props = { stockId: string };

export default function GoodsRentalStockDetailView({ stockId }: Props) {
  const { showAlert, showConfirm, handleError } = useModal();
  const { closePopup, refreshAndClosePopup } = useContext(PopupContext);

  const [goods, setGoods] = useState<Partial<GoodsRentalStock>>({});
  const [selectedStock, setSelectedStock] = useState(
    GOODS_HISTORY_TYPES.TOTAL_MODIFIED
  );
  const [stockCount, setStockCount] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const res = await getGoodsRentalStock(stockId);
        if (res.status === HttpStatusCode.Ok) {
          setGoods(res.data);
          setStockCount(res.data.availableCount?.toString());
        }
      } catch (err: any) {
        handleError(err, "조회");
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleGoodsChange =
    (key: keyof GoodsRentalStock) => (value: string | boolean | File) => {
      setGoods((prev) => ({ ...prev, [key]: value }));
    };

  const handleStockChange = (value: string) => {
    const newValue =
      selectedStock === GOODS_HISTORY_TYPES.RESTORED &&
      Number(value) > (goods.restorableCount ?? 0)
        ? goods.restorableCount?.toString() ?? ""
        : value;
    setStockCount(newValue);
  };

  const handleUpdate = () => {
    const errorMessage = validationResultMessage([
      validationFunctions.required(stockCount, "재고 변경"),
    ]);

    if (errorMessage) {
      showAlert(errorMessage);
    } else {
      showConfirm("수정 하시겠습니까?", updateData);
    }
  };

  const updateData = async () => {
    const keyName: keyof GoodsRentalStock =
      selectedStock === GOODS_HISTORY_TYPES.TOTAL_MODIFIED
        ? "stockCount"
        : "restoreCount";

    const data: Partial<GoodsRentalStock> = {
      useStock: goods.useStock,
      [keyName]: Number(stockCount),
      isActive: goods.isActive,
    };

    try {
      const res = await putGoodsRentalStock(stockId, data);
      if (res.status === HttpStatusCode.Ok) {
        showAlert("수정 되었습니다.");
        refreshAndClosePopup();
      }
    } catch (err: any) {
      handleError(err, "수정");
    }
  };

  const stockOptions = () => {
    const options: ComboBoxType[] = [
      {
        value: GOODS_HISTORY_TYPES.TOTAL_MODIFIED,
        label: `정상 재고 변경(현재 정상 재고 : ${
          goods.availableCount?.toLocaleString() ?? 0
        }개)`,
        disabled: isStockDisabled(),
      },
      {
        value: GOODS_HISTORY_TYPES.RESTORED,
        label: `분실/손상 복원(복원 가능 수 : ${
          goods.restorableCount?.toLocaleString() ?? 0
        }개)`,
        disabled: isStockDisabled(),
      },
    ];

    return options;
  };

  const isStockDisabled = () => {
    return !goods.useStock || !goods.isActive;
  };

  return (
    <div className="flex flex-col gap-5 min-w-[900px]">
      {isLoading && <Spinner />}
      <div className="flex flex-col max-h-[700px] overflow-y-auto">
        <FormHeader title="기본 정보" />
        <FormRow>
          <FormRowLabel title="물품 연결 번호">
            <DefaultLabel text={goods.stockId} />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <div className="flex w-full">
            <FormRowLabel title="등록 일시">
              <DefaultLabel text={goods.createdAt} />
            </FormRowLabel>
            <FormRowLabel title="최종 수정일시">
              <DefaultLabel text={goods.modifiedAt} />
            </FormRowLabel>
          </div>
        </FormRow>

        <FormHeader title="기준 정보" />
        <FormRow>
          <FormRowLabel title="서비스">
            <DefaultLabel text={GOODS_RENTAL_STOCK_SERVICE_NAME} />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="물품 대상">
            <DefaultLabel text={goods.buildingName} />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="사용 유무">
            <div className="flex flex-col gap-2">
              <RadioButtonGroup
                options={USAGE_OPTIONS}
                value={goods.isActive?.toString()}
                onChange={(value: string) => {
                  const isActive = value === BOOLEAN_TYPES.TRUE;
                  handleGoodsChange("isActive")(isActive);

                  if (goods.useStock) {
                    handleStockChange(
                      isActive ? goods.stockCount?.toString() ?? "0" : "0"
                    );
                  }
                }}
              />
              <DefaultLabel
                text="* 미사용으로 저장 시 재고가 0으로 변경됩니다."
                color="summary"
              />
            </div>
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="재고 사용 여부">
            <DefaultLabel text={goods.useStock ? "사용" : "미사용"} />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="물품 종류">
            <DefaultLabel
              text={
                goodsRentalStockLabels[
                  goods.goodsTypeId as GoodsRentalStockType
                ] ?? ""
              }
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="물품명">
            <DefaultLabel text={goods.goodsName} />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="재고 변경">
            <div className="flex flex-col gap-2">
              <RadioButtonGroup
                value={selectedStock}
                options={stockOptions()}
                onChange={(value: string) => {
                  setSelectedStock(value);

                  const stock =
                    value === GOODS_HISTORY_TYPES.TOTAL_MODIFIED
                      ? goods.availableCount
                      : goods.restorableCount;
                  setStockCount(stock?.toLocaleString() ?? "");
                }}
              />
              <div className="flex max-w-[200px]">
                <DefaultInput
                  type="number"
                  value={stockCount}
                  onChange={handleStockChange}
                  unitLabel="개"
                  placeholder="0"
                  disabled={isStockDisabled()}
                />
              </div>
            </div>
          </FormRowLabel>
        </FormRow>
      </div>

      <div className="flex justify-center gap-2">
        <DefaultButton onClick={closePopup}>닫기</DefaultButton>
        <DefaultButton color="primary" onClick={handleUpdate}>
          수정
        </DefaultButton>
      </div>
    </div>
  );
}
