import { useContext } from "react";
import { PopupContext } from "../../../../contexts/Popup";
import { CategoryData, CategoryReturnField } from "../../../../types/category";
import DefaultButton from "../../../DefaultButton";
import PostCreateForm from "../CreateForm";
import {
  findCategoryValueById,
  removeCategoryItemById,
} from "../../../../libs/category";
import { useUserContext } from "../../../../contexts/User";

type Props = { categoryData: CategoryData[]; refresh: () => void };

const CATEGORY_ID_TALK = 1;
const CATEGORY_ID_SANDI = 2;
const CATEGORY_ID_BUILDING = 3;
export const CATEGORY_ID_MENU = 31; // 건물 글 등록의 경우 메뉴옵션 제외

export default function PostListTopRight({ categoryData, refresh }: Props) {
  const { userInfo } = useUserContext();
  const { openPopup } = useContext(PopupContext);

  const renderSandi = () => {
    return (
      <DefaultButton
        size="tableTop"
        color="default"
        onClick={() =>
          openPopup({
            header: "샌디안내 글 등록",
            content: (
              <PostCreateForm
                categoryData={
                  findCategoryValueById(
                    CATEGORY_ID_SANDI,
                    categoryData,
                    CategoryReturnField.SUB_LIST
                  ) as CategoryData[]
                }
                postCategory="sandi"
                refresh={refresh}
              />
            ),
          })
        }
      >
        샌디안내 글 등록
      </DefaultButton>
    );
  };

  const renderMultiBuilding = () => {
    return (
      <DefaultButton
        size="tableTop"
        color="default"
        onClick={() =>
          openPopup({
            header: "다중 건물 게시글 등록",
            content: (
              <PostCreateForm
                categoryData={removeCategoryItemById(
                  findCategoryValueById(
                    CATEGORY_ID_BUILDING,
                    categoryData,
                    CategoryReturnField.SUB_LIST
                  ) as CategoryData[],
                  CATEGORY_ID_MENU
                )}
                postCategory="multi"
                refresh={refresh}
              />
            ),
          })
        }
      >
        다중 건물 게시글 등록
      </DefaultButton>
    );
  };

  const renderMenu = () => {
    return (
      <DefaultButton
        size="tableTop"
        color="default"
        onClick={() =>
          openPopup({
            header: "메뉴 글 등록",
            content: <PostCreateForm postCategory="menu" refresh={refresh} />,
          })
        }
      >
        메뉴 글 등록
      </DefaultButton>
    );
  };

  const renderTalk = () => {
    return (
      <DefaultButton
        size="tableTop"
        color="default"
        onClick={() =>
          openPopup({
            header: "토크 글 등록",
            content: (
              <PostCreateForm
                categoryData={
                  findCategoryValueById(
                    CATEGORY_ID_TALK,
                    categoryData,
                    CategoryReturnField.SUB_LIST
                  ) as CategoryData[]
                }
                postCategory="talk"
                refresh={refresh}
              />
            ),
          })
        }
      >
        토크 글 등록
      </DefaultButton>
    );
  };

  const renderBuilding = () => {
    return (
      <DefaultButton
        size="tableTop"
        color="default"
        onClick={() =>
          openPopup({
            header: "건물 글 등록",
            content: (
              <PostCreateForm
                categoryData={removeCategoryItemById(
                  findCategoryValueById(
                    CATEGORY_ID_BUILDING,
                    categoryData,
                    CategoryReturnField.SUB_LIST
                  ) as CategoryData[],
                  CATEGORY_ID_MENU
                )}
                postCategory="building"
                refresh={refresh}
              />
            ),
          })
        }
      >
        건물 글 등록
      </DefaultButton>
    );
  };

  const renderButtonsBasedOnRole = () => {
    const commonRender = (
      <>
        {renderMenu()}
        {renderBuilding()}
      </>
    );

    if (userInfo?.role.includes("ROLE_CAFETERIA")) {
      return commonRender;
    } else if (userInfo?.role.includes("ROLE_ADMIN")) {
      return (
        <>
          {renderMultiBuilding()}
          {renderSandi()}
          {renderTalk()}
          {commonRender}
        </>
      );
    }
    return null;
  };

  return <>{renderButtonsBasedOnRole()}</>;
}
