import { PopupProvider } from "@/contexts/Popup";
import { Outlet } from "react-router-dom";

type Props = {};

export default function DashboardLayout(props: Props) {
  return (
    <PopupProvider>
      <Outlet />
    </PopupProvider>
  );
}
