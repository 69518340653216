export type AtgBuilding = {
  buildingId: string;
  buildingName: string;
  address: string;
  ownerName: string;
  latitude: string;
  longitude: string;
  gridX: string;
  gridY: string;
  atgGroupId: string;
  departmentName: string;
  businessPlaceName: string;
  vocManagementUnit: string;
  atgBuildingId: string;
  helpDeskTel: string;
  bldgGroupLevelCode: string;
  upperBldgGroupId: string;
  dispSeq: string;
  memberId: string;
  attchFileSn: string;
  attchFileNm: string;
  attchFileString: string;
  attchFileNameString: string;
};

export type AtgResource = {
  materialName: string;
  resourceName: string;
  standardName: string;
  centerCode: string;
  materialCode: string;
  inventory: string;
  centerName: string;
  storageCode: string;
  storageName: string;
  communicationResult: string;
  communicationResultMessage: string;
};

export type AtgWorker = {
  workerName: string;
  job: string;
};

export type AtgFloor = {
  floorName: string;
  isActive: string;
  floorCode: string;
};

export const atgFloorActiveValue = {
  TRUE: "Y",
  FALSE: "N",
};
