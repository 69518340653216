import { HttpStatusCode } from "axios";
import { useContext, useEffect, useState } from "react";
import { useModal } from "../../../../../contexts/Modal";
import { PopupContext } from "../../../../../contexts/Popup";
import {
  validationFunctions,
  validationResultMessage,
} from "../../../../../libs/validations";
import {
  getServiceGroup,
  getServiceGroupExists,
  putServiceGroup,
} from "../../../../../services/ServiceGroupService";
import { Building } from "../../../../../types/building";
import { ComboBoxType } from "../../../../../types/search";
import { ServiceGroup, ServiceGroupDto } from "../../../../../types/service";
import DefaultButton from "../../../../DefaultButton";
import FormHeader from "../../../../Form/Header";
import FormRow from "../../../../Form/Row";
import FormRowLabel from "../../../../Form/RowLabel";
import DefaultInput from "../../../../Input/DefaultInput";
import DefaultLabel from "../../../../Input/DefaultLabel";
import DefaultSelect from "../../../../SelectBox/DefaultSelect";
import BuildingMultiSelectTable from "../../../../SelectTable/MultiSelectTable/BuildingMultiSelectTable";
import Spinner from "../../../../Spinner";

type Props = { groupId: string; groupName: string; category: ComboBoxType[] };

export default function ServiceGroupDetailView({
  groupId,
  groupName,
  category,
}: Props) {
  const { showAlert, showConfirm, handleError } = useModal();
  const { refreshAndClosePopup, closePopup } = useContext(PopupContext);
  const [isLoading, setIsLoading] = useState(false);
  const [serviceGroup, setServiceGroup] = useState<Partial<ServiceGroup>>({});
  const [selectedBuilding, setSelectedBuilding] = useState<Building[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        const response = await getServiceGroup(groupId);
        if (response.status === HttpStatusCode.Ok) {
          const list = response.data.items.map((item) => ({
            buildingId: item.itemId,
            buildingName: item.itemName,
          })) as Building[];

          setServiceGroup(response.data);
          setSelectedBuilding(list);
        }
      } catch (err: any) {
        handleError(err, "조회");
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleChange = (key: keyof ServiceGroup) => (value: string) => {
    setServiceGroup((prev) => ({ ...prev, [key]: value }));
  };

  const handleUpdate = async () => {
    const items = selectedBuilding.map((item) => item.buildingId);
    const errorMessage = validationResultMessage([
      validationFunctions.required(serviceGroup.categoryCode, "카테고리"),
      validationFunctions.required(serviceGroup.groupName, "서비스그룹 이름"),
    ]);

    if (errorMessage) {
      showAlert(errorMessage);
    } else {
      const isExists =
        groupName !== serviceGroup.groupName
          ? await checkGroupExists(serviceGroup.groupName ?? "")
          : false;
      if (isExists) {
        showAlert("서비스 그룹 중복입니다.");
      } else {
        showConfirm("수정 하시겠습니까?", () => updateData(items));
      }
    }
  };

  const checkGroupExists = async (groupName: string) => {
    try {
      setIsLoading(true);

      const response = await getServiceGroupExists(groupName);
      if (response.status === HttpStatusCode.Ok) {
        return response.data;
      } else {
        throw new Error("Failed to valid serviceGroupName");
      }
    } catch (err: any) {
      handleError(err, "서비스 그룹 중복 체크");
    } finally {
      setIsLoading(false);
    }
    return false;
  };

  const updateData = async (items: string[]) => {
    try {
      const response = await putServiceGroup(groupId, createData(items));
      if (response.status === HttpStatusCode.Ok) {
        showAlert("수정 되었습니다.");
        refreshAndClosePopup();
      }
    } catch (err: any) {
      handleError(err, "수정");
    }
  };

  const createData = (items: string[]) => {
    const data: Partial<ServiceGroupDto> = {
      groupName: serviceGroup.groupName,
      categoryCode: serviceGroup.categoryCode,
      items,
    };
    return data;
  };

  return (
    <div className="flex flex-col gap-5">
      <div className="flex flex-col min-w-[900px] max-h-[700px] overflow-y-auto">
        {isLoading && <Spinner />}
        <FormHeader title="기본 정보" />
        <FormRow>
          <FormRowLabel title="서비스그룹 ID">
            <DefaultLabel text={serviceGroup.groupId} />
          </FormRowLabel>
          <FormRowLabel title="생성일">
            <DefaultLabel text={serviceGroup.createdAt} />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="서비스그룹 이름">
            <DefaultInput
              value={serviceGroup.groupName}
              onChange={handleChange("groupName")}
              placeholder="서비스그룹 이름을 입력해주세요"
            />
          </FormRowLabel>
          <FormRowLabel title="최종 변경일">
            <DefaultLabel text={serviceGroup.modifiedAt} />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="서비스 카테고리">
            <DefaultSelect
              value={serviceGroup.categoryCode}
              onChange={handleChange("categoryCode")}
              optionList={category}
              placeholder="신청 서비스 선택"
              placeholderDisabled
            />
          </FormRowLabel>
        </FormRow>

        <FormHeader title="연결건물" />
        <FormRow>
          <BuildingMultiSelectTable
            selectedData={selectedBuilding}
            setSelectedData={setSelectedBuilding}
          />
        </FormRow>
      </div>
      <div className="flex gap-2 justify-center">
        <DefaultButton onClick={closePopup}>닫기</DefaultButton>
        <DefaultButton color="primary" onClick={handleUpdate}>
          수정
        </DefaultButton>
      </div>
    </div>
  );
}
