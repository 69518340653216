import React from "react";

import {
  MemberInfoAccessInformation,
  getMemberInfoAccessList,
} from "../../../../services/memberService";

import ListPage from "../../../../components/ListPage";
import { TableColumnType } from "../../../../components/Table";

type Props = {};

export default function MaskingList(props: Props) {
  const columnInfo: TableColumnType<MemberInfoAccessInformation>[] = [
    // { header: "No.", defaultNo: true, width: "no" },
    { header: "아이디", name: "logId" },
    { header: "유저 아이디", name: "memberId" },
    { name: "type", header: "타입" },
    { name: "remoteIp4", header: "접속 아이피" },
    { name: "createdAt", header: "접속 일시", width: "date" },
    { name: "reason", header: "사유" },
    { name: "accessProperty", header: "접속 속성" },
    {
      name: "accessTarget",
      header: "접속 대상",
      render: (item) => (
        <div className="w-full flex justify-center items-center">
          <div className="w-[150px] truncate text-center">
            {item.accessTarget}
          </div>
        </div>
      ),
    },

    // { header: "제목", name: "title" },
    // { header: "최초 등록일시", name: "createdAt", width: "date" },
    // { header: "최초 등록자", name: "createdByName" },
  ];

  return (
    <ListPage
      getDataApi={getMemberInfoAccessList}
      keyId={(item: MemberInfoAccessInformation) => item.logId}
      columnInfo={columnInfo}
      tableTitle="회원 정보 접근 이력 목록"
      defaultPageSize={50}
      defaultPageSizeList={[50, 100, 300]}
    />
  );
}
