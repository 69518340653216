import { SearchCondition } from "../components/ListPage";
import request from "../libs/request";
import { downloadExcel } from "../utils/fileUtils";
import { formatSortSearchCondition } from "../utils/formatUtils";

export const getExcelDownloadApi = <T>(
  url: string,
  params: SearchCondition<T>
) =>
  request
    .get(url, {
      responseType: "blob",
      params: formatSortSearchCondition(params),
    })
    .then(downloadExcel);
