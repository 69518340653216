import CommonRadio from "@/components/Input/CommonRadio";
import { TableColumnType } from "@/components/Table";
import { useModal } from "@/contexts/Modal";
import { EmergencyBuildingGroup } from "@/types/emergency";
import { useEffect, useState, useMemo } from "react";
import { ComponentType } from "../../../types/search";
import { getEmergencyBuildingGroupList } from "@/services/emergencyService";
import TagSingleSelector from "@/components/TagSelector/TagSingleSelector";
import DefaultButton from "@/components/DefaultButton";
import DefaultInput from "@/components/Input/DefaultInput";
import CommonPopup from "@/components/Popup/CommonPopup";
import { SearchCondition } from "@/components/ListPage";

type Props = {
  selectType: "EmergencyBuilding";
  selectedItem?: EmergencyBuildingGroup;
  onChangeEmergencyBuilding?: (item: EmergencyBuildingGroup) => void;
};

export default function TagEmergencyFilter({
  selectType,
  selectedItem,
  onChangeEmergencyBuilding,
}: Props) {
  const { showAlert, showConfirm } = useModal();
  const [isOpen, setIsOpen] = useState(false);
  const [checkedOption, setCheckedOption] = useState<
    EmergencyBuildingGroup | undefined
  >(selectedItem);

  useEffect(() => {
    setCheckedOption(selectedItem);
  }, [selectedItem]);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClosePopup = () => {
    setIsOpen(false);
    setCheckedOption(selectedItem);
  };

  const handleCheckedChange = (item: EmergencyBuildingGroup) => {
    if (!item.isCompleteDefaultShelter) {
      showAlert("기본대피영역이 지정된 그룹을 선택해주세요");
      return;
    }
    setCheckedOption(item);
  };

  const handleApply = () => {
    if (!checkedOption) {
      showAlert("선택된 항목이 없습니다.");
      return;
    }

    showConfirm("적용 하시겠습니까?", async () => {
      if (selectType === "EmergencyBuilding" && onChangeEmergencyBuilding) {
        onChangeEmergencyBuilding(checkedOption as EmergencyBuildingGroup);
      } else {
        console.error("EmergencyBuilding type error");
      }
      handleClosePopup();
    });
  };

  const isEqual = (
    checkedItem: EmergencyBuildingGroup | undefined,
    currentItem: EmergencyBuildingGroup
  ) => {
    if (!checkedItem) {
      return false;
    }
    return checkedItem.evacuationGroupId === currentItem.evacuationGroupId;
  };

  const EmergencyBuildingColumnInfo: TableColumnType<EmergencyBuildingGroup>[] =
    [
      {
        header: "선택",
        render: (item: EmergencyBuildingGroup) => {
          return (
            <div className="flex justify-center items-center min-w-[40px]">
              <CommonRadio
                checked={isEqual(checkedOption, item)}
                onChange={() => {
                  handleCheckedChange(item);
                }}
                value={item.evacuationGroupId}
                dataTestId="building"
              />
            </div>
          );
        },
      },
      { header: "비상대피 그룹 ID", name: "evacuationGroupId" },
      { header: "비상대피 그룹명", name: "evacuationGroupName" },
      {
        header: "대피영역 지정여부",
        render: (item: EmergencyBuildingGroup) => {
          return item.isCompleteDefaultShelter ? "완료" : "미완료";
        },
      },
    ];

  const emergencyBuildingComponentList: ComponentType<EmergencyBuildingGroup>[] =
    [
      {
        labelName: "비상대피 그룹명",
        typeName: "text",
        keyName: "evacuationGroupName",
        category: "detailSearch",
        placeholder: "그룹명을 입력해주세요",
      },
    ];

  const getEmergencyBuildingListWrapper = useMemo(
    () => async (params: SearchCondition<EmergencyBuildingGroup>) => {
      return getEmergencyBuildingGroupList({
        ...params,
      });
    },
    []
  );

  const render = () => {
    const title = "비상대피 그룹 선택";
    return (
      <>
        <TagSingleSelector
          getDataApi={getEmergencyBuildingListWrapper}
          componentList={emergencyBuildingComponentList}
          columnInfo={EmergencyBuildingColumnInfo}
          title={title}
          closePopup={handleClosePopup}
          handleApply={handleApply}
          selectType="emergencyBuilding"
        />
      </>
    );
  };

  return (
    <div className="flex gap-2 items-center">
      <DefaultInput
        placeholder={"비상대피 건물을(를) 선택해주세요"}
        value={
          (selectedItem as EmergencyBuildingGroup)?.evacuationGroupName ?? ""
        }
        disabled
      />
      <div className="min-w-fit">
        <DefaultButton
          size="tableButton"
          className="h-[38px]"
          onClick={handleOpen}
          testId={`search-${selectType}-tag-single-filter`}
        >
          검색
        </DefaultButton>
      </div>

      {isOpen && (
        <CommonPopup
          header={"비상대피 그룹 선택"}
          content={render()}
          isOpen={isOpen}
          closePopup={handleClosePopup}
        />
      )}
    </div>
  );
}
