import request from "../libs/request";
import { AtgFloor, AtgResource, AtgWorker } from "../types/atg";

const ATG_API_PREFIX = "/v2/admin/AtG";

export const getAtgWorker = (atgBuildingId: string, workerName: string) => {
  return request.get<AtgWorker[]>(ATG_API_PREFIX + "/worker", {
    params: { atgBuildingId, workerName },
  });
};

export const getAtgRetire = () => {
  return request.get(ATG_API_PREFIX + "/retire");
};

export const getAtgResource = (atgBuildingId: string, resourceName: string) =>
  request.get<AtgResource[]>(ATG_API_PREFIX + "/resource", {
    params: { atgBuildingId, resourceName },
  });

export const getAtgManagerMember = (memberName: string, phone: string) => {
  return request.get(ATG_API_PREFIX + "/managerMember", {
    params: { memberName, phone },
  });
};

export const getAtgFloor = (atgBuildingId: string) => {
  return request.get<AtgFloor[]>(ATG_API_PREFIX + "/floor", {
    params: { atgBuildingId },
  });
};

export const getAtgBuildingInfo = (departmentName: string) => {
  return request.get(ATG_API_PREFIX + "/buildingInfo", {
    params: { departmentName },
  });
};
