import React, { useEffect, useState } from "react";
import { HttpStatusCode } from "axios";

import { MINUTE_UNIT, SECOND_UNIT } from "../../../utils/dateUtils";

import { getTokenExpirationDate } from "../../../libs/jwt";
import { setLocalStorage } from "../../../libs/localStorage";

import { useModal } from "../../../contexts/Modal";

import {
  TokenOnlyData,
  getTokenByRefresh,
  logoutUser,
} from "../../../services/userServices";

import SimpleModal from "../../Modal/SimpleModal";

type Props = {
  originToken?: TokenOnlyData;
  printRemainTime?: boolean;
  onChangeToken?: () => void;
};

export default function SessionExtendPopup({
  originToken,
  onChangeToken,
  printRemainTime = false,
}: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const [timeLeft, setTimeLeft] = useState<number | null>(null);
  const [token, setToken] = useState<TokenOnlyData | undefined>(originToken);
  const { showAlert } = useModal();

  const checkTime =
    (refreshExpirationDate: Date, accessTokenExpirationDate: Date) => () => {
      const now = new Date();
      const loginExtendableTime =
        refreshExpirationDate.getTime() - accessTokenExpirationDate.getTime();

      const timeDifference = refreshExpirationDate.getTime() - now.getTime();
      setTimeLeft(timeDifference);

      if (timeDifference < 0) {
        logoutUser();
      } else {
        // NOTHING TO DO S
      }

      if (timeDifference <= loginExtendableTime && timeDifference > 0) {
        setIsOpen(true);
      } else {
        setIsOpen(false);
      }
    };

  const handleExtendSession = async () => {
    try {
      if (!token) {
        throw new Error("Token is not found");
      }
      const response = await getTokenByRefresh(token.refreshToken);

      if (response.status === HttpStatusCode.Ok) {
        setLocalStorage(
          "jwtToken",
          JSON.stringify({
            grantType: response.data.grantType,
            accessToken: response.data.accessToken,
            refreshToken: response.data.refreshToken,
          })
        );
        onChangeToken?.();
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }
    } catch (e) {
      showAlert(
        "이미 로그아웃한 계정입니다.",
        async () => {
          logoutUser();
          setIsOpen(false);
        },
        "로그인으로 이동",
        "알림"
      );
    }
  };

  useEffect(() => {
    let intervalId: NodeJS.Timer | null = null;

    if (token) {
      const refreshExpirationDate = getTokenExpirationDate(token.refreshToken);
      const accessTokenExpirationDate = getTokenExpirationDate(
        token.accessToken
      );
      if (!refreshExpirationDate || !accessTokenExpirationDate) {
        return;
      }

      intervalId = setInterval(
        checkTime(refreshExpirationDate, accessTokenExpirationDate),
        SECOND_UNIT
      );
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [token]);

  useEffect(() => {
    if (
      originToken?.accessToken !== token?.accessToken ||
      originToken?.refreshToken !== token?.refreshToken
    ) {
      setToken(originToken);
    }
  }, [originToken]);

  if (!token) return <></>;
  return (
    <div>
      {printRemainTime &&
        `${Math.floor(
          (timeLeft || 0) / SECOND_UNIT / MINUTE_UNIT
        )}:${Math.floor(((timeLeft || 0) / SECOND_UNIT) % MINUTE_UNIT)
          .toString()
          .padStart(2, "0")}`}
      <SimpleModal
        header="자동 로그아웃 안내"
        content={
          <div className="flex flex-col items-center w-full gap-2.5">
            <div>자동 로그아웃 예정입니다.</div>
            <div className="bg-neutral-100 py-2.5 px-5 flex gap-1">
              남은 시간 :{" "}
              <p className="font-bold text-brand-primary-magenta">{`${Math.floor(
                (timeLeft || 0) / SECOND_UNIT / MINUTE_UNIT
              )}:${Math.floor(((timeLeft || 0) / SECOND_UNIT) % MINUTE_UNIT)
                .toString()
                .padStart(2, "0")}
            `}</p>
            </div>
            <div className="flex flex-col items-center">
              <div>로그인한지 1시간 이후 자동 로그아웃 됩니다.</div>
              <div className="flex gap-1">
                계속 사용하시려면{" "}
                <p className="text-brand-primary-gray-100">[로그인 연장]</p>
                버튼을 눌러주세요.
              </div>
            </div>
          </div>
        }
        isOpen={isOpen}
        onConfirm={handleExtendSession}
        onCancel={() => logoutUser()}
        confirmLabel="로그인 연장"
        cancelLabel="로그아웃"
      />
    </div>
  );
}
