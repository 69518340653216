import { ReactNode, createContext, useContext, useReducer } from "react";

type ModalType = "alert" | "confirm";

type Modal = {
  id: string;
  type: ModalType;
  title?: ReactNode;
  message: ReactNode;
  onConfirm?: () => Promise<void>;
  onClose?: () => void;
  confirmLabel?: string;
  closeLabel?: string;
};

export interface ModalContextProps {
  modals: Modal[];
  showAlert: (
    message: ReactNode,
    onConfirm?: () => Promise<void>,
    confirmLabel?: string,
    title?: ReactNode
  ) => void;
  showConfirm: (
    message: ReactNode,
    onConfirm: () => Promise<void>,
    confirmLabel?: string,
    closeLabel?: string,
    title?: ReactNode
  ) => void;
  handleError: (error: any, customMessage: string) => void;
  closeModal: (id: string) => void;
}

const ModalContext = createContext<ModalContextProps | undefined>(undefined);

export function useModal() {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error("useModal must be used within a ModalProvider");
  }
  return context;
}

const CONFIRM_TITLE_DEFAULT_TEXT = "확인";
const ALERT_TITLE_DEFAULT_TEXT = "알림";
const CONFIRM_DEFAULT_TEXT = "확인";
const CLOSE_DEFAULT_TEXT = "취소";

type Props = { children: React.ReactNode };

type ModalAction =
  | { type: "ADD_MODAL"; modal: Modal }
  | { type: "REMOVE_MODAL"; id: string };

// TODO: 중복된 메시지로 modal이 출력되는 경우가 있어, 중복된 메시지가 들어오는 경우 체크해서 Modal 출력되지 않도록 처리. 추후 modal 수정 필요.
const modalReducer = (state: Modal[], action: ModalAction): Modal[] => {
  switch (action.type) {
    case "ADD_MODAL":
      const isExist = state.some(
        (modal) =>
          typeof modal.message === "string" &&
          modal.message === action.modal.message
      );
      if (isExist) {
        return state;
      }
      return [...state, action.modal];
    case "REMOVE_MODAL":
      return state.filter((modal) => modal.id !== action.id);
    default:
      throw new Error("Unhandled action type");
  }
};

export const ModalProvider = ({ children }: Props) => {
  const [modals, dispatch] = useReducer(modalReducer, []);

  const handleError = (error: any, customMessage: string) => {
    const message = `${customMessage} 중 오류가 발생했습니다.`;

    if (error.response?.data?.error) {
      showAlert(error.response.data.error);
    } else {
      showAlert(message);
    }
  };

  const showAlert = (
    message: ReactNode,
    onConfirm?: () => Promise<void>,
    confirmLabel = CONFIRM_DEFAULT_TEXT,
    title: ReactNode = ALERT_TITLE_DEFAULT_TEXT
  ) => {
    const transformedMessage = transMessage(message);
    const id = new Date().getTime().toString();
    dispatch({
      type: "ADD_MODAL",
      modal: {
        id,
        type: "alert",
        title,
        message: transformedMessage,
        confirmLabel,
        onConfirm,
      },
    });
  };

  const showConfirm = (
    message: ReactNode,
    onConfirm: () => Promise<void>,
    confirmLabel = CONFIRM_DEFAULT_TEXT,
    closeLabel = CLOSE_DEFAULT_TEXT,
    title: ReactNode = CONFIRM_TITLE_DEFAULT_TEXT
  ) => {
    const transformedMessage = transMessage(message);
    const id = new Date().getTime().toString();
    dispatch({
      type: "ADD_MODAL",
      modal: {
        id,
        type: "confirm",
        title,
        message: transformedMessage,
        onConfirm,
        confirmLabel,
        closeLabel,
      },
    });
  };

  // TODO: error.response.data.error로 출력 시 Unauthorized로 출력되던 문구 치환. 추후 토큰 관련 에러처리 수정될 시 추가작업 필요.
  const transMessage = (message: ReactNode) => {
    if (typeof message === "string") {
      if (message === "Unauthorized")
        return "일정 시간이 지나 로그아웃 되었습니다. \n다시 로그인 해주세요.";
    }
    return message;
  };

  const closeModal = (id: string) => {
    dispatch({ type: "REMOVE_MODAL", id });
  };

  return (
    <ModalContext.Provider
      value={{ modals, showAlert, showConfirm, closeModal, handleError }}
    >
      {children}
    </ModalContext.Provider>
  );
};
