import { useState } from "react";
import Tab, { TabInfo } from "../../../components/Tab";
import Air from "./Air";
import Cleaning from "./Cleaning";
import Compartment from "./Compartment";
import InOut from "./InOut";

type Props = {};

export default function IotStatus(prop: Props) {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (tabIndex: number) => {
    setActiveTab(tabIndex);
  };

  const tabInfo: TabInfo[] = [
    { title: "화장실 : 출입문", page: <InOut /> },
    { title: "화장실 : 칸", page: <Compartment /> },
    { title: "화장실 : 미화점검", page: <Cleaning /> },
    { title: "공기질 모니터링", page: <Air /> },
  ];

  return (
    <Tab tabInfo={tabInfo} activeTab={activeTab} onTabClick={handleTabClick} />
  );
}
