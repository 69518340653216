import { useState } from "react";
import { useModal } from "../../contexts/Modal";
import { MemberSelect } from "../../types/member";
import { getMemberExistence } from "../../services/memberService";
import { HttpStatusCode } from "axios";
import {
  validationFunctions,
  validationResultMessage,
} from "../../libs/validations";
import { useUserContext } from "../../contexts/User";
import RadioButtonGroup from "../../components/Input/RadioButtonGroup";
import Spinner from "../../components/Spinner";
import { ComboBoxType } from "../../types/search";
import DefaultButton from "../../components/DefaultButton";
import DefaultInput from "../../components/Input/DefaultInput";

const choiceMemberOptions: ComboBoxType[] = [
  { value: "true", label: "신청자와 동일" },
  { value: "false", label: "대신 예약" },
];

export default function useMemberSelector(): [
  {
    member: Partial<MemberSelect>;
    isSelf: boolean;
    isConfirm: boolean;
  },
  JSX.Element
] {
  const { showAlert, handleError } = useModal();
  const { userInfo } = useUserContext();

  const [member, setMember] = useState<Partial<MemberSelect>>({
    memberId: userInfo?.memberId ?? 0,
    name: userInfo?.name ?? "",
    mobileNumber: userInfo?.mobileNumber ?? "",
  });
  const [selectedRadio, setSelectedRadio] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirm, setIsConfirm] = useState(true);
  const [name, setName] = useState(userInfo?.name ?? "");
  const [tel, setTel] = useState(userInfo?.mobileNumber ?? "");
  const [isSelf, setIsSelf] = useState(true);

  const handleChange = (data: Partial<MemberSelect>) => {
    setMember(data);
  };

  const getMember = async () => {
    try {
      setIsLoading(true);

      const response = await getMemberExistence(name, tel);
      if (response.status === HttpStatusCode.Ok) {
        if (response.data.existence) {
          handleChange(response.data);
          setIsConfirm(true);
        } else {
          showAlert("샌디 회원이 아닙니다.");
        }
      } else {
        throw new Error("Failed to load member info");
      }
    } catch (err: any) {
      handleError(err, "회원 연락처 조회");
    } finally {
      setIsLoading(false);
    }
  };

  const handleConfirmClick = async () => {
    const errorMessage = validationResultMessage([
      validationFunctions.required(name, "이름"),
      validationFunctions.required(tel, "연락처"),
    ]);

    if (errorMessage) {
      showAlert(errorMessage);
    } else {
      getMember();
    }
  };

  const handleRetryClick = () => {
    setIsConfirm(false);
  };

  const handleRadioChange = (value: string) => {
    const isSelf = value === "true";
    if (isSelf) {
      setName(userInfo?.name ?? "");
      setTel(userInfo?.mobileNumber ?? "");
    } else {
      setName("");
      setTel("");
    }
    setIsConfirm(isSelf);
    setSelectedRadio(isSelf);
    setIsSelf(isSelf);

    const data: MemberSelect = {
      memberId: isSelf ? userInfo?.memberId ?? 0 : 0,
      name: isSelf ? userInfo?.name ?? "" : name,
      mobileNumber: isSelf ? userInfo?.mobileNumber ?? "" : tel,
      existence: true,
    };

    handleChange(data);
  };

  const renderButton = () => {
    if (selectedRadio) {
      return null;
    }

    if (isConfirm) {
      return (
        <DefaultButton size="popupDefault" onClick={handleRetryClick}>
          다시 입력
        </DefaultButton>
      );
    } else {
      return (
        <DefaultButton size="popupDefault" onClick={handleConfirmClick}>
          회원 연락처 조회
        </DefaultButton>
      );
    }
  };

  return [
    { member, isSelf, isConfirm },
    <div className="flex flex-col gap-2">
      {isLoading && <Spinner />}
      <RadioButtonGroup
        value={selectedRadio.toString()}
        options={choiceMemberOptions}
        onChange={handleRadioChange}
      />
      <div className="flex gap-2 items-end">
        <DefaultInput
          className="text-sm"
          value={name}
          onChange={setName}
          label="이름"
          placeholder="실사용자 이름 입력"
          disabled={selectedRadio || isConfirm}
        />
        <DefaultInput
          className="text-sm"
          value={tel}
          type="tel"
          onChange={setTel}
          label="연락처"
          placeholder="실사용자 연락처 입력"
          disabled={selectedRadio || isConfirm}
        />
        <div className="min-w-fit">{renderButton()}</div>
      </div>
    </div>,
  ];
}
