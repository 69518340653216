import { HttpStatusCode } from "axios";
import { useModal } from "../../../../contexts/Modal";
import { Batch } from "../../../../types/system";
import {
  getBatchList,
  getBatchListExcel,
  postBatch,
} from "../../../../services/systemService";
import DefaultButton from "../../../../components/DefaultButton";
import ListPage from "../../../../components/ListPage";
import { TableColumnType } from "../../../../components/Table";

type Props = {};

export default function BatchList(props: Props) {
  const { showAlert, showConfirm, handleError } = useModal();

  const columnInfo: TableColumnType<Batch>[] = [
    { header: "No.", defaultNo: true, width: "no" },
    { header: "상태", name: "triggerState" },
    { header: "스케줄러명", name: "schedName" },
    { header: "배치명", name: "jobName" },
    { header: "배치그룹", name: "jobGroup" },
    { header: "배치설명", name: "description" },
    { header: "최근 실행 일시", name: "prevFireTime", width: "date" },
    { header: "다음 실행 일시", name: "nextFireTime", width: "date" },
    {
      header: "활동",
      render: (item: Batch) => (
        <div className="w-full h-full flex gap-2 justify-center items-center">
          <DefaultButton
            size="tableButton"
            color="blue"
            onClick={() => {
              showConfirm("배치를 실행하시겠습니까?", () =>
                handleExecuteBatch(item.jobGroup, item.jobName)
              );
            }}
          >
            수동실행
          </DefaultButton>
        </div>
      ),
      width: "button",
    },
  ];

  const handleExecuteBatch = async (jobGroup: string, jobName: string) => {
    try {
      const response = await postBatch(jobGroup, jobName);

      if (response.status === HttpStatusCode.Ok) {
        showAlert("배치가 실행되었습니다.");
      }
    } catch (err: any) {
      handleError(err, "배치 수동 실행");
    }
  };

  return (
    <ListPage
      getDataApi={getBatchList}
      excelDownloadApi={getBatchListExcel}
      keyId={(item: Batch) => `${item.jobName}_${item.jobClassName}`}
      columnInfo={columnInfo}
      tableTitle="목록"
      hidePageSize
      hidePagination
    />
  );
}

export const BatchListRightInfo = () => (
  <div className="text-sm pl-4 h-[36px]">
    <span>
      SANDI APP 캘린더 : 공공데이터 포털에서 공휴일, 기념일 등 정보조회를 사용
    </span>
    <br />
    <span>캘린더 URL : </span>
    <a
      href="https://www.data.go.kr/data/15012690/openapi.do#/tab_layer_detail_function"
      target="_blank"
      rel="noopener noreferrer"
      className="underline text-blue-600"
    >
      https://www.data.go.kr/data/15012690/openapi.do#/tab_layer_detail_function
    </a>
  </div>
);
