import clsx from "clsx";
import { useState } from "react";

type Props = {
  options: string[];
  onChange: (value: string) => void;
};

export default function TextToggleButton({ options = [], onChange }: Props) {
  const [selectedOption, setSelectedOption] = useState(options[0]);

  const handleOptionChange = (option: string) => {
    setSelectedOption(option);
    onChange(option);
  };

  return (
    <div className="flex">
      {options.map((option, index) => (
        <button
          key={index}
          className={clsx([
            "h-[38px] w-[60px] text-label",
            {
              "text-white bg-brand-primary-gray-100": option === selectedOption,
              "text-brand-text-black-disabled border border-brand-primary-gray-200 rounded-none":
                option !== selectedOption,
            },
          ])}
          onClick={() => handleOptionChange(option)}
        >
          {option}
        </button>
      ))}
    </div>
  );
}
