import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { RouteInfo } from "../../libs/routes";
import LowerMenu, { LowerMenuLeafPrefix } from "../LowerMenu";
import clsx from "clsx";

type Props = { route: RouteInfo };

export default function UpperMenu({ route }: Props) {
  const isLeaf = !route.children;
  const location = useLocation();
  const [open, setOpen] = useState<Boolean>(
    isLeaf || (route.path && location.pathname.includes(`/${route.path}`))
      ? true
      : false
  );
  const navigate = useNavigate();

  return (
    <>
      <div
        className={`flex gap-4 px-[25px] p-4 text-sm font-medium cursor-pointer text-white transition-all h-[40px]`}
        onClick={() => {
          if (isLeaf) {
            navigate(`/${route.path ?? ""}`);
          } else {
            setOpen((prev) => !prev);
          }
        }}
      >
        <div className="flex justify-between items-center w-full">
          <div className="flex gap-4 items-center">
            {route.icon}
            <span>{route.title}</span>
          </div>
          <div>
            {!isLeaf && (
              <div
                className={clsx([
                  "transition-transform",
                  { "rotate-180 ": open },
                ])}
              >
                <UpperMenuDownArrow />
              </div>
            )}
          </div>
        </div>
      </div>
      {!isLeaf && (
        <div className={`${open ? "visible" : "hidden"}`}>
          {route.children?.map((child, index) => {
            if (child.programId) {
              return (
                <LowerMenu
                  key={index}
                  route={child}
                  parentPath={`/${route.path}`}
                  currentPath={location.pathname}
                  prefix={<LowerMenuLeafPrefix />}
                />
              );
            } else {
              return null;
            }
          })}
        </div>
      )}
    </>
  );
}

export const UpperMenuDownArrow = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M2.922 5.74609L7.99982 10.8239L13.0776 5.74609" fill="#868687" />
  </svg>
);
