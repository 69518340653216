import { FiEdit2 } from "react-icons/fi";
import DefaultButton from "../../../components/DefaultButton";
import ListPage from "../../../components/ListPage";
import { TableColumnType } from "../../../components/Table";
import {
  GoodsHistoryType,
  GoodsRentalStockHistory,
  GoodsRentalStockType,
  goodsHistoryLabels,
  goodsRentalStockLabels,
} from "../../../types/goods";
import { ComboBoxType, ComponentType, DASH } from "../../../types/search";
import { addDefaultOption, toComboBoxType } from "../../../utils/comboBoxUtils";
import {
  GOODS_HISTORY_OPTIONS,
  GOODS_HISTORY_TYPES,
  GOODS_RENTAL_REQUEST_OPTIONS,
} from "../../../types/comboBoxOption";
import {
  getGoodsRentalStockHistoryList,
  getGoodsRentalStockHistoryListExcel,
} from "../../../services/goodsService";
import { useEffect, useState } from "react";
import { getBuildingGroupList } from "../../../services/buildingService";
import { useUserContext } from "../../../contexts/User";
import { useModal } from "../../../contexts/Modal";
import { HttpStatusCode } from "axios";
import Spinner from "../../../components/Spinner";
import GoodsRentalStockDetailView from "../../../components/PageComponents/Service/GoodsRentalStockList/DetailView";
import { buildingGroupCategoryCodeValue } from "../../../types/building";

type Props = {};

export default function GoodsRentalStockHistoryList(props: Props) {
  const { getCategoryCodeByRole } = useUserContext();
  const { handleError } = useModal();

  const [buildingOptions, setBuildingOptions] = useState<ComboBoxType[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const columnInfo: TableColumnType<GoodsRentalStockHistory>[] = [
    { header: "변경일시", name: "createdAt", width: "date" },
    { header: "물품 번호", name: "goodsId", width: "id" },
    {
      header: "물품 종류",
      render: (item: GoodsRentalStockHistory) =>
        goodsRentalStockLabels[item.goodsTypeId as GoodsRentalStockType] ??
        DASH,
      width: "100",
    },
    { header: "물품명", name: "goodsName" },
    {
      header: "상점명",
      render: (item: GoodsRentalStockHistory) => (
        <div>
          {item.buildingName && (
            <>
              {`[${item.buildingName}]`}
              <br />
            </>
          )}
          {item.storeName}
        </div>
      ),
    },
    { header: "변경자", name: "createdByName" },
    {
      header: "변경이력",
      render: (item: GoodsRentalStockHistory) =>
        goodsHistoryLabels[item.historyType as GoodsHistoryType] ?? DASH,
    },
    {
      header: "변경수량",
      render: (item: GoodsRentalStockHistory) => getGoodsCount(item) ?? DASH,
      width: "number",
    },
    {
      header: "정상 재고",
      render: (item: GoodsRentalStockHistory) =>
        item.stockCount.toLocaleString() ?? 0,
      width: "number",
    },
    { header: "대여자 이름", name: "leasedByName" },
    { header: "대여자 회사", name: "leasedByCompanyName" },
    {
      header: "활동",
      render: (item: GoodsRentalStockHistory, handler) => (
        <div className="flex w-full h-full justify-center items-center">
          <DefaultButton
            size="tableIcon"
            color="blue"
            onClick={() => {
              if (handler?.popup) {
                handler.popup({
                  header: "대여 물품 연결 상세",
                  content: (
                    <GoodsRentalStockDetailView stockId={item.stockId} />
                  ),
                });
              }
            }}
          >
            <FiEdit2 />
          </DefaultButton>
        </div>
      ),
      width: "icon",
    },
  ];

  const getGoodsCount = (item: GoodsRentalStockHistory) => {
    switch (item.historyType) {
      case GOODS_HISTORY_TYPES.LOST_DAMAGED:
        return item.lostDamagedCount * -1; // 분실손상의 경우 음수로 표기
      case GOODS_HISTORY_TYPES.RESTORED:
        return item.restoredCount;
      case GOODS_HISTORY_TYPES.MOVED:
      case GOODS_HISTORY_TYPES.TOTAL_MODIFIED:
        return item.modifiedCount;
      default:
        return DASH;
    }
  };

  const componentList: ComponentType<GoodsRentalStockHistory>[] = [
    {
      labelName: "변경일자",
      typeName: "dateRange",
      keyName: "createdAt",
      category: "date",
    },
    {
      labelName: "물품 종류",
      typeName: "comboBox",
      keyName: "goodsTypeId",
      comboBoxOptions: addDefaultOption(GOODS_RENTAL_REQUEST_OPTIONS),
      category: "typeSearch",
    },
    {
      labelName: "변경 이력",
      typeName: "comboBox",
      keyName: "historyType",
      comboBoxOptions: addDefaultOption(GOODS_HISTORY_OPTIONS),
      category: "typeSearch",
    },
    {
      labelName: "건물목록",
      typeName: "searchComboBox",
      keyName: "buildingId",
      category: "typeSearch",
      placeholder: "전체",
      comboBoxOptions: buildingOptions,
    },
    {
      labelName: "물품 번호",
      typeName: "text",
      keyName: "goodsId",
      category: "detailSearch",
      placeholder: "물품 번호를 입력해주세요",
    },
    {
      labelName: "물품명",
      typeName: "text",
      keyName: "goodsName",
      category: "detailSearch",
      placeholder: "물품명을 입력해주세요",
    },
    {
      labelName: "상점명",
      typeName: "text",
      keyName: "storeName",
      category: "detailSearch",
      placeholder: "상점명을 입력해주세요",
    },
  ];

  useEffect(() => {
    const fetchBuildingList = async () => {
      try {
        setIsLoading(true);

        const res = await getBuildingGroupList({
          categoryCode: getCategoryCodeByRole(
            buildingGroupCategoryCodeValue.RENTAL
          ),
        });

        if (res.status === HttpStatusCode.Ok) {
          setBuildingOptions(
            toComboBoxType(res.data, "buildingId", "buildingName")
          );
        }
      } catch (err: any) {
        handleError(err, "건물 목록 조회");
      } finally {
        setIsLoading(false);
      }
    };

    fetchBuildingList();
  }, []);

  return (
    <>
      {isLoading && <Spinner />}
      <ListPage
        getDataApi={getGoodsRentalStockHistoryList}
        keyId={(item: GoodsRentalStockHistory) => item.goodsStockHistoryId}
        componentList={componentList}
        columnInfo={columnInfo}
        tableTitle="변경 이력"
        excelDownloadApi={getGoodsRentalStockHistoryListExcel}
        needExcelDownloadReason
      />
    </>
  );
}
