type Props = {};

export default function WarningCircleIcon(props: Props) {
  return (
    <svg
      width="60"
      height="61"
      viewBox="0 0 60 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.3">
        <path
          d="M5.39941 30.8997C5.39941 44.7068 16.5923 55.8997 30.3994 55.8997C44.2065 55.8997 55.3994 44.7068 55.3994 30.8997C55.3994 17.0925 44.2065 5.89966 30.3994 5.89966C16.5923 5.89966 5.39941 17.0925 5.39941 30.8997Z"
          stroke="#1D1929"
          strokeWidth="2"
          strokeLinejoin="round"
        />
        <path
          d="M30.4014 20.021V31.4522"
          stroke="black"
          strokeWidth="3"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
        <path
          d="M30.3896 39.4922H30.4102"
          stroke="black"
          strokeWidth="3"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}
