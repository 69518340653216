import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

type Props = {};
export default function Redirect(props: Props) {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/notFound");
  }, []);

  return <>Redirect Page</>;
}
