import { HttpStatusCode } from "axios";
import { useContext, useEffect, useState } from "react";

import {
  BuildingGroup,
  TagSelectedItem,
  TempHum,
  buildingGroupCategoryCodeValue,
} from "../../../../../../types/building";
import { ComboBoxType } from "../../../../../../types/search";
import {
  USAGE_OPTIONS,
  DEFAULT_SELECT_ALL_VALUE,
} from "../../../../../../types/comboBoxOption";
import { FileScale } from "../../../../../../types/file";

import {
  validationFunctions,
  validationResultMessage,
} from "../../../../../../libs/validations";
import { getImageByScale } from "../../../../../../utils/fileUtils";

import { useModal } from "../../../../../../contexts/Modal";
import { PopupContext } from "../../../../../../contexts/Popup";

import {
  getBuildingFloor,
  getTempHum,
  putTempHum,
} from "../../../../../../services/buildingService";

import DefaultButton from "../../../../../DefaultButton";
import FormHeader from "../../../../../Form/Header";
import FormRow from "../../../../../Form/Row";
import FormRowLabel from "../../../../../Form/RowLabel";
import DefaultInput from "../../../../../Input/DefaultInput";
import DefaultLabel from "../../../../../Input/DefaultLabel";
import RadioButtonGroup from "../../../../../Input/RadioButtonGroup";
import PermissionWrapper from "../../../../../PermissionWrapper";
import DefaultSelect from "../../../../../SelectBox/DefaultSelect";
import Spinner from "../../../../../Spinner";
import TagSingleFilter from "../../../../../TagFilter/TagSingleFilter";
import SingleImageUploader from "../../../../../FileUploader/ImageFileUploader/SingleImageFileUploader";

type Props = { buildingSpaceId: string };

export default function TempHumDetailView({ buildingSpaceId }: Props) {
  const { refreshAndClosePopup, closePopup } = useContext(PopupContext);
  const { showAlert, showConfirm, handleError } = useModal();
  const [tempHum, setTempHum] = useState<Partial<TempHum>>({ isActive: true });
  const [floorOptions, setFloorOptions] = useState<ComboBoxType[]>([]);
  const [selectedTag, setSelectedTag] = useState<TagSelectedItem>();
  const [isLoading, setIsLoading] = useState(false);

  const handlePut = () => {
    const errorMessage = validationResultMessage([
      validationFunctions.required(tempHum.buildingId, "건물"),
      validationFunctions.required(tempHum.buildingFloorId, "층"),
      validationFunctions.required(tempHum.spaceName, "공간명"),
      validationFunctions.required(tempHum.sortOrder, "정렬순서"),
    ]);

    if (errorMessage) {
      showAlert(errorMessage);
    } else {
      showConfirm("수정 하시겠습니까?", putData);
    }
  };

  const putData = async () => {
    try {
      const response = await putTempHum(buildingSpaceId, createFormData());

      if (response.status === HttpStatusCode.Ok) {
        showAlert("수정 되었습니다.");
        refreshAndClosePopup();
      }
    } catch (err: any) {
      handleError(err, "수정");
    }
  };

  const createFormData = () => {
    const formData = new FormData();

    tempHum.imageFile && formData.append("uploadFile", tempHum.imageFile);
    formData.append("request", JSON.stringify(tempHum));

    return formData;
  };

  const fetchData = async (buildingSpaceId: string) => {
    try {
      setIsLoading(true);

      const response = await getTempHum(buildingSpaceId);
      const data = response.data;

      if (data) {
        setTempHum(data);
        setSelectedTag({
          buildingId: data.buildingId,
          buildingName: data.buildingName,
        } as BuildingGroup);

        if (data.buildingId) {
          setFloorOptions([
            { value: data.buildingFloorId, label: data.floorName },
          ]);
        }
      }
    } catch (err: any) {
      handleError(err, "조회");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchFloor = async (buildingId: string) => {
    try {
      setIsLoading(true);

      const response = await getBuildingFloor(buildingId);

      if (response.data) {
        const options = response.data.map((item) => ({
          value: item.buildingFloorId,
          label: item.floorName,
        }));

        setFloorOptions(options);
      }
    } catch (err: any) {
      handleError(err, "층 정보 조회");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData(buildingSpaceId);
  }, [buildingSpaceId]);

  const handleTempHumChange = (data: Partial<TempHum>) => {
    setTempHum((prev) => ({ ...prev, ...data }));
  };

  const handleBuildingChange = (item: BuildingGroup) => {
    if (item.buildingId) {
      setSelectedTag(item);
      handleTempHumChange({ buildingId: item.buildingId });
      fetchFloor(item.buildingId);
    } else {
      console.error("buildingId is null");
    }
  };

  return (
    <div className="flex flex-col gap-5">
      {isLoading && <Spinner />}
      <div className="flex flex-col min-w-[700px] max-h-[700px] overflow-y-auto">
        <FormHeader title="기본 정보" />
        <FormRow>
          <FormRowLabel title="공간 ID">
            <DefaultLabel text={tempHum.buildingSpaceId} />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <div className="flex gap-20">
            <FormRowLabel title="등록일시">
              <DefaultLabel text={tempHum.createdAt} />
            </FormRowLabel>
            <FormRowLabel title="최종 수정일시">
              <DefaultLabel text={tempHum.modifiedAt} />
            </FormRowLabel>
          </div>
        </FormRow>

        <FormHeader title="기준 정보" />
        <FormRow>
          <FormRowLabel title="위치" isRequired>
            <div className="flex flex-col gap-1.5">
              <TagSingleFilter
                selectType="building"
                selectedItem={selectedTag}
                onChangeBuilding={handleBuildingChange}
                categoryCode={buildingGroupCategoryCodeValue.IOT_DEVICE}
              />
              <DefaultSelect
                value={tempHum.buildingFloorId}
                placeholder="층 선택"
                optionList={floorOptions}
                onChange={(value: string) => {
                  handleTempHumChange({
                    buildingFloorId:
                      value !== DEFAULT_SELECT_ALL_VALUE ? value : undefined,
                  });
                }}
                disabled={
                  tempHum.buildingId === undefined || floorOptions.length === 0
                }
              />
            </div>
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="사용 여부">
            <RadioButtonGroup
              value={tempHum.isActive?.toString()}
              options={USAGE_OPTIONS}
              onChange={(value: string) =>
                handleTempHumChange({
                  isActive: value === "true" ? true : false,
                })
              }
            />
          </FormRowLabel>
        </FormRow>

        <FormHeader title="공간정보" />
        <FormRow>
          <FormRowLabel title="공간명" isRequired>
            <DefaultInput
              value={tempHum.spaceName}
              onChange={(value: string) =>
                handleTempHumChange({ spaceName: value })
              }
              maxLength={20}
              placeholder="공간명을 입력해주세요"
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="정렬 순서" isRequired>
            <DefaultInput
              type="number"
              value={tempHum.sortOrder?.toString()}
              onChange={(value: string) =>
                handleTempHumChange({
                  sortOrder: value !== "" ? Number(value) : undefined,
                })
              }
              maxLength={10}
              hideMaxLength
              placeholder="정렬순서를 입력해주세요"
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="도면 추가">
            <SingleImageUploader
              image={tempHum.imageFile}
              onChange={(file) => {
                handleTempHumChange({ ...tempHum, imageFile: file });
              }}
              defaultImage={getImageByScale(
                tempHum?.planFile?.items ?? [],
                FileScale.MEDIUM
              )}
              onDelete={() => {
                handleTempHumChange({
                  planFile: undefined,
                  isDeleteFile: true,
                });
              }}
            />
          </FormRowLabel>
        </FormRow>
      </div>
      <div className="flex justify-center gap-2">
        <DefaultButton onClick={closePopup}>닫기</DefaultButton>
        <PermissionWrapper>
          <DefaultButton color="primary" onClick={handlePut}>
            수정
          </DefaultButton>
        </PermissionWrapper>
      </div>
    </div>
  );
}
