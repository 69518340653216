import { HttpStatusCode } from "axios";
import { useEffect, useState } from "react";
import {
  validationFunctions,
  validationResultMessage,
} from "../../../libs/validations";
import { FileInfo, FileScale } from "../../../types/file";
import {
  SERVICE_INPUT_TYPE,
  Service,
  ServiceDetailType,
  ServiceType,
} from "../../../types/service";
import { getFirstOptionValue } from "../../../utils/comboBoxUtils";
import {
  AUTH_COMPANY_OPTIONS,
  CHANGE_OPTIONS,
  CHANGE_STATUS,
  BOOLEAN_TYPES,
} from "../../../types/comboBoxOption";
import { getImageByScale } from "../../../utils/fileUtils";
import DefaultButton from "../../DefaultButton";
import SingleImageUploader from "../../FileUploader/ImageFileUploader/SingleImageFileUploader";
import FormHeader from "../../Form/Header";
import FormRow from "../../Form/Row";
import FormRowLabel from "../../Form/RowLabel";
import DefaultInput from "../../Input/DefaultInput";
import RadioButtonGroup from "../../Input/RadioButtonGroup";
import Spinner from "../../Spinner";
import { useModal } from "../../../contexts/Modal";
import WarningCircleIcon from "../../../svgs/icons/Common/WarningCircle";
import PermissionWrapper from "../../PermissionWrapper";

type Props = {
  serviceType: ServiceType;
  id: string;
  defaultItem?: Service;
  serviceDetailType?: ServiceDetailType;
  refreshService: () => Promise<void>;
  getService: (id: string, serviceId: string) => Promise<any>;
  createService: (id: string, service: Partial<Service>) => Promise<any>;
  updateService: (
    id: string,
    serviceId: string,
    service: Partial<Service>
  ) => Promise<any>;
};

const CREATE_ACTION = "등록";
const UPDATE_ACTION = "수정";
const COMPANY_AUTH_NOTICE_TEXT =
  "* 특정 입주사만 서비스 노출은 입주사별 서비스 관리에서 가능합니다";
const EMPTY_TEXT = "서비스를 선택해주세요";

const SERVICE_TYPE_MANAGER = "MANAGER";

export default function ServiceDetailView({
  serviceType,
  id,
  defaultItem,
  serviceDetailType,
  refreshService,
  getService,
  createService,
  updateService,
}: Props) {
  const { showAlert, showConfirm, handleError } = useModal();
  const [selectedOptions, setSelectedOptions] = useState({
    name: "",
    icon: "",
    auth: "",
  });
  const [modifiedItem, setModifiedItem] = useState<Partial<Service>>({});
  const [serviceName, setServiceName] = useState("");
  const [icon, setIcon] = useState<{ items: FileInfo[] }>({ items: [] });
  const [isLoading, setIsLoading] = useState(false);

  const getData = async (serviceId: string) => {
    if (!serviceId) {
      showAlert("서비스 ID가 존재하지 않습니다.");
      return;
    }

    try {
      setIsLoading(true);

      const response = await getService(id, serviceId);
      if (response.status === HttpStatusCode.Ok) {
        const { useCustomServiceName, useCustomIcon, useOnlyAuthorized } =
          response.data;
        setModifiedItem({
          ...response.data,
          useOnlyAuthorized: Boolean(useOnlyAuthorized),
        });
        setServiceName(response.data.serviceName);
        setIcon(response.data.fileInfo);
        setSelectedOptions({
          name: useCustomServiceName
            ? CHANGE_STATUS.CHANGE
            : CHANGE_STATUS.DEFAULT,
          icon: useCustomIcon ? CHANGE_STATUS.CHANGE : CHANGE_STATUS.DEFAULT,
          auth: useOnlyAuthorized ? BOOLEAN_TYPES.TRUE : BOOLEAN_TYPES.FALSE,
        });
      } else {
        throw new Error();
      }
    } catch (err: any) {
      handleError(err, "조회");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    initState();
  }, [defaultItem]);

  const initState = () => {
    if (defaultItem?.serviceId) {
      getData(defaultItem.serviceId);
    } else {
      setModifiedItem({
        ...defaultItem,
        serviceName: defaultItem?.serviceTemplateName,
        isActive: true,
      });
      setServiceName(defaultItem?.serviceTemplateName ?? "");
      setSelectedOptions({
        name: getFirstOptionValue(CHANGE_OPTIONS),
        icon: getFirstOptionValue(CHANGE_OPTIONS),
        auth: getFirstOptionValue(AUTH_COMPANY_OPTIONS),
      });
    }
  };

  const handleExecuteApi = (action: string) => {
    // serviceItemType 누락 시 존재하지 않는 빈 문자열의 서비스 항목 밑으로 저장되기 때문에 추가 예외처리 함.
    if (!modifiedItem.serviceItemType) {
      showAlert("서비스 타입이 누락됐습니다.");
      console.error("Service type not found");
      return;
    }

    const valid = serviceActions
      .filter(({ condition }) => {
        if (
          !(
            condition === SERVICE_INPUT_TYPE.ICON &&
            selectedOptions.icon === CHANGE_STATUS.DEFAULT
          )
        ) {
          return serviceDetailType?.toLowerCase().includes(condition);
        } else {
          return false;
        }
      })
      .map(({ valid }) => valid());

    const errorMessage = validationResultMessage(valid);
    if (errorMessage) {
      showAlert(errorMessage);
    } else {
      const message = `${action} 하시겠습니까?`;

      const data = createData(action);
      showConfirm(message, () =>
        action === CREATE_ACTION ? postData(data) : updateData(data)
      );
    }
  };

  const createData = (action: string) => {
    const useCustomIcon =
      selectedOptions.icon === CHANGE_STATUS.CHANGE ? true : false;
    const useCustomServiceName =
      selectedOptions.name === CHANGE_STATUS.CHANGE ? true : false;

    const data: Partial<Service> = {
      ...(action === CREATE_ACTION && {
        serviceType: modifiedItem.serviceType,
        serviceItemType: modifiedItem.serviceItemType,
        serviceDetailType: modifiedItem.serviceDetailType,
        serviceTemplateId: modifiedItem.serviceTemplateId,
      }),
      isActive: modifiedItem.isActive,
      serviceName: modifiedItem.useCustomServiceName
        ? modifiedItem.serviceName
        : modifiedItem.serviceTemplateName,
      sortOrder: modifiedItem.sortOrder,
      useOnlyAuthorized:
        defaultItem?.serviceType === SERVICE_TYPE_MANAGER // 관리 서비스의 경우 건물 단위로 관리되기 때문에 이용범위 전체로 고정
          ? true
          : modifiedItem.useOnlyAuthorized,
      useCustomIcon,
      useCustomServiceName,
      ...(useCustomIcon &&
        modifiedItem.iconFile && { iconFile: modifiedItem.iconFile }),
    };
    return data;
  };

  const updateData = async (data: Partial<Service>) => {
    if (!modifiedItem.serviceId) {
      showAlert("서비스 ID가 존재하지 않습니다. 관리자에게 문의하세요.");
      console.error("Service id is null");
      return;
    }

    try {
      const response = await updateService(id, modifiedItem.serviceId, data);
      if (response.status === HttpStatusCode.Ok) {
        showAlert("수정 되었습니다.");
        getData(modifiedItem.serviceId ?? "");
      } else {
        throw new Error();
      }
    } catch (err: any) {
      handleError(err, "수정");
    } finally {
      refreshService();
      initState();
    }
  };

  const postData = async (data: Partial<Service>) => {
    try {
      const response = await createService(id, data);
      if (response.status === HttpStatusCode.Ok) {
        showAlert("등록 되었습니다.");

        const { id } = response.data;
        if (id) {
          getData(response.data.id);
        } else {
          throw new Error("Not found id value");
        }
      } else {
        throw new Error("Create data fail");
      }
    } catch (err: any) {
      handleError(err, "등록");
    } finally {
      refreshService();
      initState();
    }
  };

  const handleSelectedChange = (data: {}) => {
    setSelectedOptions((prev) => ({ ...prev, ...data }));
  };

  const handleItemChange = (data: Partial<Service>) => {
    setModifiedItem((prev) => ({ ...prev, ...data }));
  };

  const renderServiceName = () => {
    return (
      <FormRow>
        <FormRowLabel title="서비스명" isRequired>
          <div className="flex flex-col gap-[10px]">
            <RadioButtonGroup
              value={selectedOptions.name}
              onChange={(value: string) => {
                const serviceName =
                  value === CHANGE_STATUS.CHANGE
                    ? modifiedItem?.serviceName ?? ""
                    : modifiedItem?.serviceTemplateName ?? "";
                handleItemChange({
                  serviceName,
                  useCustomServiceName: value === CHANGE_STATUS.CHANGE,
                });
                handleSelectedChange({ name: value });
              }}
              options={CHANGE_OPTIONS}
            />
            <DefaultInput
              value={modifiedItem.serviceName}
              onChange={(value: string) =>
                handleItemChange({ serviceName: value })
              }
              disabled={selectedOptions.name === CHANGE_STATUS.DEFAULT}
            />
          </div>
        </FormRowLabel>
      </FormRow>
    );
  };

  const renderServiceIcon = () => {
    return (
      <FormRow>
        <FormRowLabel title="서비스 아이콘" isRequired>
          <div className="flex flex-col gap-[10px]">
            <RadioButtonGroup
              value={selectedOptions.icon}
              onChange={(value: string) => {
                handleItemChange({
                  iconFile: undefined,
                  useCustomIcon: value === CHANGE_STATUS.CHANGE,
                });
                handleSelectedChange({ icon: value });
                if (value === CHANGE_STATUS.DEFAULT) {
                  setIcon(modifiedItem.fileInfo ?? { items: [] });
                }
              }}
              options={CHANGE_OPTIONS}
            />
            {selectedOptions.icon === CHANGE_STATUS.CHANGE && (
              <>
                <SingleImageUploader
                  image={modifiedItem.iconFile}
                  onChange={(file: File) => {
                    handleItemChange({ iconFile: file });
                  }}
                  rightLabel="* 단건 등록"
                  defaultImage={getImageByScale(icon.items, FileScale.MEDIUM)}
                  onDelete={() => {
                    setIcon({ items: [] });
                  }}
                />
              </>
            )}
          </div>
        </FormRowLabel>
      </FormRow>
    );
  };

  const renderAuth = () => {
    return (
      <FormRow>
        <FormRowLabel title="이용 범위" isRequired>
          <div className="flex flex-col gap-[11px]">
            <RadioButtonGroup
              value={modifiedItem?.useOnlyAuthorized?.toString()}
              onChange={(value: string) => {
                const result = value === BOOLEAN_TYPES.TRUE;

                handleItemChange({ useOnlyAuthorized: result });
                handleSelectedChange({ auth: value });
              }}
              options={AUTH_COMPANY_OPTIONS}
            />
          </div>
        </FormRowLabel>
      </FormRow>
    );
  };

  const serviceActions = [
    {
      condition: SERVICE_INPUT_TYPE.NAME,
      valid: () =>
        validationFunctions.required(
          modifiedItem.useCustomServiceName
            ? modifiedItem.serviceName
            : modifiedItem.serviceTemplateName,
          "서비스명"
        ),
      render: renderServiceName,
    },
    {
      condition: SERVICE_INPUT_TYPE.ICON,
      valid: () =>
        validationFunctions.required(
          Boolean(icon.items.length) || modifiedItem.iconFile ? "N" : "",
          "아이콘"
        ),
      render: renderServiceIcon,
    },
    {
      condition: SERVICE_INPUT_TYPE.AUTH,
      valid: () =>
        validationFunctions.boolean(
          modifiedItem.useOnlyAuthorized,
          "회사 인증 필수 여부"
        ),
      render: renderAuth,
    },
  ];

  return (
    <>
      {isLoading && <Spinner />}
      {serviceDetailType ? (
        <div className="flex flex-col gap-2.5">
          <FormHeader
            title={serviceName}
            right={
              <div className="bg-gray-200 text-xs py-[7px] px-[15px]">
                서비스번호 : {modifiedItem?.serviceId ?? "등록 필요"}
              </div>
            }
          />
          <div className="flex flex-col bg-white">
            {serviceActions.map(({ condition, render }) => (
              <div key={condition}>
                {serviceDetailType?.toLowerCase().includes(condition)
                  ? render()
                  : null}
              </div>
            ))}
          </div>
          {serviceType === "BUILDING" && (
            <span className="text-sm pl-2.5">{COMPANY_AUTH_NOTICE_TEXT}</span>
          )}
          <div className="flex justify-center">
            <PermissionWrapper>
              <DefaultButton
                size="popupDefault"
                color="primary"
                onClick={() =>
                  handleExecuteApi(
                    modifiedItem?.serviceId ? UPDATE_ACTION : CREATE_ACTION
                  )
                }
              >
                {modifiedItem?.serviceId ? UPDATE_ACTION : CREATE_ACTION}
              </DefaultButton>
            </PermissionWrapper>
          </div>
        </div>
      ) : (
        <span className="flex flex-col h-full justify-center items-center">
          <WarningCircleIcon />
          <span className="text-sm text-black text-opacity-60 pt-[10px]">
            {EMPTY_TEXT}
          </span>
        </span>
      )}
    </>
  );
}
