import { useContext, useEffect, useState } from "react";
import DefaultButton from "../../../../DefaultButton";
import FormHeader from "../../../../Form/Header";
import FormRow from "../../../../Form/Row";
import FormRowLabel from "../../../../Form/RowLabel";
import { PopupContext } from "../../../../../contexts/Popup";
import { useModal } from "../../../../../contexts/Modal";
import {
  validationFunctions,
  validationResultMessage,
} from "../../../../../libs/validations";
import {
  getAppVersionNewestInfo,
  postAppVersion,
} from "../../../../../services/systemService";
import { AppVersion } from "../../../../../types/system";
import { HttpStatusCode } from "axios";
import DefaultSelect from "../../../../SelectBox/DefaultSelect";
import { toComboBoxType } from "../../../../../utils/comboBoxUtils";
import VersionInput from "../../../../Input/VersionInput";
import FormRowLabelItem from "../../../../Form/RowLabelItem";
import DefaultLabel from "../../../../Input/DefaultLabel";
import Spinner from "../../../../Spinner";
import {
  OS_OPTIONS,
  OS_TYPES,
  YES_NO_OPTIONS,
} from "../../../../../types/comboBoxOption";
import DefaultInput from "../../../../Input/DefaultInput";

type Props = {};
export default function AppVersionCreateForm(props: Props) {
  const { closePopup, refreshAndClosePopup } = useContext(PopupContext);
  const { showAlert, showConfirm, handleError } = useModal();

  const [appVersion, setAppVersion] = useState<Partial<AppVersion>>({});
  const [newestVersion, setNewestVersion] = useState<AppVersion[]>([]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const res = await getAppVersionNewestInfo();
        if (res.status === HttpStatusCode.Ok) {
          setNewestVersion(res.data);
        }
      } catch (err: any) {
        handleError(err, "최신버전 조회");
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const handlePost = () => {
    const errorMessage = validationResultMessage([
      validationFunctions.required(appVersion.osType, "OS"),
      validationFunctions.isFalsy(
        appVersion.isForceUpdate,
        "강제 업데이트 여부"
      ),
      validationFunctions.required(appVersion.releaseNote, "개선 내용"),
    ]);

    if (!isValidVersion(appVersion.version ?? "")) {
      showAlert("버전을 입력해주세요");
      return;
    }

    const compareVersion = newestVersion.find(
      (item) => item.osType === appVersion.osType
    )?.version;

    if (compareVersion && appVersion.version) {
      if (!validVersion(compareVersion, appVersion.version)) {
        showAlert("등록 버전이 현재 버전보다 높아야 합니다.");
        return;
      }
    }

    if (errorMessage) {
      showAlert(errorMessage);
    } else {
      showConfirm("등록 하시겠습니까?", postData);
    }
  };

  const isValidVersion = (version: string) => {
    const regex = /^\d+\.\d+\.\d+$/;
    return regex.test(version);
  };

  const postData = async () => {
    const data: Partial<AppVersion> = {
      osType: appVersion.osType,
      version: appVersion.version,
      isForceUpdate: appVersion.isForceUpdate,
      releaseNote: appVersion.releaseNote,
    };

    try {
      const res = await postAppVersion(data);
      if (res.status === HttpStatusCode.Ok) {
        showAlert("등록 되었습니다.");
        refreshAndClosePopup();
      }
    } catch (err: any) {
      handleError(err, "등록");
    }
  };

  const validVersion = (version1: string, version2: string): boolean => {
    const parseVersion1 = version1.split(".").map((item) => Number(item));
    const parseVersion2 = version2.split(".").map((item) => Number(item));

    for (let i = 0; i < parseVersion1.length; i++) {
      if (parseVersion2[i] > parseVersion1[i]) {
        return true;
      } else if (parseVersion2[i] < parseVersion1[i]) {
        return false;
      }
    }
    return false;
  };

  const handleAppVersionChange = (key: keyof AppVersion) => (value: string) => {
    setAppVersion((prev) => ({ ...prev, [key]: value }));
  };

  const getOsOptions = () => {
    // 앱버전관리는 실제 앱에 영향을 미치므로 개발서버의 경우 테스트를 위한 옵션을 추가한다.
    if (process.env.REACT_APP_ENV === "production") {
      return OS_OPTIONS;
    }

    return toComboBoxType(newestVersion, "osType", "osType").map((option) => {
      switch (option.value) {
        case OS_TYPES.ANDROID:
          return { ...option, label: "안드로이드" };
        case OS_TYPES.IOS:
          return { ...option, label: "IOS" };
        default:
          return option;
      }
    });
  };

  const renderVersion = (item: AppVersion) => {
    return (
      <FormRow>
        <FormRowLabel title={`현재 ${item.osType} 버전`}>
          <div className="flex flex-col">
            <FormRowLabelItem>
              <DefaultLabel text={`현재(최신) 버전 : ${item.version}`} />
            </FormRowLabelItem>
            <FormRowLabelItem>
              <DefaultLabel text={`개선 내용 : ${item.releaseNote}`} />
            </FormRowLabelItem>
          </div>
        </FormRowLabel>
      </FormRow>
    );
  };

  return (
    <div className="flex flex-col gap-5">
      {isLoading && <Spinner />}
      <div className="flex flex-col min-w-[700px] max-h-[700px] overflow-y-auto">
        <FormHeader title="기본정보" />
        {newestVersion.map((item) => renderVersion(item))}

        <FormHeader title="변경정보" />
        <FormRow>
          <FormRowLabel title="OS" isRequired>
            <DefaultSelect
              optionList={getOsOptions()}
              value={appVersion.osType}
              onChange={handleAppVersionChange("osType")}
              placeholder="선택"
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="강제 업데이트 여부" isRequired>
            <DefaultSelect
              optionList={YES_NO_OPTIONS}
              value={appVersion.isForceUpdate?.toString()}
              onChange={handleAppVersionChange("isForceUpdate")}
              placeholder="선택"
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="버전" isRequired>
            <VersionInput onVersionChange={handleAppVersionChange("version")} />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="개선 내용" isRequired>
            <DefaultInput
              value={appVersion.releaseNote}
              onChange={handleAppVersionChange("releaseNote")}
              minWidth="w-full"
              maxLength={100}
              placeholder="개선 내용을 입력해주세요"
            />
          </FormRowLabel>
        </FormRow>
      </div>

      <div className="flex justify-center gap-2">
        <DefaultButton onClick={closePopup}>닫기</DefaultButton>
        <DefaultButton color="primary" onClick={handlePost}>
          등록
        </DefaultButton>
      </div>
    </div>
  );
}
