import { useState } from "react";

import { BuildingFloor, initBuildingFloor } from "../../types/building";
import { CrudProcessType } from "../../types/common";

import {
  validationFunctions,
  validationResultMessage,
} from "../../libs/validations";

import { useModal } from "../../contexts/Modal";

import DefaultButton from "../DefaultButton";
import DefaultInput from "../Input/DefaultInput";
import ToggleButton from "../Input/ToggleButton";
import ListPage from "../ListPage";
import { TableColumnType } from "../Table";
import DefaultLabel from "../Input/DefaultLabel";

import PlusButtonIcon from "../../svgs/icons/Common/PlusButton";
import MinusButtonIcon from "../../svgs/icons/Common/MinusButton";

type Props = {
  crudProcessType?: CrudProcessType;
  data: BuildingFloor[];
  onChange: (list: BuildingFloor[]) => void;
};

export default function FloorList({
  crudProcessType = "CREATE",
  data,
  onChange,
}: Props) {
  const columnInfo: TableColumnType<BuildingFloor>[] = [
    ...(crudProcessType === "UPDATE"
      ? [
          {
            header: "층 ID",
            render: (item: BuildingFloor) => (
              <div className="flex justify-center items-center">
                {item.buildingFloorId ? (
                  <DefaultLabel text={item.buildingFloorId} />
                ) : (
                  <DefaultLabel text={"자동 생성"} />
                )}
              </div>
            ),
          },
        ]
      : []),
    {
      header: "엣지 ID",
      render: (item: BuildingFloor) => (
        <div className="flex justify-center items-center">
          <DefaultInput
            value={item.atgBuildingFloorId}
            onChange={(value: string) =>
              handleInputChange(value, item, "atgBuildingFloorId")
            }
            placeholder="엣지 ID 입력"
          />
        </div>
      ),
    },
    {
      header: "층명",
      render: (item: BuildingFloor) => (
        <div className="flex justify-center items-center">
          <DefaultInput
            value={item.floorName}
            onChange={(value: string) =>
              handleInputChange(value, item, "floorName")
            }
            placeholder="층명 입력"
          />
        </div>
      ),
    },
    {
      header: "순서",
      render: (item: BuildingFloor) => (
        <div className="flex justify-center items-center">
          <DefaultInput
            type="number"
            className="text-center h-6 text-sm"
            minWidth="w-[90px]"
            value={item.sortOrder?.toString()}
            onChange={(value: string) =>
              handleInputChange(value, item, "sortOrder")
            }
            maxLength={5}
            hideMaxLength
          />
        </div>
      ),
    },
    ...(crudProcessType === "UPDATE"
      ? [
          {
            header: "사용여부",
            render: (item: BuildingFloor) => (
              <div className="flex justify-center items-center min-w-icon">
                {item.buildingFloorId && (
                  <ToggleButton
                    value={item.isActive}
                    onChange={(value: boolean) =>
                      handleInputChange(value, item, "isActive")
                    }
                  />
                )}
              </div>
            ),
          },
        ]
      : []),
    {
      header: "상세",
      render: (item: BuildingFloor) => {
        const lastBuildingFloorId = data[data.length - 1].buildingFloorId;
        return (
          <div className="flex items-center justify-center min-w-icon">
            {data.length > 1 && !item.buildingFloorId && (
              <DefaultButton size="icon" onClick={() => handleRemove(item)}>
                <MinusButtonIcon />
              </DefaultButton>
            )}
            {item.buildingFloorId === lastBuildingFloorId && (
              <DefaultButton size="icon" onClick={() => handleAdd()}>
                <PlusButtonIcon />
              </DefaultButton>
            )}
          </div>
        );
      },
    },
  ];

  const handleInputChange = (
    value: string | boolean,
    item: BuildingFloor,
    field: keyof BuildingFloor
  ) => {
    const newData = [...data];
    const index = newData.findIndex((i) => i === item);

    if (field === "sortOrder") {
      newData[index][field] = Number(value) as never;
    } else {
      newData[index][field] = value as never;
    }

    onChange(newData);
  };

  const handleAdd = () => {
    onChange([...data, { ...initBuildingFloor }]);
  };

  const handleRemove = (item: BuildingFloor) => {
    const newData = data.filter((i) => i !== item);
    onChange(newData);
  };

  const renderTopRight = () => {
    const isFloorCreated = data.some((item) => item.buildingFloorId);
    return !isFloorCreated ? <FloorCreateForm onChange={onChange} /> : null;
  };

  return (
    <>
      <ListPage<BuildingFloor>
        getDataApi={Promise.resolve}
        columnInfo={columnInfo}
        tableTitle="층 목록"
        selectedMode
        selectedData={data}
        hidePagination
        hidePageSize
        renderTopRight={renderTopRight()}
      />
    </>
  );
}

type FloorCreateFormProps = { onChange: (list: BuildingFloor[]) => void };

export const FloorCreateForm = ({ onChange }: FloorCreateFormProps) => {
  const { showAlert } = useModal();

  const [underground, setUnderground] = useState("");
  const [ground, setGround] = useState("");

  const handleFloorCreate = () => {
    const errorMessage = validationResultMessage([
      validationFunctions.required(underground, "지하 층"),
      validationFunctions.required(ground, "지상 층"),
    ]);

    if (errorMessage) {
      showAlert(errorMessage);
    } else {
      createFloorList();
    }
  };

  const createFloorList = () => {
    const groundNum = Number(ground);
    const undergroundNum = Number(underground);

    const initItem = { ...initBuildingFloor } as BuildingFloor;
    const list: BuildingFloor[] = [];

    for (let i = groundNum; i > 0; i--) {
      list.push({ ...initItem, floorName: `${i}F` });
    }

    for (let i = 1; i <= undergroundNum; i++) {
      list.push({ ...initItem, floorName: `B${i}` });
    }

    list.forEach((item, index) => (item.sortOrder = index + 1));

    onChange(list);
  };

  return (
    <div className="flex gap-2 items-center">
      <span className="text-label">지하</span>
      <DefaultInput
        type="number"
        value={underground}
        onChange={setUnderground}
        min={0}
        minWidth="w-[80px]"
        maxLength={3}
        hideMaxLength
        className="text-center"
        placeholder="0"
      />
      <span className="text-label">지상</span>
      <DefaultInput
        type="number"
        value={ground}
        onChange={setGround}
        min={0}
        minWidth="w-[80px]"
        maxLength={3}
        hideMaxLength
        className="text-center"
        placeholder="0"
      />
      <DefaultButton size="popupDefault" onClick={handleFloorCreate}>
        전체 층 등록
      </DefaultButton>
    </div>
  );
};
