import { useContext } from "react";
import { PopupContext } from "../../../../../contexts/Popup";
import DefaultButton from "../../../../DefaultButton";
import GoodsRentalItemCreateForm from "../CreateForm";

type Props = {};

export default function GoodsRentalItemListTopRight(props: Props) {
  const { openPopup } = useContext(PopupContext);

  return (
    <DefaultButton
      size="tableTop"
      color="default"
      onClick={() =>
        openPopup({
          header: "대여 물품 등록",
          content: <GoodsRentalItemCreateForm />,
        })
      }
    >
      대여 물품 등록
    </DefaultButton>
  );
}
