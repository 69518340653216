import { useContext, useEffect, useState } from "react";
import { PopupContext } from "../../../../contexts/Popup";
import {
  validationFunctions,
  validationResultMessage,
} from "../../../../libs/validations";
import {
  Building,
  BuildingFloor,
  BuildingVoc,
  BuildingVocGet,
  initBuildingFloor,
} from "../../../../types/building";
import DefaultButton from "../../../DefaultButton";
import SingleImageUploader from "../../../FileUploader/ImageFileUploader/SingleImageFileUploader";
import FloorList from "../../../FloorList";
import FormHeader from "../../../Form/Header";
import FormRow from "../../../Form/Row";
import FormRowLabel from "../../../Form/RowLabel";
import DefaultInput from "../../../Input/DefaultInput";
import Spinner from "../../../Spinner";
import {
  getBuilding,
  getBuildingVocUnitList,
  putBuilding,
} from "../../../../services/buildingService";
import DefaultLabel from "../../../Input/DefaultLabel";
import RadioButtonGroup from "../../../Input/RadioButtonGroup";
import { USAGE_OPTIONS, BOOLEAN_TYPES } from "../../../../types/comboBoxOption";
import { HttpStatusCode } from "axios";
import { useModal } from "../../../../contexts/Modal";
import { getImageByScale } from "../../../../utils/fileUtils";
import { FileScale } from "../../../../types/file";
import { AtgBuilding } from "../../../../types/atg";
import AtgBuildingTagSingleFilter from "../../../TagFilter/TagSingleFilter/AtgBuildingTagSingleFilter";
import { DASH } from "../../../../types/search";
import CommonPopup from "../../../Popup/CommonPopup";
import PermissionWrapper from "../../../PermissionWrapper";

type Props = { buildingId: string };

export default function BuildingDetailView({ buildingId }: Props) {
  const { showAlert, showConfirm, handleError } = useModal();
  const { closePopup, refreshAndClosePopup } = useContext(PopupContext);
  const [building, setBuilding] = useState<Partial<Building>>({});
  const [vocList, setVocList] = useState<BuildingVocGet[]>([]);
  const [atg, setAtg] = useState<AtgBuilding>();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    try {
      setIsLoading(true);

      const [resBuilding, resVoc] = await Promise.all([
        getBuilding(buildingId),
        getBuildingVocUnitList(),
      ]);
      if (
        resBuilding.status === HttpStatusCode.Ok &&
        resVoc.status === HttpStatusCode.Ok
      ) {
        const data: Partial<Building> = {
          ...resBuilding.data,
          buildingFloorList:
            resBuilding.data.buildingFloorList.length > 0
              ? resBuilding.data.buildingFloorList.sort(
                  (a, b) => a.sortOrder - b.sortOrder
                )
              : [{ ...initBuildingFloor }],
        };
        setBuilding(data);

        const vocList = resVoc.data.map((voc) => {
          const item = data.buildingVocList?.find(
            (bVoc) => bVoc.vocUnitCode === voc.code
          );
          if (item) {
            return { ...voc, vocId: item.vocId };
          }
          return voc;
        });
        setVocList(vocList);
      } else {
        throw new Error("Failed to load building data.");
      }
    } catch (err: any) {
      handleError(err, "조회");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [buildingId]);

  const handleBuildingChange =
    (property: keyof Building) =>
    (data: string | number | File | boolean | undefined | BuildingFloor[]) => {
      setBuilding((prev) => {
        return { ...prev, [property]: data };
      });
    };

  const handleBuildingChangeObject = (data: Partial<Building>) => {
    setBuilding((prev) => ({ ...prev, ...data }));
  };

  const handleBuildingVocIdChange = (code: string) => (value: string) => {
    setVocList((prev) =>
      prev.map((prevItem) =>
        prevItem.code === code ? { ...prevItem, vocId: value } : prevItem
      )
    );
  };

  const handleUpdate = () => {
    const errorMessage = validationResultMessage([
      validationFunctions.required(building?.buildingName, "건물명"),
      validationFunctions.required(building?.helpDeskTel, "고객센터"),
      validationFunctions.required(building?.address, "주소"),
      validationFunctions.required(building?.lat, "위도"),
      validationFunctions.number(building?.lat, "위도"),
      validationFunctions.required(building?.lng, "경도"),
      validationFunctions.number(building?.lng, "경도"),
      validationFunctions.required(building?.gridX, "격자 X"),
      validationFunctions.number(building?.gridX, "격자 X"),
      validationFunctions.required(building?.gridY, "격자 Y"),
      validationFunctions.number(building?.gridY, "격자 Y"),
    ]);

    if (errorMessage) {
      showAlert(errorMessage);
      return;
    }

    const floorList = building.buildingFloorList;

    const isAllNotEmpty = floorList?.every(isItemNotEmpty);
    const isAllEmpty = floorList?.every(isItemEmpty);
    const invalidNotEmpty =
      floorList?.length === 1 ? isAllNotEmpty || isAllEmpty : isAllNotEmpty;

    if (invalidNotEmpty) {
      showConfirm("수정 하시겠습니까?", updateData);
    } else {
      showAlert("건물 층 정보를 입력해주세요");
    }
  };

  const isItemEmpty = (item: BuildingFloor) => item.floorName === "";

  const isItemNotEmpty = (item: BuildingFloor) => item.floorName !== "";

  const updateData = async () => {
    try {
      const response = await putBuilding(buildingId, createFormData());

      if (response.status === HttpStatusCode.Ok) {
        showAlert("수정 되었습니다.");
        refreshAndClosePopup();
      } else {
        throw new Error("Building update fail");
      }
    } catch (err: any) {
      handleError(err, "수정");
    }
  };

  const createFormData = () => {
    const formData = new FormData();

    const data: Partial<Building> = {
      ...building,
      lat: Number(building.lat),
      lng: Number(building.lng),
      gridX: Number(building.gridX),
      gridY: Number(building.gridY),
      atgDepartmentName: atg?.departmentName ?? building.atgDepartmentName,
      atgCompanyName: atg?.businessPlaceName ?? building.atgCompanyName,
      atgBuildingId: atg?.atgBuildingId ?? building.atgBuildingId,
      buildingFloorList: building.buildingFloorList?.filter(
        (floor) => floor.floorName
      ),
      buildingVocList: vocList.map(
        (voc) =>
          ({
            vocUnitCode: voc.code,
            vocId: voc.vocId,
            isActive: true,
          } as BuildingVoc)
      ),
      ...(!building.imageFile && { deleteFileInfo: building.deleteFileInfo }),
    };

    building.imageFile && formData.append("imageFile", building.imageFile);
    formData.append("buildingCreateRequest", JSON.stringify(data));

    return formData;
  };

  const renderVocList = () => {
    return vocList.map((voc, index) => (
      <FormRow key={index}>
        <FormRowLabel title={voc.codeName}>
          <DefaultInput
            value={voc.vocId}
            onChange={handleBuildingVocIdChange(voc.code)}
            placeholder={`${voc.codeName ?? ""} 입력`}
          />
        </FormRowLabel>
      </FormRow>
    ));
  };

  const handleClosePopup = () => {
    setIsOpen(false);
  };

  return (
    <div className="flex flex-col gap-5">
      {isLoading && <Spinner />}
      <div className="flex flex-col min-w-[900px] max-h-[700px] overflow-y-auto">
        <FormHeader title="등록 정보" />
        <FormRow>
          <FormRowLabel title="건물 ID">
            <DefaultLabel text={building.buildingId ?? DASH} />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <div className="flex gap-2 w-full">
            <FormRowLabel title="등록일시">
              <DefaultLabel text={building.createdAt ?? DASH} />
            </FormRowLabel>
            <FormRowLabel title="최종 수정일시">
              <DefaultLabel text={building.modifiedAt ?? DASH} />
            </FormRowLabel>
          </div>
        </FormRow>

        <FormHeader title="기본 정보" />
        <FormRow>
          <FormRowLabel title="건물명" isRequired>
            <DefaultInput
              value={building.buildingName}
              onChange={handleBuildingChange("buildingName")}
              placeholder="건물명을 입력해주세요"
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="대표 사진">
            <SingleImageUploader
              image={building.imageFile}
              onChange={handleBuildingChange("imageFile")}
              defaultImage={getImageByScale(
                building.fileInfo?.items ?? [],
                FileScale.MEDIUM
              )}
              onDelete={() => {
                handleBuildingChangeObject({
                  fileInfo: undefined,
                  deleteFileInfo: true,
                });
              }}
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <div className="flex gap-2">
            <FormRowLabel title="고객센터" isRequired>
              <DefaultInput
                value={building.helpDeskTel}
                type="tel"
                onChange={handleBuildingChange("helpDeskTel")}
                placeholder="고객센터 번호를 입력해주세요"
              />
            </FormRowLabel>
            <FormRowLabel title="아워홈 상점코드">
              <DefaultInput
                value={building.shopCode}
                onChange={handleBuildingChange("shopCode")}
                placeholder="아워홈 상점코드를 입력해주세요"
              />
            </FormRowLabel>
          </div>
        </FormRow>
        <FormRow>
          <FormRowLabel title="사용 유무">
            <RadioButtonGroup
              value={building?.isActive?.toString()}
              onChange={(value: string) =>
                handleBuildingChange("isActive")(value === BOOLEAN_TYPES.TRUE)
              }
              options={USAGE_OPTIONS}
            />
          </FormRowLabel>
        </FormRow>

        <FormHeader
          title="상세 정보"
          right={
            <span className="text-sm">
              ※위경도 정보는 구글 지도서비스(
              <a
                href="https://map.google.com"
                target="_blank"
                className="underline"
              >
                map.google.com
              </a>
              )를 이용하여 검색할 수 있습니다
            </span>
          }
        />
        <FormRow>
          <FormRowLabel title="주소">
            <DefaultInput
              value={building.address}
              onChange={handleBuildingChange("address")}
              minWidth="w-full"
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="위경도" isRequired>
            <div className="flex gap-2">
              <DefaultInput
                value={building?.lat?.toString()}
                label="위도"
                placeholder="위도 입력"
                onChange={handleBuildingChange("lat")}
              />
              <DefaultInput
                value={building?.lng?.toString()}
                label="경도"
                placeholder="경도 입력"
                onChange={handleBuildingChange("lng")}
              />
            </div>
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="날씨기준 정보" isRequired>
            <div className="flex gap-2">
              <DefaultInput
                value={building?.gridX?.toString()}
                label="격자 X"
                placeholder="격자 X 입력"
                onChange={handleBuildingChange("gridX")}
              />
              <DefaultInput
                value={building?.gridY?.toString()}
                label="격자 Y"
                placeholder="격자 Y 입력"
                onChange={handleBuildingChange("gridY")}
              />
              <DefaultInput
                value={building?.airMonitoringStation}
                label="측정소"
                placeholder="측정소명을 입력해주세요"
                onChange={handleBuildingChange("airMonitoringStation")}
              />
            </div>
          </FormRowLabel>
        </FormRow>

        <FormHeader
          title="엣지 정보"
          right={
            <DefaultButton size="popupDefault" onClick={() => setIsOpen(true)}>
              엣지 정보 조회
            </DefaultButton>
          }
        />
        <FormRow>
          <FormRowLabel title="엣지 정보">
            <div className="flex flex-col gap-1.5">
              <DefaultInput
                value={atg?.departmentName ?? building.atgDepartmentName}
                label="엣지부서명"
                placeholder="엣지부서명을 입력해주세요"
              />
              <DefaultInput
                value={atg?.businessPlaceName ?? building.atgCompanyName}
                label="엣지사업장명"
                placeholder="엣지사업장명을 입력해주세요"
              />
              <DefaultInput
                value={atg?.atgBuildingId ?? building.atgBuildingId}
                label="엣지 ID"
                placeholder="엣지 ID를 입력해주세요"
              />
            </div>
          </FormRowLabel>
        </FormRow>

        <FormHeader title="불편신고 VOC 연결 서비스" />
        {renderVocList()}

        <FormHeader title="층 정보" />
        <FormRow>
          <FloorList
            crudProcessType="UPDATE"
            data={building.buildingFloorList ?? []}
            onChange={handleBuildingChange("buildingFloorList")}
          />
        </FormRow>
      </div>

      <div className="flex justify-center gap-2">
        <DefaultButton onClick={closePopup}>닫기</DefaultButton>
        <PermissionWrapper>
          <DefaultButton color="primary" onClick={handleUpdate}>
            수정
          </DefaultButton>
        </PermissionWrapper>
      </div>

      {isOpen && (
        <CommonPopup
          header="엣지 정보 조회"
          content={
            <AtgBuildingTagSingleFilter
              onClosePopup={handleClosePopup}
              onChange={(value: AtgBuilding) => {
                setAtg(value);
                if (vocList.length) {
                  setVocList((prev) =>
                    prev.map((item) => ({
                      ...item,
                      vocId: value.vocManagementUnit,
                    }))
                  );
                }
              }}
            />
          }
          closePopup={handleClosePopup}
          isOpen={isOpen}
        />
      )}
    </div>
  );
}
