import { useEffect, useState } from "react";

import {
  RESERVATION_STATUS_TYPES,
  ReservationInfo,
  reservationStatusValue,
} from "../../../types/spaceReservation";

import { getTimeInMinutes } from "../../../utils/dateUtils";
import {
  getPrintTimeRange,
  isDisabledCheckbox,
} from "../../../libs/spaceReservation";

import { useModal } from "../../../contexts/Modal";

import Checkbox from "../Checkbox";

import { BsSlashLg } from "react-icons/bs";
import { RESERVATION_SPACE_TYPES, reservationSpaceType } from "@/types/space";

type Props = {
  spaceReservationDetailId: string;
  usePayment?: boolean;
  reservationList: ReservationInfo[];
  availableTimeStart: string;
  availableTimeEnd: string;
  allTime?: boolean;
  disabled?: boolean;
  reservationStatus?: reservationStatusValue;
  onAllTimeChange: (value: boolean) => void;
  onTimesChange: (
    reservationTimeStart: string,
    reservationTimeEnd: string
  ) => void;
  reservationSpaceType?: reservationSpaceType;
  useBlockTime: boolean;
  blockTimeStart?: string;
  blockTimeEnd?: string;
};

type ReservationCheck = {
  checked: boolean;
  status: reservationStatusValue;
};

export default function CheckboxTimes({
  spaceReservationDetailId,
  usePayment,
  reservationList,
  availableTimeStart,
  availableTimeEnd,
  allTime = false,
  disabled = false,
  reservationStatus,
  onAllTimeChange,
  onTimesChange,
  reservationSpaceType = RESERVATION_SPACE_TYPES.DEFAULT,
  useBlockTime = false,
  blockTimeStart,
  blockTimeEnd,
}: Props) {
  const { showAlert } = useModal();
  const [reservations, setReservations] = useState<ReservationCheck[]>([]);
  const [checkedBoxes, setCheckedBoxes] = useState<boolean[]>([]);
  const [printTimeRange, setPrintTimeRange] = useState<string[]>([]);

  const initReservationCHeck: ReservationCheck = {
    checked: false,
    status: "AVAILABLE",
  };

  useEffect(() => {
    const newCheckedBoxes = checkedBoxes.map(() => allTime);
    if (allTime) {
      setCheckedBoxes(newCheckedBoxes);
    } else {
      if (checkedBoxes.every((item) => item)) {
        setCheckedBoxes(newCheckedBoxes);
      }
    }
  }, [allTime]);

  useEffect(() => {
    if (availableTimeStart && availableTimeEnd) {
      initCheckbox();
    }
  }, [availableTimeStart, availableTimeEnd, reservationList]);

  const initCheckbox = () => {
    const [printTimeStart, printTimeEnd] = getPrintTimeRange(
      [availableTimeStart, availableTimeEnd],
      reservationList
    );
    setPrintTimeRange([printTimeStart, printTimeEnd]);
    const totalSlots = getSlot(printTimeEnd, printTimeStart);
    const newReservations: ReservationCheck[] = Array.from(
      { length: totalSlots },
      () => ({
        ...initReservationCHeck,
      })
    );
    const newCheckedBoxes = Array(totalSlots).fill(false);

    reservationList.forEach((reservation) => {
      const resStartSlot = getSlot(
        reservation.reservationTimeStart,
        printTimeStart
      );
      const resEndSlot = getSlot(
        reservation.reservationTimeEnd,
        printTimeStart
      );
      let resStatus: reservationStatusValue;
      let cleanStatus: reservationStatusValue;

      if (spaceReservationDetailId === reservation.spaceReservationDetailId) {
        resStatus = "AVAILABLE";
        cleanStatus = "CLEANING";
      } else {
        resStatus = "COMPLETED";
        cleanStatus = "COMPLETED";
      }

      for (let i = resStartSlot; i < resEndSlot; i++) {
        if (i < totalSlots) {
          if (
            spaceReservationDetailId === reservation.spaceReservationDetailId
          ) {
            newCheckedBoxes[i] = true;
          } else {
            newReservations[i].checked = true;
          }
          newReservations[i].status = resStatus;
        }
      }

      if (usePayment) {
        const cleanStartSlot =
          getTimeInMinutes(reservation.beforeCleanTime) !==
          getTimeInMinutes(reservation.reservationTimeStart)
            ? getSlot(reservation.beforeCleanTime, printTimeStart)
            : -1;
        const cleanEndSlot =
          getTimeInMinutes(reservation.afterCleanTime) !==
          getTimeInMinutes(reservation.reservationTimeEnd)
            ? getSlot(reservation.afterCleanTime, printTimeStart)
            : -1;

        if (cleanStartSlot >= 0 && cleanStartSlot < newReservations.length) {
          if (newReservations[cleanStartSlot].status !== "COMPLETED") {
            newReservations[cleanStartSlot].checked = false;
            newReservations[cleanStartSlot].status = cleanStatus;
          }
        }

        if (cleanEndSlot > 0 && cleanEndSlot <= newReservations.length) {
          if (newReservations[cleanEndSlot - 1].status !== "COMPLETED") {
            newReservations[cleanEndSlot - 1].checked = false;
            newReservations[cleanEndSlot - 1].status = cleanStatus;
          }
        }
      }
    });

    // 샤워실 타입인 경우 특정 시간대를 비활성화 상태로 설정
    const updateCheckedBoxesForBlockTime = () => {
      const blockedTimeRange = {
        start: getTimeInMinutes(blockTimeStart as string),
        end: getTimeInMinutes(blockTimeEnd as string),
      };

      for (let i = 0; i < newCheckedBoxes.length; i++) {
        const currentTime = getTimeInMinutes(printTimeStart) + i * 30;
        if (
          currentTime >= blockedTimeRange.start &&
          currentTime < blockedTimeRange.end
        ) {
          newReservations[i].status = RESERVATION_STATUS_TYPES.COMPLETED; // 비활성화 상태로 설정
          newReservations[i].checked = true;
        }
      }
    };

    if (useBlockTime && blockTimeStart && blockTimeEnd) {
      updateCheckedBoxesForBlockTime();
    }

    setReservations(newReservations);
    setCheckedBoxes(newCheckedBoxes);
  };

  const getSlot = (time: string, baseTime: string): number => {
    const slot = (getTimeInMinutes(time) - getTimeInMinutes(baseTime)) / 30;
    return slot;
  };

  useEffect(() => {
    const checkedIndexes = checkedBoxes.reduce(
      (indexes: number[], isChecked: boolean, i: number) => {
        if (isChecked) {
          indexes.push(i);
        }
        return indexes;
      },
      []
    );

    if (checkedBoxes.some((item) => item === false)) {
      onAllTimeChange(false);
    }

    if (checkedIndexes.length > 0) {
      const [startHour, startMinute] = printTimeRange[0].split(":").map(Number);

      const startMinutes =
        checkedIndexes[0] * 30 + (startHour * 60 + startMinute);
      const endMinutes =
        (checkedIndexes[checkedIndexes.length - 1] + 1) * 30 +
        (startHour * 60 + startMinute);

      const reservationTimeStart = `${Math.floor(startMinutes / 60)
        .toString()
        .padStart(2, "0")}:${(startMinutes % 60).toString().padStart(2, "0")}`;

      const reservationTimeEnd = `${Math.floor(endMinutes / 60)
        .toString()
        .padStart(2, "0")}:${(endMinutes % 60).toString().padStart(2, "0")}`;

      onTimesChange(reservationTimeStart, reservationTimeEnd);
    } else {
      onTimesChange("", "");
    }
  }, [checkedBoxes]);

  const getDisplayTime = (index: number) => {
    if (printTimeRange[0] && printTimeRange[1]) {
      const [startHour, startMinute] = printTimeRange[0].split(":").map(Number);
      const baseMinutes = startHour * 60 + startMinute;
      const currentMinutes = baseMinutes + index * 30;
      const nextMinutes = currentMinutes + 30;
      const hour = Math.floor(currentMinutes / 60);
      const minute = currentMinutes % 60;
      const nextHour = Math.floor(nextMinutes / 60);
      const nextMinute = nextMinutes % 60;
      return `${hour.toString().padStart(2, "0")}:${minute
        .toString()
        .padStart(2, "0")} \n~\n ${nextHour
        .toString()
        .padStart(2, "0")}:${nextMinute.toString().padStart(2, "0")}`;
    } else {
      return "";
    }
  };

  const changeReservation = (index: number) => {
    if (
      reservations[index].checked ||
      reservations[index].status === "COMPLETED"
    ) {
      return;
    }

    const newCheckedBoxes = [...checkedBoxes];
    // 예약 가능 시간 밖에 출력물이 추가되어 가능한 범위에서는 정상 클릭 반응을 위해 index 범위 계산
    const availableStartIndex = getSlot(availableTimeStart, printTimeRange[0]);
    const availableEndIndex = getSlot(availableTimeEnd, printTimeRange[0]);
    // 위 범위를 이용해서 첫번째 true 값과 마지막 true 값의 index를 찾아서 사용중인 경우 마지막 시간대만 수정 가능하도록 제한
    const firstTrueIndex = checkedBoxes.findIndex(
      (value, index) => value === true && index >= availableStartIndex
    );
    const lastTrueIndex = checkedBoxes.findLastIndex(
      (value, index) => value === true && index < availableEndIndex
    );

    if (
      reservationStatus === "IN_USE" &&
      (lastTrueIndex > index || firstTrueIndex === index)
    ) {
      showAlert("사용중인 경우 마지막 시간대만 수정 가능합니다.");
      return;
    }

    if (newCheckedBoxes[index]) {
      if (firstTrueIndex === index) {
        for (let i = index; i >= 0; i--) {
          newCheckedBoxes[i] = false;
        }
      } else if (lastTrueIndex === index) {
        for (let i = index; newCheckedBoxes[i] === true; i++) {
          newCheckedBoxes[i] = false;
        }
      } else {
        showAlert("처음과 마지막 시간대만 체크해제 가능합니다.");
      }
    } else {
      const startIndex = Math.min(firstTrueIndex, index);
      const endIndex = Math.max(lastTrueIndex, index);

      if (startIndex > -1) {
        let canCheck = true;
        for (let i = startIndex; i <= endIndex; i++) {
          if (reservations[i].checked) {
            canCheck = false;
            break;
          }
        }

        if (canCheck) {
          for (let i = startIndex; i <= endIndex; i++) {
            newCheckedBoxes[i] = true;
          }
        } else {
          showAlert("이미 예약된 시간대가 포함되어 있습니다.");
        }
      } else {
        newCheckedBoxes[index] = true;
      }
    }
    setCheckedBoxes(newCheckedBoxes);
  };

  const renderCell = (
    status: reservationStatusValue,
    checked: boolean,
    index: number,
    disabled: boolean,
    getDisplayTime: (index: number) => string
  ) => {
    const displayTime = getDisplayTime(index).split("\n")[0].trim();
    if (status === "AVAILABLE") {
      return (
        <Checkbox
          checked={checkedBoxes[index]}
          inline
          className="h-full w-full flex justify-center items-center"
          disabled={disabled}
          testId={`checkbox-${displayTime}`}
        />
      );
    } else if (status === "CLEANING") {
      return (
        <div className="w-full h-full">
          <Checkbox
            checked={checkedBoxes[index]}
            inline
            className="bg-red-400 h-full w-full flex justify-center items-center"
            disabled={disabled}
            testId={`checkbox-${displayTime}`}
          />
        </div>
      );
    } else if (status === "COMPLETED") {
      return (
        <div className="relative w-full h-full">
          <BsSlashLg className="absolute h-full w-full text-gray-500" />
          <Checkbox
            checked={checked}
            inline
            disabled
            className="bg-gray-400 h-full w-full flex justify-center items-center"
            testId={`checkbox-${displayTime}`}
          />
        </div>
      );
    }
    return null;
  };

  return (
    <div className="overflow-x-auto w-full">
      <table className="w-full">
        <thead>
          <tr>
            {reservations.map((res, index) => (
              <th
                key={index}
                className="text-center text-sm border p-1 bg-gray-100"
              >
                {getDisplayTime(index)
                  .split("\n")
                  .map((line, i) => (
                    <div key={i}>{line}</div>
                  ))}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr className="h-8">
            {reservations.map((res, index) => {
              const disabled = isDisabledCheckbox(
                printTimeRange,
                [availableTimeStart, availableTimeEnd],
                index,
                reservations.length,
                useBlockTime,
                blockTimeStart,
                blockTimeEnd
              );

              return (
                <td
                  key={index}
                  className="text-center border m-0 p-0 h-10"
                  onClick={() => {
                    if (disabled) {
                      showAlert("현재 상태에서는 시간 변경을 할 수 없습니다.");
                    } else {
                      changeReservation(index);
                    }
                  }}
                >
                  {renderCell(res.status, res.checked, index, disabled, () =>
                    getDisplayTime(index)
                  )}
                </td>
              );
            })}
          </tr>
        </tbody>
      </table>
    </div>
  );
}
