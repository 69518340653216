import { useState } from "react";
import Tab, { TabInfo } from "../../../components/Tab";
import DateList from "./DateList";
import SpaceReservationList from "./SpaceReservationList";

type Props = {};

export default function SpaceReservation(prop: Props) {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (tabIndex: number) => {
    setActiveTab(tabIndex);
  };

  const tabInfo: TabInfo[] = [
    {
      title: "일간",
      page: <DateList range="daily" onTabClick={handleTabClick} />,
    },
    {
      title: "주간",
      page: <DateList range="weekly" onTabClick={handleTabClick} />,
    },
    {
      title: "월간",
      page: <DateList range="monthly" onTabClick={handleTabClick} />,
    },
    { title: "목록", page: <SpaceReservationList /> },
  ];

  return (
    <Tab tabInfo={tabInfo} activeTab={activeTab} onTabClick={handleTabClick} />
  );
}
