import { HttpStatusCode } from "axios";
import clsx from "clsx";
import { useContext, useState } from "react";
import { useModal } from "../../../contexts/Modal";
import { PopupContext } from "../../../contexts/Popup";
import {
  validationFunctions,
  validationResultMessage,
} from "../../../libs/validations";
import { getCompanyExist, postCompany } from "../../../services/companyService";
import { Company } from "../../../types/company";
import { USAGE_OPTIONS } from "../../../types/comboBoxOption";
import DefaultButton from "../../DefaultButton";
import FormHeader from "../../Form/Header";
import FormRow from "../../Form/Row";
import FormRowLabel from "../../Form/RowLabel";
import DefaultInput from "../../Input/DefaultInput";
import RadioButtonGroup from "../../Input/RadioButtonGroup";
import Spinner from "../../Spinner";

type Props = {};

export default function CompanyCreateForm(props: Props) {
  const { showAlert, showConfirm, handleError } = useModal();
  const { closePopup, refreshAndClosePopup } = useContext(PopupContext);
  const [company, setCompany] = useState<Partial<Company>>({
    isActive: true,
  });
  const [isNameExist, setNameIsExist] = useState(false);
  const [isChecking, setIsChecking] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleCompanyChange = (data: Partial<Company>) => {
    setCompany((prev) => ({ ...prev, ...data }));
  };

  const handleNameExist = () => {
    const result = validationFunctions.required(company.companyName, "회사명");

    if (!result.isValid) {
      showAlert(result.errorMessage);
      return;
    }

    setIsLoading(true);

    getCompanyExist(company.companyName ?? "")
      .then((response) => {
        setNameIsExist(Boolean(response.data));
        setIsChecking(true);
      })
      .catch((err: any) => {
        setNameIsExist(false);
        handleError(err, "중복 체크");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handlePost = () => {
    const errorMessage = validationResultMessage([
      validationFunctions.required(company.companyName, "회사명"),
      validationFunctions.boolean(company.isActive, "회사 사용 여부"),
    ]);

    if (errorMessage) {
      showAlert(errorMessage);
      return;
    }

    if (!isChecking || isNameExist) {
      showAlert("회사명 중복 체크를 해주세요.");
      return;
    }

    showConfirm("등록 하시겠습니까?", postData);
  };

  const postData = async () => {
    try {
      const response = await postCompany(company);
      if (response.status === HttpStatusCode.Ok) {
        showAlert("등록이 완료되었습니다.");
        refreshAndClosePopup();
      }
    } catch (err: any) {
      handleError(err, "등록");
    }
  };

  const renderCompanyNameExists = () => {
    const getLabelClassName = clsx("text-xs px-2.5 pt-2.5", {
      "text-brand-primary-magenta": isNameExist,
      "text-brand-blue": !isNameExist,
    });

    return (
      <label className={getLabelClassName}>
        {isNameExist
          ? "이미 등록된 회사 이름입니다. 다른 회사 이름을 입력해주세요."
          : "등록 가능한 회사 이름입니다."}
      </label>
    );
  };

  return (
    <>
      {isLoading && <Spinner />}
      <div className="flex flex-col gap-5 min-w-[700px]">
        <div>
          <FormHeader title="기본 정보" />
          <FormRow>
            <FormRowLabel title="회사명" isRequired>
              <div className="flex flex-col">
                <div className="flex gap-2 items-center">
                  <DefaultInput
                    value={company?.companyName}
                    onChange={(value: string) => {
                      handleCompanyChange({ companyName: value });
                      setIsChecking(false);
                    }}
                    placeholder="회사명을 입력해주세요"
                    maxLength={50}
                    hideMaxLength
                  />

                  <DefaultButton
                    className="min-w-fit"
                    size="popupDefault"
                    onClick={handleNameExist}
                  >
                    회사이름 중복 조회
                  </DefaultButton>
                </div>
                {isChecking && renderCompanyNameExists()}
              </div>
            </FormRowLabel>
          </FormRow>

          <FormRow>
            <FormRowLabel title="메일 도메인">
              <DefaultInput
                value={company?.mailDomain}
                onChange={(value: string) =>
                  handleCompanyChange({ mailDomain: value })
                }
                placeholder="메일 도메인을 입력해주세요"
                maxLength={100}
                hideMaxLength
              />
            </FormRowLabel>
          </FormRow>
          <FormRow>
            <FormRowLabel title="메일 서브 도메인">
              <DefaultInput
                value={company?.subMailDomain}
                onChange={(value: string) =>
                  handleCompanyChange({ subMailDomain: value })
                }
                placeholder="메일 서브 도메인을 입력해주세요"
                maxLength={100}
                hideMaxLength
              />
            </FormRowLabel>
          </FormRow>
          <FormRow>
            <FormRowLabel title="회사 사용 여부">
              <RadioButtonGroup
                options={USAGE_OPTIONS}
                value={company.isActive?.toString()}
                onChange={(value: string) =>
                  handleCompanyChange({ isActive: value === "true" })
                }
              />
            </FormRowLabel>
          </FormRow>
        </div>

        <div className="flex justify-center gap-2 py-5">
          <DefaultButton onClick={closePopup}>닫기</DefaultButton>
          <DefaultButton color="primary" onClick={handlePost}>
            등록
          </DefaultButton>
        </div>
      </div>
    </>
  );
}
