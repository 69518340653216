import { AxiosResponse } from "axios";

import {
  EmergencyBuilding,
  EmergencyBuildingGroup,
  EmergencyBuildingGroupRequest,
  EmergencyDashboard,
  EmergencyDashboardComment,
  EmergencyDashboardCommentRequest,
  EmergencyDashboardGroup,
  EmergencyDashboardTableInfo,
  EmergencyDashboardTableRequest,
  EmergencyDepartment,
  EmergencySituationCount,
  EvacuationUser,
  EvacueeStatusHistory,
  Shelter,
  Situation,
} from "@/types/emergency";

import request, { ListResponse } from "@/libs/request";

import { SearchCondition } from "../components/ListPage";

const SHELTER_API_PREFIX = "/v2/admin/evacuation/shelter";
const EVACUEE_API_PREFIX = "/v2/admin/evacuation/evacuee";
const EVACUEE_STATUS_API_PREFIX = "/v2/admin/evacuation/evacuee-map";
const GROUP_API_PREFIX = "/v2/admin/evacuation/group";
const SITUATION_API_PREFIX = "/v2/admin/evacuation/situation";
const DASHBOARD_API_PREFIX = "/v2/admin/evacuation/dashboard";
const MANAGER_API_PREFIX = "/v2/manager/evacuation/evacuee";

// 대피영역 목록조회 (페이지네이션)
export const getShelterList = (params: SearchCondition<Shelter>) =>
  request.get<ListResponse<Shelter>>(SHELTER_API_PREFIX + "/list-page", {
    params,
  });
// 비상대피 인원 리스트 불러오기
export const getDashboardEvacueeList = (
  params: EmergencyDashboardTableRequest
) =>
  request.get<ListResponse<EmergencyDashboardTableInfo>>(
    MANAGER_API_PREFIX + "/list-page",
    {
      params,
    }
  );
// 대피영역 정보 상세 조회 - shelterId 필수
export const getShelter = (shelterId: string) =>
  request.get<Shelter>(SHELTER_API_PREFIX + `/${shelterId}`);
// 대피영역 정보 수정 - shelterId 및 data 필수
export const putShelter = (shelterId: string, data: FormData) =>
  request.put<Shelter>(SHELTER_API_PREFIX + `/${shelterId}`, data);
// 대피영역 등록
export const postShelter = (data: FormData) =>
  request.post(SHELTER_API_PREFIX, data);

// 참여자 목록조회 (페이지네이션)
export const getEvacueeList = (params: SearchCondition<EvacuationUser>) =>
  request.get<ListResponse<EvacuationUser>>(EVACUEE_API_PREFIX + "/list-page", {
    params,
  });
// 참여자 정보 상세 조회 - evacueeId 필수
export const getEvacuee = (evacueeId: string) =>
  request.get<EvacuationUser>(EVACUEE_API_PREFIX + `/${evacueeId}`);
// 참여자 일괄 업로드 양식 다운로드
export const getEvacueeBulkExcelForm = () =>
  request.get(EVACUEE_API_PREFIX + "/excel/upload-form", {
    responseType: "blob",
    params: {},
  });
// 참여자 정보 수정 - evacueeId 및 data 필수
export const putEvacuee = (evacueeId: string, data: Partial<EvacuationUser>) =>
  request.put<EvacuationUser>(EVACUEE_API_PREFIX + `/${evacueeId}`, data);
// 참여자 등록
export const postEvacuee = (data: Partial<EvacuationUser>) =>
  request.post<EvacuationUser>(EVACUEE_API_PREFIX, data);
// 참여자 엑셀 등록
export const postEvacueeBulkExcel = (data: FormData) =>
  request.post(EVACUEE_API_PREFIX + "/excel/list", data);
// 참여자 정보 삭제
export const deleteEvacuee = (evacueeId: number) =>
  request.delete(EVACUEE_API_PREFIX + `/${evacueeId}`);

// 상황 목록조회 (페이지네이션)
export const getSituationList = (params: SearchCondition<Situation>) =>
  request.get<ListResponse<Situation>>(SITUATION_API_PREFIX + "/list", {
    params,
  });
// 상황 상세 조회 - situationId 필수
export const getSituation = (situationId: string) =>
  request.get<Situation>(SITUATION_API_PREFIX + `/${situationId}`);
// 상황 등록
export const postSituation = (data: Partial<Situation>) =>
  request.post(SITUATION_API_PREFIX, data);
// 상황 수정 - situationId 및 data 필수
export const putSituation = (situationId: string, data: Partial<Situation>) =>
  request.put<Situation>(SITUATION_API_PREFIX + `/${situationId}`, data);

export const getSituationGroupList = (groupId: string) =>
  request.get(SITUATION_API_PREFIX + `/group/${groupId}`);

// 상황 시작 처리
export const putSituationStart = (situationId: string) =>
  request.put(SITUATION_API_PREFIX + `/${situationId}/start`);

// 상황 종료 처리
export const putSituationEnd = (situationId: string) =>
  request.put(SITUATION_API_PREFIX + `/${situationId}/end`);

export const getEvacuationGroupBuildingList = (groupId: string) =>
  request.get<EmergencyBuilding[]>(SITUATION_API_PREFIX + `group/${groupId}`);

// 비상 대피 건물 그룹 리스트 가져오기
export const getEmergencyBuildingGroupList = (
  params: SearchCondition<EmergencyBuildingGroup>
) =>
  request.get<ListResponse<EmergencyBuildingGroup>>(
    GROUP_API_PREFIX + "/list",
    {
      params,
    }
  );

// 완료
export const postEmergencyBuildingGroup = (
  params: EmergencyBuildingGroupRequest
) => request.post<AxiosResponse<string>>(GROUP_API_PREFIX, params);

// groupId 로 특정 group 정보 가져오기
export const getEmergencyBuildingGroupInfo = (evacuationGroupId: string) =>
  request.get<EmergencyBuildingGroup>(
    GROUP_API_PREFIX + `/${evacuationGroupId}`
  );

// groupId 로 특정 group 의 모든 건물 및 활성화된 회사 정보를 가져오기
export const getEmergencyBuildingGroupCompanyEnabled = (groupId: string) =>
  request.get<EmergencyBuildingGroup>(
    SITUATION_API_PREFIX + "/list-group-building",
    { params: { groupId: groupId } }
  );

export const putEmergencyBuildingGroupInfo = (
  evacuationGroupId: string,
  params: EmergencyBuildingGroupRequest
) =>
  request.put<AxiosResponse<string>>(
    GROUP_API_PREFIX + `/${evacuationGroupId}`,
    params
  );

export const postBuildingShelterMapping = (
  evacuationGroupId: string,
  params: EmergencyBuilding[]
) =>
  request.post<AxiosResponse>(
    GROUP_API_PREFIX + `/${evacuationGroupId}`,
    params
  );

export const getEmergencyDashboard = (evacuationId: string) =>
  request.get<EmergencyDashboard>(
    DASHBOARD_API_PREFIX + `/info/${evacuationId}`
  );

export const getEmergencyDashboardGroupInfo = (evacuationId: string) =>
  request.get<EmergencyDashboardGroup>(
    DASHBOARD_API_PREFIX + `/organization/info/${evacuationId}`
  );

export const getEmergencyDashboardDetail = (
  evacuationId: string,
  params: Partial<EmergencyDepartment>
) =>
  request.get<EmergencySituationCount>(
    DASHBOARD_API_PREFIX + `/organization/response/${evacuationId}`,
    { params }
  );

export const getEmergencyDashboardCommentList = (evacuationId: string) =>
  request.get<EmergencyDashboardComment[]>(
    DASHBOARD_API_PREFIX + `/comment/${evacuationId}`
  );

export const postEmergencyDashboardComment = (
  evacuationId: string,
  params: EmergencyDashboardCommentRequest
) =>
  request.post<EmergencyDashboardComment>(
    DASHBOARD_API_PREFIX + `/comment/${evacuationId}`,
    params
  );

export const deleteEmergencyDashboardComment = (evacuationCommentId: string) =>
  request.delete<AxiosResponse>(
    DASHBOARD_API_PREFIX + `/comment/${evacuationCommentId}`
  );

export const putEmergencyDashboardComment = (
  evacuationCommentId: string,
  comment: string
) =>
  request.put<AxiosResponse>(
    DASHBOARD_API_PREFIX + `/comment/${evacuationCommentId}`,
    { comment }
  );

export const getMockEmergencyBuildingList = (
  params: SearchCondition<EmergencyBuilding>
) =>
  request.get<ListResponse<EmergencyBuilding>>("/api/mock", {
    params,
  });

export const getEvacueeStatusHistoryList = (
  params: SearchCondition<EvacueeStatusHistory>
) =>
  request.get<ListResponse<EvacueeStatusHistory>>(
    EVACUEE_STATUS_API_PREFIX + "/history/list",
    { params }
  );
