type Props = {};

export default function MemberMenuIcon(props: Props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.6">
        <path
          d="M16.5 19H19V17.5C19 16.1193 17.8807 15 16.5 15M13 7.99804C13 9.65396 11.657 10.9961 10 10.9961C8.343 10.9961 7 9.65396 7 7.99804C7 6.34212 8.343 5 10 5C11.657 5 13 6.34212 13 7.99804ZM15 18.9909H5C5 18.2808 5 17.6053 5 16.9936C5 15.3368 6.34315 13.9941 8 13.9941H12C13.6569 13.9941 15 15.3368 15 16.9936C15 17.6053 15 18.2808 15 18.9909ZM16 13C14.8954 13 14 12.1046 14 11C14 9.89543 14.8954 9 16 9C17.1046 9 18 9.89543 18 11C18 12.1046 17.1046 13 16 13Z"
          stroke="white"
        />
      </g>
    </svg>
  );
}
