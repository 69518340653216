import { SearchCondition } from "../components/ListPage";
import request, { ListResponse } from "../libs/request";
import { AirInfo, Tag, TagType, ToiletInfo, ToiletTag } from "../types/tag";
import { getExcelDownloadApi } from "./commonService";

const TAG_API_PREFIX = "/v2/admin/iot/tag";

const TAG_STATUS_API_PREFIX = "/v2/admin/iot/status";

export const getTagList = (params: SearchCondition<Tag>) =>
  request.get<ListResponse<Tag>>(TAG_API_PREFIX + "/list", { params });

export const getTagBulkExcel = () =>
  request.get(TAG_API_PREFIX + "/excel/upload-form", { responseType: "blob" });

export const postTag = (data: Partial<Tag>) =>
  request.post(TAG_API_PREFIX, data);

export const postTagBulk = (data: FormData) =>
  request.post(TAG_API_PREFIX + "/list", data);

export const getTagType = () =>
  request.get<TagType[]>(TAG_API_PREFIX + "/type");

export const patchTag = (id: number) =>
  request.patch(TAG_API_PREFIX, null, { params: { id: id } });

export const getTagGroupType = () => request.get(TAG_API_PREFIX + "/groupType");

export const getTagExists = (tagId: string) =>
  request.get(TAG_API_PREFIX + "/exists/id", { params: { tagId: tagId } });

export const getSensorList = (params: SearchCondition<Tag>) =>
  request.get<ListResponse<Tag>>(TAG_API_PREFIX + "/list/sensor", { params });

export const getTag = (id: number) =>
  request.get<Tag>(TAG_API_PREFIX + `/${id}`);

export const putTag = (data: Partial<Tag>) => request.put(TAG_API_PREFIX, data);

export const getToiletTagList = (params: SearchCondition<ToiletTag>) =>
  request.get<ListResponse<ToiletTag>>(TAG_API_PREFIX + "/list/toilet", {
    params,
  });

export const getTagTypeList = () =>
  request.get<TagType[]>(TAG_API_PREFIX + "/type");

export const getTagStatusToiletInOut = (params: SearchCondition<ToiletInfo>) =>
  request.get<ListResponse<ToiletInfo>>(
    TAG_STATUS_API_PREFIX + "/toilet/inout",
    {
      params,
    }
  );

export const getTagListExcel = (params: SearchCondition<Tag>) =>
  getExcelDownloadApi(TAG_API_PREFIX + "/list/sensor/excel", params);

export const getToiletTagListExcel = (params: SearchCondition<ToiletTag>) =>
  getExcelDownloadApi(TAG_API_PREFIX + "/list/toilet/excel", params);

export const getTagStatusToiletInOutExcel = (
  params: SearchCondition<ToiletInfo>
) => getExcelDownloadApi(TAG_STATUS_API_PREFIX + "/toilet/inout/excel", params);

export const getTagStatusToiletCompartment = (
  params: SearchCondition<ToiletInfo>
) =>
  request.get<ListResponse<ToiletInfo>>(
    TAG_STATUS_API_PREFIX + "/toilet/compartment",
    { params }
  );

export const getTagStatusToiletCompartmentExcel = (
  params: SearchCondition<ToiletInfo>
) =>
  getExcelDownloadApi(
    TAG_STATUS_API_PREFIX + "/toilet/compartment/excel",
    params
  );

export const getTagStatusToiletCleaning = (
  params: SearchCondition<ToiletInfo>
) =>
  request.get<ListResponse<ToiletInfo>>(
    TAG_STATUS_API_PREFIX + "/toilet/cleaning",
    { params }
  );

export const getTagStatusToiletCleaningExcel = (
  params: SearchCondition<ToiletInfo>
) =>
  getExcelDownloadApi(TAG_STATUS_API_PREFIX + "/toilet/cleaning/excel", params);

export const getTagStatusAir = (params: SearchCondition<AirInfo>) =>
  request.get<ListResponse<AirInfo>>(TAG_STATUS_API_PREFIX + "/air", {
    params,
  });

export const getTagStatusAirExcel = (params: SearchCondition<AirInfo>) =>
  getExcelDownloadApi(TAG_STATUS_API_PREFIX + "/air/excel", params);
