import { SearchCondition } from "../components/ListPage";
import request, { ListResponse } from "../libs/request";
import {
  GoodsRequest,
  GoodsServiceItem,
  GoodsRequestType,
  Parcel,
  ParcelBuilding,
  ParcelList,
} from "../types/applyService";
import { getExcelDownloadApi } from "./commonService";

const PARCEL_API_PREFIX = "/v2/admin/pudo";
const GOODS_REQUEST_API_PREFIX = "/v2/admin/goods/request";

export const getParcelList = (params: SearchCondition<Parcel>) =>
  request.get<ParcelList>(PARCEL_API_PREFIX, { params });

export const getParcelBuildingList = () =>
  request.get<ParcelBuilding[]>(PARCEL_API_PREFIX + "/building");

export const getPudoListExcel = (params: SearchCondition<Parcel>) =>
  getExcelDownloadApi(PARCEL_API_PREFIX + "/excel", params);

export const getGoodsRequestList = (params: SearchCondition<GoodsRequest>) => {
  const { buildingId, companyId, ...otherParams } = params;

  const newParams = {
    ...otherParams,
    ...(buildingId && { buildingIds: buildingId.join(",") }),
    ...(companyId && { companyIds: companyId.join(",") }),
  };

  return request.get<ListResponse<GoodsRequest>>(
    GOODS_REQUEST_API_PREFIX + "/list",
    {
      params: newParams,
    }
  );
};

export const getGoodsRequestListExcel = (
  params: SearchCondition<GoodsRequest>
) => {
  const { buildingId, companyId, ...otherParams } = params;

  const newParams = {
    ...otherParams,
    ...(buildingId && { buildingIds: buildingId.join(",") }),
    ...(companyId && { companyIds: companyId.join(",") }),
  };

  return getExcelDownloadApi(
    GOODS_REQUEST_API_PREFIX + "/list/excel",
    newParams
  );
};

export const getGoodsRequest = (goodsServiceId: string) =>
  request.get<GoodsRequest>(
    GOODS_REQUEST_API_PREFIX + `/detail/${goodsServiceId}`
  );

export const getGoodsRequestGoodsList = (
  companyId: string,
  goodsRequestType?: GoodsRequestType
) =>
  request.get<GoodsServiceItem[]>(
    GOODS_REQUEST_API_PREFIX + `/goods-list/${companyId}`,
    { params: { goodsRequestType } }
  );

export const patchGoodsRequestCompleted = (
  goodsServiceId: string,
  adminNotes: string
) =>
  request.patch(GOODS_REQUEST_API_PREFIX + `/${goodsServiceId}/completed`, {
    adminNotes,
  });

export const patchGoodsRequestNotes = (
  goodsServiceId: string,
  adminNotes: string
) =>
  request.patch(GOODS_REQUEST_API_PREFIX + `/${goodsServiceId}/notes`, {
    adminNotes,
  });
