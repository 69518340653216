import { HttpStatusCode } from "axios";
import { useEffect, useState } from "react";
import { FiEdit2 } from "react-icons/fi";
import DefaultButton from "../../../components/DefaultButton";
import ListPage from "../../../components/ListPage";
import { TableColumnType } from "../../../components/Table";
import { useModal } from "../../../contexts/Modal";
import { useUserContext } from "../../../contexts/User";
import { getBuildingGroupList } from "../../../services/buildingService";
import {
  getGoodsRentalList,
  getGoodsRentalListExcel,
} from "../../../services/goodsService";
import {
  GOODS_RENTAL_OPTIONS,
  GOODS_RENTAL_STATUS_OPTIONS,
} from "../../../types/comboBoxOption";
import {
  GoodsRental,
  goodsRentalItemLabels,
  goodsRentalStatusLabels,
} from "../../../types/goods";
import { ComboBoxType, ComponentType, DASH } from "../../../types/search";
import { addDefaultOption, toComboBoxType } from "../../../utils/comboBoxUtils";
import GoodsRentalDetailView from "../../../components/PageComponents/Service/GoodsRentalList/DetailView";
import { buildingGroupCategoryCodeValue } from "../../../types/building";

type Props = {};

export default function GoodsRentalList(props: Props) {
  const { handleError } = useModal();
  const { getCategoryCodeByRole } = useUserContext();

  const [buildingOptions, setBuildingOptions] = useState<ComboBoxType[]>([]);

  const columnInfo: TableColumnType<GoodsRental>[] = [
    { header: "서비스 ID", name: "goodsServiceId", width: "id" },
    {
      header: "상태",
      render: (item: GoodsRental) =>
        goodsRentalStatusLabels[item.status] ?? DASH,
      width: "70",
    },
    {
      header: "물품종류",
      render: (item: GoodsRental) =>
        goodsRentalItemLabels[item.goodsTypeId] ?? DASH,
      width: "70",
    },
    { header: "물품명", name: "goodsName" },
    {
      header: "대여장소",
      render: (item: GoodsRental) => (
        <div>
          {item.rentalBuildingName && `[${item.rentalBuildingName}]`}
          <br />
          {item.rentalStoreName ?? DASH}
        </div>
      ),
    },
    {
      header: "반납장소",
      render: (item: GoodsRental) => (
        <div>
          {item.returnBuildingName && `[${item.returnBuildingName}]`}
          <br />
          {item.returnStoreName ?? DASH}
        </div>
      ),
    },
    { header: "이용자 회사", name: "createdByCompanyName" },
    { header: "이용자 이름", name: "createdByName" },
    {
      header: "신청일시",
      render: (item: GoodsRental) => renderDateTime(item.createdAt),
      width: "date",
    },
    {
      header: "대여일시",
      render: (item: GoodsRental) => renderDateTime(item.rentalAt),
      width: "date",
    },
    {
      header: "반납예정일시",
      render: (item: GoodsRental) => renderDateTime(item.returnDueAt),
      width: "date",
    },
    {
      header: "반납일시",
      render: (item: GoodsRental) => renderDateTime(item.actualReturnAt),
      width: "date",
    },
    {
      header: "활동",
      render: (item: GoodsRental, handler) => (
        <div className="flex w-full h-full justify-center items-center">
          <DefaultButton
            size="tableIcon"
            color="blue"
            onClick={() => {
              if (handler?.popup) {
                handler.popup({
                  header: "대여 상세",
                  content: (
                    <GoodsRentalDetailView
                      goodsServiceId={item.goodsServiceId}
                    />
                  ),
                });
              }
            }}
          >
            <FiEdit2 />
          </DefaultButton>
        </div>
      ),
      width: "icon",
    },
  ];

  const renderDateTime = (dateTime: string) => {
    if (!dateTime) return DASH;

    const [date, time] = dateTime.split(" ");
    return (
      <div>
        {date}
        <br />
        {time}
      </div>
    );
  };

  useEffect(() => {
    const fetchBuildingList = async () => {
      try {
        const res = await getBuildingGroupList({
          categoryCode: getCategoryCodeByRole(
            buildingGroupCategoryCodeValue.RENTAL
          ),
        });

        if (res.status === HttpStatusCode.Ok) {
          setBuildingOptions(
            toComboBoxType(res.data, "buildingId", "buildingName")
          );
        }
      } catch (err: any) {
        handleError(err, "건물 목록 조회");
      }
    };

    fetchBuildingList();
  }, []);

  const componentList: ComponentType<GoodsRental>[] = [
    {
      typeName: "dateRange",
      keyName: "createdAt",
      category: "date",
      dateRangeType: "0",
    },
    {
      labelName: "건물명",
      typeName: "comboBox",
      keyName: "buildingId",
      comboBoxOptions: addDefaultOption(buildingOptions),
      category: "typeSearch",
    },
    {
      labelName: "물품 종류",
      typeName: "comboBox",
      keyName: "goodsTypeId",
      comboBoxOptions: addDefaultOption(GOODS_RENTAL_OPTIONS),
      category: "typeSearch",
    },
    {
      labelName: "상태",
      typeName: "comboBox",
      keyName: "status",
      comboBoxOptions: addDefaultOption(GOODS_RENTAL_STATUS_OPTIONS),
      category: "typeSearch",
    },
    {
      labelName: "서비스 ID",
      typeName: "text",
      keyName: "goodsServiceId",
      category: "detailSearch",
      placeholder: "서비스 ID를 입력해주세요",
    },
    {
      labelName: "물품명",
      typeName: "text",
      keyName: "goodsName",
      category: "detailSearch",
      placeholder: "물품명을 입력해주세요",
    },
    {
      labelName: "대여 장소명",
      typeName: "text",
      keyName: "rentalStoreName",
      category: "detailSearch",
      placeholder: "대여 장소명을 입력해주세요",
    },
    {
      labelName: "반납 장소명",
      typeName: "text",
      keyName: "returnStoreName",
      category: "detailSearch",
      placeholder: "반납 장소명을 입력해주세요",
    },
    {
      labelName: "이용자 회사명",
      typeName: "text",
      keyName: "userCompanyName",
      category: "detailSearch",
      placeholder: "이용자 회사명을 입력해주세요",
    },
    {
      labelName: "이용자 이름",
      typeName: "text",
      keyName: "userName",
      category: "detailSearch",
      placeholder: "이용자 이름을 입력해주세요",
    },
  ];

  return (
    <ListPage
      getDataApi={getGoodsRentalList}
      keyId={(item: GoodsRental) => item.goodsServiceId}
      componentList={componentList}
      columnInfo={columnInfo}
      tableTitle="이용 이력"
      excelDownloadApi={getGoodsRentalListExcel}
      needExcelDownloadReason
    />
  );
}
