import { useEffect, useState } from "react";
import { FiEdit2 } from "react-icons/fi";
import DefaultButton from "../../../../components/DefaultButton";
import ListPage from "../../../../components/ListPage";
import Spinner from "../../../../components/Spinner";
import { TableColumnType } from "../../../../components/Table";
import { useModal } from "../../../../contexts/Modal";
import { useUserContext } from "../../../../contexts/User";
import {
  getGoodsRequestGoodsList,
  getGoodsRequestList,
  getGoodsRequestListExcel,
} from "../../../../services/applyServiceService";
import UseNoIcon from "../../../../svgs/icons/UseYn/UseNoIcon";
import UseYesIcon from "../../../../svgs/icons/UseYn/UseYesIcon";
import {
  GoodsRequest,
  goodsRequestStatusValue,
  goodsRequestTypeValue,
} from "../../../../types/applyService";
import { ComboBoxType, ComponentType, DASH } from "../../../../types/search";
import {
  addDefaultOption,
  toComboBoxType,
} from "../../../../utils/comboBoxUtils";
import { SATISFACTION_OPTIONS } from "../../../../types/comboBoxOption";
import GoodsRequestDetailView from "../../../../components/PageComponents/Service/ApplyService/GoodsRequestList/DetailView";
import { HttpStatusCode } from "axios";

type Props = {};

export default function GoodsRequestList(props: Props) {
  const { userInfo, hasRole } = useUserContext();
  const { showAlert, handleError } = useModal();
  const [isLoading, setIsLoading] = useState(false);
  const [goodsOptions, setGoodsOptions] = useState<ComboBoxType[]>([]);

  const isAdmin = hasRole(["ROLE_ADMIN"]);

  const columnInfo: TableColumnType<GoodsRequest>[] = [
    { header: "서비스번호", name: "goodsServiceId", width: "id" },
    {
      header: "상태",
      render: (item: GoodsRequest) =>
        goodsRequestStatusValue[item.status] ?? DASH,
      width: "70",
    },
    {
      header: "구분",
      render: (item: GoodsRequest) =>
        goodsRequestTypeValue[item.requestType]?.requestName ?? DASH,
      width: "70",
    },
    { header: "건물명", name: "buildingName" },
    { header: "신청 층", name: "requestBuildingFloorName" },
    {
      header: "신청 장소",
      render: (item: GoodsRequest) =>
        goodsRequestTypeValue[item.requestType]?.location ?? DASH,
    },
    { header: "물품", render: (item: GoodsRequest) => getGoodsText(item) },
    { header: "신청회사", name: "companyName" },
    { header: "부서", name: "usedDepartment" },
    { header: "신청자이름", name: "createdByName" },
    { header: "접수일시", name: "createdAt", width: "date" },
    {
      header: "만족도평가",
      render: (item: GoodsRequest) => (
        <div className="flex justify-center items-center">
          {item.isSatisfactionCompleted ? <UseYesIcon /> : <UseNoIcon />}
        </div>
      ),
      width: "icon",
    },
    {
      header: "활동",
      render: (item: GoodsRequest, handler) => (
        <div className="flex justify-center items-center">
          <DefaultButton
            size="tableIcon"
            color="blue"
            onClick={() => {
              if (handler)
                handler.popup({
                  header: "사무용품 상세",
                  content: (
                    <GoodsRequestDetailView
                      goodsServiceId={item.goodsServiceId}
                    />
                  ),
                });
            }}
          >
            <FiEdit2 />
          </DefaultButton>
        </div>
      ),
      width: "icon",
    },
  ];

  const getGoodsText = (item: GoodsRequest) => {
    const { goodsServiceItemList } = item;
    const listLength = goodsServiceItemList?.length;

    const goodsName = goodsServiceItemList[0].goodsName;

    if (!listLength || !goodsName) return <span>{DASH}</span>;

    return (
      <span>
        {listLength > 1 ? `${goodsName} 외 ${listLength - 1}건` : goodsName}
      </span>
    );
  };

  const STATUS_OPTIONS: ComboBoxType[] = [
    { value: "REQUESTED", label: "예약" },
    { value: "COMPLETED", label: "처리 완료" },
    { value: "CANCELED", label: "취소" },
  ];

  const REQUEST_TYPE_OPTIONS: ComboBoxType[] = [
    { value: "REFILL", label: goodsRequestTypeValue.REFILL.requestName },
    { value: "PICK_UP", label: goodsRequestTypeValue.PICK_UP.requestName },
  ];

  const componentList: ComponentType<GoodsRequest>[] = [
    {
      typeName: "dateRange",
      keyName: "createdAt",
      category: "date",
    },
    {
      labelName: "상태",
      typeName: "comboBox",
      keyName: "goodsRequestStatus",
      category: "typeSearch",
      comboBoxOptions: addDefaultOption(STATUS_OPTIONS),
    },
    {
      labelName: "구분",
      typeName: "comboBox",
      keyName: "goodsRequestType",
      category: "typeSearch",
      comboBoxOptions: addDefaultOption(REQUEST_TYPE_OPTIONS),
    },
    {
      labelName: "만족도 평가",
      typeName: "comboBox",
      keyName: "isSatisfactionCompleted",
      category: "typeSearch",
      comboBoxOptions: addDefaultOption(SATISFACTION_OPTIONS),
    },
    {
      labelName: "건물 검색",
      typeName: "tagFilter",
      keyName: "tagFilter",
      category: "typeSearch",
      tagFilter: {
        tagFilterSearchType: "building",
        ...(!isAdmin && { tagFilterType: "goodsRequest" }),
      },
    },
    {
      labelName: "서비스번호",
      typeName: "text",
      keyName: "goodsServiceId",
      category: "detailSearch",
      placeholder: "서비스번호를 입력해주세요",
    },
    {
      labelName: "신청 회사",
      typeName: "text",
      keyName: "companyName",
      category: "detailSearch",
      placeholder: "신청 회사를 입력해주세요",
    },
    {
      labelName: "물품명",
      typeName: "text",
      keyName: "goodsName",
      category: "detailSearch",
      placeholder: "물품명을 입력해주세요",
    },
    {
      labelName: "물품명",
      typeName: "comboBox",
      keyName: "goodsId",
      category: "detailSearch",
      comboBoxOptions: addDefaultOption(goodsOptions),
    },
    {
      labelName: "신청자이름",
      typeName: "text",
      keyName: "createdByName",
      category: "detailSearch",
      placeholder: "신청자이름을 입력해주세요",
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      if (!userInfo?.companyId) {
        showAlert("회사 정보가 없습니다.");
        return;
      }

      try {
        setIsLoading(true);
        const res = await getGoodsRequestGoodsList(userInfo.companyId);
        if (res.status === HttpStatusCode.Ok) {
          setGoodsOptions(toComboBoxType(res.data, "goodsId", "goodsName"));
        }
      } catch (err: any) {
        handleError(err, "물품 조회");
      } finally {
        setIsLoading(false);
      }
    };

    if (!isAdmin) {
      fetchData();
    }
  }, []);

  const filteredComponentList = () => {
    const keys = isAdmin ? ["goodsId"] : ["companyName", "goodsName"];

    return componentList.filter(
      (component) => !keys.includes(component.keyName)
    );
  };

  return (
    <>
      {isLoading && <Spinner />}
      <ListPage
        getDataApi={getGoodsRequestList}
        componentList={filteredComponentList()}
        keyId={(item: GoodsRequest) => item.goodsServiceId}
        columnInfo={columnInfo}
        tableTitle="신청 목록"
        excelDownloadApi={getGoodsRequestListExcel}
        needExcelDownloadReason
      />
    </>
  );
}
