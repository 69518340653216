import React, { useEffect, useState } from "react";

import { getLocalStorage } from "../../../libs/localStorage";

import SessionExtendPopup from "../../Popup/SessionExtendPopup";

type Props = {};

export default function SessionExtendWrapper(props: Props) {
  const stringToTokenOnlyData = (tokenString: string) => {
    const jwtToken = JSON.parse(tokenString);
    return {
      refreshToken: jwtToken?.refreshToken,
      accessToken: jwtToken?.accessToken,
    };
  };

  const [jwtToken, setJwtToken] = useState(
    stringToTokenOnlyData(getLocalStorage("jwtToken") ?? "{}")
  );

  const handleChangeJwtToken = () => {
    const nextJwtTokenString = stringToTokenOnlyData(
      getLocalStorage("jwtToken") ?? "{}"
    );

    if (
      jwtToken.accessToken !== nextJwtTokenString.accessToken ||
      jwtToken.refreshToken !== nextJwtTokenString.refreshToken
    ) {
      setJwtToken(nextJwtTokenString);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(handleChangeJwtToken, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <SessionExtendPopup
      originToken={jwtToken}
      onChangeToken={handleChangeJwtToken}
    />
  );
}
