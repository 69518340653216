import { TagFilterType, TagType } from "../components/TagFilter";
import { ValidationFunction } from "../libs/validations";
import { CategoryData, CategoryReturnField } from "./category";
import { DEFAULT_SELECT_ALL_VALUE } from "./comboBoxOption";
import { IncommodityCategoryList } from "./incommodity";

export type TextInputType =
  | "text"
  | "number"
  | "password"
  | "checkbox"
  | "radio"
  | "tel";

export const DASH = "-";

export type ComponentCategory =
  | "date"
  | "referenceDate"
  | "typeSearch"
  | "detailSearch";

export const ComponentCategoryMap: Record<ComponentCategory, string> = {
  date: "접수일자",
  referenceDate: "기준일자",
  typeSearch: "유형검색",
  detailSearch: "상세검색",
};

export type InitialDataLoadedType = {
  [key in DataLoadedComponentType]: boolean;
};

export type DataLoadedComponentType = "buildingAndFloor" | "searchCondition";

export enum DataLoadedComponentEnum {
  BuildingAndFloor = "buildingAndFloor",
  SearchCondition = "searchCondition",
}

export type ComponentType<T> = {
  labelName?: string;
  placeholder?: string;
  typeName:
    | TextInputType
    | "comboBox"
    | "searchComboBox"
    | "date"
    | "dateRange"
    | "defaultDateRange"
    | "dateRangeRadio"
    | "tagFilter"
    | "categoryComboBox"
    | "buildingAndFloor"
    | "incommodityCategory";
  keyName: keyof T;
  comboBoxOptions?: ComboBoxType[];
  incommodityCategory?: {
    data: IncommodityCategoryList;
    allOptionLabels?: string[];
  };
  showAllOption?: boolean;
  validation?: ValidationFunction[];
  category?: ComponentCategory;
  categoryData?: {
    searchField: CategoryReturnField;
    optionData: CategoryData[];
    allOptionLabels?: string[];
    defaultOptions?: number[];
  };
  buildingGroupCategoryCode?: string;
  tagFilter?: {
    tagFilterSearchType?: TagType;
    tagFilterType?: TagFilterType;
  };
  dateRangeType?: string;
};

export type ComboBoxType = {
  value: string;
  label: string;
  disabled?: boolean;
};

export type SearchDateRange = {
  startDate?: string;
  endDate?: string;
};

export type SearchDateRangeRadio = {
  selectRadio?: string;
  dateRange?: SearchDateRange;
};

export type SearchBuildingCompany = {
  buildingIds?: string[];
  companyIds?: string[];
};

export type DataRangeButton = {
  value: number | typeof DEFAULT_SELECT_ALL_VALUE;
  label: string;
};
