import { useContext } from "react";
import { PopupContext } from "../../../../contexts/Popup";
import DefaultButton from "../../../DefaultButton";
import BuildingCreateForm from "../CreateForm";
import AtgBuildingTagSingleFilter from "../../../TagFilter/TagSingleFilter/AtgBuildingTagSingleFilter";
import PermissionWrapper from "../../../PermissionWrapper";

type Props = {};

export default function BuildingListTopRight(props: Props) {
  const { openPopup } = useContext(PopupContext);

  return (
    <>
      <PermissionWrapper>
        <DefaultButton
          size="tableTop"
          color="default"
          onClick={() =>
            openPopup({ header: "건물 등록", content: <BuildingCreateForm /> })
          }
        >
          건물 등록
        </DefaultButton>
      </PermissionWrapper>
      <PermissionWrapper>
        <DefaultButton
          size="tableTop"
          color="default"
          onClick={() =>
            openPopup({
              header: "엣지 건물 등록",
              content: <AtgBuildingTagSingleFilter />,
            })
          }
        >
          엣지 건물 등록
        </DefaultButton>
      </PermissionWrapper>
    </>
  );
}
