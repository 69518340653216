import React, { createContext, useContext, useEffect, useReducer } from "react";

interface ScrollContextProps {
  depth: number;
  dispatch: React.Dispatch<Action>;
  lockAndUnlockScroll: () => () => void;
}

type Action = { type: "LOCK_SCROLL" } | { type: "UNLOCK_SCROLL" };

const ScrollContext = createContext<ScrollContextProps | undefined>(undefined);

const scrollReducer = (state: number, action: Action): number => {
  switch (action.type) {
    case "LOCK_SCROLL":
      return state + 1;
    case "UNLOCK_SCROLL":
      return Math.max(state - 1, 0);
    default:
      return state;
  }
};

export const useLockScroll = () => {
  const context = useContext(ScrollContext);
  if (!context) {
    throw new Error("useLockScroll must be used within a ScrollProvider");
  }
  return context;
};

type Props = { children: React.ReactNode };

export const ScrollProvider = ({ children }: Props) => {
  const [depth, dispatch] = useReducer(scrollReducer, 0);

  useEffect(() => {
    const { style } = document.body;

    if (depth > 0) {
      const isOverflowHidden = style.overflow === "hidden";
      if (!isOverflowHidden) {
        // 스크롤 너비 계산. 전체 창 너비에서 스크롤이 콘텐츠가 차지하는 너비를 뺀다.
        // 이 값은 overflow가 hidden으로 설정 될 때 스크롤바가 사라지면서 생기는 공간을 메우기 위해 사용된다.
        const scrollbarWidth =
          window.innerWidth - document.documentElement.clientWidth;

        style.overflow = "hidden";
        style.paddingRight = `${scrollbarWidth}px`;
      }
    } else {
      style.overflow = "";
      style.paddingRight = "";
    }
  }, [depth]);

  const lockAndUnlockScroll = () => {
    dispatch({ type: "LOCK_SCROLL" });

    return () => {
      dispatch({ type: "UNLOCK_SCROLL" });
    };
  };

  return (
    <ScrollContext.Provider value={{ depth, dispatch, lockAndUnlockScroll }}>
      {children}
    </ScrollContext.Provider>
  );
};
