import clsx from "clsx";
import { ComboBoxType } from "../../../../../types/search";
import { WeekDay } from "../../../../../types/space";
import CheckboxDays from "../../../../Input/CheckboxDays";
import DefaultSelect from "../../../../SelectBox/DefaultSelect";
import DateTimePickerRange from "../../../../DateTime/DateTimePickerRange";
import { convertTimeToDate, formatDate } from "../../../../../utils/dateUtils";
import { useEffect } from "react";

type Props = {
  label?: string;
  selectedItem: string;
  weekDay: WeekDay;
  enabledWeekDay?: WeekDay;
  startTime: string;
  endTime: string;
  startDate?: Date;
  endDate?: Date;
  minuteStep?: number;
  onSelectedChange: (value: string) => void;
  onWeekDayChange: (weekday: WeekDay) => void;
  onStartTimeChange: (time: string) => void;
  onEndTimeChange: (time: string) => void;
};

type DayType = "weekday" | "weekend" | "allDay";

export const dayType = {
  select: "select",
  weekday: "weekday",
  weekend: "weekend",
  allDay: "allDay",
};

export const dayOptions: ComboBoxType[] = [
  { value: dayType.weekday, label: "평일" },
  { value: dayType.weekend, label: "주말" },
  { value: dayType.allDay, label: "항시" },
  { value: dayType.select, label: "직접 선택" },
];

export const initWeekValue = {
  monday: false,
  tuesday: false,
  wednesday: false,
  thursday: false,
  friday: false,
  saturday: false,
  sunday: false,
};

export const weekValueOptions = {
  select: initWeekValue,
  weekday: {
    monday: true,
    tuesday: true,
    wednesday: true,
    thursday: true,
    friday: true,
    saturday: false,
    sunday: false,
  },
  weekend: {
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: true,
    sunday: true,
  },
  allDay: {
    monday: true,
    tuesday: true,
    wednesday: true,
    thursday: true,
    friday: true,
    saturday: true,
    sunday: true,
  },
};

export const getWeekDaysSelectedOption = (weekDay: WeekDay) => {
  if (compareWeekDays(weekDay, weekValueOptions.allDay)) {
    return dayType.allDay;
  } else if (compareWeekDays(weekDay, weekValueOptions.weekend))
    return dayType.weekend;
  else if (compareWeekDays(weekDay, weekValueOptions.weekday))
    return dayType.weekday;
  else {
    return dayType.select;
  }
};

export const compareWeekDays = (
  weekDay1: WeekDay,
  weekDay2: WeekDay
): boolean => {
  return (Object.keys(weekDay1) as Array<keyof WeekDay>).every(
    (day) => weekDay1[day] === weekDay2[day]
  );
};

export default function SelectTimeDays({
  label,
  selectedItem,
  weekDay,
  enabledWeekDay,
  startTime,
  endTime,
  startDate,
  endDate,
  minuteStep = 1,
  onSelectedChange,
  onWeekDayChange,
  onStartTimeChange,
  onEndTimeChange,
}: Props) {
  useEffect(() => {
    if (enabledWeekDay) {
      const newDayOptions = determineDayOptions(enabledWeekDay);
      checkSelectedItem(newDayOptions);
    }
  }, [enabledWeekDay]);

  const handleSelectChange = (value: string) => {
    onSelectedChange(value);

    if (value in weekValueOptions) {
      onWeekDayChange(weekValueOptions[value as DayType]);
    }
  };

  const renderCheckboxDays = () => {
    if (selectedItem === dayType.select) {
      return (
        <CheckboxDays
          weekDay={weekDay}
          enabledWeekDay={enabledWeekDay}
          onWeekDayChange={onWeekDayChange}
        />
      );
    } else {
      return null;
    }
  };

  const determineDayOptions = (RequiredWeekDay: WeekDay): ComboBoxType[] => {
    const matchingKeys = Object.entries(weekValueOptions)
      .filter(([_, value]) =>
        Object.entries(value).every(
          ([day, isAvailable]) =>
            Number(RequiredWeekDay[day as keyof WeekDay]) >=
            (isAvailable ? 1 : 0)
        )
      )
      .map(([key]) => key);

    return dayOptions.map((option) => ({
      ...option,
      disabled:
        option.value !== dayType.select && !matchingKeys.includes(option.value),
    }));
  };

  const checkSelectedItem = (options: ComboBoxType[]) => {
    const isDisabled = options.find((o) => o.value === selectedItem)?.disabled;

    if (isDisabled) {
      onSelectedChange(dayType.select);
    }
  };

  const getDivClassName = clsx("flex gap-2", {
    "flex-col": selectedItem === dayType.select,
  });

  return (
    <>
      {label && <span className="block text-label">{label}</span>}
      <div className={getDivClassName}>
        <div className="flex gap-2">
          <DefaultSelect
            value={selectedItem}
            optionList={
              enabledWeekDay ? determineDayOptions(enabledWeekDay) : dayOptions
            }
            onChange={handleSelectChange}
          />
          {renderCheckboxDays()}
        </div>
        <DateTimePickerRange
          startPeriod={convertTimeToDate(startTime ?? "")}
          endPeriod={convertTimeToDate(endTime ?? "")}
          onlyTime
          minuteStep={minuteStep}
          startDate={startDate}
          endDate={endDate}
          onStartPeriodChange={(value: Date) => {
            onStartTimeChange(formatDate(value, "HH:mm"));
          }}
          onEndPeriodChange={(value: Date) => {
            onEndTimeChange(formatDate(value, "HH:mm"));
          }}
        />
      </div>
    </>
  );
}
