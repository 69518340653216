import request, { ListResponse } from "../libs/request";

import { NullableObject } from "@/types/common";
import {
  GoodsRentalStockHistory,
  GoodsRentalItem,
  GoodsRentalStock,
  GoodsRequestItem,
  GoodsRental,
} from "../types/goods";

import { getExcelDownloadApi } from "./commonService";

import { SearchCondition } from "../components/ListPage";

const GOODS_REQUEST_ITEM_API_PREFIX = "/v2/admin/goods/request-item";
const GOODS_RENTAL_ITEM_API_PREFIX = "/v2/admin/goods/rental-item";
const GOODS_RENTAL_STOCK_API_PREFIX = "/v2/admin/goods/rental-stock";
const GOODS_RENTAL_API_PREFIX = "/v2/admin/goods/rental";

// 신청 물품 관리
export const getGoodsRequestItemList = (
  params: SearchCondition<GoodsRequestItem>
) =>
  request.get<ListResponse<GoodsRequestItem>>(
    GOODS_REQUEST_ITEM_API_PREFIX + "/list",
    { params }
  );

export const getGoodsRequestItem = (goodsId: string) =>
  request.get<GoodsRequestItem>(GOODS_REQUEST_ITEM_API_PREFIX + `/${goodsId}`);

export const postGoodsRequestItem = (formData: FormData) =>
  request.post(GOODS_REQUEST_ITEM_API_PREFIX, formData);

export const putGoodsRequestItem = (goodsId: string, formData: FormData) =>
  request.put(GOODS_REQUEST_ITEM_API_PREFIX + `/${goodsId}`, formData);

export const getGoodsRequestItemListExcel = (
  params: SearchCondition<GoodsRequestItem>
) => getExcelDownloadApi(GOODS_REQUEST_ITEM_API_PREFIX + "/list/excel", params);

export const getGoodsRequestItemBulkExcelForm = () =>
  request.get(GOODS_REQUEST_ITEM_API_PREFIX + "/excel/upload-form", {
    responseType: "blob",
    params: {},
  });

export const postGoodsRequestItemBulkExcel = (data: FormData) =>
  request.post(GOODS_REQUEST_ITEM_API_PREFIX + "/list", data);

// 대여 물품 관리
export const getGoodsRentalItemList = (
  params: SearchCondition<GoodsRentalItem>
) =>
  request.get<ListResponse<GoodsRentalItem>>(
    GOODS_RENTAL_ITEM_API_PREFIX + "/list",
    { params }
  );

export const getGoodsRentalItem = (goodsId: string) =>
  request.get<GoodsRentalItem>(GOODS_RENTAL_ITEM_API_PREFIX + `/${goodsId}`);

export const postGoodsRentalItem = (formData: FormData) =>
  request.post(GOODS_RENTAL_ITEM_API_PREFIX, formData);

export const putGoodsRentalItem = (goodsId: string, formData: FormData) =>
  request.put(GOODS_RENTAL_ITEM_API_PREFIX + `/${goodsId}`, formData);

export const getGoodsRentalItemListExcel = (
  params: SearchCondition<GoodsRentalItem>
) => getExcelDownloadApi(GOODS_RENTAL_ITEM_API_PREFIX + "/list/excel", params);

export const getGoodsRentalItemBulkExcelForm = () =>
  request.get(GOODS_RENTAL_ITEM_API_PREFIX + "/excel/upload-form", {
    responseType: "blob",
    params: {},
  });

export const postGoodsRentalItemBulkExcel = (data: FormData) =>
  request.post(GOODS_RENTAL_ITEM_API_PREFIX + "/list", data);

// 대여 물품 재고 관리
export const getGoodsRentalStockList = (
  params: SearchCondition<GoodsRentalStock>
) =>
  request.get<ListResponse<GoodsRentalStock>>(
    GOODS_RENTAL_STOCK_API_PREFIX + "/list",
    { params }
  );

export const getGoodsRentalStock = (stockId: string) =>
  request.get<GoodsRentalStock>(GOODS_RENTAL_STOCK_API_PREFIX + `/${stockId}`);

export const postGoodsRentalStock = (data: Partial<GoodsRentalStock>) =>
  request.post(GOODS_RENTAL_STOCK_API_PREFIX, data);

export const putGoodsRentalStock = (
  stockId: string,
  data: Partial<GoodsRentalStock>
) => request.put(GOODS_RENTAL_STOCK_API_PREFIX + `/${stockId}`, data);

export const getGoodsRentalStockListExcel = (
  params: SearchCondition<GoodsRentalStock>
) => getExcelDownloadApi(GOODS_RENTAL_STOCK_API_PREFIX + "/list/excel", params);

// 대여 물품 재고 이력 관리
export const getGoodsRentalStockHistoryList = (
  params: SearchCondition<GoodsRentalStockHistory>
) =>
  request.get<ListResponse<GoodsRentalStockHistory>>(
    GOODS_RENTAL_STOCK_API_PREFIX + "/history",
    { params }
  );

export const getGoodsRentalStockHistoryListExcel = (
  params: SearchCondition<GoodsRentalStockHistory>
) =>
  getExcelDownloadApi(GOODS_RENTAL_STOCK_API_PREFIX + "/history/excel", params);

// 대여 이용 관리
export const getGoodsRentalList = (params: SearchCondition<GoodsRental>) =>
  request.get<ListResponse<GoodsRental>>(GOODS_RENTAL_API_PREFIX + "/list", {
    params,
  });

export const getGoodsRentalListExcel = (params: SearchCondition<GoodsRental>) =>
  getExcelDownloadApi(GOODS_RENTAL_API_PREFIX + "/list/excel", params);

export const getGoodsRental = (goodsServiceId: string) =>
  request.get<NullableObject<GoodsRental>>(
    GOODS_RENTAL_API_PREFIX + `/detail/${goodsServiceId}`
  );

export const putGoodsRentalRented = (goodsServiceId: string) =>
  request.put(GOODS_RENTAL_API_PREFIX + `/${goodsServiceId}/rented`);

export const patchGoodsRentalLostDamaged = (goodsServiceId: string) =>
  request.patch(GOODS_RENTAL_API_PREFIX + `/${goodsServiceId}/lost-damaged`);

export const patchGoodsRentalCompleted = (goodsServiceId: string) =>
  request.patch(GOODS_RENTAL_API_PREFIX + `/${goodsServiceId}/completed`);

export const patchGoodsRentalRestored = (goodsServiceId: string) =>
  request.patch(GOODS_RENTAL_API_PREFIX + `/${goodsServiceId}/restored`);

export const postGoodsRentalPush = (goodsServiceId: string) =>
  request.post(GOODS_RENTAL_API_PREFIX + `/${goodsServiceId}/push`);
