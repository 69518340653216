import { SearchCondition } from "../components/ListPage";
import request, { ListResponse } from "../libs/request";
import {
  EtcCategory,
  EtcCategoryItem,
  EtcSatisfactionComment,
  EtcSatisfactionList,
  EtcTerms,
} from "../types/etc";
import { getExcelDownloadApi } from "./commonService";

const ETC_API_PREFIX = "/v2/admin/etc";
const ETC_TERMS_API_PREFIX = "/v2/admin/etc/terms";

export const getEtcCategory = () =>
  request.get<EtcCategory[]>(ETC_TERMS_API_PREFIX + "/category");

export const getEtcCategoryTermsId = (termsCategoryId: number) =>
  request.get<EtcCategoryItem[]>(
    ETC_TERMS_API_PREFIX + `/category/${termsCategoryId}`
  );

export const getEtcTermsList = (params: SearchCondition<EtcTerms>) =>
  request.get<ListResponse<EtcTerms>>(ETC_TERMS_API_PREFIX, { params });

export const getEtcTerms = (termsId: number) =>
  request.get<EtcTerms>(ETC_TERMS_API_PREFIX + `/${termsId}`);

export const postEtcTerms = (data: Partial<EtcTerms>) =>
  request.post(ETC_TERMS_API_PREFIX, data);

export const putEtcTerms = (termsId: number, data: Partial<EtcTerms>) =>
  request.put(ETC_TERMS_API_PREFIX + `/${termsId}`, data);

export const deleteEtcTerms = (termsId: number) =>
  request.delete(ETC_TERMS_API_PREFIX + `/${termsId}`);

export const getEtcTermsListExcel = (params: SearchCondition<EtcTerms>) =>
  getExcelDownloadApi(ETC_TERMS_API_PREFIX + "/excel", params);

export const getEtcSatisfaction = (serviceName: string, serviceId: string) =>
  request.get<EtcSatisfactionList>(ETC_API_PREFIX + "/satisfaction", {
    params: { serviceName, serviceId },
  });

export const postEtcSatisfaction = (
  serviceId: string,
  data: Partial<EtcSatisfactionComment>
) => request.post(ETC_API_PREFIX + `/satisfaction/reply/${serviceId}`, data);

export const patchEtcSatisfaction = (
  etcSatisfactionId: number,
  data: Partial<EtcSatisfactionComment>
) =>
  request.patch(
    ETC_API_PREFIX + `/satisfaction/reply/${etcSatisfactionId}`,
    data
  );
