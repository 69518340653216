import { useContext, useState } from "react";

import { HttpStatusCode } from "axios";

import { EvacuationUser } from "@/types/emergency";
import {
  BuildingCompany,
  BuildingGroup,
  buildingGroupCategoryCodeValue,
  TagSelectedItem,
} from "@/types/building";

import {
  validationFunctions,
  validationResultMessage,
} from "@/libs/validations";

import { useModal } from "@/contexts/Modal";
import { PopupContext } from "@/contexts/Popup";

import { postEvacuee } from "@/services/emergencyService";

import DefaultSelect from "@/components/SelectBox/DefaultSelect";
import TagSingleFilter from "@/components/TagFilter/TagSingleFilter";
import FormHeader from "@/components/Form/Header";
import FormRow from "@/components/Form/Row";
import FormRowLabel from "@/components/Form/RowLabel";
import DefaultInput from "@/components/Input/DefaultInput";
import { getBuildingCompanyListAll } from "@/services/buildingService";
import { SearchCondition } from "@/components/ListPage";
import { toComboBoxType } from "@/utils/comboBoxUtils";
import { evacueeType } from "@/types/comboBoxOption";

type Props = {};

export default function CreateEvacuee(props: Props) {
  const { showAlert, showConfirm, handleError } = useModal();
  const { closePopup, refreshAndClosePopup } = useContext(PopupContext);
  const [evacuee, setEvacuee] = useState<Partial<EvacuationUser>>({});
  const [selectedBuilding, setSelectedBuilding] = useState<TagSelectedItem>();
  const [isLoading, setIsLoading] = useState(false);
  const [company, setCompany] = useState<BuildingCompany[]>([]);

  const handleEvacueeChange = (data: Partial<EvacuationUser>) => {
    setEvacuee((prev) => ({ ...prev, ...data }));
  };

  const handlePost = () => {
    const errorMessage = validationResultMessage([
      validationFunctions.required(evacuee?.name, "이름"),
      validationFunctions.required(evacuee.mobileNumber, "전화번호"),
      validationFunctions.required(evacuee.role, "권한 선택"),
      validationFunctions.required(evacuee.evacuationBuildingId, "건물 선택"),
      validationFunctions.required(evacuee.evacuationCompanyId, "회사 선택"),
      validationFunctions.required(evacuee.evacuationDepartmentName, "부서"),
      // validationFunctions.required(evacuee.floor, "근무 층"),
    ]);

    if (errorMessage) {
      showAlert(errorMessage);
      return;
    }

    showConfirm("등록 하시겠습니까?", postData);
  };

  const postData = async () => {
    try {
      const response = await postEvacuee(evacuee);
      if (response.status === HttpStatusCode.Ok) {
        showAlert("등록이 완료되었습니다.");
        refreshAndClosePopup();
      }
    } catch (err: any) {
      handleError(err, "등록");
    }
  };

  const fetchCompany = async (buildingId: string) => {
    try {
      const params = {
        buildingId: buildingId,
      } as SearchCondition<BuildingCompany>;
      const response = await getBuildingCompanyListAll(params);
      if (response.status === HttpStatusCode.Ok) {
        setCompany(response.data);
      } else {
        showAlert("회사 목록 조회에 실패 하였습니다.");
      }
    } catch (err: any) {
      const error = err.response?.data?.error;

      if (error) {
        showAlert(err.response?.data?.error);
      } else {
        handleError(err, "대피영역 지정 - 회사 목록");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleBuildingChange = (item: BuildingGroup) => {
    if (item.buildingId) {
      fetchCompany(item.buildingId);
      handleEvacueeChange({
        evacuationBuildingId: item.buildingId,
      });
    } else {
      console.error("Building ID is not found while selecting building.");
    }
  };

  return (
    <>
      <div className="flex flex-col gap-5">
        <div className="max-h-[700px] overflow-y-auto">
          <FormHeader title="등록 인원 상세정보" />
          <FormRow>
            <FormRowLabel title="이름" isRequired>
              <div className="flex flex-col">
                <div className="flex gap-2 items-center">
                  <DefaultInput
                    value={evacuee?.name}
                    onChange={(value: string) => {
                      handleEvacueeChange({ name: value });
                    }}
                    placeholder="이름을 입력해주세요"
                  />
                </div>
              </div>
            </FormRowLabel>
          </FormRow>
          <FormRow>
            <FormRowLabel title="전화번호" isRequired>
              <div className="flex flex-col">
                <div className="flex gap-2 items-center">
                  <DefaultInput
                    value={evacuee?.mobileNumber}
                    onChange={(value: string) => {
                      handleEvacueeChange({ mobileNumber: value });
                    }}
                    placeholder="전화번호를 입력해주세요"
                    type="tel"
                    hideMaxLength
                    maxLength={20}
                  />
                </div>
              </div>
            </FormRowLabel>
          </FormRow>
          <FormRow>
            <FormRowLabel title="권한 선택" isRequired>
              <DefaultSelect
                value={evacuee.role}
                optionList={evacueeType}
                onChange={(value: string) => {
                  handleEvacueeChange({ role: value });
                }}
                placeholder="권한을 선택해주세요"
              />
            </FormRowLabel>
          </FormRow>
          <FormHeader title="등록 인원 회사정보" />
          <FormRow>
            <FormRowLabel title="건물 선택" isRequired>
              <TagSingleFilter
                selectType="building"
                selectedItem={selectedBuilding}
                onChangeBuilding={handleBuildingChange}
                categoryCode={buildingGroupCategoryCodeValue.POST}
              />
            </FormRowLabel>
          </FormRow>
          <FormRow>
            <FormRowLabel title="회사 선택" isRequired>
              <DefaultSelect
                value={evacuee.evacuationCompanyId}
                optionList={toComboBoxType(company, "companyId", "companyName")}
                onChange={(value: string) => {
                  handleEvacueeChange({ evacuationCompanyId: value });
                }}
                placeholder="회사를 선택해주세요"
              />
            </FormRowLabel>
          </FormRow>
          <FormRow>
            <FormRowLabel title="부서" isRequired>
              <div className="flex flex-col">
                <div className="flex gap-2 items-center">
                  <DefaultInput
                    value={evacuee?.evacuationDepartmentName}
                    onChange={(value: string) => {
                      handleEvacueeChange({ evacuationDepartmentName: value });
                    }}
                    placeholder="부서를 입력해주세요"
                  />
                </div>
              </div>
            </FormRowLabel>
          </FormRow>
          {/* <FormRow>
            <FormRowLabel title="근무 층" isRequired>
              <div className="flex flex-col">
                <div className="flex gap-2 items-center">
                  <DefaultInput
                    value={evacuee?.floor}
                    onChange={(value: string) => {
                      handleEvacueeChange({ floor: value });
                    }}
                    placeholder="층을 입력해주세요"
                  />
                </div>
              </div>
            </FormRowLabel>
          </FormRow> */}
        </div>
      </div>
    </>
  );
}
