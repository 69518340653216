import { AxiosResponse, HttpStatusCode } from "axios";
import { useContext, useEffect, useState } from "react";

import {
  GOODS_RENTAL_STATUS_TYPES,
  GoodsRental,
  goodsRentalStatusLabels,
} from "../../../../../types/goods";
import { DASH } from "../../../../../types/search";
import { NullableObject } from "@/types/common";

import { formatMillisecondsToDetailTime } from "../../../../../utils/formatUtils";
import { SECOND_UNIT } from "../../../../../utils/dateUtils";

import { useModal } from "../../../../../contexts/Modal";
import { PopupContext } from "../../../../../contexts/Popup";

import {
  getGoodsRental,
  patchGoodsRentalCompleted,
  patchGoodsRentalLostDamaged,
  patchGoodsRentalRestored,
  postGoodsRentalPush,
  putGoodsRentalRented,
} from "../../../../../services/goodsService";

import DefaultButton from "../../../../DefaultButton";
import FormHeader from "../../../../Form/Header";
import FormRow from "../../../../Form/Row";
import FormRowLabel from "../../../../Form/RowLabel";
import FormRowLabelItem from "../../../../Form/RowLabelItem";
import DefaultLabel from "../../../../Input/DefaultLabel";
import Spinner from "../../../../Spinner";

type Props = { goodsServiceId: string };

export default function GoodsRentalDetailView({ goodsServiceId }: Props) {
  const { showAlert, showConfirm, handleError } = useModal();
  const { closePopup, refreshAndClosePopup } = useContext(PopupContext);

  const [rental, setRental] = useState<NullableObject<GoodsRental>>({});

  const [isLoading, setIsLoading] = useState(false);
  const [returnTime, setReturnTime] = useState("");

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const res = await getGoodsRental(goodsServiceId);
      if (res.status === HttpStatusCode.Ok) {
        setRental(res.data);
      }
    } catch (err: any) {
      handleError(err, "조회");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const { status, returnDueAt, actualReturnAt } = rental;

    if (status === "RENTED" && returnDueAt) {
      const updateCountdown = () => {
        const dueDate = new Date(returnDueAt);
        const currentDate = new Date();
        const diff = dueDate.getTime() - currentDate.getTime();
        const diffInSeconds = Math.round(diff / SECOND_UNIT) * SECOND_UNIT;

        const timeString = formatMillisecondsToDetailTime(
          Math.abs(diffInSeconds)
        );
        const description = diff < 0 ? "연체" : "남음";

        setReturnTime(`${timeString ? timeString : "0초"} ${description}`);
      };

      updateCountdown(); // 인터벌 시간은 첫 렌더링 후 1초 뒤에 출력되기 때문에 시간이 바로 출력되도록 수동으로 호출
      const intervalId = setInterval(updateCountdown, SECOND_UNIT);
      return () => clearInterval(intervalId);
    } else if (status === "COMPLETED" && returnDueAt && actualReturnAt) {
      const dueDate = new Date(returnDueAt);
      const returnedDate = new Date(actualReturnAt);
      const diff = returnedDate.getTime() - dueDate.getTime();

      if (diff >= 0) {
        const timeString = `(${formatMillisecondsToDetailTime(diff)} 연체)`;
        setReturnTime(timeString);
      }
    }
  }, [rental.returnDueAt]);

  const handleReturnAlarm = () => {
    showConfirm("반납 알림을 발송하시겠습니까?", async () => {
      try {
        setIsLoading(true);
        const res = await postGoodsRentalPush(goodsServiceId);
        if (res.status === HttpStatusCode.Ok) {
          showAlert("반납 알림이 발송되었습니다.");
        }
      } catch (err: any) {
        handleError(err, "반납 알림");
      } finally {
        setIsLoading(false);
      }
    });
  };

  const isStatusIn = (statuses: string[]) =>
    statuses.includes(rental.status ?? "");

  const handleExecuteApi = (
    commandText: string,
    api: (goodsServiceId: string) => Promise<AxiosResponse>
  ) => {
    showConfirm(`${commandText} 처리하시겠습니까?`, async () => {
      try {
        const res = await api(goodsServiceId);
        if (res.status === HttpStatusCode.Ok) {
          showAlert(`${commandText} 처리되었습니다.`);
          refreshAndClosePopup();
        }
      } catch (err: any) {
        handleError(err, `${commandText} 처리`);
      }
    });
  };

  const renderButton = () => {
    const renderStatusButton = (
      commandText: string,
      api: (goodsServiceId: string) => Promise<AxiosResponse>
    ) => {
      return (
        <DefaultButton
          color="primary"
          onClick={() => handleExecuteApi(commandText, api)}
        >
          {commandText}
        </DefaultButton>
      );
    };

    return (
      <>
        <DefaultButton onClick={closePopup}>닫기</DefaultButton>
        {isStatusIn([GOODS_RENTAL_STATUS_TYPES.REQUESTED]) &&
          renderStatusButton("수동 대여", putGoodsRentalRented)}
        {isStatusIn([
          GOODS_RENTAL_STATUS_TYPES.RENTED,
          GOODS_RENTAL_STATUS_TYPES.COMPLETED,
        ]) && renderStatusButton("분실/손상", patchGoodsRentalLostDamaged)}
        {isStatusIn([GOODS_RENTAL_STATUS_TYPES.RENTED]) &&
          renderStatusButton("수동 반납", patchGoodsRentalCompleted)}
        {isStatusIn([GOODS_RENTAL_STATUS_TYPES.LOST_DAMAGED]) &&
          renderStatusButton("정상 반납 처리", patchGoodsRentalRestored)}
      </>
    );
  };

  const getStatusText = () => {
    if (rental.status) {
      const statusText = goodsRentalStatusLabels[rental.status] ?? DASH;
      const brokenText = rental.isLostDamage ? "(분실/손상)" : "";
      return `${statusText} ${brokenText}`;
    }
    return "";
  };

  const getRentalLocationText = () => {
    return `${rental.rentalBuildingName ?? ""} ${
      rental.rentalBuildingFloorName ?? ""
    } / ${rental.rentalStoreName ?? ""}`;
  };

  const getReturnLocationText = () => {
    return `${rental.returnBuildingName ?? ""} ${
      rental.returnBuildingFloorName ?? ""
    } / ${rental.returnStoreName ?? ""}`;
  };

  return (
    <div className="flex flex-col gap-5">
      {isLoading && <Spinner />}
      <div className="flex flex-col min-w-[700px] max-h-[700px] overflow-y-auto">
        <FormHeader title="기본 정보" />
        <FormRow>
          <FormRowLabel title="물품 정보">
            <div className="flex flex-col gap-2">
              <DefaultLabel text={getRentalLocationText()} />
              <DefaultLabel text={rental.goodsName ?? ""} />
            </div>
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="처리 상태">
            <DefaultLabel text={getStatusText()} />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="신청 일시">
            <div
              className="flex flex-col gap-2"
              data-testid="goods-rental-created-at"
            >
              <DefaultLabel text={rental.createdAt ?? DASH} />
              {isStatusIn([
                GOODS_RENTAL_STATUS_TYPES.REQUESTED,
                GOODS_RENTAL_STATUS_TYPES.CANCELED,
              ]) && (
                <>
                  <DefaultLabel
                    text={`신청 대여 장소 : ${getRentalLocationText()}`}
                  />
                  <DefaultLabel
                    text={rental.createdByName ?? ""}
                    masking="BO_GOODS_RENTAL_DETAIL_CREATED_BY"
                    targetId={rental.createdBy ?? -1}
                    property="NAME"
                    prefix={`신청자 : ${rental.createdByCompanyName ?? ""} `}
                  />
                </>
              )}
            </div>
          </FormRowLabel>
        </FormRow>
        {isStatusIn([
          GOODS_RENTAL_STATUS_TYPES.RENTED,
          GOODS_RENTAL_STATUS_TYPES.COMPLETED,
          GOODS_RENTAL_STATUS_TYPES.LOST_DAMAGED,
        ]) && (
          <>
            <FormRow>
              <FormRowLabel title="대여 정보">
                <div className="flex flex-col">
                  <FormRowLabelItem>
                    <DefaultLabel
                      text={`대여 일시 : ${rental.rentalAt ?? DASH}`}
                    />
                  </FormRowLabelItem>
                  <FormRowLabelItem>
                    <DefaultLabel
                      text={`대여 장소 : ${getRentalLocationText()}`}
                    />
                  </FormRowLabelItem>
                  <FormRowLabelItem>
                    <div className="flex gap-2 items-center">
                      <DefaultLabel
                        text={rental.createdByName ?? ""}
                        masking="BO_GOODS_RENTAL_DETAIL_CREATED_BY"
                        targetId={rental.createdBy ?? -1}
                        property="NAME"
                        prefix={`대여자 : ${
                          rental.createdByCompanyName ?? ""
                        } `}
                        unmaskButtonTestId="unmask-name"
                      />
                      {isStatusIn([GOODS_RENTAL_STATUS_TYPES.RENTED]) && (
                        <DefaultButton
                          size="unmaskingButton"
                          onClick={handleReturnAlarm}
                        >
                          반납 알림 발송
                        </DefaultButton>
                      )}
                    </div>
                  </FormRowLabelItem>
                  <FormRowLabelItem>
                    <DefaultLabel
                      text={rental.createdByMobileNumber ?? ""}
                      masking="BO_GOODS_RENTAL_DETAIL_CREATED_BY"
                      targetId={rental.createdBy ?? -1}
                      property="MOBILE_NUMBER"
                      prefix={`연락처 : `}
                      unmaskButtonTestId="unmask-mobile-number"
                    />
                  </FormRowLabelItem>
                </div>
              </FormRowLabel>
            </FormRow>
            <FormRow>
              <FormRowLabel title="반납정보">
                <div className="flex flex-col gap-2">
                  <DefaultLabel
                    text={`반납예정 일시 : ${rental.returnDueAt ?? DASH}`}
                  />
                  <DefaultLabel
                    text={`반납일시 : ${
                      rental.actualReturnAt ?? ""
                    } ${returnTime}`}
                  />
                  {isStatusIn([GOODS_RENTAL_STATUS_TYPES.COMPLETED]) && (
                    <DefaultLabel
                      text={`반납장소 : ${getReturnLocationText()}`}
                    />
                  )}
                </div>
              </FormRowLabel>
            </FormRow>
          </>
        )}
      </div>
      <div className="flex gap-2 justify-center">{renderButton()}</div>
    </div>
  );
}
