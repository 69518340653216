import DefaultButton from "../../../components/DefaultButton";
import ListPage from "../../../components/ListPage";
import { TableColumnType } from "../../../components/Table";
import { ComponentType } from "../../../types/search";

import { FiEdit2 } from "react-icons/fi";
import { getShelterList } from "@/services/emergencyService";
import ShelterDetailView from "@/components/PageComponents/Emergency/ShelterList/DetailView";
import EmergencyShelterListTopRight from "@/components/PageComponents/Emergency/ShelterList/ListTopRight";
import { Shelter } from "@/types/emergency";
import DefaultLabel from "@/components/Input/DefaultLabel";
import NaverMapComponent from "@/components/PageComponents/Emergency/ShelterList/NaverMapComponent";

type Props = {};

export default function ShelterList(props: Props) {
  const columnInfo: TableColumnType<Shelter>[] = [
    { header: "No.", defaultNo: true, width: "no" },
    { header: "대피영역 ID", name: "shelterId" },
    { header: "대피영역명", name: "shelterName" },
    { header: "등록자 Email", name: "createdByEmail" },
    { header: "등록자 이름", name: "createdByName" },
    { header: "등록일시", name: "createdAt", width: "date" },
    {
      header: "대피 영역 관리",
      render: (item: Shelter, handler) => (
        <div className="w-full h-full flex gap-2 justify-center items-center">
          <DefaultButton
            size="tableIcon"
            onClick={() => {
              if (handler)
                handler.popup({
                  header: "대피영역 상세",
                  content: (
                    <NaverMapComponent
                      shelterId={item.shelterId}
                      defaultLat={item.lat}
                      defaultLng={item.lng}
                    />
                  ),
                });
            }}
          >
            <FiEdit2 />
          </DefaultButton>
        </div>
      ),
      width: "icon",
    },
    {
      header: "상세",
      render: (item: Shelter, handler) => (
        <div className="w-full h-full flex gap-2 justify-center items-center">
          <DefaultButton
            size="tableIcon"
            onClick={() => {
              if (handler)
                handler.popup({
                  header: "대피영역 상세",
                  content: <ShelterDetailView shelterId={item.shelterId} />,
                });
            }}
          >
            <FiEdit2 />
          </DefaultButton>
        </div>
      ),
      width: "icon",
    },
  ];

  const componentList: ComponentType<Shelter>[] = [
    {
      labelName: "등록일자",
      typeName: "dateRange",
      keyName: "createdAt",
      category: "date",
    },
    {
      labelName: "대피영역명",
      typeName: "text",
      keyName: "shelterName",
      category: "detailSearch",
      placeholder: "대피영역명을 입력해주세요",
    },
    {
      labelName: "대피영역ID",
      typeName: "text",
      keyName: "shelterId",
      category: "detailSearch",
      placeholder: "대피영역ID를 입력해주세요",
    },
  ];

  return (
    <ListPage
      getDataApi={getShelterList}
      keyId={(item: Shelter) => item.shelterId}
      columnInfo={columnInfo}
      componentList={componentList}
      tableTitle="대피 영역 목록"
      renderTopRight={<EmergencyShelterListTopRight />}
      defaultPageSize={10}
      defaultPageSizeList={[10, 20, 30]}
    />
  );
}
