import {
  Building,
  BuildingCompany,
  BuildingCompanyService,
  BuildingFloor,
  BuildingGroup,
  BuildingServiceGroupType,
  BuildingVocGet,
  TempHum,
  Toilet,
} from "../types/building";
import { Service, ServiceList } from "../types/service";

import request, { ListResponse, objectToFormData } from "../libs/request";

import { getExcelDownloadApi } from "./commonService";

import { SearchCondition } from "../components/ListPage";

export const DEFAULT_PAGE_NUMBER = 1;
export const DEFAULT_PAGE_SIZE = 10;

const BUILDING_API_PREFIX = "/v2/admin/building";
const SERVICE_API_PREFIX = "/v2/admin/service";
const TOILET_API_PREFIX = "/v2/admin/space/toilet";

export const getBuildingList = (params: SearchCondition<Building>) =>
  request.get<ListResponse<Building>>(BUILDING_API_PREFIX + "/list", {
    params,
  });

export const getBuildingGroupList = (params: Partial<Building>) =>
  request.get<Building[]>(BUILDING_API_PREFIX + "/group-building-list", {
    params,
  });

export const getBuildingListWithGroup = (params: SearchCondition<BuildingServiceGroupType>) => 
  request.get<ListResponse<BuildingServiceGroupType>>(SERVICE_API_PREFIX + "/group/building-bbs/building/list", {
    params,
  });

export const getBuildingGroupSearch = (params: Partial<Building>) =>
  request.get<ListResponse<BuildingGroup>>(
    BUILDING_API_PREFIX + "/group-building-search",
    { params }
  );

export const getBuildingListExcel = (params: SearchCondition<Building>) =>
  getExcelDownloadApi(BUILDING_API_PREFIX + "/list/excel", params);

export const getBuilding = (buildingId: string) =>
  request.get<Building>(BUILDING_API_PREFIX + `/${buildingId}`);

export const getBuildingVocUnitList = () =>
  request.get<BuildingVocGet[]>(BUILDING_API_PREFIX + `/voc-unit/list`);

export const postBuilding = (data: FormData) =>
  request.post(BUILDING_API_PREFIX, data);

export const putBuilding = (buildingId: string, data: FormData) =>
  request.put(BUILDING_API_PREFIX + `/${buildingId}`, data);

export const getBuildingCompanyList = (
  params: SearchCondition<BuildingCompany>
) =>
  request.get<ListResponse<BuildingCompany>>(
    BUILDING_API_PREFIX + "/company/list",
    { params }
  );

export const getBuildingCompanyListAll = (
  params: SearchCondition<BuildingCompany>
) =>
  request.get<BuildingCompany[]>(BUILDING_API_PREFIX + "/company/list/all", {
    params,
  });

export const getBuildingCompanyCompanyList = (
  params: SearchCondition<BuildingCompany>
) => {
  const { buildingId, companyId, ...otherParams } = params;

  const newParams = {
    ...otherParams,
    ...(buildingId && { buildingIds: buildingId.join(",") }),
    ...(companyId && { companyIds: companyId.join(",") }),
  };

  return request.get<ListResponse<BuildingCompany>>(
    BUILDING_API_PREFIX + "/company/company-list",
    {
      params: newParams,
    }
  );
};

export const getBuildingCompanyListExcel = (
  params: SearchCondition<BuildingCompany>
) => {
  const { buildingId, companyId, ...otherParams } = params;

  const newParams = {
    ...otherParams,
    ...(buildingId && { buildingIds: buildingId.join(",") }),
    ...(companyId && { companyIds: companyId.join(",") }),
  };

  return getExcelDownloadApi(
    BUILDING_API_PREFIX + "/company/company-list/excel",
    newParams
  );
};

export const patchBuildingCompanyServiceList = (
  buildingId: string,
  companyId: string,
  data: BuildingCompanyService[]
) =>
  request.patch(
    BUILDING_API_PREFIX +
      `/company/${buildingId}/company/${companyId}/service/list`,
    data
  );

export const getBuildingCompanyServiceList = (
  params: SearchCondition<BuildingCompanyService>,
  buildingId: string,
  companyId: string
) =>
  request.get<ListResponse<BuildingCompanyService>>(
    BUILDING_API_PREFIX +
      `/company/${buildingId}/company/${companyId}/service/list`,
    { params }
  );

export const putBuildingCompanyCompanyIds = (
  buildingId: string,
  companyIds: string[]
) =>
  request.put(BUILDING_API_PREFIX + `/company/${buildingId}/company`, {
    companyIds,
  });

export const getBuildingFloor = (buildingId: string) =>
  request.get<BuildingFloor[]>(
    BUILDING_API_PREFIX + `/${buildingId}/floor/list`
  );

export const getToiletList = (params: SearchCondition<Toilet>) =>
  request.get<ListResponse<Toilet>>(TOILET_API_PREFIX + "/list", {
    params,
  });

export const getToiletListExcel = (params: SearchCondition<Toilet>) =>
  getExcelDownloadApi(TOILET_API_PREFIX + "/list/excel", params);

export const getToiletBulkExcel = () =>
  request.get(TOILET_API_PREFIX + "/excel/upload-form", {
    responseType: "blob",
  });

export const getToiletCompartmentBulkExcel = () =>
  request.get(TOILET_API_PREFIX + "/compartment/excel/upload-form", {
    responseType: "blob",
  });

export const getToiletCompanyBulkExcel = () =>
  request.get(TOILET_API_PREFIX + "/company/excel/upload-form", {
    responseType: "blob",
  });

export const postToilet = (data: Partial<Toilet>) =>
  request.post(TOILET_API_PREFIX, data);

export const postToiletListBulk = (data: FormData) =>
  request.post(TOILET_API_PREFIX + "/list", data);

export const postToiletCompartmentBulk = (data: FormData) =>
  request.post(TOILET_API_PREFIX + "/compartment/list", data);

export const postToiletCompanyBulk = (data: FormData) =>
  request.post(TOILET_API_PREFIX + "/company/list", data);

export const putToilet = (data: Partial<Toilet>) =>
  request.put("/v2/admin/space/toilet", data);

export const patchToilet = (spaceToiletId: string) =>
  request.patch(TOILET_API_PREFIX + `/floor/${spaceToiletId}`);

export const getToilet = (spaceToiletId: string) =>
  request.get<Toilet>(TOILET_API_PREFIX + `/${spaceToiletId}`);

export const getBuildingServiceList = (buildingId: string) =>
  request.get<ServiceList>(BUILDING_API_PREFIX + `/${buildingId}/service/list`);

export const getBuildingService = (buildingId: string, serviceId: string) =>
  request.get<Service>(
    BUILDING_API_PREFIX + `/${buildingId}/service/${serviceId}`
  );

export const putBuildingService = (
  buildingId: string,
  serviceId: string,
  data: Partial<Service>
) =>
  request.put(
    BUILDING_API_PREFIX + `/${buildingId}/service/${serviceId}`,
    objectToFormData(data)
  );

export const postBuildingService = (
  buildingId: string,
  data: Partial<Service>
) =>
  request.post(
    BUILDING_API_PREFIX + `/${buildingId}/service`,
    objectToFormData(data)
  );

export const patchBuildingService = (
  buildingId: string,
  data: Partial<Service>[]
) => request.patch(BUILDING_API_PREFIX + `/${buildingId}/service/list`, data);

export const getTempHumList = (params: SearchCondition<TempHum>) =>
  request.get<ListResponse<TempHum>>(
    BUILDING_API_PREFIX + "/space/temp_hum_air/list",
    { params }
  );

export const getTempHumListExcel = (params: SearchCondition<TempHum>) =>
  getExcelDownloadApi(
    BUILDING_API_PREFIX + "/space/temp_hum_air/list/excel",
    params
  );

export const getTempHumBulkExcel = () =>
  request.get(BUILDING_API_PREFIX + "/space/temp_hum_air/excel/upload-form", {
    responseType: "blob",
  });

export const patchBuildingSpaceDisconnecting = (buildingSpaceId: string) =>
  request.patch(
    BUILDING_API_PREFIX + `/space/${buildingSpaceId}/disconnecting`
  );

export const postTempHum = (data: FormData) =>
  request.post(BUILDING_API_PREFIX + "/space", data);

export const postTempHumBulk = (data: FormData) =>
  request.post(BUILDING_API_PREFIX + "/space/temp_hum_air/list/excel", data);

export const getTempHum = (buildingSpaceId: string) =>
  request.get<TempHum>(BUILDING_API_PREFIX + `/space/${buildingSpaceId}`);

export const putTempHum = (buildingSpaceId: string, data: FormData) =>
  request.put(BUILDING_API_PREFIX + `/space/${buildingSpaceId}`, data);
