import TimeListPage, {
  TimeSearchCondition,
} from "../../../../components/ListPage/TimeListPage";
import { TimeTableRange } from "../../../../components/TimeTable";
import {
  getSpaceReservationDaily,
  getSpaceReservationRange,
} from "../../../../services/spaceReservationService";
import { buildingGroupCategoryCodeValue } from "../../../../types/building";
import { ComponentType } from "../../../../types/search";
import { SpaceReservationData } from "../../../../types/spaceReservation";
import { addDefaultOption } from "../../../../utils/comboBoxUtils";
import { PAYMENT_OPTIONS } from "../../../../types/comboBoxOption";
import {
  formatDate,
  getMonthRange,
  getToday,
  getWeekRange,
} from "../../../../utils/dateUtils";

type Props = {
  range: TimeTableRange;
  onTabClick: (tabIndex: number) => void;
};

export default function DateList({ range, onTabClick }: Props) {
  const componentList: ComponentType<SpaceReservationData>[] = [
    {
      typeName: "date",
      keyName: "reservationAt",
      category: "date",
    },
    {
      typeName: "buildingAndFloor",
      keyName: "buildingAndFloor",
      showAllOption: false,
      category: "typeSearch",
      buildingGroupCategoryCode: buildingGroupCategoryCodeValue.SPACE,
    },
    {
      labelName: "요금정책",
      typeName: "comboBox",
      keyName: "usePayment",
      category: "typeSearch",
      comboBoxOptions: addDefaultOption(PAYMENT_OPTIONS),
    },
  ];
  const getSpaceReservationDailyByRange = async (
    searchCondition: TimeSearchCondition
  ) => {
    const date = new Date(searchCondition.reservationAt) ?? new Date();

    if (range === "daily") {
      return await getSpaceReservationDaily(
        searchCondition.buildingId,
        searchCondition.buildingFloorId,
        searchCondition.usePayment,
        formatDate(date, "yyyy-MM-dd")
      );
    } else {
      const { start, end } = getReservationDates(range, date);
      return await getSpaceReservationRange(
        searchCondition.buildingId,
        searchCondition.buildingFloorId,
        searchCondition.usePayment,
        start,
        end
      );
    }
  };

  const getReservationDates = (range: TimeTableRange, date: Date) => {
    let dates = { start: date, end: date };

    if (range === "weekly") {
      dates = getWeekRange(date);
    } else if (range === "monthly") {
      dates = getMonthRange(date);
    }

    return {
      start: formatDate(dates.start, "yyyy-MM-dd"),
      end: formatDate(dates.end, "yyyy-MM-dd"),
    };
  };

  return (
    <div className="flex">
      <TimeListPage
        getDataApi={getSpaceReservationDailyByRange}
        onTabClick={onTabClick}
        range={range}
        componentList={componentList}
        initSearchCondition={{ reservationAt: getToday("yyyy-MM-dd") }}
      />
    </div>
  );
}
