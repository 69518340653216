import { useEffect, useState } from "react";
import DefaultButton from "../../../../DefaultButton";
import ListPage from "../../../../ListPage";
import { TableColumnType } from "../../../../Table";
import { TemplateParam } from "../../../../../types/template";
import { useModal } from "../../../../../contexts/Modal";
import { getTemplateParamList } from "../../../../../services/templateService";
import { HttpStatusCode } from "axios";
import Spinner from "../../../../Spinner";
import { useUserContext } from "../../../../../contexts/User";

const EMAIL_CODE_NAME = "#{email}";

type Props = { closePopup: () => void };

export default function TemplateParamDetailView({ closePopup }: Props) {
  const { handleError } = useModal();
  const { userInfo } = useUserContext();

  const [templateParam, setTemplateParam] = useState<TemplateParam[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const columnInfo: TableColumnType<TemplateParam>[] = [
    {
      header: "변수 정보",
      render: (item: TemplateParam) => (
        <>
          {item.codeName}
          {item.code === EMAIL_CODE_NAME && (
            <>
              <br />({userInfo?.email})
            </>
          )}
        </>
      ),
    },
    { header: "변수명", name: "code" },
    { header: "출력 예시", name: "comment" },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const res = await getTemplateParamList();
        if (res.status === HttpStatusCode.Ok) {
          setTemplateParam(res.data);
        }
      } catch (err: any) {
        handleError(err, "변수 조회");
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="flex flex-col gap-5 min-w-[700px]">
      {isLoading && <Spinner />}
      <div className="max-h-[700px] overflow-y-auto">
        <ListPage
          getDataApi={Promise.resolve}
          columnInfo={columnInfo}
          selectedData={templateParam}
          selectedMode
          onlyRenderTable
        />
      </div>

      <div className="flex justify-center">
        <DefaultButton onClick={closePopup}>닫기</DefaultButton>
      </div>
    </div>
  );
}
