import { FiEdit2 } from "react-icons/fi";
import DefaultButton from "../../../components/DefaultButton";
import ListPage from "../../../components/ListPage";
import { TableColumnType } from "../../../components/Table";
import {
  getBuildingCompanyCompanyList,
  getBuildingCompanyListExcel,
} from "../../../services/buildingService";
import { BuildingCompany } from "../../../types/building";
import { ComponentType } from "../../../types/search";
import { addDefaultOption } from "../../../utils/comboBoxUtils";
import { USAGE_OPTIONS, usageYnLabels } from "../../../types/comboBoxOption";
import BuildingCompanyManage from "../../../components/PageComponents/Building/BuildingCompanyList/Manage";
import BuildingCompanyServiceManage from "../../../components/PageComponents/Building/BuildingCompanyList/ServiceManage";
import DefaultLabel from "../../../components/Input/DefaultLabel";

type Props = {};

export default function BuildingCompanyList(props: Props) {
  const columnInfo: TableColumnType<BuildingCompany>[] = [
    { header: "No", defaultNo: true, width: "no" },
    { header: "건물 ID", name: "buildingId", width: "id" },
    { header: "건물명", name: "buildingName" },
    { header: "입주사 수", name: "companyCount", width: "number" },
    { header: "수정일시", name: "modifiedAt", width: "date" },
    {
      header: "건물 사용여부",
      render: (item: BuildingCompany) => (
        <div className="flex items-center justify-center">
          <DefaultLabel
            text={item.isActive ? usageYnLabels.TRUE : usageYnLabels.FALSE}
            color={item.isActive ? "blue" : "gray"}
          />
        </div>
      ),
      width: "yesOrNo",
    },
    {
      header: "입주사 관리",
      render: (item: BuildingCompany, handler) => (
        <div className="w-full h-full flex gap-2 justify-center items-center">
          <DefaultButton
            size="tableIcon"
            color="blue"
            onClick={() => {
              if (handler)
                handler.popup({
                  header: "입주사 관리",
                  content: <BuildingCompanyManage buildingCompany={item} />,
                });
            }}
          >
            <FiEdit2 />
          </DefaultButton>
        </div>
      ),
      width: "icon",
    },
    {
      header: "입주사별 서비스관리",
      render: (item: BuildingCompany, handler) => (
        <div className="w-full h-full flex gap-2 justify-center items-center">
          <DefaultButton
            size="tableIcon"
            color="blue"
            onClick={() => {
              if (handler)
                handler.popup({
                  header: "입주사 계약 관리",
                  content: (
                    <BuildingCompanyServiceManage buildingCompany={item} />
                  ),
                });
            }}
          >
            <FiEdit2 />
          </DefaultButton>
        </div>
      ),
      width: "icon",
    },
  ];

  const componentList: ComponentType<BuildingCompany>[] = [
    {
      labelName: "건물 등록일자",
      typeName: "dateRange",
      keyName: "createdAt",
      category: "date",
    },
    {
      labelName: "사용 여부",
      typeName: "comboBox",
      keyName: "isActive",
      category: "typeSearch",
      comboBoxOptions: addDefaultOption(USAGE_OPTIONS),
    },
    {
      labelName: "포함검색",
      typeName: "tagFilter",
      keyName: "tagFilter",
      category: "typeSearch",
    },
  ];

  return (
    <ListPage
      getDataApi={getBuildingCompanyCompanyList}
      keyId={(item: BuildingCompany) => item.buildingId}
      columnInfo={columnInfo}
      componentList={componentList}
      tableTitle="건물 입주사 목록"
      excelDownloadApi={getBuildingCompanyListExcel}
    />
  );
}
