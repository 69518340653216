import { useState } from "react";
import CommonPopup from "../../../Popup/CommonPopup";

type Props = {
  imageUrl: string;
  onDelete?: () => void;
  showDeleteButton?: boolean;
  index?: number
};

export default function ImageThumbnail({
  imageUrl,
  onDelete = () => {},
  showDeleteButton = false,
  index = 0,
}: Props) {
  const [showPopup, setShowPopup] = useState<string | null>("");

  const handleImageClick = (url: string) => {
    setShowPopup(url);
  };

  const handleImageClose = () => {
    setShowPopup(null);
  };

  return (
    <>
      {imageUrl && imageUrl.length > 0 && (
        <div className="relative w-32 h-32">
          <img
            src={imageUrl}
            alt=""
            className="w-full h-full object-cover border-2 border-gray-700"
            onClick={() => handleImageClick(imageUrl)}
            data-testid={`${index}-image-thumbnail-img-tag`}
          />
          {showDeleteButton && (
            <button
              className="absolute top-0 right-0 text-white bg-gray-700 focus:outline-none"
              onClick={onDelete}
            >
              <DeleteButtonIcon />
            </button>
          )}
          {showPopup && (
            <CommonPopup
              header="Image"
              content={
                <img
                  src={showPopup}
                  alt=""
                  className="max-w-[1024px] max-h-[700px] object-contain"
                />
              }
              closePopup={handleImageClose}
              isOpen={showPopup !== null}
            />
          )}
        </div>
      )}
    </>
  );
}

export const DeleteButtonIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="24" height="24" fill="black" />
    <path
      d="M16.5787 7.1496L7.37793 16.3504"
      stroke="white"
      strokeWidth="1.75"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M7.37793 7.1496L16.5787 16.3504"
      stroke="white"
      strokeWidth="1.75"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
  </svg>
);
