import { HttpStatusCode } from "axios";
import { useContext, useEffect, useState } from "react";
import { useModal } from "../../../contexts/Modal";
import { PopupContext } from "../../../contexts/Popup";
import {
  validationFunctions,
  validationResultMessage,
} from "../../../libs/validations";
import { getCompany, putCompany } from "../../../services/companyService";
import { Company } from "../../../types/company";
import {
  USAGE_OPTIONS,
  BOOLEAN_YN_TYPES,
  USAGE_YN_OPTIONS,
} from "../../../types/comboBoxOption";
import DefaultButton from "../../DefaultButton";
import FormHeader from "../../Form/Header";
import FormRow from "../../Form/Row";
import FormRowLabel from "../../Form/RowLabel";
import DefaultInput from "../../Input/DefaultInput";
import DefaultLabel from "../../Input/DefaultLabel";
import RadioButtonGroup from "../../Input/RadioButtonGroup";
import Spinner from "../../Spinner";

type Props = {
  companyId: string;
};

export default function CompanyDetailView({ companyId }: Props) {
  const { showAlert, showConfirm, handleError } = useModal();
  const { refreshAndClosePopup } = useContext(PopupContext);
  const [company, setCompany] = useState<Partial<Company>>({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        const response = await getCompany(companyId);
        if (response.status === HttpStatusCode.Ok) {
          const pudoPostPayYn = response.data.pudoPostPayYn
            ? response.data.pudoPostPayYn
            : BOOLEAN_YN_TYPES.FALSE;

          setCompany({
            ...response.data,
            pudoPostPayYn,
          });
        }
      } catch (err: any) {
        handleError(err, "조회");
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [companyId]);

  const handleCompanyChange = (data: Partial<Company>) => {
    setCompany((prev) => ({ ...prev, ...data }));
  };

  const handleUpdate = () => {
    const errorMessage = validationResultMessage([
      validationFunctions.required(company.companyName, "회사명"),
      validationFunctions.boolean(company.isActive, "사용 유무"),
    ]);

    if (errorMessage) {
      showAlert(errorMessage);
    } else {
      showConfirm("수정 하시겠습니까?", updateData);
    }
  };

  const updateData = async () => {
    try {
      const response = await putCompany(companyId, createData());

      if (response.status === HttpStatusCode.Ok) {
        showAlert("수정 되었습니다.");
        refreshAndClosePopup();
      }
    } catch (err: any) {
      handleError(err, "수정");
    }
  };

  const createData = () => {
    const data: Partial<Company> = {
      companyName: company.companyName,
      mailDomain: company.mailDomain,
      subMailDomain: company.subMailDomain,
      isActive: company.isActive,
      pudoPostPayYn: company.pudoPostPayYn,
    };
    return data;
  };

  return (
    <div className="flex flex-col gap-5">
      {isLoading && <Spinner />}
      <div className="flex flex-col min-w-[700px] max-h-[700px] overflow-y-auto">
        <FormHeader title="등록 정보" />
        <FormRow>
          <FormRowLabel title="회사 ID">
            <DefaultLabel text={company.companyId} />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <div className="flex gap-20">
            <FormRowLabel title="등록일시">
              <DefaultLabel text={company.createdAt} />
            </FormRowLabel>
            <FormRowLabel title="최종 수정일시">
              <DefaultLabel text={company.modifiedAt} />
            </FormRowLabel>
          </div>
        </FormRow>

        <FormHeader title="기본 정보" />
        <FormRow>
          <FormRowLabel title="회사명" isRequired>
            <DefaultInput
              value={company?.companyName ?? ""}
              onChange={(value: string) =>
                handleCompanyChange({ companyName: value })
              }
              placeholder="회사명을 입력해주세요"
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="메일 도메인">
            <DefaultInput
              value={company?.mailDomain ?? ""}
              onChange={(value: string) =>
                handleCompanyChange({ mailDomain: value })
              }
              placeholder="메일 도메인을 입력해주세요"
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="메일 서브 도메인">
            <DefaultInput
              value={company?.subMailDomain ?? ""}
              onChange={(value: string) =>
                handleCompanyChange({ subMailDomain: value })
              }
              placeholder="메일 서브 도메인을 입력해주세요"
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="사용 유무">
            <RadioButtonGroup
              options={USAGE_OPTIONS}
              value={company.isActive?.toString()}
              onChange={(value: string) =>
                handleCompanyChange({ isActive: value === "true" })
              }
            />
          </FormRowLabel>
        </FormRow>

        <FormRow>
          <FormRowLabel title="Pudo 택배 후불제 사용 여부">
            <RadioButtonGroup
              options={USAGE_YN_OPTIONS}
              value={company.pudoPostPayYn}
              onChange={(value: string) =>
                handleCompanyChange({ pudoPostPayYn: value })
              }
            />
          </FormRowLabel>
        </FormRow>
      </div>

      <div className="flex justify-center">
        <DefaultButton color="primary" onClick={handleUpdate}>
          수정
        </DefaultButton>
      </div>
    </div>
  );
}
