import CloseIcon from "../../svgs/icons/Common/Close";
import { TagData } from "../TagList";

type Props = { tag: TagData; onDelete: () => void };

export default function Tag({ tag, onDelete }: Props) {
  return (
    <div className="flex h-[38px] rounded-[20px] px-4 whitespace-nowrap bg-opacity-10 bg-brand-primary-gray-100 gap-2.5">
      <span className="flex items-center justify-center text-sm">
        {tag.name}
      </span>
      <button className="text-gray-600 hover:text-gray-700" onClick={onDelete}>
        <CloseIcon />
      </button>
    </div>
  );
}
