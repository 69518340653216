import DefaultButton from "../../../components/DefaultButton";
import EmergencyBuildingGroupListTopRight from "../../../components/PageComponents/Emergency/EmergencyBuildingGroupList/ListTopRight";
import ListPage from "../../../components/ListPage";
import ShelterMapping from "../../../components/PageComponents/Emergency/EmergencyBuildingGroupList/ShelterMapping";

import { ComponentType } from "../../../types/search";
import { TableColumnType } from "../../../components/Table";
import { FiEdit2 } from "react-icons/fi";
import { EmergencyBuildingGroup } from "../../../types/emergency";
import { getEmergencyBuildingGroupList } from "../../../services/emergencyService";
import clsx from "clsx";
import EmergencyBuildingGroupCreateForm from "@/components/PageComponents/Emergency/EmergencyBuildingGroupList/CreateForm";
import { useState } from "react";

type Props = {};

export default function EmergencyBuildingGroupList(props: Props) {
  const [refresh, setRefresh] = useState<boolean>(false);

  const columnInfo: TableColumnType<EmergencyBuildingGroup>[] = [
    { header: "No.", defaultNo: true, width: "no" },
    { header: "그룹명", name: "evacuationGroupName" },
    { header: "선택 건물 수", name: "buildingCount" },
    { header: "등록자 Email", name: "createdByEmail" },
    { header: "등록자 이름", name: "createdByName" },
    { header: "등록일시", name: "createdAt", width: "date" },
    {
      header: "대피영역 등록",
      render: (item: EmergencyBuildingGroup) => (
        <div className="w-full h-full flex gap-2 justify-center items-center">
          {convertBooleanToComplete(item.isCompleteDefaultShelter)}
        </div>
      ),
    },
    {
      header: "대피영역 설정 상태",
      render: (item: EmergencyBuildingGroup, handler) => (
        <div className="w-full h-full flex gap-2 justify-center items-center">
          <DefaultButton
            size="tableIcon"
            onClick={() => {
              if (handler)
                handler.popup({
                  header: (
                    <div className="flex items-center">
                      {"대피영역 지정"}
                      <span className="text-sm text-[#D95050] ml-4 font-normal">
                        *팝업을 닫을 경우 기존 작성된 내용이 삭제됩니다.
                      </span>
                    </div>
                  ),
                  content: (
                    <ShelterMapping
                      evacuationGroupId={item.evacuationGroupId}
                      setRefresh={setRefresh}
                    />
                  ),
                });
            }}
          >
            <FiEdit2 />
          </DefaultButton>
        </div>
      ),
      width: "icon",
    },
    {
      header: "상세",
      render: (item: EmergencyBuildingGroup, handler) => (
        <div className="w-full h-full flex gap-2 justify-center items-center">
          <DefaultButton
            size="tableIcon"
            onClick={() => {
              if (handler)
                handler.popup({
                  header: "비상대피 건물 그룹 상세",
                  content: (
                    <EmergencyBuildingGroupCreateForm
                      groupId={item.evacuationGroupId}
                      setRefresh={setRefresh}
                    />
                  ),
                });
            }}
          >
            <FiEdit2 />
          </DefaultButton>
        </div>
      ),
      width: "icon",
    },
  ];

  const convertBooleanToComplete = (bool: boolean) => {
    // 완료 / 미완료에 컬러 추가된 엘리먼트를 리턴하도록 변경 필요
    return (
      <div className={clsx(bool ? "text-brand-primary-green" : "text-red-500")}>
        {bool ? "완료" : "미완료"}
      </div>
    );
  };

  const componentList: ComponentType<EmergencyBuildingGroup>[] = [
    {
      labelName: "등록일자",
      typeName: "dateRange",
      keyName: "createdAt",
      category: "date",
    },
    {
      labelName: "그룹명",
      typeName: "text",
      keyName: "evacuationGroupName",
      category: "detailSearch",
      placeholder: "그룹명을 입력해주세요",
    },
    {
      labelName: "등록자 이름",
      typeName: "text",
      keyName: "createdByName",
      category: "detailSearch",
      placeholder: "이름을 입력해주세요",
    },
    {
      labelName: "등록자 Email",
      typeName: "text",
      keyName: "createdByEmail",
      category: "detailSearch",
      placeholder: "Email을 입력해주세요",
    },
  ];

  return (
    <ListPage
      getDataApi={getEmergencyBuildingGroupList}
      // keyId={(item: EmergencyBuildingGroup) => item?.groupId}
      columnInfo={columnInfo}
      componentList={componentList}
      tableTitle="비상대피 건물 그룹 목록"
      renderTopRight={
        <EmergencyBuildingGroupListTopRight setRefresh={setRefresh} />
      }
      defaultPageSize={10}
      defaultPageSizeList={[10, 20, 30]}
      refresh={refresh}
      setRefresh={setRefresh}
    />
  );
}
