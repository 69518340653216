import { HttpStatusCode } from "axios";
import { useContext, useState } from "react";

import {
  TAG_ID_REGEX,
  TAG_ID_REGEX_COMMENT,
  Tag,
  TagGroupType,
  TagType,
} from "../../../../../../types/tag";
import { ComboBoxType } from "../../../../../../types/search";
import {
  Building,
  BuildingGroup,
  TagSelectedItem,
  buildingGroupCategoryCodeValue,
} from "../../../../../../types/building";

import {
  validationFunctions,
  validationResultMessage,
} from "../../../../../../libs/validations";
import { toComboBoxType } from "../../../../../../utils/comboBoxUtils";

import { PopupContext } from "../../../../../../contexts/Popup";
import { useModal } from "../../../../../../contexts/Modal";
import { useUserContext } from "../../../../../../contexts/User";

import { getSensorList, postTag } from "../../../../../../services/tagService";
import {
  getBuildingFloor,
  getTempHumList,
} from "../../../../../../services/buildingService";

import FormHeader from "../../../../../Form/Header";
import FormRow from "../../../../../Form/Row";
import FormRowLabel from "../../../../../Form/RowLabel";
import TagSingleFilter from "../../../../../TagFilter/TagSingleFilter";
import DefaultSelect from "../../../../../SelectBox/DefaultSelect";
import RadioButtonGroup from "../../../../../Input/RadioButtonGroup";
import DefaultInput from "../../../../../Input/DefaultInput";
import DefaultButton from "../../../../../DefaultButton";
import Spinner from "../../../../../Spinner";

type Props = { tagType: TagType[]; tagGroupType: TagGroupType[] };

type OptionsType = {
  floorOptions: ComboBoxType[];
  spaceOptions: ComboBoxType[];
  tagTypeOptions: ComboBoxType[];
  tagGroupTypeOptions: ComboBoxType[];
};

const DELIMITER_TAG_NAME = "_";

export default function TempHumTagCreateForm({ tagType, tagGroupType }: Props) {
  const { userInfo } = useUserContext();
  const { closePopup, refreshAndClosePopup } = useContext(PopupContext);
  const { showAlert, showConfirm, handleError } = useModal();
  const [tempHumTag, setTempHumTag] = useState<Partial<Tag>>({
    isActive: true,
  });
  const [options, setOptions] = useState<OptionsType>({
    floorOptions: [],
    spaceOptions: [],
    tagTypeOptions: [],
    tagGroupTypeOptions: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTag, setSelectedTag] = useState<TagSelectedItem>();

  const resetFieldMap: Partial<Record<keyof Tag, Array<keyof Tag>>> = {
    buildingId: [
      "buildingFloorId",
      "buildingSpaceId",
      "tagGroupTypeId",
      "tagTypeId",
    ],
    buildingFloorId: ["buildingSpaceId", "tagGroupTypeId", "tagTypeId"],
    buildingSpaceId: ["tagGroupTypeId", "tagTypeId"],
    tagGroupTypeId: ["tagTypeId"],
  };

  const resetOptionMap: Partial<Record<keyof Tag, Array<keyof OptionsType>>> = {
    buildingId: ["tagGroupTypeOptions", "tagTypeOptions"],
    buildingFloorId: ["tagGroupTypeOptions", "tagTypeOptions"],
    buildingSpaceId: ["tagTypeOptions"],
  };

  const functionMap: Partial<Record<keyof Tag, (value: string) => void>> = {
    buildingId: (value) => fetchFloor(value),
    buildingFloorId: (value) => fetchSpace(tempHumTag.buildingId, value),
    buildingSpaceId: () => fetchSpaceOptions(),
    tagGroupTypeId: (value) => updateOptions(value),
  };

  const handlePost = async () => {
    const errorMessage = validationResultMessage([
      validationFunctions.required(tempHumTag.buildingId, "건물"),
      validationFunctions.required(tempHumTag.buildingSpaceId, "공간"),
      validationFunctions.required(tempHumTag.tagGroupTypeId, "센서유형"),
      validationFunctions.required(tempHumTag.tagTypeId, "태그유형"),
      validationFunctions.required(tempHumTag.tagId, "태그 ID"),
      validationFunctions.matchesRegex(
        tempHumTag.tagId,
        TAG_ID_REGEX,
        "태그 ID",
        TAG_ID_REGEX_COMMENT
      ),
    ]);

    if (errorMessage) {
      showAlert(errorMessage);
    } else {
      const tagName = getTagName();

      if (!tagName) {
        console.error("Failed to create tagName");
        return;
      }

      showConfirm("등록 하시겠습니까?", async () => {
        try {
          const response = await postTag({
            ...tempHumTag,
            createdBy: userInfo?.memberId ?? 0,
            tagName: tagName,
          });
          if (response.status === HttpStatusCode.Ok) {
            showAlert("등록 되었습니다.");
            refreshAndClosePopup();
          }
        } catch (err: any) {
          handleError(err, "등록");
        }
      });
    }
  };

  const findNameByValue = (options: ComboBoxType[], value: string) => {
    const option = options.find((option) => option.value === value);
    return option ? option.label : "";
  };

  const getTagName = () => {
    const items: string[] = [
      (selectedTag as Building)?.buildingName ?? "",
      findNameByValue(options.floorOptions, tempHumTag.buildingFloorId ?? ""),
      findNameByValue(options.spaceOptions, tempHumTag.buildingSpaceId ?? ""),
      findNameByValue(
        options.tagGroupTypeOptions,
        tempHumTag.tagGroupTypeId?.toString() ?? ""
      ),
      findNameByValue(
        options.tagTypeOptions,
        tempHumTag.tagTypeId?.toString() ?? ""
      ),
    ];
    const valid = items.every((item) => item !== "");
    return valid ? items.join(DELIMITER_TAG_NAME) : "";
  };

  const checkExistByTagType = async (filteredOptions: TagType[]) => {
    try {
      setIsLoading(true);

      const data = {
        buildingId: tempHumTag.buildingId,
        buildingFloorId: tempHumTag.buildingFloorId,
        buildingSpaceId: tempHumTag.buildingSpaceId,
      };

      if (Object.values(data).includes(undefined)) {
        throw new Error("태그 중복 체크 실패");
      }

      const response = await getSensorList(data);
      const options =
        response.data && response.data.items.length > 0
          ? filteredOptions.map((option) => ({
              value: option.tagTypeId.toString(),
              label: option.tagTypeName,
              disabled: response.data.items.find(
                (item) => item.tagTypeId === option.tagTypeId
              )
                ? true
                : false,
            }))
          : toComboBoxType(filteredOptions, "tagTypeId", "tagTypeName");

      setOptions((prev) => ({ ...prev, tagTypeOptions: options }));
    } catch (err: any) {
      handleError(err, "태그 중복 체크");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchFloor = async (buildingId: string) => {
    try {
      setIsLoading(true);

      const response = await getBuildingFloor(buildingId);

      if (response.data) {
        const options = toComboBoxType(
          response.data,
          "buildingFloorId",
          "floorName"
        );

        setOptions((prev) => ({
          ...prev,
          floorOptions: options,
        }));
      }
    } catch (err: any) {
      handleError(err, "층 정보 조회");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchSpace = async (
    buildingId: string | undefined,
    buildingFloorId: string
  ) => {
    try {
      setIsLoading(true);

      if (!buildingId) {
        throw new Error("Invalid Parameter");
      }

      const response = await getTempHumList({
        buildingId: buildingId,
        buildingFloorId: buildingFloorId,
      });

      if (response.status === HttpStatusCode.Ok) {
        const options = toComboBoxType(
          response.data.items,
          "buildingSpaceId",
          "spaceName"
        );

        setOptions((prev) => ({
          ...prev,
          spaceOptions: options,
        }));

        if (options.length === 0) {
          showAlert("등록된 공간이 없습니다.");
        }
      }
    } catch (err: any) {
      handleError(err, "공간 정보 조회");
    } finally {
      setIsLoading(false);
    }
  };

  const updateOptions = (value: string) => {
    const tagGroupId = Number(value);

    if (!Number.isNaN(tagGroupId)) {
      const filteredOptions = tagType.filter(
        (item) => item.tagGroupTypeId === tagGroupId
      );

      checkExistByTagType(filteredOptions);
    } else {
      console.error("data type not number");
    }
  };

  const resetFields = (keys: Array<keyof Tag>) => {
    const fields = keys.reduce((acc, key) => {
      acc[key] = undefined;
      return acc;
    }, {} as Partial<Tag>);

    setTempHumTag((prev) => ({ ...prev, ...fields }));
  };

  const resetOptions = (keys: Array<keyof OptionsType>) => {
    const options = keys.reduce((acc, key) => {
      acc[key] = [];
      return acc;
    }, {} as OptionsType);

    setOptions((prev) => ({ ...prev, ...options }));
  };

  const fetchSpaceOptions = () => {
    const options = toComboBoxType(
      tagGroupType,
      "tagGroupTypeId",
      "tagGroupTypeName"
    );

    setOptions((prev) => ({
      ...prev,
      tagGroupTypeOptions: options,
    }));
  };

  const handleChange =
    (key: keyof Tag, isNumber: boolean = false) =>
    (value: string) => {
      setTempHumTag((prev) => ({
        ...prev,
        [key]: isNumber
          ? !Number.isNaN(Number(value))
            ? Number(value)
            : value
          : value,
      }));

      if (functionMap[key]) {
        (functionMap[key] as (value: string) => void)(value);
      }

      resetFields(resetFieldMap[key] || []);
      resetOptions(resetOptionMap[key] || []);
    };

  return (
    <div className="flex flex-col gap-5">
      {isLoading && <Spinner />}
      <div className="flex flex-col min-w-[700px] max-h-[700px] overflow-y-auto">
        <FormHeader title="위치" />
        <FormRow>
          <FormRowLabel title="위치" isRequired>
            <div className="flex flex-col gap-1.5">
              <TagSingleFilter
                selectType="building"
                selectedItem={selectedTag}
                onChangeBuilding={(item: BuildingGroup) => {
                  setSelectedTag(item);
                  if (item.buildingId) {
                    handleChange("buildingId")(item.buildingId);
                  } else {
                    console.error("buildingId is not exist while selecting");
                  }
                }}
                categoryCode={buildingGroupCategoryCodeValue.IOT_DEVICE}
              />
              <DefaultSelect
                value={tempHumTag.buildingFloorId}
                placeholder="층 선택"
                optionList={options.floorOptions}
                onChange={handleChange("buildingFloorId")}
                disabled={
                  tempHumTag.buildingId === undefined ||
                  options.floorOptions.length === 0
                }
              />
            </div>
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="공간" isRequired>
            <DefaultSelect
              value={tempHumTag.buildingSpaceId}
              placeholder="선택"
              optionList={options.spaceOptions}
              onChange={handleChange("buildingSpaceId")}
              disabled={
                tempHumTag.buildingFloorId === undefined ||
                options.spaceOptions.length === 0
              }
            />
          </FormRowLabel>
        </FormRow>

        <FormHeader title="태그정보" />
        {options.tagGroupTypeOptions.length > 0 && (
          <FormRow>
            <FormRowLabel title="센서 유형" isRequired>
              <RadioButtonGroup
                options={options.tagGroupTypeOptions}
                value={tempHumTag.tagGroupTypeId?.toString()}
                onChange={handleChange("tagGroupTypeId", true)}
              />
            </FormRowLabel>
          </FormRow>
        )}
        {options.tagTypeOptions.length > 0 && (
          <FormRow>
            <FormRowLabel title="태그 유형" isRequired>
              <RadioButtonGroup
                options={options.tagTypeOptions}
                value={tempHumTag.tagTypeId?.toString()}
                onChange={handleChange("tagTypeId", true)}
              />
            </FormRowLabel>
          </FormRow>
        )}
        <FormRow>
          <FormRowLabel title="태그 ID" isRequired>
            <DefaultInput
              value={tempHumTag.tagId}
              onChange={handleChange("tagId")}
              maxLength={20}
              placeholder="태그 ID를 입력해주세요"
            />
          </FormRowLabel>
        </FormRow>
      </div>
      <div className="flex justify-center gap-2">
        <DefaultButton onClick={closePopup}>닫기</DefaultButton>
        <DefaultButton onClick={handlePost} color="primary">
          등록
        </DefaultButton>
      </div>
    </div>
  );
}
