import { useContext, useEffect, useState } from "react";

import { HttpStatusCode } from "axios";

import { Shelter } from "@/types/emergency";
import { FileScale } from "@/types/file";

import {
  validationFunctions,
  validationResultMessage,
} from "@/libs/validations";

import { useModal } from "@/contexts/Modal";
import { PopupContext } from "@/contexts/Popup";

import { getShelter, putShelter } from "@/services/emergencyService";

import DefaultButton from "@/components/DefaultButton";
import SingleImageUploader from "@/components/FileUploader/ImageFileUploader/SingleImageFileUploader";
import FormHeader from "@/components/Form/Header";
import FormRow from "@/components/Form/Row";
import FormRowLabel from "@/components/Form/RowLabel";
import DefaultInput from "@/components/Input/DefaultInput";
import DefaultLabel from "@/components/Input/DefaultLabel";
import Spinner from "@/components/Spinner";

import { getImageByScale } from "@/utils/fileUtils";
import RadioButtonGroup from "@/components/Input/RadioButtonGroup";
import { BOOLEAN_TYPES, USAGE_OPTIONS } from "@/types/comboBoxOption";

type Props = {
  shelterId: string;
};

export default function ShelterDetailView({ shelterId }: Props) {
  const { showAlert, showConfirm, handleError } = useModal();
  const { refreshAndClosePopup, closePopup } = useContext(PopupContext);
  const [shelter, setShelter] = useState<Partial<Shelter>>({});
  const [imageFile, setImageFile] = useState<File>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        const response = await getShelter(shelterId);
        if (response.status === HttpStatusCode.Ok) {
          setShelter({
            ...response.data,
          });
        }
      } catch (err: any) {
        handleError(err, "조회");
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [shelterId]);

  const handleShelterChange =
    (property: keyof Shelter) => (data: string | number | File | boolean) => {
      setShelter((prev) => {
        return { ...prev, [property]: data };
      });
    };

  const handleShelterChangeObject = (data: Partial<Shelter>) => {
    setShelter((prev) => ({ ...prev, ...data }));
  };

  const handleUpdate = () => {
    const errorMessage = validationResultMessage([
      validationFunctions.required(shelter.shelterName, "대피영역명"),
      validationFunctions.required(shelter.description, "대피영역 주소"),
      validationFunctions.required(shelter.lat, "위도"),
      validationFunctions.number(shelter.lat, "위도"),
      validationFunctions.required(shelter.lng, "경도"),
      validationFunctions.number(shelter.lng, "경도"),
    ]);

    if (errorMessage) {
      showAlert(errorMessage);
    } else {
      showConfirm("수정 하시겠습니까?", updateData);
    }
  };

  const updateData = async () => {
    try {
      const response = await putShelter(shelterId, createFormData());

      if (response.status === HttpStatusCode.Ok) {
        showAlert("수정 되었습니다.");
        refreshAndClosePopup();
      } else {
        throw new Error("Shelter put fail");
      }
    } catch (err: any) {
      handleError(err, "수정");
    }
  };

  const createFormData = () => {
    const formData = new FormData();

    const postData: Partial<Shelter> = {
      ...shelter,
      lat: Number(shelter.lat),
      lng: Number(shelter.lng),
    };

    if (imageFile) {
      formData.append("imageFiles", imageFile);
    }

    formData.append("request", JSON.stringify(postData));

    return formData;
  };

  return (
    <div className="flex flex-col gap-5">
      {isLoading && <Spinner />}
      <div className="flex flex-col min-w-[700px] max-h-[700px] overflow-y-auto">
        <FormHeader
          title="대피영역 정보"
          right={
            <span className="text-sm">
              ※위경도 정보는 구글 지도서비스(
              <a
                href="https://map.google.com"
                target="_blank"
                className="underline"
              >
                map.google.com
              </a>
              )를 이용하여 검색할 수 있습니다
            </span>
          }
        />
        <FormRow>
          <FormRowLabel title="대피영역 ID">
            <DefaultLabel text={shelter.shelterId} />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="등록자명">
            <DefaultLabel text={shelter.createdByName} />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="등록자 Email">
            <DefaultLabel text={shelter.createdByEmail} />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="대피영역명" isRequired>
            <div className="flex flex-col">
              <div className="flex gap-2 items-center">
                <DefaultInput
                  value={shelter.shelterName}
                  onChange={handleShelterChange("shelterName")}
                  placeholder="대피영역을 입력해주세요"
                  minWidth="w-[435px]"
                />
              </div>
            </div>
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="대피영역 주소" isRequired>
            <div className="flex flex-col">
              <div className="flex gap-2 items-center">
                <DefaultInput
                  value={shelter.description}
                  onChange={handleShelterChange("description")}
                  placeholder="대피영역 주소를 입력해주세요"
                  minWidth="w-[435px]"
                />
              </div>
            </div>
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="대피영역 이미지" isRequired>
            <SingleImageUploader
              image={imageFile}
              onChange={setImageFile}
              defaultImage={getImageByScale(
                shelter.fileInfo?.items ?? [],
                FileScale.MEDIUM
              )}
              onDelete={() => {
                handleShelterChangeObject({
                  fileInfo: undefined,
                  deleteFileInfo: true,
                });
              }}
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="위경도" isRequired>
            <div className="flex gap-2">
              <DefaultInput
                value={shelter.lat?.toString()}
                label="위도"
                placeholder="위도 입력"
                onChange={handleShelterChange("lat")}
                minWidth="w-[250px]"
              />
              <DefaultInput
                value={shelter.lng?.toString()}
                label="경도"
                placeholder="경도 입력"
                onChange={handleShelterChange("lng")}
                minWidth="w-[250px]"
              />
            </div>
          </FormRowLabel>
        </FormRow>
      </div>

      <div className="flex justify-center gap-2 py-5">
        <DefaultButton onClick={closePopup}>닫기</DefaultButton>
        <DefaultButton color="primary" onClick={handleUpdate}>
          수정
        </DefaultButton>
      </div>
    </div>
  );
}
