import clsx from "clsx";
import { useState } from "react";

export type CommonRadioProps = {
  checked?: boolean;
  value?: any;
  disabled?: boolean;
  onChange?: (text: string) => void;
  dataTestId?: string;
};

export const CommonRadio = ({
  checked,
  value,
  disabled,
  onChange,
  dataTestId,
}: CommonRadioProps) => {
  const [hover, setHover] = useState(false);

  return (
    <div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={clsx([
        { "opacity-20": disabled === true, checked: checked },
        "cursor-pointer flex justify-center items-center",
      ])}
      onClick={() => onChange?.(value)}
      aria-disabled={disabled}
      aria-checked={checked}
      aria-label={`${dataTestId}-common-radio`}
      data-testid={`${dataTestId}-${value}-common-radio`}
    >
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="2.5"
          y="2.5"
          width="15"
          height="15"
          rx="7.5"
          stroke={
            hover === true ? "#E7004B" : checked === true ? "black" : "#868687"
          }
        />
        {checked === true && (
          <rect x="6" y="6" width="8" height="8" rx="4" fill="black" />
        )}
      </svg>
    </div>
  );
};

export default CommonRadio;
