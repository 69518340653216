import clsx from "clsx";
import { ComboBoxType } from "../../../types/search";
import RequiredIcon from "../../../svgs/icons/Common/Required";
import { DEFAULT_SELECT_ALL_VALUE } from "../../../types/comboBoxOption";

import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { IoMdCheckmark, IoIosArrowDown } from "react-icons/io";
import { useState } from "react";
import { Button } from "@/components/ui/button";

type Props = {
  value?: string | null;
  onChange?: (value: string) => void;
  optionList?: ComboBoxType[];
  placeholder?: string;
  label?: string;
  disabled?: boolean;
  selectWidth?: string;
  required?: boolean;
  dataTestId?: string;
};

const defaultPopoverWidth = "w-[200px]";

export default function SearchAndSelect({
  value,
  onChange,
  selectWidth,
  dataTestId,
  optionList = [],
  placeholder = "",
  label = "",
  disabled = false,
  required = false,
}: Props) {
  const [open, setOpen] = useState(false);

  const selectedValue = value ? value : DEFAULT_SELECT_ALL_VALUE;

  const popoverWidth = selectWidth ? selectWidth : defaultPopoverWidth;

  const renderSearchComboBox = () => {
    return (
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            role="combobox"
            disabled={disabled}
            aria-expanded={open}
            className="w-[200px] h-[38px] justify-between rounded-[0px] pr-[2px] cursor-default hover:bg-inherit truncate border-gray-200"
          >
            <span className="text-label font-normal truncate">
              {selectedValue !== DEFAULT_SELECT_ALL_VALUE
                ? optionList.find((item) => item.value === selectedValue)?.label
                : "전체"}
            </span>

            <IoIosArrowDown className="ml-2 h-[13px] w-[13px] shrink-0" />
          </Button>
        </PopoverTrigger>

        <PopoverContent className={clsx(popoverWidth, "p-0")}>
          <Command>
            <CommandInput placeholder={`${label} 검색`} />
            <CommandList>
              <CommandEmpty>{`${label}을 찾을 수 없습니다.`}</CommandEmpty>
              <CommandGroup>
                <CommandItem
                  value={`${DEFAULT_SELECT_ALL_VALUE}|${"전체"}}`}
                  onSelect={(currentValue) => {
                    const [id] = currentValue.split("|");
                    onChange && onChange(id);
                    setOpen(false);
                  }}
                  className="flex"
                >
                  <IoMdCheckmark
                    className={clsx(
                      "mr-2 h-4 w-4 flex-shrink-0",
                      (value as string) === DEFAULT_SELECT_ALL_VALUE
                        ? "opacity-100"
                        : "opacity-0"
                    )}
                  />
                  {placeholder}
                </CommandItem>
                {optionList.map((item) => (
                  <CommandItem
                    key={item.value}
                    value={`${item.value}|${item.label}`} // 검색 대상 값(ex. 건물명)이 value 에 있어야 검색 가능. 여기선 label
                    onSelect={(currentValue) => {
                      const [id] = currentValue.split("|"); // 선택된 값에서 id 에 해당하는 값을 추출
                      onChange && onChange(id);
                      setOpen(false);
                    }}
                  >
                    <IoMdCheckmark
                      className={clsx(
                        "mr-2 h-4 w-4 flex-shrink-0",
                        value === item.value ? "opacity-100" : "opacity-0"
                      )}
                    />
                    {item.label}
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    );
  };

  return (
    <div
      className="flex flex-col gap-1.5 min-w-fit"
      data-testid={`${dataTestId}-search-select`}
    >
      {label && (
        <div className="flex items-center">
          <span className="text-label">{label}</span>
          {required && <RequiredIcon />}
        </div>
      )}
      {renderSearchComboBox()}
    </div>
  );
}
