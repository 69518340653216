import { useCallback } from "react";
import { getTemplateConnectionList } from "../../../../../services/templateService";
import { ComponentType } from "../../../../../types/search";
import { TemplateConnection } from "../../../../../types/template";
import ListPage, { SearchCondition } from "../../../../ListPage";
import { TableColumnType } from "../../../../Table";
import DefaultButton from "../../../../DefaultButton";
import BuildingServiceForm from "../../../../Building/BuildingList/ServiceForm";
import { FiEdit2 } from "react-icons/fi";
import { TEMPLATE_SERVICE_TYPES } from "../../../../../types/comboBoxOption";
import CompanyServiceForm from "../../../../Company/ServiceForm";
import { generateAxiosListResponse } from "../../../../../libs/request";

type Props = { serviceTemplateId: number; target?: string };

export default function TemplateConnectionTable({
  serviceTemplateId,
  target,
}: Props) {
  const columnInfo: TableColumnType<TemplateConnection>[] = [
    { header: "대상 ID", name: "targetId" },
    { header: "대상명", name: "targetName" },
    {
      header: "상세",
      render: (item: TemplateConnection, handler) => (
        <div className="w-full h-full flex gap-2 justify-center items-center">
          <DefaultButton
            size="tableIcon"
            color="blue"
            onClick={() => {
              if (handler?.popup) {
                handler.popup({
                  header: `서비스 관리${
                    item.targetName ? ` - ${item.targetName}` : ""
                  }`,
                  content:
                    target === TEMPLATE_SERVICE_TYPES.BUILDING ? (
                      <BuildingServiceForm buildingId={item.targetId} />
                    ) : (
                      <CompanyServiceForm companyId={item.targetId} />
                    ),
                });
              }
            }}
          >
            <FiEdit2 />
          </DefaultButton>
        </div>
      ),
    },
  ];

  const componentList: ComponentType<TemplateConnection>[] = [
    {
      labelName: "대상명",
      typeName: "text",
      keyName: "targetName",
      placeholder: "대상명을 입력해주세요",
    },
  ];

  const getTemplateConnectionListUseCallback = useCallback(
    async (params: SearchCondition<TemplateConnection>) => {
      if (!target) {
        return generateAxiosListResponse(params, []);
      }

      return getTemplateConnectionList(
        { ...params, target },
        serviceTemplateId
      );
    },
    [serviceTemplateId, target]
  );

  return (
    <ListPage
      getDataApi={getTemplateConnectionListUseCallback}
      componentList={componentList}
      columnInfo={columnInfo}
      tableTitle="연결 목록"
      isSmallButton
      isTableScroll
    />
  );
}
