import Tag from "../Tag";

export type TagData = {
  id: string;
  name: string;
  data: any;
};

type Props = {
  tags: TagData[];
  onDelete: (tagId: string | number) => void;
};

export default function TagList({ tags = [], onDelete }: Props) {
  return (
    <ul className="max-h-[150px]  overflow-y-auto flex flex-wrap items-center gap-2">
      {tags.map((tag, index) => (
        <li key={index}>
          <Tag tag={tag} onDelete={() => onDelete(tag.id)} />
        </li>
      ))}
    </ul>
  );
}
