import { useContext } from "react";
import { BsSlashLg } from "react-icons/bs";
import { PopupContext } from "../../contexts/Popup";
import {
  RESERVATION_DAILY_STATUS,
  RESERVATION_STATUS_TYPES,
  RESERVATION_WEEKLY_MONTHLY_STATUS,
  ReservationStatusType,
  SpaceReservationData,
  reservationStatusValue,
} from "../../types/spaceReservation";
import {
  calculateTime,
  compareDate,
  formatDate,
  getTimeDifference,
  getTimeInMinutes,
  getWeekRange,
  parseTime,
} from "../../utils/dateUtils";
import { TimeSearchCondition } from "../ListPage/TimeListPage";
import SpaceReservationCreateForm from "../PageComponents/Service/SpaceReservationList/CreateForm";
import SpaceReservationDetailView from "../PageComponents/Service/SpaceReservationList/DetailView";
import clsx from "clsx";

const DAILY_WIDTH = "min-w-[25px]";
const WEEKLY_WIDTH = "min-w-[100px]";
const MONTHLY_WIDTH = "min-w-[40px]";

export type TimeTableRange = "daily" | "weekly" | "monthly";

interface TimeTableProps {
  range: TimeTableRange;
  date: Date;
  spaceReservationData: SpaceReservationData[];
  isTableScroll?: boolean;
  onTabClick?: (tabIndex: number, searchCondition: TimeSearchCondition) => void;
}

export default function TimeTable({
  range,
  date,
  spaceReservationData,
  isTableScroll = false,
  onTabClick,
}: TimeTableProps) {
  const { openPopup } = useContext(PopupContext);

  const getEndHour = (range: TimeTableRange): number => {
    if (range === "daily") {
      return 23;
    } else if (range === "weekly") {
      return 6;
    } else if (range === "monthly") {
      return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
    }
    return 0;
  };

  const startHour = 0;
  const endHour = getEndHour(range);

  const getColumnHeaders = (): JSX.Element[] => {
    const headers: JSX.Element[] = [];

    if (range === "daily") {
      for (let hour = startHour; hour <= endHour; hour++) {
        const columnHeader = `${hour.toString().padStart(2, "0")}-${(hour + 1)
          .toString()
          .padStart(2, "0")}`;
        headers.push(
          <th
            colSpan={2}
            key={columnHeader}
            className={`text-xs py-2 ${DAILY_WIDTH}`}
          >
            {columnHeader}
          </th>
        );
      }
    } else if (range === "weekly") {
      const weekRange = getWeekRange(date);
      let currentDate = new Date(weekRange.start);

      for (let i = 0; i < 7; i++) {
        const {
          text: columnHeader,
          isSaturday,
          isSunday,
        } = getDayOfWeek(currentDate);

        const color = isSaturday
          ? "text-blue-600"
          : isSunday
          ? "text-red-600"
          : "";

        headers.push(
          <th
            key={columnHeader}
            className={`${WEEKLY_WIDTH} text-sm py-2 ${color}`}
          >
            {columnHeader}
          </th>
        );
        currentDate.setDate(currentDate.getDate() + 1);
      }
    } else if (range === "monthly") {
      const year = date.getFullYear();
      const month = date.getMonth();
      const daysInMonth = new Date(year, month + 1, 0).getDate();

      for (let i = 1; i <= daysInMonth; i++) {
        const day = new Date(year, month, 0);
        day.setDate(day.getDate() + i);
        const {
          text: columnHeader,
          isSaturday,
          isSunday,
        } = getDayOfWeek(day, true);

        const color = isSaturday
          ? "text-blue-600"
          : isSunday
          ? "text-red-600"
          : "";

        const [dateText, dayText] = columnHeader.split("\n").map(String);

        headers.push(
          <th
            key={columnHeader}
            className={`text-xs py-2 ${MONTHLY_WIDTH} ${color}`}
          >
            {dateText}
            <br />
            {dayText}
          </th>
        );
      }
    }

    return headers;
  };

  const getDayOfWeek = (date: Date, isNewLine: boolean = false) => {
    const dayOfWeek = new Intl.DateTimeFormat(navigator.language, {
      weekday: "short",
    }).format(date);

    const weekday = date.getDay();
    return {
      text: `${(date.getMonth() + 1).toString().padStart(2, "0")}/${date
        .getDate()
        .toString()
        .padStart(2, "0")}${isNewLine ? "\n" : ""}(${dayOfWeek})`,
      isSunday: weekday === 0,
      isSaturday: weekday === 6,
    };
  };

  const renderReservationStatus = (
    status: ReservationStatusType,
    index: number
  ): JSX.Element => {
    const getHeaderClassName = clsx("flex flex-col w-5 h-5 border", {
      "bg-[#FFB800]": status.value === RESERVATION_STATUS_TYPES.IN_USE,
      "bg-[#1FD7A1]": status.value === RESERVATION_STATUS_TYPES.CLEANING,
      "bg-[#E1E1E1]": status.value === RESERVATION_STATUS_TYPES.OUT_OF_SERVICE,
      "bg-brand-primary-magenta":
        status.value === RESERVATION_STATUS_TYPES.CONFIRMED,
      "bg-brand-primary-gray-100":
        status.value === RESERVATION_STATUS_TYPES.COMPLETED,
      "bg-[#F3F3F3]":
        status.value === RESERVATION_STATUS_TYPES.AVAILABLE ||
        status.value === RESERVATION_STATUS_TYPES.WAITING,
      "bg-brand-primary-gray-100 bg-opacity-30":
        status.value === RESERVATION_STATUS_TYPES.CONNECTION ||
        status.value === RESERVATION_STATUS_TYPES.UNAVAILABLE,
    });

    return (
      <div key={index} className="flex flex-row gap-2 mr-3 items-center">
        <div className={getHeaderClassName}>
          {status.isSlashed && <BsSlashLg className="w-full h-full" />}
          {status.isChecked && (
            <CheckIcon color={status.checkColor} className="w-full h-full" />
          )}
          {status.isCircle && <CircleIcon className="w-full h-full" />}
          {status.isLoading && <LoadingIcon className="w-full h-full" />}
          {status.isDots && <DotsIcon className="w-full h-full" />}
        </div>
        <span className="flex flex-col">{status.label}</span>
      </div>
    );
  };

  const getCell = (
    key: string,
    statusValue: reservationStatusValue,
    reservationHourText: string | undefined,
    spaceReservation: SpaceReservationData,
    reservationAt: string,
    spaceReservationDetailId: string = ""
  ): JSX.Element => {
    const status =
      range === "daily"
        ? RESERVATION_DAILY_STATUS.find(
            (status) => status.value === statusValue
          )
        : RESERVATION_WEEKLY_MONTHLY_STATUS.find(
            (status) => status.value === statusValue
          );

    if (status === undefined) {
      return <td key={key} className="bg-white border"></td>;
    }

    const onClick = () => {
      if (range === "daily") {
        if (statusValue === RESERVATION_STATUS_TYPES.AVAILABLE) {
          openPopup({
            header: "공간 예약 신청",
            content: (
              <SpaceReservationCreateForm
                reservationSpaceId={spaceReservation.reservationSpaceId}
                reservationDate={reservationAt}
                onRefresh={() => handleRefresh(reservationAt)}
              />
            ),
          });
        } else if (
          statusValue !== RESERVATION_STATUS_TYPES.OUT_OF_SERVICE &&
          statusValue !== RESERVATION_STATUS_TYPES.CLEANING &&
          statusValue !== RESERVATION_STATUS_TYPES.CONNECTION
        ) {
          openPopup({
            header: "공간 예약 상세",
            content: (
              <SpaceReservationDetailView
                spaceReservationDetailId={spaceReservationDetailId}
                onRefresh={() => handleRefresh(reservationAt)}
              />
            ),
          });
        }
      } else {
        if (onTabClick) {
          onTabClick(0, { reservationAt });
        }
      }
    };

    const width =
      range === "daily"
        ? DAILY_WIDTH
        : range === "weekly"
        ? WEEKLY_WIDTH
        : MONTHLY_WIDTH;

    const getContentClassName = clsx(`border h-10 ${width}`, {
      "bg-[#FFB800] hover:bg-opacity-70 cursor-pointer":
        status.value === RESERVATION_STATUS_TYPES.IN_USE,
      "bg-[#1FD7A1]": status.value === RESERVATION_STATUS_TYPES.CLEANING,
      "bg-[#E1E1E1]": status.value === RESERVATION_STATUS_TYPES.OUT_OF_SERVICE,
      "bg-brand-primary-magenta hover:bg-opacity-70 cursor-pointer":
        status.value === RESERVATION_STATUS_TYPES.CONFIRMED,
      "bg-brand-primary-gray-100 hover:bg-opacity-70 cursor-pointer":
        status.value === RESERVATION_STATUS_TYPES.COMPLETED,
      "bg-[#F3F3F3] hover:bg-gray-300 cursor-pointer":
        status.value === RESERVATION_STATUS_TYPES.AVAILABLE ||
        status.value === RESERVATION_STATUS_TYPES.WAITING,
      "bg-brand-primary-gray-100 bg-opacity-30 ":
        status.value === RESERVATION_STATUS_TYPES.CONNECTION ||
        status.value === RESERVATION_STATUS_TYPES.UNAVAILABLE,
    });

    return (
      <td key={key} className={getContentClassName} onClick={onClick}>
        <div className="relative w-full h-full flex justify-center items-center">
          <div className="absolute">
            <span
              className={clsx("text-sm", {
                "text-white":
                  statusValue === RESERVATION_STATUS_TYPES.COMPLETED,
              })}
            >
              {reservationHourText}
            </span>
            {status.isChecked && renderCheckIcon(statusValue)}
            {status.isCircle && <CircleIcon />}
            {status.isLoading && <LoadingIcon />}
            {status.isDots && <DotsIcon />}
          </div>
          {status.isSlashed && <DiagonalLine />}
        </div>
      </td>
    );
  };

  const renderCheckIcon = (status: reservationStatusValue) => {
    const color: CheckIconColor =
      status === RESERVATION_STATUS_TYPES.WAITING
        ? "primary"
        : status === RESERVATION_STATUS_TYPES.UNAVAILABLE ||
          status === RESERVATION_STATUS_TYPES.CONNECTION
        ? "gray"
        : "white";

    return <CheckIcon color={color} />;
  };

  const handleRefresh = (reservationAt: string) => {
    onTabClick?.(0, { reservationAt });
  };

  const getIndexes = () => {
    if (range === "daily")
      return RESERVATION_DAILY_STATUS.map((status, index) =>
        renderReservationStatus(status, index)
      );
    else
      return RESERVATION_WEEKLY_MONTHLY_STATUS.map((status, index) =>
        renderReservationStatus(status, index)
      );
  };

  const checkAvailableTime = (
    availableTimeStart: string,
    availableTimeEnd: string,
    targetTime: string
  ) => {
    const startMinutes = getTimeInMinutes(availableTimeStart);
    const endMinutes = getTimeInMinutes(availableTimeEnd);
    const targetMinutes = getTimeInMinutes(targetTime);

    return startMinutes <= targetMinutes && endMinutes > targetMinutes;
  };

  const isCleaningTime = (
    hour: number,
    minute: number,
    spaceReservation: SpaceReservationData
  ) => {
    const targetTime = new Date();
    targetTime.setHours(hour, minute, 0, 0);

    const blockTimeStart = spaceReservation.blockTimeStart as string;
    const blockTimeEnd = spaceReservation.blockTimeEnd as string;

    const startHour = parseInt(blockTimeStart.split(":")[0]);
    const startMinute = parseInt(blockTimeStart.split(":")[1]);
    const endHour = parseInt(blockTimeEnd.split(":")[0]);
    const endMinute = parseInt(blockTimeEnd.split(":")[1]);

    const startCleaningTime = new Date();
    startCleaningTime.setHours(startHour, startMinute, 0, 0);

    const endCleaningTime = new Date();
    endCleaningTime.setHours(endHour, endMinute, 0, 0);

    return targetTime >= startCleaningTime && targetTime < endCleaningTime;
  };

  const getTableRows = (): JSX.Element[] => {
    const getStatusFromCleanReservation = (status: reservationStatusValue) => {
      const statusTypes: reservationStatusValue[] = [
        RESERVATION_STATUS_TYPES.COMPLETED,
        RESERVATION_STATUS_TYPES.IN_USE,
        RESERVATION_STATUS_TYPES.CONNECTION,
      ];
      return statusTypes.includes(status)
        ? status
        : RESERVATION_STATUS_TYPES.CLEANING;
    };

    const rows: JSX.Element[] = [];
    if (range === "daily") {
      for (let spaceReservation of spaceReservationData) {
        const row: JSX.Element[] = [];
        row.push(
          <td
            className="min-w-label border"
            key={spaceReservation.reservationSpaceId}
          >
            {spaceReservation.reservationSpaceName}
          </td>
        );

        for (let hour = startHour; hour <= endHour; hour++) {
          for (let minute = 0; minute < 60; minute += 30) {
            const reservation = spaceReservation.reservationList.find(
              (reservation) =>
                (parseTime(reservation.reservationTimeStart).hour < hour ||
                  (parseTime(reservation.reservationTimeStart).hour === hour &&
                    parseTime(reservation.reservationTimeStart).minute <=
                      minute)) &&
                (parseTime(reservation.reservationTimeEnd).hour > hour ||
                  (parseTime(reservation.reservationTimeEnd).hour === hour &&
                    parseTime(reservation.reservationTimeEnd).minute > minute))
            );

            const cleanReservation = spaceReservation.reservationList.find(
              (reservation) =>
                (reservation.beforeCleanTime &&
                  reservation.beforeCleanTime !==
                    reservation.reservationTimeStart &&
                  parseTime(reservation.beforeCleanTime).hour === hour &&
                  parseTime(reservation.beforeCleanTime).minute === minute) ||
                (reservation.afterCleanTime &&
                  reservation.afterCleanTime !==
                    reservation.reservationTimeEnd &&
                  parseTime(calculateTime(reservation.afterCleanTime, -30))
                    .hour === hour &&
                  parseTime(calculateTime(reservation.afterCleanTime, -30))
                    .minute === minute)
            );

            const targetTime = `${hour.toString().padStart(2, "0")}:${minute
              .toString()
              .padEnd(2, "0")}`;

            const isTimeAvailable = checkAvailableTime(
              spaceReservation.availableTimeStart,
              spaceReservation.availableTimeEnd,
              targetTime
            );

            const status: reservationStatusValue = isTimeAvailable
              ? cleanReservation
                ? getStatusFromCleanReservation(
                    cleanReservation.reservationStatus
                  )
                : reservation
                ? reservation.reservationStatus
                : RESERVATION_STATUS_TYPES.AVAILABLE
              : RESERVATION_STATUS_TYPES.OUT_OF_SERVICE;

            const isCleaning =
              spaceReservation.useBlockTime &&
              isCleaningTime(hour, minute, spaceReservation);

            row.push(
              getCell(
                targetTime,
                isCleaning ? RESERVATION_STATUS_TYPES.CLEANING : status,
                "",
                spaceReservation,
                formatDate(date, "yyyy-MM-dd"),
                reservation?.spaceReservationDetailId ??
                  cleanReservation?.spaceReservationDetailId
              )
            );
          }
        }
        rows.push(<tr key={spaceReservation.reservationSpaceId}>{row}</tr>);
      }
    } else if (range === "weekly") {
      for (let spaceReservation of spaceReservationData) {
        const row: JSX.Element[] = [];
        row.push(
          <td
            className="min-w-label border"
            key={spaceReservation.reservationSpaceId}
          >
            {spaceReservation.reservationSpaceName}
          </td>
        );

        const weekRange = getWeekRange(date);
        const dateOfWeek = new Date(weekRange.start);

        for (let day = 0; day < 7; day++) {
          const currentDate = new Date(dateOfWeek);
          currentDate.setDate(dateOfWeek.getDate() + day);
          const reservation = spaceReservation.reservationList.find(
            (reservation) =>
              new Date(reservation.reservationDate).getDate() ===
              currentDate.getDate()
          );

          const reservationHourText =
            reservation?.reservationHour && reservation.reservationHour > 0
              ? `${reservation?.reservationHour} / ${getTimeDifference(
                  spaceReservation.availableTimeStart,
                  spaceReservation.availableTimeEnd
                )}`
              : "";

          const currentDateObj = new Date(currentDate);
          currentDateObj.setDate(currentDateObj.getDate() + 1);

          const status: reservationStatusValue =
            compareDate(new Date(), currentDateObj) === 1
              ? reservationHourText.length > 0
                ? RESERVATION_STATUS_TYPES.COMPLETED
                : RESERVATION_STATUS_TYPES.UNAVAILABLE
              : !reservation
              ? RESERVATION_STATUS_TYPES.AVAILABLE
              : RESERVATION_STATUS_TYPES.IN_USE;

          row.push(
            getCell(
              `${currentDate.getDate()}`,
              status,
              reservationHourText,
              spaceReservation,
              formatDate(
                new Date(
                  currentDate.getFullYear(),
                  currentDate.getMonth(),
                  currentDate.getDate()
                ),
                "yyyy-MM-dd"
              )
            )
          );
        }
        rows.push(<tr key={spaceReservation.reservationSpaceId}>{row}</tr>);
      }
    } else if (range === "monthly") {
      for (let spaceReservation of spaceReservationData) {
        const row: JSX.Element[] = [];
        row.push(
          <td
            className="min-w-label border"
            key={spaceReservation.reservationSpaceId}
          >
            {spaceReservation.reservationSpaceName}
          </td>
        );
        const daysInMonth = new Date(
          date.getFullYear(),
          date.getMonth() + 1,
          0
        ).getDate();

        for (let day = 1; day <= daysInMonth; day++) {
          const reservation = spaceReservation.reservationList.find(
            (reservation) =>
              new Date(reservation.reservationDate).getDate() === day
          );
          const reservationHourText = reservation?.reservationHour
            ? `${reservation?.reservationHour} / ${getTimeDifference(
                spaceReservation.availableTimeStart,
                spaceReservation.availableTimeEnd
              )}`
            : "";

          const status: reservationStatusValue =
            compareDate(
              new Date(),
              new Date(date.getFullYear(), date.getMonth(), day + 1)
            ) === 1
              ? reservationHourText.length > 0
                ? RESERVATION_STATUS_TYPES.COMPLETED
                : RESERVATION_STATUS_TYPES.UNAVAILABLE
              : !reservation
              ? RESERVATION_STATUS_TYPES.AVAILABLE
              : RESERVATION_STATUS_TYPES.IN_USE;

          row.push(
            getCell(
              `${day}`,
              status,
              reservationHourText,
              spaceReservation,
              formatDate(
                new Date(date.getFullYear(), date.getMonth(), day),
                "yyyy-MM-dd"
              )
            )
          );
        }
        rows.push(<tr key={spaceReservation.reservationSpaceId}>{row}</tr>);
      }
    }

    return rows;
  };

  return (
    <div className="flex flex-col bg-white">
      <div className="flex my-5 justify-end">{getIndexes()}</div>
      <div className={isTableScroll ? "h-96 max-h-96 overflow-auto" : ""}>
        <table className="w-full border-collapse text-center">
          <thead className="bg-white border">
            <tr className="h-10">
              <th className="min-w-label">예약 공간</th>
              {getColumnHeaders()}
            </tr>
          </thead>
          <tbody className="">{getTableRows()}</tbody>
        </table>
      </div>
    </div>
  );
}

export const DiagonalLine = () => (
  <svg
    className="bg-gray-300"
    width="100%"
    height="40"
    viewBox="0 0 50 50"
    preserveAspectRatio="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line x1="50" y1="0" x2="0" y2="50" stroke="gray" strokeWidth="1" />
  </svg>
);

export type CheckIconColor = "white" | "gray" | "primary";
type CheckIconProps = {
  color?: CheckIconColor;
  className?: string;
};
export const CheckIcon = ({
  color = "white",
  className = "",
}: CheckIconProps) => {
  return (
    <svg
      className={className}
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {color !== "primary" ? (
        <path
          d="M5.66846 13.3412L10.0651 17.339L19.3312 8.8811"
          stroke={color}
          strokeWidth="1.75"
          strokeLinecap="square"
        />
      ) : (
        <>
          <path
            d="M5.86816 13.3412L10.2648 17.339L19.5309 8.8811"
            stroke="url(#time_table_primary)"
            strokeWidth="3"
            strokeLinecap="square"
          />
          <defs>
            <linearGradient
              id="time_table_primary"
              x1="-23.7618"
              y1="34731.8"
              x2="38.0299"
              y2="34731.8"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#EA27C2" />
              <stop offset="1" stopColor="#E100A3" />
            </linearGradient>
          </defs>
        </>
      )}
    </svg>
  );
};

export const CircleIcon = ({ className = "" }: { className?: string }) => (
  <svg
    className={className}
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.3606 12.6484C17.3606 15.3328 15.1835 17.5083 12.4991 17.5083C9.81468 17.5083 7.63916 15.3328 7.63916 12.6484C7.63916 9.96239 9.81468 7.78687 12.4991 7.78687C15.1835 7.78687 17.3606 9.96239 17.3606 12.6484Z"
      stroke="#9F9F9F"
      strokeOpacity="0.6"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const LoadingIcon = ({ className = "" }: { className?: string }) => (
  <svg
    className={className}
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.2998 17.3914V20.554"
      stroke="white"
      strokeWidth="1.75"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M15.6523 15.9998L17.8899 18.2373"
      stroke="white"
      strokeWidth="1.75"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M6.70996 18.2373L8.9475 15.9998"
      stroke="white"
      strokeWidth="1.75"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M17.0439 12.6475H20.2065"
      stroke="white"
      strokeWidth="1.75"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M4.39355 12.6475H7.55615"
      stroke="white"
      strokeWidth="1.75"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M15.6523 9.29516L17.8899 7.05762"
      stroke="white"
      strokeWidth="1.75"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M6.70996 7.05762L8.9475 9.29516"
      stroke="white"
      strokeWidth="1.75"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M12.2998 4.74097V7.90356"
      stroke="white"
      strokeWidth="1.75"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
  </svg>
);

export const DotsIcon = ({ className = "" }: { className?: string }) => (
  <svg
    className={className}
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.1001 11.1475C5.27167 11.1475 4.6001 11.819 4.6001 12.6475C4.6001 13.4759 5.27167 14.1475 6.1001 14.1475C6.92852 14.1475 7.6001 13.4759 7.6001 12.6475C7.6001 11.819 6.92852 11.1475 6.1001 11.1475Z"
      fill="white"
    />
    <path
      d="M10.6001 12.6475C10.6001 11.819 11.2717 11.1475 12.1001 11.1475C12.9285 11.1475 13.6001 11.819 13.6001 12.6475C13.6001 13.4759 12.9285 14.1475 12.1001 14.1475C11.2717 14.1475 10.6001 13.4759 10.6001 12.6475Z"
      fill="white"
    />
    <path
      d="M16.6001 12.6475C16.6001 11.819 17.2717 11.1475 18.1001 11.1475C18.9285 11.1475 19.6001 11.819 19.6001 12.6475C19.6001 13.4759 18.9285 14.1475 18.1001 14.1475C17.2717 14.1475 16.6001 13.4759 16.6001 12.6475Z"
      fill="white"
    />
  </svg>
);
