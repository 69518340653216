import { useContext, useEffect, useState } from "react";
import { useModal } from "../../../../../contexts/Modal";
import { PopupContext } from "../../../../../contexts/Popup";
import {
  ValidType,
  validationFunctions,
  validationResultMessage,
} from "../../../../../libs/validations";
import {
  NEED_OPTIONS,
  TEMPLATE_ACTION_OPTIONS,
  TEMPLATE_ACTION_TYPES,
  TEMPLATE_SERVICE_OPTIONS,
  TEMPLATE_SERVICE_TYPES,
  USAGE_OPTIONS,
  BOOLEAN_TYPES,
} from "../../../../../types/comboBoxOption";
import {
  Template,
  TemplateActionJson,
  templateAppJsonData,
} from "../../../../../types/template";
import DefaultButton from "../../../../DefaultButton";
import SingleImageUploader from "../../../../FileUploader/ImageFileUploader/SingleImageFileUploader";
import FormHeader from "../../../../Form/Header";
import FormRow from "../../../../Form/Row";
import FormRowLabel from "../../../../Form/RowLabel";
import DefaultInput from "../../../../Input/DefaultInput";
import DefaultLabel from "../../../../Input/DefaultLabel";
import RadioButtonGroup from "../../../../Input/RadioButtonGroup";
import CommonPopup from "../../../../Popup/CommonPopup";
import CategorySelect from "../../../../SelectBox/CategorySelect";
import {
  CategoryData,
  CategoryReturnField,
  getTemplateActionCategoryData,
} from "../../../../../types/category";
import DefaultSelect from "../../../../SelectBox/DefaultSelect";
import TemplateParamDetailView from "../ParamDetailView";
import { postTemplate } from "../../../../../services/templateService";
import { HttpStatusCode } from "axios";
import { findCategoryValueById } from "../../../../../libs/category";
import clsx from "clsx";

const TEMPLATE_TYPE_TEXT = "템플릿";
export const TEMPLATE_BUTTON_1 = 1;
export const TEMPLATE_BUTTON_2 = 2;
export const TEMPLATE_CATEGORY_APP_ID = 22;

type TemplateActionJsonInput = TemplateActionJson & {
  selectedButton: string;
  selectedCategory: number[];
};

type Props = {};

export default function TemplateCreateForm(props: Props) {
  const { closePopup, refreshAndClosePopup } = useContext(PopupContext);
  const { showAlert, showConfirm, handleError } = useModal();
  const [template, setTemplate] = useState<Partial<Template>>({
    target: TEMPLATE_SERVICE_TYPES.BUILDING,
    useOnlyAuthorized: false,
    action: TEMPLATE_ACTION_TYPES.INFO,
    useBtn: false,
  });
  const [isOpen, setIsOpen] = useState(false);
  const [category, setCategory] = useState<CategoryData[]>([]);

  const [actionJson, setActionJson] = useState<
    Partial<TemplateActionJsonInput>[]
  >([{}, {}]);

  useEffect(() => {
    setCategory(getTemplateActionCategoryData());
  }, []);

  const handleOpenPopup = () => {
    setIsOpen(true);
  };

  const handleClosePopup = () => {
    setIsOpen(false);
  };

  const handleTemplateChange = (key: keyof Template) => (value: any) => {
    setTemplate((prev) => ({ ...prev, [key]: value }));
  };

  const handleTemplateChangeObject = (data: Partial<Template>) => {
    setTemplate((prev) => ({ ...prev, ...data }));
  };

  const handleActionJsonChange = (
    index: number,
    data: Partial<TemplateActionJsonInput>
  ) => {
    setActionJson((prevActionJson) => {
      const updatedActionJson = [...prevActionJson];
      updatedActionJson[index] = { ...updatedActionJson[index], ...data };
      return updatedActionJson;
    });
  };

  const handlePost = () => {
    const validations = [
      validationFunctions.required(template.iconImage, "대표 아이콘"),
      validationFunctions.required(template.serviceTemplateName, "서비스명"),
      validationFunctions.required(template.sortOrder, "노출 순서"),
    ];

    if (template.action === TEMPLATE_ACTION_TYPES.LINK) {
      validAppJson(validations, actionJson[0]);
    } else if (template.action === TEMPLATE_ACTION_TYPES.INFO) {
      validations.push(
        validationFunctions.required(template.actionInfoImage, "상세 이미지")
      );
    }

    if (template.useBtn) {
      for (let i = 0; i < 2; i++) {
        if (actionJson[i]?.selectedButton !== BOOLEAN_TYPES.FALSE) {
          validations.push(
            validationFunctions.required(
              actionJson[i]?.btn,
              `${i + 1}버튼 버튼명`
            )
          );

          validAppJson(validations, actionJson[i]);
        }
      }
    }

    const errorMessage = validationResultMessage(validations);
    if (errorMessage) {
      showAlert(errorMessage);
    } else {
      showConfirm("템플릿 서비스를 등록 하시겠습니까?", postData);
    }
  };

  const validAppJson = (
    validations: ValidType[],
    actionJson: Partial<TemplateActionJsonInput>
  ) => {
    const selectedCategory = actionJson.selectedCategory ?? [];
    const isSelectedAppCategoryValue =
      selectedCategory[selectedCategory.length - 1] ===
      TEMPLATE_CATEGORY_APP_ID;

    if (isSelectedAppCategoryValue) {
      const isEmpty = templateAppJsonData.every(
        (data) => !actionJson[data.value]
      );

      if (isEmpty) {
        validations.push(validationFunctions.required(null, "주소"));
      }
    } else {
      validations.push(
        validationFunctions.required(actionJson?.link, "url 혹은 이동 정보")
      );
    }
  };

  const postData = async () => {
    try {
      const res = await postTemplate(createFormData());
      if (res.status === HttpStatusCode.Ok) {
        showAlert("템플릿 서비스가 등록 되었습니다.");
        refreshAndClosePopup();
      }
    } catch (err: any) {
      handleError(err, "등록");
    }
  };

  const createFormData = () => {
    const formData = new FormData();

    template.iconImage && formData.append("iconFile", template.iconImage);
    template.actionInfoImage &&
      formData.append("actionInfoFile", template.actionInfoImage);

    const data: Partial<Template> = {
      iconImage: template.iconImage,
      actionInfoImage: template.actionInfoImage,
      serviceTemplateName: template.serviceTemplateName,
      isActive: true,
      target: template.target,
      useOnlyAuthorized: template.useOnlyAuthorized,
      action: template.action,
      useBtn: template.useBtn,
      sortOrder: template.sortOrder,
      actionJson: actionJson
        .filter((item) => {
          const { selectedButton, selectedCategory, ...json } = item;
          return selectedButton !== BOOLEAN_TYPES.FALSE && json.target
            ? json
            : null;
        })
        .map((item) => {
          const { selectedButton, selectedCategory, ...json } = item;
          return json;
        }) as TemplateActionJson[],
    };

    formData.append("request", JSON.stringify(data));
    return formData;
  };

  const getCategoryPlaceholder = (selectedId: number) => {
    const flag = selectedId === category[0]?.id;
    const placeholder = flag
      ? "호출 url을 입력해주세요"
      : "이동 정보를 입력해주세요";
    return placeholder;
  };

  const getCategoryValue = (
    id: number | undefined,
    returnType: CategoryReturnField
  ) => {
    if (!id) return null;
    return findCategoryValueById(id, category, returnType);
  };

  const handleCategoryChange = (index: number) => (value: number[]) => {
    const v0 = value[0];
    const v1 = value[1];

    const appJson =
      v1 !== TEMPLATE_CATEGORY_APP_ID
        ? Object.assign(
            {},
            ...templateAppJsonData.map((data) => ({ [data.value]: "" }))
          )
        : ({ link: "" } as TemplateActionJsonInput);

    const selectedData = {
      selectedCategory: value,
      target: getCategoryValue(v0, CategoryReturnField.ALIAS)?.toString(),
      targetName: getCategoryValue(v0, CategoryReturnField.NAME)?.toString(),
      action: v1
        ? getCategoryValue(v1, CategoryReturnField.ALIAS)?.toString()
        : undefined,
      actionName: v1
        ? getCategoryValue(v1, CategoryReturnField.NAME)?.toString()
        : undefined,
      ...appJson,
    } as TemplateActionJsonInput;

    handleActionJsonChange(index, selectedData);
  };

  const renderActionAdditional = () => {
    const isSelectedApp = actionJson[0].selectedCategory?.includes(
      TEMPLATE_CATEGORY_APP_ID
    );

    return (
      <div className="flex gap-2 w-full">
        <div
          className={clsx("flex gap-2 w-full", { "flex-col": isSelectedApp })}
        >
          <CategorySelect
            value={actionJson[0].selectedCategory ?? []}
            categoryData={category}
            onChange={handleCategoryChange(0)}
          />
          {isSelectedApp ? (
            renderPackageNameInput(TEMPLATE_BUTTON_1, actionJson[0])
          ) : (
            <DefaultInput
              value={actionJson[0].link}
              onChange={(value: string) =>
                handleActionJsonChange(0, { link: value })
              }
              minWidth="w-full"
              maxLength={200}
              placeholder={getCategoryPlaceholder(
                actionJson[0]?.selectedCategory?.[0] ?? 0
              )}
            />
          )}
        </div>
      </div>
    );
  };

  const renderButtonGroup = (
    buttonIndex: number,
    actionJson: Partial<TemplateActionJsonInput>
  ) => {
    const isSelectedApp = actionJson.selectedCategory?.includes(
      TEMPLATE_CATEGORY_APP_ID
    );

    return (
      <div className="flex gap-2 w-full">
        <DefaultSelect
          optionList={USAGE_OPTIONS}
          value={actionJson.selectedButton}
          onChange={(value: string) => {
            const updateData = { selectedButton: value };
            if (value === BOOLEAN_TYPES.FALSE) {
              setActionJson((prev) => {
                const newData = [...prev];
                newData[buttonIndex - 1] = updateData;
                return newData;
              });
            } else {
              handleActionJsonChange(buttonIndex - 1, updateData);
            }
          }}
          label={`버튼 ${buttonIndex}`}
          width="w-fit"
          required={buttonIndex === TEMPLATE_BUTTON_1}
          disabled={buttonIndex === TEMPLATE_BUTTON_1}
        />
        {actionJson.selectedButton !== BOOLEAN_TYPES.FALSE && (
          <div className="flex gap-2 pt-[26px] w-full">
            <div>
              <DefaultInput
                value={actionJson.btn}
                onChange={(value: string) =>
                  handleActionJsonChange(buttonIndex - 1, { btn: value })
                }
                placeholder={`버튼 ${buttonIndex} 이름을 입력해주세요`}
                maxLength={20}
                hideMaxLength
              />
            </div>
            <div className="flex gap-2 w-full">
              <div
                className={clsx("flex gap-2 w-full", {
                  "flex-col": isSelectedApp,
                })}
              >
                <CategorySelect
                  value={actionJson.selectedCategory ?? []}
                  categoryData={category}
                  onChange={handleCategoryChange(buttonIndex - 1)}
                />
                {isSelectedApp ? (
                  renderPackageNameInput(buttonIndex, actionJson)
                ) : (
                  <DefaultInput
                    value={actionJson.link}
                    onChange={(value: string) =>
                      handleActionJsonChange(buttonIndex - 1, { link: value })
                    }
                    minWidth="w-full"
                    maxLength={200}
                    placeholder={getCategoryPlaceholder(
                      actionJson.selectedCategory?.[0] ?? 0
                    )}
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderPackageNameInput = (
    buttonIndex: number,
    actionJson: Partial<TemplateActionJsonInput>
  ) => {
    return (
      <>
        {templateAppJsonData.map((data, index) => (
          <DefaultInput
            label={data.name}
            isLabelHorizontal
            key={index}
            value={actionJson[data.value]}
            onChange={(value: string) =>
              handleActionJsonChange(buttonIndex - 1, {
                [data.value]: value,
              })
            }
            minWidth="w-full"
            maxLength={data.maxLength}
            placeholder={`${data.name}을(를) 입력해주세요`}
          />
        ))}
      </>
    );
  };

  const renderFixAdditional = () => {
    return (
      <div className="flex flex-col gap-2">
        {renderButtonGroup(TEMPLATE_BUTTON_1, actionJson[0])}
        {renderButtonGroup(TEMPLATE_BUTTON_2, actionJson[1])}
      </div>
    );
  };

  const initActionJson = () => {
    setActionJson([
      { selectedButton: BOOLEAN_TYPES.TRUE },
      { selectedButton: BOOLEAN_TYPES.FALSE },
    ]);
  };

  return (
    <div className="flex flex-col gap-5">
      <div className="flex flex-col max-h-[800px] min-w-[1100px] overflow-y-auto">
        <FormHeader title="서비스 정보" />
        <FormRow>
          <FormRowLabel title="분류">
            <DefaultLabel text={TEMPLATE_TYPE_TEXT} />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="서비스 구분">
            <RadioButtonGroup
              options={TEMPLATE_SERVICE_OPTIONS}
              value={template.target}
              onChange={handleTemplateChange("target")}
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="회사 인증 여부">
            <RadioButtonGroup
              options={NEED_OPTIONS}
              value={template.useOnlyAuthorized?.toString()}
              onChange={(value: string) =>
                handleTemplateChange("useOnlyAuthorized")(
                  value === BOOLEAN_TYPES.TRUE
                )
              }
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="대표 아이콘" isRequired>
            <SingleImageUploader
              image={template.iconImage}
              onChange={handleTemplateChange("iconImage")}
              rightLabel="* 단건 등록"
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="서비스명" isRequired>
            <DefaultInput
              value={template.serviceTemplateName}
              onChange={handleTemplateChange("serviceTemplateName")}
              maxLength={15}
              minWidth="w-[250px]"
              placeholder="서비스명을 입력해주세요"
            />
          </FormRowLabel>
        </FormRow>

        <FormHeader
          title="서비스 상세"
          right={
            <DefaultButton size="popupContentButton" onClick={handleOpenPopup}>
              변수 목록
            </DefaultButton>
          }
        />
        <FormRow>
          <FormRowLabel title="접근 행동">
            <div className="flex flex-col gap-2 w-full">
              <RadioButtonGroup
                options={TEMPLATE_ACTION_OPTIONS}
                value={template.action}
                onChange={(value: string) => {
                  if (template.action !== value) {
                    const isGiveInfo = value === TEMPLATE_ACTION_TYPES.INFO;

                    handleTemplateChangeObject({
                      action: value,
                      useBtn: false,
                      ...(isGiveInfo && { isFixButton: false }),
                    });

                    initActionJson();
                  }
                }}
              />
              {template.action === TEMPLATE_ACTION_TYPES.LINK &&
                renderActionAdditional()}
            </div>
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel
            title="상세 이미지"
            isRequired={template.action === TEMPLATE_ACTION_TYPES.INFO}
          >
            <SingleImageUploader
              image={template.actionInfoImage}
              onChange={handleTemplateChange("actionInfoImage")}
              rightLabel="* 단건 등록"
            />
          </FormRowLabel>
        </FormRow>
        {template.action === TEMPLATE_ACTION_TYPES.INFO && (
          <FormRow>
            <FormRowLabel title="고정 버튼">
              <div className="flex flex-col gap-2 w-full">
                <RadioButtonGroup
                  options={USAGE_OPTIONS}
                  value={template.useBtn?.toString()}
                  onChange={(value: string) => {
                    if (template.useBtn?.toString() !== value) {
                      handleTemplateChangeObject({
                        useBtn: value === BOOLEAN_TYPES.TRUE,
                      });

                      initActionJson();
                    }
                  }}
                />
                {template.useBtn && renderFixAdditional()}
              </div>
            </FormRowLabel>
          </FormRow>
        )}
        <FormRow>
          <FormRowLabel title="노출 순서" isRequired>
            <DefaultInput
              type="number"
              value={template.sortOrder?.toString()}
              onChange={handleTemplateChange("sortOrder")}
              placeholder="노출 순서를 입력해주세요"
            />
          </FormRowLabel>
        </FormRow>
        {TemplateServiceAppInfo}
      </div>

      <div className="flex justify-center gap-2">
        <DefaultButton onClick={closePopup}>닫기</DefaultButton>
        <DefaultButton color="primary" onClick={handlePost}>
          등록
        </DefaultButton>
      </div>

      {isOpen && (
        <CommonPopup
          header="변수 목록"
          content={<TemplateParamDetailView closePopup={handleClosePopup} />}
          closePopup={handleClosePopup}
          isOpen={isOpen}
        />
      )}
    </div>
  );
}

export const TemplateServiceAppInfo = (
  <ul className="list-disc pl-12 pt-1 text-sm text-brand-primary-magenta font-medium">
    <li>앱 이동 서비스 설정 시 Native 개발이 추가로 필요합니다.</li>
    <li>
      Android: 앱 이동 시 안드로이드 스토어 주소 끝에 '?id=패키지명'을 붙여줘야
      합니다.
    </li>
    <li>
      내장호출 url의 경우 https만 가능합니다. http인 경우 호출이 되지 않습니다.
    </li>
  </ul>
);
