import { HttpStatusCode } from "axios";
import { useContext, useEffect, useState } from "react";
import { PopupContext } from "../../../../contexts/Popup";
import {
  validationFunctions,
  validationResultMessage,
} from "../../../../libs/validations";
import { getStore, patchStore } from "../../../../services/storeService";
import { CategoryData } from "../../../../types/category";
import { ComboBoxType, DASH } from "../../../../types/search";
import { Store, StoreModifyDto } from "../../../../types/store";
import DefaultButton from "../../../DefaultButton";
import FormHeader from "../../../Form/Header";
import FormRow from "../../../Form/Row";
import FormRowLabel from "../../../Form/RowLabel";
import DefaultInput from "../../../Input/DefaultInput";
import CategorySelect from "../../../SelectBox/CategorySelect";
import DefaultSelect from "../../../SelectBox/DefaultSelect";
import Spinner from "../../../Spinner";
import SingleImageUploader from "../../../FileUploader/ImageFileUploader/SingleImageFileUploader";
import {
  MAX_LENGTH_STORE_NAME,
  MAX_LENGTH_STORE_TEL,
  RENTAL_CATEGORY_ID,
} from "../CreateForm";
import RadioButtonGroup from "../../../Input/RadioButtonGroup";
import { USAGE_OPTIONS } from "../../../../types/comboBoxOption";
import { getBuildingFloor } from "../../../../services/buildingService";
import ImageThumbnail from "../../../FileUploader/ImageFileUploader/ImageThumbnail";
import { useModal } from "../../../../contexts/Modal";
import DefaultLabel from "../../../Input/DefaultLabel";
import DefaultTextArea from "../../../DefaultTextArea";
import PermissionWrapper from "../../../PermissionWrapper";
import { useListPageContext } from "../../../ListPage";

type Props = { storeId: string; categoryData: CategoryData[] };

export default function StoreDetailView({ storeId, categoryData }: Props) {
  const { closePopup } = useContext(PopupContext);
  const { refreshListPage } = useListPageContext();
  const { showAlert, showConfirm, handleError } = useModal();

  const [store, setStore] = useState<Partial<Store>>({});
  const [selectedCategory, setSelectedCategory] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [buildingFloor, setBuildingFloor] = useState<ComboBoxType[]>([]);

  const handleChange = (data: Partial<Store>) => {
    setStore((prev) => ({ ...prev, ...data }));
  };

  const handleCategoryChange = (value: number[]) => {
    setSelectedCategory(value);
    handleChange({ storeCategoryId: value[value.length - 1] });
  };

  const createFormData = (): FormData => {
    const formData = new FormData();

    if (store.uploadFiles) {
      formData.append("uploadFiles", store.uploadFiles);
    }

    const data: Partial<StoreModifyDto> = {
      buildingFloorId: store.buildingFloorId,
      location: store.location,
      storeCategoryId: store.storeCategoryId,
      name: store.name,
      tel: store.tel,
      subTel: store.subTel,
      ...(selectedCategory.includes(RENTAL_CATEGORY_ID) && {
        precaution: store.precaution,
      }),
      isActive: store.isActive,
      isDelete: store.fileUrl ? store.isDelete : false,
    };

    formData.append("storeModifyDto", JSON.stringify(data));

    return formData;
  };

  const fetchBuildingFloor = async (buildingId: string) => {
    if (!buildingId) {
      return;
    }

    try {
      setIsLoading(true);
      const response = await getBuildingFloor(buildingId);

      if (response.status === HttpStatusCode.Ok) {
        const floorOptions = response.data.map((item) => ({
          value: item.buildingFloorId,
          label: item.floorName,
        }));

        setBuildingFloor(floorOptions);
      }
    } catch (err: any) {
      handleError(err, "층 정보 조회");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchStore = async () => {
    try {
      setIsLoading(true);

      const response = await getStore(storeId);

      if (response.status === HttpStatusCode.Ok) {
        const data = response.data;
        const category: number[] = [
          Number(data.upperCategoryId),
          Number(data.midCategoryId),
          Number(data.lowerCategoryId),
        ].filter((categoryItem) => categoryItem !== 0);

        setStore({ ...data, storeCategoryId: category[category.length - 1] });
        setSelectedCategory(category);

        fetchBuildingFloor(data.buildingId);
      } else {
        throw new Error("Response data is null");
      }
    } catch (err: any) {
      handleError(err, "조회");
    } finally {
      setIsLoading(false);
    }
  };

  const handlePatch = () => {
    const errorMessage = validationResultMessage([
      validationFunctions.required(store?.buildingId, "기준 건물"),
      validationFunctions.required(store?.buildingFloorId, "건물 층"),
      validationFunctions.boolean(store?.isActive, "사용 여부"),
      validationFunctions.required(store?.storeCategoryId, "상점 유형"),
      validationFunctions.required(store?.name, "상점명"),
    ]);

    if (errorMessage) {
      showAlert(errorMessage);
    } else {
      showConfirm("상점을 수정 하시겠습니까?", patchData);
    }
  };

  const patchData = async () => {
    try {
      const response = await patchStore(storeId, createFormData());
      if (response.status === HttpStatusCode.Ok) {
        showAlert("상점 수정이 완료됐습니다.");
        refreshListPage(closePopup);
      }
    } catch (err: any) {
      handleError(err, "수정");
    }
  };

  useEffect(() => {
    fetchStore();
  }, []);

  return (
    <div className="flex flex-col gap-5">
      {isLoading && <Spinner />}
      <div className="flex flex-col max-h-[700px] overflow-y-auto">
        <FormHeader title="기본 정보" />
        <FormRow>
          <FormRowLabel title="상점 번호">
            <DefaultLabel text={store.storeId ?? DASH} />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="등록일시">
            <DefaultLabel text={store.createdAt ?? DASH} />
          </FormRowLabel>
          <FormRowLabel title="최종 수정일시">
            <DefaultLabel text={store.modifiedAt ?? DASH} />
          </FormRowLabel>
        </FormRow>

        <FormHeader title="기준 정보" />
        <FormRow>
          <FormRowLabel title="기준 건물" isRequired>
            <DefaultLabel text={store.buildingName ?? DASH} />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="상세 위치" isRequired>
            <div className="flex gap-2">
              <DefaultSelect
                value={store.buildingFloorId ?? ""}
                optionList={buildingFloor}
                onChange={(value: string) =>
                  handleChange({ buildingFloorId: value })
                }
              />
              <DefaultInput
                value={store?.location}
                onChange={(value: string) => handleChange({ location: value })}
                placeholder="상세 위치를 입력해주세요"
              />
            </div>
          </FormRowLabel>
        </FormRow>

        <FormHeader title="서비스 정보" />
        <FormRow>
          <FormRowLabel title="사용 여부" isRequired>
            <RadioButtonGroup
              options={USAGE_OPTIONS}
              value={store.isActive?.toString() ?? ""}
              onChange={(value: string) =>
                handleChange({ isActive: value === "true" })
              }
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="상점 유형" isRequired>
            <CategorySelect
              value={selectedCategory}
              onChange={handleCategoryChange}
              categoryData={categoryData}
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="상점명" isRequired>
            <DefaultInput
              value={store?.name}
              onChange={(value: string) => handleChange({ name: value })}
              minWidth="w-full"
              maxLength={MAX_LENGTH_STORE_NAME}
              placeholder="상점명을 입력해주세요"
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="대표 전화">
            <DefaultInput
              type="tel"
              value={store?.tel}
              onChange={(value: string) => handleChange({ tel: value })}
              maxLength={MAX_LENGTH_STORE_TEL}
              hideMaxLength
              placeholder="대표 전화번호를 입력해주세요"
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="추가 연락처">
            <DefaultInput
              type="tel"
              value={store?.subTel}
              onChange={(value: string) => handleChange({ subTel: value })}
              maxLength={MAX_LENGTH_STORE_TEL}
              hideMaxLength
              placeholder="추가 연락처를 입력해주세요"
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="대표 이미지">
            <div className="flex flex-col">
              <SingleImageUploader
                image={store.uploadFiles}
                onChange={(file: File) => {
                  handleChange({ uploadFiles: file, isDelete: true });
                }}
              />
              {store.fileUrl && !store.isDelete && (
                <ImageThumbnail
                  imageUrl={store.fileUrl ?? ""}
                  showDeleteButton
                  onDelete={() => {
                    handleChange({ isDelete: true });
                  }}
                />
              )}
            </div>
          </FormRowLabel>
        </FormRow>
        {selectedCategory.includes(RENTAL_CATEGORY_ID) && (
          <FormRow>
            <FormRowLabel title="대여하기">
              <DefaultTextArea
                label="기타 안내"
                value={store.precaution ?? ""}
                onChange={(value: string) =>
                  handleChange({ precaution: value })
                }
                rows={5}
                maxLength={200}
                placeholder="기타 안내 내용 및 휴무일 정보를 입력해주세요"
              />
            </FormRowLabel>
          </FormRow>
        )}
      </div>

      <div className="flex justify-center gap-2">
        <DefaultButton onClick={closePopup}>닫기</DefaultButton>
        <PermissionWrapper>
          <DefaultButton color="primary" onClick={handlePatch}>
            수정
          </DefaultButton>
        </PermissionWrapper>
      </div>
    </div>
  );
}
