import React from "react";
import RequiredIcon from "../../../svgs/icons/Common/Required";

type Props = {
  title: string;
  isRequired?: boolean;
  children?: React.ReactNode;
};

export default function FormRowLabel({
  title,
  isRequired = false,
  children,
}: Props) {
  return (
    <div className="flex items-center w-full">
      <div className="flex min-w-popup-label px-2.5 text-sm text-neutral-60">
        <span>{title}</span>
        {isRequired && <RequiredIcon />}
      </div>
      <div className="flex justify-between w-full items-center min-h-[38px]">
        {children}
      </div>
    </div>
  );
}
