type Props = {};

export default function IotMenuIcon(props: Props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.6">
        <path
          d="M16.92 18.8203C16.9521 18.8203 17.0021 18.8203 17.0021 18.8203C18.2327 18.8294 19.2026 17.8286 19.5276 17.5442C22.5175 14.9281 20.9222 9.66852 16.9753 9.16717C15.5624 0.617004 3.22034 3.87117 6.14636 12.0111C6.14636 12.0111 3.32909 12.3571 2.94225 14.6531C2.72372 17.2428 4.25788 18.8203 6.81198 18.8203"
          stroke="white"
          strokeMiterlimit="10"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
        <path
          d="M8.47754 17.8735H7.30566V12.2173H8.47754V17.8735ZM11.3135 17.9595C10.8916 17.9595 10.5231 17.8683 10.208 17.686C9.89551 17.5011 9.65462 17.2433 9.48535 16.9126C9.31868 16.5819 9.23535 16.1991 9.23535 15.7642C9.23535 15.3293 9.31868 14.9478 9.48535 14.6196C9.65462 14.2889 9.89551 14.0324 10.208 13.8501C10.5231 13.6678 10.8916 13.5767 11.3135 13.5767C11.7354 13.5767 12.1025 13.6678 12.415 13.8501C12.7301 14.0324 12.9723 14.2889 13.1416 14.6196C13.3135 14.9478 13.3994 15.3293 13.3994 15.7642C13.3994 16.1991 13.3135 16.5819 13.1416 16.9126C12.9723 17.2433 12.7301 17.5011 12.415 17.686C12.1025 17.8683 11.7354 17.9595 11.3135 17.9595ZM10.4072 15.7642C10.4072 16.0168 10.4411 16.2407 10.5088 16.436C10.5791 16.6287 10.6833 16.7811 10.8213 16.8931C10.9593 17.005 11.126 17.061 11.3213 17.061C11.5114 17.061 11.6742 17.0063 11.8096 16.897C11.945 16.785 12.0465 16.6326 12.1143 16.4399C12.1846 16.2472 12.2197 16.0272 12.2197 15.7798C12.2197 15.5246 12.1859 15.298 12.1182 15.1001C12.0505 14.8996 11.9489 14.7433 11.8135 14.6313C11.6781 14.5168 11.514 14.4595 11.3213 14.4595C11.126 14.4595 10.9593 14.5155 10.8213 14.6274C10.6833 14.7394 10.5791 14.8944 10.5088 15.0923C10.4411 15.2876 10.4072 15.5116 10.4072 15.7642ZM13.3707 12.2173H18.0035V13.186H16.2691V17.8735H15.105V13.186H13.3707V12.2173Z"
          fill="white"
        />
      </g>
    </svg>
  );
}
