import { HttpStatusCode } from "axios";
import { useEffect, useState } from "react";

import { ComboBoxType, ComponentType } from "../../../types/search";
import { EvacuationUser } from "@/types/emergency";

import { getShownEvacueeType, renderEvacueeType } from "@/libs/evacuation";

import { addDefaultOption } from "../../../utils/comboBoxUtils";

import {
  getEvacueeBulkExcelForm,
  getEvacueeList,
  postEvacueeBulkExcel,
} from "@/services/emergencyService";
import {
  getBuildingCompanyListAll,
  getBuildingGroupList,
} from "@/services/buildingService";

import EvacueeListTopRight from "@/components/PageComponents/Emergency/EvacueeList/ListTopRight";
import ExcelFileUploaderButton from "@/components/FileUploader/ExcelFileUploaderButton";
import EvacueeListDetailView from "@/components/PageComponents/Emergency/EvacueeList/DetailView";
import DefaultButton from "../../../components/DefaultButton";
import ListPage from "../../../components/ListPage";
import { TableColumnType } from "../../../components/Table";

import { FiEdit2 } from "react-icons/fi";

type Props = {};

export default function EvacueeList(props: Props) {
  const [buildingOptions, setBuildingOptions] = useState<ComboBoxType[]>([]);
  const [companyOptions, setCompanyOptions] = useState<ComboBoxType[]>([]);
  const [searchOptions, setSearchOptions] = useState<any>({});
  const TOTAL_PAGE_SIZE = 1000;

  const userTypeOptions = [
    { label: "수동 등록", value: "MANUAL" },
    { label: "출입 이력 연동", value: "LOG" },
  ];

  const columnInfo: TableColumnType<EvacuationUser>[] = [
    { header: "회원 ID", name: "memberId", width: "id" },
    { header: "회원 이름", name: "name" },
    { header: "전화번호", name: "mobileNumber" },
    {
      header: "권한",
      name: "role",
      render: (item: EvacuationUser) => {
        return getShownEvacueeType(item.role);
      },
    },
    { header: "건물", name: "evacuationBuildingName" },
    { header: "회사", name: "evacuationCompanyName" },
    { header: "부서", name: "evacuationDepartmentName" },
    {
      header: "인원 유형",
      render: (item: EvacuationUser) => renderEvacueeType(item),
    },
    {
      header: "상세",
      render: (item: EvacuationUser, handler) => (
        <div className="w-full h-full flex gap-2 justify-center items-center">
          <DefaultButton
            size="tableIcon"
            onClick={() => {
              if (handler)
                handler.popup({
                  header: "인원 등록 상세",
                  content: <EvacueeListDetailView userId={item.evacueeId} />,
                });
            }}
          >
            <FiEdit2 />
          </DefaultButton>
        </div>
      ),
      width: "icon",
    },
  ];

  const componentList: ComponentType<EvacuationUser>[] = [
    {
      labelName: "회원ID",
      typeName: "text",
      keyName: "memberId",
      category: "detailSearch",
      placeholder: "회원ID을 입력해주세요",
    },
    {
      labelName: "회원명",
      typeName: "text",
      keyName: "name",
      category: "detailSearch",
      placeholder: "회원명을 입력해주세요",
    },
    {
      labelName: "회원 전화번호",
      typeName: "text",
      keyName: "mobileNumber",
      category: "detailSearch",
      placeholder: "전화번호를 입력해주세요",
    },
    {
      labelName: "건물",
      typeName: "comboBox",
      keyName: "evacuationBuildingId",
      comboBoxOptions: addDefaultOption(buildingOptions),
      category: "detailSearch",
    },
    {
      labelName: "회사명",
      typeName: "comboBox",
      keyName: "evacuationCompanyId",
      comboBoxOptions: addDefaultOption(companyOptions),
      category: "detailSearch",
    },
    {
      labelName: "부서명",
      typeName: "text",
      keyName: "evacuationDepartmentName",
      category: "detailSearch",
      placeholder: "부서명을 입력해주세요",
    },
    {
      labelName: "인원 유형",
      typeName: "comboBox",
      keyName: "type",
      comboBoxOptions: addDefaultOption(userTypeOptions),
      category: "detailSearch",
    },
  ];

  const loadBuildingList = async () => {
    try {
      const result = await getBuildingGroupList({
        categoryCode: "ALL",
      });
      if (result.status === HttpStatusCode.Ok) {
        setBuildingOptions(
          result.data.map(
            (item) =>
              ({
                value: item.buildingId,
                label: item.buildingName,
              } as ComboBoxType)
          )
        );
      } else {
        console.error(result);
      }
    } catch (e: any) {
      console.error(e);
    }
  };

  const loadCompanyList = async () => {
    try {
      const result = await getBuildingCompanyListAll({
        buildingId: searchOptions.evacuationBuildingId,
      });
      if (result.status === HttpStatusCode.Ok) {
        setCompanyOptions(
          result.data.map(
            (item) =>
              ({
                value: item.companyId,
                label: item.companyName,
              } as ComboBoxType)
          )
        );
      } else {
        console.error(result);
      }
    } catch (e: any) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (searchOptions.evacuationBuildingId) {
      loadCompanyList();
    } else {
      setCompanyOptions([]);
    }
  }, [searchOptions?.evacuationBuildingId]);

  useEffect(() => {
    loadBuildingList();
  }, []);

  return (
    <ListPage
      getDataApi={getEvacueeList}
      keyId={(item: EvacuationUser) => item.memberId}
      columnInfo={columnInfo}
      componentList={componentList}
      tableTitle="비상대피 참여 인원 목록"
      renderTopRight={<EvacueeListTopRight />}
      excelUpload={
        <ExcelFileUploaderButton
          header={
            <div className="flex flex-row items-center">
              <div>파일업로드</div>
              <div className="text-sm text-[#D95050] font-normal ml-4">
                *등록되는 인원이 비회원인 경우 권한은 일반 사용자로 변경됩니다.
              </div>
            </div>
          }
          title="인원"
          getExcelDataApi={getEvacueeBulkExcelForm} // 엑셀 다운로드 API 함수
          postExcelDataApi={postEvacueeBulkExcel} // 수정된 JSON 업로드 API 함수
        />
      }
      defaultPageSize={10}
      defaultPageSizeList={[10, 20, 30]}
      onChangeSearch={(search) => setSearchOptions(search)}
    />
  );
}
