import clsx from "clsx";
import { Service } from "../../../types/service";
import CommonCheckbox from "../../Input/Checkbox";
import DefaultInput from "../../Input/DefaultInput";
import { useState } from "react";

type Props = {
  depth?: number;
  data: { [key: string]: Service[] };
  services: { [serviceId: string]: Service };
  selected: string;
  onContentChange: (item: Service) => void;
  onServiceChange: (service: Service) => void;
  onSelectedChange: (detailType: string) => void;
};

export default function TreeList({
  depth = 1,
  data,
  services,
  selected,
  onContentChange,
  onServiceChange,
  onSelectedChange,
}: Props) {
  const width = 16;
  const divPaddingLeftValue = depth * width;
  const itemPaddingLeftValue = divPaddingLeftValue + width * 2;

  const getChecked = (item: Service) => {
    if (item.serviceId) {
      return services[item.serviceId]?.isActive ?? item.isActive;
    }
    return false;
  };

  const renderRow = (item: Service, index: number) => (
    <div
      key={index}
      className={clsx("relative flex py-2.5 border-b border-neutral-15", {
        "bg-brand-tree": selected === item.serviceDetailType,
      })}
      style={{ paddingLeft: `${itemPaddingLeftValue}px` }}
    >
      <div
        className={clsx("flex items-center gap-1 w-full", {
          "font-semibold": selected === item.serviceDetailType,
        })}
      >
        <CommonCheckbox
          checked={getChecked(item)}
          onClick={() => {
            if (item.serviceId) {
              onServiceChange({
                ...item,
                isActive: !getChecked(item),
              });
            } else {
              onContentChange(item);
            }
          }}
        />
        <div
          className="hover:cursor-pointer"
          onClick={() => {
            onSelectedChange(item.serviceDetailType);
            onContentChange(item);
          }}
        >
          {item.useCustomServiceName
            ? item.serviceName
            : item.serviceTemplateName}
        </div>
      </div>
      <div className="absolute top-1/2 -translate-y-1/2 w-[90px] right-1">
        {renderInput(item)}
      </div>
      <br />
    </div>
  );

  const renderInput = (item: Service) => {
    return (
      getChecked(item) && (
        <DefaultInput
          type="number"
          minWidth="w-[90px]"
          value={
            services[item.serviceId]?.sortOrder?.toString() ??
            item.sortOrder?.toString()
          }
          onChange={(value: string) =>
            onServiceChange({
              ...item,
              isActive: true,
              sortOrder: Number(value),
            })
          }
          maxLength={5}
          hideMaxLength
        />
      )
    );
  };

  const renderHeader = (keyName: string) => (
    <div className={"py-2.5 border-b border-neutral-15"}>
      <div
        className="flex items-center gap-1 font-bold"
        style={{ paddingLeft: `${divPaddingLeftValue}px` }}
      >
        {`- ${keyName}`}
      </div>
    </div>
  );

  const tree =
    typeof data === "object"
      ? Object.keys(data).map((key, index) => {
          const currentData = data[key];

          if (Array.isArray(currentData)) {
            return (
              <div key={index} className={clsx("text-sm")}>
                {renderHeader(key)}
                {data[key].map((item, itemIndex) => renderRow(item, itemIndex))}
              </div>
            );
          } else if (typeof currentData === "object") {
            return (
              <div key={index} className={clsx("text-sm")}>
                <div className={"py-2.5 border-b border-neutral-15"}>
                  <div className="flex items-center gap-1 font-bold pl-4">
                    - {key}
                  </div>
                </div>
                <TreeList
                  key={index}
                  depth={depth + 1}
                  data={currentData}
                  services={services}
                  selected={selected}
                  onContentChange={onContentChange}
                  onServiceChange={onServiceChange}
                  onSelectedChange={onSelectedChange}
                />
              </div>
            );
          }
          return null;
        })
      : null;

  return <>{tree}</>;
}
