import {
  Member,
  ROLE_MANAGER_TYPES,
  getMemberRoleName,
} from "../../../types/member";
import { ComboBoxType, ComponentType } from "../../../types/search";
import { GENDER_OPTIONS } from "../../../types/comboBoxOption";
import { buildingGroupCategoryCodeValue } from "../../../types/building";

import {
  addDefaultOption,
  createBooleanOptions,
} from "../../../utils/comboBoxUtils";

import { useUserContext } from "../../../contexts/User";

import { getMemberExcel, getMemberList } from "../../../services/memberService";

import { TableColumnType } from "../../../components/Table";
import ListPage from "../../../components/ListPage";
import DefaultButton from "../../../components/DefaultButton";
import MemberDetailView from "../../../components/PageComponents/Member/MemberList/DetailView";

import { FiEdit2 } from "react-icons/fi";
import UseNoIcon from "../../../svgs/icons/UseYn/UseNoIcon";
import UseYesIcon from "../../../svgs/icons/UseYn/UseYesIcon";

type Props = {};

export default function MemberList(props: Props) {
  const { userInfo } = useUserContext();

  const exceptExcelDownloadRoleList = [ROLE_MANAGER_TYPES.ROLE_CALL_CENTER];

  const columnInfo: TableColumnType<Member>[] = [
    { header: "회원 ID", name: "memberId", width: "id" },
    { header: "회원 이메일", name: "email" },
    { header: "회원이름", name: "name" },
    { header: "상태", name: "status", width: "70" },
    { header: "생년월일", name: "birthday", width: "100" },
    { header: "가입일", name: "createdAt", width: "date" },
    {
      header: "권한유형",
      render: (item: Member) => getMemberRoleName(item.role),
    },
    { header: "최종 로그인", name: "lastLoggedAt", width: "date" },
    { header: "회사", name: "companyName" },
    { header: "건물", name: "buildingName" },
    { header: "층", name: "buildingFloorName" },
    { header: "추천인 이메일", name: "referrerEmail" },
    {
      header: "본인인증",
      render: (item: Member) => (
        <span className="flex justify-center items-center">
          {item.isSelfCertificated ? <UseYesIcon /> : <UseNoIcon />}
        </span>
      ),
      width: "icon",
    },
    {
      header: "광고성알림",
      render: (item: Member) => (
        <span className="flex justify-center items-center">
          {item.isAdvertiseAgreed ? <UseYesIcon /> : <UseNoIcon />}
        </span>
      ),
      width: "icon",
    },
    { header: "기기종류", name: "deviceType" },
    { header: "버전", name: "appVersion" },
    {
      header: "활동",
      render: (item: Member, handler) => (
        <div className="w-full h-full flex gap-2 justify-center items-center">
          <DefaultButton
            size="tableIcon"
            onClick={() => {
              if (handler)
                handler.popup({
                  header: "회원 정보 상세",
                  content: <MemberDetailView userId={item.memberId} />,
                });
            }}
          >
            <FiEdit2 />
          </DefaultButton>
        </div>
      ),
      width: "icon",
    },
  ];

  const MEMBER_STATUS_OPTIONS: ComboBoxType[] = [
    { value: "정상", label: "정상" },
    { value: "휴면", label: "휴면" },
    { value: "탈퇴", label: "탈퇴" },
  ];

  const AUTH_OPTIONS: ComboBoxType[] = createBooleanOptions("인증", "미인증");

  const AUTH_TYPE_OPTIONS: ComboBoxType[] = [
    { value: "일반사용자", label: "일반 사용자" },
    { value: "서비스매니저", label: "샌디 매니저" },
    { value: "샌디운영자", label: "샌디 운영자" },
  ];

  const DEVICE_OPTIONS: ComboBoxType[] = [
    { value: "Android", label: "Android" },
    { value: "IOS", label: "IOS" },
  ];

  const ADVERTISE_OPTIONS: ComboBoxType[] = createBooleanOptions("ON", "OFF");

  const getCategoryCode = () => {
    const role = userInfo?.role.split(",");

    if (role?.includes(ROLE_MANAGER_TYPES.ROLE_CALL_CENTER)) {
      return buildingGroupCategoryCodeValue.ALL;
    } else if (role?.includes(ROLE_MANAGER_TYPES.ROLE_SPACE)) {
      return buildingGroupCategoryCodeValue.CONTRACT;
    }

    return "";
  };

  const componentList: ComponentType<Member>[] = [
    {
      labelName: "가입일",
      typeName: "dateRange",
      keyName: "createdAt",
      category: "date",
    },
    {
      labelName: "연령대",
      typeName: "defaultDateRange",
      keyName: "birthday",
      category: "typeSearch",
    },
    {
      labelName: "회원 이메일",
      typeName: "text",
      keyName: "email",
      category: "detailSearch",
      placeholder: "회원 이메일을 입력해주세요",
    },
    {
      labelName: "회원이름",
      typeName: "text",
      keyName: "name",
      category: "detailSearch",
      placeholder: "회원이름을 입력해주세요",
    },
    {
      labelName: "회원별명",
      typeName: "text",
      keyName: "nickname",
      category: "detailSearch",
      placeholder: "회원별명을 입력해주세요",
    },
    {
      labelName: "성별",
      typeName: "comboBox",
      keyName: "gender",
      category: "typeSearch",
      comboBoxOptions: addDefaultOption(GENDER_OPTIONS),
    },
    {
      labelName: "회원 상태",
      typeName: "comboBox",
      keyName: "status",
      category: "typeSearch",
      comboBoxOptions: addDefaultOption(MEMBER_STATUS_OPTIONS),
    },
    {
      labelName: "본인인증",
      typeName: "comboBox",
      keyName: "isSelfCertificated",
      category: "typeSearch",
      comboBoxOptions: addDefaultOption(AUTH_OPTIONS),
    },
    {
      labelName: "회사인증",
      typeName: "comboBox",
      keyName: "isCompanyCertificated",
      category: "typeSearch",
      comboBoxOptions: addDefaultOption(AUTH_OPTIONS),
    },
    {
      labelName: "권한유형",
      typeName: "comboBox",
      keyName: "role",
      category: "typeSearch",
      comboBoxOptions: addDefaultOption(AUTH_TYPE_OPTIONS),
    },
    {
      labelName: "이용자 기기",
      typeName: "comboBox",
      keyName: "deviceType",
      category: "typeSearch",
      comboBoxOptions: addDefaultOption(DEVICE_OPTIONS),
    },
    {
      labelName: "광고성 알림선택",
      typeName: "comboBox",
      keyName: "isAdvertiseAgreed",
      category: "typeSearch",
      comboBoxOptions: addDefaultOption(ADVERTISE_OPTIONS),
    },
    {
      labelName: "건물 검색",
      typeName: "tagFilter",
      keyName: "tagFilterBuilding",
      category: "typeSearch",
      buildingGroupCategoryCode: getCategoryCode(),
      tagFilter: { tagFilterSearchType: "building" },
    },
    {
      labelName: "회사 검색",
      typeName: "tagFilter",
      keyName: "tagFilterCompany",
      category: "typeSearch",
      buildingGroupCategoryCode: getCategoryCode(),
      tagFilter: { tagFilterSearchType: "company" },
    },
  ];

  const hasRoleSpace = userInfo?.roles?.includes(ROLE_MANAGER_TYPES.ROLE_SPACE);
  const hasExceptedRoles = !exceptExcelDownloadRoleList.some((role) =>
    userInfo?.roles?.includes(role)
  );

  const getExcelDownloadApi =
    hasRoleSpace || hasExceptedRoles ? getMemberExcel : undefined;

  return (
    <ListPage
      getDataApi={getMemberList}
      keyId={(item: Member) => item.memberId}
      columnInfo={columnInfo}
      componentList={componentList}
      excelDownloadApi={getExcelDownloadApi}
      needExcelDownloadReason
      tableTitle="회원목록 전체"
    />
  );
}
