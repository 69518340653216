import { useContext } from "react";
import { PopupContext } from "../../../../../contexts/Popup";
import DefaultButton from "../../../../DefaultButton";
import ServiceGroupCreateForm from "../CreateForm";
import { ComboBoxType } from "../../../../../types/search";

type Props = { category: ComboBoxType[] };

export default function ServiceGroupListTopRight({ category }: Props) {
  const { openPopup } = useContext(PopupContext);

  return (
    <DefaultButton
      size="tableTop"
      color="default"
      onClick={() =>
        openPopup({
          header: "공간 등록",
          content: <ServiceGroupCreateForm category={category} />,
        })
      }
    >
      서비스그룹 등록
    </DefaultButton>
  );
}
