import { FiEdit2 } from "react-icons/fi";
import DefaultButton from "../../../components/DefaultButton";
import { TableColumnType } from "../../../components/Table";
import {
  GoodsRentalItem,
  GoodsRentalItemType,
  goodsRentalItemLabels,
} from "../../../types/goods";
import { ComponentType, DASH } from "../../../types/search";
import {
  GOODS_RENTAL_OPTIONS,
  usageYnLabels,
  USAGE_OPTIONS,
} from "../../../types/comboBoxOption";
import { addDefaultOption } from "../../../utils/comboBoxUtils";
import ListPage from "../../../components/ListPage";
import {
  getGoodsRentalItemBulkExcelForm,
  getGoodsRentalItemList,
  getGoodsRentalItemListExcel,
  postGoodsRentalItemBulkExcel,
} from "../../../services/goodsService";
import ExcelFileUploaderButton from "../../../components/FileUploader/ExcelFileUploaderButton";
import GoodsRentalItemListTopRight from "../../../components/PageComponents/Service/GoodsRentalItemList/ListTopRight";
import GoodsRentalItemDetailView from "../../../components/PageComponents/Service/GoodsRentalItemList/DetailView";
import DefaultLabel from "../../../components/Input/DefaultLabel";

type Props = {};

export default function GoodsRentalItemList(props: Props) {
  const columnInfo: TableColumnType<GoodsRentalItem>[] = [
    { header: "물품 번호", name: "goodsId", width: "id" },
    {
      header: "물품 종류",
      render: (item: GoodsRentalItem) =>
        goodsRentalItemLabels[item.goodsTypeId as GoodsRentalItemType] ?? DASH,
    },
    { header: "물품명", name: "goodsName" },
    { header: "등록일시", name: "createdAt", width: "date" },
    { header: "수정일시", name: "modifiedAt", width: "date" },
    {
      header: "사용유무",
      render: (item: GoodsRentalItem) => (
        <div className="flex items-center justify-center w-[88px]">
          <DefaultLabel
            text={item.isActive ? usageYnLabels.TRUE : usageYnLabels.FALSE}
            color={item.isActive ? "blue" : "gray"}
          />
        </div>
      ),
      width: "icon",
    },
    {
      header: "활동",
      render: (item: GoodsRentalItem, handler) => (
        <div className="flex w-full h-full justify-center items-center">
          <DefaultButton
            size="tableIcon"
            color="blue"
            onClick={() => {
              if (handler?.popup) {
                handler.popup({
                  header: "대여 물품 상세",
                  content: <GoodsRentalItemDetailView goodsId={item.goodsId} />,
                });
              }
            }}
          >
            <FiEdit2 />
          </DefaultButton>
        </div>
      ),
      width: "icon",
    },
  ];

  const componentList: ComponentType<GoodsRentalItem>[] = [
    {
      labelName: "등록일자",
      typeName: "dateRange",
      keyName: "createdAt",
      category: "date",
    },
    {
      labelName: "물품 종류",
      typeName: "comboBox",
      keyName: "goodsTypeId",
      comboBoxOptions: addDefaultOption(GOODS_RENTAL_OPTIONS),
      category: "typeSearch",
    },
    {
      labelName: "사용 유무",
      typeName: "comboBox",
      keyName: "isActive",
      comboBoxOptions: addDefaultOption(USAGE_OPTIONS),
      category: "typeSearch",
    },
    {
      labelName: "물품 번호",
      typeName: "text",
      keyName: "goodsId",
      category: "detailSearch",
      placeholder: "물품 번호를 입력해주세요",
    },
    {
      labelName: "물품명",
      typeName: "text",
      keyName: "goodsName",
      category: "detailSearch",
      placeholder: "물품명을 입력해주세요",
    },
  ];

  return (
    <ListPage
      getDataApi={getGoodsRentalItemList}
      keyId={(item: GoodsRentalItem) => item.goodsId}
      componentList={componentList}
      columnInfo={columnInfo}
      tableTitle="물품 목록"
      renderTopRight={<GoodsRentalItemListTopRight />}
      excelDownloadApi={getGoodsRentalItemListExcel}
      excelUpload={
        <ExcelFileUploaderButton
          title="대여 물품"
          postExcelDataApi={postGoodsRentalItemBulkExcel}
          getExcelDataApi={getGoodsRentalItemBulkExcelForm}
        />
      }
    />
  );
}
