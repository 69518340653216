import { DAY_UNIT, HOUR_UNIT, MINUTE_UNIT, SECOND_UNIT } from "./dateUtils";

import { SearchCondition } from "../components/ListPage";

export const formatToVirtualNumber = (number: string) => {
  const len = number.length;

  if (len === 10) {
    return number.replace(/(\d{2})(\d{4})(\d{4})/, "$1-$2-$3");
  } else if (len === 11) {
    return number.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
  } else if (len === 12) {
    return number.replace(/(\d{4})(\d{4})(\d{4})/, "$1-$2-$3");
  }

  return number;
};

export const formatMillisecondsToDetailTime = (Milliseconds: number) => {
  const days = Math.floor(
    Milliseconds / (SECOND_UNIT * MINUTE_UNIT * HOUR_UNIT * DAY_UNIT)
  );
  const hours = Math.floor(
    (Milliseconds % (SECOND_UNIT * MINUTE_UNIT * HOUR_UNIT * DAY_UNIT)) /
      (SECOND_UNIT * MINUTE_UNIT * HOUR_UNIT)
  );
  const minutes = Math.floor(
    (Milliseconds % (SECOND_UNIT * MINUTE_UNIT * HOUR_UNIT)) /
      (SECOND_UNIT * MINUTE_UNIT)
  );
  const seconds = Math.floor(
    (Milliseconds % (SECOND_UNIT * MINUTE_UNIT)) / SECOND_UNIT
  );

  const parts = [];

  if (days) parts.push(`${days}일`);
  if (hours) parts.push(`${hours}시간`);
  if (minutes) parts.push(`${minutes}분`);
  if (seconds) parts.push(`${seconds}초`);

  return parts.join(" ");
};

export const formatSortSearchCondition = <T>(params: SearchCondition<T>) => {
  const { sortBy, ...condition } = params;

  if (sortBy?.name) {
    condition.sort = `${sortBy.name.toString()}.${sortBy.order.toUpperCase()}`;
  }

  return condition;
};

export const phoneFormatter = (num: string) => {
  num = num.replace(/[^\d]/g, ""); // 숫자 외 문자 제거

  const patterns = [
    { regex: /^(\d{3})(\d{4})(\d{4})$/, format: "$1-$2-$3" }, // 010-1111-2222
    { regex: /^(\d{4})(\d{4})$/, format: "$1-$2" }, // 1234-5678
    { regex: /^02(\d{3,4})(\d{4})$/, format: "02-$1-$2" }, // 02-111-2222
    { regex: /^(\d{2,4})(\d{3,4})(\d{4})$/, format: "$1-$2-$3" }, // 0504-1111-2222
    { regex: /^(\d{2,})(\d{4})(\d{4})$/, format: "$1-$2-$3" }, // 그 외
  ];

  for (let pattern of patterns) {
    if (pattern.regex.test(num)) {
      return num.replace(pattern.regex, pattern.format);
    }
  }

  return num;
};
