import { useContext } from "react";
import { TagGroupType, TagType } from "../../../../../../types/tag";
import { PopupContext } from "../../../../../../contexts/Popup";
import DefaultButton from "../../../../../DefaultButton";
import TempHumTagCreateForm from "../CreateForm";

type Props = { tagType: TagType[]; tagGroupType: TagGroupType[] };

export default function TempHumTagListTopRight({
  tagType,
  tagGroupType,
}: Props) {
  const { openPopup } = useContext(PopupContext);
  return (
    <>
      <DefaultButton
        size="tableTop"
        color="default"
        onClick={() =>
          openPopup({
            header: "등록",
            content: (
              <TempHumTagCreateForm
                tagType={tagType}
                tagGroupType={tagGroupType}
              />
            ),
          })
        }
      >
        등록
      </DefaultButton>
    </>
  );
}
