import {
  DEFAULT_SELECT_ALL_VALUE,
  SUB_DEFAULT_SELECT_ALL_VALUE,
} from "../types/comboBoxOption";
import { CategoryReturnField } from "../types/category";
import {
  ComponentType,
  SearchDateRange,
  SearchDateRangeRadio,
} from "../types/search";

import { findCategoryValueById } from "./category";

import { SearchCondition } from "../components/ListPage";
import { SearchTagData } from "../components/TagFilter";
import { BuildingAndFloor } from "../components/SelectBox/BuildingAndFloorSelect";

export type ValidType = {
  isValid: boolean;
  errorMessage?: string;
};

export const validationResultMessage = (validations: ValidType[]) => {
  const result = validations.find((valid) => !valid.isValid);
  if (result?.isValid === false) {
    return result?.errorMessage;
  }
  return null;
};

export type ValidationFunction = (value: any, name?: string) => ValidType;

export const validationFunctions = {
  required: (
    value:
      | string
      | number
      | undefined
      | File
      | null
      | string[]
      | number[]
      | object,
    name?: string
  ): { isValid: boolean; errorMessage?: string } => {
    if (
      !value ||
      (typeof value === "string" &&
        (value.trim() === "" || value === DEFAULT_SELECT_ALL_VALUE)) ||
      (Array.isArray(value) && (value.length === 0 || value.some((v) => !v))) ||
      (typeof value === "number" && Number.isNaN(value)) ||
      (value instanceof File && !value.name) ||
      (!(value instanceof File) &&
        typeof value === "object" &&
        Object.keys(value).length === 0)
    ) {
      return {
        isValid: false,
        errorMessage: name
          ? `${name}을(를)\n입력 또는 선택해주세요.`
          : "값을 입력 또는 선택해주세요.",
      };
    }
    return { isValid: true };
  },
  isFalsy: (
    value: any,
    name?: string
  ): { isValid: boolean; errorMessage?: string } => {
    if (!value) {
      return {
        isValid: false,
        errorMessage: name
          ? `${name}을(를) 입력 또는 선택해주세요.`
          : "값을 입력 또는 선택해주세요.",
      };
    }
    return { isValid: true };
  },
  number: (
    value: string | number | undefined,
    name?: string
  ): { isValid: boolean; errorMessage?: string } => {
    const num = Number(value);

    if (Number.isNaN(num)) {
      return {
        isValid: false,
        errorMessage: name
          ? `${name}은(는) 유효한 숫자가 아닙니다.`
          : "유효한 숫자를 입력해주세요.",
      };
    }

    return { isValid: true };
  },
  radioChecked: (
    value: any,
    name?: string
  ): { isValid: boolean; errorMessage?: string } => {
    if (
      value === null ||
      value === undefined ||
      (typeof value === "object" && Object.keys(value).length === 0)
    ) {
      return {
        isValid: false,
        errorMessage: name
          ? `${name}을(를) 선택해주세요.`
          : "항목을 선택해주세요.",
      };
    }
    return { isValid: true };
  },
  maxLen: (maxLength: number = 50) => {
    return (
      value: any,
      name?: string
    ): { isValid: boolean; errorMessage?: string } => {
      if (value && value.length > maxLength) {
        return {
          isValid: false,
          errorMessage: name
            ? `${name}은(는) ${maxLength} 길이보다 작아야 합니다.`
            : `${maxLength} 길이보다 작아야 합니다.`,
        };
      }
      return { isValid: true };
    };
  },
  numberRange: (min: number, max: number) => {
    return (
      value: any,
      name?: string
    ): { isValid: boolean; errorMessage?: string } => {
      if ((value && value < min) || value > max) {
        return {
          isValid: false,
          errorMessage: name
            ? `${name} 값 범위는  ${min}이상 ${max}이하 합니다.`
            : `값 범위는  ${min}이상 ${max}이하 합니다.`,
        };
      }
      return { isValid: true };
    };
  },
  domain: (
    value: any,
    name?: string
  ): { isValid: boolean; errorMessage?: string } => {
    const emailRegex = /^((?!-)[A-Za-z0-9-]{1,63}(?<!-)\.)+[A-Za-z]{2,6}$/;
    if (!value || !emailRegex.test(value)) {
      return {
        isValid: false,
        errorMessage: name
          ? `${name} 도메인 형식이 올바르지 않습니다.`
          : "도메인 형식이 올바르지 않습니다.",
      };
    }
    return { isValid: true };
  },
  email: (
    value: any,
    name?: string
  ): { isValid: boolean; errorMessage?: string } => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!value || !emailRegex.test(value)) {
      return {
        isValid: false,
        errorMessage: name
          ? `${name} 메일 형식이 올바르지 않습니다.`
          : "메일 형식이 올바르지 않습니다.",
      };
    }
    return { isValid: true };
  },
  boolean: (
    value: any,
    name?: string
  ): { isValid: boolean; errorMessage?: string } => {
    if (typeof value !== "boolean") {
      return {
        isValid: false,
        errorMessage: name
          ? `${name} 값이 잘못 입력되었습니다.`
          : "값이 잘못 입력되었습니다.",
      };
    }
    return { isValid: true };
  },
  changedObject: (
    before: any,
    after: any
  ): { isValid: boolean; errorMessage?: string } => {
    if (JSON.stringify(before) === JSON.stringify(after)) {
      return {
        isValid: false,
        errorMessage: "변경사항이 없습니다.",
      };
    }
    return { isValid: true };
  },
  matchesRegex: (
    value: string | undefined | null,
    regex: RegExp,
    name?: string,
    ruleComment?: string
  ): { isValid: boolean; errorMessage?: string } => {
    let comment = ruleComment ? `\n허용 규칙: ${ruleComment}` : "";

    if (!value || value.trim() === "") {
      return {
        isValid: false,
        errorMessage:
          (name
            ? `${name}을(를) 입력 또는 선택해주세요.`
            : "값을 입력 또는 선택해주세요.") + comment,
      };
    }

    if (!regex.test(value)) {
      return {
        isValid: false,
        errorMessage:
          (name
            ? `${name}이(가) 유효한 형식이 아닙니다.`
            : "입력된 값이 유효한 형식이 아닙니다.") + comment,
      };
    }
    return { isValid: true };
  },
};

export const validateAndSetSearchConditions = <T,>(
  searchParams: SearchCondition<T>,
  componentList: ComponentType<T>[],
  showAlert: (message: string) => void,
  addValidValue: (key: string, value: string | undefined, target: any) => void
): SearchCondition<T> | null => {
  let newData = {} as SearchCondition<T>;

  const entries = Object.entries(searchParams);

  for (let i = 0; i < entries.length; i++) {
    const [key, value] = entries[i];
    const component = componentList.find((c) => c.keyName === key);

    if (value) {
      if (
        component &&
        (component.typeName === "dateRange" ||
          component.typeName === "defaultDateRange")
      ) {
        const { startDate, endDate } = value as SearchDateRange;

        newData = {
          ...newData,
          ...(startDate && {
            [`${key}Start`]: startDate.replace(/[^\d]/g, ""),
          }),
          ...(endDate && { [`${key}End`]: endDate.replace(/[^\d]/g, "") }),
        };
      } else if (value === DEFAULT_SELECT_ALL_VALUE) {
        // combobox 전체 선택 시 검색조건에서 제외
      } else if (component?.typeName === "tagFilter") {
        const tagFilterData = value as SearchTagData;
        const tagIds = (tagFilterData.data || []).map((tag) => tag.id);

        newData = {
          ...newData,
          ...(tagIds.length && {
            [`${tagFilterData.type}Id` ?? ""]: tagIds,
          }),
        };
      } else if (component?.typeName === "categoryComboBox") {
        if (Array.isArray(value)) {
          if (value.length > 0) {
            const alias = findCategoryValueById(
              value[value.length - 1],
              component.categoryData?.optionData ?? [],
              component.categoryData?.searchField ?? CategoryReturnField.ID
            );
            newData[key as keyof T] = alias as any;
          } else {
            // nothing to do
          }
        } else {
          console.error("category data must be an array");
        }
      } else if (component?.typeName === "dateRangeRadio") {
        const { selectRadio, dateRange } = value as SearchDateRangeRadio;
        const { startDate, endDate } = dateRange as SearchDateRange;

        newData = {
          ...newData,
          ...(startDate && {
            [`${selectRadio}Start`]: startDate.replace(/[^\d]/g, ""),
          }),
          ...(endDate && {
            [`${selectRadio}End`]: endDate.replace(/[^\d]/g, ""),
          }),
        };
      } else if (component?.typeName === "buildingAndFloor") {
        const { buildingId, buildingFloorId } = value as BuildingAndFloor;

        addValidValue("buildingId", buildingId, newData);
        addValidValue("buildingFloorId", buildingFloorId, newData);
      } else if (component?.typeName === "incommodityCategory") {
        if (value === SUB_DEFAULT_SELECT_ALL_VALUE) {
          showAlert("상세분류 선택을 해주세요");
          return null;
        }
        const serviceTemplateId = Number(value);
        if (!isNaN(serviceTemplateId)) {
          newData[key as keyof T] = value as any;
        } else {
          showAlert("상세분류 선택을 해주세요");
          return null;
        }
      } else {
        newData[key as keyof T] = value as any;
      }
    }
  }

  return newData;
};
