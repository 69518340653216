import { Dispatch, SetStateAction, useMemo } from "react";
import MultiSelectTable from "..";
import { getSpaceListConnection } from "../../../../services/spaceService";
import { SpaceConnection } from "../../../../types/space";
import { SearchCondition } from "../../../ListPage";
import { TableColumnType } from "../../../Table";
import axios, { HttpStatusCode } from "axios";

type Props = {
  reservationSpaceId: string;
  selectedData: SpaceConnection[];
  defaultIds?: string[];
  setSelectedData: (data: SpaceConnection[]) => void;
  setData: Dispatch<SetStateAction<SpaceConnection[]>>;
};

export default function SpaceMultiSelectTable({
  reservationSpaceId,
  selectedData,
  defaultIds = [],
  setSelectedData,
  setData,
}: Props) {
  let defaultIdsForUse = [...defaultIds];

  const columnInfo: TableColumnType<SpaceConnection>[] = [
    { header: "층 정보", name: "floorName" },
    { header: "회의실 ID", name: "reservationSpaceId" },
    { header: "회의실명", name: "reservationSpaceName" },
  ];

  const getSpaceListConnectionById = useMemo(
    () => async (params: SearchCondition<SpaceConnection>) => {
      try {
        const response = await getSpaceListConnection(reservationSpaceId);
        if (response.status === HttpStatusCode.Ok) {
          const defaultData =
            defaultIdsForUse.length > 0
              ? response.data.filter((item) =>
                  defaultIdsForUse.includes(item.reservationSpaceId)
                )
              : [];

          if (defaultIdsForUse.length > 0) {
            defaultIdsForUse = [];
          }

          setData((prev) => {
            const newArr = [...prev, ...defaultData];
            const newData = newArr.filter(
              (v, i, a) =>
                a.findIndex(
                  (t) => t.reservationSpaceId === v.reservationSpaceId
                ) === i
            );

            return newData;
          });

          return response;
        }
      } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
          console.error(error.response.data);
          return error.response.data;
        }
      }
    },
    [reservationSpaceId]
  );

  const compareItem = (item: SpaceConnection) => (o: SpaceConnection) => {
    return item.reservationSpaceId === o.reservationSpaceId;
  };

  return (
    <>
      <MultiSelectTable
        getDataApi={getSpaceListConnectionById}
        columnInfo={columnInfo}
        title="회의실 목록"
        selectedData={selectedData}
        onSelectedDataChange={setSelectedData}
        compareItem={compareItem}
      />
    </>
  );
}
