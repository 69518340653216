import { useEffect, useState } from "react";
import { CategoryData } from "../../../types/category";

type Props = {
  value: number[];
  onChange: (value: number[]) => void;
  categoryData?: CategoryData[];
  allOptionLabels?: string[];
  defaultOptions?: number[];
  label?: string;
};

export default function CategorySelect({
  value,
  onChange,
  categoryData = [],
  allOptionLabels = [],
  defaultOptions = [],
  label = "",
}: Props) {
  const [maxDepth, setMaxDepth] = useState(0);

  const getDefaultSelectedItems = (
    categoryData: CategoryData[],
    defaultOptions: number[]
  ): number[] => {
    const defaultSelectedItems: number[] = [];

    const traverse = (categories: CategoryData[], depth: number) => {
      if (!categories.length) return;

      const selectedIndex = defaultOptions?.[depth] ?? categories[0]?.id;
      defaultSelectedItems[depth] = selectedIndex;

      const selectedCategory = categories.find(
        (item) => item.id === selectedIndex
      );

      if (selectedCategory?.subList) {
        traverse(selectedCategory.subList, depth + 1);
      }
    };

    traverse(categoryData, 0);

    return defaultSelectedItems;
  };

  const getMaxDepth = (
    categories: CategoryData[],
    depth: number = 1
  ): number => {
    const depths = categories.map((category) => {
      if (!category.subList || category.subList.length === 0) {
        return depth;
      }

      return getMaxDepth(category.subList, depth + 1);
    });

    return depths.length > 0 ? Math.max(...depths) : 0;
  };

  useEffect(() => {
    const selectedItems =
      defaultOptions.length > 0
        ? getDefaultSelectedItems(categoryData, defaultOptions) // 기본 옵션 선택
        : allOptionLabels.length === 0
        ? getDefaultSelectedItems(categoryData, [categoryData[0]?.id]) // 기본 옵션, 라벨 없을 경우 첫 번째 항목 선택
        : [];

    onChange(selectedItems);
  }, []);

  useEffect(() => {
    const depth = getMaxDepth(categoryData);
    setMaxDepth(depth);
  }, [categoryData]);

  const renderComboBox = (
    depth: number,
    max: number,
    parentData: CategoryData[]
  ) => {
    if (depth > max) {
      return null;
    }

    const handleChange = (level: number, input: string) => {
      const selectedIndex = parseInt(input);

      const newSelectedItems =
        value === undefined ? [] : [...value].slice(0, level);
      if (selectedIndex > -1) {
        newSelectedItems[depth] = selectedIndex;
      }

      if (allOptionLabels.length === 0) {
        onChange(getDefaultSelectedItems(categoryData, newSelectedItems));
      } else {
        onChange(newSelectedItems);
      }
    };

    return (
      <div className="flex gap-2">
        <select
          onChange={(e) => handleChange(depth, e.target.value)}
          className="w-fit truncate h-[38px] border border-gray-200 pl-3 pr-1.5 py-[9px] text-label"
          value={value?.[depth] ?? -1}
          data-testid={`category-select`}
        >
          {allOptionLabels.length > 0 && (
            <option value={-1}>
              {allOptionLabels?.[depth] ?? "선택하세요"}
            </option>
          )}
          {parentData.map((item) => (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          ))}
        </select>
        {renderComboBox(
          depth + 1,
          allOptionLabels.length > 0 &&
            (value?.[depth] === -1 || value?.[depth] === undefined)
            ? max
            : getMaxDepth(
                parentData.find((item) => item.id === value?.[depth])
                  ?.subList ?? [],
                depth + 1
              ),
          value?.[depth] !== -1
            ? parentData.find((item) => item.id === value?.[depth])?.subList ??
                []
            : []
        )}
      </div>
    );
  };

  return (
    <div className="flex flex-col gap-1.5">
      {label && <label className="text-label">{label}</label>}
      {renderComboBox(0, maxDepth - 1, categoryData)}
    </div>
  );
}
