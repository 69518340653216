export const MASKING_TYPE_PREDEFINE = {
  BO_MEMBER_DETAIL: "BO_MEMBER_DETAIL",
  BO_POST_WRITER_DETAIL: "BO_POST_WRITER_DETAIL",
  BO_REPORT_POST_DETAIL_CREATED_BY: "BO_REPORT_POST_DETAIL_CREATED_BY",
  BO_REPORT_POST_DETAIL_REPORTED_BY: "BO_REPORT_POST_DETAIL_REPORTED_BY",
  BO_INCOMMODITY_DETAIL_CREATED_BY: "BO_INCOMMODITY_DETAIL_CREATED_BY",
  BO_INCOMMODITY_DETAIL_RECEIPTED_BY: "BO_INCOMMODITY_DETAIL_RECEIPTED_BY",
  BO_INCOMMODITY_DETAIL_SATISFIED_BY: "BO_INCOMMODITY_DETAIL_SATISFIED_BY",
  BO_SPACE_RESERVATION_DETAIL_ACTUAL_USER:
    "BO_SPACE_RESERVATION_DETAIL_ACTUAL_USER",
  BO_SPACE_RESERVATION_DETAIL_CREATED_BY:
    "BO_SPACE_RESERVATION_DETAIL_CREATED_BY",
  BO_GOODS_REQUEST_DETAIL_CREATED_BY: "BO_GOODS_REQUEST_DETAIL_CREATED_BY",
  BO_GOODS_RENTAL_DETAIL_CREATED_BY: "BO_GOODS_RENTAL_DETAIL_CREATED_BY",
  BO_BANNER_DETAIL_CREATED_BY: "BO_BANNER_DETAIL_CREATED_BY",
  BO_BANNER_DETAIL_MODIFIED_BY: "BO_BANNER_DETAIL_MODIFIED_BY",
  BO_TERM_DETAIL_CREATED_BY: "BO_TERM_DETAIL_CREATED_BY",
  BO_MEMBER_EXCEL: "BO_MEMBER_EXCEL",
  BO_INCOMMODITY_EXCEL: "BO_INCOMMODITY_EXCEL",
  BO_SPACE_RESERVATION_EXCEL: "BO_SPACE_RESERVATION_EXCEL",
  BO_GOODS_REQUEST_EXCEL: "BO_GOODS_REQUEST_EXCEL",
  BO_GOODS_RENTAL_EXCEL: "BO_GOODS_RENTAL_EXCEL",
  BO_BANNER_EXCEL: "BO_BANNER_EXCEL",
  BO_TERM_EXCEL: "BO_TERM_EXCEL",
} as const;

export type MaskingType = keyof typeof MASKING_TYPE_PREDEFINE;

type MaskingTypeListItem = {
  key: MaskingType;
  description: string;
};

export const MASKING_TYPE_LIST: MaskingTypeListItem[] = [
  { key: "BO_MEMBER_DETAIL", description: "BO 회원 상세,ACCESS_LOG" },
  {
    key: "BO_POST_WRITER_DETAIL",
    description: "BO 게시글 상세 - 작성자,ACCESS_LOG",
  },
  {
    key: "BO_REPORT_POST_DETAIL_CREATED_BY",
    description: "BO 신고 게시글 상세 - 작성자,ACCESS_LOG",
  },
  {
    key: "BO_REPORT_POST_DETAIL_REPORTED_BY",
    description: "BO 신고 게시글 상세 - 신고자,ACCESS_LOG",
  },
  {
    key: "BO_INCOMMODITY_DETAIL_CREATED_BY",
    description: "BO 신고센터 상세 - 등록자,ACCESS_LOG",
  },
  {
    key: "BO_INCOMMODITY_DETAIL_RECEIPTED_BY",
    description: "BO 신고센터 상세 - 확인자,ACCESS_LOG",
  },
  {
    key: "BO_INCOMMODITY_DETAIL_SATISFIED_BY",
    description: "BO 신고센터 상세 - 만족도 평가자,ACCESS_LOG",
  },
  {
    key: "BO_SPACE_RESERVATION_DETAIL_ACTUAL_USER",
    description: "BO 공간예약 상세 - 실사용자,ACCESS_LOG",
  },
  {
    key: "BO_SPACE_RESERVATION_DETAIL_CREATED_BY",
    description: "BO 공간예약 상세 - 신청자,ACCESS_LOG",
  },
  {
    key: "BO_GOODS_REQUEST_DETAIL_CREATED_BY",
    description: "BO 사무용품 신청 상세 - 등록자,ACCESS_LOG",
  },
  {
    key: "BO_GOODS_RENTAL_DETAIL_CREATED_BY",
    description: "BO 대여 이용 이력 상세 - 등록자(이용자),ACCESS_LOG",
  },
  {
    key: "BO_BANNER_DETAIL_CREATED_BY",
    description: "BO 배너 상세 - 최초 등록자,ACCESS_LOG",
  },
  {
    key: "BO_BANNER_DETAIL_MODIFIED_BY",
    description: "BO 배너 상세 - 최종 수정자,ACCESS_LOG",
  },
  {
    key: "BO_TERM_DETAIL_CREATED_BY",
    description: "BO 약관 상세 - 최초 등록자,ACCESS_LOG",
  },
  {
    key: "BO_MEMBER_EXCEL",
    description: "BO 회원 엑셀 다운로드,ACCESS_LOG,,reason required",
  },
  {
    key: "BO_INCOMMODITY_EXCEL",
    description: "BO 신고센터 엑셀 다운로드,ACCESS_LOG,,reason required",
  },
  {
    key: "BO_SPACE_RESERVATION_EXCEL",
    description: "BO 공간예약 엑셀 다운로드,ACCESS_LOG,,reason required",
  },
  {
    key: "BO_GOODS_REQUEST_EXCEL",
    description: "BO 사무용품 신청 엑셀 다운로드,ACCESS_LOG,,reason required",
  },
  {
    key: "BO_GOODS_RENTAL_EXCEL",
    description: "BO 대여 이용 이력 엑셀 다운로드,ACCESS_LOG,,reason required",
  },
  {
    key: "BO_BANNER_EXCEL",
    description: "BO 배너 엑셀 다운로드,ACCESS_LOG,,reason required",
  },
  { key: "BO_TERM_EXCEL", description: "BO 약관 엑셀" },
];

export const MASKING_PROPERTY_PREDEFINE = {
  NAME: "NAME",
  EMAIL: "EMAIL",
  MOBILE_NUMBER: "MOBILE_NUMBER",
  BIRTHDAY: "BIRTHDAY",
} as const;

export type MASKING_PROPERTY_TYPE = keyof typeof MASKING_PROPERTY_PREDEFINE;

export const convertProperty = (property: MASKING_PROPERTY_TYPE): string => {
  switch (property) {
    case "NAME":
      return "name";
    case "EMAIL":
      return "email";
    case "MOBILE_NUMBER":
      return "mobileNumber";
    case "BIRTHDAY":
      return "birthday";
    default:
      return "name";
  }
};
