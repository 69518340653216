import { useContext } from "react";
import TempHumCreateForm from "../CreateForm";
import { PopupContext } from "../../../../../../contexts/Popup";
import DefaultButton from "../../../../../DefaultButton";
import PermissionWrapper from "../../../../../PermissionWrapper";

type Props = {};

export default function TempHumListTopRight(props: Props) {
  const { openPopup } = useContext(PopupContext);
  return (
    <>
      <PermissionWrapper>
        <DefaultButton
          size="tableTop"
          color="default"
          onClick={() =>
            openPopup({ header: "등록", content: <TempHumCreateForm /> })
          }
        >
          등록
        </DefaultButton>
      </PermissionWrapper>
    </>
  );
}
