import { useContext } from "react";
import { PopupContext } from "../../../../contexts/Popup";
import DefaultButton from "../../../DefaultButton";
import BannerCreateForm from "../CreateForm";
type Props = {};

export default function BannerListTopRight(props: Props) {
  const { openPopup } = useContext(PopupContext);

  return (
    <DefaultButton
      size="tableTop"
      color="default"
      onClick={() =>
        openPopup({
          header: "배너/팝업 등록",
          content: <BannerCreateForm />,
        })
      }
    >
      신규등록
    </DefaultButton>
  );
}
