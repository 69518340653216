import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { HttpStatusCode } from "axios";

import {
  MaintenanceResponse,
  getMaintenanceFlag,
} from "../../services/userServices";

export const useMaintenance = (): [
  MaintenanceResponse,
  () => Promise<boolean>
] => {
  const exceptUserAgent = "U2FuZGlBZG1pbkFjY2VzczEwMTY="; // base64 Encoding

  const navigate = useNavigate();

  const [maintenance, setMaintenance] = useState<MaintenanceResponse>({
    isMaintenance: false,
  });

  const checkMaintenance = async () => {
    if (window.navigator.userAgent.includes(exceptUserAgent)) {
      return false;
    }

    try {
      const res = await getMaintenanceFlag();
      if (res.status === HttpStatusCode.Ok) {
        const message = res.data.message?.replaceAll("\\n", "\n");
        setMaintenance({ ...res.data, message });

        if (res.data.isMaintenance) {
          navigate("/maintenance", { replace: true });
        }

        return res.data.isMaintenance;
      }
    } catch (e) {
      console.error(e);
    }

    return false;
  };

  return [maintenance, checkMaintenance];
};
