import { FiEdit2 } from "react-icons/fi";
import DefaultButton from "../../../components/DefaultButton";
import ExcelFileUploaderButton from "../../../components/FileUploader/ExcelFileUploaderButton";
import ListPage from "../../../components/ListPage";
import GoodsRequestItemListTopRight from "../../../components/PageComponents/Service/GoodsRequestItemList/ListTopRight";
import { TableColumnType } from "../../../components/Table";
import {
  getGoodsRequestItemBulkExcelForm,
  getGoodsRequestItemList,
  getGoodsRequestItemListExcel,
  postGoodsRequestItemBulkExcel,
} from "../../../services/goodsService";
import {
  GoodsRequestItem,
  GoodsRequestItemType,
  goodsRequestItemLabels,
} from "../../../types/goods";
import { ComponentType, DASH } from "../../../types/search";
import { addDefaultOption } from "../../../utils/comboBoxUtils";
import {
  GOODS_REQUEST_OPTIONS,
  usageYnLabels,
  USAGE_OPTIONS,
} from "../../../types/comboBoxOption";
import GoodsRequestItemDetailView from "../../../components/PageComponents/Service/GoodsRequestItemList/DetailView";
import DefaultLabel from "../../../components/Input/DefaultLabel";

type Props = {};

export default function GoodsRequestItemList(props: Props) {
  const columnInfo: TableColumnType<GoodsRequestItem>[] = [
    { header: "물품 번호", name: "goodsId", width: "id" },
    {
      header: "물품 종류",
      render: (item: GoodsRequestItem) =>
        goodsRequestItemLabels[item.goodsTypeId as GoodsRequestItemType] ??
        DASH,
    },
    { header: "물품명", name: "goodsName" },
    {
      header: "대상 정보",
      render: (item: GoodsRequestItem) => (
        <div className="flex justify-center items-center">
          {item.buildingName && (
            <>
              {`[${item.buildingName}]`}
              <br />
            </>
          )}
          {item.companyName}
        </div>
      ),
    },
    { header: "등록일시", name: "createdAt", width: "date" },
    { header: "수정일시", name: "modifiedAt", width: "date" },
    {
      header: "사용유무",
      render: (item: GoodsRequestItem) => (
        <div className="flex justify-center items-center w-[88px]">
          <DefaultLabel
            text={item.isActive ? usageYnLabels.TRUE : usageYnLabels.FALSE}
            color={item.isActive ? "blue" : "gray"}
          />
        </div>
      ),
      width: "yesOrNo",
    },
    {
      header: "활동",
      render: (item: GoodsRequestItem, handler) => (
        <div className="flex w-full h-full justify-center items-center">
          <DefaultButton
            size="tableIcon"
            color="blue"
            onClick={() => {
              if (handler?.popup) {
                handler.popup({
                  header: "신청 물품 상세",
                  content: (
                    <GoodsRequestItemDetailView goodsId={item.goodsId} />
                  ),
                });
              }
            }}
          >
            <FiEdit2 />
          </DefaultButton>
        </div>
      ),
      width: "icon",
    },
  ];

  const componentList: ComponentType<GoodsRequestItem>[] = [
    {
      labelName: "등록일자",
      typeName: "dateRange",
      keyName: "createdAt",
      category: "date",
    },
    {
      labelName: "물품 종류",
      typeName: "comboBox",
      keyName: "goodsTypeId",
      comboBoxOptions: addDefaultOption(GOODS_REQUEST_OPTIONS),
      category: "typeSearch",
    },
    {
      labelName: "사용 유무",
      typeName: "comboBox",
      keyName: "isActive",
      comboBoxOptions: addDefaultOption(USAGE_OPTIONS),
      category: "typeSearch",
    },
    {
      labelName: "물품 번호",
      typeName: "text",
      keyName: "goodsId",
      category: "detailSearch",
      placeholder: "물품 번호를 입력해주세요",
    },
    {
      labelName: "물품명",
      typeName: "text",
      keyName: "goodsName",
      category: "detailSearch",
      placeholder: "물품명을 입력해주세요",
    },
    {
      labelName: "회사명",
      typeName: "text",
      keyName: "companyName",
      category: "detailSearch",
      placeholder: "회사명을 입력해주세요",
    },
  ];

  return (
    <ListPage
      getDataApi={getGoodsRequestItemList}
      keyId={(item: GoodsRequestItem) => item.goodsId}
      componentList={componentList}
      columnInfo={columnInfo}
      tableTitle="물품 목록"
      renderTopRight={<GoodsRequestItemListTopRight />}
      excelDownloadApi={getGoodsRequestItemListExcel}
      excelUpload={
        <ExcelFileUploaderButton
          title="신청 물품"
          postExcelDataApi={postGoodsRequestItemBulkExcel}
          getExcelDataApi={getGoodsRequestItemBulkExcelForm}
        />
      }
    />
  );
}
