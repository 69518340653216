import { useEffect } from "react";
import ListPage from "../../../../components/ListPage";
import { TableColumnType } from "../../../../components/Table";
import { useProgramId } from "../../../../hooks/useProgramId";
import { getGeoLocationInfoList, postAccessProgramId } from "../../../../services/geoLocationService";
import { GeoLocationInfo } from "../../../../types/geoLocation";
import { ComponentType } from "../../../../types/search";

type Props = {};

export default function GeoLocationList(props: Props) {
  const programId = useProgramId();
  
  const columnInfo: TableColumnType<GeoLocationInfo>[] = [
    { header: "이력 ID", name: "logId", width: "id"},
    { header: "회원 ID", name: "memberId", width: "id" },
    { header: "회원 이메일", name: "email"},
    { header: "취득경로", name: "reason", width: "200"},
    { header: "제공받는자", name: "recipient"},
    { header: "수집일시", name: "createdAt", width: "date"}
  ];

  const componentList: ComponentType<GeoLocationInfo>[] = [
    {
      labelName: "수집일자",
      typeName: "dateRange",
      keyName: "createdAt",
      category: "date",
    }
  ];

  useEffect(() => {
    if(programId){
      postAccessProgramId(programId);
    }
  }, [programId]);

  return (
    <ListPage
      getDataApi={getGeoLocationInfoList}
      keyId={(item: GeoLocationInfo) => item.logId}
      columnInfo={columnInfo}
      componentList={componentList}
      tableTitle="위치 정보 수집 목록"
    />
  )

}