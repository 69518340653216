import { HttpStatusCode } from "axios";
import { useContext, useState } from "react";
import { useModal } from "../../../../../contexts/Modal";
import { PopupContext } from "../../../../../contexts/Popup";
import { useUserContext } from "../../../../../contexts/User";
import {
  validationFunctions,
  validationResultMessage,
} from "../../../../../libs/validations";
import { postGoodsRequestItem } from "../../../../../services/goodsService";
import { TagSelectedItem } from "../../../../../types/building";
import { GOODS_REQUEST_OPTIONS } from "../../../../../types/comboBoxOption";
import { Company } from "../../../../../types/company";
import { GoodsRequestItem } from "../../../../../types/goods";
import DefaultButton from "../../../../DefaultButton";
import DefaultTextArea from "../../../../DefaultTextArea";
import SingleImageUploader from "../../../../FileUploader/ImageFileUploader/SingleImageFileUploader";
import FormHeader from "../../../../Form/Header";
import FormRow from "../../../../Form/Row";
import FormRowLabel from "../../../../Form/RowLabel";
import DefaultInput from "../../../../Input/DefaultInput";
import DefaultLabel from "../../../../Input/DefaultLabel";
import DefaultSelect from "../../../../SelectBox/DefaultSelect";
import TagSingleFilter from "../../../../TagFilter/TagSingleFilter";

const GOODS_REQUEST_ITEM_SERVICE_NAME = "사무용품 신청";

type Props = {};

export default function GoodsRequestItemCreateForm(props: Props) {
  const { userInfo, hasRole } = useUserContext();
  const { showAlert, showConfirm, handleError } = useModal();
  const { closePopup, refreshAndClosePopup } = useContext(PopupContext);

  const isAdmin = hasRole(["ROLE_ADMIN"]);

  const [selectedTag, setSelectedTag] = useState<TagSelectedItem>();
  const [goods, setGoods] = useState<Partial<GoodsRequestItem>>({
    ...(!isAdmin && { companyId: userInfo?.companyId }),
  });

  const handleGoodsChange =
    (key: keyof GoodsRequestItem) => (value: string | boolean | File) => {
      setGoods((prev) => ({ ...prev, [key]: value }));
    };

  const handlePost = () => {
    const validations = [
      validationFunctions.required(goods.goodsTypeId, "물품종류"),
      validationFunctions.required(goods.goodsName, "물품명"),
    ];

    if (isAdmin) {
      validations.unshift(
        validationFunctions.required(
          (selectedTag as Company)?.companyId,
          "회사"
        )
      );
    }

    const errorMessage = validationResultMessage(validations);
    if (errorMessage) {
      showAlert(errorMessage);
    } else {
      showConfirm("등록 하시겠습니까?", postData);
    }
  };

  const postData = async () => {
    try {
      const res = await postGoodsRequestItem(createFormData());
      if (res.status === HttpStatusCode.Ok) {
        showAlert("등록 되었습니다.");
        refreshAndClosePopup();
      }
    } catch (err: any) {
      handleError(err, "등록");
    }
  };

  const createFormData = () => {
    const formData = new FormData();

    const data: Partial<GoodsRequestItem> = {
      goodsTypeId: goods.goodsTypeId,
      goodsName: goods.goodsName,
      goodsDescription: goods.goodsDescription,
      companyId: goods.companyId,
    };

    goods.uploadFile && formData.append("uploadFile", goods.uploadFile);
    formData.append("params", JSON.stringify(data));

    return formData;
  };

  const renderSelectTag = () => {
    return (
      <FormRowLabel title="회사" isRequired={isAdmin}>
        {isAdmin ? (
          <TagSingleFilter
            selectType="company"
            selectedItem={selectedTag}
            onChangeCompany={(item: Company) => {
              handleGoodsChange("companyId")(item.companyId);
              setSelectedTag(item);
            }}
          />
        ) : (
          <DefaultLabel text={userInfo?.companyName} />
        )}
      </FormRowLabel>
    );
  };

  return (
    <div className="flex flex-col gap-5 min-w-[900px]">
      <div className="flex flex-col max-h-[700px] overflow-y-auto">
        <FormHeader title="기준 정보" />
        <FormRow>
          <FormRowLabel title="서비스">
            <DefaultLabel text={GOODS_REQUEST_ITEM_SERVICE_NAME} />
          </FormRowLabel>
        </FormRow>
        <FormRow>{renderSelectTag()}</FormRow>

        <FormHeader title="물품 정보" />
        <FormRow>
          <FormRowLabel title="물품 종류" isRequired>
            <DefaultSelect
              value={goods.goodsTypeId}
              onChange={handleGoodsChange("goodsTypeId")}
              optionList={GOODS_REQUEST_OPTIONS}
              placeholder="선택해주세요"
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="물품명" isRequired>
            <DefaultInput
              value={goods.goodsName}
              onChange={handleGoodsChange("goodsName")}
              maxLength={50}
              placeholder="물품명 입력"
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="대표 이미지">
            <SingleImageUploader
              image={goods.uploadFile}
              onChange={handleGoodsChange("uploadFile")}
              rightLabel="* 단건 등록"
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="물품 설명">
            <DefaultTextArea
              maxLength={500}
              rows={5}
              value={goods.goodsDescription}
              onChange={handleGoodsChange("goodsDescription")}
              placeholder="물품 설명을 입력해주세요"
            />
          </FormRowLabel>
        </FormRow>
      </div>

      <div className="flex justify-center gap-2">
        <DefaultButton onClick={closePopup}>닫기</DefaultButton>
        <DefaultButton color="primary" onClick={handlePost}>
          등록
        </DefaultButton>
      </div>
    </div>
  );
}
