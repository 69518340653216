import { TableColumnType } from "../../../../components/Table";
import { AirInfo } from "../../../../types/tag";

import { ComponentType } from "../../../../types/search";
import ListPage from "../../../../components/ListPage";
import {
  getTagStatusAir,
  getTagStatusAirExcel,
} from "../../../../services/tagService";
import { buildingGroupCategoryCodeValue } from "../../../../types/building";

type Props = {};

export default function Air(props: Props) {
  const columnInfo: TableColumnType<AirInfo>[] = [
    { header: "측정일시", name: "updatedAt", sortable: true, width: "date" },
    { header: "건물명", name: "buildingName", sortable: true },
    { header: "층명", name: "floorName" },
    { header: "공간명", name: "spaceName" },
    { header: "센서상태", name: "connectedStatus" },
    { header: "온도", name: "temperature", sortable: true },
    { header: "습도", name: "humidity", sortable: true },
    { header: "이산화탄소", name: "co2", sortable: true },
    { header: "화학물질", name: "tvoc", sortable: true },
    { header: "미세먼지", name: "dust", sortable: true },
    { header: "조도", name: "lux", sortable: true },
    { header: "소음", name: "dba", sortable: true },
    { header: "종합지수", name: "total", sortable: true },
  ];

  const componentList: ComponentType<AirInfo>[] = [
    {
      typeName: "buildingAndFloor",
      keyName: "buildingAndFloor",
      category: "typeSearch",
      buildingGroupCategoryCode: buildingGroupCategoryCodeValue.IOT_DEVICE,
    },
  ];

  return (
    <ListPage
      getDataApi={getTagStatusAir}
      excelDownloadApi={getTagStatusAirExcel}
      keyId={(item: AirInfo) => JSON.stringify(item)}
      columnInfo={columnInfo}
      componentList={componentList}
      tableTitle="공간별 공기질"
    />
  );
}
