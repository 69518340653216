import request, { ListResponse } from "../libs/request";

import { StoreCategory } from "../types/category";
import { Store } from "../types/store";

import { getExcelDownloadApi } from "./commonService";

import { SearchCondition } from "../components/ListPage";

const STORE_API_PREFIX = "/v2/admin/service/store";

export const getStoreList = (params: SearchCondition<Store>) =>
  request.get<ListResponse<Store>>(STORE_API_PREFIX, { params });

export const getStoreListExcel = (params: SearchCondition<Store>) =>
  getExcelDownloadApi(STORE_API_PREFIX + "/list/excel", params);

export const getStoreBulkExcelForm = () =>
  request.get(STORE_API_PREFIX + "/excel/upload-form", {
    responseType: "blob",
    params: {},
  });

export const postStoreBulkExcel = (data: FormData) =>
  request.post(STORE_API_PREFIX + "/list", data);

export const getStoreCategoryList = () =>
  request.get<StoreCategory[]>(STORE_API_PREFIX + "/category");

export const getStore = (storeId: string) =>
  request.get<Store>(STORE_API_PREFIX + `/${storeId}`);

export const postStore = (data: FormData) =>
  request.post(STORE_API_PREFIX, data);

export const patchStore = (storeId: string, data: FormData) =>
  request.patch(STORE_API_PREFIX + `/${storeId}`, data);
