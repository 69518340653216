import clsx from "clsx";
import React from "react";

type Props = {
  children: React.ReactNode;
  dataTestId?: string;
  className?: string;
};

export default function FormRow({ children, dataTestId, className }: Props) {
  return (
    <div
      className={clsx([
        "flex items-center h-full w-full py-2.5 px-5 border-b border-neutral-15",
        className,
      ])}
      data-testid={`${dataTestId}-form-row`}
    >
      {children}
    </div>
  );
}
