import { useContext } from "react";

import { PopupContext } from "@/contexts/Popup";

import DefaultButton from "@/components/DefaultButton";
import EvacueeListCreateForm from "../CreateForm";

type Props = {};
export default function EvacueeListTopRight(props: Props) {
  const { openPopup } = useContext(PopupContext);

  return (
    <>
      <DefaultButton
        size="tableTop"
        color="default"
        onClick={() =>
          openPopup({
            header: "인원 등록",
            content: <EvacueeListCreateForm />,
          })
        }
      >
        인원 등록
      </DefaultButton>
    </>
  );
}
