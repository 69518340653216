type DateTimeType = "date" | "datetime-local";

interface Props {
  type?: DateTimeType;
  date?: string;
  disabled?: boolean;
  min?: string;
  max?: string;
  onDateTimeChange?: (datetime: string) => void;
}

export default function DateTimeInput({
  type = "date",
  date = "",
  min = "",
  max = "",
  disabled = false,
  onDateTimeChange,
}: Props) {
  return (
    <input
      className="min-w-[200px] h-[38px] border border-gray-200 px-4 py-2 text-sm"
      type={type}
      value={date}
      min={min}
      max={max}
      onChange={(e) => {
        if (onDateTimeChange) onDateTimeChange(e.target.value);
      }}
      disabled={disabled}
    />
  );
}
