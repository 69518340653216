import DefaultButton from "@/components/DefaultButton";
import FormHeader from "@/components/Form/Header";
import FormRow from "@/components/Form/Row";
import { SearchCondition } from "@/components/ListPage";
import Pagination from "@/components/Pagination";
import Spinner from "@/components/Spinner";
import { Checkbox } from "@/components/ui/checkbox";
import { useModal } from "@/contexts/Modal";
import { PopupContext } from "@/contexts/Popup";
import { getBuildingCompanyListAll } from "@/services/buildingService";
import {
  getEmergencyBuildingGroupInfo,
  getShelterList,
  postBuildingShelterMapping,
} from "@/services/emergencyService";
import WarningCircleIcon from "@/svgs/icons/Common/WarningCircle";
import { BuildingCompany } from "@/types/building";
import {
  EmergencyBuilding,
  EmergencyBuildingGroup,
  Shelter,
} from "@/types/emergency";
import { Pageable } from "@/types/pageable";
import { formatSortSearchCondition } from "@/utils/formatUtils";
import { HttpStatusCode } from "axios";
import clsx from "clsx";
import React from "react";
import { useContext, useEffect, useState } from "react";
import { IoIosLink } from "react-icons/io";
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";

type Props = {
  evacuationGroupId: string;
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
};

enum shelterMappingStepEnum {
  first = "first",
  second = "second",
}

const defaultPageSize = 8;

type companyChecked = {
  buildingName: string;
  companyId: string;
  companyName: string;
};

const initPagination = {
  offset: 0,
  page: 1,
  pageSize: defaultPageSize,
  sort: "",
  total: 0,
  totalPages: 0,
};

export default function ShelterMapping({
  evacuationGroupId,
  setRefresh,
}: Props) {
  const { showAlert, showConfirm, handleError } = useModal();
  const { closePopup } = useContext(PopupContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [groupInfo, setGroupInfo] = useState<
    EmergencyBuildingGroup | undefined
  >(undefined);
  const [buildingList, setBuildingList] = useState<EmergencyBuilding[]>([]); // 리스트 표출용.
  const [companyList, setCompanyList] = useState<BuildingCompany[]>([]); // 리스트 표출용.
  const [companyAllList, setCompanyAllList] = useState<BuildingCompany[]>([]); // 회사 목록 전체 저장용
  const [shelterList, setShelterList] = useState<Shelter[]>([]);
  const [shelterMappingStep, setShelterMappingStep] = useState<string>(
    shelterMappingStepEnum.first
  );
  const [selectedBuilding, setSelectedBuilding] = useState<
    EmergencyBuilding | undefined
  >(undefined);
  const [buildingModified, setBuildingModified] = useState<boolean>(false);
  const [buildingCheckedList, setBuildingCheckedList] = useState<string[]>([]);
  const [companyCheckedMap, setCompanyCheckedMap] = useState<
    Record<string, companyChecked[]>
  >({}); // 빌딩ID + 회사ID 형태로 관리 해야 함.
  const [buildingPagination, setBuildingPagination] =
    useState<Pageable>(initPagination);
  const [companyPagination, setCompanyPagination] =
    useState<Pageable>(initPagination);
  const [shelterPagination, setShelterPagination] =
    useState<Pageable>(initPagination);
  const [shelterSearchCondition, setShelterSearchCondition] = useState<
    SearchCondition<Shelter>
  >({
    ...({
      page: 1,
      pageSize: defaultPageSize,
    } as SearchCondition<Shelter>),
  });

  const isBuildingAllChecked = () => {
    const unCheckedList = buildingList.filter(
      (newBuilding) =>
        !buildingCheckedList.some(
          (checkedBuilding) => checkedBuilding === newBuilding.buildingId
        )
    );

    if (unCheckedList.length > 0) {
      return false;
    } else {
      return true;
    }
  };

  const isCompanyAllChecked = () => {
    if (selectedBuilding) {
      if (companyCheckedMap[selectedBuilding.buildingId]) {
        const activeCompanyList = companyList.filter((company) =>
          isCompanyActive(company.companyId)
        );
        if (activeCompanyList.length > 0) {
          const unCheckedList = activeCompanyList.filter(
            (company) =>
              !companyCheckedMap[selectedBuilding.buildingId].some(
                (checkedCompany) =>
                  company.companyId === checkedCompany.companyId
              )
          );
          if (unCheckedList.length > 0) {
            return false;
          } else {
            return true;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const isStepOne = () => {
    return shelterMappingStep === shelterMappingStepEnum.first;
  };

  const isStepTwo = () => {
    return shelterMappingStep === shelterMappingStepEnum.second;
  };

  const fetchGroupInfo = async (evacuationGroupId: string) => {
    try {
      setIsLoading(true);
      const response = await getEmergencyBuildingGroupInfo(evacuationGroupId);
      if (response.status === HttpStatusCode.Ok) {
        setGroupInfo(response.data);
        initBuildingList(response.data);
      } else {
        showAlert("그룹 조회에 실패 했습니다.");
      }
    } catch (error: any) {
      handleError(error, "비상 대피 그룹 조회");
      setGroupInfo(undefined);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchShelterList = async () => {
    try {
      setIsLoading(true);
      const condition = formatSortSearchCondition(shelterSearchCondition);
      const response = await getShelterList(condition);
      if (response.status === HttpStatusCode.Ok) {
        if (response.data?.items) {
          setShelterList(response.data.items);
          setShelterPagination(response.data.pageable);
        }
      } else {
        showAlert("대피영역 목록 조회에 실패 했습니다.");
      }
    } catch (error: any) {
      handleError(error, "대피영역 목록 조회");
    } finally {
      setIsLoading(false);
    }
  };

  // 의도는 렌더링 후 처음 그룹을 가져왔을때 셋팅 기준. 이후 데이터 변경시와 구분하기 위해 useEffect 를 사용하지 않음
  const initBuildingList = (groupInfo: EmergencyBuildingGroup) => {
    if (groupInfo && groupInfo.buildingList) {
      // 빌딩 리스트 셋팅
      const page = buildingPagination.page ?? 1;
      const pageSize = buildingPagination.pageSize ?? defaultPageSize;
      const pageData = {
        page: page,
        pageSize: pageSize,
        total: groupInfo.buildingList.length,
        totalPages: Math.ceil(groupInfo.buildingList.length / pageSize),
      } as Pageable;

      setBuildingPagination(pageData);

      setBuildingList(
        groupInfo.buildingList.slice(
          buildingPagination.offset,
          buildingPagination.pageSize
        )
      );
    }
  };

  const initCompanyList = (companyList: BuildingCompany[]) => {
    if (companyList) {
      const pageSize = companyPagination.pageSize ?? defaultPageSize;
      const pageData = {
        page: 1,
        pageSize: pageSize,
        total: companyList.length,
        totalPages: Math.ceil(companyList.length / pageSize),
      } as Pageable;

      setCompanyPagination(pageData);

      setCompanyList(
        companyList.slice(companyPagination.offset, companyPagination.pageSize)
      );
    }
  };

  const handleCheckedBuildingAll = (buildingList: EmergencyBuilding[]) => {
    setBuildingCheckedList((prev) => {
      const prevData = [...prev];
      let newData = [];

      const needAddList = buildingList.filter(
        (newBuilding) =>
          !prevData.some(
            (checkedBuilding) => checkedBuilding === newBuilding.buildingId
          )
      );

      if (needAddList.length > 0) {
        newData = [
          ...prev,
          ...needAddList.map((building) => building.buildingId),
        ];
      } else {
        // 전체 해제
        const filteredData = prevData.filter(
          (checkedBuilding) =>
            !buildingList.some(
              (newBuilding) => newBuilding.buildingId === checkedBuilding
            )
        );

        newData = filteredData;
      }
      return newData;
    });
  };

  const pickProps = <T, K extends keyof T>(
    obj: T,
    propsToKeep: K[]
  ): Pick<T, K> => {
    return propsToKeep.reduce((acc, prop) => {
      acc[prop] = obj[prop];
      return acc;
    }, {} as Pick<T, K>);
  };

  const handleCheckedCompanyAll = (companyList: BuildingCompany[]) => {
    const activeCompanyList = companyList.filter((company) =>
      isCompanyActive(company.companyId)
    );
    if (activeCompanyList.length > 0) {
      const prevData = { ...companyCheckedMap };
      const prevCheckedList = prevData[activeCompanyList[0].buildingId];
      let newData: Record<string, companyChecked[]> = {};

      const remainKeys: (keyof BuildingCompany)[] = [
        "buildingName",
        "companyId",
        "companyName",
      ];

      if (prevCheckedList && prevCheckedList.length > 0) {
        const needAddList = activeCompanyList.filter(
          (newCompany) =>
            !prevCheckedList.some(
              (checkedCompany) =>
                checkedCompany.companyId === newCompany.companyId
            )
        );

        const addList = needAddList.map((buildingCompany) =>
          pickProps(buildingCompany, remainKeys)
        );

        if (needAddList.length > 0) {
          // 추가
          newData = {
            ...prevData,
            [activeCompanyList[0].buildingId]: [...prevCheckedList, ...addList],
          };
        } else {
          // 전체 해제
          const filteredList = prevCheckedList.filter(
            (checkedCompany) =>
              !activeCompanyList.some(
                (deleteCompany) =>
                  checkedCompany.companyId === deleteCompany.companyId
              )
          );

          newData = {
            ...prevData,
            [activeCompanyList[0].buildingId]: filteredList,
          };
        }
      } else {
        // 처음 추가되는 케이스. 즉 전체 추가
        const addList = activeCompanyList.map((buildingCompany) =>
          pickProps(buildingCompany, remainKeys)
        );
        newData = {
          ...prevData,
          [activeCompanyList[0].buildingId]: [...addList],
        };
      }

      setCompanyCheckedMap(newData);
    }
  };

  const handleCheckedChangeBuilding = (buildingId: string) => {
    // 체크된 대상만 리스트로 관리.
    setBuildingCheckedList((prev) => {
      const prevData = [...prev];
      let newData = [];

      const exist = prevData.some((building) => building === buildingId);

      if (exist) {
        // 제거
        newData = prevData.filter((building) => building !== buildingId);
      } else {
        // 추가
        newData = [...prev, buildingId];
      }

      return newData;
    });
  };

  const handleCheckedChangeCompany = (company: BuildingCompany) => {
    // 체크된 대상만 리스트로 관리.
    // 1. 비활성화인지 체크하기
    if (isCompanyActive(company.companyId)) {
      setCompanyCheckedMap((prev) => {
        const prevData = { ...prev };
        let newData: Record<string, companyChecked[]> = {};

        const buildingId = company.buildingId;
        const buildingName = company.buildingName;
        const companyId = company.companyId;
        const companyName = company.companyName;

        const checkedCompanyList = prevData[buildingId];
        if (checkedCompanyList) {
          // 추가 또는 삭제
          const exist = checkedCompanyList.some(
            (checkedCompany) => checkedCompany.companyId === companyId
          );
          let newCheckedCompany: companyChecked[] = [];
          if (exist) {
            newCheckedCompany = checkedCompanyList.filter(
              (checkedCompany) => checkedCompany.companyId !== companyId
            );
          } else {
            // 추가
            newCheckedCompany = [
              ...checkedCompanyList,
              {
                companyId: companyId,
                buildingName: buildingName,
                companyName: companyName,
              } as companyChecked,
            ];
          }

          newData = { ...prev, [buildingId]: newCheckedCompany };
        } else {
          // 빌딩 자체가 처음 -> 항목 + 컴퍼니 바로 추가
          newData = {
            ...prev,
            [buildingId]: [
              {
                companyId: companyId,
                buildingName: buildingName,
                companyName: companyName,
              } as companyChecked,
            ],
          };
        }

        return newData;
      });
    }
  };

  const handleClickShelterCommit = (shelter: Shelter) => {
    if (isStepOne()) {
      // 빌딩 체크 및 적용
      if (buildingCheckedList.length > 0) {
        // 적용을 한번이라도 했는지 체크해 두기.
        setBuildingModified(true);
        setGroupInfo((prev) => {
          if (prev?.buildingList && prev?.buildingList.length > 0) {
            const prevBuildingList = [...prev?.buildingList];
            let newData: EmergencyBuildingGroup;

            prevBuildingList.forEach((building) => {
              if (
                buildingCheckedList.some(
                  (checkedBuildingId) =>
                    checkedBuildingId === building.buildingId
                )
              ) {
                // 체크 된 케이스
                building.defaultShelterId = shelter.shelterId;
                building.defaultShelterName = shelter.shelterName;
              }
            });

            newData = { ...prev, buildingList: prevBuildingList };

            return newData;
          }
        });
        // 체크 전체 해제 필요
        setBuildingCheckedList([]);
      } else {
        showAlert("적용 대상을 선택 해주세요.");
      }
    } else {
      const existCompanyMappingData = Object.values(companyCheckedMap).some(
        (list) => list.length > 0
      );
      if (existCompanyMappingData) {
        showConfirm(
          companyShelterMappingMessage(shelter),
          () => postCompanyShelterMap(shelter),
          undefined,
          undefined,
          "회사 대피영역 선택 현황"
        );
      } else {
        showAlert("적용 대상 회사를 선택 해주세요.");
      }
    }
  };

  const postCompanyShelterMap = async (shelter: Shelter) => {
    const postData = companyShelterMapping(shelter);
    setGroupInfo(postData);
    await postBuildingShelterMappingData(
      postData.buildingList as EmergencyBuilding[]
    );
    initCheck();
  };

  const companyShelterMapping = (shelter: Shelter): EmergencyBuildingGroup => {
    // 실질적으로 groupInfo 데이터를 수정하는 단계
    const newGroupInfo = { ...groupInfo };

    const preBuildingList = newGroupInfo.buildingList;

    preBuildingList?.forEach((building) => {
      const checkedCompanyList = companyCheckedMap[building.buildingId];
      const dataExist = checkedCompanyList && checkedCompanyList.length > 0;
      if (dataExist) {
        checkedCompanyList.forEach((checkedCompany) => {
          const preCheckedList = building.companyShelterMapDtoList;
          // 1) 있었는지 체크
          const destination = preCheckedList.findIndex(
            (preChecked) => preChecked.companyId === checkedCompany.companyId
          );
          if (destination !== -1) {
            // 수정
            preCheckedList[destination] = {
              ...preCheckedList[destination],
              shelterId: shelter.shelterId,
              shelterName: shelter.shelterName,
            };
          } else {
            // 추가
            preCheckedList.push({
              companyId: checkedCompany.companyId,
              companyName: checkedCompany.companyName,
              shelterId: shelter.shelterId,
              shelterName: shelter.shelterName,
              companyIsActive: true,
            });
          }
        });
      }
    });

    return newGroupInfo as EmergencyBuildingGroup;
  };

  const companyShelterMappingMessage = (shelter: Shelter) => {
    const checkedBuildingList = Object.keys(companyCheckedMap);
    return (
      <React.Fragment>
        <div className="w-[400px] h-[400px] border border-gray-300 overflow-y-auto">
          <table className="w-full">
            <thead>
              <tr className="border-b border-gray-300">
                <td className="w-1/2 py-2 px-3">
                  <div className="w-full flex items-center justify-center text-center text-gray-500">
                    건물/회사
                  </div>
                </td>
                <td className="w-1/2 py-2 px-3">
                  <div className="w-full flex items-center justify-center text-center text-gray-500">
                    대피영역
                  </div>
                </td>
              </tr>
            </thead>
            <tbody>
              {checkedBuildingList.map((buildingId, index) =>
                companyCheckedMap[buildingId].map((company, subIndex) => (
                  <tr
                    key={`${index}-${subIndex}`}
                    className="border-b border-gray-300"
                  >
                    <td className="w-1/2 py-2 px-3">
                      <div className="w-full flex items-center justify-center text-center font-semibold">
                        {company.buildingName + " / " + company.companyName}
                      </div>
                    </td>
                    <td className="w-1/2 py-2 px-3">
                      <div className="w-full flex items-center justify-center text-center font-semibold">
                        {shelter.shelterName}
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        <div>위 같이 적용됩니다. 적용 하시겠습니까?</div>
      </React.Fragment>
    );
  };

  const isAllBuildingMapped = () => {
    if (groupInfo && groupInfo?.buildingList) {
      // mapping 되지 않은 데이터가 하나라도 있는지 체크
      const isDataUnmapped = groupInfo.buildingList.some(
        (building) => !building.defaultShelterId
      );
      return !isDataUnmapped;
    } else {
      return false;
    }
  };

  const handleClickSaveAndNext = () => {
    // 1. step 체크 필요.
    if (isStepOne()) {
      // 1-1) 모든 대피영역이 지정 되었는지 체크, 안되어있으면 경고
      const isAllMapped = isAllBuildingMapped();

      if (isAllMapped && groupInfo?.buildingList) {
        // 수정을 한 경우에만 저장을 진행한다. 첫번째 스텝이면서 모든 건물이 맵핑되어있는데, 적용을 누르지 않은 경우엔 바로 다음 단계 진입 하도록 한다.
        if (buildingModified) {
          // 1-2) save and next
          // let postData: EmergencyBuilding[];

          const postData = groupInfo?.buildingList?.map((building) => {
            const data = {
              buildingId: building.buildingId,
              defaultShelterId: building.defaultShelterId,
              companyShelterMapDtoList: building.companyShelterMapDtoList,
            } as EmergencyBuilding;
            return data;
          });
          showConfirm("저장 하시겠습니까?", async () => {
            setBuildingModified(false);
            postBuildingShelterMappingData(postData);
          });
        } else {
          setShelterMappingStep(shelterMappingStepEnum.second);
        }
      } else {
        showAlert(
          "대피영역이 지정되지 않은 건물이 있습니다. 모두 지정 해주세요."
        );
      }
    } else {
      closePopup();
    }
    // 체크 상태 전체 초기화
    initCheck();
  };

  const initCheck = () => {
    setBuildingCheckedList([]);
    setCompanyCheckedMap({});
  };

  const postBuildingShelterMappingData = async (
    postData: EmergencyBuilding[]
  ) => {
    // 이론상 이전 로직으로 인해 validation 은 모두 끝난 상태
    try {
      const response = await postBuildingShelterMapping(
        evacuationGroupId,
        postData
      );
      if (response.status === HttpStatusCode.Ok) {
        showAlert("저장이 완료되었습니다.");
        setRefresh(true);
        setShelterMappingStep(shelterMappingStepEnum.second);
      } else {
        showAlert("저장에 실패했습니다.");
      }
    } catch (err: any) {
      const error = err.response?.data?.error;

      if (error) {
        showAlert(err.response?.data?.error);
      } else {
        handleError(err, "대피영역 지정");
      }
    }
  };

  const handleClickBuildingRow = async (building: EmergencyBuilding) => {
    // 기존에 체크된게 내가 아니면 발동
    // 컴퍼니 리스트 가져오기
    if (
      selectedBuilding === undefined ||
      selectedBuilding.buildingId !== building.buildingId
    ) {
      setIsLoading(true);
      setSelectedBuilding(building);
      setCompanyList([]);
      try {
        const params = {
          buildingId: building.buildingId,
        } as SearchCondition<BuildingCompany>;
        const response = await getBuildingCompanyListAll(params);
        if (response.status === HttpStatusCode.Ok) {
          setCompanyAllList(response.data);
          initCompanyList(response.data);
        } else {
          showAlert("회사 목록 조회에 실패 하였습니다.");
        }
      } catch (err: any) {
        const error = err.response?.data?.error;

        if (error) {
          showAlert(err.response?.data?.error);
        } else {
          handleError(err, "대피영역 지정 - 회사 목록");
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  const getCompanyShelterName = (companyId: string) => {
    // 기본 대피영역을 보여줄건지, 지정 대피영역을 보여줄건지 체크해서 리턴
    if (groupInfo?.buildingList) {
      const buildingInfo = groupInfo?.buildingList.find(
        (building) => building.buildingId === selectedBuilding?.buildingId
      );

      if (buildingInfo && buildingInfo.companyShelterMapDtoList.length > 0) {
        const mapData = buildingInfo.companyShelterMapDtoList.find(
          (companyMap) => companyMap.companyId === companyId
        );
        return mapData && mapData.shelterName
          ? mapData.shelterName
          : selectedBuilding?.defaultShelterName;
      } else {
        return selectedBuilding?.defaultShelterName;
      }
    } else {
      return selectedBuilding?.defaultShelterName;
    }
  };

  const isCompanyChecked = (company: BuildingCompany) => {
    const companyCheckedList = companyCheckedMap[company.buildingId];
    if (companyCheckedList) {
      return companyCheckedList.some(
        (checkedCompany) => checkedCompany.companyId === company.companyId
      );
    } else {
      return false;
    }
  };

  const isCompanyActive = (companyId: string) => {
    if (groupInfo?.buildingList) {
      const buildingInfo = groupInfo?.buildingList.find(
        (building) => building.buildingId === selectedBuilding?.buildingId
      );
      if (buildingInfo && buildingInfo.companyShelterMapDtoList.length > 0) {
        const mapData = buildingInfo.companyShelterMapDtoList.find(
          (companyMap) => companyMap.companyId === companyId
        );
        return mapData ? mapData.companyIsActive : true;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  const closeShelterMapping = async () => {
    closePopup();
  };

  const handleClickPrevButton = () => {
    if (isStepOne()) {
      // 스텝1이면 대피영역 상세를 렌더링 해주고 해당 팝업은 close.
      // -> 24.08.22 스텝1에서 이전으로 가는 룰이 없어짐.(기획서가 잘못 되어 있었음)
      //    따라서 닫기 버튼으로 바뀌고, 경고 후 확인하면 닫는 것으로 변경
      if (buildingModified) {
        showConfirm(
          "저장하지 않은 내용은 모두 사라집니다. 닫으시겠습니까?",
          () => closeShelterMapping()
        );
      } else {
        closePopup();
      }
    } else {
      // 스텝2면 step 을 1로 바꿔주고, 컴퍼니 체크했던거 리셋, 컴퍼니 페이지네이션 리셋, 빌딩 선택값 리셋
      setShelterMappingStep(shelterMappingStepEnum.first);
      initCheck();
      setSelectedBuilding(undefined);
      setCompanyList([]);
      setCompanyPagination(initPagination);
    }
  };

  const handleClickDisableOrEnable = async (
    company: BuildingCompany,
    bool: boolean
  ) => {
    showConfirm("활성화 상태를 변경 하시겠습니까?", () =>
      changeCompanyActive(company, bool)
    );
  };

  const changeCompanyActive = async (
    company: BuildingCompany,
    bool: boolean
  ) => {
    const postData = companyActiveSetting(company, bool);
    setGroupInfo(postData);
    await postBuildingShelterMappingData(
      postData.buildingList as EmergencyBuilding[]
    );
  };

  const companyActiveSetting = (company: BuildingCompany, bool: boolean) => {
    const newGroupInfo: EmergencyBuildingGroup = { ...groupInfo! };
    const preBuildingList = newGroupInfo.buildingList;
    const destinationBuilding = preBuildingList?.find(
      (building) => building.buildingId === company.buildingId
    );
    const companyList = destinationBuilding?.companyShelterMapDtoList;
    const destinationCompanyIndex = companyList?.findIndex(
      (preCompany) => preCompany.companyId === company.companyId
    );

    if (
      companyList &&
      destinationCompanyIndex !== -1 &&
      destinationCompanyIndex !== undefined
    ) {
      companyList[destinationCompanyIndex] = {
        ...companyList[destinationCompanyIndex],
        companyIsActive: bool,
      };
    } else {
      companyList?.push({
        companyId: company.companyId,
        companyName: company.companyName,
        companyIsActive: bool,
      });
    }

    return newGroupInfo;
  };

  useEffect(() => {
    const startIndex =
      (buildingPagination.page - 1) * buildingPagination.pageSize;
    const endIndex = startIndex + buildingPagination.pageSize;

    if (groupInfo && groupInfo.buildingList) {
      setBuildingList(groupInfo.buildingList.slice(startIndex, endIndex));
    }
  }, [buildingPagination.page]);

  useEffect(() => {
    const startIndex =
      (companyPagination.page - 1) * companyPagination.pageSize;
    const endIndex = startIndex + companyPagination.pageSize;

    if (companyList.length > 0) {
      setCompanyList(companyAllList.slice(startIndex, endIndex));
    }
  }, [companyPagination.page]);

  useEffect(() => {
    if (evacuationGroupId) {
      fetchGroupInfo(evacuationGroupId);
    }
  }, [evacuationGroupId]);

  useEffect(() => {
    fetchShelterList();
  }, [shelterSearchCondition]);

  const renderBuildingHeader = (buildingList: EmergencyBuilding[]) => {
    return (
      <tr className="border-b border-gray-300">
        {isStepOne() && (
          <th className="w-[40px] h-[25px]">
            <div className="flex w-full h-full justify-center items-center">
              <Checkbox
                className="w-[21px] h-[21px]"
                checked={isBuildingAllChecked()}
                onCheckedChange={() => handleCheckedBuildingAll(buildingList)}
              />
            </div>
          </th>
        )}
        <th className="w-[180px] py-2 px-3">
          <p className="w-full flex items-center justify-center text-center text-gray-500">
            건물
          </p>
        </th>
        <th className="w-[120px] py-2 px-3">
          <span className="w-full flex items-center justify-center text-center text-gray-500">
            기본 대피영역
          </span>
        </th>
        {isStepTwo() && <th className="w-[40px] p-2"></th>}
      </tr>
    );
  };

  const renderBuildingRow = (building: EmergencyBuilding, index: number) => {
    return (
      <tr
        key={index}
        className={clsx(
          "w-full border-b border-gray-300",
          isStepTwo() && "cursor-pointer hover:bg-gray-100",
          selectedBuilding?.buildingId === building.buildingId && "bg-gray-200"
        )}
        onClick={
          isStepTwo() ? () => handleClickBuildingRow(building) : () => {}
        }
      >
        {isStepOne() && (
          <td className="border-r w-[40px] h-[25px] border-gray-300">
            <div className="flex w-full h-full justify-center items-center">
              <Checkbox
                className="w-[21px] h-[21px]"
                checked={buildingCheckedList.some(
                  (checked) => checked === building.buildingId
                )}
                onCheckedChange={() =>
                  handleCheckedChangeBuilding(building.buildingId)
                }
              />
            </div>
          </td>
        )}
        <td className="w-[180px] py-2 px-3">
          <p className="w-full font-semibold flex items-center justify-center text-center">
            {building.buildingName}
          </p>
        </td>
        <td className="w-[120px] py-2 px-3 break-words">
          <div>
            {building.defaultShelterName ? (
              <span className="text-brand-primary-green w-full flex align-center justify-center text-center">
                {building.defaultShelterName}
              </span>
            ) : (
              <span className="text-red-500 font-semibold w-full flex align-center justify-center text-center">
                등록 미완료
              </span>
            )}
          </div>
        </td>
        {isStepTwo() && (
          <td className="w-[40px] p-2">
            <div
              className={clsx(
                "flex items-center justify-center transform transition-transform duration-300",
                {
                  "rotate-0":
                    selectedBuilding?.buildingId === building.buildingId,
                  "rotate-180": !(
                    selectedBuilding?.buildingId === building.buildingId
                  ),
                }
              )}
            >
              <MdOutlineKeyboardDoubleArrowRight className="w-[25px] h-[25px]" />
            </div>
          </td>
        )}
      </tr>
    );
  };

  const renderCompanyHeader = (companyList: BuildingCompany[]) => {
    return (
      <tr className="border-b border-gray-300">
        <th className="w-[40px] h-[25px]">
          <div className="flex w-full h-full justify-center items-center">
            <Checkbox
              className="w-[21px] h-[21px]"
              checked={isCompanyAllChecked()}
              disabled={
                companyList.filter((company) =>
                  isCompanyActive(company.companyId)
                ).length === 0
              }
              onCheckedChange={() => handleCheckedCompanyAll(companyList)}
            />
          </div>
        </th>
        <th className="w-[160px] py-2 px-3">
          <p className="w-full flex items-center justify-center text-center text-gray-500">
            회사
          </p>
        </th>
        <th className="w-[120px] py-2 px-3">
          <span className="w-full flex items-center justify-center text-center text-gray-500">
            대피영역
          </span>
        </th>
        <th className="w-[90px]"></th>
      </tr>
    );
  };

  const renderCompanyRow = (company: BuildingCompany, index: number) => {
    return (
      <tr key={index} className="w-full border-b border-gray-300">
        <td className="border-r w-[40px] h-[25px] border-gray-300">
          <div className="flex w-full h-full justify-center items-center">
            <Checkbox
              className="w-[21px] h-[21px]"
              disabled={!isCompanyActive(company.companyId)}
              checked={isCompanyChecked(company)}
              onCheckedChange={() => handleCheckedChangeCompany(company)}
            />
          </div>
        </td>
        <td className="w-[160px] py-2 px-3">
          <p className="w-full font-semibold flex items-center justify-center text-center">
            {company.companyName}
          </p>
        </td>
        <td className="w-[120px] p-2 break-words">
          <div>
            {isCompanyActive(company.companyId) ? (
              <span className="text-brand-primary-green w-full flex align-center justify-center text-center">
                {getCompanyShelterName(company.companyId)}
              </span>
            ) : (
              <span className="text-gray-500 font-semibold w-full flex align-center justify-center text-center">
                비활성화
              </span>
            )}
          </div>
        </td>
        <td className="w-[90px]">
          <div className="flex justify-center items-center">
            <DefaultButton
              className="w-[80px] h-8 px-4 text-sm font-medium"
              size="none"
              color={isCompanyActive(company.companyId) ? undefined : "brown"}
              onClick={
                isCompanyActive(company.companyId)
                  ? () => handleClickDisableOrEnable(company, false)
                  : () => handleClickDisableOrEnable(company, true)
              }
            >
              {isCompanyActive(company.companyId) ? "비활성화" : "활성화"}
            </DefaultButton>
          </div>
        </td>
      </tr>
    );
  };

  const renderShelterRow = (shelter: Shelter, index: number) => {
    return (
      <tr key={index} className="w-full border-b border-gray-300">
        <td className="w-[120px] py-2 px-3">
          <p className="w-full font-semibold flex items-center justify-center text-center">
            {shelter.shelterName}
          </p>
        </td>
        <td className="w-[80px]">
          <div className="flex justify-center items-center">
            <DefaultButton onClick={() => handleClickShelterCommit(shelter)}>
              적용
            </DefaultButton>
          </div>
        </td>
      </tr>
    );
  };

  const getMappingTitle = () => {
    if (isStepOne()) {
      return "기본 대피영역 적용";
    } else if (isStepTwo()) {
      return (
        <div className="flex flex-row items-center">
          <div>회사 대피영역 변경</div>
          <div className="text-[#D95050] text-sm ml-4 font-normal">
            *기본 대피영역이 지정된 상태입니다. 회사별로 대피영역 지정을 원하실
            경우 변경해주세요.
          </div>
        </div>
      );
    }
  };

  return (
    <div className="flex flex-col gap-3">
      {isLoading && <Spinner />}
      <FormHeader title={groupInfo?.evacuationGroupName ?? "-"} />
      <div className="px-5 text-xl font-semibold font-semibold flex items-center flex-row flex-wrap">
        {getMappingTitle()}
      </div>
      <FormRow>
        <div data-testid="shelter-mapping-box" className="flex flex-row">
          <div
            data-testid="building-list-box"
            className="flex flex-col border border-gray-300 w-[360px]"
          >
            <div className="text-md flex align-center justify-center p-2.5">
              건물 기본 대피영역 선택
            </div>
            <div className="flex flex-col min-h-[350px] max-h-[350px] overflow-y-auto overflow-x-hidden">
              {buildingList.length > 0 ? (
                <table className="w-full table-fixed">
                  <thead>{renderBuildingHeader(buildingList)}</thead>
                  <tbody>
                    {buildingList.map((building, index) =>
                      renderBuildingRow(building, index)
                    )}
                  </tbody>
                </table>
              ) : (
                <div className="w-full h-full flex flex-col justify-center items-center">
                  <WarningCircleIcon />
                  건물 정보가 없습니다.
                </div>
              )}
            </div>
            <Pagination
              totalPages={buildingPagination.totalPages}
              currentPage={buildingPagination.page}
              onPageChange={(page) =>
                setBuildingPagination((prev) => ({ ...prev, page }))
              }
              pageLimit={3}
            />
          </div>

          {isStepTwo() && (
            <div
              data-testid="company-list-box"
              className="flex flex-col border border-gray-300 w-[430px] ml-2"
            >
              <div className="text-md flex align-center justify-center p-2.5">
                회사 대피영역 선택
              </div>
              <div className="flex flex-col min-h-[350px] max-h-[350px] overflow-y-auto overflow-x-hidden">
                {companyList.length > 0 ? (
                  <table className="w-full table-fixed">
                    <thead>{renderCompanyHeader(companyList)}</thead>
                    <tbody>
                      {companyList.map((company, index) =>
                        renderCompanyRow(company, index)
                      )}
                    </tbody>
                  </table>
                ) : (
                  <div className="w-full h-full flex flex-col justify-center items-center">
                    <WarningCircleIcon />
                    회사 정보가 없습니다.
                  </div>
                )}
              </div>
              <Pagination
                totalPages={companyPagination.totalPages}
                currentPage={companyPagination.page}
                onPageChange={(page) =>
                  setCompanyPagination((prev) => ({ ...prev, page }))
                }
                pageLimit={3}
              />
            </div>
          )}

          <div className="flex flex-wrap justify-center items-center p-4">
            <IoIosLink className="min-w-[24px] min-h-[24px]" />
          </div>

          <div
            data-testid="shelter-list-box"
            className="flex flex-col border border-gray-300 w-[330px]"
          >
            <div className="text-md border-b border-gray-300 flex align-center justify-center p-2.5">
              대피영역 지정
            </div>
            <div className="flex flex-col min-h-[350px] max-h-[350px] overflow-y-auto overflow-x-hidden">
              {shelterList.length > 0 ? (
                <table className="w-full">
                  <tbody>
                    {shelterList.map((shelter, index) =>
                      renderShelterRow(shelter, index)
                    )}
                  </tbody>
                </table>
              ) : (
                <div className="w-full h-full flex flex-col justify-center items-center">
                  <WarningCircleIcon />
                  대피영역 정보가 없습니다.
                </div>
              )}
            </div>
            <Pagination
              totalPages={shelterPagination.totalPages}
              currentPage={shelterPagination.page}
              onPageChange={(page) =>
                setShelterSearchCondition((prev) => ({ ...prev, page }))
              }
              pageLimit={3}
            />
          </div>
        </div>
      </FormRow>
      <div className="flex gap-2 justify-center">
        <DefaultButton onClick={() => handleClickPrevButton()}>
          {isStepOne() ? "닫기" : "이전"}
        </DefaultButton>
        <DefaultButton
          color="primary"
          onClick={() => handleClickSaveAndNext()}
          testId="save-and-next"
        >
          {isStepOne() ? "다음" : "확인"}
        </DefaultButton>
      </div>
    </div>
  );
}
