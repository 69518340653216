import {
  CategoryData,
  CategoryReturnField,
  CategoryStoreData,
} from "../types/category";
import { ComboBoxType } from "../types/search";

export const transformCategoryData = <T, U extends CategoryData>(
  data: T[],
  idKey: keyof T | undefined,
  aliasKey: keyof T | undefined,
  nameKey: keyof T | undefined,
  subListKey: keyof T | undefined,
  storeCategoryIdKey: keyof T | undefined,
  storeListKey: keyof T | undefined
): U[] => {
  return data.map((item) => {
    const subList = subListKey ? (item[subListKey] as T[]) : [];
    return {
      id: idKey ? (item[idKey] as number) : "",
      alias: aliasKey ? (item[aliasKey] as string) : "",
      name: nameKey ? (item[nameKey] as string) : "",
      subList: subList
        ? transformCategoryData(
            subList,
            idKey,
            aliasKey,
            nameKey,
            subListKey,
            storeCategoryIdKey,
            storeListKey
          )
        : [],
      storeCategoryId: storeCategoryIdKey
        ? (item[storeCategoryIdKey] as string)
        : "",
      storeList: storeListKey
        ? (item[storeListKey] as CategoryStoreData[])
        : [],
    } as U;
  });
};

export const findCategoryValueById = (
  id: number,
  categoryDataArray: CategoryData[],
  field: CategoryReturnField
): number | string | CategoryData[] | CategoryStoreData[] | undefined => {
  for (const category of categoryDataArray) {
    if (category.id === id) {
      if (field === CategoryReturnField.ALIAS) {
        return category.alias;
      } else if (field === CategoryReturnField.ID) {
        return category.id;
      } else if (field === CategoryReturnField.SUB_LIST) {
        return category.subList;
      } else if (field === CategoryReturnField.STORE_LIST) {
        return category.storeList;
      } else if (field === CategoryReturnField.NAME) {
        return category.name;
      }
    }

    if (category.subList) {
      const result = findCategoryValueById(id, category.subList, field);
      if (result !== undefined) {
        return result;
      }
    }
  }

  return undefined;
};

export const findCategoryIdByAlias = (
  alias: string,
  categoryDataArray: CategoryData[]
): number | undefined => {
  for (const category of categoryDataArray) {
    if (category.alias === alias) {
      return category.id;
    }

    if (category.subList) {
      const result = findCategoryIdByAlias(alias, category.subList);
      if (result !== undefined) {
        return result;
      }
    }
  }

  return undefined;
};

export const removeCategoryItemById = (
  items: CategoryData[],
  removeId: number
): CategoryData[] => {
  let result: CategoryData[] = [];

  for (let i = 0; i < items.length; i++) {
    if (items[i].id !== removeId) {
      if (items[i].subList.length > 0) {
        items[i].subList = removeCategoryItemById(items[i].subList, removeId);
      }
      result.push(items[i]);
    }
  }

  return result;
};

export const transformComboBox = (
  obj: Record<string, string>
): ComboBoxType[] => {
  return Object.entries(obj).map(([value, label]) => ({ value, label }));
};
