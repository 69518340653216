import { useContext, useState } from "react";
import { ServiceGroup, ServiceGroupDto } from "../../../../../types/service";
import FormHeader from "../../../../Form/Header";
import FormRow from "../../../../Form/Row";
import FormRowLabel from "../../../../Form/RowLabel";
import DefaultInput from "../../../../Input/DefaultInput";
import DefaultLabel from "../../../../Input/DefaultLabel";
import DefaultSelect from "../../../../SelectBox/DefaultSelect";
import { ComboBoxType } from "../../../../../types/search";
import BuildingMultiSelectTable from "../../../../SelectTable/MultiSelectTable/BuildingMultiSelectTable";
import { Building } from "../../../../../types/building";
import DefaultButton from "../../../../DefaultButton";
import { useModal } from "../../../../../contexts/Modal";
import {
  validationFunctions,
  validationResultMessage,
} from "../../../../../libs/validations";
import {
  getServiceGroupExists,
  postServiceGroup,
} from "../../../../../services/ServiceGroupService";
import { HttpStatusCode } from "axios";
import { PopupContext } from "../../../../../contexts/Popup";
import { getToday } from "../../../../../utils/dateUtils";
import Spinner from "../../../../Spinner";

type Props = { category: ComboBoxType[] };

export default function ServiceGroupCreateForm({ category }: Props) {
  const { showAlert, showConfirm, handleError } = useModal();
  const { refreshAndClosePopup, closePopup } = useContext(PopupContext);
  const [serviceGroup, setServiceGroup] = useState<Partial<ServiceGroupDto>>(
    {}
  );
  const [selectedBuilding, setSelectedBuilding] = useState<Building[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (key: keyof ServiceGroup) => (value: string) => {
    setServiceGroup((prev) => ({ ...prev, [key]: value }));
  };

  const handlePost = async () => {
    const items = selectedBuilding.map((item) => item.buildingId);
    const errorMessage = validationResultMessage([
      validationFunctions.required(serviceGroup.categoryCode, "카테고리"),
      validationFunctions.required(serviceGroup.groupName, "서비스그룹 이름"),
      validationFunctions.required(items, "건물 목록"),
    ]);

    if (errorMessage) {
      showAlert(errorMessage);
    } else {
      const isExists = await checkGroupExists(serviceGroup.groupName ?? "");
      if (isExists) {
        showAlert("서비스 그룹 중복입니다.");
      } else {
        showConfirm("등록 하시겠습니까?", () => postData(items));
      }
    }
  };

  const checkGroupExists = async (groupName: string) => {
    try {
      setIsLoading(true);
      const response = await getServiceGroupExists(groupName);

      if (response.status === HttpStatusCode.Ok) {
        return response.data;
      } else {
        throw new Error("Failed to valid serviceGroupName");
      }
    } catch (err: any) {
      handleError(err, "서비스 그룹 중복 체크");
    } finally {
      setIsLoading(false);
    }
    return false;
  };

  const postData = async (items: string[]) => {
    try {
      const response = await postServiceGroup({ ...serviceGroup, items });
      if (response.status === HttpStatusCode.Ok) {
        showAlert("등록 되었습니다.");
        refreshAndClosePopup();
      }
    } catch (err: any) {
      handleError(err, "등록");
    }
  };

  return (
    <div className="flex flex-col gap-5">
      {isLoading && <Spinner />}
      <div className="min-w-[900px] max-h-[700px] overflow-y-auto">
        <FormHeader title="기본 정보" />
        <FormRow>
          <FormRowLabel title="서비스 카테고리 선택">
            <DefaultSelect
              value={serviceGroup.categoryCode}
              onChange={handleChange("categoryCode")}
              optionList={category}
              placeholder="신청 서비스 선택"
              placeholderDisabled
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="서비스그룹 ID">
            <DefaultLabel text="자동으로 생성됩니다." />
          </FormRowLabel>
          <FormRowLabel title="생성일">
            <DefaultLabel text={getToday()} />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="서비스그룹 이름">
            <DefaultInput
              value={serviceGroup.groupName}
              onChange={handleChange("groupName")}
              placeholder="서비스그룹 이름을 입력해주세요"
            />
          </FormRowLabel>
          <FormRowLabel title="최종 변경일">
            <DefaultLabel />
          </FormRowLabel>
        </FormRow>

        <FormHeader title="연결건물 목록" />
        <FormRow>
          <BuildingMultiSelectTable
            selectedData={selectedBuilding}
            setSelectedData={setSelectedBuilding}
          />
        </FormRow>
      </div>

      <div className="flex gap-2 justify-center">
        <DefaultButton onClick={closePopup}>닫기</DefaultButton>
        <DefaultButton color="primary" onClick={handlePost}>
          등록
        </DefaultButton>
      </div>
    </div>
  );
}
