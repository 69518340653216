import { HttpStatusCode } from "axios";
import { useContext, useEffect, useState } from "react";
import { useModal } from "../../../../contexts/Modal";
import { PopupContext } from "../../../../contexts/Popup";
import {
  validationFunctions,
  validationResultMessage,
} from "../../../../libs/validations";
import {
  deleteEtcTerms,
  getEtcTerms,
  putEtcTerms,
} from "../../../../services/etcService";
import { EtcTerms, TermsGrade } from "../../../../types/etc";
import { toEnumValueOrDefault } from "../../../../utils/comboBoxUtils";
import {
  USAGE_OPTIONS,
  BOOLEAN_TYPES,
  TERMS_GRADE_OPTIONS,
  TERMS_GRADE_TYPES,
} from "../../../../types/comboBoxOption";
import { formatDate } from "../../../../utils/dateUtils";
import DateTimePicker from "../../../DateTime/DateTimePicker";
import DefaultButton from "../../../DefaultButton";
import DefaultTextArea from "../../../DefaultTextArea";
import FormRow from "../../../Form/Row";
import FormRowLabel from "../../../Form/RowLabel";
import DefaultInput from "../../../Input/DefaultInput";
import DefaultLabel from "../../../Input/DefaultLabel";
import RadioButtonGroup from "../../../Input/RadioButtonGroup";
import Spinner from "../../../Spinner";
import {
  TERMS_VERSION_REGEX,
  TERMS_VERSION_REGEX_COMMENT,
} from "../CreateForm";

type Props = { termsId: number };

export default function TermsDetailView({ termsId }: Props) {
  const { showAlert, showConfirm, handleError } = useModal();
  const { closePopup, refreshAndClosePopup } = useContext(PopupContext);
  const [terms, setTerms] = useState<Partial<EtcTerms>>({ isUse: false });
  const [date, setDate] = useState<Date>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        const res = await getEtcTerms(termsId);
        if (res.status === HttpStatusCode.Ok) {
          setTerms({
            ...res.data,
            grade: toEnumValueOrDefault(
              res.data.grade,
              TERMS_GRADE_TYPES,
              TERMS_GRADE_TYPES.MANDATORY
            ) as TermsGrade,
          });

          if (res.data.postAt) {
            setDate(new Date(res.data.postAt));
          }
        }
      } catch (err: any) {
        handleError(err, "조회");
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [termsId]);

  const handleTermsChange = (key: keyof EtcTerms) => (value: any) => {
    setTerms((prev) => ({ ...prev, [key]: value }));
  };

  const handleUpdate = () => {
    const errorMessage = validationResultMessage([
      validationFunctions.matchesRegex(
        terms.version,
        TERMS_VERSION_REGEX,
        "약관 버전",
        TERMS_VERSION_REGEX_COMMENT
      ),
      validationFunctions.required(terms.name, "약관명"),
      validationFunctions.required(terms.content, "약관 내용"),
      ...(!date ? [validationFunctions.required(date, "약관 고지일시")] : []),
    ]);

    if (errorMessage) {
      showAlert(errorMessage);
    } else {
      showConfirm("수정 하시겠습니까?", async () => {
        try {
          const res = await putEtcTerms(termsId, createData());
          if (res.status === HttpStatusCode.Ok) {
            showAlert("수정 되었습니다.");
            refreshAndClosePopup();
          }
        } catch (err: any) {
          handleError(err, "수정");
        }
      });
    }
  };

  const createData = () => {
    const data: Partial<EtcTerms> = {
      termsCategoryId: terms.termsCategoryId,
      name: terms.name,
      description: terms.description,
      content: terms.content,
      ...(date && { postAt: formatDate(date, "yyyy/MM/dd HH:mm:ss") }),
      version: terms.version,
      isUse: terms.isUse,
      grade: terms.grade,
    };

    return data;
  };

  const handleDelete = () => {
    showConfirm("삭제 하시겠습니까?", async () => {
      try {
        const res = await deleteEtcTerms(termsId);
        if (res.status === HttpStatusCode.Ok) {
          showAlert("삭제 되었습니다.");
          refreshAndClosePopup();
        }
      } catch (err: any) {
        handleError(err, "삭제");
      }
    });
  };

  return (
    <div className="flex flex-col gap-5">
      {isLoading && <Spinner />}
      <div className="flex flex-col min-w-[1000px] max-h-popup overflow-y-auto">
        <FormRow>
          <FormRowLabel title="약관 유형">
            <DefaultLabel text={terms.categoryName} />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="약관 버전" isRequired>
            <DefaultInput
              value={terms.version}
              onChange={handleTermsChange("version")}
              placeholder="버전을 입력해주세요.  ex) 0.0.1"
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="사용 여부">
            <RadioButtonGroup
              options={USAGE_OPTIONS}
              value={terms.isUse?.toString() ?? ""}
              onChange={(value: string) =>
                handleTermsChange("isUse")(value === BOOLEAN_TYPES.TRUE)
              }
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="필수 여부" isRequired>
            <RadioButtonGroup
              options={TERMS_GRADE_OPTIONS}
              value={terms.grade}
              onChange={handleTermsChange("grade")}
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="약관명" isRequired>
            <DefaultInput
              value={terms.name}
              onChange={handleTermsChange("name")}
              placeholder="약관명을 입력해주세요"
              minWidth="w-full"
              maxLength={50}
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="약관 설명">
            <DefaultInput
              value={terms.description}
              onChange={handleTermsChange("description")}
              placeholder="약관 설명을 입력해주세요"
              minWidth="w-full"
              maxLength={100}
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="약관 내용" isRequired>
            <DefaultTextArea
              rows={10}
              value={terms.content}
              onChange={handleTermsChange("content")}
              placeholder="약관 내용을 입력해주세요"
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="약관 고지일시" isRequired>
            <DateTimePicker
              date={date ?? new Date()}
              onDateChange={setDate}
              minuteStep={30}
            />
          </FormRowLabel>
        </FormRow>
      </div>

      <div className="flex gap-2 justify-center">
        <DefaultButton onClick={closePopup}>닫기</DefaultButton>
        {terms.purpose === null && (
          <DefaultButton color="primary" onClick={handleDelete}>
            삭제
          </DefaultButton>
        )}
        <DefaultButton color="primary" onClick={handleUpdate}>
          저장
        </DefaultButton>
      </div>
    </div>
  );
}
