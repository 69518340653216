import { HttpStatusCode } from "axios";
import { useMemo, useState } from "react";
import DefaultButton from "../../../../components/DefaultButton";
import ListPage, { SearchCondition } from "../../../../components/ListPage";
import Spinner from "../../../../components/Spinner";
import { TableColumnType } from "../../../../components/Table";
import {
  getTempHumBulkExcel,
  getTempHumList,
  getTempHumListExcel,
  patchBuildingSpaceDisconnecting,
  postTempHumBulk,
} from "../../../../services/buildingService";
import {
  TempHum,
  buildingGroupCategoryCodeValue,
} from "../../../../types/building";
import { ComponentType } from "../../../../types/search";
import { addDefaultOption } from "../../../../utils/comboBoxUtils";
import {
  CONNECT_OPTIONS,
  USAGE_OPTIONS,
  usageYnLabels,
} from "../../../../types/comboBoxOption";
import TempHumListTopRight from "../../../../components/PageComponents/IotDevice/Space/TempHumList/ListTopRight";
import { FiEdit2 } from "react-icons/fi";
import TempHumDetailView from "../../../../components/PageComponents/IotDevice/Space/TempHumList/DetailView";
import { useModal } from "../../../../contexts/Modal";
import ExcelFileUploaderButton from "../../../../components/FileUploader/ExcelFileUploaderButton";
import DefaultLabel from "../../../../components/Input/DefaultLabel";
import PermissionWrapper from "../../../../components/PermissionWrapper";

type Props = {};

export default function TempHumList(props: Props) {
  const { showAlert, showConfirm, handleError } = useModal();
  const [refresh, setRefresh] = useState(false);
  const [isLoading] = useState(false);

  const columnInfo: TableColumnType<TempHum>[] = [
    { header: "공간 ID", name: "buildingSpaceId", width: "id" },
    { header: "건물명", name: "buildingName" },
    { header: "층명", name: "floorName" },
    { header: "공간명", name: "spaceName" },
    { header: "정렬순서", name: "sortOrder", width: "number" },
    {
      header: "사용여부",
      render: (item: TempHum) => (
        <div className="flex items-center justify-center">
          <DefaultLabel
            text={item.isActive ? usageYnLabels.TRUE : usageYnLabels.FALSE}
            color={item.isActive ? "blue" : "gray"}
          />
        </div>
      ),
      width: "yesOrNo",
    },
    { header: "등록일시", name: "createdAt", width: "date" },
    {
      header: "상세",
      render: (item: TempHum, handler) => {
        return (
          <div className="w-full h-full flex gap-2 justify-center items-center">
            {item.isConnected && (
              <PermissionWrapper>
                <DefaultButton
                  size="tableButton"
                  color="blue"
                  onClick={() => patchData(item.buildingSpaceId)}
                >
                  층 연결 해제
                </DefaultButton>
              </PermissionWrapper>
            )}
            <DefaultButton
              size="tableIcon"
              color="blue"
              onClick={() => {
                if (handler)
                  handler.popup({
                    header: "상세",
                    content: (
                      <TempHumDetailView
                        buildingSpaceId={item.buildingSpaceId}
                      />
                    ),
                  });
              }}
            >
              <FiEdit2 />
            </DefaultButton>
          </div>
        );
      },
      width: "200",
    },
  ];

  const componentList: ComponentType<TempHum>[] = [
    {
      labelName: "등록일자",
      typeName: "dateRange",
      keyName: "createdAt",
      category: "date",
    },
    {
      labelName: "사용여부",
      typeName: "comboBox",
      keyName: "isActive",
      comboBoxOptions: addDefaultOption(USAGE_OPTIONS),
      category: "typeSearch",
    },
    {
      labelName: "연결상태",
      typeName: "comboBox",
      keyName: "isConnected",
      comboBoxOptions: addDefaultOption(CONNECT_OPTIONS),
      category: "typeSearch",
    },
    {
      labelName: "공간 ID",
      typeName: "text",
      keyName: "buildingSpaceId",
      category: "detailSearch",
      placeholder: "공간 ID를 입력해주세요",
    },
    {
      labelName: "공간명",
      typeName: "text",
      keyName: "spaceName",
      category: "detailSearch",
      placeholder: "공간명을 입력해주세요",
    },
    {
      typeName: "buildingAndFloor",
      keyName: "buildingAndFloor",
      category: "detailSearch",
      buildingGroupCategoryCode: buildingGroupCategoryCodeValue.IOT_DEVICE,
    },
  ];

  const patchData = (buildingSpaceId: string) => {
    showConfirm("연결을 끊으시겠습니까?", async () => {
      try {
        const response = await patchBuildingSpaceDisconnecting(buildingSpaceId);

        if (response.status === HttpStatusCode.Ok) {
          showAlert("연결끊기가 적용되었습니다.");
          setRefresh(!refresh);
        }
      } catch (err: any) {
        handleError(err, "연결끊기");
      }
    });
  };

  const getToiletListWrapper = useMemo(
    () => (params: SearchCondition<TempHum>) => {
      return getTempHumList(params);
    },
    [refresh]
  );

  return (
    <>
      {isLoading && <Spinner />}
      <ListPage
        getDataApi={getToiletListWrapper}
        excelDownloadApi={getTempHumListExcel}
        excelUpload={
          <ExcelFileUploaderButton
            title="온습도공기"
            getExcelDataApi={getTempHumBulkExcel}
            postExcelDataApi={postTempHumBulk}
          />
        }
        keyId={(item: TempHum) => item.buildingSpaceId}
        columnInfo={columnInfo}
        componentList={componentList}
        tableTitle="목록"
        renderTopRight={<TempHumListTopRight />}
      />
    </>
  );
}
