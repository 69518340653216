import { RentalDevice } from "../../../../../types/space";
import { getTimeInMinutes } from "../../../../../utils/dateUtils";
import CommonCheckbox from "../../../../Input/Checkbox";
import { TableColumnType } from "../../../../Table";
import TotalTable from "../../../../Table/TotalTable";

type Props = {
  rentalDeviceList: RentalDevice[];
  reservationTimeStart?: string;
  reservationTimeEnd?: string;
  usePayment?: boolean;
  disabled?: boolean;
  onDeviceChange?: (list: RentalDevice[]) => void;
};

type RentalDeviceTotal = RentalDevice & {
  subTotal: number;
};

export default function DeviceTotalTable({
  rentalDeviceList,
  reservationTimeStart = "",
  reservationTimeEnd = "",
  usePayment = false,
  disabled = false,
  onDeviceChange,
}: Props) {
  const totalTime =
    (getTimeInMinutes(reservationTimeEnd) -
      getTimeInMinutes(reservationTimeStart)) /
    30;

  const enhancedRentalDeviceList: RentalDeviceTotal[] = rentalDeviceList.map(
    (item) =>
      ({
        ...item,
        price: usePayment ? item.price : 0,
        subTotal:
          item.checked && usePayment ? (item.price ?? 0) * totalTime : 0,
      } as RentalDeviceTotal)
  );

  const totalColumn: TableColumnType<RentalDeviceTotal>[] = [
    {
      header: "선택",
      render: (item: RentalDeviceTotal) => (
        <div className="flex justify-center items-center min-w-short">
          <CommonCheckbox
            checked={item.checked}
            onClick={(value: boolean) => {
              if (disabled) return;

              const list = rentalDeviceList.map((propsItem) =>
                propsItem.rentalDeviceId === item.rentalDeviceId
                  ? ({ ...propsItem, checked: value } as RentalDevice)
                  : propsItem
              );
              onDeviceChange?.(list);
            }}
            disabled={disabled}
          />
        </div>
      ),
    },
    { header: "기기명", name: "deviceName" },
    {
      header: "이용요금\n(30분 단위)",
      render: (item: RentalDeviceTotal) => (
        <span>{`${item.price?.toLocaleString() ?? "0"}원`}</span>
      ),
    },
    {
      header: "소계",
      render: (item: RentalDeviceTotal) => (
        <span>{`${item.subTotal.toLocaleString() ?? "0"}원`}</span>
      ),
    },
  ];

  return (
    <div className="max-w-screen-md">
      <TotalTable
        columns={totalColumn}
        data={enhancedRentalDeviceList}
        totalColumn="subTotal"
        calculateTax={false}
      />
    </div>
  );
}
