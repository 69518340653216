import { useEffect, useState } from "react";
import { ServiceList } from "../../../types/service";
import Spinner from "../../Spinner";
import ServiceForm from "../../ServiceManagement/ServiceForm";
import {
  getCompanyService,
  getCompanyServiceList,
  patchCompanyService,
  postCompanyService,
  putCompanyService,
} from "../../../services/companyService";
import { HttpStatusCode } from "axios";
import { useModal } from "../../../contexts/Modal";

type Props = { companyId: string };

export default function CompanyServiceForm({ companyId }: Props) {
  const { handleError } = useModal();
  const [data, setData] = useState<Partial<ServiceList>>({});
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    try {
      setIsLoading(true);

      const response = await getCompanyServiceList(companyId);

      if (response.status === HttpStatusCode.Ok) {
        setData(response.data);
      }
    } catch (err: any) {
      handleError(err, "서비스 목록 조회");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {isLoading && <Spinner />}
      <ServiceForm
        id={companyId}
        serviceType="COMPANY"
        data={Object.assign(
          data?.vocList ? { 불편신고: data?.vocList } : {},
          data?.otherList
        )}
        templateData={data?.templateList ?? []}
        refreshService={fetchData}
        patchService={patchCompanyService}
        getService={getCompanyService}
        createService={postCompanyService}
        updateService={putCompanyService}
      />
    </>
  );
}
