import { HttpStatusCode } from "axios";
import { useContext, useEffect, useState } from "react";
import { PopupContext } from "../../../contexts/Popup";
import {
  getIncommodity,
  getIncommodityVirtualNumber,
  patchIncommodity,
  patchIncommodityComplete,
  patchIncommoditySchedule,
  patchIncommodityWorking,
} from "../../../services/incommodityService";
import {
  CenterIncommodityProcessDto,
  INCOMMODITY_STATUS_VALUE,
  ImageType,
  Incommodity,
  IncommodityResource,
  IncommodityStatus,
  ResourcePushTypeCode,
  feedbackOptions,
  incommodityErrorCode,
  incommodityStatusMap,
} from "../../../types/incommodity";
import DefaultButton from "../../DefaultButton";
import Feedback from "../../Feedback";
import ImageThumbnail from "../../FileUploader/ImageFileUploader/ImageThumbnail";
import MultiImageUploader from "../../FileUploader/ImageFileUploader/MultiImageFileUploader";
import FormHeader from "../../Form/Header";
import FormRow from "../../Form/Row";
import FormRowLabel from "../../Form/RowLabel";
import DefaultInput from "../../Input/DefaultInput";
import Spinner from "../../Spinner";
import { FileInfo } from "../../../types/file";
import SatisfactionReply from "../../Comment/SatisfactionReply";
import { useModal } from "../../../contexts/Modal";
import { AtgResource } from "../../../types/atg";
import IncommodityAtgResourceSelector from "./AtgResourceSelector";
import DefaultLabel from "../../Input/DefaultLabel";
import IncommodityAtgWorkerSelector from "./AtgWorkerSelector";
import {
  validationFunctions,
  validationResultMessage,
} from "../../../libs/validations";
import { SearchTagData } from "../../TagFilter";
import { getToday } from "../../../utils/dateUtils";
import ListPage from "../../ListPage";
import { TableColumnType } from "../../Table";
import { DASH } from "../../../types/search";
import FormRowLabelItem from "../../Form/RowLabelItem";
import { formatToVirtualNumber } from "../../../utils/formatUtils";
import PermissionWrapper from "../../PermissionWrapper";
import DefaultTextArea from "../../DefaultTextArea";

type Props = { centerIncommodityId: number };

export default function IncommodityDetailView({ centerIncommodityId }: Props) {
  const { showAlert, showConfirm, handleError } = useModal();
  const { closePopup, refreshAndClosePopup } = useContext(PopupContext);

  const [incommodity, setIncommodity] = useState<Partial<Incommodity>>({});
  const [safeNumber, setSafeNumber] = useState("");
  const [beforeFiles, setBeforeFiles] = useState<File[]>([]);
  const [afterFiles, setAfterFiles] = useState<File[]>([]);
  const [resourceTags, setResourceTags] = useState<SearchTagData>({});
  const [workerTags, setWorkerTags] = useState<SearchTagData>({});
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    try {
      setIsLoading(true);

      const response = await getIncommodity(centerIncommodityId);
      if (response.status === HttpStatusCode.Ok) {
        setIncommodity(response.data);
      } else {
        throw new Error("정보를 불러오지 못했습니다.");
      }
    } catch (err: any) {
      handleError(err, "조회");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSafeNumber = async () => {
    try {
      setIsLoading(true);
      const res = await getIncommodityVirtualNumber(centerIncommodityId);
      if (res.status === HttpStatusCode.Ok) {
        setSafeNumber(formatToVirtualNumber(res.data.virtualNumber));
        showAlert("정상으로 안심번호 변환 처리되었습니다.");
      }
    } catch (err: any) {
      handleError(err, "안심번호 확인");
    } finally {
      setIsLoading(false);
    }
  };

  const handleConfirm = () => {
    showConfirm("접수내용을 확인하셨나요?", async () => {
      try {
        const response = await patchIncommodityWorking(centerIncommodityId);
        if (response.status === HttpStatusCode.Ok) {
          showAlert("접수확인 되었습니다.");
          refreshAndClosePopup();
        } else {
          throw new Error("확인을 실패했습니다.");
        }
      } catch (err: any) {
        handleError(err, "접수확인");
      }
    });
  };

  const handleSchedule = () => {
    const errorMessage = validationResultMessage([
      validationFunctions.required(incommodity.feedback, "피드백"),
      validationFunctions.numberRange(10, 1000)(
        incommodity.feedback?.length ?? 0,
        "피드백"
      ),
    ]);

    if (errorMessage) {
      showAlert(errorMessage);
    } else {
      showConfirm(
        "불편신고 내역을 예정 처리 하시겠습니까?\n(투입자재, 작업자는 저장되지 않습니다.)",
        async () => {
          updateSchedule(incommodity.feedback ?? "");
        }
      );
    }
  };

  const handleUpdate = () => {
    showConfirm(
      `불편신고 내역을 수정하시겠습니까? ${
        includeStatus(["schedule"])
          ? "\n(투입자재, 작업자는 저장되지 않습니다.)"
          : ""
      }`,
      async () => {
        try {
          const res = await patchIncommodity(
            centerIncommodityId,
            getUpdateFormData()
          );
          if (res.status === HttpStatusCode.Ok) {
            if (res.data.result === incommodityErrorCode.ERROR) {
              showAlert(res.data.message);
            } else {
              showAlert("수정 되었습니다.");
              refreshAndClosePopup();
            }
          }
        } catch (err: any) {
          handleError(err, "수정");
        }
      }
    );
  };

  const handleComplete = () => {
    const isWorker = (workerTags.data?.length ?? 0) > 0;

    const isEmptyInventory = resourceTags.data?.some(
      (item) => (item.data as AtgResource).inventory === ""
    );

    const errorMessage = validationResultMessage([
      ...(!incommodity.atgBuildingId
        ? [validationFunctions.required(incommodity.worker, "작업자")]
        : !isWorker
        ? [validationFunctions.required(null, "작업자")]
        : []),
      ...(incommodity.atgBuildingId && isEmptyInventory
        ? [validationFunctions.required(null, "투입자재 재고")]
        : []),
      ...(incommodity.atgBuildingId ? [] : []),
      validationFunctions.required(incommodity.feedback, "피드백"),
      validationFunctions.numberRange(10, 1000)(
        incommodity.feedback?.length ?? 0,
        "피드백"
      ),
    ]);

    if (errorMessage) {
      showAlert(errorMessage);
    } else {
      showConfirm("불편신고 내역을 완료처리 하시겠습니까?", updateComplete);
    }
  };

  const getUpdateFormData = () => {
    const formData = new FormData();

    const dto = {
      deleteFiles: incommodity.deleteFiles,
      feedback: incommodity.feedback,
    };

    beforeFiles.forEach((file) => formData.append("beforeFiles", file));
    afterFiles.forEach((file) => formData.append("afterFiles", file));
    formData.append("centerIncommodityModifyDto", JSON.stringify(dto));

    return formData;
  };

  const getCompleteFormData = () => {
    const formData = new FormData();

    beforeFiles.forEach((file) => formData.append("beforeFiles", file));
    afterFiles.forEach((file) => formData.append("afterFiles", file));

    const resource =
      resourceTags.data?.map((resource) => {
        const resourceData = resource.data as AtgResource;
        return {
          materialCode: resourceData.materialCode,
          storageCode: resourceData.storageCode,
          centerCode: resourceData.centerCode,
          useQuantity: resourceData.inventory,
          materialName: resourceData.materialName,
          standardName: resourceData.standardName,
        } as IncommodityResource;
      }) ?? [];

    const data: Partial<CenterIncommodityProcessDto> = {
      completedAt: getToday("yyyy/MM/dd HH:mm:ss"),
      feedback: incommodity.feedback,
      worker: incommodity.atgBuildingId
        ? workerTags.data?.map((worker) => worker.name) ?? []
        : [incommodity.worker ?? ""],
      ...(checkResourceInputFromAtg()
        ? { resource }
        : { resource: [], resourceStr: incommodity.resourceStr }),
    };

    formData.append("CenterIncommodityProcessDto", JSON.stringify(data));
    return formData;
  };

  const updateSchedule = async (feedback: string) => {
    try {
      const res = await patchIncommoditySchedule(centerIncommodityId, feedback);
      if (res.status === HttpStatusCode.Ok) {
        showAlert("예정 처리 되었습니다.");
        refreshAndClosePopup();
      }
    } catch (err: any) {
      handleError(err, "예정 처리");
    }
  };

  const updateComplete = async () => {
    try {
      const res = await patchIncommodityComplete(
        centerIncommodityId,
        getCompleteFormData()
      );
      if (res.status === HttpStatusCode.Ok) {
        showAlert("처리완료 되었습니다.");
        refreshAndClosePopup();
      }
    } catch (err: any) {
      handleError(err, "처리완료");
    }
  };

  const handleTextArea = (value: string) => {
    setIncommodity((prev) => ({ ...prev, feedback: value }));
  };

  const handleImageDelete = (item: ImageType | FileInfo) => {
    const handleDelete = (
      prevState: any,
      fileId: number,
      fileIdKey: keyof ImageType,
      deleteFileKey: keyof Incommodity,
      preFileKey: keyof Incommodity,
      postFileKey: keyof Incommodity
    ) => {
      const deleteFiles = prevState[deleteFileKey] ?? [];
      if (deleteFiles.includes(fileId)) {
        return {
          ...prevState,
          [deleteFileKey]: deleteFiles.filter((id: number) => id !== fileId),
        };
      } else {
        return {
          ...prevState,
          [deleteFileKey]: [...deleteFiles, fileId],
          [preFileKey]: prevState[preFileKey]?.filter(
            (image: ImageType) => image[fileIdKey] !== fileId
          ),
          [postFileKey]: prevState[postFileKey]?.filter(
            (image: ImageType) => image[fileIdKey] !== fileId
          ),
        };
      }
    };

    setIncommodity((prev) => {
      return handleDelete(
        prev,
        item.fileId,
        "fileId",
        "deleteFiles",
        "s3PreFile",
        "s3PostFile"
      );
    });
  };

  const handleIncommodityChange =
    (key: keyof Incommodity) => (value: string) => {
      setIncommodity((prev) => ({ ...prev, [key]: value }));
    };

  const getStatusValue = (status?: string) => {
    if (status) {
      switch (status) {
        case INCOMMODITY_STATUS_VALUE.RECEIPT:
          return incommodityStatusMap.receipt.value;
        case INCOMMODITY_STATUS_VALUE.CONFIRM:
          return incommodityStatusMap.confirm.value;
        case INCOMMODITY_STATUS_VALUE.SCHEDULE:
          return incommodityStatusMap.schedule.value;
        case INCOMMODITY_STATUS_VALUE.COMPLETE:
        case INCOMMODITY_STATUS_VALUE.COMPLETE2:
          return incommodityStatusMap.complete.value;
        case INCOMMODITY_STATUS_VALUE.CANCEL:
          return incommodityStatusMap.cancel.value;
      }
    }
    return "";
  };

  const includeStatus = (statuses: IncommodityStatus[]) => {
    const currentStatus = getStatusValue(incommodity.status);
    return statuses.some(
      (status) => incommodityStatusMap[status].value === currentStatus
    );
  };

  const checkResourceInputFromAtg = () => {
    const { atgBuildingId, pushTypeCode } = incommodity;

    // 아래 코드의 경우 Atg 자재선택창을 출력하고, 그 외에는 직접입력창으로 출력한다.
    const codes = [
      ResourcePushTypeCode.FACILITY,
      ResourcePushTypeCode.COMMUNICATION,
      `${ResourcePushTypeCode.FACILITY},${ResourcePushTypeCode.CLEANING}`,
    ];

    const isAtgBuilding = Boolean(atgBuildingId);
    const isIncludeCode = codes.includes(pushTypeCode ?? "");

    return isAtgBuilding && isIncludeCode;
  };

  const renderResourceTable = (resource: string = "") => {
    if (!resource) {
      return DASH;
    }

    const parse = JSON.parse(resource);
    const columnInfo: TableColumnType<IncommodityResource>[] = [
      { header: "자재명", name: "materialName" },
      { header: "규격", name: "standardName" },
      {
        header: "수량",
        render: (item: IncommodityResource) =>
          isNaN(Number(item.useQuantity))
            ? DASH
            : Number(item.useQuantity).toLocaleString(),
      },
    ];

    return (
      <ListPage
        getDataApi={Promise.resolve}
        columnInfo={columnInfo}
        onlyRenderTable
        selectedMode
        selectedData={parse}
      />
    );
  };

  const renderCommon = () => {
    return (
      <>
        <div>
          <FormHeader title="신고센터 접수상세" />
          <FormRow>
            <FormRowLabel title="접수 번호">
              <DefaultLabel
                text={incommodity.centerIncommodityId?.toString()}
              />
            </FormRowLabel>
            <FormRowLabel title="접수 채널">
              <DefaultLabel text={incommodity.channelName ?? ""} />
            </FormRowLabel>
          </FormRow>
          <FormRow>
            <FormRowLabel title="작성일시">
              <DefaultLabel text={incommodity.createdAt ?? ""} />
            </FormRowLabel>
            <FormRowLabel title="최종 수정일시">
              <DefaultLabel text={incommodity.modifiedAt} />
            </FormRowLabel>
          </FormRow>
        </div>

        <div>
          <FormHeader title="신청 내역" />
          <FormRow>
            <FormRowLabel title="접수 시간">
              <DefaultLabel text={incommodity.createdAt ?? ""} />
            </FormRowLabel>
            <FormRowLabel title="처리 상태">
              <DefaultLabel text={incommodity.statusName ?? ""} />
            </FormRowLabel>
          </FormRow>
          <FormRow>
            <FormRowLabel title="분류">
              <DefaultLabel text={incommodity.categoryName ?? ""} />
            </FormRowLabel>
          </FormRow>

          <FormRow>
            <FormRowLabel title="위치">
              <div className="flex flex-col gap-2">
                <DefaultLabel
                  text={`건물 : ${incommodity.buildingName ?? ""} ${
                    incommodity.floorName ?? ""
                  }`}
                />
                <DefaultLabel
                  text={`상세 위치 : ${incommodity.location ?? ""}`}
                />
              </div>
            </FormRowLabel>
          </FormRow>

          <FormRow>
            <FormRowLabel title="신청자 정보">
              <div className="flex flex-col">
                <FormRowLabelItem>
                  <DefaultLabel
                    text={`회사 : ${incommodity.createdByCompany ?? ""}`}
                  />
                </FormRowLabelItem>
                <FormRowLabelItem>
                  <DefaultLabel
                    text={incommodity.createdByName ?? ""}
                    masking={"BO_INCOMMODITY_DETAIL_CREATED_BY"}
                    targetId={incommodity.createdBy ?? -1}
                    property="NAME"
                    prefix="이름 : "
                  />
                </FormRowLabelItem>
                <FormRowLabelItem>
                  <div className="flex items-center gap-2">
                    <span className="text-sm">전화번호 :</span>
                    {!safeNumber ? (
                      <DefaultButton
                        size="popupContentButton"
                        onClick={handleSafeNumber}
                      >
                        안심번호확인
                      </DefaultButton>
                    ) : (
                      <DefaultLabel text={`${safeNumber}`} />
                    )}
                  </div>
                </FormRowLabelItem>
              </div>
            </FormRowLabel>
          </FormRow>
          <FormRow>
            <FormRowLabel title="접수 정보">
              <div className="flex flex-col gap-2 w-full">
                <DefaultLabel
                  text={`${incommodity.equipmentTypeCodeName ?? ""}`}
                />
              </div>
            </FormRowLabel>
          </FormRow>
          <FormRow>
            <FormRowLabel title="처리희망시간">
              <DefaultLabel text={incommodity.deadLine ?? "즉시 처리 희망"} />
            </FormRowLabel>
          </FormRow>
          <FormRow>
            <FormRowLabel title="요청 내용">
              <div className="flex flex-col gap-2 w-full">
                <DefaultTextArea
                  value={incommodity.description ?? ""}
                  rows={5}
                  disabled
                />
                {incommodity.userFile && (
                  <ImageThumbnail
                    imageUrl={incommodity.userFile.filePath ?? ""}
                  />
                )}
              </div>
            </FormRowLabel>
          </FormRow>
        </div>
      </>
    );
  };

  const renderProcess = () => {
    return (
      <>
        <div>
          <FormHeader title="작업/결과" />
          <FormRow>
            <FormRowLabel title="접수확인 시간">
              <DefaultLabel text={incommodity.receiptedAt ?? ""} />
            </FormRowLabel>
            <FormRowLabel title="확인자">
              <DefaultLabel
                text={incommodity.receiptedByName ?? ""}
                masking={"BO_INCOMMODITY_DETAIL_RECEIPTED_BY"}
                targetId={incommodity.receiptedBy ?? -1}
                property="NAME"
                prefix=""
              />
            </FormRowLabel>
          </FormRow>
          {includeStatus(["complete"]) && (
            <FormRow>
              <FormRowLabel title="처리완료 시간">
                <DefaultLabel text={incommodity.completedAt ?? ""} />
              </FormRowLabel>
              <FormRowLabel title="작업자">
                <DefaultLabel text={incommodity.worker} />
              </FormRowLabel>
            </FormRow>
          )}
          <FormRow>
            <FormRowLabel title="작업 전 사진">
              <MultiImageUploader
                images={beforeFiles}
                maxImages={3}
                onChange={setBeforeFiles}
                defaultImages={
                  (incommodity.s3PreFile?.length ?? 0) > 0
                    ? incommodity.s3PreFile
                    : undefined
                }
                onDelete={handleImageDelete}
              />
            </FormRowLabel>
          </FormRow>
          <FormRow>
            <FormRowLabel title="작업 후 사진">
              <MultiImageUploader
                images={afterFiles}
                maxImages={4}
                onChange={setAfterFiles}
                defaultImages={
                  (incommodity.s3PostFile?.length ?? 0) > 0
                    ? incommodity.s3PostFile
                    : undefined
                }
                onDelete={handleImageDelete}
              />
            </FormRowLabel>
          </FormRow>
          <FormRow>
            <FormRowLabel title="투입 자재">
              {renderResourceContent()}
            </FormRowLabel>
          </FormRow>
          <FormRow>
            <FormRowLabel title="작업자" isRequired>
              <div className="w-full">
                {includeStatus(["confirm", "schedule"]) ? (
                  incommodity.atgBuildingId ? (
                    <IncommodityAtgWorkerSelector
                      atgBuildingId={incommodity.atgBuildingId}
                      workerTags={workerTags}
                      setWorkerTags={setWorkerTags}
                    />
                  ) : (
                    <DefaultInput
                      value={incommodity.worker}
                      onChange={handleIncommodityChange("worker")}
                      placeholder="작업자를 입력해주세요"
                    />
                  )
                ) : (
                  <DefaultLabel text={incommodity.worker} />
                )}
              </div>
            </FormRowLabel>
          </FormRow>
        </div>

        <div>
          <FormHeader title="피드백" />
          <FormRow>
            <FormRowLabel title="메시지" isRequired>
              <Feedback
                message={incommodity.feedback ?? ""}
                handleTextArea={handleTextArea}
                options={feedbackOptions}
              />
            </FormRowLabel>
          </FormRow>
        </div>

        {includeStatus(["complete"]) && (
          <div>
            <FormHeader title="만족도 평가" />
            <FormRow>
              <SatisfactionReply
                incommodity={incommodity}
                refreshData={fetchData}
              />
            </FormRow>
          </div>
        )}
      </>
    );
  };

  const renderResourceContent = () => {
    const isStatusInclude = includeStatus(["confirm", "schedule"]);
    const isSelectorInput = checkResourceInputFromAtg();

    let content;

    if (isStatusInclude) {
      content = isSelectorInput ? (
        <IncommodityAtgResourceSelector
          atgBuildingId={incommodity.atgBuildingId ?? ""}
          resourceTags={resourceTags}
          setResourceTags={setResourceTags}
        />
      ) : (
        <DefaultInput
          value={incommodity.resourceStr}
          onChange={handleIncommodityChange("resourceStr")}
          minWidth="w-full"
          placeholder="투입자재를 입력해주세요"
          maxLength={100}
        />
      );
    } else {
      content = isSelectorInput ? (
        renderResourceTable(incommodity.resource)
      ) : (
        <DefaultLabel text={incommodity.resourceStr} />
      );
    }

    return <div className="w-full">{content}</div>;
  };

  const renderCancel = () => {
    return (
      <div>
        <FormHeader title="취소 정보" />
        <FormRow>
          <FormRowLabel title="취소시간">
            <DefaultLabel text={incommodity.canceledAt ?? ""} />
          </FormRowLabel>
        </FormRow>
      </div>
    );
  };

  const renderButtons = (status: string | undefined) => {
    const realStatus =
      status === incommodityStatusMap.schedule.value && !incommodity.isScheduled
        ? incommodityStatusMap.confirm.value
        : status;

    switch (realStatus) {
      case incommodityStatusMap["receipt"].value:
        return (
          <DefaultButton color="primary" onClick={handleConfirm}>
            접수 확인
          </DefaultButton>
        );
      case incommodityStatusMap["confirm"].value:
        return (
          <>
            <DefaultButton color="primary" onClick={handleSchedule}>
              처리 예정
            </DefaultButton>
            <DefaultButton color="primary" onClick={handleComplete}>
              처리 완료
            </DefaultButton>
          </>
        );
      case incommodityStatusMap["schedule"].value:
        return (
          <>
            <DefaultButton color="primary" onClick={handleUpdate}>
              수정
            </DefaultButton>
            <DefaultButton color="primary" onClick={handleComplete}>
              처리 완료
            </DefaultButton>
          </>
        );
      case incommodityStatusMap["complete"].value:
        return (
          <DefaultButton color="primary" onClick={handleUpdate}>
            수정
          </DefaultButton>
        );
      default:
        return null;
    }
  };

  return (
    <div className="flex flex-col gap-5">
      {isLoading && <Spinner />}
      <div className="max-h-popup min-w-[900px] overflow-y-auto">
        {renderCommon()}
        {includeStatus(["confirm", "schedule", "complete"]) && renderProcess()}
        {includeStatus(["cancel"]) && renderCancel()}
      </div>
      <div className="flex justify-center gap-2">
        <DefaultButton onClick={closePopup}>닫기</DefaultButton>
        <PermissionWrapper>
          {renderButtons(getStatusValue(incommodity.status))}
        </PermissionWrapper>
      </div>
    </div>
  );
}
