import { HttpStatusCode } from "axios";
import ListPage, { SearchCondition } from "../../../../components/ListPage";
import { TableColumnType } from "../../../../components/Table";
import { useModal } from "../../../../contexts/Modal";
import {
  getManagerList,
  patchManagerLock,
  patchManagerPassword,
} from "../../../../services/managerService";
import { Manager } from "../../../../types/manager";
import { ComboBoxType, ComponentType, DASH } from "../../../../types/search";
import { addDefaultOption } from "../../../../utils/comboBoxUtils";
import DefaultButton from "../../../../components/DefaultButton";
import DefaultLabel from "../../../../components/Input/DefaultLabel";
import {
  ROLE_TYPES,
  getMemberRoleName,
  memberRoleLabels,
} from "../../../../types/member";
import {
  DEFAULT_SELECT_ALL_VALUE,
  USAGE_YN_OPTIONS,
  usageYnLabels,
} from "../../../../types/comboBoxOption";
import React, { useCallback, useState } from "react";
import PermissionWrapper from "../../../../components/PermissionWrapper";
import { useUserContext } from "../../../../contexts/User";
import { FaCopy } from "react-icons/fa";
import { copyToClipboard } from "../../../../libs/common";
import { useToast } from "@/components/ui/use-toast";

export const PasswordCopyBt = ({ copyText }: { copyText: string }) => {
  const { toast } = useToast();
  return (
    <span
      className="flex ml-2"
    >
      <span className="flex items-center border border-gray-300 w-[150px]">
        <span className="font-bold ml-2">
          {copyText}
        </span>
      </span>
      <button
          onClick={() => {
            copyToClipboard(copyText);
            toast({
              description: "비밀번호가 클립보드에 복사 되었습니다.",
              duration: 2000
            });
          }}
          className="bg-gray-300 px-3 hover:bg-gray-400 font-bold"
          title="복사"
          data-testid="password-copy-button"
      >
        복사하기
      </button>
    </span>
  );
};

type Props = {};
export default function ManagerList(props: Props) {
  const { userInfo } = useUserContext();
  const { showAlert, showConfirm, handleError } = useModal();

  const [refresh, setRefresh] = useState(false);

  const columnInfo: TableColumnType<Manager>[] = [
    { header: "회원 ID", name: "memberId", width: "id" },
    { header: "회원 이메일", name: "email" },
    { header: "이름", name: "name" },
    { header: "전화번호", name: "mobileNumber", width: "tel" },
    { header: "권한", render: (item: Manager) => getMemberRoleName(item.role) },
    { header: "건물", name: "buildingName" },
    { header: "층", name: "buildingFloorName" },
    { header: "세부위치", name: "spaceName" },
    { header: "회사", name: "companyName" },
    {
      header: "회사인증",
      render: (item: Manager) => (
        <div className="flex items-center justify-center">
          <DefaultLabel
            text={item.isCompanyCertificated ? "인증" : "미인증"}
            color={item.isCompanyCertificated ? "blue" : "gray"}
          />
        </div>
      ),
      width: "yesOrNo",
    },
    {
      header: "사용여부",
      render: (item: Manager) => (
        <div className="flex items-center justify-center">
          <DefaultLabel
            text={item.isUsable ? usageYnLabels.TRUE : usageYnLabels.FALSE}
            color={item.isUsable ? "blue" : "gray"}
          />
        </div>
      ),
      width: "yesOrNo",
    },
    {
      header: "비밀번호 초기화",
      render: (item: Manager) => (
        <div
          className="flex justify-center items-center"
          data-testid="password-init-bt"
        >
          {renderInitPasswordButton() ? (
            <DefaultButton
              size="tableButton"
              color="gray_default"
              onClick={() => {
                showConfirm(
                  `${item.memberId} 계정 비밀번호를 초기화 하시겠습니까?`,
                  () => resetPassword(item.memberId)
                );
              }}
              testId="reset-password"
            >
              초기화
            </DefaultButton>
          ) : (
            DASH
          )}
        </div>
      ),
      width: "button",
    },
    {
      header: "계정잠금",
      render: (item: Manager) => {
        const commandText = item.isLocked ? "잠금해지" : "잠금";
        return (
          <div className="flex justify-center items-center">
            <PermissionWrapper fallback={DASH}>
              <DefaultButton
                size="tableButton"
                color="gray_default"
                className="w-[80px]"
                onClick={() => {
                  showConfirm(
                    `${item.memberId} 계정을 ${commandText} 하시겠습니까?`,
                    () => lock(item.memberId, !item.isLocked, commandText)
                  );
                }}
              >
                {commandText}
              </DefaultButton>
            </PermissionWrapper>
          </div>
        );
      },
      width: "button",
    },
  ];

  const renderInitPasswordButton = () => {
    const renderRoles = [ROLE_TYPES.ROLE_ADMIN, ROLE_TYPES.ROLE_CALL_CENTER];
    return renderRoles.some((r) => userInfo?.roles?.includes(r));
  };

  const passwordCopyMassage = (
    memberId: string,
    copyText: string
  ): React.ReactNode => {
    return (
      <span>
        {memberId} 계정 비밀번호가 초기화 되었습니다.
        <br />
        <span className="flex items-center mt-2">
          변경된 비밀번호 <PasswordCopyBt copyText={copyText} />
        </span>
      </span>
    );
  };

  const resetPassword = async (memberId: string) => {
    try {
      const res = await patchManagerPassword(memberId);
      if (res.status === HttpStatusCode.Ok) {
        showAlert(passwordCopyMassage(memberId, res.data.password));
        setRefresh(!refresh);
      } else {
        throw new Error("Failed to reset password");
      }
    } catch (err: any) {
      handleError(err, "비밀번호 초기화");
    }
  };

  const lock = async (memberId: string, lock: boolean, commandText: string) => {
    try {
      const res = await patchManagerLock(memberId, lock);
      if (res.status === HttpStatusCode.Ok) {
        showAlert(`${memberId} 계정이 ${commandText} 되었습니다.`);
        setRefresh(!refresh);
      } else {
        throw new Error("Failed to lock member");
      }
    } catch (error: any) {
      handleError(error, commandText);
    }
  };

  const comboBoxRole: ComboBoxType[] = [
    { value: DEFAULT_SELECT_ALL_VALUE, label: "전체" },
    { value: ROLE_TYPES.ROLE_USER, label: memberRoleLabels.ROLE_USER },
    {
      value: ROLE_TYPES.ROLE_MONITORING,
      label: memberRoleLabels.ROLE_MONITORING,
    },
    { value: ROLE_TYPES.ROLE_FIELD, label: memberRoleLabels.ROLE_FIELD },
    { value: ROLE_TYPES.ROLE_MANAGER, label: memberRoleLabels.ROLE_MANAGER },
    {
      value: ROLE_TYPES.ROLE_CALL_CENTER,
      label: memberRoleLabels.ROLE_CALL_CENTER,
    },
    {
      value: ROLE_TYPES.ROLE_CAFETERIA,
      label: memberRoleLabels.ROLE_CAFETERIA,
    },
    { value: ROLE_TYPES.ROLE_SPACE, label: memberRoleLabels.ROLE_SPACE },
    { value: ROLE_TYPES.ROLE_ADMIN, label: memberRoleLabels.ROLE_ADMIN },
  ];

  const componentList: ComponentType<Manager>[] = [
    {
      labelName: "권한 그룹명",
      typeName: "comboBox",
      keyName: "role",
      category: "typeSearch",
      comboBoxOptions: comboBoxRole,
    },
    {
      labelName: "사용 여부",
      typeName: "comboBox",
      keyName: "useYn",
      category: "typeSearch",
      comboBoxOptions: addDefaultOption(USAGE_YN_OPTIONS),
    },
    {
      labelName: "사용자 이름",
      typeName: "text",
      keyName: "name",
      category: "detailSearch",
      placeholder: "사용자 이름을 입력해주세요",
    },
    {
      labelName: "사용자 이메일",
      typeName: "text",
      keyName: "email",
      category: "detailSearch",
      placeholder: "사용자 이메일을 입력해주세요",
    },
  ];

  const getManagerListCallback = useCallback(
    async (params: SearchCondition<Manager>) => getManagerList(params),
    [refresh]
  );

  return (
    <ListPage
      getDataApi={getManagerListCallback}
      keyId={(item: Manager) => item.memberId}
      columnInfo={columnInfo}
      componentList={componentList}
      tableTitle="사용자 목록"
    />
  );
}
