import clsx from "clsx";

type Props = {
  value?: string | null;
  rows?: number;
  cols?: number;
  onChange?: (value: string) => void;
  resize?: boolean;
  maxLength?: number;
  lengthVertical?: boolean;
  placeholder?: string;
  label?: string;
  disabled?: boolean;
};

export default function DefaultTextArea({
  onChange,
  value = "",
  rows = 3,
  cols = 1,
  resize = false,
  maxLength = 0,
  lengthVertical = false,
  placeholder = "",
  label = "",
  disabled = false,
}: Props) {
  return (
    <div
      className={clsx("flex w-full gap-2 relative text-sm", {
        "flex-col": lengthVertical,
      })}
    >
      <div className="flex flex-col gap-2 w-full">
        {label && <span className="block min-w-label text-label">{label}</span>}
        <textarea
          value={value ?? ""}
          onChange={(e) => {
            if (onChange) onChange(e.target.value);
          }}
          className={clsx(
            "w-full h-full px-3 py-2 border border-gray-200 text-[13px] rounded-none focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent",
            { "resize-none": !resize }
          )}
          rows={rows}
          cols={cols}
          maxLength={maxLength > 0 ? maxLength : undefined}
          placeholder={placeholder}
          disabled={disabled}
        />
      </div>
      {maxLength > 0 && (
        <label className="flex items-end justify-start absolute right-2 bottom-1 text-[11px] text-[#b2b2b2]">
          {value?.length ?? 0}/{maxLength}
        </label>
      )}
    </div>
  );
}
