import { useContext, useEffect, useState } from "react";
import { HttpStatusCode } from "axios";

import {
  BuildingCompany,
  BuildingGroup,
  buildingGroupCategoryCodeValue,
  TagSelectedItem,
} from "@/types/building";
import { evacueeType } from "@/types/comboBoxOption";
import { EvacuationUser } from "@/types/emergency";
import { ROLE_EMERGENCY_TYPES } from "@/types/member";

import {
  validationFunctions,
  validationResultMessage,
} from "@/libs/validations";
import { toComboBoxType } from "@/utils/comboBoxUtils";

import { PopupContext } from "@/contexts/Popup";
import { useModal } from "@/contexts/Modal";

import { getEvacuee, putEvacuee } from "@/services/emergencyService";
import {
  getBuildingCompanyListAll,
  getBuildingFloor,
} from "@/services/buildingService";

import DefaultButton from "../../../../DefaultButton";
import FormHeader from "../../../../Form/Header";
import FormRow from "../../../../Form/Row";
import FormRowLabel from "../../../../Form/RowLabel";
import DefaultInput from "../../../../Input/DefaultInput";
import Spinner from "../../../../Spinner";
import DefaultSelect from "@/components/SelectBox/DefaultSelect";
import TagSingleFilter from "@/components/TagFilter/TagSingleFilter";
import { SearchCondition } from "@/components/ListPage";
import { ComboBoxType } from "@/types/search";

type Props = {
  userId: string;
};

export default function EvacueeListDetailView({ userId: evacueeId }: Props) {
  const { showAlert, showConfirm, handleError } = useModal();
  const { refreshAndClosePopup, closePopup } = useContext(PopupContext);
  const [evacuee, setEvacuee] = useState<Partial<EvacuationUser>>({});
  const [isLoading, setIsLoading] = useState(false);
  const [company, setCompany] = useState<BuildingCompany[]>([]);
  const [selectedBuilding, setSelectedBuilding] = useState<TagSelectedItem>();
  const [buildingFloorOptions, setBuildingFloorOptions] = useState<
    ComboBoxType[]
  >([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        const response = await getEvacuee(evacueeId);
        if (response.status === HttpStatusCode.Ok) {
          setEvacuee({
            ...response.data,
          });
          setSelectedBuilding({
            buildingId: response.data.evacuationBuildingId,
            buildingName: response.data.evacuationBuildingName,
            address: "",
            atgBuildingId: "",
            isActive: true,
            createdAt: "",
          } as BuildingGroup);
        }
      } catch (err) {
        handleError(err, "조회");
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [evacueeId]);

  useEffect(() => {
    if (evacuee.evacuationBuildingId) {
      fetchCompany(evacuee.evacuationBuildingId);
      fetchFloorData(evacuee.evacuationBuildingId);
    }
  }, [evacuee.evacuationBuildingId]);

  const handleEvacueeChange = (data: Partial<EvacuationUser>) => {
    setEvacuee((prev) => ({ ...prev, ...data }));
  };

  const handleUpdate = () => {
    const errorMessage = validationResultMessage([
      validationFunctions.required(evacuee?.name, "이름"),
      validationFunctions.required(evacuee.mobileNumber, "전화번호"),
      validationFunctions.required(evacuee.role, "권한선택"),
      validationFunctions.required(evacuee.evacuationBuildingId, "건물 선택"),
      validationFunctions.required(evacuee.evacuationCompanyId, "회사 선택"),
      validationFunctions.required(evacuee.evacuationFloorId, "근무 층 선택"),
      validationFunctions.required(evacuee.evacuationDepartmentName, "부서"),
      // TODO : 근무 층은 잠시 필수 항목에서 제외
    ]);

    if (errorMessage) {
      showAlert(errorMessage);
    } else {
      showConfirm("수정 하시겠습니까?", updateData);
    }
  };

  const updateData = async () => {
    try {
      const response = await putEvacuee(evacueeId, createData());

      if (response.status === HttpStatusCode.Ok) {
        showAlert("수정 되었습니다.");
        refreshAndClosePopup();
      }
    } catch (err: any) {
      handleError(err, "수정");
    }
  };

  const createData = () => {
    const data: Partial<EvacuationUser> = {
      name: evacuee.name,
      mobileNumber: evacuee.mobileNumber,
      role: evacuee.role,
      evacuationBuildingId: evacuee.evacuationBuildingId,
      evacuationBuildingName: evacuee.evacuationBuildingName,
      evacuationCompanyId: evacuee.evacuationCompanyId,
      evacuationCompanyName: evacuee.evacuationCompanyName,
      evacuationDepartmentName: evacuee.evacuationDepartmentName,
      evacuationFloorId: evacuee.evacuationFloorId,
    };
    return data;
  };

  const fetchCompany = async (buildingId: string) => {
    try {
      const params = {
        buildingId: buildingId,
      } as SearchCondition<BuildingCompany>;
      const response = await getBuildingCompanyListAll(params);
      if (response.status === HttpStatusCode.Ok) {
        setCompany(response.data);
      } else {
        showAlert("회사 목록 조회에 실패 하였습니다.");
      }
    } catch (err: any) {
      const error = err.response?.data?.error;

      if (error) {
        showAlert(err.response?.data?.error);
      } else {
        handleError(err, "대피영역 지정 - 회사 목록");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const fetchFloorData = async (buildingId: string) => {
    if (!buildingId) {
      showAlert("건물을 선택해주세요.");
      return;
    }

    try {
      setIsLoading(true);
      const response = await getBuildingFloor(buildingId);

      if (response.status === HttpStatusCode.Ok) {
        const options = toComboBoxType(
          response.data,
          "buildingFloorId",
          "floorName"
        );
        setBuildingFloorOptions(options);
      }
    } catch (err: any) {
      handleError(err, "건물 층 정보 조회");
    } finally {
      setIsLoading(false);
    }
  };

  const handleBuildingChange = (item: BuildingGroup) => {
    if (item.buildingId) {
      setSelectedBuilding(item);
      handleEvacueeChange({
        evacuationBuildingId: item.buildingId,
        evacuationCompanyId: undefined,
        evacuationCompanyName: undefined,
        evacuationDepartmentName: undefined,
        evacuationFloorId: undefined,
      });
    } else {
      console.error("Building ID is not found while selecting building.");
    }
  };

  return (
    <div className="flex flex-col gap-5">
      {isLoading && <Spinner />}
      <div>
        <FormHeader title="등록 인원 상세정보" />
        <FormRow>
          <FormRowLabel title="이름" isRequired>
            <div className="flex flex-col">
              <div className="flex gap-2 items-center">
                <DefaultInput
                  value={evacuee?.name}
                  onChange={(value: string) => {
                    handleEvacueeChange({ name: value });
                  }}
                  placeholder="이름을 입력해주세요"
                />
              </div>
            </div>
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="전화번호" isRequired>
            <div className="flex flex-col">
              <div className="flex gap-2 items-center">
                <DefaultInput
                  value={evacuee?.mobileNumber}
                  onChange={(value: string) => {
                    handleEvacueeChange({ mobileNumber: value });
                  }}
                  placeholder="전화번호를 입력해주세요"
                  type="tel"
                />
              </div>
            </div>
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="권한 선택" isRequired>
            <div className="flex flex-col">
              <DefaultSelect
                value={evacuee.role}
                optionList={evacueeType}
                onChange={(value: string) => {
                  handleEvacueeChange({ role: value });
                }}
                placeholder="권한을 선택해주세요"
                dataTestId="role"
                disabled={evacuee.role === ROLE_EMERGENCY_TYPES.ROLE_ADMIN}
              />
              <div className="text-xs text-[#D95050] mt-2">
                *등록되는 인원이 비회원인 경우 권한은 일반 사용자로 변경됩니다.
              </div>
            </div>
          </FormRowLabel>
        </FormRow>
        <FormHeader title="등록 인원 회사정보" />
        <FormRow>
          <FormRowLabel title="건물 선택" isRequired>
            <TagSingleFilter
              selectType="building"
              selectedItem={selectedBuilding}
              onChangeBuilding={handleBuildingChange}
              categoryCode={buildingGroupCategoryCodeValue.POST}
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="회사 선택" isRequired>
            <DefaultSelect
              value={evacuee.evacuationCompanyId}
              optionList={toComboBoxType(company, "companyId", "companyName")}
              onChange={(value: string) => {
                handleEvacueeChange({ evacuationCompanyId: value });
              }}
              placeholder="회사를 선택해주세요"
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="근무 층 선택" isRequired>
            <DefaultSelect
              value={evacuee.evacuationFloorId}
              onChange={(value: string) =>
                handleEvacueeChange({ evacuationFloorId: value })
              }
              optionList={buildingFloorOptions}
              placeholder="층을 선택해주세요"
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="부서" isRequired>
            <div className="flex flex-col">
              <div className="flex gap-2 items-center">
                <DefaultInput
                  value={evacuee?.evacuationDepartmentName}
                  onChange={(value: string) => {
                    handleEvacueeChange({ evacuationDepartmentName: value });
                  }}
                  placeholder="부서를 입력해주세요"
                />
              </div>
            </div>
          </FormRowLabel>
        </FormRow>
        {/* <FormRow>
          <FormRowLabel title="근무 층">
            <div className="flex flex-col">
              <div className="flex gap-2 items-center">
                <DefaultInput
                  value={evacuee?.floor}
                  onChange={(value: string) => {
                    handleEvacueeChange({ floor: value });
                  }}
                  placeholder="층을 입력해주세요"
                />
              </div>
            </div>
          </FormRowLabel>
        </FormRow> */}
      </div>

      <div className="flex gap-2 justify-center">
        <DefaultButton onClick={closePopup}>닫기</DefaultButton>
        <DefaultButton color="primary" onClick={handleUpdate}>
          수정
        </DefaultButton>
      </div>
    </div>
  );
}
