import { useContext } from "react";
import { PopupContext } from "../../../../../contexts/Popup";
import DefaultButton from "../../../../DefaultButton";
import TemplateCreateForm from "../CreateForm";

type Props = {};

export default function TemplateListTopRight(props: Props) {
  const { openPopup } = useContext(PopupContext);

  return (
    <DefaultButton
      size="tableTop"
      color="default"
      onClick={() =>
        openPopup({
          header: "템플릿 서비스 등록",
          content: <TemplateCreateForm />,
        })
      }
    >
      템플릿 서비스 등록
    </DefaultButton>
  );
}
