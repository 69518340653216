import { useEffect, useState } from "react";
import { formatDate } from "../../../utils/dateUtils";
import { MAX_DATE, MIN_DATE } from "../../../constants/dateConstants";

type Props = {
  date: Date;
  maxDate?: Date;
  minDate?: Date;
  onlyTime?: boolean;
  minuteStep?: number;
  startDate?: Date;
  endDate?: Date;
  onDateChange: (date: Date) => void;
  testId?: string;
};

export const formatDateBanner = (date: Date): string => {
  const year = date.getFullYear();
  const month = ("0" + (date.getMonth() + 1)).slice(-2); // getMonth()는 0부터 시작하므로 1을 추가
  const day = ("0" + date.getDate()).slice(-2);
  const hours = ("0" + date.getHours()).slice(-2);
  const minutes = ("0" + date.getMinutes()).slice(-2);
  const seconds = ("0" + date.getSeconds()).slice(-2);

  return `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
};

export default function DateTimePicker({
  date,
  maxDate,
  minDate,
  onlyTime = false,
  minuteStep = 1,
  startDate,
  endDate,
  onDateChange,
  testId,
}: Props) {
  const [hours, setHours] = useState(date.getHours());
  const [minutes, setMinutes] = useState(date.getMinutes());

  useEffect(() => {
    onDateChange(date);
  }, []);

  useEffect(() => {
    setHours(date.getHours());
    setMinutes(date.getMinutes());
  }, [date]);

  useEffect(() => {
    const newDate = new Date(date);
    newDate.setHours(hours);
    newDate.setMinutes(minutes);
    onDateChange(newDate);
  }, [hours, minutes]);

  useEffect(() => {
    if (startDate && endDate) {
      setHours(date.getHours());
      setMinutes(date.getMinutes());
    }
  }, [startDate, endDate]);

  const handleDateChange = (value: string) => {
    const newDate = new Date(date);
    const [year, month, day] = value.split("-").map(Number);
    newDate.setFullYear(year, month - 1, day);
    onDateChange(newDate);
  };

  const handleHourChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newHours = Number(event.target.value);
    setHours(newHours);

    if (
      minDate &&
      newHours === minDate.getHours() &&
      minutes < minDate.getMinutes()
    ) {
      setMinutes(minDate.getMinutes());
    }

    if (
      maxDate &&
      newHours === maxDate.getHours() &&
      minutes > maxDate.getMinutes()
    ) {
      setMinutes(maxDate.getMinutes());
    }

    const newDate = new Date(date);
    newDate.setHours(newHours);
    newDate.setMinutes(minutes);
    onDateChange(newDate);
  };

  const handleMinuteChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newMinutes = Number(event.target.value);
    setMinutes(newMinutes);

    const newDate = new Date(date);
    newDate.setHours(hours);
    newDate.setMinutes(newMinutes);
    onDateChange(newDate);
  };

  const hourOptions = Array.from({ length: 24 }, (_, i) => i).filter(
    (hour) =>
      (!startDate || hour >= startDate.getHours()) &&
      (!endDate || hour <= endDate.getHours()) &&
      (!onlyTime ||
        ((!minDate || hour >= minDate.getHours()) &&
          (!maxDate || hour <= maxDate.getHours())))
  );

  const minuteOptions = Array.from(
    { length: Math.floor(60 / minuteStep) },
    (_, i) => i * minuteStep
  ).filter(
    (minute) =>
      !onlyTime ||
      ((!startDate ||
        hours > startDate.getHours() ||
        (hours === startDate.getHours() && minute >= startDate.getMinutes())) &&
        (!endDate ||
          hours < endDate.getHours() ||
          (hours === endDate.getHours() && minute <= endDate.getMinutes())) &&
        (!minDate ||
          hours > minDate.getHours() ||
          (hours === minDate.getHours() && minute >= minDate.getMinutes())) &&
        (!maxDate ||
          hours < maxDate.getHours() ||
          (hours === maxDate.getHours() && minute <= maxDate.getMinutes())))
  );

  const dateValue = `${date.getFullYear()}-${(date.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;

  return (
    <div className="flex gap-2" data-testid={testId}>
      {!onlyTime && (
        <input
          type="date"
          className="block border border-gray-200 h-[38px] w-[200px] px-4 py-2 text-[13px]"
          value={dateValue}
          max={maxDate ? formatDate(maxDate, "yyyy-MM-dd") : MAX_DATE}
          min={minDate ? formatDate(minDate, "yyyy-MM-dd") : MIN_DATE}
          onChange={(e) => handleDateChange(e.target.value)}
        />
      )}
      <select
        className="border border-gray-200 px-4 py-2 text-[13px] h-[38px]"
        onChange={handleHourChange}
        value={hours}
      >
        {hourOptions.map((hour) => (
          <option key={hour} value={hour}>
            {hour.toString().padStart(2, "0")}시
          </option>
        ))}
      </select>

      <select
        className="border border-gray-200 px-4 py-2 text-[13px] h-[38px]"
        onChange={handleMinuteChange}
        value={minutes}
      >
        {minuteOptions.map((minute) => (
          <option key={minute} value={minute}>
            {minute.toString().padStart(2, "0")}분
          </option>
        ))}
      </select>
    </div>
  );
}
