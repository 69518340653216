import React from "react";
import clsx from "clsx";

interface Props {
  label?: string;
  inline?: boolean;
  onClick?: (value: boolean) => void;
  checked?: boolean;
  className?: string;
  disabled?: boolean;
  testId?: string;
  labelTestId?: string;
}

export default function CommonCheckbox({
  label,
  inline = false,
  className,
  onClick,
  disabled,
  testId,
  labelTestId,
  ...rest
}: Props) {
  const classNames = clsx(
    "flex items-center text-sm",
    inline ? "inline-flex" : "block",
    className
  );

  return (
    <label
      className={classNames}
      onClick={() => onClick?.(!rest.checked)}
      data-testid={`${testId}-common-check-box-wrapper`}
    >
      <div className="p-0.5">
        <div
          className={clsx([
            "w-5 h-5 rounded-sm flex items-center justify-center",
            {
              "border border-neutral-50":
                rest.checked !== true && disabled !== true,
              "border border-neutral-20 disabled":
                rest.checked !== true && disabled === true,
              "bg-sandi-gradient checked":
                rest.checked === true && disabled !== true,
              "bg-neutral-20 checked disabled":
                rest.checked === true && disabled === true,
            },
          ])}
          data-testid={`${testId}-common-check-box-div`}
        >
          <div
            className={clsx([
              "transition-all",
              {
                "opacity-0": rest.checked !== true,
                "opacity-100 checked": rest.checked === true,
              },
            ])}
            data-testid={`${testId}-common-check-box`}
            aria-disabled={disabled}
            aria-checked={rest.checked}
          >
            <CheckboxCheckIcon />
          </div>
        </div>
      </div>
      {label && (
        <span
          className="ml-2 text-gray-700"
          data-testid={`${labelTestId}-common-check-box-label`}
        >
          {label}
        </span>
      )}
    </label>
  );
}

export const CheckboxCheckIcon = () => (
  <svg
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.0625 8.42188L8.5625 12.1719L14.5625 4.67188"
      stroke="white"
      strokeWidth="1.75"
    />
  </svg>
);
