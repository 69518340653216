import ListPage from "../../../../components/ListPage";
import { TableColumnType } from "../../../../components/Table";
import {
  getLoggingPermissionList,
  getLoggingPermissionListExcel,
} from "../../../../services/loggingService";
import { LoggingPermission } from "../../../../types/logging";
import { ComponentType } from "../../../../types/search";

type Props = {};

export default function LoggingPermissionList(props: Props) {
  const columnInfo: TableColumnType<LoggingPermission>[] = [
    { header: "No.", defaultNo: true, width: "no" },
    { header: "사용자 Email", name: "targetEmail" },
    { header: "사용자명", name: "targetName" },
    { header: "이전 권한", name: "before" },
    { header: "변경 권한", name: "after" },
    { header: "변경자 Email", name: "createdByEmail" },
    { header: "변경자명", name: "createdByName" },
    { header: "IP 주소", name: "createdByIp" },
    { header: "처리 일시", name: "createdAt", width: "date" },
  ];

  const componentList: ComponentType<LoggingPermission>[] = [
    {
      typeName: "dateRange",
      keyName: "loggedAt",
      category: "date",
      dateRangeType: "0",
    },
    {
      labelName: "사용자 Email",
      typeName: "text",
      keyName: "targetEmail",
      category: "detailSearch",
      placeholder: "사용자 Email을 입력해주세요",
    },
    {
      labelName: "사용자명",
      typeName: "text",
      keyName: "targetName",
      category: "detailSearch",
      placeholder: "사용자명을 입력해주세요",
    },
    {
      labelName: "변경자 Email",
      typeName: "text",
      keyName: "createdByEmail",
      category: "detailSearch",
      placeholder: "변경자 Email을 입력해주세요",
    },
    {
      labelName: "변경자명",
      typeName: "text",
      keyName: "createdByName",
      category: "detailSearch",
      placeholder: "변경자명을 입력해주세요",
    },
  ];

  return (
    <ListPage
      getDataApi={getLoggingPermissionList}
      keyId={(item: LoggingPermission) => item.historyId}
      columnInfo={columnInfo}
      componentList={componentList}
      tableTitle="권한 부여 이력 목록"
      defaultPageSize={100}
      defaultPageSizeList={[100, 200, 300]}
      excelDownloadApi={getLoggingPermissionListExcel}
      needExcelDownloadReason
    />
  );
}
