import { AxiosResponse, HttpStatusCode } from "axios";
import { useContext, useEffect, useRef, useState } from "react";

import { toComboBoxType } from "@/utils/comboBoxUtils";
import {
  validationFunctions,
  validationResultMessage,
} from "@/libs/validations";
import { getShownEvacueeType, renderEvacueeType } from "@/libs/evacuation";

import {
  EmergencyBuilding,
  EmergencyBuildingGroup,
  EvacuationUser,
  radioBoxSituationPushLevel,
  radioBoxSituationPushNoResponse,
  radioBoxSituationType,
  Situation,
} from "@/types/emergency";
import { ComboBoxType } from "@/types/search";
import { DEFAULT_SELECT_ALL_VALUE } from "@/types/comboBoxOption";

import { PopupContext } from "@/contexts/Popup";
import { useModal } from "@/contexts/Modal";

import {
  getEmergencyBuildingGroupCompanyEnabled,
  getEvacueeBulkExcelForm,
  getSituationGroupList,
  postSituation,
} from "@/services/emergencyService";

import { getBuildingFloor } from "@/services/buildingService";

import TagEmergencyFilter from "@/components/TagFilter/TagEmergencyFilter";
import CreateEvacuee from "../CreateEvacuee";
import EmergencyModal from "@/components/Modal/EmergencyModal";
import CommonCheckbox from "@/components/Input/Checkbox";
import ExcelFileUploaderButton from "@/components/FileUploader/ExcelFileUploaderButton";
import Spinner from "@/components/Spinner";
import FormHeader from "@/components/Form/Header";
import FormRowLabel from "@/components/Form/RowLabel";
import FormRow from "@/components/Form/Row";
import DefaultInput from "@/components/Input/DefaultInput";
import DefaultSelect from "@/components/SelectBox/DefaultSelect";
import DefaultButton from "@/components/DefaultButton";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import Pagination from "@/components/Pagination";

export default function SituationListCreateForm() {
  const { showAlert, showConfirm, handleError } = useModal();
  const [isLoading, setIsLoading] = useState(false);
  const [isUserLoading, setIsisUserLoading] = useState(false);
  const [situation, setSituation] = useState<Partial<Situation>>({});
  const [selectedTag, setSelectedTag] = useState<EmergencyBuildingGroup>();
  const [evacueeList, setEvacueeList] = useState<EvacuationUser[]>([]);
  const [paginatedEvacueeList, setPaginatedEvacueeList] = useState<
    EvacuationUser[]
  >([]);
  const [pageable, setPageable] = useState<{
    page: number;
    totalPages: number;
    totalCount: number;
  }>({ page: 1, totalPages: 0, totalCount: 0 });
  const [buildingFloorOptions, setBuildingFloorOptions] = useState<
    ComboBoxType[]
  >([]);
  const [emergencyBuilding, setEmergencyBuilding] = useState<
    EmergencyBuilding[] | undefined
  >([]);
  const [isOpen, setIsOpen] = useState(false);
  const [evacueeCheckedList, setEvacueeCheckedList] = useState<string[]>([]);
  const tempDataCount = useRef(0);
  const initSearchQuery = {
    name: "",
    mobileNumber: "",
    companyName: "",
  };
  const [searchQuery, setSearchQuery] = useState(initSearchQuery);
  const [searchTriggered, setSearchTriggered] = useState(false); // 검색이 트리거되었는지 여부
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const progress = isLoading || isUserLoading;

  const { closePopup, refreshAndClosePopup } = useContext(PopupContext);

  const handleSearch = () => {
    setSearchTriggered(!searchTriggered);
    setCurrentPage(1); // 검색할 때 첫 페이지로 이동
    setEvacueeCheckedList([]);
  };

  const handleReset = () => {
    setSearchQuery(initSearchQuery);
    setSearchTriggered(!searchTriggered); // 검색 초기화
    setCurrentPage(1); // 초기화할 때 첫 페이지로 이동
    setEvacueeCheckedList([]);
  };

  const handlePost = () => {
    const errorMessage = validationResultMessage([
      validationFunctions.required(situation.evacuationName, "상황명"),
      validationFunctions.required(situation.incidentAddress, "상황 발생 주소"),
      validationFunctions.required(
        situation.evacuationCategoryCode,
        "상황 유형"
      ),
      validationFunctions.required(
        situation.incidentBuildingId,
        "상황 발생 건물"
      ),
      validationFunctions.required(situation.incidentFloor, "상황 발생 층"),
    ]);

    if (errorMessage) {
      showAlert(errorMessage);
    } else {
      let isEvacueeListComplete: boolean = true;
      evacueeList.forEach((evacuee) => {
        if (
          evacuee.evacuationBuildingName === "-" ||
          evacuee.evacuationCompanyName === "-"
        ) {
          isEvacueeListComplete = false;
        }
      });
      if (isEvacueeListComplete) {
        showConfirm("비상대피 상황을 등록하시겠습니까?", async () =>
          postData()
        );
      } else {
        showAlert(
          "그룹 정보와 일치하지 않는 건물 또는 회사를 설정한 유저가 있습니다."
        );
      }
    }
  };

  const addToList = (item: EvacuationUser) => {
    setEvacueeList((prevList) => [
      { ...item, evacueeId: `temp_${tempDataCount.current++}` },
      ...prevList,
    ]);
    setEvacueeCheckedList([]);
  };

  const postData = async () => {
    situation.evacueeList = evacueeList;
    try {
      const response = await postSituation(situation);
      if (response.status === HttpStatusCode.Ok) {
        showAlert("등록 되었습니다.");
        refreshAndClosePopup();
      }
    } catch (err: any) {
      handleError(err, "등록");
    }
  };

  const handleSituationChange = (data: Partial<Situation>) => {
    setSituation((prev) => ({ ...prev, ...data }));
  };

  const fetchFloorData = async (buildingId: string) => {
    if (!buildingId) {
      showAlert("건물을 선택해주세요.");
      return;
    }

    try {
      setIsLoading(true);
      const response = await getBuildingFloor(buildingId);

      if (response.status === HttpStatusCode.Ok) {
        const options = toComboBoxType(
          response.data,
          "buildingFloorId",
          "floorName"
        );
        setBuildingFloorOptions(options);
      }
    } catch (err: any) {
      handleError(err, "건물 층 정보 조회");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchEmergencyBuildingGroup = async (evacuationGroupId: string) => {
    try {
      setIsLoading(true);
      const response = await getEmergencyBuildingGroupCompanyEnabled(
        evacuationGroupId
      );
      if (response.status === HttpStatusCode.Ok) {
        setEmergencyBuilding(response.data.buildingList);
      } else {
        showAlert("그룹 조회에 실패 했습니다.");
      }
    } catch (error: any) {
      handleError(error, "비상 대피 그룹 조회");
    } finally {
      setIsLoading(false);
    }
  };

  const handleEmergencyGroupChange = (item: EmergencyBuildingGroup) => {
    if (item.evacuationGroupId) {
      setSelectedTag(item);
      fetchEmergencyBuildingGroup(item.evacuationGroupId);
      handleSituationChange({
        evacuationGroupId: item.evacuationGroupId,
        incidentBuildingId: undefined,
        incidentFloor: undefined,
        evacuationCategoryCode: undefined,
      });
      fetchGroupUserData(item.evacuationGroupId);
    } else {
      console.error(
        "EmergencyBuilding ID is not found while selecting building."
      );
    }

    setEvacueeCheckedList([]);
  };

  const handleFloorChange = (buildingFloorId: string) => {
    if (buildingFloorId) {
      handleSituationChange({
        incidentFloor: buildingFloorId,
      });
    }
  };

  const fetchGroupUserData = async (groupId: string) => {
    setIsisUserLoading(true);
    try {
      const response = await getSituationGroupList(groupId);
      if (response.status === HttpStatusCode.Ok) {
        if (response.data.length > 0) {
          setEvacueeList((prev) => [
            ...prev.filter((item) => !item.evacuationGroupId),
            ...response.data,
          ]);
        } else {
          showAlert("등록된 인원이 없습니다.");
        }
      }
    } catch (e: any) {
      handleError(e, "조회");
    } finally {
      setIsisUserLoading(false);
    }
  };

  const isAllChecked = () => {
    const unCheckedList = paginatedEvacueeList.filter(
      (newEvacuee) =>
        !evacueeCheckedList.some(
          (checkedEvacuee) => checkedEvacuee === newEvacuee.evacueeId
        )
    );

    if (unCheckedList.length > 0) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    if (evacueeList) {
      const filteredList = evacueeList.filter((entry) => {
        return (
          entry.name.includes(searchQuery.name) &&
          entry.mobileNumber.includes(searchQuery.mobileNumber) &&
          (entry.evacuationCompanyName === undefined ||
          entry.evacuationCompanyName === null
            ? true
            : entry.evacuationCompanyName?.includes(searchQuery.companyName) ??
              false)
        );
      });
      const paginatedEvacueeList = filteredList.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
      );

      if (paginatedEvacueeList.length === 0 && currentPage > 1) {
        // 현재 보여줄 부분이 없다 -> 페이지를 하나 전꺼로 돌려줌
        const prePage = currentPage - 1;
        setCurrentPage(prePage);
      } else {
        // 원래대로
        const pageable = {
          totalPages: Math.max(
            1,
            Math.ceil(filteredList.length / itemsPerPage)
          ),
          page: currentPage,
          totalCount: filteredList.length,
        };
        setPageable(pageable);
        setPaginatedEvacueeList(paginatedEvacueeList);
      }
    }
  }, [evacueeList, searchTriggered, currentPage]);

  return (
    <>
      {progress && <Spinner />}
      <div className="flex flex-col gap-5 min-w-[700px]">
        <div>
          <FormHeader title="비상대피 그룹 선택" />
          <FormRow>
            <FormRowLabel title="비상대피 건물 그룹" isRequired>
              <TagEmergencyFilter
                selectType="EmergencyBuilding"
                selectedItem={selectedTag}
                onChangeEmergencyBuilding={handleEmergencyGroupChange}
              />
            </FormRowLabel>
          </FormRow>
          <FormHeader title="상세 정보" />
          <FormRow>
            <FormRowLabel title="상황명 입력" isRequired>
              <div className="flex flex-col">
                <div className="flex gap-2 items-center">
                  <DefaultInput
                    value={situation.evacuationName}
                    onChange={(value: string) => {
                      handleSituationChange({ evacuationName: value });
                    }}
                    placeholder="상황명을 입력해주세요"
                    minWidth="w-[435px]"
                  />
                </div>
              </div>
            </FormRowLabel>
          </FormRow>
          <FormRow>
            <FormRowLabel title="상황 발생 주소" isRequired>
              <div className="flex flex-col">
                <div className="flex gap-2 items-center">
                  <DefaultInput
                    value={situation.incidentAddress}
                    onChange={(value: string) => {
                      handleSituationChange({ incidentAddress: value });
                    }}
                    placeholder="주소를 입력해주세요"
                    minWidth="w-[435px]"
                  />
                </div>
              </div>
            </FormRowLabel>
          </FormRow>
          <FormRow>
            <FormRowLabel title="상황 유형" isRequired>
              <DefaultSelect
                value={situation.evacuationCategoryCode}
                optionList={radioBoxSituationType}
                onChange={(value: string) => {
                  handleSituationChange({ evacuationCategoryCode: value });
                }}
                placeholder="상황 유형을 선택해주세요"
              />
            </FormRowLabel>
          </FormRow>
          <FormRow>
            <FormRowLabel title="상황 발생 건물" isRequired>
              <DefaultSelect
                value={situation.incidentBuildingId}
                optionList={toComboBoxType(
                  emergencyBuilding!,
                  "buildingId",
                  "buildingName"
                )}
                onChange={(value: string) => {
                  handleSituationChange({
                    incidentBuildingId: value,
                    incidentFloor: undefined,
                  });
                  if (value === DEFAULT_SELECT_ALL_VALUE) {
                    setBuildingFloorOptions([]);
                  } else {
                    fetchFloorData(value);
                  }
                }}
                placeholder="건물을 선택해주세요"
              />
            </FormRowLabel>
          </FormRow>
          <FormRow>
            <FormRowLabel title="상황 발생 층" isRequired>
              <DefaultSelect
                value={situation.incidentFloor}
                onChange={handleFloorChange}
                optionList={buildingFloorOptions}
                placeholder="층을 선택해주세요"
              />
            </FormRowLabel>
          </FormRow>
          {situation.evacuationCategoryCode === "fire" && (
            <>
              <FormHeader title="Push 전송" />
              <FormRow>
                <FormRowLabel title="Push 전송 간격(단계별)" isRequired>
                  <div className="flex flex-row gap-3 items-center">
                    <DefaultSelect
                      value={situation.pushStepInterval?.toString()}
                      optionList={radioBoxSituationPushLevel}
                      onChange={(value: string) => {
                        handleSituationChange({
                          pushStepInterval: Number(value),
                        });
                      }}
                      placeholder="전송 간격을 선택해주세요"
                    />
                    <div className="text-sm text-[#D95050]">
                      *상황 발생 후 3단계에 걸쳐 유저에게 Push가 전송됩니다.
                    </div>
                  </div>
                </FormRowLabel>
              </FormRow>
              <FormRow>
                <FormRowLabel title="Push 전송 간격(무응답)" isRequired>
                  <DefaultSelect
                    value={situation.pushNoResponseInterval?.toString()}
                    optionList={radioBoxSituationPushNoResponse}
                    onChange={(value: string) => {
                      handleSituationChange({
                        pushNoResponseInterval: Number(value),
                      });
                    }}
                    placeholder="전송 간격을 선택해주세요"
                  />
                </FormRowLabel>
              </FormRow>
            </>
          )}
          {/* 검색 필드 */}
          <FormRow className="border-white">
            <div className="flex flex-col">
              <FormRowLabel title="참여 인원 검색">
                <div className="flex flex-row gap-2 mr-2">
                  <DefaultInput
                    value={searchQuery.name}
                    onChange={(value) =>
                      setSearchQuery({ ...searchQuery, name: value })
                    }
                    placeholder="이름 검색"
                    minWidth="w-[160px]"
                    label="이름"
                  />
                  <DefaultInput
                    value={searchQuery.mobileNumber}
                    onChange={(value) =>
                      setSearchQuery({ ...searchQuery, mobileNumber: value })
                    }
                    placeholder="전화번호 검색"
                    minWidth="w-[160px]"
                    label="전화번호"
                  />
                  <DefaultInput
                    value={searchQuery.companyName}
                    onChange={(value) =>
                      setSearchQuery({ ...searchQuery, companyName: value })
                    }
                    placeholder="회사명 검색"
                    minWidth="w-[160px]"
                    label="회사"
                  />
                </div>
                <div className="flex gap-2 pt-7">
                  <DefaultButton
                    className="w-[70px] h-[38px]"
                    onClick={handleSearch}
                    size="tableButton"
                  >
                    조회
                  </DefaultButton>
                  <DefaultButton
                    className="w-[70px] h-[38px] !bg-brand-primary-gray-100 !bg-opacity-10 rounded-none text-sm font-semibold !text-brand-text-black-disabled"
                    onClick={handleReset}
                    size="tableButton"
                  >
                    초기화
                  </DefaultButton>
                </div>
              </FormRowLabel>
              <div className="flex flex-row border-white justify-between pt-4 pl-2">
                <div className="flex flex-row gap-3 items-center">
                  <div>상황 참여 인원 목록</div>
                  <span className="rounded-full bg-neutral-85 px-2.5 text-xs text-white font-medium">
                    {pageable.totalCount + " / " + evacueeList.length}
                  </span>
                </div>
                <div className="flex flex-row gap-2">
                  <DefaultButton
                    color="default"
                    size="tableTop"
                    onClick={() => {
                      if (!situation.evacuationGroupId) {
                        showAlert("비상대피 건물 그룹을 먼저 선택 해주세요.");
                      } else {
                        setIsOpen(true);
                      }
                    }}
                  >
                    인원 등록
                  </DefaultButton>
                  <EmergencyModal
                    addToList={addToList}
                    isOpen={isOpen}
                    onCancel={() => setIsOpen(false)}
                    content={<CreateEvacuee />}
                    emergencyBuildingList={emergencyBuilding}
                  />
                  <ExcelFileUploaderButton
                    header={
                      <div className="flex flex-row items-center">
                        <div>파일업로드</div>
                        <div className="text-sm text-[#D95050] font-normal ml-4">
                          *등록되는 인원이 비회원인 경우 권한은 일반 사용자로
                          변경됩니다.
                        </div>
                      </div>
                    }
                    title="인원"
                    getExcelDataApi={getEvacueeBulkExcelForm}
                    onJsonResult={async (data: any) => {
                      try {
                        const mappedData = data.map((item: any) => {
                          return {
                            evacueeId: `temp_${tempDataCount.current++}`,
                            name: String(item["*이름"]),
                            evacuationBuildingId: String(
                              item["*비상대피 건물 ID"]
                            ),
                            evacuationBuildingName: String(
                              emergencyBuilding?.find(
                                (building) =>
                                  building.buildingId ===
                                  item["*비상대피 건물 ID"]
                              )?.buildingName ?? "-"
                            ),
                            evacuationCompanyId: String(
                              item["*비상대피 회사 ID"]
                            ),
                            evacuationCompanyName: String(
                              emergencyBuilding
                                ?.find(
                                  (building) =>
                                    building.buildingId ===
                                    item["*비상대피 건물 ID"]
                                )
                                ?.companyShelterMapDtoList.find(
                                  (company) =>
                                    company.companyId ===
                                    item["*비상대피 회사 ID"]
                                )?.companyName ?? "-"
                            ),
                            evacuationFloorId: String(item["*층ID"]),
                            evacuationDepartmentName: String(item["부서명"]),
                            mobileNumber: String(item["*전화번호"]),
                            role: "ROLE_EV_USER",
                            type: "MANUAL",
                          };
                        });
                        setSearchQuery(initSearchQuery);
                        setCurrentPage(1);
                        setEvacueeCheckedList([]);
                        setEvacueeList((prevList) => [
                          ...mappedData,
                          ...prevList,
                        ]);
                      } catch (e: any) {
                        handleError(e, "인원 일괄 업로드");
                      }
                      return {
                        status: HttpStatusCode.Ok,
                        data: {},
                      } as unknown as AxiosResponse<void>;
                    }}
                    anotherPopup
                  />
                  <DefaultButton
                    color="default"
                    size="tableTop"
                    onClick={() => {
                      setEvacueeList((prevList) =>
                        prevList.filter(
                          (entry) =>
                            !evacueeCheckedList.includes(entry.evacueeId)
                        )
                      );
                      setEvacueeCheckedList([]);
                    }}
                  >
                    삭제
                  </DefaultButton>
                </div>
              </div>
            </div>
          </FormRow>
          <FormRow className="!p-0">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>
                    <CommonCheckbox
                      checked={
                        paginatedEvacueeList.length > 0 && isAllChecked()
                      }
                      onClick={() => {
                        if (isAllChecked()) {
                          setEvacueeCheckedList((prev) => {
                            const newList = prev.filter(
                              (prevCheckedId) =>
                                !paginatedEvacueeList.some(
                                  (user) => user.evacueeId === prevCheckedId
                                )
                            );
                            return newList;
                          });
                        } else {
                          // 기존 체크 + 중복 제거된 리스트 추가
                          setEvacueeCheckedList((prev) => {
                            const newAddList = paginatedEvacueeList
                              .filter(
                                (user) =>
                                  !prev.some(
                                    (prevId) => user.evacueeId === prevId
                                  )
                              )
                              .map((user) => user.evacueeId); // 기존 체크에 없는 애들만 골라냄

                            return [...prev, ...newAddList];
                          });
                        }
                      }}
                    />
                  </TableHead>
                  <TableHead className="text-center">이름</TableHead>
                  <TableHead className="text-center">건물</TableHead>
                  <TableHead className="text-center">회사</TableHead>
                  <TableHead className="text-center">부서</TableHead>
                  <TableHead className="text-center">인원유형</TableHead>
                  <TableHead className="text-center">전화번호</TableHead>
                  <TableHead className="text-center">권한</TableHead>
                  <TableHead></TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {paginatedEvacueeList.map((entry, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <CommonCheckbox
                        checked={evacueeCheckedList.includes(entry.evacueeId)}
                        onClick={() => {
                          setEvacueeCheckedList((prevCheckedList) =>
                            prevCheckedList.includes(entry.evacueeId)
                              ? prevCheckedList.filter(
                                  (id) => id !== entry.evacueeId
                                )
                              : [...prevCheckedList, entry.evacueeId]
                          );
                        }}
                      />
                    </TableCell>
                    <TableCell className="text-center">{entry.name}</TableCell>
                    <TableCell className="text-center">
                      {entry.evacuationBuildingName}
                    </TableCell>
                    <TableCell className="text-center">
                      {entry.evacuationCompanyName}
                    </TableCell>
                    <TableCell className="text-center">
                      {entry.evacuationDepartmentName}
                    </TableCell>
                    <TableCell className="text-center">
                      {renderEvacueeType(entry)}
                    </TableCell>
                    <TableCell className="text-center">
                      {entry.mobileNumber}
                    </TableCell>
                    <TableCell className="text-center">
                      {getShownEvacueeType(entry.role)}
                    </TableCell>
                    {/* 상황 등록시에는 권한변경 미노출 요청 SAN-60 */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </FormRow>
          <FormRow className="flex justify-center">
            <Pagination
              totalPages={pageable.totalPages}
              currentPage={pageable.page}
              onPageChange={(page) => setCurrentPage(page)}
              pageLimit={itemsPerPage}
            />
          </FormRow>
        </div>
        <div className="flex justify-center gap-2 py-5">
          <DefaultButton onClick={closePopup}>닫기</DefaultButton>
          <DefaultButton color="primary" onClick={handlePost}>
            등록
          </DefaultButton>
        </div>
      </div>
    </>
  );
}
