type Props = {};
export default function UseYesIcon(props: Props) {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.5" width="20" height="20" rx="2" fill="#194BDC" />
      <path
        d="M7.53613 6.22266L9.55566 9.74609H9.6416L11.6719 6.22266H13.0146L10.1895 10.9492V14H9.00781V10.9492L6.19336 6.22266H7.53613Z"
        fill="white"
      />
    </svg>
  );
}
