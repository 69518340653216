import React, { useState } from "react";
import { PostComment } from "../../../types/comment";
import { Pageable } from "../../../types/pageable";
import { DASH } from "../../../types/search";
import DefaultButton from "../../DefaultButton";
import { SearchCondition } from "../../ListPage";
import SortButton from "../../Table/SortButton";
import WarningCircleIcon from "../../../svgs/icons/Common/WarningCircle";

const EMPTY_DATA_TEXT = "등록된 댓글이 없습니다.";

export interface CommentTableColumnType<T> {
  header: string;
  name?: keyof T;
  render?: (
    item: T,
    replyComment?: (id: number, newItem: T) => void
  ) => React.ReactNode;
  sortable?: boolean;
  defaultNo?: boolean | { ascending: boolean };
}

interface Props<T> {
  searchCondition?: SearchCondition<T>;
  setSearchCondition?: React.Dispatch<React.SetStateAction<SearchCondition<T>>>;
  tableTitle?: string;
  renderTopRight?: React.ReactNode;
  defaultPageSizeList?: number[];
  excelDownloadApi?: (searchCondition: SearchCondition<T>) => void;
  total?: number;
  data: T[];
  columnInfo: CommentTableColumnType<T>[];
  pageable?: Pageable;
  defaultContentText?: string;
}

export default function Table<T extends PostComment>({
  searchCondition,
  setSearchCondition,
  tableTitle,
  renderTopRight,
  defaultPageSizeList,
  excelDownloadApi,
  total,
  data,
  columnInfo = [],
  pageable,
  defaultContentText = EMPTY_DATA_TEXT,
}: Props<T>) {
  const [newItems, setNewItems] = useState<Record<number, T>>({});

  const replyComment = (id: number, newItem: T) => {
    const data = newItems.hasOwnProperty(id) ? {} : { [id]: newItem };
    setNewItems(data);
  };

  const getTdClassName =
    "border border-gray-300 border-l-0 border-r-0 min-h-[70px] text-sm py-5 max-w-[350px]";

  const renderHeader = (header: string) => {
    const lines = header.split("\n");
    return (
      <>
        {lines.map((line, index) => (
          <React.Fragment key={index}>
            {line}
            <br />
          </React.Fragment>
        ))}
      </>
    );
  };

  return (
    <div className="w-full bg-white">
      <div className="flex justify-between gap-2.5 px-5">
        <div className="text-lg font-semibold flex items-center gap-2.5 flex-row flex-wrap">
          {tableTitle}
          <span className="rounded-full bg-neutral-85 px-2.5 text-xs text-white font-medium">
            {total}
          </span>
        </div>
        <div className="flex gap-2 items-center">
          {renderTopRight}
          <div className="flex">
            <select
              className="border border-gray-200 px-4 h-[30px] text-brand-text-black-disabled text-label"
              value={searchCondition?.pageSize}
              onChange={(e) => {
                if (setSearchCondition) {
                  setSearchCondition((prev) => ({
                    ...prev,
                    page: 1,
                    pageSize: parseInt(e.target.value),
                  }));
                }
              }}
            >
              {defaultPageSizeList?.map((value, index) => (
                <option key={index} value={value}>
                  {value}
                </option>
              ))}
            </select>
          </div>
          {excelDownloadApi && (
            <DefaultButton
              color="default"
              size="tableTop"
              onClick={() => {
                if (window.confirm("Excel 파일을 다운로드 하시겠습니까?")) {
                  excelDownloadApi(searchCondition ?? {});
                }
              }}
            >
              Excel 다운로드
            </DefaultButton>
          )}
        </div>
      </div>

      <div className="max-h-96 overflow-y-auto">
        <table className="w-full text-center">
          <thead className="bg-white">
            <tr className="h-10 text-xs text-neutral-60">
              {columnInfo.map((column, index) => (
                <th key={index} className="!font-normal">
                  <div className="flex items-center gap-2 justify-center min-w-fit">
                    {renderHeader(column.header)}
                    {column.sortable && column.name && (
                      <SortButton
                        name={column.name as keyof T}
                        searchCondition={searchCondition}
                        setSearchCondition={setSearchCondition}
                      />
                    )}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.length > 0 ? (
              data.map((item, rowIndex) => (
                <React.Fragment key={(item as any).lifeCommentId || rowIndex}>
                  <tr className="min-h-[70px]">
                    {columnInfo.map((column, index) => (
                      <td key={index} className={getTdClassName}>
                        {column.render
                          ? column.render(item, replyComment)
                          : column.name
                          ? String(item[column.name] ?? DASH)
                          : DASH}
                      </td>
                    ))}
                  </tr>
                  {newItems[item.lifeCommentId] && (
                    <tr>
                      {columnInfo.map((column, index) => (
                        <td key={index} className={getTdClassName}>
                          {column.render
                            ? column.render(
                                newItems[item.lifeCommentId] || {},
                                replyComment
                              )
                            : column.name
                            ? String(
                                newItems[item.lifeCommentId][column.name] ??
                                  DASH
                              )
                            : DASH}
                        </td>
                      ))}
                    </tr>
                  )}
                  {item.commentList &&
                    item.commentList.map((reply, index) => (
                      <tr key={index}>
                        {columnInfo.map((column, index) => (
                          <td key={index} className={getTdClassName}>
                            {column.render
                              ? column.render(reply as T, replyComment)
                              : column.name
                              ? String((reply as T)[column.name] ?? DASH)
                              : DASH}
                          </td>
                        ))}
                      </tr>
                    ))}
                </React.Fragment>
              ))
            ) : (
              <tr>
                <td
                  className="text-center py-[30px] text-gray-400"
                  colSpan={columnInfo.length}
                >
                  <span className="flex flex-col gap-2.5 h-full justify-center items-center">
                    <WarningCircleIcon />
                    <span className="text-sm text-black text-opacity-60">
                      {defaultContentText}
                    </span>
                  </span>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
