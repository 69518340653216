import { SearchCondition } from "../components/ListPage";
import request, { ListResponse } from "../libs/request";
import { AppVersion, Batch } from "../types/system";
import { getExcelDownloadApi } from "./commonService";

const BATCH_API_PREFIX = "/v2/admin/batch";
const APP_VERSION_API_PREFIX = "/v2/admin/etc/app/version";

export const getBatchList = (params: SearchCondition<Batch>) =>
  request.get<ListResponse<Batch>>(BATCH_API_PREFIX + "/list", { params });

export const getBatchListExcel = (params: SearchCondition<Batch>) =>
  getExcelDownloadApi(BATCH_API_PREFIX + "/list/excel", params);

export const postBatch = (jobGroup: string, jobName: string) =>
  request.post(BATCH_API_PREFIX + `/${jobGroup}/${jobName}`);

export const getAppVersionList = (params: SearchCondition<AppVersion>) =>
  request.get<ListResponse<AppVersion>>(APP_VERSION_API_PREFIX, { params });

export const getAppVersion = (appVersionId: number) =>
  request.get<AppVersion>(APP_VERSION_API_PREFIX + `/${appVersionId}`);

export const postAppVersion = (data: Partial<AppVersion>) =>
  request.post(APP_VERSION_API_PREFIX, data);

export const patchAppVersion = (
  appVersionId: number,
  data: Partial<AppVersion>
) => request.patch(APP_VERSION_API_PREFIX + `/${appVersionId}`, data);

export const getAppVersionNewestInfo = () =>
  request.get<AppVersion[]>(APP_VERSION_API_PREFIX + "/os");

export const deleteAppVersionForce = (appVersionId: number) =>
  request.delete(APP_VERSION_API_PREFIX + `/force/${appVersionId}`);
