import { ImageType } from "./incommodity";
import { ComboBoxType } from "./search";

export type Banner = {
  etcBannerId: number;
  location: string;
  title: string;
  linkType: string;
  linkUrl: string;
  status: boolean;
  isExpose: boolean;
  exposeStartAt: string;
  exposeEndAt: string;
  sortOrder: number;
  buildingId: string;
  buildingName: string;
  companyId: string;
  createdAt: string;
  createdBy: number;
  createdByName: string;
  modifiedAt: string;
  modifiedBy: number;
  modifiedByName: string;
  file: ImageType;
  uploadFile: File;
  dateRangeRadio: string;

  tagFilter: string;
};

export type BannerCreateType = {
  uploadFile: File;
  bannerCreateDto: Partial<Banner>;
};

export type BannerModifyType = {
  uploadFile: File;
  bannerModifyDto: Partial<Banner>;
};

export const radioBoxBannerExpose: ComboBoxType[] = [
  { value: "true", label: "노출" },
  { value: "false", label: "비노출" },
];

export enum BannerLinkTypeEnum {
  CURRENT = "CURRENT",
  NEW = "NEW",
  NULL = "NULL",
}

export type BannerLinkType = "CURRENT" | "NEW" | "NULL";

export const bannerLinkTypeMap: Record<BannerLinkType, string> = {
  CURRENT: "현재창",
  NEW: "새창",
  NULL: "링크 없음",
};

export enum BannerLocationEnum {
  MAINROLL = "MAINROLL",
  MAINFIX = "MAINFIX",
  BUILDING = "BUILDING",
  TALK = "TALK",
  MYINFO = "MYINFO",
}

export type BannerLocation =
  | "MAINROLL"
  | "MAINFIX"
  | "BUILDING"
  | "TALK"
  | "MYINFO";

export const bannerLocationMap: Record<BannerLocation, string> = {
  MAINROLL: "메인 롤링",
  MAINFIX: "메인 고정",
  BUILDING: "건물별",
  TALK: "샌디톡",
  MYINFO: "내정보",
};

export const BANNER_LOCATION_OPTIONS: ComboBoxType[] = [
  { value: "MAINROLL", label: "메인 롤링" },
  { value: "MAINFIX", label: "메인 고정" },
  { value: "BUILDING", label: "건물별" },
  { value: "TALK", label: "샌디톡" },
  { value: "MYINFO", label: "내정보" },
];

export const radioBoxBannerLinkType: ComboBoxType[] = [
  { value: BannerLinkTypeEnum.CURRENT, label: "자창" },
  { value: BannerLinkTypeEnum.NEW, label: "새창" },
  { value: BannerLinkTypeEnum.NULL, label: "링크없음" },
];
