import { HttpStatusCode } from "axios";
import { useContext, useState } from "react";

import {
  BuildingFloor,
  BuildingGroup,
  TagSelectedItem,
  buildingGroupCategoryCodeValue,
} from "../../../types/building";
import {
  IncommodityCategoryList,
  IncommodityCreateDto,
} from "../../../types/incommodity";
import {
  SCHEDULE_TYPES,
  SCHEDULE_TYPES_OPTIONS,
} from "../../../types/comboBoxOption";

import {
  validationFunctions,
  validationResultMessage,
} from "../../../libs/validations";
import { toComboBoxType } from "../../../utils/comboBoxUtils";
import { createIncommodityCreateData } from "../../../libs/incommodity";

import { useModal } from "../../../contexts/Modal";
import { PopupContext } from "../../../contexts/Popup";

import { getBuildingFloor } from "../../../services/buildingService";
import {
  getBuildingCategoryList,
  postIncommodity,
} from "../../../services/incommodityService";

import useMemberSelector from "../../../hooks/useMemberSelector";

import DefaultButton from "../../DefaultButton";
import DefaultTextArea from "../../DefaultTextArea";
import SingleImageUploader from "../../FileUploader/ImageFileUploader/SingleImageFileUploader";
import FormHeader from "../../Form/Header";
import FormRow from "../../Form/Row";
import FormRowLabel from "../../Form/RowLabel";
import DefaultInput from "../../Input/DefaultInput";
import RadioButtonGroup from "../../Input/RadioButtonGroup";
import DefaultSelect from "../../SelectBox/DefaultSelect";
import Spinner from "../../Spinner";
import TagSingleFilter from "../../TagFilter/TagSingleFilter";
import IncommodityCategorySelect from "../CategorySelect";
import DateTimePicker from "../../DateTime/DateTimePicker";
import PermissionWrapper from "../../PermissionWrapper";

type Props = {};

const LOCATION_TEXT_LENGTH = 20;
const DESCRIPTION_TEXT_LENGTH = 500;

const allOptionLabels: string[] = [
  "유형을 선택해주세요",
  "세부유형을 선택해주세요",
];

const getTomorrowAt4PM = (): Date => {
  const date = new Date();
  date.setDate(date.getDate() + 1);
  date.setHours(16, 0, 0, 0);
  return date;
};

export default function IncommodityCreateForm(props: Props) {
  const { showAlert, showConfirm, handleError } = useModal();
  const { closePopup, refreshAndClosePopup } = useContext(PopupContext);

  const [incommodity, setIncommodity] = useState<Partial<IncommodityCreateDto>>(
    {}
  );
  const [imageFile, setImageFile] = useState<File>();
  const [scheduleRadio, setScheduleRadio] = useState(SCHEDULE_TYPES.NOW);
  const [selectedBuilding, setSelectedBuilding] = useState<TagSelectedItem>();
  const [buildingFloor, setBuildingFloor] = useState<BuildingFloor[]>([]);
  const [category, setCategory] = useState("");
  const [date, setDate] = useState<Date>(getTomorrowAt4PM());
  const [isLoading, setIsLoading] = useState(false);
  const [categoryList, setCategoryList] = useState<IncommodityCategoryList>();

  // isMySelf => true:신청자본인, false:대신예약. 신청자 본인인 경우 memberId를 보내지 않음
  const [{ member, isConfirm, isSelf }, renderMemberSelector] =
    useMemberSelector();

  const handleChange = (data: Partial<IncommodityCreateDto>) => {
    setIncommodity((prev) => ({ ...prev, ...data }));
  };

  const handleScheduleRadioChange = (value: string) => {
    setScheduleRadio(value);

    if (value === SCHEDULE_TYPES.NOW) {
      handleChange({ deadLine: undefined });
      setDate(getTomorrowAt4PM());
    }
  };

  const handleCategoryChange = (categoryId: string) => {
    setCategory(categoryId);

    const id = Number(categoryId);
    handleChange({ serviceTemplateId: isNaN(id) ? 0 : id });
  };

  const fetchBuildingFloor = async (buildingId: string) => {
    if (!buildingId) {
      return;
    }

    try {
      setIsLoading(true);
      const response = await getBuildingFloor(buildingId);

      if (response.data) {
        setBuildingFloor(response.data);

        const buildingFloorId = response.data?.[0]?.buildingFloorId;
        handleChange({ buildingFloorId: buildingFloorId });
      }
    } catch (err: any) {
      handleError(err, "건물 층 정보 조회");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchBuildingCategoryList = async (buildingId: string) => {
    if (!buildingId) {
      return;
    }

    try {
      setIsLoading(true);
      const response = await getBuildingCategoryList(buildingId);

      if (response.data) {
        setCategoryList(response.data);
      }
    } catch (err: any) {
      handleError(err, "건물 불편 신고 카테고리 정보 조회");
    } finally {
      setIsLoading(false);
    }
  };

  const createFormData = () => {
    const formData = new FormData();

    if (imageFile) {
      formData.append("uploadFiles", imageFile);
    }

    const data: Partial<IncommodityCreateDto> = createIncommodityCreateData(
      isSelf,
      member,
      incommodity,
      scheduleRadio,
      date
    );

    formData.append("incommodityCreateDto", JSON.stringify(data));
    return formData;
  };

  const postData = async () => {
    try {
      const response = await postIncommodity(createFormData());
      if (response.status === HttpStatusCode.Ok) {
        showAlert("등록 되었습니다.");
        refreshAndClosePopup();
      } else {
        throw new Error("Failed to post data");
      }
    } catch (err: any) {
      handleError(err, "등록");
    }
  };

  const handlePost = () => {
    if (isConfirm === false) {
      showAlert("회원 정보를 조회해주세요.");
      return;
    }

    const errorMessage = validationResultMessage([
      validationFunctions.required(incommodity.buildingId, "건물"),
      validationFunctions.required(incommodity.buildingFloorId, "층"),
      validationFunctions.required(incommodity.serviceTemplateId, "유형"),
      validationFunctions.required(incommodity.location, "상세 장소"),
      validationFunctions.required(incommodity.description, "상세 내용"),
    ]);

    if (errorMessage) {
      showAlert(errorMessage);
    } else {
      showConfirm("등록 하시겠습니까?", postData);
    }
  };

  return (
    <div className="flex flex-col gap-5 min-w-[900px]">
      {isLoading && <Spinner />}
      <div className="min-w-[700px] max-h-popup overflow-y-auto">
        <FormHeader title="접수 정보" />
        <FormRow>
          <FormRowLabel title="신청 채널">모바일</FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="접수자 정보" isRequired>
            {renderMemberSelector}
          </FormRowLabel>
        </FormRow>

        <FormHeader title="신고 내용" />
        <FormRow>
          <FormRowLabel title="장소" isRequired>
            <div className="flex flex-col gap-2">
              <TagSingleFilter
                selectType="building"
                selectedItem={selectedBuilding}
                onChangeBuilding={(item: BuildingGroup) => {
                  if (item.buildingId !== null) {
                    setSelectedBuilding(item);
                    handleChange({
                      buildingId: item.buildingId,
                    });
                    fetchBuildingFloor(item.buildingId);
                    fetchBuildingCategoryList(item.buildingId);
                  } else {
                    showAlert("건물을 선택해주세요.");
                  }
                }}
                categoryCode={buildingGroupCategoryCodeValue.INCOMMODITY}
              />
              <DefaultSelect
                value={incommodity.buildingFloorId}
                optionList={toComboBoxType(
                  buildingFloor,
                  "buildingFloorId",
                  "floorName"
                )}
                onChange={(value: string) =>
                  handleChange({ buildingFloorId: value })
                }
                placeholder="층 선택"
              />
            </div>
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="유형" isRequired>
            <IncommodityCategorySelect
              value={category}
              onChange={handleCategoryChange}
              data={categoryList ?? {}}
              allOptionLabels={allOptionLabels}
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="상세 장소" isRequired>
            <DefaultInput
              value={incommodity.location}
              placeholder="정확한 위치를 입력해주세요"
              maxLength={LOCATION_TEXT_LENGTH}
              onChange={(value: string) => handleChange({ location: value })}
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="상세 내용" isRequired>
            <DefaultTextArea
              value={incommodity.description}
              maxLength={DESCRIPTION_TEXT_LENGTH}
              onChange={(value: string) => handleChange({ description: value })}
              placeholder="상세 내용을 입력해주세요"
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="참고 이미지">
            <SingleImageUploader image={imageFile} onChange={setImageFile} />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="요청 일정">
            <div className="flex flex-col gap-2">
              <RadioButtonGroup
                options={SCHEDULE_TYPES_OPTIONS}
                value={scheduleRadio}
                onChange={handleScheduleRadioChange}
              />
              {scheduleRadio === SCHEDULE_TYPES.CHANGE && (
                <DateTimePicker
                  date={date}
                  onDateChange={setDate}
                  minuteStep={30}
                />
              )}
            </div>
          </FormRowLabel>
        </FormRow>
      </div>

      <div className="flex justify-center gap-2">
        <DefaultButton onClick={closePopup}>닫기</DefaultButton>
        <PermissionWrapper>
          <DefaultButton color="primary" onClick={handlePost}>
            접수
          </DefaultButton>
        </PermissionWrapper>
      </div>
    </div>
  );
}
