import { useCallback, useEffect, useState } from "react";
import { useModal } from "../../../../contexts/Modal";
import { generateAxiosResponse } from "../../../../libs/request";
import { validationFunctions } from "../../../../libs/validations";
import { getAtgResource } from "../../../../services/atgService";
import { AtgResource } from "../../../../types/atg";
import { ComponentType } from "../../../../types/search";
import DefaultButton from "../../../DefaultButton";
import DefaultInput from "../../../Input/DefaultInput";
import ListPage, { SearchCondition } from "../../../ListPage";
import { TableColumnType } from "../../../Table";
import { SearchTagData } from "../../../TagFilter";
import { TagData } from "../../../TagList";
import { RightArrowIcon } from "../../../TagSelector";

export type AtgResourceTag = AtgResource & {
  rowIndex: number;
};

type Props = {
  atgBuildingId: string;
  resourceTags: SearchTagData;
  setResourceTags: (value: SearchTagData) => void;
};

export default function IncommodityAtgResourceSelector({
  atgBuildingId,
  resourceTags,
  setResourceTags,
}: Props) {
  const { handleError } = useModal();
  const [selectedData, setSelectedData] = useState<AtgResource[]>(
    resourceTags?.data?.map((item) => item.data) ?? []
  );

  useEffect(() => {
    const left = resourceTags.data?.map((item) => item.data);
    const right = selectedData;

    if (JSON.stringify(left) === JSON.stringify(right)) {
      return;
    }

    setResourceTags({
      data: selectedData.map((item) => getTagAtg(item)),
      type: "building",
    });
  }, [selectedData]);

  useEffect(() => {
    setSelectedData(resourceTags?.data?.map((tag) => tag.data) ?? []);
  }, [resourceTags]);

  const getTagAtg = (item: AtgResource) => {
    return {
      id: item.materialCode,
      name: item.resourceName,
      data: item,
    } as TagData;
  };

  const handleInventoryChange = (item: AtgResource, value: string) => {
    setSelectedData((prev) =>
      prev.map((dataItem) =>
        dataItem === item ? { ...dataItem, inventory: value } : dataItem
      )
    );
  };

  const handleVisible = (item: AtgResource) => {
    return selectedData.some(compareAtg(item));
  };

  const handleAdd = (item: AtgResource) => {
    setSelectedData((prev) => [item, ...prev]);
  };

  const handleRemove = (item: AtgResource) => {
    setSelectedData((prev) => prev.filter((o) => o !== item));
  };

  const compareAtg = (item: AtgResource) => (o: AtgResource) => {
    return getJsonString(item) === getJsonString(o);
  };

  const getJsonString = (data: AtgResource) => {
    const itemCopy = { ...data };
    delete (itemCopy as Partial<AtgResource>).inventory;
    return JSON.stringify(itemCopy);
  };

  const isNotEmptyInventory = (item: AtgResource) => {
    const inventory = Number(item.inventory);

    if (isNaN(inventory)) {
      console.error("투입자재 수량의 타입이 숫자가 아닙니다.");
    }

    return inventory > 0;
  };

  const renderAdd = (item: AtgResource) => {
    const visible = handleVisible(item);

    return (
      <div className="w-full h-3 flex gap-2 justify-center items-center">
        {!visible && isNotEmptyInventory(item) && (
          <DefaultButton
            onClick={() => {
              handleAdd({
                ...item,
                inventory: "",
              });
            }}
          >
            추가
          </DefaultButton>
        )}
      </div>
    );
  };

  const renderRemove = (item: AtgResource) => (
    <div className="w-full h-3 flex gap-2 justify-center items-center">
      <DefaultButton
        onClick={() => {
          handleRemove(item);
        }}
      >
        삭제
      </DefaultButton>
    </div>
  );

  const headerAddAll = (data: AtgResource[]) => {
    return (
      <DefaultButton
        color="white"
        className="h-[22px] text-xs font-normal"
        onClick={() => {
          setSelectedData((prev) => {
            const filteredData = data
              .filter(isNotEmptyInventory)
              .map((item) => ({ ...item, inventory: "" }));

            const newData = filteredData.filter(
              (item) => !prev.some(compareAtg(item))
            );
            return [...newData, ...prev];
          });
        }}
      >
        전체 선택
      </DefaultButton>
    );
  };

  const headerRemoveAll = () => {
    return (
      <DefaultButton
        color="white"
        className="h-[22px] text-xs font-normal"
        onClick={() => setSelectedData([])}
      >
        전체 해제
      </DefaultButton>
    );
  };

  const getAtgResourceList = useCallback(
    async (searchCondition: SearchCondition<AtgResource>) => {
      try {
        if (!searchCondition.resourceName) {
          return generateAxiosResponse([]);
        }

        const response = await getAtgResource(
          atgBuildingId,
          searchCondition.resourceName
        );

        return generateAxiosResponse(response.data);
      } catch (err: any) {
        handleError(err, "목록 조회");
        return err;
      }
    },
    [atgBuildingId]
  );

  const atgResourceComponents: ComponentType<AtgResource>[] = [
    {
      labelName: "자재명",
      typeName: "text",
      validation: [validationFunctions.required],
      keyName: "resourceName",
      placeholder: "자재명을 입력해주세요",
    },
  ];

  const atgResourceColumns: TableColumnType<AtgResource>[] = [
    { header: "센터", name: "centerName" },
    { header: "창고", name: "storageName" },
    { header: "자재명", name: "materialName" },
    { header: "규격", name: "standardName" },
  ];

  return (
    <>
      <div className={"flex flex-col"}>
        <div className="flex items-end">
          <div className={"min-w-[600px] w-full pb-4"}>
            <ListPage
              getDataApi={getAtgResourceList}
              columnInfo={[
                ...atgResourceColumns,
                { header: "재고", name: "inventory", width: "100" },
                {
                  header: "활동",
                  render: renderAdd,
                  headerRender: headerAddAll,
                },
              ]}
              componentList={atgResourceComponents}
              tableTitle={"투입자재 목록"}
              isTableScroll
              tableHeight="small"
              hidePagination
              hidePageSize
              isSmallButton
            />
          </div>
        </div>
        <div className="flex items-center justify-center">
          <RightArrowIcon directionDown />
        </div>
        <div className="flex items-end">
          <div className="min-w-[600px] w-full">
            <ListPage
              getDataApi={Promise.resolve}
              selectedData={selectedData}
              columnInfo={[
                ...atgResourceColumns,
                {
                  header: "재고",
                  render: (item: AtgResource) => (
                    <div className="flex justify-center items-center">
                      <DefaultInput
                        type="number"
                        value={item.inventory}
                        onChange={(value: string) =>
                          handleInventoryChange(item, value)
                        }
                        minWidth="w-[70px]"
                      />
                    </div>
                  ),
                },
                {
                  header: "활동",
                  render: renderRemove,
                  headerRender: headerRemoveAll,
                },
              ]}
              componentList={atgResourceComponents}
              tableTitle={`선택 투입자재 목록`}
              selectedMode
              isTableScroll
              tableHeight="small"
              hidePagination
              hidePageSize
            />
          </div>
        </div>
      </div>
    </>
  );
}
