import clsx from "clsx";
import React from "react";

type Props = { className?: string };

export default function HorizonRule({ className }: Props) {
  return (
    <div className={clsx(["w-full h-px bg-neutral-300", className])}></div>
  );
}
