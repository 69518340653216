import { HttpStatusCode } from "axios";
import { useContext, useEffect, useState } from "react";
import { useModal } from "../../../../../contexts/Modal";
import { PopupContext } from "../../../../../contexts/Popup";
import {
  validationFunctions,
  validationResultMessage,
} from "../../../../../libs/validations";
import {
  getGoodsRentalItem,
  putGoodsRentalItem,
} from "../../../../../services/goodsService";
import {
  GoodsRentalItem,
  goodsRentalItemLabels,
} from "../../../../../types/goods";
import {
  USAGE_OPTIONS,
  BOOLEAN_TYPES,
} from "../../../../../types/comboBoxOption";
import DefaultButton from "../../../../DefaultButton";
import DefaultTextArea from "../../../../DefaultTextArea";
import SingleImageUploader from "../../../../FileUploader/ImageFileUploader/SingleImageFileUploader";
import FormHeader from "../../../../Form/Header";
import FormRow from "../../../../Form/Row";
import FormRowLabel from "../../../../Form/RowLabel";
import DefaultInput from "../../../../Input/DefaultInput";
import DefaultLabel from "../../../../Input/DefaultLabel";
import RadioButtonGroup from "../../../../Input/RadioButtonGroup";
import Spinner from "../../../../Spinner";

const GOODS_RENTAL_ITEM_SERVICE_NAME = "대여 신청";

type Props = { goodsId: string };

export default function GoodsRentalItemDetailView({ goodsId }: Props) {
  const { showAlert, showConfirm, handleError } = useModal();
  const { closePopup, refreshAndClosePopup } = useContext(PopupContext);

  const [goods, setGoods] = useState<Partial<GoodsRentalItem>>({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const res = await getGoodsRentalItem(goodsId);
        if (res.status === HttpStatusCode.Ok) {
          setGoods(res.data);
        }
      } catch (err: any) {
        handleError(err, "조회");
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleGoodsChange =
    (key: keyof GoodsRentalItem) =>
    (value: string | boolean | File | undefined) => {
      setGoods((prev) => ({ ...prev, [key]: value }));
    };

  const handleGoodsChangeObject = (data: Partial<GoodsRentalItem>) => {
    setGoods((prev) => ({ ...prev, ...data }));
  };

  const handleUpdate = () => {
    const validations = [
      validationFunctions.required(goods.goodsTypeId, "물품종류"),
      validationFunctions.required(goods.goodsName, "물품명"),
    ];

    const errorMessage = validationResultMessage(validations);
    if (errorMessage) {
      showAlert(errorMessage);
    } else {
      showConfirm("수정 하시겠습니까?", updateData);
    }
  };

  const updateData = async () => {
    try {
      const res = await putGoodsRentalItem(goodsId, createFormData());
      if (res.status === HttpStatusCode.Ok) {
        showAlert("수정 되었습니다.");
        refreshAndClosePopup();
      }
    } catch (err: any) {
      handleError(err, "수정");
    }
  };

  const createFormData = () => {
    const formData = new FormData();

    const data: Partial<GoodsRentalItem> = {
      goodsName: goods.goodsName,
      goodsDescription: goods.goodsDescription,
      isActive: goods.isActive,
      isDelete: goods.isDelete,
    };

    goods.uploadFile && formData.append("uploadFile", goods.uploadFile);
    formData.append("params", JSON.stringify(data));

    return formData;
  };

  return (
    <div className="flex flex-col gap-5 min-w-[900px]">
      {isLoading && <Spinner />}
      <div className="flex flex-col max-h-[700px] overflow-y-auto">
        <FormHeader title="기본 정보" />
        <FormRow>
          <FormRowLabel title="물품 번호">
            <DefaultLabel text={goods.goodsId} />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <div className="flex w-full">
            <FormRowLabel title="등록 일시">
              <DefaultLabel text={goods.createdAt} />
            </FormRowLabel>
            <FormRowLabel title="최종 수정일시">
              <DefaultLabel text={goods.modifiedAt} />
            </FormRowLabel>
          </div>
        </FormRow>

        <FormHeader title="기준 정보" />
        <FormRow>
          <FormRowLabel title="서비스">
            <DefaultLabel text={GOODS_RENTAL_ITEM_SERVICE_NAME} />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="사용 유무">
            <RadioButtonGroup
              options={USAGE_OPTIONS}
              value={goods.isActive?.toString() ?? ""}
              onChange={(value: string) =>
                handleGoodsChange("isActive")(value === BOOLEAN_TYPES.TRUE)
              }
            />
          </FormRowLabel>
        </FormRow>

        <FormHeader title="물품 정보" />
        <FormRow>
          <FormRowLabel title="물품 종류" isRequired>
            <DefaultLabel
              text={
                goods.goodsTypeId && goodsRentalItemLabels[goods.goodsTypeId]
              }
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="물품명" isRequired>
            <DefaultInput
              value={goods.goodsName}
              onChange={handleGoodsChange("goodsName")}
              minWidth="w-full"
              maxLength={50}
              placeholder="물품명 입력"
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="대표 이미지">
            <SingleImageUploader
              image={goods.uploadFile}
              onChange={handleGoodsChange("uploadFile")}
              rightLabel="* 단건 등록"
              defaultImage={goods.image}
              onDelete={() =>
                handleGoodsChangeObject({ image: undefined, isDelete: true })
              }
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="물품 설명">
            <DefaultTextArea
              maxLength={500}
              rows={5}
              value={goods.goodsDescription}
              onChange={handleGoodsChange("goodsDescription")}
              placeholder="물품 설명을 입력해주세요"
            />
          </FormRowLabel>
        </FormRow>
      </div>

      <div className="flex justify-center gap-2">
        <DefaultButton onClick={closePopup}>닫기</DefaultButton>
        <DefaultButton color="primary" onClick={handleUpdate}>
          수정
        </DefaultButton>
      </div>
    </div>
  );
}
