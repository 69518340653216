import { useEffect, useState } from "react";
import { DASH } from "../../../../../types/search";
import { SpaceReservationData } from "../../../../../types/spaceReservation";
import { TableColumnType } from "../../../../Table";
import TotalTable from "../../../../Table/TotalTable";
import { getTimeInMinutes } from "../../../../../utils/dateUtils";
import { WeekDay } from "../../../../../types/space";

type TotalTableType = {
  name: string;
  price: number;
};

const initPayTotalTable: TotalTableType[] = [
  { name: "공간 이용", price: 0 },
  { name: "기기 이용", price: 0 },
];

type Props = {
  reservationDate: string;
  spaceReservationData: Partial<SpaceReservationData>;
  onChange: (data: Partial<SpaceReservationData>) => void;
};

const dayNames: (keyof WeekDay)[] = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];

export default function PayTotalTable({
  reservationDate,
  spaceReservationData,
  onChange,
}: Props) {
  const [data, setData] = useState<TotalTableType[]>([...initPayTotalTable]);

  const calculatePrice = () => {
    const data = spaceReservationData.spaceInfo ?? spaceReservationData;

    const dayName = dayNames[new Date(reservationDate ?? "").getDay()];
    const isDefaultPrice = data.defaultPriceDays?.[dayName];

    const sDate = spaceReservationData.reservationTimeStart ?? "";
    const eDate = spaceReservationData.reservationTimeEnd ?? "";
    const defaultSDate = data.defaultPriceTimeStart ?? "";
    const defaultEDate = data.defaultPriceTimeEnd ?? "";
    const usableTime = data.usableTimeStart ?? 0;
    const defaultPrice = data.defaultPrice ?? 0;
    const additionalPrice = data.additionalPrice ?? 0;

    const sTime = getTimeInMinutes(sDate);
    const eTime = getTimeInMinutes(eDate);
    const defaultSTime = getTimeInMinutes(defaultSDate);
    const defaultETime = getTimeInMinutes(defaultEDate);

    let defaultTime = 0;
    let additionalTime = 0;
    let premiumTime = 0;
    let premiumDefaultTime = 0;

    for (let time = sTime; time < eTime; time += 30) {
      if (isDefaultPrice && time >= defaultSTime && time < defaultETime) {
        if (defaultTime + premiumDefaultTime + additionalTime < usableTime) {
          defaultTime += 30;
        } else {
          additionalTime += 30;
        }
      } else {
        if (defaultTime + premiumDefaultTime + premiumTime < usableTime) {
          premiumDefaultTime += 30;
        } else {
          premiumTime += 30;
        }
      }
    }

    const defaultCost = (defaultTime / 30) * defaultPrice;
    const additionalCost = (additionalTime / 30) * additionalPrice;
    const premiumDefaultCost = (premiumDefaultTime / 30) * defaultPrice * 1.2;
    const premiumCost = (premiumTime / 30) * additionalPrice * 1.2;

    const totalCost =
      defaultCost + additionalCost + premiumCost + premiumDefaultCost;

    return Math.round(totalCost);
  };

  const calculateData = () => {
    const spacePrice = calculatePrice();

    const sTime = getTimeInMinutes(
      spaceReservationData.reservationTimeStart ?? ""
    );
    const eTime = getTimeInMinutes(
      spaceReservationData.reservationTimeEnd ?? ""
    );
    const totalTime = (eTime - sTime) / 30;

    const sourceList =
      spaceReservationData.spaceInfo?.rentalDeviceList.length ?? 0 > 0
        ? spaceReservationData.spaceInfo?.rentalDeviceList
        : spaceReservationData.rentalDeviceList;

    const devicePrice = Math.round(
      spaceReservationData.usePayment ||
        spaceReservationData.spaceInfo?.usePayment
        ? sourceList?.reduce((acc, item) => {
            if (item.checked) {
              return acc + (item.price ?? 0) * totalTime;
            }
            return acc;
          }, 0) ?? 0
        : 0
    );

    onChange({
      totalDeviceDefaultPrice: devicePrice.toString(),
      totalSpaceDefaultPrice: spacePrice.toString(),
      vatDefaultPrice: Math.round((devicePrice + spacePrice) * 0.1).toString(),
    });

    return [
      { name: "공간 이용", price: spacePrice },
      { name: "기기 이용", price: devicePrice },
    ];
  };

  useEffect(() => {
    const newData = calculateData();
    setData(newData);
  }, [
    spaceReservationData.spaceInfo?.rentalDeviceList,
    spaceReservationData.rentalDeviceList,
    spaceReservationData.reservationTimeStart,
    spaceReservationData.reservationTimeEnd,
  ]);

  const totalColumn: TableColumnType<TotalTableType>[] = [
    { header: "구분", name: "name" },
    {
      header: "소계",
      render: (item: TotalTableType) => (
        <span>{`${item.price.toLocaleString() ?? DASH}원`}</span>
      ),
    },
  ];

  return (
    <div className="max-w-screen-md">
      <TotalTable columns={totalColumn} data={data ?? []} totalColumn="price" />
    </div>
  );
}
