import { SearchCondition } from "../components/ListPage";
import request, { ListResponse } from "../libs/request";
import { Service, ServiceType } from "../types/service";
import {
  Template,
  TemplateConnection,
  TemplateParam,
  TemplateTargetPost,
} from "../types/template";
import { getExcelDownloadApi } from "./commonService";

const TEMPLATE_API_PREFIX = "/v2/admin/service/template";

export const getTemplateList = (params: SearchCondition<Template>) => {
  const { buildingId, companyId, ...otherParams } = params;

  const newParams = {
    ...otherParams,
    ...(buildingId && { buildingIds: buildingId.join(",") }),
    ...(companyId && { companyIds: companyId.join(",") }),
  };

  return request.get<ListResponse<Template>>(TEMPLATE_API_PREFIX + "/list", {
    params: newParams,
  });
};

export const getTemplateParamList = () =>
  request.get<TemplateParam[]>(TEMPLATE_API_PREFIX + "/param-list");

export const getTemplate = (serviceTemplateId: number) =>
  request.get<Template>(TEMPLATE_API_PREFIX + `/${serviceTemplateId}`);

export const putTemplate = (serviceTemplateId: number, formData: FormData) =>
  request.put(TEMPLATE_API_PREFIX + `/${serviceTemplateId}`, formData);

export const postTemplate = (formData: FormData) =>
  request.post(TEMPLATE_API_PREFIX, formData);

export const postTemplateTarget = (
  target: ServiceType,
  targetId: string,
  templateTargetPost: TemplateTargetPost
) =>
  request.post(
    TEMPLATE_API_PREFIX + `/${target}/${targetId}`,
    templateTargetPost
  );

export const getTemplateListTarget = (target: ServiceType, targetId: string) =>
  request.get<Service[]>(TEMPLATE_API_PREFIX + `/${target}/${targetId}/list`);

export const deleteTemplate = (targetId: string, serviceId: string) =>
  request.delete(TEMPLATE_API_PREFIX + `/${targetId}/${serviceId}`);

export const getTemplateConnectionList = (
  params: Partial<TemplateConnection>,
  serviceTemplateId: number
) =>
  request.get<ListResponse<TemplateConnection>>(
    TEMPLATE_API_PREFIX + `/${serviceTemplateId}/connection-list`,
    { params }
  );

export const getTemplateListExcel = (params: SearchCondition<Template>) => {
  const { buildingId, companyId, ...otherParams } = params;

  const newParams = {
    ...otherParams,
    ...(buildingId && { buildingIds: buildingId.join(",") }),
    ...(companyId && { companyIds: companyId.join(",") }),
  };

  return getExcelDownloadApi(TEMPLATE_API_PREFIX + "/list/excel", newParams);
};
