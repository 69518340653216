import { useContext, useEffect, useState } from "react";
import { HttpStatusCode } from "axios";

import { GENDER_TYPES } from "../../../../../types/comboBoxOption";
import { Member, getMemberRoleName } from "../../../../../types/member";

import { useModal } from "../../../../../contexts/Modal";
import { PopupContext } from "../../../../../contexts/Popup";
import { useUserContext } from "../../../../../contexts/User";

import {
  getMember,
  patchMemberCompanyVerification,
} from "../../../../../services/memberService";

import { useListPageContext } from "../../../../ListPage";
import DefaultButton from "../../../../DefaultButton";
import ImageThumbnail from "../../../../FileUploader/ImageFileUploader/ImageThumbnail";
import FormHeader from "../../../../Form/Header";
import FormRow from "../../../../Form/Row";
import FormRowLabel from "../../../../Form/RowLabel";
import HorizonRule from "../../../../HorizonRule";
import DefaultLabel from "../../../../Input/DefaultLabel";
import CommonPopup from "../../../../Popup/CommonPopup";
import RoleDetailView from "../RoleDetailView";
import Spinner from "../../../../Spinner";

import OffBadgeIcon from "../../../../../svgs/icons/Badge/Off";
import OnBadgeIcon from "../../../../../svgs/icons/Badge/On";

type Props = { userId: number };

type ErrorResponse = {
  response: {
    data: {
      code: number;
      error: string;
    };
  };
};

export default function MemberDetailView({ userId }: Props) {
  const { refreshListPage } = useListPageContext();
  const { showAlert, showConfirm, handleError } = useModal();
  const { userInfo, hasRole } = useUserContext();
  const { closePopup } = useContext(PopupContext);

  const [isOpen, setIsOpen] = useState(false);
  const [member, setMember] = useState<Partial<Member>>({});
  const [isLoading, setIsLoading] = useState(false);

  const isOwner = userInfo?.memberId === userId;

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const res = await getMember(userId);
      if (res.status === HttpStatusCode.Ok) {
        setMember(res.data);
      } else {
        throw new Error("Failed to load member info");
      }
    } catch (err: any) {
      handleError(err, "조회");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleRefresh = () => {
    fetchData();
    refreshListPage();
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClosePopup = () => {
    setIsOpen(false);
  };

  const handleCompanyCertificated = () => {
    showConfirm("회사 인증 하시겠습니까?", async () => {
      try {
        const res = await patchMemberCompanyVerification(userId);
        if (res.status === HttpStatusCode.Ok) {
          showAlert("회사 인증 되었습니다.");
          fetchData();
        }
      } catch (err) {
        if (err != null && (err as ErrorResponse).response.data.code === 506) {
          showAlert("회사 정보를 찾을 수 없습니다.");
        } else {
          showAlert("회사 인증 중 오류가 발생했습니다.");
        }
      }
    });
  };

  const renderSetting = (
    label: string,
    value: boolean = false,
    isLastItem: boolean = false
  ) => (
    <>
      <div className="flex">
        <div className="w-1/2">
          <DefaultLabel text={label} />
        </div>
        <div className="w-1/2">
          {value ? <OnBadgeIcon /> : <OffBadgeIcon />}
        </div>
      </div>
      {!isLastItem && <HorizonRule />}
    </>
  );

  const renderRoleButton = () => {
    if (!member?.role) {
      return null;
    }

    const isVisible =
      !isOwner &&
      (hasRole(["ROLE_ADMIN"]) ||
        (hasRole(["ROLE_SPACE"]) && member.role !== "ROLE_ADMIN"));

    return isVisible ? (
      <DefaultButton
        size="popupSmall"
        className="whitespace-nowrap !text-neutral-90"
        onClick={handleOpen}
      >
        권한변경
      </DefaultButton>
    ) : null;
  };

  return (
    <div className="flex flex-col gap-5">
      {isLoading && <Spinner />}
      <div className="max-h-[700px] min-w-[930px] overflow-y-auto">
        <FormHeader title="기본 정보" />
        <FormRow>
          <FormRowLabel title="가입일">
            <DefaultLabel text={member.createdAt} />
          </FormRowLabel>
          <FormRowLabel title="회원이름">
            <DefaultLabel
              text={member.name ?? ""}
              masking="BO_MEMBER_DETAIL"
              targetId={member.memberId ?? -1}
              property="NAME"
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="최종로그인">
            <DefaultLabel text={member.lastLoggedAt} />
          </FormRowLabel>
          <FormRowLabel title="회원 ID">
            <DefaultLabel
              text={member.email ?? ""}
              masking="BO_MEMBER_DETAIL"
              targetId={member.memberId ?? -1}
              property="EMAIL"
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="추천인 이메일">
            <DefaultLabel text={member.referrerEmail} />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="회원상태">
            <DefaultLabel text={member.status} />
          </FormRowLabel>
          <FormRowLabel title="회원 별명">
            <DefaultLabel text={member.nickname} />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="본인인증">
            <DefaultLabel
              text={member.isSelfCertificated ? "인증" : "미인증"}
            />
          </FormRowLabel>
          <FormRowLabel title="성별/전화번호">
            <div className="flex flex-col gap-1">
              <DefaultLabel
                text={
                  member.gender === GENDER_TYPES.MALE
                    ? "남"
                    : member.gender === GENDER_TYPES.FEMALE
                    ? "여"
                    : ""
                }
              />
              <DefaultLabel
                text={member.mobileNumber ?? ""}
                masking="BO_MEMBER_DETAIL"
                targetId={member.memberId ?? -1}
                property="MOBILE_NUMBER"
              />
            </div>
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="회사인증">
            <div className="flex justify-between items-center">
              <DefaultLabel
                text={member.isCompanyCertificated ? "인증" : "미인증"}
              />
              {!member.isCompanyCertificated && member.companyId && (
                <DefaultButton
                  size="popupSmall"
                  className="whitespace-nowrap !text-neutral-90"
                  onClick={handleCompanyCertificated}
                >
                  회사인증
                </DefaultButton>
              )}
            </div>
          </FormRowLabel>
          <FormRowLabel title="권한">
            <div className="flex justify-between items-center">
              <DefaultLabel text={getMemberRoleName(member.role ?? "")} />
              {renderRoleButton()}
            </div>
          </FormRowLabel>
        </FormRow>

        <FormHeader title="기타 정보" />

        <div className="flex">
          <div className="flex flex-col w-full">
            <FormRow>
              <FormRowLabel title="등록건물">
                <DefaultLabel text={member.buildingName} />
              </FormRowLabel>
            </FormRow>
            <FormRow>
              <FormRowLabel title="등록회사">
                <DefaultLabel text={member.companyName} />
              </FormRowLabel>
            </FormRow>
            <FormRow>
              <FormRowLabel title="이용자 기기">
                <DefaultLabel text={member.deviceType} />
              </FormRowLabel>
            </FormRow>
          </div>

          <div className="w-full">
            <FormRow>
              <FormRowLabel title="등록 프로필 이미지">
                <div className="w-full h-full flex justify-center items-center">
                  <ImageThumbnail
                    imageUrl={member.profileImage?.filePath ?? ""}
                    showDeleteButton={false}
                  />
                </div>
              </FormRowLabel>
            </FormRow>
          </div>
        </div>

        <FormHeader title="알림 설정 정보" />
        <FormRow>
          <FormRowLabel title="앱설정">
            <div className="flex flex-col w-full gap-4 py-1.5">
              {renderSetting("간편 로그인", member.appSettings?.simpleLogin)}
              {renderSetting("로그인 유지", member.appSettings?.maintainLogin)}
              {renderSetting("광고성 알림", member.alarm?.advertise)}
              {renderSetting("마케팅 정보수신", member.alarm?.marketing, true)}
            </div>
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="이용알림">
            <div className="flex flex-col w-full gap-4 py-1.5">
              {renderSetting("샌디 Life 댓글 알림", member.alarm?.talkReply)}
              {renderSetting("불편신고 알림", member.alarm?.incommodity)}
              {renderSetting("신청사비스 알림", member.alarm?.service)}
              {renderSetting("대여하기 알림", member.alarm?.rent)}
              {renderSetting("공간예약 알림", member.alarm?.reservation, true)}
            </div>
          </FormRowLabel>
        </FormRow>
        {member.managerAlarm && (
          <FormRow>
            <FormRowLabel title="매니저">
              <div className="flex flex-col w-full gap-4 pt-2">
                {renderSetting(
                  "사원식당 문의 알림",
                  member.managerAlarm?.restaurant
                )}
                {renderSetting(
                  "불편신고 매니저 알림",
                  member.managerAlarm?.incommodityCommon
                )}
                {renderSetting(
                  "- 불편신고 매니저 알림: 미화",
                  member.managerAlarm?.incommodityCleaning
                )}
                {renderSetting(
                  "- 불편신고 매니저 알림: 시설",
                  member.managerAlarm?.incommodityFacility
                )}
                {renderSetting(
                  "- 불편신고 매니저 알림: 기타",
                  member.managerAlarm?.incommodityEtc
                )}
                {renderSetting(
                  "신청 샌디 매니저 알림",
                  member.managerAlarm?.service
                )}
                {renderSetting(
                  "대여하기 매니저 알림",
                  member.managerAlarm?.rent
                )}
                {renderSetting(
                  "공간 예약 매니저 알림",
                  member.managerAlarm?.reservation
                )}
                {renderSetting(
                  "모니터링 알림",
                  member.managerAlarm?.monitoring,
                  true
                )}
              </div>
            </FormRowLabel>
          </FormRow>
        )}
      </div>

      <div className="flex justify-center">
        <DefaultButton onClick={closePopup}>닫기</DefaultButton>
      </div>

      {isOpen && (
        <CommonPopup
          header="회원 권한 변경"
          content={
            <RoleDetailView
              userId={userId}
              userName={member.name}
              role={member.role}
              closePopup={handleClosePopup}
              fetchData={handleRefresh}
            />
          }
          closePopup={handleClosePopup}
          isOpen={isOpen}
        />
      )}
    </div>
  );
}
