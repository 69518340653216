import { transformCategoryData } from "../libs/category";
import { TemplateActionCategory } from "./template";

export type CategoryData = {
  id: number;
  alias: string;
  name: string;
  subList: CategoryData[];
  storeCategoryId: string;
  storeList: CategoryStoreData[];
};

export type CategoryStoreData = {
  name: string;
  storeId: string;
};

export type ReportCategory = {
  ILLEGAL: string;
  OTHER: string;
  OBSCENITY: string;
  PRIVACY: string;
  ABUSE: string;
  PROFIT: string;
  SPAM: string;
};

export interface LifeCategoryData {
  lifeCategoryId: number;
  alias: string;
  name: string;
  type: string;
  lifeCategoryList?: LifeCategoryData[];
  storeCategoryId: string;
  storeList: CategoryStoreData[];
}

export type StoreCategory = {
  storeCategoryId: number;
  storeCategoryName: string;
  subCategory: StoreCategory[];
};

export enum CategoryReturnField {
  ID = "id",
  ALIAS = "alias",
  SUB_LIST = "subList",
  STORE_LIST = "storeList",
  NAME = "name",
}

export const getTemplateActionCategoryData = () => {
  const data: TemplateActionCategory[] = [
    { id: 1, alias: "inner", name: "내장 호출", subList: [] },
    {
      id: 2,
      alias: "outer",
      name: "외부 이동",
      subList: [
        { id: 21, alias: "web", name: "웹 이동", subList: [] },
        { id: 22, alias: "app", name: "앱 이동", subList: [] },
        { id: 23, alias: "phone", name: "전화 연결", subList: [] },
        { id: 24, alias: "activity", name: "액티비티", subList: [] },
      ],
    },
  ];

  return transformCategoryData(
    data,
    "id",
    "alias",
    "name",
    "subList",
    undefined,
    undefined
  );
};
