import { AxiosResponse, HttpStatusCode } from "axios";
import { useContext, useEffect, useState } from "react";
import { useModal } from "../../../../../contexts/Modal";
import { PopupContext } from "../../../../../contexts/Popup";
import {
  validationFunctions,
  validationResultMessage,
} from "../../../../../libs/validations";
import {
  getSpaceReservationDetail,
  patchSpaceReservationCancel,
  patchSpaceReservationCheckIn,
  patchSpaceReservationConfirm,
  patchSpaceReservationLongTermCancel,
  patchSpaceReservationModifyPrice,
  putSpaceReservation,
  putSpaceReservationLongTerm,
} from "../../../../../services/spaceReservationService";
import { DASH } from "../../../../../types/search";
import {
  RESERVATION_SPACE_TYPES,
  RentalDevice,
  getGender,
} from "../../../../../types/space";
import {
  RESERVATION_STATUS_TYPES,
  SpaceReservationCreateDto,
  SpaceReservationData,
  convertReservationTypeToLabel,
  reservationStatusMap,
} from "../../../../../types/spaceReservation";
import {
  findComboBoxItem,
  createBooleanOptions,
} from "../../../../../utils/comboBoxUtils";
import {
  convertWeekDaysToString,
  formatDate,
  getHoursUntil,
} from "../../../../../utils/dateUtils";
import DefaultButton from "../../../../DefaultButton";
import DefaultTextArea from "../../../../DefaultTextArea";
import FormHeader from "../../../../Form/Header";
import FormRow from "../../../../Form/Row";
import FormRowLabel from "../../../../Form/RowLabel";
import Checkbox from "../../../../Input/Checkbox";
import DefaultInput from "../../../../Input/DefaultInput";
import DefaultLabel from "../../../../Input/DefaultLabel";
import RadioButtonGroup from "../../../../Input/RadioButtonGroup";
import Spinner from "../../../../Spinner";
import {
  dayOptions,
  getWeekDaysSelectedOption,
  initWeekValue,
} from "../../SpaceList/SelectTimeDays";
import AvailableTime from "../AvailableTime";
import DeviceTotalTable from "../DeviceTotalTable";
import PayTotalTable from "../PayTotalTable";
import { useUserContext } from "../../../../../contexts/User";
import Satisfaction from "../../../../Satisfaction";
import { getLongTermItemList } from "../../../../../libs/spaceReservation";
import { MASKING_TYPE_PREDEFINE } from "@/types/masking";

const CANCEL_STATUS = {
  SINGLE: "single",
  LONG_TERM: "longTerm",
  NOTHING: "nothing",
};

const payOptions = createBooleanOptions("정상 적용", "확정 요금 변경");

type Props = {
  spaceReservationDetailId: string;
  onRefresh?: () => void;
};

export default function SpaceReservationDetailView({
  spaceReservationDetailId,
  onRefresh,
}: Props) {
  const { closePopup, refreshAndClosePopup } = useContext(PopupContext);
  const { showAlert, showConfirm, handleError } = useModal();
  const { userInfo } = useUserContext();
  const [spaceReservation, setSpaceReservation] = useState<
    Partial<SpaceReservationData>
  >({});
  const [reservationTime, setReservationTime] = useState("");
  const [longTerm, setLongTerm] = useState(false);
  const [cancel, setCancel] = useState(CANCEL_STATUS.NOTHING);
  const [usePayment, setUsePayment] = useState(false);
  const [payConfirm, setPayConfirm] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await getSpaceReservationDetail(
        spaceReservationDetailId
      );

      if (response.status === HttpStatusCode.Ok) {
        let data = response.data;
        if (data.fixedPrice >= 0) {
          setPayConfirm(false);
          data = { ...data, modifyPrice: data.fixedPrice };
        }
        setSpaceReservation({
          ...data,
          spaceInfo: {
            ...data.spaceInfo,
            rentalDeviceList: data.spaceInfo.rentalDeviceList.map((item) =>
              data.rentalDeviceList.some(
                (usedItem) => usedItem.rentalDeviceId === item.rentalDeviceId
              )
                ? { ...item, checked: true }
                : item
            ),
          },
        });
        setReservationTime(
          `${data.reservationDate} ${data.reservationTimeStart}`
        );
        setUsePayment(data.spaceInfo.usePayment);
      } else {
        throw new Error("Failed to reservation data load");
      }
    } catch (err: any) {
      handleError(err, "조회");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const validation = () => {
    const errorMessage = validationResultMessage([
      validationFunctions.required(
        spaceReservation.reservationTimeStart,
        "이용시간"
      ),
      validationFunctions.required(
        spaceReservation.reservationTimeEnd,
        "이용시간"
      ),
      ...(usePayment
        ? [
            validationFunctions.required(
              spaceReservation.usedDepartment,
              "부서"
            ),
          ]
        : []),
      ...(cancel !== CANCEL_STATUS.NOTHING
        ? [
            validationFunctions.required(
              spaceReservation.cancelComment,
              "취소 사유"
            ),
          ]
        : []),
    ]);
    return errorMessage;
  };

  const handleReservationChangeObject = (
    data: Partial<SpaceReservationData>
  ) => {
    setSpaceReservation((prev) => ({ ...prev, ...data }));
  };

  const handleSpaceInfoRentalDeviceChange = (list: RentalDevice[]) => {
    setSpaceReservation((prev) => ({
      ...prev,
      spaceInfo: prev.spaceInfo
        ? { ...prev.spaceInfo, rentalDeviceList: list }
        : undefined,
    }));
  };

  const renderAvailableTime = () => {
    let startDay = "";
    let endDay = "";

    if (
      spaceReservation?.spaceInfo?.availableDays &&
      spaceReservation?.spaceInfo?.defaultPriceDays
    ) {
      startDay =
        findComboBoxItem(
          dayOptions,
          getWeekDaysSelectedOption(spaceReservation.spaceInfo.availableDays)
        )?.label ?? "";
      endDay =
        findComboBoxItem(
          dayOptions,
          getWeekDaysSelectedOption(spaceReservation.spaceInfo.defaultPriceDays)
        )?.label ?? "";
    }
    const showerRoomCleanTimeText = `/ 이용 제한 시간 ${spaceReservation.spaceInfo?.blockTimeStart}~${spaceReservation.spaceInfo?.blockTimeEnd}`;

    return (
      <DefaultLabel
        label="운영시간"
        text={`${startDay} ${
          spaceReservation?.spaceInfo?.availableTimeStart ?? ""
        }~${spaceReservation?.spaceInfo?.availableTimeEnd ?? ""} 
        / 정가구간 ${endDay} ${
          spaceReservation?.spaceInfo?.defaultPriceTimeStart ?? ""
        }~${spaceReservation?.spaceInfo?.defaultPriceTimeEnd ?? ""} 
        ${
          spaceReservation?.spaceInfo?.useBlockTime
            ? showerRoomCleanTimeText
            : ""
        }`}
      />
    );
  };

  const getLongTermText = () => {
    const longTermInfo = spaceReservation?.longTermInfo;

    if (!longTermInfo) return DASH;

    const {
      longTermReservationDateStart,
      longTermReservationDateEnd,
      longTermReservationDayOfWeek,
    } = longTermInfo;

    const startDate = formatDate(
      new Date(longTermReservationDateStart ?? ""),
      "yyyy/MM/dd"
    );
    const endDate = formatDate(
      new Date(longTermReservationDateEnd ?? ""),
      "yyyy/MM/dd"
    );
    const days = convertWeekDaysToString(
      longTermReservationDayOfWeek ?? initWeekValue
    );

    return startDate && endDate && days.length > 0
      ? `${startDate} ~ ${endDate} [${days}]`
      : DASH;
  };

  const patchCancel = async (
    api: (id: string, comment: string) => Promise<AxiosResponse>,
    id: string,
    comment: string
  ) => {
    try {
      const response = await api(id, comment);
      if (response.status === HttpStatusCode.Ok) {
        showAlert("취소 되었습니다.");
        refreshAndClosePopup();
      } else {
        throw new Error("Failed to patch cancel");
      }
    } catch (err: any) {
      handleError(err, "취소");
    }
  };

  const handleCancelClick = () => {
    if (cancel === CANCEL_STATUS.NOTHING) {
      showAlert("취소 구분을 선택해주세요.");
      return;
    }
    const untilTime = getHoursUntil(reservationTime);

    const message =
      untilTime <= 48
        ? "48시간 이내 예약 취소 시 취소 수수료(100%)가 부과됩니다.\n예약 접수를 취소하시겠습니까?"
        : "예약 접수를 취소하시겠습니까?";

    const [api, id] =
      cancel === CANCEL_STATUS.SINGLE
        ? [patchSpaceReservationCancel, spaceReservationDetailId]
        : cancel === CANCEL_STATUS.LONG_TERM
        ? [
            patchSpaceReservationLongTermCancel,
            spaceReservation.longTermInfo?.longTermReservationId ?? "",
          ]
        : [null, null];

    if (
      cancel === CANCEL_STATUS.LONG_TERM &&
      !spaceReservation.longTermInfo?.longTermReservationId
    ) {
      showAlert("장기예약 정보가 없습니다.");
      return;
    }

    if (api === null || id === null) {
      return;
    }

    const errorMessage = validationResultMessage([
      validationFunctions.required(spaceReservation.cancelComment, "취소 사유"),
    ]);
    if (errorMessage) {
      showAlert(errorMessage);
    } else {
      showConfirm(message, () =>
        patchCancel(api, id, spaceReservation.cancelComment ?? "")
      );
    }
  };

  const handleUpdate = () => {
    const errorMessage = validation();
    if (errorMessage) {
      showAlert(errorMessage);
    } else {
      const message = spaceReservation.longTermInfo?.longTermReservationId
        ? "장기예약건 중 해당건만 수정됩니다.\n수정하시겠습니까?"
        : "수정하시겠습니까?";

      showConfirm(message, async () => {
        const data: Partial<SpaceReservationCreateDto> = {
          reservationTimeStart: spaceReservation.reservationTimeStart,
          reservationTimeEnd: spaceReservation.reservationTimeEnd,
          usedDepartment: spaceReservation.usedDepartment,
          ...(spaceReservation.spaceInfo?.usePayment && {
            beforeCleanTime: spaceReservation.beforeCleanTime,
            afterCleanTime: spaceReservation.afterCleanTime,
            totalSpaceDefaultPrice: spaceReservation.totalSpaceDefaultPrice,
            totalDeviceDefaultPrice: spaceReservation.totalDeviceDefaultPrice,
            vatDefaultPrice: spaceReservation.vatDefaultPrice,
          }),
          rentalDeviceList: (spaceReservation.spaceInfo?.rentalDeviceList ?? [])
            .filter(
              (item) =>
                item.checked === true && item.rentalDeviceId !== undefined
            )
            .map((item) => item.rentalDeviceId) as number[],
        };

        try {
          const res = await putSpaceReservation(spaceReservationDetailId, data);
          if (res.status === HttpStatusCode.Ok) {
            showAlert("수정 되었습니다.");
            refreshAndClosePopup();
          }
        } catch (err: any) {
          handleError(err, "수정");
        }
      });
    }
  };

  const handleUpdateLongTerm = () => {
    const errorMessage = validation();
    if (errorMessage) {
      showAlert(errorMessage);
    } else {
      const message =
        "해당 예약건을 포함한 이후의 모든 장기예약건이 수정됩니다.\n수정하시겠습니까?";

      showConfirm(message, async () => {
        const data: Partial<SpaceReservationCreateDto> = {
          reservationTimeStart: spaceReservation.reservationTimeStart,
          reservationTimeEnd: spaceReservation.reservationTimeEnd,
          usedDepartment: spaceReservation.usedDepartment,
          ...(spaceReservation.spaceInfo?.usePayment && {
            beforeCleanTime: spaceReservation.beforeCleanTime,
            afterCleanTime: spaceReservation.afterCleanTime,
          }),
          longTermSpaceReservationItemList: getLongTermItemList(
            {
              ...spaceReservation,
              rentalDeviceList: spaceReservation.spaceInfo?.rentalDeviceList,
            },
            true
          ),
          rentalDeviceList: (spaceReservation.spaceInfo?.rentalDeviceList ?? [])
            .filter(
              (item) =>
                item.checked === true && item.rentalDeviceId !== undefined
            )
            .map((item) => item.rentalDeviceId) as number[],
        };

        try {
          if (spaceReservation.longTermReservationId) {
            const res = await putSpaceReservationLongTerm(
              spaceReservation.longTermReservationId,
              data
            );
            if (res.status === HttpStatusCode.Ok) {
              showAlert("수정 되었습니다.");
              refreshAndClosePopup();
            }
          } else {
            showAlert("장기예약 id값이 존재하지 않습니다.");
          }
        } catch (err: any) {
          handleError(err, "수정");
        }
      });
    }
  };

  const handleConfirm = () => {
    showConfirm("승인 처리 하시겠습니까?", async () => {
      try {
        const res = await patchSpaceReservationConfirm(
          spaceReservationDetailId
        );
        if (res.status === HttpStatusCode.Ok) {
          showAlert("승인 처리 되었습니다.");
          fetchData();
          onRefresh?.();
        }
      } catch (err: any) {
        handleError(err, "승인 처리");
      }
    });
  };

  const handleCheckIn = () => {
    showConfirm("입실 확인 처리 하시겠습니까?", async () => {
      try {
        const res = await patchSpaceReservationCheckIn(
          spaceReservationDetailId
        );
        if (res.status === HttpStatusCode.Ok) {
          showAlert("입실 확인 처리 되었습니다.");
          fetchData();
          onRefresh?.();
        }
      } catch (err: any) {
        handleError(err, "입실 확인 처리");
      }
    });
  };

  const handlePayConfirmChange = () => {
    showConfirm("확정 요금을 변경하시겠습니까?", async () => {
      try {
        const res = await patchSpaceReservationModifyPrice(
          spaceReservationDetailId,
          Number(spaceReservation.modifyPrice)
        );
        if (res.status === HttpStatusCode.Ok) {
          showAlert("확정 요금 변경되었습니다.");
          fetchData();
          onRefresh?.();
        }
      } catch (err: any) {
        handleError(err, "확정 요금 변경");
      }
    });
  };

  const isCancelOrCompleted = () => {
    const statusList = [
      RESERVATION_STATUS_TYPES.CANCELED,
      RESERVATION_STATUS_TYPES.COMPLETED,
    ];

    return statusList.some(
      (status) => status === spaceReservation.reservationStatus
    );
  };

  const renderUseInfo = () => {
    return (
      <>
        {!Boolean(spaceReservation.cancelInfo) && (
          <>
            <FormHeader title="사용 정보" />
            {!spaceReservation.spaceInfo?.useAutoConfirm && (
              <FormRow>
                <FormRowLabel title="승인일시">
                  {spaceReservation.approvalTime ? (
                    <DefaultLabel
                      text={spaceReservation.approvalTime}
                      testId={"space-use-approval"}
                    />
                  ) : (
                    <DefaultButton
                      size="popupContentButton"
                      onClick={handleConfirm}
                      testId={"use-confirm"}
                    >
                      사용 승인
                    </DefaultButton>
                  )}
                </FormRowLabel>
              </FormRow>
            )}

            {(spaceReservation.spaceInfo?.useAutoConfirm ||
              spaceReservation.approvalTime) && (
              <FormRow>
                <FormRowLabel title="입실확인 일시">
                  {spaceReservation.checkInTime ? (
                    <DefaultLabel
                      text={`${spaceReservation.checkInTime} ${
                        spaceReservation.isCheckInByAdmin
                          ? "[관리자의 입실확인 처리]"
                          : ""
                      }`}
                    />
                  ) : (
                    <DefaultButton
                      size="popupContentButton"
                      onClick={handleCheckIn}
                      testId={"check-in-confirm"}
                    >
                      입실 확인
                    </DefaultButton>
                  )}
                </FormRowLabel>
              </FormRow>
            )}
          </>
        )}
      </>
    );
  };

  const renderCancel = () => {
    return (
      <>
        <FormHeader title="취소 처리" />
        <FormRow>
          <FormRowLabel title="취소 구분">
            {!Boolean(spaceReservation.cancelInfo) ? (
              <div className="flex gap-2 items-center">
                <Checkbox
                  label="단건 취소"
                  checked={cancel === CANCEL_STATUS.SINGLE}
                  onClick={(value: boolean) => {
                    const status = value
                      ? CANCEL_STATUS.SINGLE
                      : CANCEL_STATUS.NOTHING;
                    setCancel(status);
                  }}
                />
                {spaceReservation.longTermInfo?.longTermReservationId && (
                  <Checkbox
                    label="장기예약 취소"
                    checked={cancel === CANCEL_STATUS.LONG_TERM}
                    onClick={(value: boolean) => {
                      const status = value
                        ? CANCEL_STATUS.LONG_TERM
                        : CANCEL_STATUS.NOTHING;
                      setCancel(status);
                    }}
                  />
                )}
                <DefaultButton
                  size="popupContentButton"
                  onClick={handleCancelClick}
                >
                  취소 반영
                </DefaultButton>
              </div>
            ) : (
              <DefaultLabel
                text={
                  spaceReservation.cancelInfo?.longTermReservationId
                    ? "장기예약 취소"
                    : "단건 취소"
                }
              />
            )}
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="취소 사유" isRequired>
            {!Boolean(spaceReservation.cancelInfo) ? (
              <DefaultTextArea
                value={spaceReservation.cancelComment ?? ""}
                cols={40}
                onChange={(value: string) =>
                  handleReservationChangeObject({ cancelComment: value })
                }
                disabled={cancel === CANCEL_STATUS.NOTHING}
                maxLength={500}
                placeholder="취소 사유를 입력하세요"
              />
            ) : (
              <DefaultLabel text={spaceReservation.cancelInfo?.cancelComment} />
            )}
          </FormRowLabel>
        </FormRow>
      </>
    );
  };

  return (
    <div className="flex flex-col gap-5">
      <div className="flex flex-col min-w-[900px] max-h-[700px] overflow-y-auto">
        {isLoading && <Spinner />}
        <FormHeader title="기본 정보" />
        <FormRow>
          <FormRowLabel title="서비스 번호">
            <DefaultLabel text={spaceReservationDetailId} />
          </FormRowLabel>
          <FormRowLabel title="상태">
            <DefaultLabel
              text={
                reservationStatusMap[
                  spaceReservation.reservationStatus ?? "EMPTY"
                ]
              }
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="장기예약 번호">
            <DefaultLabel
              text={
                spaceReservation.longTermInfo?.longTermReservationId ?? DASH
              }
            />
          </FormRowLabel>
          <FormRowLabel title="장기예약 내역">
            <DefaultLabel text={getLongTermText()} />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="접수일시">
            <DefaultLabel text={spaceReservation.createdAt} />
          </FormRowLabel>
          <FormRowLabel title="최종 수정일시">
            <DefaultLabel text={spaceReservation.modifiedAt ?? DASH} />
          </FormRowLabel>
        </FormRow>

        <FormHeader title="예약 공간 정보" />
        <FormRow>
          <FormRowLabel title="위치">
            <DefaultLabel
              text={`${spaceReservation?.spaceInfo?.buildingName ?? ""} ${
                spaceReservation?.spaceInfo?.buildingFloorName ?? ""
              }`}
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="공간 정보">
            <div className="flex flex-col gap-2">
              <DefaultLabel
                label="공간명"
                text={spaceReservation?.spaceInfo?.reservationSpaceName}
              />
              <DefaultLabel
                label="공간 분류"
                testId={"space-type"}
                text={convertReservationTypeToLabel(
                  spaceReservation?.spaceInfo?.reservationSpaceType ??
                    RESERVATION_SPACE_TYPES.DEFAULT
                )}
              />
              {renderAvailableTime()}
              <DefaultLabel
                label="이용가능 성별"
                text={
                  spaceReservation?.spaceInfo?.availableGender &&
                  getGender(spaceReservation.spaceInfo?.availableGender)
                }
              />
              <DefaultLabel
                label="이용자 제한"
                text={`${
                  spaceReservation?.spaceInfo?.availablePeopleCountStart ?? ""
                }~${
                  spaceReservation?.spaceInfo?.availablePeopleCountEnd ?? ""
                }명`}
              />
              <DefaultLabel
                label="이용 시간"
                text={`기본 ${
                  spaceReservation?.spaceInfo?.usableTimeStart ?? ""
                }분 / 최대 ${
                  spaceReservation?.spaceInfo?.usableTimeEnd ?? ""
                }분(일일 ${
                  spaceReservation?.spaceInfo?.dailyLimit ?? ""
                }회 예약 제한)`}
              />
            </div>
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="공간 상세 정보">
            <div className="flex flex-col gap-2">
              <DefaultLabel
                label="담당자 정보"
                text={`${spaceReservation?.spaceInfo?.contactInfo ?? ""} / ${
                  spaceReservation?.spaceInfo?.contactNumber ?? ""
                }`}
              />
              <DefaultLabel
                label="공간 특징"
                text={spaceReservation?.spaceInfo?.reservationSpaceFeatures}
              />
              <DefaultLabel
                label="비고"
                text={spaceReservation?.spaceInfo?.reservationSpaceNote}
              />
            </div>
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="요금 정보">
            <div className="flex flex-col gap-2">
              <DefaultLabel
                label="요금 정책"
                text={spaceReservation?.spaceInfo?.usePayment ? "유료" : "무료"}
              />
              {spaceReservation?.spaceInfo?.usePayment && (
                <DefaultLabel
                  label="이용 요금"
                  text={`기본 ${
                    spaceReservation?.spaceInfo?.defaultPrice?.toLocaleString() ??
                    ""
                  }원 / 추가 ${
                    spaceReservation?.spaceInfo?.additionalPrice?.toLocaleString() ??
                    ""
                  }원`}
                />
              )}
            </div>
          </FormRowLabel>
        </FormRow>

        <FormHeader title="신청 내역" />
        <FormRow>
          <FormRowLabel title="신청자 정보">
            <DefaultLabel
              text={spaceReservation.createdByName ?? ""}
              masking={
                MASKING_TYPE_PREDEFINE.BO_SPACE_RESERVATION_DETAIL_CREATED_BY
              }
              targetId={spaceReservation.createdBy ?? -1}
              property="NAME"
              prefix=""
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="실사용자 정보" isRequired>
            <div className="flex flex-col gap-4">
              <span className="text-sm">
                {spaceReservation.isSelfReservation
                  ? "신청자와 동일"
                  : "대신 예약"}
              </span>
              <DefaultLabel
                text={spaceReservation.actualUserName ?? ""}
                masking={
                  spaceReservation.isSelfReservation
                    ? MASKING_TYPE_PREDEFINE.BO_SPACE_RESERVATION_DETAIL_ACTUAL_USER
                    : undefined
                }
                targetId={spaceReservation.actualUserId ?? -1}
                property="NAME"
                prefix="이름 : "
                testId="user-name"
              />
              <DefaultLabel
                text={spaceReservation.userTel ?? ""}
                masking={
                  spaceReservation.isSelfReservation
                    ? MASKING_TYPE_PREDEFINE.BO_SPACE_RESERVATION_DETAIL_ACTUAL_USER
                    : undefined
                }
                targetId={spaceReservation.actualUserId ?? -1}
                property="MOBILE_NUMBER"
                prefix="연락처 : "
                testId="mobile-number"
              />
            </div>
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="이용 시간" isRequired>
            <AvailableTime
              longTerm={longTerm}
              onLongTermChange={setLongTerm}
              spaceReservationDetailId={
                spaceReservation.spaceReservationDetailId
              }
              reservationDate={spaceReservation.reservationDate ?? ""}
              reservationInfo={
                {
                  ...spaceReservation.spaceInfo,
                  reservationTimeStart: spaceReservation.reservationTimeStart,
                  reservationTimeEnd: spaceReservation.reservationTimeEnd,
                  longTermInfo: spaceReservation.longTermInfo,
                } ?? {}
              }
              reservationStatus={spaceReservation.reservationStatus}
              longTermReservationDateStart={
                spaceReservation.longTermInfo?.longTermReservationDateStart ??
                ""
              }
              longTermReservationDateEnd={
                spaceReservation.longTermInfo?.longTermReservationDateEnd ?? ""
              }
              longTermReservationDayOfWeek={
                spaceReservation.longTermInfo?.longTermReservationDayOfWeek ??
                initWeekValue
              }
              onChange={handleReservationChangeObject}
              hideLongTerm={
                spaceReservation.spaceInfo?.reservationSpaceType ===
                RESERVATION_SPACE_TYPES.SHOWER
              }
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="이용 정보" isRequired={usePayment}>
            <div className="flex flex-col gap-2">
              <div className="flex gap-2">
                <DefaultInput
                  label="부서"
                  value={spaceReservation.usedDepartment ?? ""}
                  onChange={(value: string) =>
                    handleReservationChangeObject({ usedDepartment: value })
                  }
                  placeholder="실사용자 부서 정보 입력"
                />
                <DefaultInput
                  label="참여 인원"
                  type="number"
                  unitLabel="명"
                  value={spaceReservation.userCount}
                  placeholder="0"
                  disabled
                  min={0}
                />
              </div>
              <div className="flex">
                <DefaultTextArea
                  label="이용 목적"
                  value={spaceReservation.purpose ?? ""}
                  placeholder="이용목적을 2자 이상 입력하세요."
                  maxLength={100}
                  cols={50}
                  disabled
                />
              </div>
            </div>
          </FormRowLabel>
        </FormRow>

        {(spaceReservation.spaceInfo?.rentalDeviceList?.length ?? 0) > 0 && (
          <FormRow>
            <FormRowLabel title="기기 이용">
              <DeviceTotalTable
                rentalDeviceList={
                  spaceReservation.spaceInfo?.rentalDeviceList ?? []
                }
                reservationTimeStart={spaceReservation.reservationTimeStart}
                reservationTimeEnd={spaceReservation.reservationTimeEnd}
                usePayment={spaceReservation.spaceInfo?.usePayment}
                onDeviceChange={handleSpaceInfoRentalDeviceChange}
                disabled={isCancelOrCompleted()}
              />
            </FormRowLabel>
          </FormRow>
        )}

        {spaceReservation.spaceInfo?.usePayment && (
          <FormRow>
            <FormRowLabel title="결제예정 요금">
              <PayTotalTable
                reservationDate={spaceReservation.reservationDate ?? ""}
                spaceReservationData={spaceReservation}
                onChange={handleReservationChangeObject}
              />
            </FormRowLabel>
          </FormRow>
        )}

        {renderUseInfo()}
        {renderCancel()}
        {isCancelOrCompleted() && (
          <>
            <FormHeader title="결제요금 확정" />
            <FormRow>
              <FormRowLabel title="변경 여부">
                <div className="flex gap-2">
                  <RadioButtonGroup
                    value={payConfirm.toString()}
                    options={payOptions}
                    onChange={(value: string) => {
                      const devicePrice = Number(
                        spaceReservation.totalDeviceDefaultPrice
                      );
                      const spacePrice = Number(
                        spaceReservation.totalSpaceDefaultPrice
                      );
                      const vatPrice = Number(spaceReservation.vatDefaultPrice);

                      if (
                        isNaN(devicePrice) ||
                        isNaN(spacePrice) ||
                        isNaN(vatPrice)
                      ) {
                        showAlert(
                          "금액정보가 올바르지 않습니다. 관리자에게 문의해주세요."
                        );
                        return;
                      }

                      const totalPrice = devicePrice + spacePrice + vatPrice;

                      const isTrue = value === "true";
                      setPayConfirm(isTrue);

                      handleReservationChangeObject({
                        modifyPrice: isTrue ? totalPrice : 0,
                      });
                    }}
                  />
                  <div className="flex gap-1.5 max-w-xs">
                    <DefaultInput
                      type="number"
                      value={spaceReservation.modifyPrice?.toString()}
                      onChange={(value: string) =>
                        handleReservationChangeObject({
                          modifyPrice: Number(value),
                        })
                      }
                      min={0}
                      unitLabel="원"
                      placeholder="0"
                    />
                    <DefaultButton
                      size="popupDefault"
                      className="whitespace-nowrap"
                      onClick={handlePayConfirmChange}
                    >
                      요금 변경
                    </DefaultButton>
                  </div>
                </div>
              </FormRowLabel>
            </FormRow>
          </>
        )}
        {spaceReservation.reservationStatus ===
          RESERVATION_STATUS_TYPES.COMPLETED && (
          <>
            <FormHeader title="만족도 평가" />
            <FormRow>
              <Satisfaction
                serviceName="space"
                serviceId={spaceReservationDetailId}
              />
            </FormRow>
          </>
        )}
      </div>

      <div className="flex gap-2 justify-center">
        <DefaultButton onClick={closePopup}>닫기</DefaultButton>
        {!isCancelOrCompleted() && (
          <>
            {spaceReservation.longTermInfo?.longTermReservationId && (
              <DefaultButton color="primary" onClick={handleUpdateLongTerm}>
                장기예약 일괄 수정
              </DefaultButton>
            )}
            <DefaultButton color="primary" onClick={handleUpdate}>
              수정
            </DefaultButton>
          </>
        )}
      </div>
    </div>
  );
}
