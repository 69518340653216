import React, { useState } from "react";

type Props = {
  accept?: string;
  onChange?: (file: File) => void;
};

export default function SingleFileUploader({
  accept = ".png, .jpg, .jpeg",
  onChange,
}: Props) {
  const [fileName, setFileName] = useState("");

  const handleChange = (files: FileList | null) => {
    const selectedFile = files?.[0];
    if (!selectedFile) {
      setFileName("");
      return;
    }

    const fileExtension = selectedFile.name.split(".").pop()?.toLowerCase();
    if (!fileExtension || !accept.includes(`.${fileExtension}`)) {
      setFileName("");
      return;
    }

    setFileName(selectedFile.name);
    if (onChange) {
      onChange(selectedFile);
    }
  };

  return (
    <div className="flex w-[433px] h-[38px]">
      <input
        type="text"
        readOnly
        value={fileName}
        className="text-[13px] border border-gray-200 pl-3 py-[9px] pr-1.5 min-w-[350px]"
        placeholder="선택한 파일 없음"
      />
      <label className="flex items-center border px-4 py-[11px] text-sm font-medium min-w-[84px] hover:cursor-pointer">
        파일선택
        <input
          type="file"
          accept={accept}
          data-testid="single-file-input"
          style={{ display: "none" }} // 실제 파일 입력 필드는 숨깁니다.
          onChange={(e) => {
            handleChange(e.target.files);
            e.target.value = "";
          }}
        />
      </label>
    </div>
  );
}
