import { useContext, useState } from "react";
import { HttpStatusCode } from "axios";
import clsx from "clsx";

import {
  KOREAN_MAX_LATITUDE,
  KOREAN_MAX_LONGITUDE,
  KOREAN_MIN_LATITUDE,
  KOREAN_MIN_LONGITUDE,
} from "@/constants/geo";

import { EmergencyCoordinate, Shelter } from "@/types/emergency";

import {
  validationFunctions,
  validationResultMessage,
} from "@/libs/validations";

import { useModal } from "@/contexts/Modal";
import { PopupContext } from "@/contexts/Popup";

import { postShelter } from "@/services/emergencyService";

import SingleImageUploader from "@/components/FileUploader/ImageFileUploader/SingleImageFileUploader";
import DefaultButton from "@/components/DefaultButton";
import FormHeader from "@/components/Form/Header";
import FormRow from "@/components/Form/Row";
import FormRowLabel from "@/components/Form/RowLabel";
import DefaultInput from "@/components/Input/DefaultInput";
import DefaultLabel from "@/components/Input/DefaultLabel";
import NaverMapComponent from "../NaverMapComponent";
import Spinner from "@/components/Spinner";

enum ShelterCreateFormStep {
  DataEntry = 0,
  AreaSelection = 1,
}

type Props = {};

export default function ShelterCreateForm(props: Props) {
  const [createStep, setCreateStep] = useState(ShelterCreateFormStep.DataEntry);
  const { showAlert, showConfirm, handleError } = useModal();
  const { closePopup, refreshAndClosePopup } = useContext(PopupContext);
  const [imageFile, setImageFile] = useState<File>();
  const [shelter, setShelter] = useState<Partial<Shelter>>({});
  const [isLoading, setIsLoading] = useState(false);

  const handleShelterChange =
    (property: keyof Shelter) => (data: string | number | File | boolean) => {
      setShelter((prev) => {
        return { ...prev, [property]: data };
      });
    };

  const handleNextStep = () => {
    const errorMessage = validationResultMessage([
      validationFunctions.required(shelter.shelterName, "대피영역명"),
      validationFunctions.required(shelter.description, "대피영역 주소"),
      validationFunctions.required(imageFile, "대피영역 이미지"),
      validationFunctions.required(shelter.lat, "위도"),
      validationFunctions.number(shelter.lat, "위도"),
      validationFunctions.required(shelter.lng, "경도"),
      validationFunctions.number(shelter.lng, "경도"),
    ]);

    if (errorMessage) {
      showAlert(errorMessage);
      return;
    } else {
      if (
        shelter.lat &&
        (shelter.lat < KOREAN_MIN_LATITUDE || shelter.lat > KOREAN_MAX_LATITUDE)
      ) {
        showAlert(
          `위도는 ${KOREAN_MIN_LATITUDE} ~ ${KOREAN_MAX_LATITUDE} 사이의 값이어야 합니다.`
        );
        return;
      }

      if (
        shelter.lng &&
        (shelter.lng < KOREAN_MIN_LONGITUDE ||
          shelter.lng > KOREAN_MAX_LONGITUDE)
      ) {
        showAlert(
          `경도는 ${KOREAN_MIN_LONGITUDE} ~ ${KOREAN_MAX_LONGITUDE} 사이의 값이어야 합니다.`
        );
        return;
      }

      setCreateStep(ShelterCreateFormStep.AreaSelection);
    }
  };

  const postData = async (shelterCoordinateListAndType: Partial<Shelter>) => {
    setIsLoading(true);
    try {
      const response = await postShelter(
        createFormData(shelterCoordinateListAndType)
      );

      if (response.status === HttpStatusCode.Ok) {
        showAlert("등록이 완료되었습니다.");
        refreshAndClosePopup();
      } else {
        throw new Error("Shelter post fail");
      }
    } catch (err: any) {
      handleError(err, "등록");
    } finally {
      setIsLoading(false);
    }
  };

  const createFormData = (shelterCoordinateListAndType: Partial<Shelter>) => {
    const formData = new FormData();

    const postData: Partial<Shelter> = {
      ...shelter,
      lat: Number(shelter.lat),
      lng: Number(shelter.lng),
      isExternal: shelterCoordinateListAndType.isExternal,
      shelterCoordinateList: shelterCoordinateListAndType.shelterCoordinateList,
    };

    if (imageFile) {
      formData.append("imageFiles", imageFile);
    }

    formData.append("request", JSON.stringify(postData));

    return formData;
  };

  if (ShelterCreateFormStep.DataEntry === createStep) {
    return (
      <>
        {isLoading && <Spinner />}
        <div className={clsx("flex flex-col gap-5 min-w-[700px]")}>
          <div>
            <FormHeader
              title="대피영역 정보"
              right={
                <span className="text-sm">
                  ※위경도 정보는 구글 지도서비스(
                  <a
                    href="https://map.google.com"
                    target="_blank"
                    className="underline"
                  >
                    map.google.com
                  </a>
                  )를 이용하여 검색할 수 있습니다
                </span>
              }
            />
            <FormRow>
              <FormRowLabel title="대피영역 ID">
                <DefaultLabel text="자동으로 생성됩니다." />
              </FormRowLabel>
            </FormRow>
            <FormRow>
              <FormRowLabel title="대피영역명" isRequired>
                <div className="flex flex-col">
                  <div className="flex gap-2 items-center">
                    <DefaultInput
                      value={shelter.shelterName}
                      onChange={handleShelterChange("shelterName")}
                      placeholder="대피영역을 입력해주세요"
                      minWidth="w-[435px]"
                    />
                  </div>
                </div>
              </FormRowLabel>
            </FormRow>
            <FormRow>
              <FormRowLabel title="대피영역 주소" isRequired>
                <div className="flex flex-col">
                  <div className="flex gap-2 items-center">
                    <DefaultInput
                      value={shelter.description}
                      onChange={handleShelterChange("description")}
                      placeholder="대피영역 주소를 입력해주세요"
                      minWidth="w-[435px]"
                    />
                  </div>
                </div>
              </FormRowLabel>
            </FormRow>
            <FormRow>
              <FormRowLabel title="대피영역 이미지" isRequired>
                <SingleImageUploader
                  image={imageFile}
                  onChange={setImageFile}
                />
              </FormRowLabel>
            </FormRow>
            <FormRow>
              <FormRowLabel title="위경도" isRequired>
                <div className="flex gap-2">
                  <DefaultInput
                    value={shelter.lat?.toString()}
                    label="위도"
                    placeholder="위도 입력"
                    onChange={handleShelterChange("lat")}
                    minWidth="w-[250px]"
                  />
                  <DefaultInput
                    value={shelter.lng?.toString()}
                    label="경도"
                    placeholder="경도 입력"
                    onChange={handleShelterChange("lng")}
                    minWidth="w-[250px]"
                  />
                </div>
              </FormRowLabel>
            </FormRow>
          </div>

          <div className="flex justify-center gap-2 py-5">
            <DefaultButton onClick={closePopup}>닫기</DefaultButton>
            <DefaultButton color="primary" onClick={handleNextStep}>
              다음
            </DefaultButton>
          </div>
        </div>
      </>
    );
  } else if (ShelterCreateFormStep.AreaSelection === createStep) {
    return (
      <div data-testid="shelter-range-select-step">
        {isLoading && <Spinner />}
        <NaverMapComponent
          defaultLat={shelter.lat ?? 37.5679819}
          defaultLng={shelter.lng ?? 126.8253066}
          shelterId={""}
          stepMode
          onCancel={() => setCreateStep(ShelterCreateFormStep.DataEntry)}
          onSuccess={(shelterCoordinateListAndType: Partial<Shelter>) => {
            showConfirm("등록 하시겠습니까?", () =>
              postData(shelterCoordinateListAndType)
            );
          }}
        />
      </div>
    );
  } else {
    return <>{createStep}</>;
  }
}
