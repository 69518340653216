import { HttpStatusCode } from "axios";
import { useCallback, useEffect, useState } from "react";
import { useModal } from "../../../contexts/Modal";
import {
  getTemplateList,
  postTemplateTarget,
} from "../../../services/templateService";
import { ComponentType, DASH } from "../../../types/search";
import { Service, ServiceType } from "../../../types/service";
import { Template, TemplateTargetPost } from "../../../types/template";
import { addDefaultOption } from "../../../utils/comboBoxUtils";
import { USAGE_OPTIONS, usageYnLabels } from "../../../types/comboBoxOption";
import DefaultButton from "../../DefaultButton";
import ListPage, { SearchCondition } from "../../ListPage";
import { TableColumnType } from "../../Table";
import { RightArrowIcon } from "../../TagSelector";
import DefaultLabel from "../../Input/DefaultLabel";
import PermissionWrapper from "../../PermissionWrapper";

type Props = {
  templateService: Service[];
  id: string;
  serviceType: ServiceType;
  refreshService: () => Promise<void>;
  closePopup: () => void;
};

export default function TemplateServiceSelector({
  templateService,
  id,
  serviceType,
  refreshService,
  closePopup,
}: Props) {
  const { showAlert, showConfirm, handleError } = useModal();
  const [data, setData] = useState<Template[]>([]);

  useEffect(() => {
    setData(
      templateService.map(
        (item) =>
          ({
            serviceTemplateName: item.serviceName,
            isActive: item.isActive,
            usedCount: item.usedCount,
            serviceTemplateId: Number(item.serviceTemplateId),
          } as Template)
      )
    );
  }, [templateService]);

  const compareItem = (item: Template) => (o: Template) => {
    return item.serviceTemplateId === o.serviceTemplateId;
  };

  const handleVisible = (item: Template) => {
    return data.some(compareItem(item));
  };

  const handleAdd = (item: Template) => {
    setData((prev) => [item, ...prev]);
  };

  const handleRemove = (item: Template) => {
    setData((prev) => prev.filter((o) => o !== item));
  };

  const renderAdd = (item: Template) => {
    const visible = handleVisible(item);

    return (
      <div className="w-full h-3 flex gap-2 justify-center items-center">
        {!visible && (
          <DefaultButton
            onClick={() => {
              handleAdd(item);
            }}
          >
            추가
          </DefaultButton>
        )}
      </div>
    );
  };

  const renderRemove = (item: Template) => (
    <div className="w-full h-3 flex gap-2 justify-center items-center">
      <DefaultButton
        onClick={() => {
          handleRemove(item);
        }}
      >
        삭제
      </DefaultButton>
    </div>
  );

  const headerAddAll = (data: Template[]) => {
    return (
      <DefaultButton
        color="white"
        className="h-[22px] text-xs font-normal"
        onClick={() => {
          setData((prev) => {
            const newData = data.filter(
              (item) => !prev.some(compareItem(item))
            );
            return [...newData, ...prev];
          });
        }}
      >
        전체 선택
      </DefaultButton>
    );
  };

  const headerRemoveAll = () => {
    return (
      <DefaultButton
        color="white"
        className="h-[22px] text-xs font-normal"
        onClick={() => setData([])}
      >
        전체 해제
      </DefaultButton>
    );
  };

  const componentList: ComponentType<Template>[] = [
    {
      labelName: "템플릿 명",
      typeName: "text",
      keyName: "serviceTemplateName",
      placeholder: "템플릿 명을 입력해주세요",
    },
    {
      labelName: "사용여부",
      typeName: "comboBox",
      keyName: "isActive",
      comboBoxOptions: addDefaultOption(USAGE_OPTIONS),
    },
  ];

  const columnInfo: TableColumnType<Template>[] = [
    { header: "템플릿 ID", name: "serviceTemplateId" },
    { header: "템플릿 명", name: "serviceTemplateName" },
    {
      header: "적용 회사 수",
      render: (item: Template) => item.usedCount?.toLocaleString() ?? DASH,
    },
    {
      header: "사용여부",
      render: (item: Template) => (
        <div className="flex items-center justify-center">
          <DefaultLabel
            text={item.isActive ? usageYnLabels.TRUE : usageYnLabels.FALSE}
            color={item.isActive ? "blue" : "gray"}
          />
        </div>
      ),
      width: "yesOrNo",
    },
  ];

  const handlePost = () => {
    const templateIds: number[] = data.map((item) => item.serviceTemplateId);

    showConfirm("템플릿 서비스를 등록 하시겠습니까?", async () => {
      try {
        const data: TemplateTargetPost = {
          serviceTemplateIds: templateIds,
        };
        const res = await postTemplateTarget(serviceType, id, data);
        if (res.status === HttpStatusCode.Ok) {
          showAlert("템플릿 서비스 등록 되었습니다.");
          refreshService();
          closePopup();
        }
      } catch (err: any) {
        handleError(err, "템플릿 서비스 등록");
      }
    });
  };

  const getTemplateListUseCallback = useCallback(
    async (params: SearchCondition<Template>) => {
      return getTemplateList({ ...params, target: serviceType });
    },
    [serviceType]
  );

  return (
    <div className="flex flex-col gap-5">
      <div className="flex max-h-[700px] overflow-y-auto">
        <div className="flex items-end">
          <div className="min-w-[600px] w-full">
            <ListPage
              getDataApi={getTemplateListUseCallback}
              columnInfo={[
                ...columnInfo,
                {
                  header: "활동",
                  render: renderAdd,
                  headerRender: headerAddAll,
                },
              ]}
              componentList={componentList}
              tableTitle="템플릿 목록"
              isTableScroll
              isSmallButton
            />
          </div>
        </div>
        <div className="flex items-center justify-center  ">
          <RightArrowIcon />
        </div>
        <div className="flex items-end">
          <div className="min-w-[600px] w-full">
            <ListPage
              getDataApi={Promise.resolve}
              selectedData={data}
              columnInfo={[
                ...columnInfo,
                {
                  header: "활동",
                  render: renderRemove,
                  headerRender: headerRemoveAll,
                },
              ]}
              componentList={componentList}
              tableTitle={`선택 템플릿 목록`}
              selectedMode
              isTableScroll
            />
          </div>
        </div>
      </div>

      <div className="flex justify-center gap-2">
        <DefaultButton onClick={closePopup}>닫기</DefaultButton>
        <PermissionWrapper>
          <DefaultButton color="primary" onClick={handlePost}>
            등록
          </DefaultButton>
        </PermissionWrapper>
      </div>
    </div>
  );
}
