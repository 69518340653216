import { useCallback, useEffect, useState } from "react";
import { generateAxiosResponse } from "../../../../libs/request";
import { validationFunctions } from "../../../../libs/validations";
import { getAtgWorker } from "../../../../services/atgService";
import { AtgWorker } from "../../../../types/atg";
import { ComponentType } from "../../../../types/search";
import { SearchCondition } from "../../../ListPage";
import { TableColumnType } from "../../../Table";
import { SearchTagData } from "../../../TagFilter";
import { TagData } from "../../../TagList";
import TagSelector from "../../../TagSelector";
import { useModal } from "../../../../contexts/Modal";
import { useUserContext } from "../../../../contexts/User";

type Props = {
  atgBuildingId: string;
  workerTags: SearchTagData;
  setWorkerTags: (value: SearchTagData) => void;
};

export default function IncommodityAtgWorkerSelector({
  atgBuildingId,
  workerTags,
  setWorkerTags,
}: Props) {
  const { handleError } = useModal();
  const { userInfo } = useUserContext();

  // 첫 렌더링 시 접속 관리자명으로 작업자 검색
  const [searchCondition, setSearchCondition] = useState<
    SearchCondition<AtgWorker>
  >({ workerName: userInfo?.name });

  useEffect(() => {
    setSearchCondition({});
  }, []);

  const getAtgWorkerAsList = useCallback(
    async (searchCondition: SearchCondition<AtgWorker>) => {
      try {
        if (!searchCondition.workerName) {
          return generateAxiosResponse([]);
        }

        const response = await getAtgWorker(
          atgBuildingId,
          searchCondition.workerName
        );

        return generateAxiosResponse(response.data);
      } catch (err: any) {
        handleError(err, "목록 조회");
        return err;
      }
    },
    [atgBuildingId]
  );

  const atgResourceComponents: ComponentType<AtgWorker>[] = [
    {
      labelName: "작업자명",
      typeName: "text",
      validation: [validationFunctions.required],
      keyName: "workerName",
      placeholder: "작업자명을 입력해주세요",
    },
  ];

  const atgResourceColumns: TableColumnType<AtgWorker>[] = [
    { header: "작업자명", name: "workerName" },
    { header: "직무", name: "job" },
  ];

  const compareAtg = (item: AtgWorker) => (o: AtgWorker) => {
    return JSON.stringify(item) === JSON.stringify(o);
  };

  const getTagAtg = (item: AtgWorker) => {
    return {
      id: JSON.stringify(item),
      name: item.workerName,
      data: item,
    } as TagData;
  };
  return (
    <>
      <TagSelector
        getDataApi={getAtgWorkerAsList}
        componentList={atgResourceComponents}
        columnInfo={atgResourceColumns}
        title="작업자 목록"
        tableHeight="small"
        initSearchCondition={searchCondition}
        tags={workerTags}
        setTags={setWorkerTags}
        compareItem={compareAtg}
        getTagItem={getTagAtg}
        inline
        hidePageSize
        hidePagination
      />
    </>
  );
}
