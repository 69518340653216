type Props = {};

export default function SystemMenuIcon(props: Props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.6">
        <path
          d="M8.5 18.5H15.5M5 6.5L5 14.5C5 15.0523 5.44772 15.5 6 15.5L18 15.5C18.5523 15.5 19 15.0523 19 14.5V6.5C19 5.94772 18.5523 5.5 18 5.5L6 5.5C5.44772 5.5 5 5.94772 5 6.5Z"
          stroke="white"
        />
        <path
          d="M15.2772 12.0907L16.9158 12.0907M7.08423 8.81356L8.72282 8.81356M13.9663 12.0907L7.08423 12.0907M10.0337 8.81356L16.9158 8.81356M10.0337 10.1244L10.0337 7.50269L8.72282 7.50269L8.72282 10.1244L10.0337 10.1244ZM15.2772 13.4016L15.2772 10.7799L13.9663 10.7799L13.9663 13.4016L15.2772 13.4016Z"
          stroke="white"
        />
      </g>
    </svg>
  );
}
