import React, { useState, useEffect } from "react";
import { useLockScroll } from "../../contexts/LockScroll";

const Spinner: React.FC = () => {
  const [highlightIndex, setHighlightIndex] = useState(0);
  const { lockAndUnlockScroll } = useLockScroll();

  useEffect(() => {
    const preventKeyboardEvents = (e: KeyboardEvent) => {
      e.preventDefault();
      e.stopPropagation();
      return false;
    };

    document.addEventListener("keydown", preventKeyboardEvents);

    const intervalId = setInterval(() => {
      setHighlightIndex((prevIndex) => (prevIndex + 1) % 8);
    }, 130);

    const unlock = lockAndUnlockScroll();

    return () => {
      unlock();
      clearInterval(intervalId);
      document.removeEventListener("keydown", preventKeyboardEvents);
    };
  }, []);

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
      data-testid="spinner"
    >
      {/* 마우스 클릭을 방지하는 레이어 */}
      <div
        className="absolute inset-0"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      ></div>
      <div>
        <div className="flex items-center space-x-2">
          <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="22"
              width="4"
              height="12"
              rx="2"
              fill={
                highlightIndex === 0
                  ? "url(#paint0_linear_369_3932)"
                  : "#D9D9D9"
              }
            />
            <rect
              x="33.8994"
              y="16.9288"
              width="4"
              height="12"
              rx="2"
              transform="rotate(-135 33.8994 16.9288)"
              fill={
                highlightIndex === 1
                  ? "url(#paint0_linear_369_3932)"
                  : "#D9D9D9"
              }
            />
            <rect
              x="36"
              y="26"
              width="4"
              height="12"
              rx="2"
              transform="rotate(-90 36 26)"
              fill={
                highlightIndex === 2
                  ? "url(#paint0_linear_369_3932)"
                  : "#D9D9D9"
              }
            />
            <rect
              x="31.071"
              y="33.8995"
              width="4"
              height="12"
              rx="2"
              transform="rotate(-45 31.071 33.8995)"
              fill={
                highlightIndex === 3
                  ? "url(#paint0_linear_369_3932)"
                  : "#D9D9D9"
              }
            />
            <rect
              x="22"
              y="36"
              width="4"
              height="12"
              rx="2"
              fill={
                highlightIndex === 4
                  ? "url(#paint0_linear_369_3932)"
                  : "#D9D9D9"
              }
            />
            <rect
              x="8.4436"
              y="42.3848"
              width="4"
              height="12"
              rx="2"
              transform="rotate(-135 8.4436 42.3848)"
              fill={
                highlightIndex === 5
                  ? "url(#paint0_linear_369_3932)"
                  : "#D9D9D9"
              }
            />
            <rect
              y="26"
              width="4"
              height="12"
              rx="2"
              transform="rotate(-90 0 26)"
              fill={
                highlightIndex === 6
                  ? "url(#paint0_linear_369_3932)"
                  : "#D9D9D9"
              }
            />
            <rect
              x="5.61523"
              y="8.4436"
              width="4"
              height="12"
              rx="2"
              transform="rotate(-45 5.61523 8.4436)"
              fill={
                highlightIndex === 7
                  ? "url(#paint0_linear_369_3932)"
                  : "#D9D9D9"
              }
            />
            <defs>
              <linearGradient
                id="paint0_linear_369_3932"
                x1="22.3964"
                y1="49298.5"
                x2="40.4869"
                y2="49298.5"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#EA27C2" />
                <stop offset="1" stopColor="#E100A3" />
              </linearGradient>
            </defs>
          </svg>
        </div>
      </div>
    </div>
  );
};

export default Spinner;
