import CompanyDetailView from "../../../components/Company/DetailView";
import CompanyListTopRight from "../../../components/Company/ListTopRight";
import DefaultButton from "../../../components/DefaultButton";
import ListPage from "../../../components/ListPage";
import { TableColumnType } from "../../../components/Table";
import {
  getCompanyBulkExcelForm,
  getCompanyList,
  getCompanyListExcel,
  postCompanyBulkExcel,
} from "../../../services/companyService";
import { Company } from "../../../types/company";
import { ComboBoxType, ComponentType } from "../../../types/search";
import CompanyServiceForm from "../../../components/Company/ServiceForm";
import ExcelFileUploaderButton from "../../../components/FileUploader/ExcelFileUploaderButton";
import DefaultLabel from "../../../components/Input/DefaultLabel";
import {
  DEFAULT_SELECT_ALL_VALUE,
  usageYnLabels,
} from "../../../types/comboBoxOption";

type Props = {};

export default function CompanyList(props: Props) {
  const columnInfo: TableColumnType<Company>[] = [
    { header: "No.", defaultNo: true, width: "no" },
    { header: "회사 ID", name: "companyId", width: "id" },
    { header: "회사명", name: "companyName" },
    { header: "회사 도메인 주소", name: "mailDomain" },
    { header: "등록일시", name: "createdAt", width: "date" },
    {
      header: "사용여부",
      render: (item: Company) => (
        <div className="flex items-center justify-center">
          <DefaultLabel
            text={item.isActive ? usageYnLabels.TRUE : usageYnLabels.FALSE}
            color={item.isActive ? "blue" : "gray"}
          />
        </div>
      ),
      width: "yesOrNo",
    },
    {
      header: "서비스 관리",
      render: (item: Company, handler) => (
        <div className="w-full h-full flex gap-2 justify-center items-center">
          <DefaultButton
            color="blue"
            size="tableButton"
            onClick={() => {
              if (handler?.popup) {
                handler.popup({
                  header: `서비스 관리 - ${item.companyName ?? ""}`,
                  content: <CompanyServiceForm companyId={item.companyId} />,
                });
              }
            }}
          >
            관리
          </DefaultButton>
        </div>
      ),
      width: "button",
    },
    {
      header: "정보관리",
      render: (item: Company, handler) => (
        <div className="w-full h-full flex gap-2 justify-center items-center">
          <DefaultButton
            color="blue"
            size="tableButton"
            onClick={() => {
              if (handler?.popup) {
                handler.popup({
                  header: "회사 상세 정보",
                  content: <CompanyDetailView companyId={item.companyId} />,
                });
              }
            }}
          >
            관리
          </DefaultButton>
        </div>
      ),
      width: "button",
    },
  ];

  const comboboxIsUse: ComboBoxType[] = [
    { value: DEFAULT_SELECT_ALL_VALUE, label: "전체" },
    { value: "true", label: "사용" },
    { value: "false", label: "미사용" },
  ];

  const componentList: ComponentType<Company>[] = [
    {
      labelName: "등록일자",
      typeName: "dateRange",
      keyName: "createdAt",
      category: "date",
    },
    {
      labelName: "사용여부",
      typeName: "comboBox",
      keyName: "isActive",
      comboBoxOptions: comboboxIsUse,
      category: "typeSearch",
    },
    {
      labelName: "회사명",
      typeName: "text",
      keyName: "companyName",
      category: "detailSearch",
      placeholder: "회사명을 입력해주세요",
    },
    {
      labelName: "회사ID",
      typeName: "text",
      keyName: "companyId",
      category: "detailSearch",
      placeholder: "회사ID를 입력해주세요",
    },
  ];

  return (
    <ListPage
      getDataApi={getCompanyList}
      excelUpload={
        <ExcelFileUploaderButton
          title="회사"
          getExcelDataApi={getCompanyBulkExcelForm}
          postExcelDataApi={postCompanyBulkExcel}
        />
      }
      excelDownloadApi={getCompanyListExcel}
      keyId={(item: Company) => item.companyId}
      columnInfo={columnInfo}
      componentList={componentList}
      tableTitle="회사목록 전체"
      renderTopRight={<CompanyListTopRight />}
      defaultPageSize={50}
      defaultPageSizeList={[50, 100, 150]}
    />
  );
}
