import React, { ReactNode, useEffect, useState } from "react";
import clsx from "clsx";

import { TagSelectedItem } from "@/types/building";
import { evacueeType } from "@/types/comboBoxOption";
import {
  companyShelterMapDto,
  EmergencyBuilding,
  EvacuationUser,
} from "@/types/emergency";

import { toComboBoxType } from "@/utils/comboBoxUtils";
import {
  validationResultMessage,
  validationFunctions,
} from "@/libs/validations";

import { useLockScroll } from "../../../contexts/LockScroll";
import { useModal } from "@/contexts/Modal";

import Spinner from "../../Spinner";
import FormHeader from "@/components/Form/Header";
import FormRow from "@/components/Form/Row";
import FormRowLabel from "@/components/Form/RowLabel";
import DefaultInput from "@/components/Input/DefaultInput";
import DefaultSelect from "@/components/SelectBox/DefaultSelect";
import { getBuildingFloor } from "@/services/buildingService";
import { HttpStatusCode } from "axios";
import { ComboBoxType } from "@/types/search";

type Props = {
  header?: ReactNode;
  content?: ReactNode;
  isOpen?: boolean;
  closeModal?: () => void;
  confirmLabel?: string;
  cancelLabel?: string;
  onCancel?: () => void;
  addToList: (item: EvacuationUser) => void;
  emergencyBuildingList?: EmergencyBuilding[];
};

export default function EmergencyModal({
  header,
  content,
  isOpen,
  closeModal,
  confirmLabel = "확인",
  cancelLabel = "취소",
  onCancel,
  addToList,
  emergencyBuildingList,
}: Props) {
  const { lockAndUnlockScroll } = useLockScroll();
  const [isLoading, setIsLoading] = useState(false);
  const [emergencyBuilding, setEmergencyBuilding] = useState<
    EmergencyBuilding[] | undefined
  >(emergencyBuildingList);

  const initEvacuee = {
    name: "",
    mobileNumber: "",
    isManager: false,
    isMember: false,
    evacuationBuildingId: "",
    evacuationBuildingName: "",
    evacuationCompanyId: "",
    evacuationCompanyName: "",
    evacuationDepartmentName: "",
    type: null,
    role: "",
    isDelete: false,
  };

  const { showAlert, showConfirm, handleError } = useModal();
  //TODO: 불필요한 schema 제거
  const [evacuee, setEvacuee] = useState<Partial<EvacuationUser>>(initEvacuee);

  const [companyList, setCompanyList] = useState<companyShelterMapDto[]>([]);

  const [buildingFloorOptions, setBuildingFloorOptions] = useState<
    ComboBoxType[]
  >([]);

  const handleEvacueeChange = (field: keyof EvacuationUser, value: string) => {
    setEvacuee((prev) => ({ ...prev, [field]: value }));
  };

  const handleEvacuee = async () => {
    const buildingId = evacuee.evacuationBuildingId;
    const companyId = evacuee.evacuationCompanyId;

    const buildingName = emergencyBuilding?.find(
      (building) => building.buildingId === buildingId
    )?.buildingName;
    const companyName = companyList?.find(
      (company) => company.companyId === companyId
    )?.companyName;

    addToList({
      ...evacuee,
      mobileNumber: evacuee.mobileNumber?.replaceAll("-", ""),
      evacuationBuildingName: buildingName,
      evacuationCompanyName: companyName,
      type: "MANUAL",
    } as EvacuationUser);

    setEvacuee(initEvacuee);

    onCancel?.();
  };

  const handlePost = () => {
    const errorMessage = validationResultMessage([
      validationFunctions.required(evacuee.name, "이름"),
      validationFunctions.required(evacuee.mobileNumber, "전화번호"),
      validationFunctions.required(evacuee.role, "권한 선택"),
      validationFunctions.required(evacuee.evacuationBuildingId, "건물 선택"),
      validationFunctions.required(evacuee.evacuationCompanyId, "회사 선택"),
      validationFunctions.required(evacuee.evacuationFloorId, "근무 층 선택"),
      validationFunctions.required(evacuee.evacuationDepartmentName, "부서"),
    ]);

    if (errorMessage) {
      showAlert(errorMessage);
      return;
    }

    showConfirm("등록 하시겠습니까?", handleEvacuee);
  };

  const fetchFloorData = async (buildingId: string) => {
    if (!buildingId) {
      showAlert("건물을 선택해주세요.");
      return;
    }

    try {
      setIsLoading(true);
      const response = await getBuildingFloor(buildingId);

      if (response.status === HttpStatusCode.Ok) {
        const options = toComboBoxType(
          response.data,
          "buildingFloorId",
          "floorName"
        );
        setBuildingFloorOptions(options);
      }
    } catch (err: any) {
      handleError(err, "건물 층 정보 조회");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (emergencyBuildingList) {
      setEmergencyBuilding(emergencyBuildingList);
    }
  }, [emergencyBuildingList]);

  useEffect(() => {
    if (isOpen) {
      // setEvacuee(initialEvacueeState);

      const contentElement = document.getElementById("common-popup-content");
      if (contentElement) {
        contentElement.scrollTop = 0;
      }

      return lockAndUnlockScroll();
    }
  }, [isOpen]);

  return (
    <>
      {isLoading && <Spinner />}
      <div
        className={clsx(
          "fixed inset-0 z-40 flex items-center justify-center bg-black bg-opacity-50",
          {
            hidden: !isOpen,
          }
        )}
        data-testid="modal-container"
      >
        <div
          className="absolute flex flex-col justify-between bg-white min-w-[420px] min-h-[210px] gap-6 p-6 transform -translate-x-1/2 -translate-y-1/2"
          style={{
            left: "50%",
            top: "50%",
          }}
          onClick={closeModal}
        >
          <div className="flex mt-1">
            <div className="w-full">
              <p className="text-xl font-semibold text-black">{header}</p>
              <div className="flex flex-col gap-5">
                <div className="max-h-[700px] overflow-y-auto">
                  <FormHeader title="등록 인원 상세정보" />
                  <FormRow>
                    <FormRowLabel title="이름" isRequired>
                      <div className="flex flex-col">
                        <div className="flex gap-2 items-center">
                          <DefaultInput
                            value={evacuee.name}
                            onChange={(value: string) => {
                              handleEvacueeChange("name", value);
                            }}
                            placeholder="이름을 입력해주세요"
                          />
                        </div>
                      </div>
                    </FormRowLabel>
                  </FormRow>
                  <FormRow>
                    <FormRowLabel title="전화번호" isRequired>
                      <div className="flex flex-col">
                        <div className="flex gap-2 items-center">
                          <DefaultInput
                            value={evacuee.mobileNumber}
                            onChange={(value: string) => {
                              handleEvacueeChange("mobileNumber", value);
                            }}
                            placeholder="전화번호를 입력해주세요"
                            type="tel"
                            hideMaxLength
                            maxLength={20}
                          />
                        </div>
                      </div>
                    </FormRowLabel>
                  </FormRow>
                  <FormRow>
                    <FormRowLabel title="권한 선택" isRequired>
                      <div className="flex flex-col">
                        <DefaultSelect
                          value={evacuee.role}
                          optionList={evacueeType}
                          onChange={(value: string) => {
                            handleEvacueeChange("role", value);
                          }}
                          placeholder="권한을 선택해주세요"
                        />
                        <div className="text-xs text-[#D95050] mt-2">
                          *등록되는 인원이 비회원인 경우 권한은 일반 사용자로
                          변경됩니다.
                        </div>
                      </div>
                    </FormRowLabel>
                  </FormRow>
                  <FormHeader title="등록 인원 회사정보" />
                  <FormRow>
                    <FormRowLabel title="건물 선택" isRequired>
                      {
                        <DefaultSelect
                          value={evacuee.evacuationBuildingId}
                          optionList={toComboBoxType(
                            emergencyBuilding!,
                            "buildingId",
                            "buildingName"
                          )}
                          onChange={(value: string) => {
                            if (value) {
                              const selectedBuilding = emergencyBuilding?.find(
                                (building) => building.buildingId === value
                              );
                              setCompanyList(
                                selectedBuilding?.companyShelterMapDtoList ?? []
                              );
                              if (selectedBuilding) {
                                fetchFloorData(selectedBuilding?.buildingId);
                              }
                              handleEvacueeChange(
                                "evacuationBuildingId",
                                value
                              );
                            } else {
                              console.error(
                                "Building ID is not found while selecting building."
                              );
                            }
                          }}
                          placeholder="건물을 선택해주세요"
                        />
                      }
                    </FormRowLabel>
                  </FormRow>
                  <FormRow>
                    <FormRowLabel title="회사 선택" isRequired>
                      <DefaultSelect
                        value={evacuee.evacuationCompanyId}
                        optionList={toComboBoxType(
                          companyList,
                          "companyId",
                          "companyName"
                        )}
                        onChange={(value: string) => {
                          handleEvacueeChange("evacuationCompanyId", value);
                        }}
                        placeholder="회사를 선택해주세요"
                      />
                    </FormRowLabel>
                  </FormRow>
                  <FormRow>
                    <FormRowLabel title="근무 층 선택" isRequired>
                      <DefaultSelect
                        value={evacuee.evacuationFloorId}
                        onChange={(value: string) =>
                          handleEvacueeChange("evacuationFloorId", value)
                        }
                        optionList={buildingFloorOptions}
                        placeholder="층을 선택해주세요"
                      />
                    </FormRowLabel>
                  </FormRow>
                  <FormRow>
                    <FormRowLabel title="부서" isRequired>
                      <div className="flex flex-col">
                        <div className="flex gap-2 items-center">
                          <DefaultInput
                            value={evacuee.evacuationDepartmentName}
                            onChange={(value: string) => {
                              handleEvacueeChange(
                                "evacuationDepartmentName",
                                value
                              );
                            }}
                            placeholder="부서를 입력해주세요"
                          />
                        </div>
                      </div>
                    </FormRowLabel>
                  </FormRow>
                  {/* SAN-79 이슈로 잠시 주석처리 -> 2차때 정책 맞춰서 다시 추가 */}
                  {/* <FormRow>
                    <FormRowLabel title="근무 층" isRequired>
                      <div className="flex flex-col">
                        <div className="flex gap-2 items-center">
                          <DefaultInput
                            value={evacuee.floor}
                            onChange={(value: string) => {
                              handleEvacueeChange("floor", value);
                            }}
                            placeholder="층을 입력해주세요"
                          />
                        </div>
                      </div>
                    </FormRowLabel>
                  </FormRow> */}
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-center gap-2 mb-1">
            <button
              className="bg-brand-primary-gray-100 text-sm text-brand-neutral-50 font-medium min-w-[65px] min-h-[38px] bg-opacity-10 px-5"
              onClick={() => {
                onCancel?.();
              }}
            >
              {cancelLabel}
            </button>
            <button
              className="bg-brand-primary-gray-100 text-brand-neutral-00 font-medium text-sm min-w-[65px] min-h-[38px] outline-none px-5"
              onClick={handlePost}
              data-testid="simple-modal-confirm-button"
            >
              {confirmLabel}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
