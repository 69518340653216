import { useCallback, useContext, useEffect, useState } from "react";
import { HttpStatusCode } from "axios";

import { BuildingCompany } from "../../../../../types/building";
import { Company } from "../../../../../types/company";
import { ComponentType, DASH } from "../../../../../types/search";

import { useModal } from "../../../../../contexts/Modal";
import { PopupContext } from "../../../../../contexts/Popup";

import {
  getBuildingCompanyListAll,
  putBuildingCompanyCompanyIds,
} from "../../../../../services/buildingService";
import { getCompanyList } from "../../../../../services/companyService";

import { SearchCondition, useListPageContext } from "../../../../ListPage";
import { TableColumnType } from "../../../../Table";
import { SearchTagData } from "../../../../TagFilter";
import { TagData } from "../../../../TagList";
import DefaultButton from "../../../../DefaultButton";
import FormHeader from "../../../../Form/Header";
import FormRow from "../../../../Form/Row";
import FormRowLabel from "../../../../Form/RowLabel";
import DefaultInput from "../../../../Input/DefaultInput";
import TagSelector from "../../../../TagSelector";
import PermissionWrapper from "../../../../PermissionWrapper";
import Spinner from "../../../../Spinner";

type Props = { buildingCompany: BuildingCompany };

export default function BuildingCompanyManage({ buildingCompany }: Props) {
  const { refreshListPage } = useListPageContext();
  const { showAlert, showConfirm, handleError } = useModal();
  const { closePopup } = useContext(PopupContext);

  const [tags, setTags] = useState<SearchTagData>({});
  const [isLoading, setIsLoading] = useState(false);

  const fetchSelectedData = async () => {
    try {
      setIsLoading(true);

      const response = await getBuildingCompanyListAll({
        buildingId: buildingCompany.buildingId,
      });

      if (response.data) {
        const list = response.data.map(
          (item) =>
            ({
              id: item.buildingId,
              name: item.buildingName,
              data: item,
            } as TagData)
        );
        setTags({ data: list, type: "company" });
      } else {
        throw new Error("Data load fail");
      }
    } catch (err: any) {
      handleError(err, "조회");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchSelectedData();
  }, []);

  const companyComponents: ComponentType<Company>[] = [
    {
      labelName: "회사명",
      typeName: "text",
      keyName: "companyName",
      placeholder: "회사명을 입력해주세요",
    },
    {
      labelName: "회사 ID",
      typeName: "text",
      keyName: "companyId",
      placeholder: "회사 ID를 입력해주세요",
    },
  ];

  const companyColumns: TableColumnType<Company>[] = [
    { header: "회사 ID", name: "companyId" },
    { header: "회사명", name: "companyName" },
  ];

  const compareCompany = (item: Company) => (o: Company) => {
    return item.companyId === o.companyId;
  };

  const getTagCompany = (item: Company) => {
    return {
      id: item.companyId,
      name: item.companyName,
      data: item,
    } as TagData;
  };

  const updateData = async () => {
    try {
      const response = await putBuildingCompanyCompanyIds(
        buildingCompany.buildingId,
        tags.data?.map((item) => item.id) ?? []
      );

      if (response.status === HttpStatusCode.Ok) {
        showAlert("수정 되었습니다.");
      } else {
        throw new Error("Update fail");
      }
    } catch (err: any) {
      handleError(err, "수정");
    } finally {
      refreshListPage();
    }
  };

  const handleUpdate = () => {
    showConfirm("수정 하시겠습니까?", updateData);
  };

  const getCompanyListWrapper = useCallback(
    (params: SearchCondition<Company>) =>
      getCompanyList({ ...params, isActive: true }),
    []
  );

  return (
    <div className="flex flex-col gap-5">
      {isLoading && <Spinner />}
      <div className="flex flex-col overflow-y-auto">
        <FormHeader title="기본 정보" />
        <FormRow>
          <FormRowLabel title="건물명">
            <DefaultInput
              value={buildingCompany.buildingName ?? DASH}
              disabled
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <div className="flex w-full">
            <FormRowLabel title="등록일시">
              <DefaultInput
                value={buildingCompany.createdAt ?? DASH}
                disabled
              />
            </FormRowLabel>
            <FormRowLabel title="최종 수정일시">
              <DefaultInput
                value={buildingCompany.modifiedAt ?? DASH}
                disabled
              />
            </FormRowLabel>
          </div>
        </FormRow>

        <FormHeader title="입주사 목록" />
        <TagSelector
          getDataApi={getCompanyListWrapper}
          componentList={companyComponents}
          columnInfo={companyColumns}
          title="회사 목록"
          tagType="company"
          tags={tags}
          setTags={setTags}
          compareItem={compareCompany}
          getTagItem={getTagCompany}
          inline
          isHorizontal
        />
      </div>

      <div className="flex gap-2 justify-center">
        <DefaultButton onClick={closePopup}>닫기</DefaultButton>
        <PermissionWrapper>
          <DefaultButton color="primary" onClick={handleUpdate}>
            수정
          </DefaultButton>
        </PermissionWrapper>
      </div>
    </div>
  );
}
