import DateRange from "..";
import {
  ComboBoxType,
  SearchDateRange,
  SearchDateRangeRadio,
} from "../../../types/search";
import RadioButtonGroup from "../../Input/RadioButtonGroup";

type Props = {
  value: SearchDateRangeRadio;
  onChange: (data: SearchDateRangeRadio) => void;
  options?: ComboBoxType[];
};

export default function DateRangeRadio({
  value = { selectRadio: "createdAt", dateRange: {} },
  onChange,
  options = [],
}: Props) {
  return (
    <div className="flex gap-4 items-center">
      <RadioButtonGroup
        options={options}
        value={value.selectRadio ?? ""}
        onChange={(radio: string) =>
          onChange({ selectRadio: radio, dateRange: value.dateRange })
        }
      />
      <DateRange
        value={value.dateRange ?? {}}
        onChange={(data: SearchDateRange) =>
          onChange({ selectRadio: value.selectRadio, dateRange: data })
        }
      />
    </div>
  );
}
