import { useCallback, useEffect, useState } from "react";
import { FiEye } from "react-icons/fi";
import PostDetailView from "../../../components/Board/PostList/DetailView";
import PostListTopRight from "../../../components/Board/PostList/ListTopRight";
import DefaultButton from "../../../components/DefaultButton";
import ListPage, { SearchCondition } from "../../../components/ListPage";
import Spinner from "../../../components/Spinner";
import { TableColumnType } from "../../../components/Table";
import { transformCategoryData } from "../../../libs/category";
import {
  getCategoryList,
  getPostList,
  getPostListExcel,
} from "../../../services/pinelifeService";
import {
  CategoryData,
  CategoryReturnField,
  LifeCategoryData,
} from "../../../types/category";
import { Post, radioBoxPostVisible } from "../../../types/post";
import { ComponentType, DASH } from "../../../types/search";
import { addDefaultOption } from "../../../utils/comboBoxUtils";
import {
  CATEGORY_LEVEL_LABELS,
  YES_NO_OPTIONS,
} from "../../../types/comboBoxOption";
import UseYesIcon from "../../../svgs/icons/UseYn/UseYesIcon";
import UseNoIcon from "../../../svgs/icons/UseYn/UseNoIcon";
import { useModal } from "../../../contexts/Modal";
import { buildingGroupCategoryCodeValue } from "../../../types/building";
import { useUserContext } from "../../../contexts/User";

type Props = {};

export default function PostList(props: Props) {
  const { handleError } = useModal();
  const { getCategoryCodeByRole } = useUserContext();

  const [categoryData, setCategoryData] = useState<CategoryData[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);

  const renderDate = (date: string, time: string) => {
    return (
      <span>
        {date}
        <br />
        {time}
      </span>
    );
  };

  const columnInfo: TableColumnType<Post>[] = [
    { header: "ID", name: "lifePostId", width: "no" },
    { header: "대분류", name: "categoryNameL" },
    { header: "중분류", name: "categoryNameM" },
    // 기획 상 고려되지 않은 상태로 숨김처리.
    // {
    //   header: "게시판 구분",
    //   render: (item: Post) => (
    //     <div className="flex justify-center items-center">
    //       {item.buildingName}
    //       <br />
    //       {`(${item.buildingId})`}
    //     </div>
    //   ),
    // },
    { header: "제목", name: "title" },
    {
      header: "작성자 정보",
      render: (item: Post) => (
        <div className="flex justify-center items-center">
          {!item.nickname && !item.email ? (
            DASH
          ) : (
            <span>
              {item.nickname}
              <br />
              {item.email && `(${item.email})`}
            </span>
          )}
        </div>
      ),
    },
    { header: "작성자 ID", name: "createdBy" },
    {
      header: "상단 고정여부",
      render: (item: Post) => (
        <div className="flex justify-center items-center">
          {item.isTop ? <UseYesIcon /> : <UseNoIcon />}
        </div>
      ),
      width: "icon",
    },
    { header: "좋아요", name: "likeCount", sortable: true, width: "number" },
    { header: "조회수", name: "viewCount", sortable: true, width: "number" },
    { header: "댓글수", name: "commentCount", sortable: true, width: "number" },
    { header: "최근 댓글 작성", name: "commentCreatedAt", width: "date" },
    {
      header: "글 작성일시",
      render: (item: Post) => {
        const [date, time] = item.createdAt.split(" ");
        return renderDate(date, time);
      },
      width: "date",
    },
    {
      header: "노출",
      render: (item: Post) => (
        <div className="flex justify-center items-center">
          {item.status === "PUBLIC" ? <UseYesIcon /> : <UseNoIcon />}
        </div>
      ),
      width: "70",
    },
    {
      header: "삭제여부",
      render: (item: Post) => (
        <div className="flex justify-center items-center">
          {item.isDeleted ? <UseYesIcon /> : <UseNoIcon />}
        </div>
      ),
      width: "70",
    },
    {
      header: "활동",
      render: (item: Post, handler) => (
        <div className="w-full h-full flex gap-2 justify-center items-center">
          <DefaultButton
            size="tableIcon"
            color="blue"
            onClick={() => {
              if (handler?.popup) {
                handler.popup({
                  header: "게시글 상세",
                  content: (
                    <PostDetailView
                      postId={item.lifePostId}
                      categoryData={categoryData}
                    />
                  ),
                });
              }
            }}
          >
            <FiEye />
          </DefaultButton>
        </div>
      ),
      width: "icon",
    },
  ];

  useEffect(() => {
    getLifeCategory();
  }, []);

  const getLifeCategory = async () => {
    try {
      setIsLoading(true);

      const response = await getCategoryList();

      if (response.data) {
        const data: LifeCategoryData[] = response.data;

        setCategoryData(
          transformCategoryData(
            data,
            "lifeCategoryId",
            "alias",
            "name",
            "lifeCategoryList",
            undefined,
            undefined
          )
        );
      } else {
        throw new Error("카테고리 정보를 불러오는 중 오류가 발생했습니다.");
      }
    } catch (err: any) {
      handleError(err, "카테고리 조회");
    } finally {
      setIsLoading(false);
    }
  };

  const componentList: ComponentType<Post>[] = [
    {
      labelName: "작성일자",
      typeName: "dateRange",
      keyName: "createdAt",
      category: "date",
    },
    {
      labelName: "카테고리",
      typeName: "categoryComboBox",
      keyName: "category",
      category: "typeSearch",
      categoryData: {
        searchField: CategoryReturnField.ALIAS,
        optionData: categoryData,
        allOptionLabels: CATEGORY_LEVEL_LABELS,
      },
    },
    {
      labelName: "노출여부",
      typeName: "comboBox",
      keyName: "status",
      category: "typeSearch",
      comboBoxOptions: addDefaultOption(radioBoxPostVisible),
    },
    {
      labelName: "상단 고정여부",
      typeName: "comboBox",
      keyName: "isTop",
      comboBoxOptions: addDefaultOption(YES_NO_OPTIONS),
      category: "typeSearch",
    },
    {
      labelName: "건물검색",
      typeName: "tagFilter",
      keyName: "tagFilter",
      category: "typeSearch",
      buildingGroupCategoryCode: getCategoryCodeByRole(
        buildingGroupCategoryCodeValue.POST
      ),
      tagFilter: { tagFilterSearchType: "building" },
    },
    {
      labelName: "글 ID",
      typeName: "number",
      keyName: "lifePostId",
      category: "detailSearch",
      placeholder: "글 ID를 입력해주세요",
    },
    {
      labelName: "제목",
      typeName: "text",
      keyName: "title",
      category: "detailSearch",
      placeholder: "제목을 입력해주세요",
    },
    {
      labelName: "본문",
      typeName: "text",
      keyName: "content",
      category: "detailSearch",
      placeholder: "본문을 입력해주세요",
    },
    {
      labelName: "작성자 이메일",
      typeName: "text",
      keyName: "email",
      category: "detailSearch",
      placeholder: "작성자 이메일을 입력해주세요",
    },
    {
      labelName: "작성자 별명",
      typeName: "text",
      keyName: "nickname",
      category: "detailSearch",
      placeholder: "작성자 별명을 입력해주세요",
    },
  ];

  const getPostListCallback = useCallback(
    async (params: SearchCondition<Post>) => {
      return getPostList(params);
    },
    [refresh]
  );

  const handleRefresh = () => {
    setRefresh(!refresh);
  };

  return (
    <>
      {!isLoading && (
        <ListPage
          getDataApi={getPostListCallback}
          excelDownloadApi={getPostListExcel}
          keyId={(item: Post) => item.lifePostId}
          columnInfo={columnInfo}
          componentList={componentList}
          tableTitle="게시글 목록"
          renderTopRight={
            <PostListTopRight
              categoryData={categoryData}
              refresh={handleRefresh}
            />
          }
          needExcelDownloadReason
        />
      )}
      {isLoading && <Spinner />}
    </>
  );
}
