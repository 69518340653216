import { HttpStatusCode } from "axios";
import { useContext, useEffect, useState } from "react";
import { useModal } from "../../../../../contexts/Modal";
import { PopupContext } from "../../../../../contexts/Popup";
import {
  validationFunctions,
  validationResultMessage,
} from "../../../../../libs/validations";
import {
  deleteAppVersionForce,
  getAppVersion,
  patchAppVersion,
} from "../../../../../services/systemService";
import { AppVersion } from "../../../../../types/system";
import DefaultButton from "../../../../DefaultButton";
import FormHeader from "../../../../Form/Header";
import FormRow from "../../../../Form/Row";
import FormRowLabel from "../../../../Form/RowLabel";
import DefaultLabel from "../../../../Input/DefaultLabel";
import Spinner from "../../../../Spinner";
import DefaultInput from "../../../../Input/DefaultInput";
import UseNoIcon from "../../../../../svgs/icons/UseYn/UseNoIcon";
import UseYesIcon from "../../../../../svgs/icons/UseYn/UseYesIcon";

type Props = { appVersionId: number };

export default function AppVersionDetailView({ appVersionId }: Props) {
  const { closePopup, refreshAndClosePopup } = useContext(PopupContext);
  const { showAlert, showConfirm, handleError } = useModal();

  const [appVersion, setAppVersion] = useState<Partial<AppVersion>>({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        const res = await getAppVersion(appVersionId);
        if (res.status === HttpStatusCode.Ok) {
          setAppVersion(res.data);
        }
      } catch (err: any) {
        handleError(err, "조회");
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleUpdate = () => {
    const errorMessage = validationResultMessage([
      validationFunctions.required(appVersion.releaseNote, "개선 내용"),
    ]);

    if (errorMessage) {
      showAlert(errorMessage);
    } else {
      showConfirm("수정 하시겠습니까?", updateData);
    }
  };

  const updateData = async () => {
    const data: Partial<AppVersion> = {
      releaseNote: appVersion.releaseNote,
    };

    try {
      const res = await patchAppVersion(appVersionId, data);
      if (res.status === HttpStatusCode.Ok) {
        showAlert("수정 되었습니다.");
        refreshAndClosePopup();
      }
    } catch (err: any) {
      handleError(err, "수정");
    }
  };

  const handleDelete = () => {
    showConfirm("버전을 삭제하시겠습니까?", deleteData);
  };

  const deleteData = async () => {
    try {
      const res = await deleteAppVersionForce(appVersionId);
      if (res.status === HttpStatusCode.Ok) {
        showAlert("삭제 되었습니다.");
        refreshAndClosePopup();
      }
    } catch (err: any) {
      handleError(err, "삭제");
    }
  };

  const handleAppVersionChange = (key: keyof AppVersion) => (value: string) => {
    setAppVersion((prev) => ({ ...prev, [key]: value }));
  };

  return (
    <div className="flex flex-col gap-5">
      {isLoading && <Spinner />}
      <div className="flex flex-col min-w-[700px] max-h-[700px] overflow-y-auto">
        <FormHeader title="기본정보" />
        <FormRow>
          <FormRowLabel title="OS">
            <DefaultLabel text={appVersion.osType} />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="강제 업데이트 여부">
            {appVersion.isForceUpdate ? <UseYesIcon /> : <UseNoIcon />}
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="버전">
            <DefaultLabel text={appVersion.version} />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="개선 내용" isRequired>
            <DefaultInput
              value={appVersion.releaseNote}
              onChange={handleAppVersionChange("releaseNote")}
              minWidth="w-full"
              maxLength={100}
              placeholder="개선 내용을 입력해주세요"
            />
          </FormRowLabel>
        </FormRow>
      </div>

      <div className="flex justify-center gap-2">
        <DefaultButton onClick={closePopup}>닫기</DefaultButton>
        <DefaultButton color="primary" onClick={handleDelete}>
          삭제
        </DefaultButton>
        <DefaultButton color="primary" onClick={handleUpdate}>
          수정
        </DefaultButton>
      </div>
    </div>
  );
}
