import { SCHEDULE_TYPES } from "../../types/comboBoxOption";
import { IncommodityCreateDto } from "../../types/incommodity";
import { MemberSelect } from "../../types/member";

import { formatDate } from "../../utils/dateUtils";

export const createIncommodityCreateData = (
  isSelf: boolean,
  member: Partial<MemberSelect>,
  incommodity: Partial<IncommodityCreateDto>,
  scheduleRadio: string,
  date: Date
): Partial<IncommodityCreateDto> => {
  const data: Partial<IncommodityCreateDto> = { ...incommodity };

  if (!isSelf) {
    data.userId = member.memberId;
  }

  if (scheduleRadio === SCHEDULE_TYPES.CHANGE) {
    data.deadLine = formatDate(date, "yyyy/MM/dd HH:mm:ss");
  }

  if (!Object.keys(data).includes("buildingId")) {
    throw new Error("buildingId is required");
  }

  if (!Object.keys(data).includes("serviceTemplateId")) {
    throw new Error("serviceTemplateId is required");
  }

  return data;
};
