import { Company } from "./company";
import { FileInfo } from "./file";
import { Store } from "./store";

export type Building = {
  type: BuildingType;
  groupId?: string;
  groupName?: string;
  buildingGroupId: string;
  buildingGroupName: string;
  talkGroupId: string;
  talkGroupName: string;
  rentalGroupId: string;
  rentalGroupName: string;
  buildingId: string;
  buildingName: string;
  buildingFloorId: string;
  floorName: string;
  address: string;
  atgBuildingId: string;
  atgBuildingVocId: string;
  isActive: true;
  createdAt: string;
  createdBy: number;
  modifiedAt: string;
  helpDeskTel: string;
  lat: number;
  lng: number;
  gridX: number;
  gridY: number;
  shopCode: string;
  atgGroupId: string;
  atgDepartmentName: string;
  atgCompanyName: string;
  vocId: string;
  airMonitoringStation: string;
  buildingFloorList: BuildingFloor[];
  buildingVocList: BuildingVoc[];
  imageFile?: File;
  deleteFileInfo?: boolean;
  categoryCode?: string;
  isExcludedOrigin?: boolean;
  fileInfo: {
    items: FileInfo[];
  };
};

// 빌딩과 서비스 그룹을 한번에 담은 타입
export type BuildingServiceGroupType = {
  groupId: string;
  groupName: string;
  buildingId: string;
  buildingName: string;
  address: string;
  helpDeskTel: string;
  isActive: boolean;
  createdAt: string;
  createdBy: number;
  modifiedAt: string;
  modifiedBy: number;
};

// 보여주는 데이터 용
export type ServiceGroupingType = {
  groupId: string;
  groupName: string;
  buildingList: {
    buildingId: string;
    buildingName: string;
  }[];
};

// 보여주는 데이터 용
export type ServiceGroupingForMulti = {
  [key: string]: {
    groupName: string;
    buildingList: {
      buildingId: string;
      buildingName: string;
    }[];
  };
};

export type SmallBuildingType = {
  groupId: string;
  groupName: string;
  buildingId: string;
  buildingName: string;
};

export type BuildingGroup = {
  type: BuildingType | null;
  buildingId: string | null;
  buildingName: string | null;
  address: string | null;
  atgBuildingId: string | null;
  isActive: boolean | null;
  createdAt: string | null;
};

export const buildingGroupCategoryCodeValue = {
  ALL: "ALL",
  SPACE: "SPACE",
  POST: "BUILDING_BBS",
  INCOMMODITY: "VOC",
  IOT_DEVICE: "MONITORING",
  CONTRACT: "CONTRACT",
  RENTAL: "RENTAL",
};

export type BuildingVocGet = {
  code: string;
  codeName: string;
  codeType: string;
  vocId?: string;
};

export type BuildingVoc = {
  vocUnitCode: string;
  vocId: string;
  isActive: boolean;
};

export type BuildingEdge = {
  department: string;
  buildingName: string;
  workplace: string;
  vocNumber: number;
};

export type BuildingCompany = {
  buildingId: string;
  buildingName: string;
  companyId: string;
  companyName: string;
  companyCount: string;
  createdAt: string;
  modifiedAt: string;
  mailDomain: string;
  isActive: boolean;
  tagFilter: string;
  occupiedBuildingCount: number;
};

export type BuildingCompanyService = {
  createdAt: string;
  createdBy: number;
  modifiedAt: string;
  modifiedBy: number;
  serviceId: string;
  targetId: string;
  serviceType: string;
  serviceTypeName: string;
  isActive: boolean;
  serviceName: string;
  sortOrder: number;
  serviceItemType: string;
  serviceItemTypeName: string;
  serviceDetailType: string;
  serviceTemplateId: number;
  target: string;
  useOnlyAuthorized: boolean;
  buildingServiceId: number;
};

export type BuildingFloor = {
  buildingFloorId: string;
  sortOrder: number;
  floorName: string;
  isActive: boolean;
  atgBuildingFloorId: string;
};

export type BuildingType = "NORMAL" | "ATG";

export const buildingType = {
  NORMAL: "일반등록건물",
  ATG: "엣지등록건물",
};

export const initBuildingFloor = {
  buildingFloorId: "",
  sortOrder: 0,
  floorName: "",
  isActive: true,
  atgBuildingFloorId: "",
};

export type TagSelectedItem = BuildingGroup | Company | Store;

export type TagSelectType =
  | "building"
  | "company"
  | "store"
  | "emergencyBuilding";

export const TagSelectTypeName: Record<TagSelectType, string> = {
  building: "건물",
  company: "회사",
  store: "상점",
  emergencyBuilding: "비상대피",
};

export type Toilet = {
  spaceToiletId: string;
  toiletName: string;
  buildingId: string;
  buildingName: string;
  buildingFloorId: string;
  buildingFloorName: string;
  companyToiletMapCount: number;
  spaceToiletCompartmentCount: number;
  companyList: Company[];
  spaceToiletCompartment: ToiletCompartment[];
  companyIds: string[];
  spaceToiletCompartmentCreateRequests: ToiletCompartment[];
  spaceToiletCompartmentModifyRequests: ToiletCompartment[];
  gender: string;
  isActive: boolean;
  createdAt: string;
  isFloorConnected: boolean;
  companyName: string;
  isConnectedAllCompany: boolean;
  buildingAndFloor: string;
};

export type ToiletCompartment = {
  appendId: number;
  toiletCompartmentId?: string;
  toiletCompartmentName: string;
  isActive: boolean;
  connected?: boolean;
};

export type TempHum = {
  buildingSpaceId: string;
  spaceName: string;
  buildingFloorId: string;
  sortOrder: number;
  buildingId: string;
  isActive: boolean;
  buildingName: string;
  floorName: string;
  isConnected: boolean;
  createdAt: string;
  modifiedAt: string;
  buildingAndFloor: string;
  imageFile?: File;
  planFile?: {
    items: FileInfo[];
  };
  isDeleteFile?: boolean;
};
