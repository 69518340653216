import React, { ReactNode, useEffect } from "react";

import { useLockScroll } from "../../../contexts/LockScroll";

type Props = {
  header?: ReactNode;
  content?: ReactNode;
  footer?: ReactNode;
  isOpen?: boolean;
  closePopup?: () => void;
};

export default function CommonPopup({
  header,
  content,
  footer,
  isOpen,
  closePopup,
}: Props) {
  const { lockAndUnlockScroll } = useLockScroll();

  useEffect(() => {
    if (isOpen) {
      const contentElement = document.getElementById("common-popup-content");
      if (contentElement) {
        contentElement.scrollTop = 0;
      }

      return lockAndUnlockScroll();
    }
  }, [isOpen]);

  return (
    <>
      <div
        className={`fixed inset-0 z-10 flex justify-center items-center ${
          isOpen ? "" : "hidden"
        }`}
        data-testid="common-popup"
      >
        <div
          className="absolute inset-0 bg-black opacity-50 z-10"
          onClick={closePopup}
        />
        <div className="bg-white min-w-fit w-96 z-10">
          <div
            className="px-5 font-bold text-3xl flex items-center justify-between h-[100px] border-b border-neutral-50"
            data-testid="common-popup-header"
          >
            {header}

            <button onClick={closePopup} className="focus:outline-none">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-6 h-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div
            id="common-popup-content"
            className="max-w-[80vw] max-h-[80vh] overflow-auto"
            data-testid="common-popup-content"
          >
            {content}
          </div>
          <div className="min-h-[22px]">{footer}</div>
        </div>
      </div>
    </>
  );
}
