import { useEffect, useMemo, useState } from "react";

import {
  Building,
  BuildingGroup,
  TagSelectedItem,
  TagSelectType,
  TagSelectTypeName,
} from "../../../types/building";
import { Company } from "../../../types/company";
import { ComponentType } from "../../../types/search";
import { Store } from "../../../types/store";

import { generateAxiosListResponse } from "../../../libs/request";

import { useUserContext } from "../../../contexts/User";
import { useModal } from "../../../contexts/Modal";

import { getBuildingGroupSearch } from "../../../services/buildingService";
import { getCompanyList } from "../../../services/companyService";
import { getServiceGroupStoreList } from "../../../services/ServiceGroupService";

import DefaultButton from "../../DefaultButton";
import DefaultInput from "../../Input/DefaultInput";
import CommonPopup from "../../Popup/CommonPopup";
import { TableColumnType } from "../../Table";
import TagSingleSelector from "../../TagSelector/TagSingleSelector";
import CommonRadio from "../../Input/CommonRadio";
import { SearchCondition } from "../../ListPage";

type Props = {
  selectType: TagSelectType;
  selectedItem?: TagSelectedItem;
  categoryCode?: string;
  onChangeBuilding?: (item: BuildingGroup) => void;
  onChangeCompany?: (item: Company) => void;
  onChangeStore?: (item: Store) => void;
};

export default function TagSingleFilter({
  selectType,
  selectedItem,
  categoryCode,
  onChangeBuilding,
  onChangeCompany,
  onChangeStore,
}: Props) {
  const { showAlert, showConfirm } = useModal();
  const { getCategoryCodeByRole } = useUserContext();
  const [isOpen, setIsOpen] = useState(false);
  const [checkedOption, setCheckedOption] = useState<
    TagSelectedItem | undefined
  >(selectedItem);

  useEffect(() => {
    setCheckedOption(selectedItem);
  }, [selectedItem]);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClosePopup = () => {
    setIsOpen(false);
    setCheckedOption(selectedItem);
  };

  const handleCheckedChange = (item: TagSelectedItem) => {
    setCheckedOption(item);
  };

  const handleApply = () => {
    if (!checkedOption) {
      showAlert("선택된 항목이 없습니다.");
      return;
    }

    showConfirm("적용 하시겠습니까?", async () => {
      if (selectType === "building" && onChangeBuilding) {
        onChangeBuilding(checkedOption as BuildingGroup);
      } else if (selectType === "company" && onChangeCompany) {
        onChangeCompany(checkedOption as Company);
      } else if (selectType === "store" && onChangeStore) {
        onChangeStore(checkedOption as Store);
      } else {
        console.error("Single tag type error");
      }
      handleClosePopup();
    });
  };

  const isEqual = (
    checkedItem: TagSelectedItem | undefined,
    currentItem: TagSelectedItem
  ) => {
    if (!checkedItem) {
      return false;
    }

    if (
      selectType === "building" &&
      "buildingId" in checkedItem &&
      "buildingId" in currentItem
    ) {
      return checkedItem.buildingId === currentItem.buildingId;
    } else if (
      selectType === "company" &&
      "companyId" in checkedItem &&
      "companyId" in currentItem
    ) {
      return checkedItem.companyId === currentItem.companyId;
    } else if (
      selectType === "store" &&
      "storeId" in checkedItem &&
      "storeId" in currentItem
    ) {
      return checkedItem.storeId === currentItem.storeId;
    } else {
      return false;
    }
  };

  const buildingColumnInfo: TableColumnType<BuildingGroup>[] = [
    {
      header: "선택",
      render: (item: BuildingGroup) => {
        return (
          <div className="flex justify-center items-center min-w-[40px]">
            <CommonRadio
              checked={isEqual(checkedOption, item)}
              onChange={() => {
                handleCheckedChange(item);
              }}
              value={item.buildingId}
              dataTestId="building"
            />
          </div>
        );
      },
    },
    { header: "건물 ID", name: "buildingId" },
    { header: "건물명", name: "buildingName" },
  ];

  const companyColumnInfo: TableColumnType<Company>[] = [
    {
      header: "선택",
      render: (item: Company) => (
        <div className="flex justify-center items-center min-w-[40px]">
          <CommonRadio
            checked={isEqual(checkedOption, item)}
            onChange={() => {
              handleCheckedChange(item);
            }}
          />
        </div>
      ),
    },
    { header: "회사 ID", name: "companyId" },
    { header: "회사명", name: "companyName" },
  ];

  const storeColumnInfo: TableColumnType<Store>[] = [
    {
      header: "선택",
      render: (item: Store) => {
        return (
          <div className="flex justify-center items-center min-w-[40px]">
            <CommonRadio
              checked={isEqual(checkedOption, item)}
              onChange={() => {
                handleCheckedChange(item);
              }}
            />
          </div>
        );
      },
    },
    { header: "상점 ID", name: "storeId" },
    { header: "상점명", name: "storeName" },
    { header: "건물 ID", name: "buildingId" },
    { header: "건물명", name: "buildingName" },
  ];

  const buildingComponentList: ComponentType<BuildingGroup>[] = [
    {
      labelName: "건물 ID",
      typeName: "text",
      keyName: "buildingId",
      category: "detailSearch",
      placeholder: "건물 ID를 입력해주세요",
    },
    {
      labelName: "건물명",
      typeName: "text",
      keyName: "buildingName",
      category: "detailSearch",
      placeholder: "건물명을 입력해주세요",
    },
  ];

  const companyComponentList: ComponentType<Company>[] = [
    {
      labelName: "회사 ID",
      typeName: "text",
      keyName: "companyId",
      category: "detailSearch",
      placeholder: "회사 ID를 입력해주세요",
    },
    {
      labelName: "회사명",
      typeName: "text",
      keyName: "companyName",
      category: "detailSearch",
      placeholder: "회사명을 입력해주세요",
    },
  ];

  const storeComponentList: ComponentType<Store>[] = [
    {
      labelName: "건물 ID",
      typeName: "text",
      keyName: "buildingId",
      category: "detailSearch",
      placeholder: "건물 ID를 입력해주세요",
    },
    {
      labelName: "건물명",
      typeName: "text",
      keyName: "buildingName",
      category: "detailSearch",
      placeholder: "건물명을 입력해주세요",
    },
    {
      labelName: "상점 ID",
      typeName: "text",
      keyName: "storeId",
      category: "detailSearch",
      placeholder: "상점 ID를 입력해주세요",
    },
    {
      labelName: "상점명",
      typeName: "text",
      keyName: "name",
      category: "detailSearch",
      placeholder: "상점명을 입력해주세요",
    },
  ];

  const getBuildingListWrapper = useMemo(
    () => async (params: SearchCondition<BuildingGroup>) => {
      const newCategoryCode = getCategoryCodeByRole(categoryCode ?? "");
      if (!newCategoryCode) {
        return generateAxiosListResponse(params, []);
      }

      return getBuildingGroupSearch({
        ...params,
        categoryCode: newCategoryCode,
      });
    },
    []
  );

  const render = () => {
    const title = `${getTagTitle()} 선택`;
    return (
      <>
        {selectType === "building" ? (
          <TagSingleSelector
            getDataApi={getBuildingListWrapper}
            componentList={buildingComponentList}
            columnInfo={buildingColumnInfo}
            title={title}
            closePopup={handleClosePopup}
            handleApply={handleApply}
            selectType={selectType}
          />
        ) : selectType === "company" ? (
          <TagSingleSelector
            getDataApi={getCompanyList}
            componentList={companyComponentList}
            columnInfo={companyColumnInfo}
            title={title}
            closePopup={handleClosePopup}
            handleApply={handleApply}
            selectType={selectType}
          />
        ) : selectType === "store" ? (
          <TagSingleSelector
            getDataApi={getServiceGroupStoreList}
            componentList={storeComponentList}
            columnInfo={storeColumnInfo}
            title={title}
            closePopup={handleClosePopup}
            handleApply={handleApply}
            selectType={selectType}
          />
        ) : null}
      </>
    );
  };

  const getTagTitle = () => {
    return TagSelectTypeName[selectType];
  };

  return (
    <div className="flex gap-2 items-center">
      <DefaultInput
        placeholder={`${getTagTitle()}을(를) 선택해주세요`}
        value={
          selectType === "building"
            ? (selectedItem as Building)?.buildingName ?? ""
            : selectType === "company"
            ? (selectedItem as Company)?.companyName ?? ""
            : (selectedItem as Store)?.storeName ?? ""
        }
        disabled
      />
      <div className="min-w-fit">
        <DefaultButton
          size="tableButton"
          className="h-[38px]"
          onClick={handleOpen}
          testId={`search-${selectType}-tag-single-filter`}
        >
          검색
        </DefaultButton>
      </div>

      {isOpen && (
        <CommonPopup
          header={getTagTitle() + " 선택"}
          content={render()}
          isOpen={isOpen}
          closePopup={handleClosePopup}
        />
      )}
    </div>
  );
}
