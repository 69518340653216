import { RentalDevice } from "../../../../../types/space";
import DefaultInput from "../../../../Input/DefaultInput";
import ListPage from "../../../../ListPage";
import { TableColumnType } from "../../../../Table";
import ToggleButton from "../../../../Input/ToggleButton";
import DefaultButton from "../../../../DefaultButton";
import MinusButtonIcon from "../../../../../svgs/icons/Common/MinusButton";
import PlusButtonIcon from "../../../../../svgs/icons/Common/PlusButton";

type Props = {
  rentalDevice: RentalDevice[];
  usePayment?: boolean;
  onChange: (list: RentalDevice[]) => void;
};

export const initRentalDevice: RentalDevice = {
  appendId: 1,
  deviceName: "",
  price: undefined,
  isActive: true,
};

export default function SpaceDeviceList({
  rentalDevice,
  usePayment = false,
  onChange,
}: Props) {
  const columnInfo: TableColumnType<RentalDevice>[] = [
    {
      header: "제공 기기명",
      render: (item: RentalDevice) => (
        <div className="flex justify-center items-center">
          <DefaultInput
            value={item.deviceName}
            onChange={(value: string) =>
              handleInputChange(value, item, "deviceName")
            }
            placeholder="기기명 입력"
          />
        </div>
      ),
    },
    {
      header: "이용 요금(30분 기준)",
      render: (item: RentalDevice) => (
        <div className="flex justify-center items-center">
          <DefaultInput
            type="number"
            value={usePayment ? item.price?.toString() : "0"}
            onChange={(value: string) =>
              handleInputChange(value, item, "price")
            }
            placeholder="이용 요금(30분 당)"
            unitLabel="원"
            disabled={!usePayment}
          />
        </div>
      ),
    },
    {
      header: "사용여부",
      render: (item: RentalDevice) => (
        <div className="flex justify-center items-center min-w-[80px]">
          {item.rentalDeviceId && (
            <ToggleButton
              value={item.isActive}
              onChange={(value: boolean) => {
                if (item.rentalDeviceId) {
                  handleActiveChange(value, item.rentalDeviceId);
                }
              }}
            />
          )}
        </div>
      ),
    },
    {
      header: "활동",
      render: (item: RentalDevice) => {
        const appendCount =
          rentalDevice.filter((c) => !c.rentalDeviceId).length ?? 0;
        const lastRentalDeviceId =
          rentalDevice[rentalDevice.length - 1].rentalDeviceId;

        return (
          <div className="flex items-center justify-center min-w-icon">
            {(rentalDevice.length ?? 0) > 1 && !item.rentalDeviceId && (
              <DefaultButton
                size="icon"
                onClick={() => {
                  if (item.appendId) handleRemove(item.appendId);
                }}
              >
                <MinusButtonIcon />
              </DefaultButton>
            )}
            {((appendCount === 0 &&
              item.rentalDeviceId === lastRentalDeviceId) ||
              (appendCount > 0 && !item.rentalDeviceId)) && (
              <DefaultButton size="icon" onClick={() => handleAdd(appendCount)}>
                <PlusButtonIcon />
              </DefaultButton>
            )}
          </div>
        );
      },
    },
  ];

  const handleActiveChange = (isActive: boolean, rentalDeviceId: number) => {
    const newData = rentalDevice.map((item) =>
      item.rentalDeviceId === rentalDeviceId ? { ...item, isActive } : item
    );
    onChange(newData);
  };

  const handleInputChange = (
    value: string,
    item: RentalDevice,
    field: keyof RentalDevice
  ) => {
    const newData = [...rentalDevice];
    const index = newData.findIndex((i) => i === item);

    if (field === "price") {
      newData[index][field] = Number(value) as never;
    } else {
      newData[index][field] = value as never;
    }

    onChange(newData);
  };

  const handleAdd = (appendCount: number) => {
    const item: RentalDevice = {
      appendId: appendCount + 1,
      deviceName: "",
      isActive: true,
    };

    onChange([...rentalDevice, item]);
  };

  const handleRemove = (appendId: number) => {
    const filteredData = rentalDevice.filter(
      (item) => item.appendId !== appendId
    );
    const fixedDataCount =
      rentalDevice.filter((item) => item.rentalDeviceId).length ?? 0;

    const fixedData = filteredData?.slice(0, fixedDataCount) ?? [];
    const updatedData =
      filteredData?.slice(fixedDataCount).map((item, index) => ({
        ...item,
        appendId: index + 1,
      })) ?? [];

    onChange([...fixedData, ...updatedData]);
  };

  return (
    <div className="min-w-[700px]">
      <ListPage
        getDataApi={Promise.resolve}
        columnInfo={columnInfo}
        tableTitle="기기 목록"
        selectedMode
        selectedData={rentalDevice}
        hidePagination
      />
    </div>
  );
}
