import { ComponentType } from "../../../../types/search";
import { TableColumnType } from "../../../../components/Table";
import { AccessLog } from "../../../../types/accessLog";
import ListPage from "../../../../components/ListPage";
import { getAccessLogList } from "../../../../services/accessService";


type Props = {};

export default function AccessList(props: Props) {
  const columnInfo: TableColumnType<AccessLog>[] = [
    { header: "이력 ID", name: "logId", width: "id" },
    { header: "접근자 ID", name: "memberId", width: "id" },
    { header: "접근자 이름", name: "name" },
    { header: "접근 일시", name: "createdAt" },
    { header: "화면 ID", name: "programId" },
    { header: "화면명", name: "programName" },
    { header: "처리 내용", name: "description" }
  ];

  const componentList: ComponentType<AccessLog>[] = [
    {
      labelName: "접근일시",
      typeName: "dateRange",
      keyName: "createdAt",
      category: "date",
    },
    {
      labelName: "접근자 ID",
      typeName: "number",
      keyName: "memberId",
      category: "detailSearch",
      placeholder: "접근자 ID 를 입력해주세요",
    },
    {
      labelName: "접근자 이름",
      typeName: "text",
      keyName: "name",
      category: "detailSearch",
      placeholder: "접근자 이름을 입력해주세요",
    },
  ];

  return (
    <ListPage
      getDataApi={getAccessLogList}
      keyId={(item: AccessLog) => item.logId}
      columnInfo={columnInfo}
      componentList={componentList}
      tableTitle="화면 접근 이력 목록"
    />
  )
}