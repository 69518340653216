import { Store } from "../types/store";
import { ServiceGroup, ServiceGroupDto } from "../types/service";

import request, { ListResponse } from "../libs/request";

import { SearchCondition } from "../components/ListPage";
import { getExcelDownloadApi } from "./commonService";

const SERVICE_GROUP_API_PREFIX = "/v2/admin/service/group";

export const getServiceGroupList = (params: SearchCondition<ServiceGroup>) =>
  request.get<ListResponse<ServiceGroup>>(SERVICE_GROUP_API_PREFIX + "/list", {
    params,
  });

export const getServiceGroupListExcel = (
  params: SearchCondition<ServiceGroup>
) => getExcelDownloadApi(SERVICE_GROUP_API_PREFIX + "/list/excel", params);

export const getServiceGroup = (groupId: string) =>
  request.get<ServiceGroup>(SERVICE_GROUP_API_PREFIX + `/${groupId}`);

export const getServiceGroupCategoryList = () =>
  request.get<ServiceGroup[]>(SERVICE_GROUP_API_PREFIX + "/category/list");

export const putServiceGroup = (
  groupId: string,
  data: Partial<ServiceGroupDto>
) => request.put<ServiceGroup>(SERVICE_GROUP_API_PREFIX + `/${groupId}`, data);

export const postServiceGroup = (data: Partial<ServiceGroupDto>) =>
  request.post(SERVICE_GROUP_API_PREFIX, data);

export const getServiceGroupExists = (groupName: string) =>
  request.get(SERVICE_GROUP_API_PREFIX + "/exists", { params: { groupName } });

export const getServiceGroupMemberList = (
  buildingId: string,
  categoryCode: string
) =>
  request.get<ServiceGroup[]>(SERVICE_GROUP_API_PREFIX + "/member/list", {
    params: { buildingId, categoryCode },
  });

export const getServiceGroupStoreList = (params: SearchCondition<Store>) =>
  request.get<ListResponse<Store>>(SERVICE_GROUP_API_PREFIX + "/store/list", {
    params,
  });
