import { HttpStatusCode } from "axios";
import { useState } from "react";
import { useModal } from "../../../../../contexts/Modal";
import {
  validationFunctions,
  validationResultMessage,
} from "../../../../../libs/validations";
import { putMemberRole } from "../../../../../services/memberService";
import {
  ROLE_TYPES,
  ServiceManagerRole,
  getMemberRoleName,
  getMemberRoleType,
  memberRoleLabels,
  serviceManagerRoles,
} from "../../../../../types/member";
import {
  MEMBER_ROLE_OPTIONS,
  MEMBER_ROLE_TYPES,
} from "../../../../../types/comboBoxOption";
import DefaultButton from "../../../../DefaultButton";
import FormHeader from "../../../../Form/Header";
import FormRow from "../../../../Form/Row";
import FormRowLabel from "../../../../Form/RowLabel";
import CommonCheckbox from "../../../../Input/Checkbox";
import DefaultLabel from "../../../../Input/DefaultLabel";
import DefaultSelect from "../../../../SelectBox/DefaultSelect";
import { useUserContext } from "../../../../../contexts/User";

type Props = {
  userId: number;
  userName?: string;
  role?: string;
  closePopup: () => void;
  fetchData: () => void;
};

const initRoles = (role: string) => {
  const arrRole = role.split(",").filter((r) => r !== "");
  const isManager = arrRole.some((role) =>
    serviceManagerRoles.includes(role as ServiceManagerRole)
  );

  return isManager ? (arrRole as ServiceManagerRole[]) : [];
};

export default function RoleDetailView({
  userId,
  userName = "",
  role = "",
  closePopup,
  fetchData,
}: Props) {
  const { userInfo, hasRole } = useUserContext();
  const { showAlert, showConfirm, handleError } = useModal();
  const [selectedRole, setSelectedRole] = useState(getMemberRoleType(role));
  const [roles, setRoles] = useState<ServiceManagerRole[]>(initRoles(role));

  const isRoleSpace = hasRole(["ROLE_SPACE"]);

  const handleUpdate = () => {
    const isManager = selectedRole === MEMBER_ROLE_TYPES.ROLE_MANAGER;
    const errorMessage = validationResultMessage([
      validationFunctions.changedObject(
        role,
        isManager ? roles.join(",") : selectedRole
      ),
      ...(isManager ? [validationFunctions.required(roles, "권한")] : []),
      isManager && roles.length === 1 && roles[0] === "ROLE_FREE_LOCATION"
        ? {
            isValid: false,
            errorMessage: "근무위치 권한만 단독으로 설정할 수 없습니다.",
          }
        : { isValid: true },
    ]);

    if (userInfo?.memberId === userId) {
      showAlert("본인의 등급은 변경할 수 없습니다.");
      return;
    }

    if (errorMessage) {
      showAlert(errorMessage);
      return;
    }

    showConfirm("저장 하시겠습니까?", async () => {
      try {
        const res = await putMemberRole(userId, getRoles());
        if (res.status === HttpStatusCode.Ok) {
          showAlert("저장 되었습니다.");
          closePopup();
          fetchData();
        }
      } catch (err: any) {
        handleError(err, "저장");
      }
    });
  };

  const getRoles = () => {
    if (selectedRole === MEMBER_ROLE_TYPES.ROLE_MANAGER) {
      return roles;
    } else {
      return [selectedRole];
    }
  };

  const handleRoleChange = (role: ServiceManagerRole) => (value: boolean) => {
    setRoles((prev) =>
      value ? [...prev, role] : prev.filter((item) => item !== role)
    );
  };

  const handleSelectChange = (value: string) => {
    setSelectedRole(value);
    setRoles(initRoles(role));
  };

  const renderCheckbox = (label: string, role: ServiceManagerRole) => (
    <CommonCheckbox
      label={label}
      checked={roles.includes(role)}
      onClick={handleRoleChange(role)}
    />
  );

  const renderManagerRoleList = () => {
    const commonRoles = (
      <>
        {renderCheckbox(memberRoleLabels.ROLE_FIELD, "ROLE_FIELD")}
        {renderCheckbox(memberRoleLabels.ROLE_MANAGER, "ROLE_MANAGER")}
        {renderCheckbox(memberRoleLabels.ROLE_CAFETERIA, "ROLE_CAFETERIA")}
        {renderCheckbox(memberRoleLabels.ROLE_MONITORING, "ROLE_MONITORING")}
        {renderCheckbox(
          memberRoleLabels.ROLE_FREE_LOCATION,
          "ROLE_FREE_LOCATION"
        )}
      </>
    );

    const conditionalRoles = !isRoleSpace ? (
      <>
        {renderCheckbox(memberRoleLabels.ROLE_CALL_CENTER, "ROLE_CALL_CENTER")}
        {renderCheckbox(memberRoleLabels.ROLE_SPACE, "ROLE_SPACE")}
      </>
    ) : null;

    return (
      <div className="flex flex-col gap-4">
        {commonRoles}
        {conditionalRoles}
      </div>
    );
  };

  const getRoleOptions = () => {
    const options = isRoleSpace
      ? MEMBER_ROLE_OPTIONS.filter(
          (option) => option.value !== ROLE_TYPES.ROLE_ADMIN
        )
      : MEMBER_ROLE_OPTIONS;

    return options;
  };

  return (
    <div className="flex flex-col gap-5">
      <div className="max-h-[700px] overflow-y-auto">
        <FormHeader title="회원정보" />
        <FormRow>
          <FormRowLabel title="회원이름">
            <DefaultLabel text={userName} />
          </FormRowLabel>
          <FormRowLabel title="회원 ID">
            <DefaultLabel text={userId.toString()} />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="현재 등급">
            <DefaultLabel text={getMemberRoleName(role)} />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="등급">
            <DefaultSelect
              dataTestId={"role"}
              optionList={getRoleOptions()}
              value={selectedRole}
              onChange={handleSelectChange}
            />
          </FormRowLabel>
        </FormRow>
        {selectedRole === MEMBER_ROLE_TYPES.ROLE_MANAGER && (
          <FormRow>
            <FormRowLabel title="권한">{renderManagerRoleList()}</FormRowLabel>
          </FormRow>
        )}
      </div>

      <div className="flex justify-center gap-2">
        <DefaultButton onClick={closePopup}>닫기</DefaultButton>
        <DefaultButton color="primary" onClick={handleUpdate}>
          변경 저장
        </DefaultButton>
      </div>
    </div>
  );
}
