import { FiEdit2 } from "react-icons/fi";
import DefaultButton from "../../../../components/DefaultButton";
import { TableColumnType } from "../../../../components/Table";
import {
  convertReservationTypeToLabel,
  RESERVATION_STATUS_TYPES,
  reservationStatusMap,
  SpaceReservation,
} from "../../../../types/spaceReservation";
import { addDefaultOption } from "../../../../utils/comboBoxUtils";
import {
  PAYMENT_OPTIONS,
  DEFAULT_SELECT_ALL_VALUE,
} from "../../../../types/comboBoxOption";
import { ComboBoxType, ComponentType, DASH } from "../../../../types/search";

import ListPage, { SearchCondition } from "../../../../components/ListPage";
import {
  getSpaceReservationList,
  getSpaceReservationListExcel,
  SpaceReservationListTotal,
} from "../../../../services/spaceReservationService";
import { HttpStatusCode } from "axios";
import SpaceReservationDetailView from "../../../../components/PageComponents/Service/SpaceReservationList/DetailView";
import { buildingGroupCategoryCodeValue } from "../../../../types/building";
import UseYesIcon from "../../../../svgs/icons/UseYn/UseYesIcon";
import UseNoIcon from "../../../../svgs/icons/UseYn/UseNoIcon";
import { useCallback, useState } from "react";
import { useModal } from "../../../../contexts/Modal";
import { RESERVATION_SPACE_OPTIONS } from "@/types/space";

export default function SpaceReservationList() {
  const { handleError } = useModal();

  const [totalInfo, setTotalInfo] = useState<SpaceReservationListTotal>();
  const [refresh, setRefresh] = useState(false);

  const columnInfo: TableColumnType<SpaceReservation>[] = [
    { header: "예약번호", name: "spaceReservationDetailId", width: "id" },
    {
      header: "요금 정책",
      render: (item: SpaceReservation) => (item.usePayment ? "유료" : "무료"),
      width: "70",
    },
    {
      header: "상태",
      render: (item: SpaceReservation) =>
        reservationStatusMap[item.reservationStatus] ?? DASH,
      width: "70",
    },
    {
      header: "취소 구분",
      render: (item: SpaceReservation) =>
        item.reservationStatus === RESERVATION_STATUS_TYPES.CANCELED
          ? item.isCancelledByAdmin
            ? "관리자"
            : "사용자"
          : DASH,
      width: "70",
    },
    { header: "공간명", name: "reservationSpaceName" },
    {
      header: "공간 분류",
      render: (item: SpaceReservation) => (
        <div>{convertReservationTypeToLabel(item.reservationSpaceType)}</div>
      ),
      width: "100",
    },
    {
      header: "이용일시",
      render: (item: SpaceReservation) => (
        <span>
          {item.reservationDate}
          <br />
          {`${item.reservationTimeStart}~${item.reservationTimeEnd}`}
        </span>
      ),
      width: "100",
    },
    {
      header: "신청자 회사/부서",
      render: (item: SpaceReservation) => (
        <span>
          {item.companyName}
          <br />
          {item.usedDepartment}
        </span>
      ),
    },
    { header: "실사용자 이름", name: "actualUserName" },
    {
      header: "이용 요금(원) 공간/기기",
      render: (item: SpaceReservation) =>
        item.usePayment
          ? `${item.totalSpaceDefaultPrice?.toLocaleString() ?? DASH}/${
              item.totalDeviceDefaultPrice?.toLocaleString() ?? DASH
            }`
          : DASH,
      width: "150",
    },
    {
      header: "예정 금액(원)",
      render: (item: SpaceReservation) =>
        item.usePayment
          ? (
              item.totalDeviceDefaultPrice +
              item.totalSpaceDefaultPrice +
              item.vatDefaultPrice
            )?.toLocaleString() ?? DASH
          : DASH,
      width: "100",
    },
    {
      header: "확정 금액(원)",
      render: (item: SpaceReservation) =>
        item.usePayment
          ? item.reservationStatus !== "CANCELED"
            ? item.fixedPrice?.toLocaleString() ?? DASH
            : DASH
          : DASH,
      width: "100",
    },
    {
      header: "확정 수수료(원)",
      render: (item: SpaceReservation) =>
        item.usePayment
          ? item.reservationStatus === RESERVATION_STATUS_TYPES.CANCELED
            ? item.fixedPrice?.toLocaleString() ?? DASH
            : DASH
          : DASH,
      width: "100",
    },
    { header: "접수일시", name: "createdAt", width: "date" },
    {
      header: "만족도 평가 여부",
      render: (item: SpaceReservation) => (
        <div className="flex justify-center items-center">
          {item.isSatisfactionCompleted ? <UseYesIcon /> : <UseNoIcon />}
        </div>
      ),
      width: "icon",
    },
    {
      header: "활동",
      render: (item: SpaceReservation, handler) => (
        <div className="w-full h-full flex gap-2 justify-center items-center min-w-icon">
          <DefaultButton
            size="tableIcon"
            color="blue"
            onClick={() => {
              if (handler?.popup) {
                handler.popup({
                  header: "상세",
                  content: (
                    <SpaceReservationDetailView
                      spaceReservationDetailId={item.spaceReservationDetailId}
                      onRefresh={handleRefresh}
                    />
                  ),
                });
              }
            }}
          >
            <FiEdit2 />
          </DefaultButton>
        </div>
      ),
      width: "icon",
    },
  ];

  const comboBoxIsCanceledOptions: ComboBoxType[] = [
    { value: DEFAULT_SELECT_ALL_VALUE, label: "전체" },
    { value: "USER", label: "사용자" },
    { value: "ADMIN", label: "관리자" },
  ];

  const comboBoxIsSatisfactionOptions: ComboBoxType[] = [
    { value: DEFAULT_SELECT_ALL_VALUE, label: "전체" },
    { value: "true", label: "Y" },
    { value: "false", label: "N" },
  ];

  const componentList: ComponentType<SpaceReservation>[] = [
    {
      labelName: "이용일자",
      typeName: "dateRange",
      keyName: "reservationAt",
      category: "date",
    },
    {
      typeName: "buildingAndFloor",
      keyName: "buildingAndFloor",
      category: "typeSearch",
      buildingGroupCategoryCode: buildingGroupCategoryCodeValue.SPACE,
    },
    {
      labelName: "요금 정책",
      typeName: "comboBox",
      keyName: "usePayment",
      comboBoxOptions: addDefaultOption(PAYMENT_OPTIONS),
      category: "typeSearch",
    },
    {
      labelName: "취소 구분",
      typeName: "comboBox",
      keyName: "cancelType",
      comboBoxOptions: comboBoxIsCanceledOptions,
      category: "typeSearch",
    },
    {
      labelName: "만족도 평가",
      typeName: "comboBox",
      keyName: "isSatisfactionCompleted",
      comboBoxOptions: comboBoxIsSatisfactionOptions,
      category: "typeSearch",
    },
    {
      labelName: "공간 분류",
      typeName: "comboBox",
      keyName: "reservationSpaceType",
      comboBoxOptions: addDefaultOption(RESERVATION_SPACE_OPTIONS),
      category: "typeSearch",
    },
    {
      labelName: "예약번호",
      typeName: "text",
      keyName: "SpaceReservationId",
      category: "detailSearch",
      placeholder: "예약번호를 입력해주세요",
    },
    {
      labelName: "공간명",
      typeName: "text",
      keyName: "spaceName",
      category: "detailSearch",
      placeholder: "공간명을 입력해주세요",
    },
    {
      labelName: "회사명",
      typeName: "text",
      keyName: "companyName",
      category: "detailSearch",
      placeholder: "회사명을 입력해주세요",
    },
    {
      labelName: "실사용자 이름",
      typeName: "text",
      keyName: "realUserName",
      category: "detailSearch",
      placeholder: "실사용자 이름을 입력해주세요",
    },
  ];

  const handleRefresh = () => {
    setRefresh((prev) => !prev);
  };

  const getSpaceReservationListByDate = useCallback(
    async (params: SearchCondition<SpaceReservation>) => {
      try {
        const res = await getSpaceReservationList(params);
        if (res.status === HttpStatusCode.Ok) {
          const info = {
            spacePrice: res.data.spacePrice,
            devicePrice: res.data.devicePrice,
            totalPrice: res.data.totalPrice,
            fixedCompletedPrice: res.data.fixedCompletedPrice,
            fixedCanceledPrice: res.data.fixedCanceledPrice,
          } as SpaceReservationListTotal;
          setTotalInfo(info);

          return { ...res, data: res.data.pageableItems };
        }
      } catch (err: any) {
        handleError(err, "공간예약목록 조회");
        return err;
      }
    },
    [refresh]
  );

  return (
    <ListPage
      getDataApi={getSpaceReservationListByDate}
      excelDownloadApi={getSpaceReservationListExcel}
      keyId={(item: SpaceReservation) => item.spaceReservationDetailId}
      componentList={componentList}
      columnInfo={columnInfo}
      tableTitle="예약 목록"
      listInfo={<RenderTotalPrice info={totalInfo} />}
      needExcelDownloadReason
    />
  );
}

type RenderTotalPriceProps = {
  info?: SpaceReservationListTotal;
};
const RenderTotalPrice = ({ info }: RenderTotalPriceProps) => {
  return info ? (
    <div className="text-base">
      {`[공간 금액: ${info.spacePrice?.toLocaleString() ?? 0}원 / 기기 금액 : ${
        info.devicePrice?.toLocaleString() ?? 0
      }원 / 예정 금액 : ${
        info.totalPrice?.toLocaleString() ?? 0
      }원 / 확정 금액 : ${
        info.fixedCompletedPrice?.toLocaleString() ?? 0
      }원 / 확정 수수료 : ${info.fixedCanceledPrice?.toLocaleString() ?? 0}원]`}
    </div>
  ) : null;
};
