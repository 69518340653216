export interface TabInfo {
  title: string;
  page: JSX.Element;
}

interface Props {
  tabInfo: TabInfo[];
  activeTab: number;
  onTabClick: (tabIndex: number) => void;
}

export default function Tab({ tabInfo, activeTab, onTabClick }: Props) {
  const tabClass = (index: number) =>
    `flex justify-center items-center min-w-[140px] h-[50px] border cursor-pointer text-[16px] ${
      activeTab === index
        ? "bg-white border-x-black border-t-black border-b-white"
        : "bg-brand-tab text-gray-400 border-b-black"
    }`;

  return (
    <div className="flex flex-col gap-5">
      <nav>
        <ul className="flex">
          {tabInfo.map((tab, index) => (
            <li
              key={index}
              className={tabClass(index)}
              onClick={() => onTabClick(index)}
            >
              {tab.title}
            </li>
          ))}
          <li className="w-full border-b border-black"></li>
        </ul>
      </nav>

      <div>{tabInfo[activeTab].page}</div>
    </div>
  );
}
