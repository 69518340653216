import { Nullable } from "./common";
import { FileInfo } from "./file";
import { ComboBoxType } from "./search";

export type Shelter = {
  shelterId: string;
  shelterName: string;
  shelterCoordinateList: EmergencyCoordinate[];
  lat: number;
  lng: number;
  description: string;
  createdByEmail: string;
  createdByName: string;
  createdAt: string;
  imageFiles: File | undefined;
  fileInfo: {
    items: FileInfo[];
  };
  deleteFileInfo?: boolean;
  isExternal?: boolean; // 영역 지정시에 생성되므로 존재 하지 않을 수 있음
};

export type EmergencyCoordinate = {
  lat: number;
  lng: number;
};

export type EvacuationUser = {
  evacueeId: string;
  name: string;
  mobileNumber: string;
  role: string;
  isMember: boolean;
  memberId: number;
  evacuationBuildingId: string;
  evacuationBuildingName: Nullable<string>;
  evacuationCompanyId: string;
  evacuationCompanyName: Nullable<string>;
  evacuationFloorId: string;
  evacuationDepartmentName: Nullable<string>;
  type: Nullable<SituationUserType>;
  evacuationGroupId?: string;
  evacuationGroupName?: string;

  createdBy?: string; // 한글 이름
  createdAt?: string;
  modifiedBy?: string; // 한글 이름
  modifiedAt?: string;

  isManager?: boolean;
  floor?: string;
  isDelete?: boolean;
};

export type EmergencyBuildingGroupRequest = {
  shelterGroupName: string;
  isActive: boolean;
  lat: number;
  lng: number;
  buildingIdList?: string[]; // 그룹에 속한 건물 목록
  createdByEmail?: string;
  createdByName?: string;
  createdAt?: string;
  shelterName?: string;
};

export type EmergencyBuildingGroup = {
  evacuationGroupId: string;
  evacuationGroupName: string;
  buildingCount?: number; // front 에서 셋팅 ?
  shelterName?: string; // TODO : 리스트의 검색 기능 때문에 넣어둔 필드인데, 스펙에서 지원 할수 있을지 체크 필요
  active: boolean;
  isCompleteDefaultShelter: boolean;
  lat: number;
  lng: number;
  buildingList?: EmergencyBuilding[]; // 그룹에 속한 건물 목록
  situationCount?: number;
  createdByEmail: string;
  createdByName: string;
  createdAt: string;
  modifiedBy?: string;
  modifiedAt?: string;
};

export type EmergencyBuilding = {
  buildingId: string;
  buildingName: string;
  defaultShelterId?: string; // 건물에 지정된 기본 대피영역 id
  defaultShelterName?: string; // 건물에 지정된 기본 대피영역 이름
  // viewingShelterName?: string; // 보여줄 대피영역 이름. +n 개 등의 표시용.
  companyShelterMapDtoList: companyShelterMapDto[];
};

export type companyShelterMapDto = {
  companyId: string;
  companyName: string;
  shelterId?: string;
  shelterName?: string;
  companyIsActive?: boolean;
};

export type SituationStatus = "CREATED" | "IN_PROGRESS" | "ENDED";

export type Situation = {
  evacuationId: string;
  evacuationCategoryCode: string;
  evacuationCategoryName: string;
  evacuationGroupId: string;
  evacuationGroupName: string;
  evacuationName: string;
  evacueeCount: number;
  status: SituationStatus;
  incidentAddress: Nullable<string>;
  incidentBuilding: Nullable<string>;
  incidentFloor: Nullable<string>;
  incidentBuildingId: string;
  incidentBuildingName: string;
  pushStepInterval: number;
  pushNoResponseInterval: number;
  evacueeList: EvacuationUser[];
  createdAt: string;
  createdByEmail: string;
  createdByName: string;
  createdBy: number;
  modifiedAt: string;
  modifiedBy: number;
  modifiedByName: string;
};

export type SituationUserType = "LOG" | "MANUAL";

export type SituationAddUser = {
  name: string;
  mobileNumber: string;
  role: string;
  evacuationBuildingName: Nullable<string>;
  evacuationCompanyName: Nullable<string>;
  evacuationDepartmentName: Nullable<string>;
};

export type SituationDeleteUser = {
  evacueeId: string;
  name: string;
  mobileNumber: string;
};

export type EmergencyDashboard = {
  evacuationId: string;
  evacuationName: string;
  evacuationType: string; // fire | ... -> 추후 enum
  evacuationCategoryName: string;
  evacuationCategoryCode: string;
  incidentAddress: string;
  startedAt?: string;
  endedAt?: string;
  totalEvacueeCount: number;
  respondEvacueeCount: number;
};

export type EmergencyDepartment = {
  buildingId: string;
  companyId: string;
  departmentName: string;
};

export type EmergencyDashboardTableRequest = {
  page: number;
  pageSize: number;
} & EmergencyDashboardTableSearch;

export type EmergencyDashboardTableSearch = {
  evacuationGroupId: string;
  evacuationId: string;
  name: string;
  mobileNumber: string;
  role: string;
  evacuationBuildingId: string;
  evacuationCompanyId: string;
  evacuationDepartmentName: string;
  status: EvacuationParticipationStatus;
};

export type EmergencyDashboardTableInfo = {
  evacuationGroupId: string;
  evacuationId: string;
  name: string;
  mobileNumber: string;
  role: string;
  evacuationBuildingName: string;
  evacuationCompanyName: string;
  evacuationDepartmentName: string;
  status: EvacuationParticipationStatus;
  rescueLocation: string;
  rescuedAt: string;
  modifiedBy: number;
  modifiedAt: string;
  verificationType: "GPS" | "NFC" | "QR" | "BLE" | "ADMIN";
};

export enum EvacuationParticipationStatus {
  CREATED_NO_RESPONSE = "CREATED_NO_RESPONSE",
  HELP_ME = "HELP_ME",
  ON_LEAVE_OR_OUT = "ON_LEAVE_OR_OUT",
  EVACUATION_COMPLETE = "EVACUATION_COMPLETE",
}

export type EmergencySituationCount = {
  completeCount: number; // 대피 완료
  helpMeCount: number; // 도와주세요
  onLeaveOrOutCount: number; // 휴가/외근
  noResponseCount: number; // 무응답
};

export type EmergencyDashboardComment = {
  evacuationCommentId: string;
  evacuationId: string;
  comment: string;
  createdBy: number;
  createdByUserName: string;
  deletedBy?: number;
  createdAt: string;
  deletedAt?: string;
};

export type EmergencyDashboardGroup = {
  evacuationId: string;
  evacuationName: string;
  evacuationGroupId: string;
  evacuationGroupName: string;
  buildingList: EmergencyDashboardBuilding[];
};

export type EmergencyDashboardBuilding = {
  buildingId: string;
  buildingName: string;
  companyList: EmergencyDashboardCompany[];
};

export type EmergencyDashboardCompany = {
  companyId: string;
  companyName: string;
  departmentList: string[];
};

export type EmergencyDashboardCommentRequest = {
  comment: string;
};

export type EvacueeStatusHistory = {
  seq: number;
  evacuationId: string;
  evacuationName: string;
  evacueeId: string;
  name: string;
  evacuationEvacueeMapUuid: string;
  firstStatus: EvacuationParticipationStatus;
  status: EvacuationParticipationStatus;
  evacuationBuildingId: string;
  evacuationBuildingName: string;
  evacuationCompanyId: string;
  evacuationCompanyName: string;
  evacuationDepartmentName: string;
  rescuedAt: string;
  rescueLocation: string;
  createdBy: number;
  createdByName: string;
  createdAt: string;
  modifiedBy?: number;
  modifiedByName?: string;
  modifiedAt?: string;
};

export const radioBoxEvacueeIsMaster: ComboBoxType[] = [
  {
    value: "ROLE_EV_MASTER",
    label: "비상대피 마스터",
  },
  {
    value: "ROLE_EV_COM_ADMIN",
    label: "회사관리자",
  },
  {
    value: "ROLE_EV_DEP_ADMIN",
    label: "부서관리자",
  },
  {
    value: "ROLE_EV_USER",
    label: "일반사용자",
  },
];

export const radioBoxSituationPushLevel: ComboBoxType[] = [
  { value: "1", label: "1분" },
  { value: "3", label: "3분" },
  { value: "5", label: "5분" },
];

export const radioBoxSituationPushNoResponse: ComboBoxType[] = [
  { value: "1", label: "1분" },
  { value: "3", label: "3분" },
  { value: "5", label: "5분" },
];

export const radioBoxSituationType: ComboBoxType[] = [
  { value: "fire", label: "화재" },
  { value: "earthquake", label: "지진" },
  { value: "flood", label: "홍수" },
];

export const radioBoxSituationStatus: ComboBoxType[] = [
  { value: "CREATED", label: "준비" },
  { value: "IN_PROGRESS", label: "진행중" },
  { value: "ENDED", label: "종료" },
];

export const convertStatusToText = (status: EvacuationParticipationStatus) => {
  let text: string;
  switch (status) {
    case "EVACUATION_COMPLETE":
      text = "대피완료";
      return text;
    case "HELP_ME":
      text = "도와주세요";
      return text;
    case "ON_LEAVE_OR_OUT":
      text = "휴가/외근";
      return text;
    case "CREATED_NO_RESPONSE":
      text = "무응답";
      return text;
    default:
      return "";
  }
};

export const convertCountTypeToStatus = (
  countType: keyof EmergencySituationCount
) => {
  switch (countType) {
    case "completeCount":
      return EvacuationParticipationStatus.EVACUATION_COMPLETE;
    case "helpMeCount":
      return EvacuationParticipationStatus.HELP_ME;
    case "onLeaveOrOutCount":
      return EvacuationParticipationStatus.ON_LEAVE_OR_OUT;
    case "noResponseCount":
      return EvacuationParticipationStatus.CREATED_NO_RESPONSE;
    default:
      return undefined;
  }
};
