import { useContext } from "react";

import DefaultButton from "@/components/DefaultButton";
import { PopupContext } from "@/contexts/Popup";

import ShelterCreateForm from "../CreateForm";

type Props = {};

export default function EmergencyShelterListTopRight(props: Props) {
  const { openPopup } = useContext(PopupContext);
  return (
    <>
      <DefaultButton
        size="tableTop"
        color="default"
        onClick={() =>
          openPopup({
            header: "대피영역 등록",
            content: <ShelterCreateForm />,
          })
        }
      >
        신규 등록
      </DefaultButton>
    </>
  );
}
