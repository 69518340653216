import React, { useState, useContext, createContext } from "react";
import { User } from "../types/user";
import { buildingGroupCategoryCodeValue } from "../types/building";
import {
  MemberRole,
  ServiceManagerRole,
  serviceManagerRoles,
} from "../types/member";

interface UserContextProps {
  userInfo: User | null;
  login: (userInfo: User) => void;
  logout: () => void;
  getCategoryCodeByRole: (categoryCode: string) => string;
  getUserRole: () => MemberRole;
  hasRole: (role: MemberRole[]) => boolean;
}

export const UserContext = createContext<UserContextProps | null>(null);

export const useUserContext = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUserContext must be used within a UserProvider");
  }
  return context;
};

type Props = { children: React.ReactNode };
export const UserProvider = ({ children }: Props) => {
  const [userInfo, setUserInfo] = useState<User | null>(null);

  const login = (userInfo: User) => {
    setUserInfo(userInfo);
  };

  const logout = () => {
    setUserInfo(null);
  };

  const getCategoryCodeByRole = (categoryCode: string) => {
    let newCategoryCode = categoryCode;

    const isCallCenterCategoryCode = [
      buildingGroupCategoryCodeValue.INCOMMODITY,
      buildingGroupCategoryCodeValue.POST,
    ].some((code) => code.includes(categoryCode));

    if (
      userInfo?.role.includes("ROLE_ADMIN") ||
      ((!categoryCode || isCallCenterCategoryCode) &&
        userInfo?.role.includes("ROLE_CALL_CENTER"))
    ) {
      newCategoryCode = buildingGroupCategoryCodeValue.ALL;
    }
    return newCategoryCode;
  };

  const hasRole = (roles: MemberRole[]): boolean => {
    return roles.some((role) => userInfo?.role.includes(role));
  };

  const getUserRole = (): MemberRole => {
    const roles = userInfo?.role.split(",") || [];

    if (roles.includes("ROLE_ADMIN")) {
      return "ROLE_ADMIN";
    } else if (
      roles.some((role) =>
        serviceManagerRoles.includes(role as ServiceManagerRole)
      )
    ) {
      return "ROLE_MANAGER";
    }

    return "ROLE_USER";
  };

  return (
    <UserContext.Provider
      value={{
        userInfo,
        login,
        logout,
        getCategoryCodeByRole,
        getUserRole,
        hasRole,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
