import { HttpStatusCode } from "axios";
import { useMemo, useState } from "react";
import { useModal } from "../../../contexts/Modal";
import {
  deletePostComment,
  getCommentList,
  patchReportHide,
  patchReportShow,
} from "../../../services/pinelifeService";
import { CommentStatus, PostComment } from "../../../types/comment";
import { DASH } from "../../../types/search";
import DefaultButton from "../../DefaultButton";
import { SearchCondition, useListPageContext } from "../../ListPage";
import CommentEditForm from "../CommentEditForm";
import CommentListPage from "../CommentListPage";
import { CommentTableColumnType } from "../CommentTable";
import { useUserContext } from "../../../contexts/User";

type Props = {
  lifePostId: number;
};

export const NEW_COMMENT_ID = -1;

export default function CommentList({ lifePostId }: Props) {
  const {refreshListPage} = useListPageContext();
  const { showAlert, showConfirm, handleError } = useModal();
  const { userInfo } = useUserContext();
  const [commentStatus, setCommentStatus] = useState<
    Record<number, CommentStatus>
  >({});
  const [refresh, setRefresh] = useState(false);

  const renderEditButton = (item: PostComment) => (
    <DefaultButton
      size="tableIcon"
      color="default"
      onClick={() => {
        setCommentStatus((prev) => ({
          ...prev,
          [item.lifeCommentId]: "edit",
        }));
      }}
    >
      <CommentEditIcon />
    </DefaultButton>
  );

  const renderDeleteButton = (item: PostComment) => (
    <DefaultButton
      size="tableIcon"
      color="default"
      onClick={() => handleDelete(item.lifeCommentId)}
    >
      <CommentTrashIcon />
    </DefaultButton>
  );

  const renderVisibleButton = (item: PostComment) => (
    <DefaultButton
      size="tableIcon"
      color="default"
      onClick={() =>
        handleVisible(item.lifeCommentId, item.isHiddenReportedComment)
      }
    >
      {item.isHiddenReportedComment ? (
        <CommentVisibleFalseIcon />
      ) : (
        <CommentVisibleTrueIcon />
      )}
    </DefaultButton>
  );

  const renderReplyButton = (
    item: PostComment,
    replyComment: (id: number, newItem: PostComment) => void
  ) =>
    item.level === 0 ? (
      <DefaultButton
        size="tableIcon"
        color="default"
        onClick={() => {
          if (replyComment) {
            setCommentStatus((prev) => ({
              ...prev,
              [NEW_COMMENT_ID]: "reply",
            }));
            replyComment(item.lifeCommentId, {
              lifeCommentId: NEW_COMMENT_ID,
              parentCommentId: item.lifeCommentId,
              memberName: userInfo?.name,
              memberNickname: userInfo?.nickname,
              createdBy: userInfo?.memberId,
              level: 1,
            } as PostComment);
          }
        }}
      >
        <CommentReplyIcon />
      </DefaultButton>
    ) : null;

  const columnInfo: CommentTableColumnType<PostComment>[] = [
    {
      header: "구분",
      name: "level",
      render: (item: PostComment) => {
        return (
          <div className="px-2.5 gap-[3px] flex items-center justify-center">
            {item.level === 0 ? (
              "댓글"
            ) : (
              <div className="flex gap-1 pl-4">
                <CommentListReplyIcon />
                답글
              </div>
            )}
          </div>
        );
      },
    },
    {
      header: "작성일시/수정일시",
      name: "createdAt",
      render: (item: PostComment) =>
        item.createdAt ? (
          <span className="text-sm">
            {item.createdAt}
            <br />
            <span className="text-brand-blue">{item.modifiedAt}</span>
          </span>
        ) : (
          DASH
        ),
    },
    {
      header: "별명(이름)\n작성자 Email",
      render: (item: PostComment) => (
        <span>
          {`${item.memberNickname}(${item.memberName})`}
          <br />
          {item.memberEmail}
        </span>
      ),
    },
    {
      header: "좋아요",
      render: (item: PostComment) => item.commentLike?.likeCount ?? DASH,
    },
    {
      header: "내용",
      render: (item: PostComment, replyComment) => (
        <div className="flex justify-center items-center px-10">
          <CommentEditForm
            lifePostId={lifePostId}
            comment={item}
            status={commentStatus[item.lifeCommentId]}
            onStatusChange={handleChange}
            replyComment={replyComment}
            fetchData={handleRefresh}
          />
        </div>
      ),
    },
    {
      header: "관리",
      render: (item: PostComment, replyComment) => {
        const isEditStatus = commentStatus[item.lifeCommentId] === "edit";
        const isCreatedByUser = userInfo?.memberId === item.createdBy;
        const isNotNewComment = item.lifeCommentId !== NEW_COMMENT_ID;

        const editOrDeleteButton = (
          <>
            {isCreatedByUser &&
              (isEditStatus
                ? renderDeleteButton(item)
                : renderEditButton(item))}
          </>
        );
        const visibleButton = <>{!isEditStatus && renderVisibleButton(item)}</>;

        return (
          <div className="w-full h-full flex justify-center items-center">
            {isNotNewComment && (
              <>
                {!item.deletedAt && (
                  <>
                    {editOrDeleteButton}
                    {visibleButton}
                  </>
                )}
                {replyComment && renderReplyButton(item, replyComment)}
              </>
            )}
          </div>
        );
      },
    },
  ];

  const handleDelete = (id: number) => {
    showConfirm("댓글을 삭제하시겠습니까?", async () => {
      try {
        const res = await deletePostComment(id);
        if (res.status === HttpStatusCode.Ok) {
          showAlert("댓글이 삭제되었습니다.");
        }
      } catch (err: any) {
        handleError(err, "댓글 삭제");
      } finally {
        handleChange(id, "default");
        handleRefresh();
      }
    });
  };

  const handleVisible = (id: number, isVisible: boolean) => {
    const message = isVisible
      ? "댓글을 노출하시겠습니까?"
      : "댓글을 숨기시겠습니까?";

    showConfirm(message, async () => {
      try {
        const res = isVisible
          ? await patchReportShow("COMMENT", id)
          : await patchReportHide("COMMENT", id);
        if (res.status === HttpStatusCode.Ok) {
          showAlert(`${isVisible ? "노출" : "숨김"} 처리되었습니다.`);
        }
      } catch (err: any) {
        handleError(err, `댓글 ${isVisible ? "노출" : "숨김"}`);
      } finally {
        handleChange(id, "default");
        handleRefresh();
      }
    });
  };

  const handleChange = (id: number, status: CommentStatus) => {
    setCommentStatus((prev) => ({ ...prev, [id]: status }));
  };

  const getCommentListByPostId = useMemo(
    () => async (params: SearchCondition<PostComment>) => {
      return await getCommentList(lifePostId, params);
    },
    [lifePostId, refresh]
  );

  const handleRefresh = () => {
    refreshListPage();
    setRefresh(!refresh);
  };

  return (
    <div className="flex flex-col gap-2 pt-2.5">
      <CommentEditForm
        lifePostId={lifePostId}
        status="listEdit"
        fetchData={handleRefresh}
      />
      <CommentListPage
        getDataApi={getCommentListByPostId}
        columnInfo={columnInfo}
        tableTitle="댓글 목록"
      />
    </div>
  );
}

export const CommentListReplyIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.6879 10.6876L12.8379 8.53757L10.6879 6.38757"
      stroke="#9F9F9F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.16289 5.31238V6.38738C3.16289 6.95759 3.38941 7.50445 3.79261 7.90766C4.19582 8.31086 4.74268 8.53738 5.31289 8.53738H12.8379"
      stroke="#9F9F9F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const CommentReplyIcon = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.2314 17.9403L19.2314 13.9403L15.2314 9.94034"
      stroke="#9F9F9F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.76929 7.94034V9.94034C4.76929 11.0012 5.07416 12.0186 5.61684 12.7688C6.15951 13.5189 6.89554 13.9403 7.663 13.9403H17.791"
      stroke="#9F9F9F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const CommentEditIcon = () => (
  <svg
    width="25"
    height="26"
    viewBox="0 0 25 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 14.3889V21.5H4V5.5H14"
      stroke="#9F9F9F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.4608 4.53921C18.2418 3.75817 19.5082 3.75816 20.2892 4.53921L20.9608 5.21079C21.7418 5.99184 21.7418 7.25817 20.9608 8.03921L11.5858 17.4142C11.2107 17.7893 10.702 18 10.1716 18L7.5 18L7.5 15.3284C7.5 14.798 7.71071 14.2893 8.08579 13.9142L17.4608 4.53921Z"
      stroke="#9F9F9F"
      strokeWidth="1.5"
    />
    <path d="M16.25 5.75L19.75 9.25" stroke="#9F9F9F" strokeWidth="1.5" />
  </svg>
);

export const CommentVisibleTrueIcon = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.3201 12.685C15.3201 14.431 13.9041 15.846 12.1581 15.846C10.4121 15.846 8.99707 14.431 8.99707 12.685C8.99707 10.938 10.4121 9.52304 12.1581 9.52304C13.9041 9.52304 15.3201 10.938 15.3201 12.685Z"
      stroke="#9F9F9F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.1562 19.9869C15.9642 19.9869 19.4472 17.2489 21.4082 12.6849C19.4472 8.1209 15.9642 5.3829 12.1562 5.3829H12.1602C8.3522 5.3829 4.8692 8.1209 2.9082 12.6849C4.8692 17.2489 8.3522 19.9869 12.1602 19.9869H12.1562Z"
      stroke="#9F9F9F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const CommentVisibleFalseIcon = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.8459 14.8669C9.2709 14.2929 8.9209 13.5129 8.9209 12.6379C8.9209 10.8849 10.3329 9.47186 12.0849 9.47186C12.9519 9.47186 13.7499 9.82286 14.3149 10.3969"
      stroke="#9F9F9F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.1903 13.1989C14.9583 14.4889 13.9423 15.5069 12.6533 15.7409"
      stroke="#9F9F9F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.73996 17.9724C5.15296 16.7264 3.80896 14.9064 2.83496 12.6374C3.81896 10.3584 5.17196 8.52835 6.76896 7.27235C8.35596 6.01635 10.187 5.33435 12.085 5.33435C13.994 5.33435 15.824 6.02635 17.421 7.29135"
      stroke="#9F9F9F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.5327 9.49084C20.2207 10.4048 20.8257 11.4598 21.3347 12.6368C19.3677 17.1938 15.8917 19.9388 12.0847 19.9388C11.2217 19.9388 10.3707 19.7988 9.55273 19.5258"
      stroke="#9F9F9F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.9722 4.74963L4.19824 20.5236"
      stroke="#9F9F9F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const CommentTrashIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21 5H3"
      stroke="#9F9F9F"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M14 4H10"
      stroke="#9F9F9F"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M5 8V21H19C19 19.8182 19 8 19 8"
      stroke="#9F9F9F"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M14 8V17.5"
      stroke="#9F9F9F"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M10 8V17.5"
      stroke="#9F9F9F"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);
