import React, { useContext } from "react";
import { PopupContext } from "../../../contexts/Popup";
import DefaultButton from "../../DefaultButton";
import IncommodityCreateForm from "../CreateForm";
import PermissionWrapper from "../../PermissionWrapper";

type Props = {};

export default function IncommodityListTopRight(props: Props) {
  const { openPopup } = useContext(PopupContext);

  return (
    <PermissionWrapper>
      <DefaultButton
        size="tableTop"
        color="default"
        onClick={() =>
          openPopup({
            header: "신고 접수",
            content: <IncommodityCreateForm />,
          })
        }
      >
        접수 등록
      </DefaultButton>
    </PermissionWrapper>
  );
}
