import React, { useEffect, useState } from "react";

import { DEFAULT_SELECT_ALL_VALUE, evacueeType } from "@/types/comboBoxOption";
import { EvacuationUser } from "@/types/emergency";

import DefaultButton from "@/components/DefaultButton";
import CommonPopup from "@/components/Popup/CommonPopup";
import FormHeader from "@/components/Form/Header";
import FormRow from "@/components/Form/Row";
import FormRowLabel from "@/components/Form/RowLabel";
import DefaultSelect from "@/components/SelectBox/DefaultSelect";
import { useModal } from "@/contexts/Modal";
import { emergencyMemberRoleLabels } from "@/types/member";
import clsx from "clsx";

type Props = {
  evacuee: EvacuationUser;
  onTempChange: (data: Partial<EvacuationUser>) => void;
  disabled: boolean;
};

export default function EvacuationPermissionChangeButton({
  evacuee,
  onTempChange,
  disabled,
}: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const [tempEvacuee, setTempEvacuee] =
    useState<Partial<EvacuationUser>>(evacuee);
  const { showAlert } = useModal();

  useEffect(() => {
    setTempEvacuee(evacuee);
  }, [evacuee]);

  return (
    <>
      <DefaultButton
        className={clsx(
          disabled &&
            "!bg-brand-primary-gray-100 !bg-opacity-10 rounded-none text-sm font-semibold !text-brand-text-black-disabled"
        )}
        onClick={() => setIsOpen(true)}
        disabled={disabled}
      >
        권한 변경
      </DefaultButton>
      <CommonPopup
        isOpen={isOpen}
        closePopup={() => setIsOpen(false)}
        header={"회원 권한 변경"}
        content={
          <>
            <div className="flex flex-col gap-5">
              <div>
                <FormHeader title="회원 정보" />
                <FormRow>
                  <FormRowLabel title="회원 이름">
                    <div className="flex flex-col">
                      <div className="flex gap-2 items-center min-w-[100px]">
                        {tempEvacuee.name}
                      </div>
                    </div>
                  </FormRowLabel>
                  <FormRowLabel title="회원 ID">
                    <div className="flex flex-col">
                      <div className="flex gap-2 items-center min-w-[100px]">
                        {tempEvacuee.memberId ?? "미확인"}
                      </div>
                    </div>
                  </FormRowLabel>
                </FormRow>
                <FormRow>
                  <FormRowLabel title="현재 권한">
                    <div className="flex flex-col">
                      <div className="flex gap-2 items-center min-w-[100px]">
                        {emergencyMemberRoleLabels[
                          evacuee.role as keyof typeof emergencyMemberRoleLabels
                        ] || evacuee.role}
                      </div>
                    </div>
                  </FormRowLabel>
                </FormRow>
                <FormRow>
                  <FormRowLabel title="권한">
                    <DefaultSelect
                      value={tempEvacuee.role}
                      optionList={evacueeType}
                      onChange={(value: string) => {
                        setTempEvacuee((prev) => ({ ...prev, role: value }));
                      }}
                      placeholder="권한을 선택해주세요"
                    />
                  </FormRowLabel>
                </FormRow>
              </div>
              <div className="flex justify-center gap-2 py-5">
                <DefaultButton onClick={() => setIsOpen(false)}>
                  닫기
                </DefaultButton>
                <DefaultButton
                  color="primary"
                  onClick={() => {
                    if (tempEvacuee.role === DEFAULT_SELECT_ALL_VALUE) {
                      showAlert("권한을 선택해주세요");
                      return;
                    }
                    onTempChange(tempEvacuee);
                    setIsOpen(false);
                  }}
                >
                  등록
                </DefaultButton>
              </div>
            </div>
          </>
        }
      />
    </>
  );
}
