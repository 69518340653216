import { SearchCondition } from "../components/ListPage";
import request, { ListResponse } from "../libs/request";
import { Manager } from "../types/manager";

const MANAGER_API_PREFIX = "/v2/admin/manager";

export const getManagerList = (params: SearchCondition<Manager>) =>
  request.get<ListResponse<Manager>>(MANAGER_API_PREFIX + "/member/list", {
    params,
  });

export const patchManagerLock = (memberId: string, lock: boolean) =>
  request.patch(MANAGER_API_PREFIX + `/${memberId}/lock`, { lock });

export const patchManagerPassword = (memberId: string) =>
  request.patch(MANAGER_API_PREFIX + `/${memberId}/password`);
