import { FiEdit2 } from "react-icons/fi";
import DefaultButton from "../../../components/DefaultButton";
import ListPage from "../../../components/ListPage";
import SpaceListTopRight from "../../../components/PageComponents/Service/SpaceList/ListTopRight";
import { TableColumnType } from "../../../components/Table";
import {
  getSpaceBulkExcel,
  getSpaceCompanyBulkExcel,
  getSpaceList,
  getSpaceListExcel,
  getSpaceRentalDeviceBulkExcel,
  postSpaceBulkExcel,
  postSpaceCompanyBulkExcel,
  postSpaceRentalDeviceBulkExcel,
} from "../../../services/spaceService";
import { ComponentType, DASH } from "../../../types/search";
import { RESERVATION_SPACE_OPTIONS, Space, getGender } from "../../../types/space";
import { addDefaultOption } from "../../../utils/comboBoxUtils";
import {
  EXIST_OPTIONS,
  PAYMENT_OPTIONS,
  USAGE_OPTIONS,
  USE_AUTO_CONFIRM_OPTIONS,
  usageYnLabels,
} from "../../../types/comboBoxOption";
import SpaceDetailView from "../../../components/PageComponents/Service/SpaceList/DetailView";
import UseYesIcon from "../../../svgs/icons/UseYn/UseYesIcon";
import UseNoIcon from "../../../svgs/icons/UseYn/UseNoIcon";
import { buildingGroupCategoryCodeValue } from "../../../types/building";
import ExcelFileUploaderButton from "../../../components/FileUploader/ExcelFileUploaderButton";
import DefaultLabel from "../../../components/Input/DefaultLabel";
import { convertReservationTypeToLabel, convertUseAutoConfirmToLabel } from "@/types/spaceReservation";

type Props = {};

export default function SpaceList(props: Props) {
  const columnInfo: TableColumnType<Space>[] = [
    { header: "공간 번호", name: "reservationSpaceId", width: "id" },
    { header: "공간명", name: "reservationSpaceName" },
    { header: "공간 분류", 
      render : (item: Space) => (
        <div>
          {convertReservationTypeToLabel(item.reservationSpaceType)}
        </div>
      ),
      width: "100",
    },
    { header: "승인 정책", 
      render : (item: Space) => (
        <div>
          {convertUseAutoConfirmToLabel(item.useAutoConfirm)}
        </div>
      ),
      width: "70",
    },
    {
      header: "이용성별",
      render: (item: Space) => getGender(item.availableGender),
      width: "70",
    },
    {
      header: "요금정책",
      render: (item: Space) => (item.usePayment ? "유료" : "무료"),
      width: "70",
    },
    {
      header: "운영시간(정가구간)",
      render: (item: Space) => (
        <div>
          {item.availableTimeStart} ~ {item.availableTimeEnd}
          {item.usePayment && (
            <>
              <br />({item.defaultPriceTimeStart} ~ {item.defaultPriceTimeEnd})
            </>
          )}
        </div>
      ),
      width: "150",
    },
    {
      header: "기본 이용시간",
      render: (item: Space) => <span>{item.usableTimeStart}분</span>,
      width: "70",
    },
    {
      header: "이용 요금(원) 기본/추가",
      render: (item: Space) => (
        <span>
          {item.usePayment
            ? `${item.defaultPrice?.toLocaleString() ?? DASH}/${
                item.additionalPrice?.toLocaleString() ?? DASH
              }`
            : "무료"}
        </span>
      ),
      width: "150",
    },
    {
      header: "기기 목록",
      render: (item: Space) => (
        <span>
          {item.rentalDeviceList && item.rentalDeviceList.length > 0
            ? item.rentalDeviceList.length > 1
              ? `${item.rentalDeviceList[0].deviceName} 외 ${
                  item.rentalDeviceList.length - 1
                }개`
              : item.rentalDeviceList[0].deviceName
            : DASH}
        </span>
      ),
    },
    { header: "건물", name: "buildingName" },
    { header: "층", name: "floorName" },
    {
      header: "회의실 연결 여부",
      render: (item: Space) => (
        <div className="flex justify-center items-center">
          {item.isConnection ? <UseYesIcon /> : <UseNoIcon />}
        </div>
      ),
      width: "icon",
    },
    {
      header: "사용 여부",
      render: (item: Space) => (
        <div className="flex items-center justify-center">
          <DefaultLabel
            text={item.isActive ? usageYnLabels.TRUE : usageYnLabels.FALSE}
            color={item.isActive ? "blue" : "gray"}
          />
        </div>
      ),
      width: "yesOrNo",
    },
    { header: "등록일시", name: "createdAt", width: "date" },
    {
      header: "활동",
      render: (item: Space, handler) => (
        <div className="w-full h-full flex gap-2 justify-center items-center">
          <DefaultButton
            size="tableIcon"
            color="blue"
            onClick={() => {
              if (handler?.popup) {
                handler.popup({
                  header: "공간 상세",
                  content: (
                    <SpaceDetailView
                      reservationSpaceId={item.reservationSpaceId}
                    />
                  ),
                });
              }
            }}
          >
            <FiEdit2 />
          </DefaultButton>
        </div>
      ),
      width: "icon",
    },
  ];

  const componentList: ComponentType<Space>[] = [
    {
      labelName: "등록일자",
      typeName: "dateRange",
      keyName: "createdAt",
      category: "date",
    },
    {
      labelName: "요금 정책",
      typeName: "comboBox",
      keyName: "usePayment",
      comboBoxOptions: addDefaultOption(PAYMENT_OPTIONS),
      category: "typeSearch",
    },
    {
      labelName: "기기 유무",
      typeName: "comboBox",
      keyName: "isRentalDevice",
      comboBoxOptions: addDefaultOption(EXIST_OPTIONS),
      category: "typeSearch",
    },
    {
      labelName: "사용 유무",
      typeName: "comboBox",
      keyName: "isActive",
      comboBoxOptions: addDefaultOption(USAGE_OPTIONS),
      category: "typeSearch",
    },
    {
      labelName: "공간 분류",
      typeName: "comboBox",
      keyName: "reservationSpaceType",
      comboBoxOptions: addDefaultOption(RESERVATION_SPACE_OPTIONS),
      category: "typeSearch",
    },
    {
      labelName: "승인 정책",
      typeName: "comboBox",
      keyName: "useAutoConfirm",
      comboBoxOptions: addDefaultOption(USE_AUTO_CONFIRM_OPTIONS),
      category: "typeSearch",
    },
    {
      labelName: "공간 번호",
      typeName: "text",
      keyName: "reservationSpaceId",
      category: "detailSearch",
      placeholder: "공간 번호를 입력해주세요",
    },
    {
      labelName: "공간명",
      typeName: "text",
      keyName: "reservationSpaceName",
      category: "detailSearch",
      placeholder: "공간명을 입력해주세요",
    },
    {
      typeName: "buildingAndFloor",
      keyName: "buildingAndFloor",
      category: "detailSearch",
      buildingGroupCategoryCode: buildingGroupCategoryCodeValue.SPACE,
    },
  ];

  return (
    <ListPage
      getDataApi={getSpaceList}
      excelUpload={
        <>
          <ExcelFileUploaderButton
            title="예약공간"
            actionButtonText="예약공간 일괄 등록"
            getExcelDataApi={getSpaceBulkExcel}
            postExcelDataApi={postSpaceBulkExcel}
          />
          <ExcelFileUploaderButton
            title="예약공간 회사지정"
            actionButtonText="예약공간 회사지정 일괄 등록"
            getExcelDataApi={getSpaceCompanyBulkExcel}
            postExcelDataApi={postSpaceCompanyBulkExcel}
          />
          <ExcelFileUploaderButton
            title="예약공간 대여 물품"
            actionButtonText="예약공간 대여 물품 일괄 등록"
            getExcelDataApi={getSpaceRentalDeviceBulkExcel}
            postExcelDataApi={postSpaceRentalDeviceBulkExcel}
          />
        </>
      }
      keyId={(item: Space) => item.reservationSpaceId}
      excelDownloadApi={getSpaceListExcel}
      componentList={componentList}
      columnInfo={columnInfo}
      tableTitle="공간 목록"
      renderTopRight={<SpaceListTopRight />}
    />
  );
}
