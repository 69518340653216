export type LocalKeyType = "saveEmail" | "jwtToken" | "user";

export const getLocalStorage = (key: LocalKeyType) => {
  return localStorage.getItem(key);
};

export const setLocalStorage = (key: LocalKeyType, value: string) => {
  return localStorage.setItem(key, value);
};

export const removeLocalStorage = (key: LocalKeyType) => {
  localStorage.removeItem(key);

  if (key === "jwtToken") {
    window.dispatchEvent(new Event("tokenRemove"));
  }
};
