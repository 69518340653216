import { useContext } from "react";
import { TableColumnType } from "..";
import { PopupContext } from "../../../contexts/Popup";
import { DASH } from "../../../types/search";

interface Props<T> {
  columns: TableColumnType<T>[];
  data: T[];
  totalColumn: keyof T;
  calculateTax?: boolean;
  calculateTotal?: boolean;
}

export default function TotalTable<T>({
  columns,
  data,
  totalColumn,
  calculateTax = true,
  calculateTotal = true,
}: Props<T>) {
  const { openPopup } = useContext(PopupContext);

  const total = data.reduce((sum, item) => sum + Number(item[totalColumn]), 0);
  const tax = Math.round(calculateTax ? total * 0.1 : 0);

  return (
    <table className="table-fixed border-collapse border-t border-b border-gray-400 w-full">
      <thead className="bg-gray-100">
        <tr>
          {columns.map((column, index) => (
            <th key={index} className="border-gray-400 px-4 py-2">
              {column.header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((item: T, rowIndex) => (
          <tr key={rowIndex}>
            {columns.map((column, index) => (
              <td
                key={index}
                className="border-t border-gray-400 px-4 py-2 text-center"
              >
                {column.render
                  ? column.render(item, { popup: openPopup }, rowIndex)
                  : String(item[column.name as keyof T] ?? DASH)}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
      {calculateTotal && (
        <tfoot className="bg-gray-300 font-bold">
          <tr>
            <td
              colSpan={columns.length - 1}
              className="border-t border-gray-400 px-4 py-2 text-center"
            >
              총 금액
            </td>
            <td className="border-t border-gray-400 px-4 py-2 text-center">
              {(total + tax).toLocaleString()}원
            </td>
          </tr>
        </tfoot>
      )}
      {calculateTax && (
        <tfoot className="bg-gray-300 font-bold">
          <tr>
            <td
              colSpan={columns.length - 1}
              className="border-t border-gray-400 px-4 py-2 text-center"
            >
              부가가치세
            </td>
            <td className="border-t border-gray-400 px-4 py-2 text-center">
              {tax.toLocaleString()}원
            </td>
          </tr>
        </tfoot>
      )}
    </table>
  );
}
