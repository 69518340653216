import { AxiosResponse, HttpStatusCode } from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useModal } from "../../../contexts/Modal";
import { PopupProvider } from "../../../contexts/Popup";
import { ListResponse } from "../../../libs/request";
import { PostComment } from "../../../types/comment";
import { Pageable, initPageable } from "../../../types/pageable";
import { SearchCondition } from "../../ListPage";
import Pagination from "../../Pagination";
import Popup from "../../Popup";
import Spinner from "../../Spinner";
import CommentTable, { CommentTableColumnType } from "../CommentTable";

type Props<T> = {
  getDataApi: (
    searchCondition: SearchCondition<T>
  ) => Promise<AxiosResponse<ListResponse<T>>>;
  excelDownloadApi?: (searchCondition: SearchCondition<T>) => void;
  columnInfo: CommentTableColumnType<T>[];
  tableTitle?: string;
  renderTopRight?: React.ReactNode;
  defaultPageSize?: number;
  defaultPageSizeList?: number[];
  pageLimit?: number;
  defaultContentText?: string;
};

export default function ListPage<T extends PostComment>({
  getDataApi,
  excelDownloadApi,
  columnInfo = [],
  tableTitle,
  renderTopRight,
  defaultPageSize = 10,
  defaultPageSizeList = [10, 20, 30],
  pageLimit = 10,
  defaultContentText,
}: Props<T>) {
  const { handleError } = useModal();
  const [data, setData] = useState<T[]>([]);
  const [pageable, setPageable] = useState<Pageable>(initPageable);
  const [searchCondition, setSearchCondition] = useState<SearchCondition<T>>({
    page: 1,
    pageSize: defaultPageSize ?? defaultPageSizeList[0],
  } as SearchCondition<T>);
  const [isLoading, setIsLoading] = useState(false);

  const getDataFromServer = useCallback(
    async (searchCondition: SearchCondition<T>) => {
      const { sortBy, ...condition } = searchCondition;
      if (sortBy?.name) {
        condition.sort = `${sortBy.name.toString()}.${sortBy.order.toUpperCase()}`;
      }
      try {
        setIsLoading(true);
        const response = await getDataApi(condition as SearchCondition<T>);
        if (response.status === HttpStatusCode.Ok) {
          setData((response.data as ListResponse<T>).items);
          setPageable(response.data.pageable);
        } else {
          throw new Error("ListPage data load fail");
        }
      } catch (err: any) {
        handleError(err, "목록 조회");
      } finally {
        setIsLoading(false);
      }
    },
    [getDataApi]
  );

  const refreshListPage = () => getDataFromServer(searchCondition);

  useEffect(() => {
    getDataFromServer(searchCondition);
  }, [searchCondition, getDataFromServer]);

  return (
    <PopupProvider>
      {isLoading && <Spinner />}
      <div className="flex flex-col gap-5">
        <div className="bg-white rounded-[3px] shadow">
          <CommentTable
            data={data}
            columnInfo={columnInfo}
            tableTitle={tableTitle}
            searchCondition={searchCondition}
            setSearchCondition={setSearchCondition}
            renderTopRight={renderTopRight}
            defaultPageSizeList={defaultPageSizeList}
            excelDownloadApi={excelDownloadApi}
            total={pageable.total}
            pageable={pageable}
            defaultContentText={defaultContentText}
          />

          <Pagination
            totalPages={pageable.totalPages}
            currentPage={pageable.page}
            onPageChange={(page) =>
              setSearchCondition((prev) => ({ ...prev, page }))
            }
            pageLimit={pageLimit}
          />
        </div>
        <Popup refreshListPage={refreshListPage} />
      </div>
    </PopupProvider>
  );
}
