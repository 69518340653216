import { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { CiWarning, CiTimer } from "react-icons/ci";
import { MdOutlineRefresh } from "react-icons/md";
import { Label, Pie, PieChart, Sector, Tooltip } from "recharts";
import {
  deleteEmergencyDashboardComment,
  getEmergencyDashboard,
  getEmergencyDashboardCommentList,
  getEmergencyDashboardDetail,
  getEmergencyDashboardGroupInfo,
  postEmergencyDashboardComment,
  putEmergencyDashboardComment,
} from "@/services/emergencyService";
import {
  EmergencyDashboard,
  EmergencyDashboardComment,
  EmergencyDashboardCommentRequest,
  EmergencyDashboardGroup,
  EmergencyDepartment,
  EmergencySituationCount,
  EvacuationParticipationStatus,
  convertCountTypeToStatus,
} from "@/types/emergency";
import { useModal } from "@/contexts/Modal";
import {
  calculateTimeDifference,
  compareDate,
  formatDate,
  getElapsedTime,
} from "@/utils/dateUtils";
import Spinner from "../Spinner";
import clsx from "clsx";
import { HttpStatusCode } from "axios";
import { ComboBoxType } from "@/types/search";
import { IoIosArrowForward } from "react-icons/io";
import { PopupContext } from "@/contexts/Popup";
import Popup from "../Popup";
import DashboardEvacueeList from "../DashboardEvacueeList";
import { PieSectorDataItem } from "recharts/types/polar/Pie";

type Props = {};

const dateFormat = "yyyy/MM/dd/HH:mm";

const DEFAULT_INTERVAL_TIME = 10000;

export default function Dashboard(props: Props) {
  const params = useParams();
  const { showAlert, showConfirm, handleError } = useModal();
  const { openPopup } = useContext(PopupContext);
  const [updatedTime, setUpdatedTime] = useState<string>("");
  const [status, setStatus] = useState<"CREATED" | "IN_PROGRESS" | "ENDED">(
    "CREATED"
  );
  const [evacuationId, setEvacuationId] = useState<string | undefined>(
    undefined
  );
  const [intervalTime, setIntervalTime] = useState<number>(
    DEFAULT_INTERVAL_TIME
  );

  const [evacuationGroupId, setEvacuationGroupId] = useState<
    string | undefined
  >(undefined);
  const [dashboardInfo, setDashboardInfo] = useState<EmergencyDashboard>({
    evacuationId: "",
    evacuationName: "",
    incidentAddress: "",
    respondEvacueeCount: 0,
    totalEvacueeCount: 0,
    evacuationCategoryCode: "",
    evacuationCategoryName: "",
  } as EmergencyDashboard);
  const [comment, setComment] = useState<string>("");
  const [commentList, setCommentList] = useState<EmergencyDashboardComment[]>(
    []
  );
  const [dashboardGroup, setDashboardGroup] = useState<
    EmergencyDashboardGroup | undefined
  >(undefined);
  const [dashboardDetail, setDashboardDetail] = useState<
    EmergencySituationCount | undefined
  >(undefined);
  const [searchDetail, setSearchDetail] = useState<
    Partial<EmergencyDepartment>
  >({}); // 디테일 검색 조건
  const [activeIndex, setActiveIndex] = useState<number | undefined>(undefined);
  const [modifyComment, setModifyComment] = useState<string | undefined>(
    undefined
  );

  const [modifyCommentContent, setModifyCommentContent] = useState<string>("");

  const [buildingOptions, setBuildingOptions] = useState<ComboBoxType[]>([]);

  const [infoLoading, setInfoLoading] = useState<boolean>(false);
  const [detailLoading, setDetailLoading] = useState<boolean>(false);
  const [commentLoading, setCommentLoading] = useState<boolean>(false);

  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const statusRef = useRef<"CREATED" | "IN_PROGRESS" | "ENDED">("CREATED");

  const isLoading = infoLoading || detailLoading || commentLoading;

  const getChartTitle = (dashboardCountKey: string) => {
    switch (dashboardCountKey) {
      case "completeCount":
        return "대피완료";
      case "helpMeCount":
        return "도와주세요";
      case "onLeaveOrOutCount":
        return "휴가/외근";
      case "noResponseCount":
        return "무응답";
      default:
        return "";
    }
  };

  const getChartColor = (dashboardCountKey: string) => {
    switch (dashboardCountKey) {
      case "completeCount":
        return "#00CDC1";
      case "helpMeCount":
        return "#FF4343";
      case "onLeaveOrOutCount":
        return "#0496FF";
      case "noResponseCount":
        return "#CFCFCF";
      default:
        return "";
    }
  };

  const getChartColorBGCss = (dashboardCountKey: string) => {
    switch (dashboardCountKey) {
      case "completeCount":
        return "bg-[#00CDC1]";
      case "helpMeCount":
        return "bg-[#FF4343]";
      case "onLeaveOrOutCount":
        return "bg-[#0496FF]";
      case "noResponseCount":
        return "bg-[#CFCFCF]";
      default:
        return "";
    }
  };

  const getChartBackgroundColor = (dashboardCountKey: string) => {
    switch (dashboardCountKey) {
      case "completeCount":
        return "bg-[#00B8AD] bg-opacity-20"; // 녹색, 20% 투명도
      case "helpMeCount":
        return "bg-[#EEA9A9] bg-opacity-40"; // 붉은 계열, 40% 투명도
      case "onLeaveOrOutCount":
        return "bg-[#0083DF] bg-opacity-20"; // 파란색, 20% 투명도
      case "noResponseCount":
        return "bg-[#EDEDED]"; // 회색, 투명도 없음
      default:
        return "";
    }
  };

  // 마우스가 올라갔을 때 해당 파이 조각을 더 크게
  const onPieEnter = (_: any, index: number) => {
    setActiveIndex(index);
  };

  // 마우스가 나갔을 때 다시 원래 크기로
  const onPieLeave = () => {
    setActiveIndex(undefined);
  };

  const renderCustomLabelLine = (props: any) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, outerRadius, percent } = props;

    // 파이 조각의 외곽 경계에서 라벨을 시작하기 위한 좌표 계산
    const x = cx + (outerRadius + 10) * Math.cos(-midAngle * RADIAN);
    const y = cy + (outerRadius + 10) * Math.sin(-midAngle * RADIAN);

    // 라벨의 끝 위치 계산
    const labelX = cx + (outerRadius + 30) * Math.cos(-midAngle * RADIAN);
    const labelY = cy + (outerRadius + 30) * Math.sin(-midAngle * RADIAN);

    // 라벨의 위치에 따른 정렬
    const textAnchor = labelX > cx ? "start" : "end";

    return (
      <>
        {/* 파이 조각 외곽에서 라벨 라인 그리기 */}
        <line x1={x} y1={y} x2={labelX} y2={labelY} stroke="black" />
        {/* 라벨 텍스트 */}
        <text
          className="font-bold"
          x={labelX}
          y={labelY}
          fill="black"
          textAnchor={textAnchor}
          fontSize={14}
        >
          {`${(percent * 100).toFixed(1)}%`}
        </text>
      </>
    );
  };

  const renderChartOptions = () => {
    if (!dashboardDetail) return;

    const dataList = (
      Object.keys(dashboardDetail) as (keyof EmergencySituationCount)[]
    ).map((dashboardCountKey) => ({
      name: getChartTitle(dashboardCountKey),
      value: dashboardDetail[dashboardCountKey],
      color: getChartColorBGCss(dashboardCountKey),
      backgroundColor: getChartBackgroundColor(dashboardCountKey),
      keyValue: dashboardCountKey,
    }));
    const sortedList = [];
    const totalEvacueeCount = dashboardInfo.totalEvacueeCount;

    const helpMe = dataList.find((data) => data.keyValue === "helpMeCount");
    const complete = dataList.find((data) => data.keyValue === "completeCount");
    const leaveOut = dataList.find(
      (data) => data.keyValue === "onLeaveOrOutCount"
    );
    const noResponse = dataList.find(
      (data) => data.keyValue === "noResponseCount"
    );

    if (helpMe && complete && leaveOut && noResponse) {
      sortedList.push(helpMe);
      sortedList.push(complete);
      sortedList.push(leaveOut);
      sortedList.push(noResponse);
    }

    return (
      <div className="flex flex-row justify-between">
        {sortedList.map((data) => (
          <div
            key={data.name}
            className={clsx(
              "flex w-[168px] rounded-[5px] cursor-pointer",
              data.backgroundColor
            )}
            onClick={() => {
              const status = convertCountTypeToStatus(data.keyValue);
              openEvacueeTable(status);
            }}
          >
            <div className="flex flex-col w-full gap-1 h-full py-4">
              <div className="flex flex-row w-full justify-between px-3 items-end">
                <div className="font-semibold">{data.name}</div>
                <div
                  className={clsx(
                    `w-[12px] h-[12px] rounded-[10px] mb-1`,
                    data.color
                  )}
                />
              </div>
              <div className="flex flex-row justify-between px-3 items-end">
                <div className="font-bold text-xl">{data.value}명</div>
                <div className="font-semibold text-sm">
                  {numberToPercent(data.value, totalEvacueeCount)}%
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const CustomPieChart = () => {
    if (!dashboardDetail) return;

    const dataList = (
      Object.keys(dashboardDetail) as (keyof EmergencySituationCount)[]
    )
      .filter((key) => dashboardDetail[key] > 0)
      .map((dashboardCountKey) => ({
        name: getChartTitle(dashboardCountKey),
        value: dashboardDetail[dashboardCountKey],
        fill: getChartColor(dashboardCountKey),
        keyValue: dashboardCountKey,
      }));

    const handleClickGraph = (data: any) => {
      const status = convertCountTypeToStatus(data.keyValue);
      openEvacueeTable(status);
    };

    return (
      <PieChart width={650} height={580}>
        <Pie
          data={dataList}
          dataKey="value"
          nameKey="name"
          cx="50%"
          cy="50%"
          innerRadius={160}
          fill="#8884d8"
          label={renderCustomLabelLine} // 커스텀 라벨 라인
          labelLine={false}
          onMouseEnter={onPieEnter}
          onMouseLeave={onPieLeave}
          activeIndex={activeIndex}
          activeShape={({ outerRadius = 0, ...props }: PieSectorDataItem) => (
            <Sector {...props} outerRadius={outerRadius + 10} />
          )}
          style={{ outline: "none", cursor: "pointer" }}
          onClick={handleClickGraph}
        >
          <Label
            value="전체 인원 응답률"
            position="center"
            className="label-top"
            fontSize={18}
          />
        </Pie>
        <Tooltip />
      </PieChart>
    );
  };

  const getDashboardInfo = async (evacuationId: string) => {
    try {
      setInfoLoading(true);
      const response = await getEmergencyDashboard(evacuationId);
      if (response.status === HttpStatusCode.Ok) {
        setDashboardInfo(response.data);
        setUpdatedTime(formatDate(new Date(), dateFormat));
      } else {
        showAlert(`대시보드 정보 요청에 실패했습니다. + ${response.status}`);
      }
    } catch (err: any) {
      const error = err.response?.data?.error;

      if (error) {
        showAlert(err.response?.data?.error);
      } else {
        handleError(err, "비상대피 대시보드");
      }
    } finally {
      setInfoLoading(false);
    }
  };

  const getDashboardCommentList = async (evacuationId: string) => {
    try {
      setCommentLoading(true);
      const response = await getEmergencyDashboardCommentList(evacuationId);
      if (response.status === HttpStatusCode.Ok) {
        setCommentList(response.data);
      } else {
        showAlert(`대시보드 정보 요청에 실패했습니다. + ${response.status}`);
      }
    } catch (err: any) {
      const error = err.response?.data?.error;

      if (error) {
        showAlert(err.response?.data?.error);
      } else {
        handleError(err, "비상대피 대시보드");
      }
    } finally {
      setCommentLoading(false);
    }
  };

  const getDashboardDetail = async (evacuationId: string) => {
    try {
      setDetailLoading(true);
      const response = await getEmergencyDashboardDetail(
        evacuationId,
        searchDetail
      );
      if (response.status === HttpStatusCode.Ok) {
        setDashboardDetail(response.data);
      } else {
        showAlert(
          `대시보드 상세 정보 요청에 실패했습니다. + ${response.status}`
        );
      }
    } catch (err: any) {
      const error = err.response?.data?.error;

      if (error) {
        showAlert(err.response?.data?.error);
      } else {
        handleError(err, "비상대피 대시보드");
      }
    } finally {
      setDetailLoading(false);
    }
  };

  // 조직도 내용 가져오기
  const getDashboardGroupInfo = async (evacuationId: string) => {
    try {
      setDetailLoading(true);
      const response = await getEmergencyDashboardGroupInfo(evacuationId);
      if (response.status === HttpStatusCode.Ok) {
        setDashboardGroup(response.data);
        setEvacuationGroupId(response.data.evacuationGroupId);
        if (response.data.buildingList.length > 0) {
          const buildingListForOptions = response.data.buildingList.map(
            (building) =>
              ({
                value: building.buildingId,
                label: building.buildingName,
              } as ComboBoxType)
          );
          setBuildingOptions(buildingListForOptions);
        }
      } else {
        showAlert(`조직 정보 요청에 실패했습니다. + ${response.status}`);
      }
    } catch (err: any) {
      const error = err.response?.data?.error;

      if (error) {
        showAlert(err.response?.data?.error);
      } else {
        handleError(err, "비상대피 대시보드");
      }
    } finally {
      setDetailLoading(false);
    }
  };

  const handleClickBuilding = (buildingId: string) => {
    if (searchDetail.buildingId === buildingId) {
      // 빌딩 해제 -> 전체검색 처럼.
      setSearchDetail({});
    } else {
      // 이하 조건은 초기화
      setSearchDetail({ buildingId: buildingId });
    }
  };

  const handleClickCompany = (buildingId: string, companyId: string) => {
    if (searchDetail.buildingId === buildingId) {
      // 같은 빌딩 내 회사 변경
      if (searchDetail.companyId === companyId) {
        setSearchDetail((prev) => ({
          ...prev,
          companyId: undefined,
          departmentName: undefined,
        }));
      } else {
        setSearchDetail((prev) => ({
          ...prev,
          companyId: companyId,
          departmentName: undefined,
        }));
      }
    } else {
      // 다른 빌딩의 또는 최초의 회사 변경 -> 빌딩, 회사 둘다 설정
      setSearchDetail({
        buildingId: buildingId,
        companyId: companyId,
        departmentName: undefined,
      });
    }
  };

  const handleClickDepartment = (
    buildingId: string,
    companyId: string,
    departmentName: string
  ) => {
    // 1. 같은 회사 내의 변경?
    if (
      searchDetail.buildingId === buildingId &&
      searchDetail.companyId === companyId
    ) {
      // 1-1 : 같은 부서 클릭 -> 해제
      if (searchDetail.departmentName === departmentName) {
        setSearchDetail((prev) => ({
          ...prev,
          departmentName: undefined,
        }));
      } else {
        setSearchDetail((prev) => ({
          ...prev,
          departmentName: departmentName,
        }));
      }
    }

    // 다른 회사, 건물 선택시 초기화 되기 때문에 다른 조건은 필요 없다.
  };

  const renderGroupTree = () => {
    if (dashboardGroup) {
      return (
        <div className="flex flex-col w-[360px] pr-4">
          <div className="flex flex-row justify-end">
            <div
              className="flex items-center p-2 pl-4 rounded-[8px] text-white font-bold w-full bg-brand-default-gray cursor-pointer"
              // onClick={handleClickAll}
              onClick={() => setSearchDetail({})}
            >
              조직전체
            </div>
          </div>

          <div className="flex flex-col">
            {dashboardGroup.buildingList.map((building) => (
              <div key={building.buildingId} className="flex flex-col">
                <div className="flex flex-row justify-end">
                  <div
                    className={clsx(
                      "relative flex items-center p-2 pl-4 rounded-[8px] border text-black font-bold w-full ml-4 mt-2 cursor-pointer",
                      searchDetail.buildingId === building.buildingId &&
                        "bg-[#B7B7B8]"
                    )}
                    onClick={() => handleClickBuilding(building.buildingId)}
                  >
                    <div className="bg-brand-default-gray absolute w-[8px] h-full left-0 rounded-tl-[8px] rounded-bl-[8px]" />
                    <div
                      className={clsx(
                        "flex items-center justify-center transform transition-transform duration-300",
                        {
                          "rotate-0": !(
                            searchDetail.buildingId === building.buildingId
                          ),
                          "rotate-90":
                            searchDetail.buildingId === building.buildingId,
                        }
                      )}
                    >
                      <IoIosArrowForward className="w-[20px] h-[20px]" />
                    </div>
                    {building.buildingName}
                  </div>
                </div>

                <div className="flex flex-col">
                  {searchDetail.buildingId === building.buildingId &&
                    building.companyList.map((company) => (
                      <div className="flex flex-col" key={company.companyId}>
                        <div className="flex flex-row justify-end">
                          <div
                            className={clsx(
                              "relative flex items-center p-2 pl-4 rounded-[8px] border text-black font-bold w-full ml-8 mt-2 cursor-pointer",
                              searchDetail.buildingId === building.buildingId &&
                                searchDetail.companyId === company.companyId &&
                                "bg-[#B7B7B8]"
                            )}
                            onClick={() =>
                              handleClickCompany(
                                building.buildingId,
                                company.companyId
                              )
                            }
                          >
                            <div className="bg-[#707F8A] absolute w-[8px] h-full left-0 rounded-tl-[8px] rounded-bl-[8px]" />
                            <div
                              className={clsx(
                                "flex items-center justify-center transform transition-transform duration-300",
                                {
                                  "rotate-0": !(
                                    searchDetail.buildingId ===
                                      building.buildingId &&
                                    searchDetail.companyId === company.companyId
                                  ),
                                  "rotate-90":
                                    searchDetail.buildingId ===
                                      building.buildingId &&
                                    searchDetail.companyId ===
                                      company.companyId,
                                }
                              )}
                            >
                              <IoIosArrowForward className="w-[20px] h-[20px]" />
                            </div>
                            {company.companyName}
                          </div>
                        </div>

                        <div className="flex flex-col">
                          {searchDetail.companyId === company.companyId &&
                            company.departmentList.map((department, index) => (
                              <div
                                className="flex flex-row justify-end"
                                key={index}
                              >
                                <div
                                  className={clsx(
                                    "relative flex items-center p-2 pl-4 rounded-[8px] border text-black font-bold w-full ml-12 mt-2 cursor-pointer",
                                    searchDetail.buildingId ===
                                      building.buildingId &&
                                      searchDetail.companyId ===
                                        company.companyId &&
                                      searchDetail.departmentName ===
                                        department &&
                                      "bg-[#B7B7B8]"
                                  )}
                                  onClick={() =>
                                    handleClickDepartment(
                                      building.buildingId,
                                      company.companyId,
                                      department
                                    )
                                  }
                                >
                                  <div className="bg-[#D9D9D9] absolute w-[8px] h-full left-0 rounded-tl-[8px] rounded-bl-[8px]" />
                                  {department}
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      );
    }
  };

  const numberToPercent = (number1: number, number2: number) => {
    if (number2 === 0) {
      return 100;
    }
    return ((number1 / number2) * 100).toFixed(1);
  };

  useEffect(() => {
    if (evacuationId) {
      getDashboardDetail(evacuationId);
    }
  }, [searchDetail]);

  useEffect(() => {
    if (params.id) {
      setEvacuationId(params.id);
    }
  }, [params]);

  useEffect(() => {
    if (evacuationId) {
      refreshData();
      getDashboardGroupInfo(evacuationId);

      // 화면을 안보게 됐을때 인터벌을 수행하지 않도록
      const handleVisibilityChange = () => {
        if (document.visibilityState !== "visible") {
          if (intervalRef.current) {
            clearInterval(intervalRef.current);
          }
        } else {
          if (intervalRef.current) {
            clearInterval(intervalRef.current);
          }
          if (statusRef.current === "IN_PROGRESS") {
            intervalRef.current = setInterval(() => {
              refreshData();
            }, intervalTime);
          }
        }
      };

      document.addEventListener("visibilitychange", handleVisibilityChange);

      return () => {
        document.removeEventListener(
          "visibilitychange",
          handleVisibilityChange
        );
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
        }
      };
    }
  }, [evacuationId]);

  useEffect(() => {
    statusRef.current = status;
  }, [status]);

  const responseRate = numberToPercent(
    dashboardInfo.respondEvacueeCount,
    dashboardInfo.totalEvacueeCount
  );

  const noResponseRate = numberToPercent(
    dashboardInfo.totalEvacueeCount - dashboardInfo.respondEvacueeCount,
    dashboardInfo.totalEvacueeCount
  );

  const handleChangeComment = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setComment(event.target.value);
  };

  const handleModifyCommentKeyDown = (
    event: React.KeyboardEvent<HTMLTextAreaElement>
  ) => {
    if (event.key === "Enter") {
      if (modifyComment && modifyCommentContent !== "") {
        handleClickCommentModify(modifyComment);
      }
    }
  };

  const commentItem = (commentInfo: EmergencyDashboardComment) => {
    const beforeEnded =
      compareDate(
        new Date(dashboardInfo.endedAt as string),
        new Date(commentInfo.createdAt)
      ) === 1;
    const endedCss = dashboardInfo.endedAt
      ? beforeEnded
        ? ""
        : "opacity-50"
      : "";
    return (
      <div key={commentInfo.evacuationCommentId} className="p-2 w-full">
        <div
          className={clsx(
            "bg-brand-default-gray-2 rounded-[8px] p-2 text-sm w-full",
            endedCss
          )}
        >
          <div className="flex flex-row justify-between text-gray-600 text-xs w-full">
            <div className="flex flex-row">
              <div>
                {formatDate(
                  new Date(commentInfo.createdAt),
                  "yyyy.MM.dd HH:mm"
                )}
              </div>
              {/* <div className="text-black ml-1">{elapsedTime + "전"}</div> */}
            </div>
            {modifyComment === commentInfo.evacuationCommentId ? (
              <div className="flex flex-row gap-1">
                <button
                  className="w-[26px] text-blue-600 font-bold underline"
                  onClick={() => {
                    setModifyComment(undefined);
                    setModifyCommentContent(""); // 초기 설정
                  }}
                >
                  취소
                </button>
                <div className="border-brand-cursor-over border-l w-[0px]" />
                <button
                  className="w-[26px] text-blue-600 font-bold underline"
                  onClick={() => {
                    if (modifyComment && modifyCommentContent !== "") {
                      showConfirm("해당 코멘트를 수정 하시겠습니까?", () =>
                        handleClickCommentModify(modifyComment)
                      );
                    } else if (modifyComment && modifyCommentContent === "") {
                      showConfirm("해당 코멘트를 삭제 하시겠습니까?", () =>
                        handleClickCommentDelete(modifyComment)
                      );
                    }
                  }}
                >
                  저장
                </button>
              </div>
            ) : (
              <div className="flex flex-row gap-1">
                <button
                  className="w-[26px] text-blue-600 font-bold underline"
                  onClick={() => {
                    setModifyComment(commentInfo.evacuationCommentId);
                    setModifyCommentContent(commentInfo.comment); // 초기 설정
                  }}
                >
                  수정
                </button>
                <div className="border-brand-cursor-over border-l w-[0px]" />
                <button
                  className="w-[26px] text-blue-600 font-bold underline"
                  onClick={() =>
                    showConfirm("해당 코멘트를 삭제 하시겠습니까?", () =>
                      handleClickCommentDelete(commentInfo.evacuationCommentId)
                    )
                  }
                >
                  삭제
                </button>
              </div>
            )}
          </div>
          {modifyComment === commentInfo.evacuationCommentId ? (
            <textarea
              className="resize-none w-full h-9 border-b p-1 text-sm w-[175px] border text-xs"
              placeholder="코멘트 입력"
              value={modifyCommentContent}
              onKeyDown={handleModifyCommentKeyDown}
              onChange={(event) => setModifyCommentContent(event.target.value)}
            />
          ) : (
            <div className="mt-1 pt-3 border-t border-gray-400 text-xs w-full break-words whitespace-normal">
              {commentInfo.comment}
            </div>
          )}
        </div>
      </div>
    );
  };

  const handleClickCommentSubmit = async () => {
    if (comment !== "" && evacuationId) {
      try {
        const response = await postEmergencyDashboardComment(evacuationId, {
          comment: comment,
        } as EmergencyDashboardCommentRequest);
        if (response.status === HttpStatusCode.Ok) {
          // 성공
        } else {
          showAlert(`코멘트 등록에 실패했습니다. + ${response.status}`);
        }
      } catch (err: any) {
        const error = err.response?.data?.error;

        if (error) {
          showAlert(err.response?.data?.error);
        } else {
          handleError(err, "비상대피 대시보드");
        }
      } finally {
        setComment("");
        getDashboardCommentList(evacuationId);
      }
    }
  };

  const handleClickCommentDelete = async (evacuationCommentId: string) => {
    try {
      const response = await deleteEmergencyDashboardComment(
        evacuationCommentId
      );
      if (response.status === HttpStatusCode.Ok) {
        showAlert("삭제 되었습니다.");
      } else {
        showAlert(`코멘트 삭제에 실패했습니다. + ${response.status}`);
      }
    } catch (err: any) {
      const error = err.response?.data?.error;

      if (error) {
        showAlert(err.response?.data?.error);
      } else {
        handleError(err, "비상대피 대시보드");
      }
    } finally {
      if (evacuationId) {
        getDashboardCommentList(evacuationId);
      }
    }
  };

  const handleClickCommentModify = async (evacuationCommentId: string) => {
    try {
      const response = await putEmergencyDashboardComment(
        evacuationCommentId,
        modifyCommentContent
      );
      if (response.status === HttpStatusCode.Ok) {
        showAlert("수정 되었습니다.");
      } else {
        showAlert(`코멘트 수정에 실패했습니다. + ${response.status}`);
      }
    } catch (err: any) {
      const error = err.response?.data?.error;

      if (error) {
        showAlert(err.response?.data?.error);
      } else {
        handleError(err, "비상대피 대시보드");
      }
    } finally {
      setModifyComment(undefined);
      setModifyCommentContent("");
      if (evacuationId) {
        getDashboardCommentList(evacuationId);
      }
    }
  };

  const refreshData = () => {
    if (evacuationId) {
      getDashboardInfo(evacuationId);
      getDashboardDetail(evacuationId);
      getDashboardCommentList(evacuationId);
    }
  };

  const handleClickRefresh = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }

    refreshData();
    if (status === "IN_PROGRESS") {
      intervalRef.current = setInterval(() => {
        refreshData();
      }, intervalTime);
    }
  };

  useEffect(() => {
    if (evacuationId && status === "IN_PROGRESS") {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }

      intervalRef.current = setInterval(() => {
        refreshData();
      }, intervalTime);

      return () => {
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
        }
      };
    }
  }, [searchDetail, evacuationId, status]);

  useEffect(() => {
    let currentStatus: "CREATED" | "IN_PROGRESS" | "ENDED";
    if (!dashboardInfo.startedAt) {
      currentStatus = "CREATED";
    } else if (dashboardInfo.startedAt && !dashboardInfo.endedAt) {
      currentStatus = "IN_PROGRESS";
    } else {
      currentStatus = "ENDED";
    }
    setStatus(currentStatus);
  }, [dashboardInfo]);

  const renderTitleBadge = () => {
    let color: string;
    let text: string;
    if (status === "CREATED") {
      color = "text-brand-primary-gray-100 bg-neutral-20";
      text = "준비";
    } else if (status === "IN_PROGRESS") {
      color = "text-brand-neutral-00 bg-brand-primary-magenta";
      text = "진행중";
    } else {
      color = "text-brand-primary-gray-100 bg-neutral-20";
      text = "종료";
    }

    const css =
      "flex items-center justify-center h-[24px] w-[60px] py-1 px-2 rounded-[12px] ml-3 font-semibold " +
      color;
    return (
      <div className={css} data-testid={"dashboard-status-badge"}>
        {text}
      </div>
    );
  };

  const renderGrayBlock = (comment: string) => {
    return (
      <div className="flex justify-center items-center w-full h-full bg-gray-300 rounded-[3px] shadow-md text-white font-bold">
        {comment}
      </div>
    );
  };

  const openEvacueeTable = (status?: EvacuationParticipationStatus) => {
    if (buildingOptions.length > 0 && evacuationId && evacuationGroupId) {
      openPopup({
        header: "상황 참여 인원",
        content: (
          <DashboardEvacueeList
            buildingOptions={buildingOptions}
            evacuationId={evacuationId}
            evacuationGroupId={evacuationGroupId}
            status={status}
          />
        ),
      });
    } else {
      showAlert("데이터를 불러오는 중입니다. 잠시 후 시도 해주세요.");
    }
  };

  const renderSelectedTree = () => {
    if (dashboardGroup) {
      const selectedBuilding = dashboardGroup?.buildingList.find(
        (building) => building.buildingId === searchDetail.buildingId
      );

      const selectedCompany = selectedBuilding?.companyList.find(
        (company) => company.companyId === searchDetail.companyId
      );

      const selectedDepartment = selectedCompany?.departmentList.find(
        (department) => department === searchDetail.departmentName
      );

      return (
        <div className="flex flex-col">
          <div className="flex flex-row pl-2 items-center truncate">
            <div
              className="flex flex-shrink-0 text-xs cursor-pointer hover:underline"
              onClick={() => setSearchDetail({})}
            >
              전체
            </div>
            {selectedBuilding && (
              <div className="flex flex-row items-center text-xs truncate">
                <div>&nbsp;&gt;&nbsp;</div>
                <div
                  className="text-xs truncate cursor-pointer hover:underline"
                  title={selectedBuilding.buildingName}
                  onClick={() => {
                    setSearchDetail((prev) => ({
                      ...prev,
                      companyId: undefined,
                      departmentName: undefined,
                    }));
                  }}
                >
                  {selectedBuilding.buildingName}
                </div>
              </div>
            )}
          </div>
          <div className="flex flex-row pl-2 items-center truncate pl-4 justify-end">
            {selectedCompany && (
              <div className="flex flex-row items-center text-xs truncate">
                <div>&nbsp;&gt;&nbsp;</div>
                <div
                  className="text-xs truncate cursor-pointer hover:underline"
                  title={selectedCompany.companyName}
                  onClick={() => {
                    setSearchDetail((prev) => ({
                      ...prev,
                      departmentName: undefined,
                    }));
                  }}
                >
                  {selectedCompany.companyName}
                </div>
              </div>
            )}
            {selectedDepartment && (
              <div className="flex flex-row items-center text-xs truncate">
                <div>&nbsp;&gt;&nbsp;</div>
                <div className="text-xs truncate" title={selectedDepartment}>
                  {selectedDepartment}
                </div>
              </div>
            )}
          </div>
        </div>
      );
    }
  };

  return (
    <div className="flex w-full min-h-screen justify-center bg-brand-default p-2 min-w-[1500px]">
      <div className="flex flex-col gap-4">
        {isLoading && <Spinner />}
        <div className="flex w-[1440px] h-[80px] mb-2 border-b border-gray-500 items-center">
          <div className="flex text-2xl items-center font-bold pl-4">
            비상대피 대시보드
          </div>
          {renderTitleBadge()}
        </div>
        <div className="flex flex-row gap-4">
          {/*왼쪽 정보 칸*/}
          <div className="flex flex-col w-[1170px] h-[850px] gap-4">
            <div className="flex flex-row w-full gap-4">
              <div className="flex flex-col bg-white w-[400px] h-[117px] px-3 rounded-[3px] shadow-md">
                <div className="flex flex-row justify-between mt-3">
                  <div className="bg-brand-default text-brand-primary-magenta py-1 px-2 rounded-[8px] text-xs font-semibold">
                    {dashboardInfo.evacuationCategoryName}
                  </div>
                  <div className="flex flex-row items-center text-xs">
                    <div className="text-gray-400">{updatedTime}</div>
                    <MdOutlineRefresh
                      className="ml-1 cursor-pointer"
                      onClick={handleClickRefresh}
                    />
                  </div>
                </div>
                <div
                  className="font-bold mt-2 truncate text-sm"
                  title={dashboardInfo.evacuationName}
                >
                  {dashboardInfo.evacuationName}
                </div>
                <div className="flex flex-row items-center mt-1 text-xs text-gray-500">
                  <CiWarning />
                  <div
                    className="truncate ml-1"
                    title={dashboardInfo.incidentAddress}
                  >
                    {dashboardInfo.incidentAddress}
                  </div>
                </div>
                {dashboardInfo.startedAt && (
                  <div className="flex flex-row items-center mt-1 text-xs text-gray-500">
                    <CiTimer />
                    <div className="ml-1 flex flex-row">
                      {dashboardInfo.startedAt}
                      <div className="ml-1">{"발생"}</div>
                      {status !== "ENDED" ? (
                        <div className="ml-1">
                          {"(" +
                            calculateTimeDifference(dashboardInfo.startedAt) +
                            "경과)"}
                        </div>
                      ) : (
                        <div className="ml-1">
                          {"(" +
                            getElapsedTime(
                              dashboardInfo.startedAt,
                              dashboardInfo.endedAt as string
                            ) +
                            " 소요)"}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>

              <div className="flex flex-col bg-white w-[752px] h-[117px] px-3 rounded-[3px] shadow-md">
                <div className="flex flex-row mt-3 justify-between items-center">
                  <div className="flex flex-col w-full">
                    <div className="flex flex-row items-center justify-between w-full">
                      <div className="flex flex-row gap-1.5 items-center">
                        <div className="font-bold">전체 인원 응답률</div>
                        <div className="flex justify-center items-center px-4 h-[22px] bg-brand-default-gray-2 rounded-[14px] text-xs">
                          <div className="w-full">
                            상황 참여 인원 : {dashboardInfo.totalEvacueeCount}명
                          </div>
                        </div>
                      </div>

                      <div
                        className="flex justify-center items-center px-4 h-[22px] bg-brand-default-gray rounded-[5px] text-xs text-white cursor-pointer"
                        onClick={() => {
                          openEvacueeTable();
                        }}
                      >
                        상황별 인원 상세보기
                      </div>
                    </div>

                    <div className="flex flex-col mt-2">
                      <div className="text-xs flex flex-row items-center">
                        <div
                          className={`w-[10px] h-[10px] rounded-[8px] mr-1 bg-brand-primary-pink`}
                        />
                        응답 인원 - {dashboardInfo.respondEvacueeCount} 명 (
                        {responseRate}% )
                      </div>
                      <div className="text-xs flex flex-row items-center">
                        <div
                          className={`w-[10px] h-[10px] rounded-[8px] mr-1 bg-gray-300`}
                        />
                        무응답 -{" "}
                        {dashboardInfo.totalEvacueeCount -
                          dashboardInfo.respondEvacueeCount}{" "}
                        명 ({noResponseRate}% )
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex justify-center items-center h-5">
                  {/*그래프 자리*/}
                  <div className="flex items-center w-full mt-5">
                    <div className="relative flex-grow h-5 bg-gray-300 overflow-hidden flex items-center rounded-[15px]">
                      <div
                        className="absolute flex items-center top-0 left-0 h-full bg-brand-primary-pink"
                        style={{
                          width: `${responseRate}%`,
                        }}
                      >
                        <span className="absolute ml-4 left-1/2 transform -translate-x-1/2 font-semibold text-xs text-white">
                          {responseRate === "0.0" ? "" : responseRate + "%"}
                        </span>
                      </div>
                      <div className="absolute right-2 ml-4 text-gray-700 font-semibold text-xs text-white">
                        {noResponseRate === "0.0" ? "" : noResponseRate + "%"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-row bg-white w-full h-[717px] rounded-[3px] shadow-md">
              <div className="flex flex-col w-[394px] my-2 mx-2">
                <div className="flex flex-row items-center justify-between pr-8">
                  <div className="flex m-3 font-bold flex-shrink-0">
                    조직단위 상황 정보 확인
                  </div>
                  {renderSelectedTree()}
                </div>
                <div className="m-3 w-[364px] h-[600px] overflow-y-auto overflow-x-hidden">
                  {renderGroupTree()}
                </div>
              </div>

              <div className="flex w-[760px]">
                <div className="flex p-3">
                  <div className="border rounded-[8px]">
                    <div className="flex flex-col gap-5 p-3">
                      {renderChartOptions()}
                      <div className="flex w-[710px] items-center justify-center">
                        {dashboardDetail && CustomPieChart()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*오른쪽 커멘트*/}
          <div className="flex w-[256px] h-[850px]">
            <div className="flex flex-col w-full bg-white rounded-[3px] shadow-md py-2">
              <div className="font-bold my-1 px-3">진행 상황 코멘트</div>

              <div className="px-3">
                <div className="border-t border-gray-400" />
                <div className="flex flex-row my-2 h-9 justify-between">
                  <textarea
                    className="resize-none w-full h-9 border-b p-1 text-sm w-[175px] border text-xs"
                    placeholder="코멘트 입력"
                    value={comment}
                    onChange={(event) => handleChangeComment(event)}
                  />
                  <button
                    className="w-12 p-1 bg-gray-200 text-sm text-gray-500 hover:bg-gray-300"
                    onClick={handleClickCommentSubmit}
                  >
                    등록
                  </button>
                </div>
              </div>

              <div className="overflow-y-auto w-full">
                {commentList.map((commentInfo) => commentItem(commentInfo))}
              </div>
            </div>
          </div>
        </div>
        <Popup refreshListPage={async () => {}} />
      </div>
    </div>
  );
}
