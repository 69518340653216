import {
  Post,
  PostContentType,
  PostPostRequest,
  Report,
} from "./../types/post";
import request, { ListResponse, objectToFormData } from "../libs/request";
import { SearchCondition } from "../components/ListPage";
import { LifeCategoryData, ReportCategory } from "../types/category";
import { PostComment } from "../types/comment";
import { getExcelDownloadApi } from "./commonService";

const POST_API_PREFIX = "/v2/admin/pinelife";

export const getPostList = (params: SearchCondition<Post>) => {
  const { buildingId, companyId, ...otherParams } = params;

  const newParams = {
    ...otherParams,
    ...(buildingId && { buildingId: buildingId.join(",") }),
    ...(companyId && { companyId: companyId.join(",") }),
  };

  return request.get<ListResponse<Post>>("/v2/admin/pinelife/post/list", {
    params: newParams,
  });
};

export const getPostListExcel = (params: SearchCondition<Post>) => {
  const { buildingId, companyId, ...otherParams } = params;

  const newParams = {
    ...otherParams,
    ...(buildingId && { buildingId: buildingId.join(",") }),
    ...(companyId && { companyId: companyId.join(",") }),
  };

  return getExcelDownloadApi(POST_API_PREFIX + "/post/list/excel", newParams);
};

export const postPost = (postPostRequest: PostPostRequest) =>
  request.post("/v2/admin/pinelife/post", objectToFormData(postPostRequest));

export const postPostList = (postPostRequest: PostPostRequest) =>
  request.post("/v2/admin/pinelife/post/list", objectToFormData(postPostRequest));

export const getCategoryList = (buildingId: string = "") =>
  request.get<LifeCategoryData[]>("/v2/admin/pinelife/category/list", {
    params: { buildingId },
  });

export const getPost = (postId: number) =>
  request.get<Post>(`/v2/admin/pinelife/post/${postId}`);

export const putPost = (lifePostId: number, data: Partial<Post>) =>
  request.put(POST_API_PREFIX + `/post/${lifePostId}`, objectToFormData(data));

export const getCommentList = (
  lifePostId: number,
  params: SearchCondition<PostComment>
) =>
  request.get<ListResponse<PostComment>>(
    POST_API_PREFIX + `/comment/${lifePostId}`,
    { params }
  );

export const postPostComment = (data: Partial<PostComment>) =>
  request.post(POST_API_PREFIX + `/comment`, data);

export const deletePostComment = (lifeCommentId: number) =>
  request.delete(POST_API_PREFIX + `/comment/${lifeCommentId}`);

export const putPostComment = (
  lifeCommentId: number,
  data: Partial<PostComment>
) => request.put(POST_API_PREFIX + `/comment/${lifeCommentId}`, data);

export const getReportList = (params: SearchCondition<Report>) => {
  const { buildingId, companyId, ...otherParams } = params;

  const newParams = {
    ...otherParams,
    ...(buildingId && { buildingId: buildingId.join(",") }),
    ...(companyId && { companyId: companyId.join(",") }),
  };

  return request.get<ListResponse<Report>>("/v2/admin/pinelife/report/list", {
    params: newParams,
  });
};

export const getReportListExcel = (params: SearchCondition<Report>) => {
  const { buildingId, companyId, ...otherParams } = params;

  const newParams = {
    ...otherParams,
    ...(buildingId && { buildingId: buildingId.join(",") }),
    ...(companyId && { companyId: companyId.join(",") }),
  };

  return getExcelDownloadApi("/v2/admin/pinelife/report/list/excel", newParams);
};

export const getReportCategoryList = () =>
  request.get<ReportCategory>("/v2/admin/pinelife/report/category");

export const patchReportHide = (
  contentType: PostContentType,
  contentId: number
) =>
  request.patch(POST_API_PREFIX + `/report/hide/${contentType}/${contentId}`);

export const patchReportShow = (
  contentType: PostContentType,
  contentId: number
) =>
  request.patch(POST_API_PREFIX + `/report/show/${contentType}/${contentId}`);
