import React, { useCallback } from "react";
import { SearchCondition } from "../../ListPage";
import { FaSortAmountDownAlt, FaSortAmountUp } from "react-icons/fa";

interface Props<T> {
  name: keyof T;
  searchCondition?: SearchCondition<T>;
  setSearchCondition?: React.Dispatch<React.SetStateAction<SearchCondition<T>>>;
}

export default function SortButton<T>({
  name,
  searchCondition,
  setSearchCondition,
}: Props<T>) {
  const handleClick = useCallback(() => {
    if (setSearchCondition) {
      setSearchCondition((prev: SearchCondition<T>) => ({
        ...prev,
        sortBy: {
          name: name,
          order:
            prev.sortBy?.name !== name
              ? "desc"
              : prev.sortBy?.order === "asc"
              ? "desc"
              : "asc",
        },
      }));
    }
  }, [name, setSearchCondition]);

  const isColumnSortBy =
    searchCondition?.sortBy?.name && searchCondition?.sortBy?.name === name;

  return (
    <button
      className={`${
        isColumnSortBy ? "text-gray-700" : "text-gray-400"
      } cursor-pointer`}
      onClick={handleClick}
    >
      {isColumnSortBy && searchCondition?.sortBy?.order === "asc" ? (
        <FaSortAmountUp />
      ) : (
        <FaSortAmountDownAlt />
      )}
    </button>
  );
}
