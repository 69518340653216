import { SearchCondition } from "../components/ListPage";
import request, { ListResponse, objectToFormData } from "../libs/request";
import { Company } from "../types/company";
import { Service, ServiceList } from "../types/service";
import { getExcelDownloadApi } from "./commonService";

const COMPANY_API_PREFIX = "/v2/admin/company";

export const getCompanyList = (params: SearchCondition<Company>) =>
  request.get<ListResponse<Company>>(COMPANY_API_PREFIX + "/list", {
    params,
  });
export const getCompanyListExcel = (params: SearchCondition<Company>) =>
  getExcelDownloadApi(COMPANY_API_PREFIX + "/list/excel", params);

export const getCompany = (companyId: string) =>
  request.get<Company>(COMPANY_API_PREFIX + `/${companyId}`);

export const getCompanyExist = (companyName: string) =>
  request.get<Company>(COMPANY_API_PREFIX + `/exists`, {
    params: { companyName },
  });

export const putCompany = (companyId: string, data: Partial<Company>) =>
  request.put<Company>(COMPANY_API_PREFIX + `?companyId=${companyId}`, data);

export const postCompany = (data: Partial<Company>) =>
  request.post<Company>(COMPANY_API_PREFIX, data);

export const getCompanyServiceList = (companyId: string) =>
  request.get<ServiceList>(COMPANY_API_PREFIX + `/${companyId}/service/list`);

export const getCompanyService = (companyId: string, serviceId: string) =>
  request.get<Service>(
    COMPANY_API_PREFIX + `/${companyId}/service/${serviceId}`
  );

export const putCompanyService = (
  companyId: string,
  serviceId: string,
  data: Partial<Service>
) =>
  request.put(
    COMPANY_API_PREFIX + `/${companyId}/service/${serviceId}`,
    objectToFormData(data)
  );

export const postCompanyService = (companyId: string, data: Partial<Service>) =>
  request.post(
    COMPANY_API_PREFIX + `/${companyId}/service`,
    objectToFormData(data)
  );

export const patchCompanyService = (
  companyId: string,
  data: Partial<Service>[]
) => request.patch(COMPANY_API_PREFIX + `/${companyId}/service/list`, data);

export const postCompanyBulkExcel = (formData: FormData) =>
  request.postForm(COMPANY_API_PREFIX + "/list", formData);

export const getCompanyBulkExcelForm = () =>
  request.get(COMPANY_API_PREFIX + "/excel/upload-form", {
    responseType: "blob",
    params: {},
  });
