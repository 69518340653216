import { HttpStatusCode } from "axios";
import { useContext, useEffect, useState } from "react";

import {
  BuildingGroup,
  TagSelectedItem,
  Toilet,
  ToiletCompartment,
  buildingGroupCategoryCodeValue,
} from "../../../../../../types/building";
import { ComboBoxType } from "../../../../../../types/search";
import {
  USAGE_OPTIONS,
  DEFAULT_SELECT_ALL_VALUE,
} from "../../../../../../types/comboBoxOption";
import {
  TAG_GROUP_TYPE_ID,
  TAG_ID_REGEX,
  TAG_ID_REGEX_COMMENT,
  Tag,
  ToiletTag,
} from "../../../../../../types/tag";

import {
  validationFunctions,
  validationResultMessage,
} from "../../../../../../libs/validations";
import { toComboBoxType } from "../../../../../../utils/comboBoxUtils";

import { PopupContext } from "../../../../../../contexts/Popup";
import { useModal } from "../../../../../../contexts/Modal";

import {
  getBuilding,
  getBuildingFloor,
  getToilet,
  getToiletList,
} from "../../../../../../services/buildingService";
import {
  getTag,
  getToiletTagList,
  putTag,
} from "../../../../../../services/tagService";

import DefaultButton from "../../../../../DefaultButton";
import FormHeader from "../../../../../Form/Header";
import FormRow from "../../../../../Form/Row";
import FormRowLabel from "../../../../../Form/RowLabel";
import DefaultInput from "../../../../../Input/DefaultInput";
import RadioButtonGroup from "../../../../../Input/RadioButtonGroup";
import ListPage from "../../../../../ListPage";
import DefaultSelect from "../../../../../SelectBox/DefaultSelect";
import Spinner from "../../../../../Spinner";
import { TableColumnType } from "../../../../../Table";
import TagSingleFilter from "../../../../../TagFilter/TagSingleFilter";
import { TOILET_COMPARTMENT_TAG_TYPE_ID } from "../CreateForm";
import CommonRadio from "../../../../../Input/CommonRadio";
import DefaultLabel from "../../../../../Input/DefaultLabel";

type Props = { id: number };

export default function ToiletTagDetailView({ id }: Props) {
  const { refreshAndClosePopup } = useContext(PopupContext);
  const { showAlert, showConfirm, handleError } = useModal();
  const [toiletTag, setToiletTag] = useState<Partial<Tag>>({});
  const [floorOptions, setFloorOptions] = useState<ComboBoxType[]>([]);
  const [toiletOptions, setToiletOptions] = useState<ComboBoxType[]>([]);
  const [selectedTag, setSelectedTag] = useState<TagSelectedItem>();
  const [isLoading, setIsLoading] = useState(false);
  const [toiletCompartmentId, setToiletCompartmentId] = useState("");
  const [toiletCompartments, setToiletCompartments] = useState<
    ToiletCompartment[]
  >([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setIsLoading(true);

      const response = await getTag(id);

      if (response.status === HttpStatusCode.Ok) {
        const data = response.data;
        setToiletTag(data);
        setToiletCompartmentId(data.toiletCompartmentId);

        if (data.buildingSpaceId) {
          const buildingResponse = await getBuilding(data.buildingId);
          if (buildingResponse.status === HttpStatusCode.Ok) {
            setSelectedTag({
              buildingId: data.buildingId,
              buildingName: buildingResponse.data.buildingName,
            } as BuildingGroup);
          }
        }

        if (data.buildingId) {
          fetchFloor(data.buildingId);
          fetchToilet(data.buildingId, data.buildingFloorId);
        }

        if (
          data.tagTypeId === TOILET_COMPARTMENT_TAG_TYPE_ID &&
          data.spaceToiletId
        ) {
          fetchToiletCompartment(data.spaceToiletId);
        }
      }
    } catch (err: any) {
      handleError(err, "조회");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchToiletCompartment = async (spaceToiletId: string) => {
    try {
      setIsLoading(true);

      const response = await getToilet(spaceToiletId);
      const data: Partial<ToiletTag> = {
        buildingId: toiletTag.buildingId,
        buildingFloorId: toiletTag.buildingFloorId,
        spaceToiletId: toiletTag.spaceToiletId,
      };
      const responseTag = await getToiletTagList(data);

      if (response.data && "spaceToiletCompartment" in response.data) {
        const toiletCompartment = (response.data as Toilet)
          .spaceToiletCompartment;

        const options = toiletCompartment.map((item) => ({
          ...item,
          connected: responseTag.data?.items.find(
            (tag) => tag.toiletCompartmentId === item.toiletCompartmentId
          )
            ? true
            : false,
        }));

        setToiletCompartments(options);
      } else {
        showAlert("화장실 칸 정보를 불러오는 중 오류가 발생했습니다.");
      }
    } catch (err: any) {
      handleError(err, "화장실 칸 정보 조회");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchToilet = async (buildingId: string, buildingFloorId: string) => {
    try {
      setIsLoading(true);

      const response = await getToiletList({ buildingId, buildingFloorId });

      if (response.status === HttpStatusCode.Ok) {
        const options = toComboBoxType(
          response.data.items,
          "spaceToiletId",
          "toiletName"
        );

        setToiletOptions(options);
      } else {
        showAlert("화장실 정보를 불러오는 중 오류가 발생했습니다.");
      }
    } catch (err: any) {
      handleError(err, "화장실 정보");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchFloor = async (buildingId: string) => {
    try {
      setIsLoading(true);

      const response = await getBuildingFloor(buildingId);

      if (response.data) {
        const options = response.data.map((item) => ({
          value: item.buildingFloorId,
          label: item.floorName,
        }));

        setFloorOptions(options);
      }
    } catch (err: any) {
      handleError(err, "층 정보 조회");
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdate = () => {
    const errorMessage = validationResultMessage([
      validationFunctions.required(toiletTag.buildingId, "위치"),
      validationFunctions.required(toiletTag.buildingFloorId, "층"),
      validationFunctions.required(toiletTag.spaceToiletId, "화장실"),
      validationFunctions.required(toiletTag.tagTypeId, "태그 유형"),
      validationFunctions.required(toiletTag.tagName, "태그명"),
      validationFunctions.matchesRegex(
        toiletTag.tagId,
        TAG_ID_REGEX,
        "태그 ID",
        TAG_ID_REGEX_COMMENT
      ),
      ...(toiletTag.tagTypeId === TOILET_COMPARTMENT_TAG_TYPE_ID
        ? [
            validationFunctions.required(
              toiletTag.toiletCompartmentId,
              "화장실 칸"
            ),
          ]
        : []),
    ]);

    if (errorMessage) {
      showAlert(errorMessage);
    } else {
      showConfirm("수정 하시겠습니까?", updateData);
    }
  };

  const updateData = async () => {
    try {
      const data = {
        id: toiletTag.id,
        tagId: toiletTag.tagId,
        tagName: toiletTag.tagName,
        tagGroupTypeId: TAG_GROUP_TYPE_ID.TOILET,
        tagTypeId: toiletTag.tagTypeId,
        tagLocation: toiletTag.tagLocation,
        buildingSpaceId: toiletTag.buildingSpaceId,
        buildingFloorId: toiletTag.buildingFloorId,
        toiletCompartmentId: toiletTag.toiletCompartmentId,
        spaceToiletId: toiletTag.spaceToiletId,
        isActive: toiletTag.isActive,
      } as Partial<Tag>;

      const response = await putTag(data);
      if (response.status === HttpStatusCode.Ok) {
        showAlert("수정 되었습니다.");
        refreshAndClosePopup();
      }
    } catch (err: any) {
      handleError(err, "수정");
    }
  };

  const handleBuildingChange = (item: BuildingGroup) => {
    if (item.buildingId && item.buildingName) {
      setToiletTag((prev) => ({
        ...prev,
        buildingId: item.buildingId as string,
        buildingName: item.buildingName as string,
        buildingFloorId: undefined,
        spaceToiletId: undefined,
      }));
      setSelectedTag(item);
      fetchFloor(item.buildingId);
    } else {
      console.error("BuildingId or BuildingName is falsy");
    }
  };

  const handleFloorChange = (key: keyof Tag) => (value: string) => {
    if (!toiletTag.buildingId) {
      console.error("BuildingId is falsy");
      return;
    }

    setToiletTag((prev) => ({
      ...prev,
      [key]: value,
      spaceToiletId: undefined,
    }));
    fetchToilet(toiletTag.buildingId, value);
  };

  const handleToiletChange = (key: keyof Tag) => (value: string) => {
    setToiletTag((prev) => ({ ...prev, [key]: value }));

    if (
      toiletTag.tagTypeId === TOILET_COMPARTMENT_TAG_TYPE_ID &&
      value !== DEFAULT_SELECT_ALL_VALUE
    ) {
      fetchToiletCompartment(value);
    }
  };

  const handleToiletTagChange =
    (key: keyof Tag, isBoolean: boolean = false) =>
    (value: string) => {
      setToiletTag((prev) => ({
        ...prev,
        [key]: isBoolean ? (value === "true" ? true : false) : value,
      }));
    };

  const handleToiletCompartmentChange = (value: string) => {
    setToiletTag((prev) => ({ ...prev, toiletCompartmentId: value }));
  };

  const compartmentColumnInfo: TableColumnType<ToiletCompartment>[] = [
    {
      header: "선택",
      render: (item: ToiletCompartment) => {
        const isConnected =
          item.connected && item.toiletCompartmentId !== toiletCompartmentId;

        return (
          <div className="flex w-full p-1 justify-center">
            {!isConnected ? (
              <CommonRadio
                value={item.toiletCompartmentId}
                checked={
                  item.toiletCompartmentId === toiletTag.toiletCompartmentId
                }
                onChange={handleToiletCompartmentChange}
              />
            ) : (
              <DefaultLabel text="연결 완료" color="gray" />
            )}
          </div>
        );
      },
    },
    { header: "칸 ID", name: "toiletCompartmentId" },
    { header: "칸 명", name: "toiletCompartmentName" },
  ];

  return (
    <div className="flex flex-col gap-5">
      {isLoading && <Spinner />}
      <div className="flex flex-col min-w-[900px] max-h-[700px] overflow-y-auto">
        <FormHeader title="기준 정보" />
        <FormRow>
          <FormRowLabel title="위치" isRequired>
            <div className="flex flex-col gap-1.5">
              <TagSingleFilter
                selectType="building"
                selectedItem={selectedTag}
                onChangeBuilding={handleBuildingChange}
                categoryCode={buildingGroupCategoryCodeValue.IOT_DEVICE}
              />
              <DefaultSelect
                value={toiletTag.buildingFloorId}
                placeholder="층 선택"
                optionList={floorOptions}
                onChange={handleFloorChange("buildingFloorId")}
                disabled={
                  toiletTag.buildingId === undefined ||
                  floorOptions.length === 0
                }
              />
            </div>
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="화장실" isRequired>
            <DefaultSelect
              optionList={toiletOptions}
              value={toiletTag.spaceToiletId}
              placeholder="화장실 선택"
              placeholderDisabled
              onChange={handleToiletChange("spaceToiletId")}
              disabled={
                toiletTag.buildingFloorId === undefined ||
                toiletOptions.length === 0
              }
            />
          </FormRowLabel>
        </FormRow>

        <FormHeader title="태그 정보" />
        <FormRow>
          <FormRowLabel title="사용 여부">
            <RadioButtonGroup
              options={USAGE_OPTIONS}
              value={toiletTag.isActive?.toString()}
              onChange={handleToiletTagChange("isActive", true)}
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="태그 유형" isRequired>
            <div className="flex flex-col gap-1.5">
              <DefaultLabel text={toiletTag.tagTypeName} />
              {toiletTag.tagTypeId === TOILET_COMPARTMENT_TAG_TYPE_ID && (
                <div className="w-[700px]">
                  <ListPage<ToiletCompartment>
                    getDataApi={Promise.resolve}
                    columnInfo={compartmentColumnInfo}
                    selectedMode
                    selectedData={toiletCompartments}
                    hidePagination
                    isTableScroll
                  />
                </div>
              )}
            </div>
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="태그명" isRequired>
            <DefaultInput
              value={toiletTag.tagName}
              placeholder="태그명을 입력해주세요"
              onChange={handleToiletTagChange("tagName")}
              minWidth="w-full"
              maxLength={200}
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="태그 ID" isRequired>
            <DefaultInput
              value={toiletTag.tagId}
              placeholder="태그 ID를 입력해주세요"
              onChange={handleToiletTagChange("tagId")}
              maxLength={20}
            />
          </FormRowLabel>
        </FormRow>
      </div>

      <div className="flex justify-center">
        <DefaultButton color="primary" onClick={handleUpdate}>
          수정
        </DefaultButton>
      </div>
    </div>
  );
}
