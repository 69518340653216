import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { HttpStatusCode } from "axios";

import { UserLogin } from "../../types/user";

import {
  findPathByProgramId,
  flattenProgramId,
  getVisibleRoutes,
  hideRoutesNotInProgramList,
  routes,
} from "../../libs/routes";
import {
  getLocalStorage,
  removeLocalStorage,
  setLocalStorage,
} from "../../libs/localStorage";
import {
  validationFunctions,
  validationResultMessage,
} from "../../libs/validations";

import { useUserContext } from "../../contexts/User";
import { useModal } from "../../contexts/Modal";
import { useRoutesContext } from "../../contexts/Routes";

import { getMenu, getToken, getUser } from "../../services/userServices";

import Spinner from "../../components/Spinner";
import CommonCheckbox from "../../components/Input/Checkbox";
import DefaultButton from "../../components/DefaultButton";
import LoginInput from "../../components/Input/LoginInput";

import SandiCi from "../../assets/img/sandi-ci.png";

type Props = {};

export default function Login(props: Props) {
  const navigate = useNavigate();
  const { login } = useUserContext();
  const { showAlert } = useModal();
  const { setRouteContextInfo } = useRoutesContext();

  const [user, setUser] = useState<UserLogin>({ email: "", password: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [isRemember, setIsRemember] = useState<boolean>(false);

  const handleLogin = () => {
    const message = validationResultMessage([
      validationFunctions.required(user.email, "이메일"),
      validationFunctions.required(user.password, "비밀번호"),
    ]);

    if (message) {
      showAlert(message);
    } else {
      doLogin();
    }
  };

  const doLogin = async () => {
    try {
      setIsLoading(true);

      const result = await getToken(user);
      if (result?.status === HttpStatusCode.Ok) {
        let initPath;
        setLocalStorage(
          "jwtToken",
          JSON.stringify({
            grantType: result.data.grantType,
            accessToken: result.data.accessToken,
            refreshToken: result.data.refreshToken,
          })
        );

        const resUser = await getUser();
        if (resUser.status === HttpStatusCode.Ok) {
          login(resUser.data);

          if (isRemember) {
            setLocalStorage("saveEmail", user.email);
          } else {
            const savedEmail = getLocalStorage("saveEmail");
            if (savedEmail) {
              removeLocalStorage("saveEmail");
            }
          }
          setLocalStorage(
            "user",
            JSON.stringify({
              email: user.email,
              memberId: resUser.data.memberId,
              name: resUser.data.name,
              nickname: resUser.data.nickname,
              mobileNumber: resUser.data.mobileNumber,
              companyId: resUser.data.companyId,
              companyName: resUser.data.companyName,
              role: result.data.auth,
              roles: resUser.data.roles,
            })
          );

          const resMenu = await getMenu();
          if (resMenu.status === HttpStatusCode.Ok) {
            const menuIds = flattenProgramId(resMenu.data);
            initPath =
              findPathByProgramId(routes, menuIds[1].programId, "") ?? "/";
            const visibleRoutes = getVisibleRoutes(
              hideRoutesNotInProgramList(routes, menuIds)
            );

            setRouteContextInfo({
              routeInfo: visibleRoutes,
              menuInfo: menuIds,
            });
          } else {
            throw new Error("Failed to load routes info");
          }
        } else {
          throw new Error("Failed to load user info");
        }

        navigate(initPath ?? "/");

        // TODO: 브라우저 캐싱 문제로 인해 배포 후 올바르게 동작하지 않는 이슈가 발생함.
        // 로그인 성공 시 화면을 임시로 리로드하여 문제를 회피.
        // 추후 브라우저 캐싱 문제 해결완료 시 리로드 코드 삭제 필요
        window.location.reload();
      } else {
        console.log(result);
        showAlert("로그인을 실패했습니다. 다시 로그인해주세요.");
      }
    } catch (error: any) {
      const status = error.response.status;
      if (status === HttpStatusCode.Unauthorized) {
        showAlert(
          "로그인 정보가 정확하지 않습니다.\n관리자 ID, 비밀번호를 정확히 입력하시기 바랍니다."
        );
      } else if (status === HttpStatusCode.Forbidden) {
        showAlert(
          "로그인 정보가 5회 오류로 계정이 잠겼습니다.\n비밀번호 초기화 및 관리자에게 문의 바랍니다."
        );
      } else {
        if (error.response.data.error) {
          showAlert(error.response.data.error);
        } else {
          showAlert("로그인 중 오류가 발생했습니다.");
        }
        console.error(error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const saveEmail = getLocalStorage("saveEmail");
    if (saveEmail) {
      setUser({ email: saveEmail } as UserLogin);
      setIsRemember(true);
    }

    // 웹페이지 뒤로가기 눌렀을 때 로그인 상태라면 home 경로로 이동시킨다.
    const userString = getLocalStorage("user");
    if (userString) {
      navigate("/");
    }
  }, []);

  return (
    <>
      {isLoading && <Spinner />}
      <div className="min-w-[956px] h-full min-h-screen w-full bg-gray-100 flex items-center justify-center">
        <div className="w-[956px] h-[1014px] bg-bg-simbol flex flex-col items-center justify-center gap-5">
          <div className="flex items-end gap-[22px] text-3xl font-semibold h-[170px] pb-[20px]">
            <div className="flex gap-[22px] items-center">
              <img src={SandiCi} alt="ci" className="h-[30px]" /> 앱 운영 시스템
            </div>
          </div>
          <div className="bg-white w-[500px] pt-[85px] pb-[103px] px-[50px] flex flex-col gap-12">
            <div className="flex flex-col gap-10">
              <form className="flex flex-col gap-8">
                <LoginInput
                  placeholder="아이디 입력"
                  value={user.email}
                  onChange={(value) =>
                    setUser((prev: UserLogin) => ({ ...prev, email: value }))
                  }
                  autoComplete="username"
                  onKeyPress={(key) =>
                    (key === "Enter" || key === "enter") && handleLogin()
                  }
                />
                <LoginInput
                  placeholder="비밀번호 입력"
                  type="password"
                  value={user.password}
                  onChange={(value) =>
                    setUser((prev: UserLogin) => ({ ...prev, password: value }))
                  }
                  autoComplete="current-password"
                  onKeyPress={(key) =>
                    (key === "Enter" || key === "enter") && handleLogin()
                  }
                />
                <div
                  className="flex items-center gap-2.5 text-neutral-80 hover:cursor-pointer"
                  onClick={() => setIsRemember((prev) => !prev)}
                >
                  <CommonCheckbox
                    checked={isRemember}
                    className="hover:cursor-pointer"
                  />
                  관리자 ID 기억
                </div>
              </form>

              <DefaultButton
                onClick={handleLogin}
                className="!bg-sandi-gradient !text-base w-full h-[56px] rounded-none font-medium"
                color="default"
              >
                로그인
              </DefaultButton>
            </div>
          </div>
          <div className="flex flex-col gap-[100px] items-center">
            <div className="w-[500px] px-[67px]">
              <ul className="list-disc text-sm font-medium text-neutral-80">
                <li>인증코드는 이메일 확인 후 입력 바랍니다.</li>
                <li className="whitespace-pre-line">
                  {
                    "비밀번호를 잃어버렸을 경우 관리자에게 문의해주세요.\n관리자이메일: sandiapp@sni.co.kr"
                  }
                </li>
              </ul>
            </div>
            <span className="text-neutral-50 text-xs">
              2023 © SANDI by S&I Corp
            </span>
          </div>
        </div>
      </div>
    </>
  );
}
