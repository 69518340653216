import React, { useState, useContext, createContext } from "react";

import { MenuInfo, RouteInfo, getInitRoutes } from "../libs/routes";

interface RoutesContextProps {
  routesContextInfo: RoutesContextInfo;
  setRouteContextInfo: (value: RoutesContextInfo) => void;
}

export const RoutesContext = createContext<RoutesContextProps | null>(null);

export const useRoutesContext = () => {
  const context = useContext(RoutesContext);
  if (!context) {
    throw new Error("useRoutesContext must be used within a RoutesProvider");
  }
  return context;
};

type RoutesContextInfo = {
  routeInfo?: RouteInfo[];
  menuInfo?: MenuInfo[];
};

type Props = { children: React.ReactNode };
export const RoutesProvider = ({ children }: Props) => {
  const [routesContextInfo, setRoutesContextInfo] = useState<RoutesContextInfo>(
    { routeInfo: getInitRoutes() }
  );

  const setRouteContextInfo = (value: RoutesContextInfo) => {
    setRoutesContextInfo(value);
  };

  return (
    <RoutesContext.Provider value={{ routesContextInfo, setRouteContextInfo }}>
      {children}
    </RoutesContext.Provider>
  );
};
