import React from "react";
import clsx from "clsx";

import { TextInputType } from "../../../types/search";

import { phoneFormatter } from "../../../utils/formatUtils";

import RequiredIcon from "../../../svgs/icons/Common/Required";

type InputSize = "small" | "medium";

type Props = {
  type?: TextInputType;
  placeholder?: string;
  label?: string;
  value?: string | null;
  checked?: boolean;
  onChange?: (value: string) => void;
  autoComplete?: string;
  disabled?: boolean;
  isLabelHorizontal?: boolean;
  maxLength?: number;
  hideMaxLength?: boolean;
  unitLabel?: string;
  required?: boolean;
  size?: InputSize;
  className?: string;
  minWidth?: string;
  min?: number;
};

export default function DefaultInput({
  onChange,
  autoComplete,
  className,
  minWidth,
  disabled = false,
  type = "text",
  placeholder = "",
  label = "",
  value = "",
  checked = false,
  isLabelHorizontal = false,
  maxLength = 0,
  hideMaxLength = false,
  unitLabel = "",
  required = false,
  size = "medium",
  min,
}: Props) {
  const inputValue = value ?? "";

  const divClassName = clsx("flex gap-2", minWidth || "w-[200px]", {
    "flex-col": !isLabelHorizontal,
    "flex-row items-center": isLabelHorizontal,
  });

  const labelClassName = clsx("block text-label", {
    "min-w-label": isLabelHorizontal,
  });

  const inputClassName = clsx(
    "w-full border border-gray-200 px-3 h-[38px] text-[13px]",
    { "pr-8": unitLabel !== "" },
    { "py-2": size === "medium" },
    { "text-brand-text-black-disabled": disabled },
    className
  );

  const unitClassName = clsx(
    "absolute right-3 top-1/2 transform -translate-y-1/2",
    { "text-brand-text-black-disabled": disabled }
  );

  const handleInputChange = (value: string) => {
    let newValue = value;

    if (type === "tel") {
      newValue = phoneFormatter(value.replace(/[^\d]/g, ""));
    } else if (type === "number") {
      if (maxLength > 0) {
        newValue = newValue.slice(0, maxLength);
      }
    }

    if (onChange) onChange(newValue);
  };

  const getMaxLengthText = () => {
    return `${inputValue.length}/${maxLength}`;
  };

  const charPx = 8;
  const paddingRight = getMaxLengthText().length * charPx;

  return (
    <div className={divClassName}>
      {label && (
        <div className="flex items-center">
          <span className={labelClassName}>{label}</span>
          {required && <RequiredIcon />}
        </div>
      )}
      <div className="relative flex w-full gap-2 text-sm">
        <input
          type={type === "tel" ? "text" : type}
          className={inputClassName}
          placeholder={placeholder}
          value={inputValue}
          checked={checked}
          onChange={(e) => handleInputChange(e.target.value)}
          style={{
            paddingRight:
              !hideMaxLength && maxLength ? paddingRight : undefined,
          }}
          autoComplete={autoComplete}
          disabled={disabled}
          maxLength={maxLength > 0 ? maxLength : 50}
          min={min}
        />
        {unitLabel && <span className={unitClassName}>{unitLabel}</span>}
        {!hideMaxLength && maxLength > 0 && (
          <label className="flex items-end justify-start absolute right-2 bottom-1 text-[11px] text-[#b2b2b2]">
            {getMaxLengthText()}
          </label>
        )}
      </div>
    </div>
  );
}
