import React, { useEffect } from "react";
import clsx from "clsx";
import { HttpStatusCode } from "axios";

import { DASH } from "../../types/search";
import {
  MASKING_PROPERTY_TYPE,
  MaskingType,
  convertProperty,
} from "../../types/masking";

import { phoneFormatter } from "../../utils/formatUtils";

import {
  MemberMaskingInformation,
  getPersonalInformation,
} from "../../services/memberService";

import DefaultButton from "../DefaultButton";
import { useModal } from "../../contexts/Modal";

type Props = {
  defaultText: string;
  className: string;
  type: MaskingType;
  targetId?: number;
  property?: MASKING_PROPERTY_TYPE;
  prefix?: string;
  unmaskButtonTestId?: string;
};

export default function MaskingData({
  defaultText,
  className,
  targetId,
  type,
  property,
  prefix,
  unmaskButtonTestId,
}: Props) {
  const [text, setText] = React.useState<string>(defaultText);
  const [isMasked, setIsMasked] = React.useState<boolean>(true);
  const { handleError } = useModal();

  const getUnmaskedText = async () => {
    try {
      const result = await getPersonalInformation(targetId as number, {
        type,
        properties: property,
      });
      if (result.status === HttpStatusCode.Ok) {
        setText(
          (convertProperty(
            property as MASKING_PROPERTY_TYPE
          ) as keyof MemberMaskingInformation) === "mobileNumber"
            ? phoneFormatter(
                result.data[
                  convertProperty(
                    property as MASKING_PROPERTY_TYPE
                  ) as keyof MemberMaskingInformation
                ] as string
              )
            : (result.data[
                convertProperty(
                  property as MASKING_PROPERTY_TYPE
                ) as keyof MemberMaskingInformation
              ] as string)
        );
        setIsMasked(false);
      } else {
        throw new Error("마스킹 데이터 로드 실패");
      }
    } catch (e) {
      handleError(e, "마스킹 데이터 로드");
    }
  };

  useEffect(() => {
    setText(defaultText);
  }, [defaultText]);

  return (
    <label
      className={clsx("flex items-center gap-1 notranslate", className)}
      data-testid={`${unmaskButtonTestId}-masking-data-label`}
    >
      {prefix ?? ""}
      {text ?? DASH}
      {isMasked && (
        <DefaultButton
          size="unmaskingButton"
          onClick={getUnmaskedText}
          testId={unmaskButtonTestId}
        >
          마스킹 해제
        </DefaultButton>
      )}
    </label>
  );
}
