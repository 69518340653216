export const API_ERROR_CODE = {
  INSUFFICIENT_AUTHENTICATION: -110, // 권한이 충분하지 않습니다.
  INVALID_FILE_TYPE: -501, // 유효하지 않은 파일 형식입니다.
  INVALID_FILE_PATH: -502, // 유효하지 않은 파일 경로입니다.
  FILE_UPLOAD_EXCEPTION: -503, // 파일 업로드에 실패했습니다.
  EMAIL_DOMAIN_NOT_MATCHED: 551, // 인증 요청 주소와 회사 등록 도메인이 일치하지 않습니다.
  MEMBER_WITHDRAWAL: 590, // 탈퇴한 회원입니다.
  MEMBER_DORMANCY: 591, // 휴면 상태인 회원입니다.
  INVALID_REQUEST: -601, // path/param/body 값을 확인해주세요.
  DUPLICATION_ERROR: -602, // 중복된 값을 입력할수 없습니다.
  DUPLICATED_KEY: -603, // 중복된 키값입니다.
  DATA_INTEGRITY_VIOLATION: -604, // 잘못된 데이터입니다. 등록되어 있는 code, category 값 등이 맞는지 확인해 주세요.
  BUILDING_ERROR: -700, // 현재 운영 하지 않는 건물 입니다.
};
