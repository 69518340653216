import { BuildingAndFloor } from "../components/SelectBox/BuildingAndFloorSelect";
import { FileInfo } from "./file";
import { ComboBoxType, DASH } from "./search";

export type Space = {
  reservationSpaceId: string;
  reservationSpaceName: string;
  reservationSpaceType: reservationSpaceType;
  useAutoConfirm: boolean;
  useBlockTime: boolean;
  blockTimeStart?: string;
  blockTimeEnd?: string;
  buildingId: string;
  buildingName: string;
  buildingFloorId: string;
  floorName: string;
  availableGender: SpaceGender;
  companyIds: string[];
  companyList: SpaceCompanyList[];
  connectionInfo: ConnectionInfoType;
  availableDays: WeekDay;
  availableTimeStart: string;
  childReservationSpaceIdList: string[];
  availableTimeEnd: string;
  availablePeopleCountStart: number;
  availablePeopleCountEnd: number;
  usableTimeStart: number;
  usableTimeEnd: number;
  dailyLimit: number;
  isConnection: boolean;
  contactInfo: string;
  contactNumber: string;
  reservationSpaceFeatures: string;
  reservationSpaceNote: string;
  usageTermId: number;
  usePayment: boolean;
  defaultPrice: number;
  additionalPrice: number;
  defaultPriceDays: WeekDay;
  defaultPriceTimeStart: string;
  defaultPriceTimeEnd: string;
  rentalDeviceList: RentalDevice[];
  rentalDeviceCreateRequestList: RentalDevice[];
  rentalDeviceModifyRequestList: RentalDevice[];
  isActive: boolean;
  isRentalDevice: boolean;
  createdAt: string;
  createdBy: number;
  fileInfo: {
    items: FileInfo[];
  };
  buildingAndFloor?: BuildingAndFloor;
  isConnectedAllCompany: boolean;
  isConcurrentLimit: boolean;
  concurrentLimit: number;
};

export const RESERVATION_SPACE_TYPES = {
  DEFAULT: "DEFAULT", // 기존 회의실
  SHOWER: "SHOWER", // 샤워실
} as const;

export type reservationSpaceType = keyof typeof RESERVATION_SPACE_TYPES;

export type SpaceConnection = {
  reservationSpaceId: string;
  reservationSpaceName?: string;
  buildingId?: string;
  buildingName?: string;
  buildingFloorId?: string;
  floorName?: string;
  isConnection?: boolean;
  isActive?: boolean;
  createdBy?: number;
  createdAt?: string;
};

type SpaceCompanyList = {
  companyId: string;
  companyName: string;
  isActive: boolean;
};

export type SpaceConnectionLevel = "PARENT" | "CHILD";

type ConnectionInfoType = {
  isConnection: boolean;
  level: SpaceConnectionLevel;
  parentReservationSpaceId: string;
  childReservationSpaceIdList: string[];
};

export type SpaceGender = "M" | "F" | "ALL";

const genderMapping: { [key: string]: string } = {
  M: "남자",
  F: "여자",
  ALL: "전체",
};

export const SPACE_GENDER_OPTIONS: ComboBoxType[] = [
  { value: "ALL", label: "전체" },
  { value: "F", label: "여자" },
  { value: "M", label: "남자" },
];

export const RESERVATION_SPACE_OPTIONS: ComboBoxType[] = [
  { value: RESERVATION_SPACE_TYPES.DEFAULT, label: "기본(회의실)" },
  { value: RESERVATION_SPACE_TYPES.SHOWER, label: "샤워실" },
];

export const getGender = (genderValue: SpaceGender): string => {
  return genderMapping[genderValue] || DASH;
};

export type WeekDay = {
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
};

export type RentalDevice = {
  checked?: boolean;
  appendId?: number;
  rentalDeviceId?: number;
  deviceName: string;
  price?: number;
  isActive: boolean;
};
