import ListPage from "@/components/ListPage";
import { TableColumnType } from "@/components/Table";
import {
  getBuildingCompanyListAll,
  getBuildingGroupList,
} from "@/services/buildingService";
import { getEvacueeStatusHistoryList } from "@/services/emergencyService";
import { EvacueeStatusHistory, convertStatusToText } from "@/types/emergency";
import { ComboBoxType, ComponentType } from "@/types/search";
import { addDefaultOption } from "@/utils/comboBoxUtils";
import { HttpStatusCode } from "axios";
import { useEffect, useState } from "react";

type Props = {};

export default function StatusHistoryList({}: Props) {
  const [buildingOptions, setBuildingOptions] = useState<ComboBoxType[]>([]);
  const [companyOptions, setCompanyOptions] = useState<ComboBoxType[]>([]);
  const [searchOptions, setSearchOptions] = useState<any>({});

  const columnInfo: TableColumnType<EvacueeStatusHistory>[] = [
    { header: "상황명", name: "evacuationName" },
    { header: "이름", name: "name", width: "name" },
    { header: "건물", name: "evacuationBuildingName" },
    { header: "회사", name: "evacuationCompanyName" },
    { header: "부서", name: "evacuationDepartmentName" },
    {
      header: "최초 상태",
      render: (item: EvacueeStatusHistory) => (
        <div>{convertStatusToText(item.firstStatus)}</div>
      ),
    },
    {
      header: "변경된 상태",
      render: (item: EvacueeStatusHistory) => (
        <div>{convertStatusToText(item.status)}</div>
      ),
    },
    { header: "상태 변경자", name: "modifiedByName" },
    { header: "도와주세요 상세위치", name: "rescueLocation" },
    { header: "상태 변경 시각", name: "modifiedAt", width: "date" },
  ];

  const componentList: ComponentType<EvacueeStatusHistory>[] = [
    {
      labelName: "상황명",
      typeName: "text",
      keyName: "evacuationName",
      category: "detailSearch",
      placeholder: "상황명을 입력해주세요",
    },
    {
      labelName: "유저이름",
      typeName: "text",
      keyName: "name",
      category: "detailSearch",
      placeholder: "유저이름을 입력해주세요",
    },
    {
      labelName: "건물",
      typeName: "comboBox",
      keyName: "evacuationBuildingId",
      comboBoxOptions: addDefaultOption(buildingOptions),
      category: "detailSearch",
    },
    {
      labelName: "회사명",
      typeName: "comboBox",
      keyName: "evacuationCompanyId",
      comboBoxOptions: addDefaultOption(companyOptions),
      category: "detailSearch",
    },
    {
      labelName: "부서명",
      typeName: "text",
      keyName: "evacuationDepartmentName",
      category: "detailSearch",
      placeholder: "부서명을 입력해주세요",
    },
  ];

  const loadBuildingList = async () => {
    try {
      const result = await getBuildingGroupList({
        categoryCode: "ALL",
      });
      if (result.status === HttpStatusCode.Ok) {
        setBuildingOptions(
          result.data.map(
            (item) =>
              ({
                value: item.buildingId,
                label: item.buildingName,
              } as ComboBoxType)
          )
        );
      } else {
        console.error(result);
      }
    } catch (e: any) {
      console.error(e);
    }
  };

  const loadCompanyList = async () => {
    try {
      const result = await getBuildingCompanyListAll({
        buildingId: searchOptions.evacuationBuildingId,
      });
      if (result.status === HttpStatusCode.Ok) {
        setCompanyOptions(
          result.data.map(
            (item) =>
              ({
                value: item.companyId,
                label: item.companyName,
              } as ComboBoxType)
          )
        );
      } else {
        console.error(result);
      }
    } catch (e: any) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (searchOptions.evacuationBuildingId) {
      loadCompanyList();
    } else {
      setCompanyOptions([]);
    }
  }, [searchOptions?.evacuationBuildingId]);

  useEffect(() => {
    loadBuildingList();
  }, []);

  return (
    <ListPage
      getDataApi={getEvacueeStatusHistoryList}
      columnInfo={columnInfo}
      componentList={componentList}
      tableTitle="유저 목록"
      defaultPageSize={10}
      defaultPageSizeList={[10, 20, 30]}
      onChangeSearch={(search) => setSearchOptions(search)}
    />
  );
}
