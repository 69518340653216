import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { User } from "../../types/user";

import {
  findPathByProgramId,
  findRouteByPathname,
  getRouteTitles,
  routes,
} from "../../libs/routes";
import { getLocalStorage } from "../../libs/localStorage";

import { useRoutesContext } from "../../contexts/Routes";

import { logoutUser, postSignOut } from "../../services/userServices";

import SideBar from "../SideBar";
import HorizonRule from "../HorizonRule";
import SessionExtendWrapper from "../Wrapper/SessionExtendWrapper";

import SandiCi from "../../assets/img/sandi-ci-white.png";

type Props = {};

export default function Layout(props: Props) {
  const navigate = useNavigate();
  const location = useLocation();
  const { routesContextInfo } = useRoutesContext();
  const userString: string | null = getLocalStorage("user");
  const user: User = JSON.parse(userString as string);

  const paths = location.pathname.split("/");
  const findPath = paths.filter((path) => path !== "").slice(-1)[0];
  const currentRoute = findRouteByPathname(
    routesContextInfo.routeInfo ?? [],
    findPath
  );
  const title = getRouteTitles(routesContextInfo.routeInfo ?? [], paths);

  const [isMenu] = useState<boolean>(true);

  const getMenuClass = (isActive: boolean) => {
    return {
      "bg-brand-primary-magenta text-white stroke-white": isActive,
      "bg-[#1320294D] text-neutral-30 stroke-neutral-30 bg-opacity-30":
        !isActive,
    };
  };

  useEffect(() => {
    if (userString === null) {
      navigate("/login");
    }

    if (routesContextInfo.menuInfo) {
      // 권한이 없는 메뉴 진입 시 notFound 화면으로 이동한다.
      if (!currentRoute) {
        navigate("/notFound");
      } else {
        // Home 이동 시 가장 첫번째 접속 가능한 화면으로 이동한다.
        if (location.pathname === "/") {
          // BO 메뉴 구성(2depth) : 분류 - 화면
          // menuInfo[0] -> 분류, menuInfo[1] -> 첫번째 화면
          const firstMenu = routesContextInfo.menuInfo?.[1];
          if (firstMenu) {
            const path =
              findPathByProgramId(routes, firstMenu.programId, "") ?? "/";
            navigate(path);
          }
        }
      }
    }
  }, [userString, routesContextInfo.menuInfo, navigate]);

  // 현재 권한이 있는 route 정보가 있는 경우에만 화면을 출력한다.
  return currentRoute ? (
    <div className="flex min-w-layout overflow-x-scroll">
      <div className="w-[260px] min-h-screen bg-brand-primary-gray-100">
        <div className="h-[60px] border border-b border-white border-opacity-10 text-white font-semibold pl-[25px] flex gap-[13px] items-center">
          <div className="flex gap-[13px] items-center">
            <img src={SandiCi} alt="ci" />
            <div className="h-[15px] w-[1px] bg-white bg-opacity-20" />
            <div>관리자 2.0</div>
          </div>
        </div>

        {/* <div className="p-[25px]">
          <div className="relative">
            <DefaultInput
              placeholder="검색어를 입력하세요"
              className="rounded-none text-xs font-light text-neutral-30"
            />
            <div className="absolute right-2.5 top-1/2 -translate-y-1/2 z-10">
              <LayoutSearchIcon />
            </div>
          </div>
        </div> */}

        <div className="px-[25px] flex">
          <div
            className={clsx([
              "flex-1 flex items-center px-2.5 gap-2 justify-center text-xs font-semibold h-[32px]",
              getMenuClass(isMenu),
            ])}
            // onClick={() => setIsMenu(true)}
          >
            <LayoutMenuIcon />
            <span>메뉴</span>
          </div>

          {/* <div 
            className={clsx([
              "flex-1 flex items-center px-2.5 gap-2 justify-center text-xs font-semibold h-[32px] cursor-pointer",
              getMenuClass(!isMenu),
            ])}
            onClick={() => setIsMenu(false)}
          >
            <LayoutQuickIcon />
            <span>즐겨찾기</span>
          </div> */}
        </div>
        <SideBar />
        {/* {isMenu ? (
          <SideBar />
        ) : (
          <div className="pt-2.5 flex flex-col gap-1.5">
            {[1, 2, 3, 4].map((index) => (
              <div className="flex items-center gap-3 text-sm font-medium text-white px-[25px] h-[40px] hover:bg-[#132029] transition-colors cursor-pointer">
                <LayoutQuickStarIcon />
                즐겨찾기 메뉴 #{index}
              </div>
            ))}
          </div>
        )} */}
      </div>

      <div className="flex-1 flex flex-col bg-brand-default">
        <div className="w-full h-[61px] bg-white flex justify-end items-center px-[30px] gap-2 text-neutral-50 text-xs font-medium">
          <SessionExtendWrapper />
          <div className="px-2.5 py-1.5 flex gap-1.5 items-center cursor-default">
            <LayoutAdminIcon />
            {user?.email ?? "사용관리자이름"}
          </div>
          <div className="h-[10px] w-[1px] bg-neutral-30 bg-opacity-40" />
          <div
            className="px-3 py-[3px] cursor-pointer"
            onClick={() => {
              postSignOut()
                .catch((err) => {
                  console.error("토큰 제거 실패", err);
                })
                .finally(() => {
                  logoutUser();
                });
            }}
          >
            로그아웃
          </div>
          <div className="h-[10px] w-[1px] bg-neutral-30 bg-opacity-40" />
          {/* 비밀번호 변경 기획서 삭제됨. */}
          {/* <div className="px-3 py-[3px] cursor-not-allowed">비밀번호 변경</div> */}
        </div>
        <div className="py-[40px] px-[50px] w-full flex flex-col">
          {/* 타이틀 위치 */}
          <div className="flex flex-col">
            <div className="self-end flex gap-1 items-center text-xs">
              {title.map((t, index) => (
                <React.Fragment key={index}>
                  {index > 0 && <LayoutBreadcrumbArrow />}
                  <span>{t}</span>
                </React.Fragment>
              ))}
            </div>
          </div>
          <div className="pb-10">
            <div className="text-3xl font-semibold flex items-center gap-2.5 pb-8">
              {title[title.length - 1] && (
                <>
                  <div className="text-3xl">{title[title.length - 1]}</div>
                  {currentRoute?.renderRight}
                  {/* <LayoutQuickDisabled /> */}
                </>
              )}
            </div>
            <HorizonRule className="!bg-black" />
          </div>

          <Outlet />
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
}

export const LayoutQuickDisabled = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.6376 2.21927C11.7859 1.91893 12.2141 1.91894 12.3624 2.21927L15.0612 7.68762C15.2657 8.10203 15.661 8.38926 16.1183 8.45572L22.153 9.33261C22.4845 9.38077 22.6168 9.78808 22.377 10.0219L18.0102 14.2784C17.6793 14.6009 17.5283 15.0657 17.6064 15.5212L18.6373 21.5315C18.6939 21.8616 18.3474 22.1133 18.051 21.9575L12.6534 19.1198C12.2443 18.9047 11.7557 18.9047 11.3466 19.1198L5.94904 21.9575C5.65258 22.1133 5.3061 21.8616 5.36272 21.5315L6.39357 15.5212C6.47169 15.0657 6.32068 14.6009 5.98975 14.2784L1.62302 10.0219L1.27401 10.3799L1.62302 10.0219C1.38318 9.78808 1.51553 9.38077 1.84697 9.33261L7.88165 8.45572C8.33898 8.38926 8.73432 8.10203 8.93885 7.68762L11.6376 2.21927Z"
      fill="white"
      stroke="#CCCCCC"
    />
  </svg>
);

export const LayoutBreadcrumbArrow = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.04346 11.9131L9.95654 8L6.04346 4.08691"
      stroke="#BCBCBC"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
  </svg>
);

export const LayoutAdminIcon = () => (
  <svg
    width="12"
    height="14"
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.79449 4.08708C7.79449 4.98233 7.06875 5.70807 6.17351 5.70807C5.27827 5.70807 4.55253 4.98233 4.55253 4.08708C4.55253 3.19184 5.27827 2.4661 6.17351 2.4661C7.06875 2.4661 7.79449 3.19184 7.79449 4.08708ZM9.29449 4.08708C9.29449 5.81075 7.89718 7.20807 6.17351 7.20807C4.44984 7.20807 3.05253 5.81075 3.05253 4.08708C3.05253 2.36342 4.44984 0.966105 6.17351 0.966105C7.89718 0.966105 9.29449 2.36342 9.29449 4.08708ZM4.34766 9.54033H7.99932C9.20652 9.54033 10.2139 10.396 10.448 11.5339H1.89898C2.13309 10.396 3.14045 9.54033 4.34766 9.54033ZM7.99932 8.04033C10.0369 8.04033 11.7187 9.56385 11.9676 11.5339C11.9885 11.6998 11.9993 11.8688 11.9993 12.0403V13.0339H10.4993H1.84766H0.347656V12.0403C0.347656 11.8688 0.358455 11.6998 0.379411 11.5339C0.628312 9.56385 2.31006 8.04033 4.34766 8.04033H7.99932Z"
      fill="#B7B7B8"
    />
  </svg>
);

export const LayoutQuickStarIcon = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 9.29166L2.90967 11L3.5 7.38168L1 4.81943L4.45484 4.29192L6 1L7.54516 4.29192L11 4.81943L8.5 7.38168L9.09033 11L6 9.29166Z"
      fill="url(#paint0_linear_316_3385)"
      stroke="url(#paint1_linear_316_3385)"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_316_3385"
        x1="-20.6867"
        y1="41054.8"
        x2="24.5397"
        y2="41054.8"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EA27C2" />
        <stop offset="1" stopColor="#E100A3" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_316_3385"
        x1="-20.6867"
        y1="41054.8"
        x2="24.5397"
        y2="41054.8"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EA27C2" />
        <stop offset="1" stopColor="#E100A3" />
      </linearGradient>
    </defs>
  </svg>
);

export const LayoutQuickIcon = () => (
  <svg
    width="13"
    height="12"
    viewBox="0 0 13 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.5 9.29166L3.40967 11L4 7.38168L1.5 4.81943L4.95484 4.29192L6.5 1L8.04516 4.29192L11.5 4.81943L9 7.38168L9.59033 11L6.5 9.29166Z"
      stroke="inherit"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const LayoutMenuIcon = () => (
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M10 9H0M10 1H0" stroke="inherit" strokeWidth="1.5" />
    <path d="M10 5H0" stroke="inherit" strokeWidth="1.5" />
  </svg>
);

export const LayoutSearchIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.6001 13.5999L10.4001 10.3999M7.20013 11.9999C4.54917 11.9999 2.40015 9.85087 2.40015 7.19992C2.40015 4.54896 4.54917 2.39994 7.20013 2.39994C9.85108 2.39994 12.0001 4.54896 12.0001 7.19992C12.0001 9.85087 9.85108 11.9999 7.20013 11.9999Z"
      stroke="#333F48"
      strokeOpacity="0.6"
      strokeWidth="1.5"
    />
  </svg>
);
