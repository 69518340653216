import { useEffect, useState } from "react";
import DefaultTextArea from "../../DefaultTextArea";
import { CommentStatus, PostComment } from "../../../types/comment";
import DefaultButton from "../../DefaultButton";
import clsx from "clsx";
import { NEW_COMMENT_ID } from "../CommentList";
import { useModal } from "../../../contexts/Modal";
import {
  postPostComment,
  putPostComment,
} from "../../../services/pinelifeService";
import { HttpStatusCode } from "axios";
import { postContentValue } from "../../../types/post";

type Props = {
  lifePostId?: number;
  comment?: PostComment;
  status?: CommentStatus;
  onStatusChange?: (id: number, status: CommentStatus) => void;
  replyComment?: (id: number, newItem: PostComment) => void;
  fetchData?: () => void;
};

export default function CommentEditForm({
  lifePostId,
  comment,
  status = "default",
  onStatusChange,
  replyComment,
  fetchData,
}: Props) {
  const { showAlert, showConfirm, handleError } = useModal();
  const [text, setText] = useState("");

  useEffect(() => {
    if (status === "edit") {
      setText(comment?.content ?? "");
    }
  }, [status]);

  const handleStatusChange = (status: CommentStatus) => {
    if (onStatusChange) {
      onStatusChange(comment?.lifeCommentId ?? 0, status);
    }
  };

  const handleChange = (value: string) => {
    setText(value);
  };

  const handleUpdate = () => {
    const data: Partial<PostComment> = {
      content: text,
      status: postContentValue.COMMENT,
    };

    showConfirm("댓글을 수정하시겠습니까?", async () => {
      if (!comment?.lifeCommentId) {
        showAlert("수정할 댓글 ID를 가져오지 못했습니다.");
        return;
      }

      try {
        const res = await putPostComment(comment?.lifeCommentId, data);
        if (res.status === HttpStatusCode.Ok) {
          showAlert("댓글이 수정되었습니다.");
        }
      } catch (err: any) {
        handleError(err, "댓글 수정");
      } finally {
        handleStatusChange("default");
        if (fetchData) fetchData();
      }
    });
  };

  const handleReply = () => {
    const data: Partial<PostComment> = {
      lifePostId,
      content: text,
      status: postContentValue.COMMENT,
      ...(status === "reply" && { parentCommentId: comment?.parentCommentId }),
    };

    showConfirm("댓글을 등록하시겠습니까?", async () => {
      try {
        const res = await postPostComment(data);
        if (res.status === HttpStatusCode.Ok) {
          showAlert("댓글이 등록되었습니다.");
          handleStatusChange("default");
          handleChange("")
        }
      } catch (err: any) {
        handleError(err, "댓글 등록");
      } finally {
        handleResetRow();
        if (fetchData) fetchData();
      }
    });
  };

  const handleResetRow = () => {
    if (comment?.lifeCommentId === NEW_COMMENT_ID && replyComment) {
      replyComment(comment?.lifeCommentId ?? NEW_COMMENT_ID, {} as PostComment);
    }
  };

  return (
    <>
      {status === "default" ? (
        <div className="px-2.5 min-w-[350px] max-h-[100px] overflow-y-auto break-words">
          {comment?.content}
        </div>
      ) : (
        <div
          className={clsx([
            "flex px-2.5 justify-center",
            {
              "gap-2.5": status !== "edit" && status !== "reply",
            },
          ])}
        >
          <div
            className={clsx({
              "min-w-[350px]": status === "edit" || status === "reply",
              "w-full": status !== "edit" && status !== "reply",
            })}
          >
            <DefaultTextArea
              cols={20}
              rows={5}
              value={text}
              onChange={handleChange}
              maxLength={2000}
              lengthVertical={true}
              placeholder="댓글 내용을 입력해주세요"
            />
          </div>

          <div className="flex flex-col right-2 bottom-8">
            {status === "edit" ? (
              <DefaultButton
                color="white"
                className="rounded-none h-full"
                onClick={handleUpdate}
              >
                수정
              </DefaultButton>
            ) : status === "reply" ? (
              <DefaultButton
                color="white"
                className="rounded-none h-full"
                onClick={handleReply}
              >
                등록
              </DefaultButton>
            ) : null}
            {status === "listEdit" ? (
              <DefaultButton
                color="white"
                className="py-[11px] rounded-none h-full w-[92px] text-sm font-medium"
                onClick={handleReply}
              >
                <span className="whitespace-nowrap">
                  댓글
                  <br />
                  등록
                </span>
              </DefaultButton>
            ) : (
              <DefaultButton
                onClick={() => {
                  setText("");

                  if (onStatusChange) {
                    onStatusChange(comment?.lifeCommentId ?? 0, "default");
                  }
                  if (
                    comment?.lifeCommentId === NEW_COMMENT_ID &&
                    replyComment
                  ) {
                    replyComment(
                      comment?.lifeCommentId ?? NEW_COMMENT_ID,
                      {} as PostComment
                    );
                  }
                }}
                color="gray"
                className="rounded-none h-[32px] border border-brand-primary-gray-100 border-opacity-20"
              >
                취소
              </DefaultButton>
            )}
          </div>
        </div>
      )}
    </>
  );
}
