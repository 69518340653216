import { PopupContext } from "@/contexts/Popup";
import { useContext, useEffect, useState } from "react";
import DefaultInput from "../Input/DefaultInput";
import {
  EmergencyDashboardTableInfo,
  EmergencyDashboardTableRequest,
  EmergencyDashboardTableSearch,
  EvacuationParticipationStatus,
} from "@/types/emergency";
import DefaultSelect from "../SelectBox/DefaultSelect";
import { ComboBoxType } from "@/types/search";
import Spinner from "../Spinner";
import { Pageable } from "@/types/pageable";
import { useModal } from "@/contexts/Modal";
import { getDashboardEvacueeList } from "@/services/emergencyService";
import { HttpStatusCode } from "axios";
import { SearchCondition } from "../ListPage";
import { BuildingCompany } from "@/types/building";
import { getBuildingCompanyListAll } from "@/services/buildingService";
import DefaultButton from "../DefaultButton";
import clsx from "clsx";
import { getShownEvacueeType } from "@/libs/evacuation";
import { formatDate } from "@/utils/dateUtils";
import Pagination from "../Pagination";
import WarningCircleIcon from "@/svgs/icons/Common/WarningCircle";

type Props = {
  evacuationId?: string;
  evacuationGroupId?: string;
  buildingOptions: ComboBoxType[];
  status?: EvacuationParticipationStatus;
};

export default function DashboardEvacueeList({
  evacuationId,
  evacuationGroupId,
  buildingOptions,
  status,
}: Props) {
  const { closePopup } = useContext(PopupContext);
  const { showAlert, showConfirm, handleError } = useModal();

  const [searchTable, setSearchTable] = useState<
    Partial<EmergencyDashboardTableSearch>
  >({
    status: status ?? EvacuationParticipationStatus.HELP_ME,
    evacuationId: evacuationId,
    evacuationGroupId: evacuationGroupId,
  }); // 인원 목록 조회 조건.
  const [roleOptions, setRoleOptions] = useState<ComboBoxType[]>([
    { value: "ROLE_ADMIN", label: "샌디 운영자" },
    { value: "ROLE_USER", label: "일반 사용자" },
    { value: "ROLE_EV_MASTER", label: "비상대피 마스터" },
    { value: "ROLE_EV_COM_ADMIN", label: "회사 관리자" },
    { value: "ROLE_EV_DEP_ADMIN", label: "부서 관리자" },
  ]);
  const [companyOptions, setCompanyOptions] = useState<ComboBoxType[]>([]);

  const [pageable, setPageable] = useState<Pageable>({
    offset: 0,
    page: 1,
    pageSize: 10,
    sort: "",
    total: 0,
    totalPages: 0,
  });
  const [evacueeLoading, setEvacueeLoading] = useState<boolean>(false);
  const [companyLoading, setCompanyLoading] = useState<boolean>(false);
  const [evacueeList, setEvacueeList] = useState<EmergencyDashboardTableInfo[]>(
    []
  );
  const handleClickStatus = (status: EvacuationParticipationStatus) => {
    if (searchTable.status !== status) {
      setSearchTable((prev) => ({
        ...prev,
        status: status,
      }));
    }
  };

  const getDashboardEvacueeInfo = async (page: number) => {
    try {
      setEvacueeLoading(true);

      const convertSearchTable = {
        ...searchTable,
        role:
          searchTable.role === "defaultSelectAllValue"
            ? undefined
            : searchTable.role,
        evacuationBuildingId:
          searchTable.evacuationBuildingId === "defaultSelectAllValue"
            ? undefined
            : searchTable.evacuationBuildingId,
        evacuationCompanyId:
          searchTable.evacuationCompanyId === "defaultSelectAllValue"
            ? undefined
            : searchTable.evacuationCompanyId,
      };
      const params = {
        page: page,
        pageSize: pageable.pageSize,
        ...convertSearchTable,
      } as EmergencyDashboardTableRequest;

      const response = await getDashboardEvacueeList(params);
      if (response.status === HttpStatusCode.Ok) {
        setPageable(response.data.pageable);
        setEvacueeList(response.data.items);
      } else {
        showAlert(`참여자 목록 요청에 실패했습니다. + ${response.status}`);
      }
    } catch (err: any) {
      const error = err.response?.data?.error;

      if (error) {
        showAlert(err.response?.data?.error);
      } else {
        handleError(err, "비상대피 대시보드");
      }
    } finally {
      setEvacueeLoading(false);
    }
  };

  const handleClickSearch = () => {
    getDashboardEvacueeInfo(pageable.page);
  };

  const fetchCompany = async (buildingId: string) => {
    setCompanyLoading(true);
    try {
      const params = {
        buildingId: buildingId,
      } as SearchCondition<BuildingCompany>;
      const response = await getBuildingCompanyListAll(params);
      if (response.status === HttpStatusCode.Ok) {
        if (response.data.length > 0) {
          const companyListForOptions = response.data.map((company) => ({
            value: company.companyId,
            label: company.companyName,
          }));
          setCompanyOptions(companyListForOptions);
        }
      } else {
        showAlert("회사 목록 조회에 실패 하였습니다.");
        setCompanyOptions([]);
      }
    } catch (err: any) {
      const error = err.response?.data?.error;

      if (error) {
        showAlert(err.response?.data?.error);
      } else {
        handleError(err, "대시보드 - 회사 목록");
      }

      setCompanyOptions([]);
    } finally {
      setCompanyLoading(false);
    }
  };

  const getColorAndText = (status: EvacuationParticipationStatus) => {
    let color: string;
    let text: string;
    switch (status) {
      case "EVACUATION_COMPLETE":
        color = "bg-brand-dashboard-green";
        text = "대피완료";
        return { color, text };
      case "HELP_ME":
        color = "bg-brand-dashboard-red";
        text = "도와주세요";
        return { color, text };
      case "ON_LEAVE_OR_OUT":
        color = "bg-brand-dashboard-blue";
        text = "휴가/외근";
        return { color, text };
      case "CREATED_NO_RESPONSE":
        color = "bg-neutral-20";
        text = "무응답";
        return { color, text };
      default:
        return { color: "", text: "" };
    }
  };

  const renderEvacueeStatus = (status: EvacuationParticipationStatus) => {
    const { color, text } = getColorAndText(status);
    if (color && text) {
      return (
        <div className="flex flex-row items-center">
          <div className={`w-[12px] h-[12px] rounded-[8px] mr-1 ${color}`} />
          <div className="text-sm">{text}</div>
        </div>
      );
    }
  };

  const convertVerificationTypeToText = (
    verificationType: "GPS" | "NFC" | "QR" | "BLE" | "ADMIN"
  ) => {
    if (verificationType === "ADMIN") {
      return "관리자 변경";
    } else {
      return verificationType + " 인증";
    }
  };

  useEffect(() => {
    if (
      searchTable.evacuationId &&
      searchTable.evacuationGroupId &&
      searchTable.status
    ) {
      // 로드 후 최초 불러오기, status 변경시마다 불러오기
      getDashboardEvacueeInfo(1);
    }
  }, [
    searchTable.evacuationId,
    searchTable.evacuationGroupId,
    searchTable.status,
  ]);

  useEffect(() => {
    if (searchTable.evacuationBuildingId) {
      fetchCompany(searchTable.evacuationBuildingId);
    }
  }, [searchTable.evacuationBuildingId]);

  return (
    <div className="flex flex-col min-w-[1200px] max-w-[1400px] h-[700px] bg-white rounded-[3px] shadow-md">
      {(evacueeLoading || companyLoading) && <Spinner />}
      <div id="search-box" className="flex flex-col p-5">
        <div className="font-bold" onClick={closePopup}>
          상태별 인원 상세
        </div>
        <div className="flex flex-row justify-between items-center mt-3">
          <div className="flex flex-col">
            <div className="font-semibold">상세검색</div>
            <div id="search-input-box" className="flex flex-row gap-1.5 mt-1">
              <DefaultInput
                placeholder="이름 입력"
                label="이름"
                minWidth={"w-[130px]"}
                value={searchTable.name}
                onChange={(value) =>
                  setSearchTable((prev) => ({
                    ...prev,
                    name: value,
                  }))
                }
              />
              <DefaultInput
                placeholder="연락처 입력"
                label="연락처"
                type="tel"
                minWidth={"w-[130px]"}
                value={searchTable.mobileNumber}
                onChange={(value) =>
                  setSearchTable((prev) => ({
                    ...prev,
                    mobileNumber: value,
                  }))
                }
              />
              <DefaultSelect
                placeholder="전체"
                label="권한"
                width={"w-[130px]"}
                value={searchTable.role}
                optionList={roleOptions}
                onChange={(value) =>
                  setSearchTable((prev) => ({
                    ...prev,
                    role: value,
                  }))
                }
              />
              <DefaultSelect
                placeholder="전체"
                label="건물"
                width={"w-[130px]"}
                optionList={buildingOptions}
                value={searchTable.evacuationBuildingId}
                onChange={(value) => {
                  setSearchTable((prev) => ({
                    ...prev,
                    evacuationBuildingId: value,
                    evacuationCompanyId: undefined,
                    departmentName: undefined,
                  }));
                  if (value === "defaultSelectAllValue") {
                    setCompanyOptions([]);
                  }
                }}
              />
              <DefaultSelect
                placeholder="전체"
                label="회사"
                width={"w-[130px]"}
                optionList={companyOptions}
                value={searchTable.evacuationCompanyId}
                onChange={(value) =>
                  setSearchTable((prev) => ({
                    ...prev,
                    evacuationCompanyId: value,
                    departmentName: undefined,
                  }))
                }
              />
              <DefaultInput
                placeholder="부서 입력"
                label="부서"
                minWidth={"w-[130px]"}
                value={searchTable.evacuationDepartmentName}
                onChange={(value) =>
                  setSearchTable((prev) => ({
                    ...prev,
                    evacuationDepartmentName: value,
                  }))
                }
              />
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <DefaultButton
              className="!text-white !bg-brand-primary-gray-100 !bg-opacity-40 min-w-[90px] rounded-none text-sm font-semibold"
              onClick={handleClickSearch}
            >
              조회
            </DefaultButton>
            <DefaultButton
              className="!bg-brand-primary-gray-100 !bg-opacity-10 h-[38px] min-w-[90px] rounded-none text-sm font-semibold !text-brand-text-black-disabled"
              onClick={() => {
                setSearchTable((prev) => ({
                  ...prev,
                  name: undefined,
                  mobileNumber: undefined,
                  role: undefined,
                  evacuationBuildingId: undefined,
                  evacuationCompanyId: undefined,
                  departmentName: undefined,
                }));
                setCompanyOptions([]);
              }}
            >
              초기화
            </DefaultButton>
          </div>
        </div>
      </div>
      <div id="tab-line" className="flex flex-row w-full h-[40px]">
        <div
          id="left-underline"
          className="w-4 border-b border-[#132029] h-full"
        />
        <div
          id="tabs"
          className="flex flex-row w-[448px] h-full flex-shrink-0 text-sm"
        >
          <div
            className={clsx(
              "flex items-center justify-center w-[112px] h-full border-x border-t cursor-pointer",
              searchTable.status === EvacuationParticipationStatus.HELP_ME
                ? "border-[#132029]"
                : "border-[#B7B7B8] bg-brand-tab text-gray-400"
            )}
            onClick={() =>
              handleClickStatus(EvacuationParticipationStatus.HELP_ME)
            }
          >
            도와주세요
          </div>
          <div
            className={clsx(
              "flex items-center justify-center w-[112px] h-full border-x border-t cursor-pointer",
              searchTable.status ===
                EvacuationParticipationStatus.EVACUATION_COMPLETE
                ? "border-[#132029]"
                : "border-[#B7B7B8] bg-brand-tab text-gray-400"
            )}
            onClick={() =>
              handleClickStatus(
                EvacuationParticipationStatus.EVACUATION_COMPLETE
              )
            }
          >
            대피완료
          </div>
          <div
            className={clsx(
              "flex items-center justify-center w-[112px] h-full border-x border-t cursor-pointer",
              searchTable.status ===
                EvacuationParticipationStatus.ON_LEAVE_OR_OUT
                ? "border-[#132029]"
                : "border-[#B7B7B8] bg-brand-tab text-gray-400"
            )}
            onClick={() =>
              handleClickStatus(EvacuationParticipationStatus.ON_LEAVE_OR_OUT)
            }
          >
            휴가/외근
          </div>
          <div
            className={clsx(
              "flex items-center justify-center w-[112px] h-full border-x border-t cursor-pointer",
              searchTable.status ===
                EvacuationParticipationStatus.CREATED_NO_RESPONSE
                ? "border-[#132029]"
                : "border-[#B7B7B8] bg-brand-tab text-gray-400"
            )}
            onClick={() =>
              handleClickStatus(
                EvacuationParticipationStatus.CREATED_NO_RESPONSE
              )
            }
          >
            무응답
          </div>
        </div>
        <div
          id="right-underline"
          className="w-full border-b border-[#132029] h-full"
        />
      </div>
      <div className="h-[450px] overflow-y-auto overflow-x-hidden">
        <table className="w-full table-fixed">
          <thead className="w-full">
            <tr className="text-xs h-[35px] border-b">
              <th className="w-[100px] font-light text-left p-1 pl-4">이름</th>
              <th className="w-[100px] font-light text-left p-1">권한</th>
              <th className="w-[150px] font-light text-left p-1">건물</th>
              <th className="w-[150px] font-light text-left p-1">회사</th>
              <th className="w-[130px] font-light text-left p-1">부서</th>
              <th className="w-[120px] font-light text-left p-1">상태</th>
              <th className="w-[120px] font-light text-left p-1">연락처</th>
              {searchTable.status === EvacuationParticipationStatus.HELP_ME && (
                <th className="w-[300px] font-light text-left p-1">상세위치</th>
              )}
              {searchTable.status ===
                EvacuationParticipationStatus.EVACUATION_COMPLETE && (
                <th className="w-[300px] font-light text-left p-1">
                  대피완료 인증 방식
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {evacueeList.length > 0 ? (
              evacueeList.map((evacuee, index) => (
                <tr className="text-sm h-[55px] border-b" key={index}>
                  <td className="font-normal text-left p-1 pl-4">
                    {evacuee.name}
                  </td>
                  <td className="font-normal text-left p-1">
                    {getShownEvacueeType(evacuee.role)}
                  </td>
                  <td className="font-normal text-left p-1">
                    {evacuee.evacuationBuildingName}
                  </td>
                  <td className="font-normal text-left p-1">
                    {evacuee.evacuationCompanyName}
                  </td>
                  <td className="font-normal text-left p-1">
                    {evacuee.evacuationDepartmentName ?? "-"}
                  </td>
                  <td className="font-normal text-left p-1 truncate">
                    {renderEvacueeStatus(evacuee.status)}
                  </td>
                  <td
                    className="font-normal text-left p-1 truncate"
                    title={evacuee.mobileNumber}
                  >
                    {evacuee.mobileNumber}
                  </td>
                  {searchTable.status ===
                    EvacuationParticipationStatus.HELP_ME && (
                    <td className="font-normal text-left p-1">
                      <div>{evacuee.rescueLocation ?? "-"}</div>{" "}
                      {evacuee.rescuedAt && (
                        <div className="text-xs font-light">
                          {formatDate(
                            new Date(evacuee.rescuedAt),
                            "yyyy/MM/dd HH:mm"
                          )}
                        </div>
                      )}
                    </td>
                  )}
                  {searchTable.status ===
                    EvacuationParticipationStatus.EVACUATION_COMPLETE && (
                    <td className="font-normal text-left p-1">
                      <div>
                        {convertVerificationTypeToText(
                          evacuee.verificationType
                        )}
                      </div>{" "}
                      {evacuee.modifiedAt && (
                        <div className="text-xs font-light">
                          {formatDate(
                            new Date(evacuee.modifiedAt),
                            "yyyy/MM/dd HH:mm"
                          )}
                        </div>
                      )}
                    </td>
                  )}
                </tr>
              ))
            ) : (
              <tr>
                <td
                  className="text-center py-[70px] text-gray-400"
                  colSpan={
                    searchTable.status ===
                      EvacuationParticipationStatus.HELP_ME ||
                    searchTable.status ===
                      EvacuationParticipationStatus.EVACUATION_COMPLETE
                      ? 8
                      : 7
                  }
                >
                  <span className="flex flex-col gap-2.5 h-full justify-center items-center">
                    <WarningCircleIcon />
                    <span className="text-sm text-black text-opacity-60">
                      참여자 정보가 없습니다.
                    </span>
                  </span>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <Pagination
        totalPages={pageable.totalPages}
        currentPage={pageable.page}
        onPageChange={(page) =>
          setPageable((prev) => {
            if (prev.page !== page) {
              getDashboardEvacueeInfo(page);
              return { ...prev, page };
            } else {
              return { ...prev };
            }
          })
        }
        pageLimit={10}
      />
    </div>
  );
}
