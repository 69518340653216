import { useEffect, useRef, useState } from "react";

import { useModal } from "../../contexts/Modal";
import { useLockScroll } from "../../contexts/LockScroll";

import Spinner from "../Spinner";

type Props = {};

export default function Modal(props: Props) {
  const { modals, closeModal } = useModal();
  const { lockAndUnlockScroll } = useLockScroll();

  const modalRef = useRef<HTMLButtonElement | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (modals.length > 0) {
      modalRef.current?.focus();
      return lockAndUnlockScroll();
    }
  }, [modals]);

  if (modals.length === 0) return null;

  const truncateMessage = (message: string, maxLength: number = 400) => {
    if (message.length <= maxLength) return message;
    return message.substring(0, maxLength) + "...";
  };

  return (
    <>
      {isLoading && <Spinner />}
      {modals.map((modal, index) => (
        <div
          key={index}
          className={`fixed inset-0 z-40 flex items-center justify-center ${
            index === modals.length - 1 ? "bg-black bg-opacity-50" : ""
          }`}
          data-testid="modal-container"
        >
          <div
            className="absolute flex flex-col justify-between bg-white min-w-[420px] min-h-[210px] gap-6 p-6 transform -translate-x-1/2 -translate-y-1/2"
            style={{
              left: "50%",
              top: "50%",
            }}
          >
            <div className="flex mt-1">
              <div>
                <p className="text-xl font-semibold">{modal.title}</p>
                <div
                  className="flex text-sm leading-6 mt-5"
                  data-testid={`${modal.type}-message-div`}
                >
                  <p>
                    {typeof modal.message === "string"
                      ? truncateMessage(modal.message)
                          .split("\n")
                          .map((line, index) => (
                            <span key={index}>
                              {line}
                              <br />
                            </span>
                          ))
                      : modal.message}
                  </p>
                </div>
              </div>
            </div>

            <div className="flex justify-center gap-2 mb-1">
              {modal.type === "confirm" && (
                <button
                  className="bg-brand-primary-gray-100 text-sm text-brand-neutral-50 font-medium min-w-[65px] min-h-[38px] bg-opacity-10"
                  onClick={() => {
                    closeModal(modal.id);
                  }}
                >
                  {modal.closeLabel}
                </button>
              )}
              <button
                className="bg-brand-primary-gray-100 text-brand-neutral-00 font-medium text-sm min-w-[65px] min-h-[38px] outline-none p-2"
                onClick={() => {
                  if (modal.type === "confirm") {
                    setIsLoading(true);
                    modal.onConfirm?.().finally(() => {
                      closeModal(modal.id);
                      setIsLoading(false);
                    });
                  } else {
                    if (modal.onConfirm) {
                      modal.onConfirm?.().finally(() => {
                        closeModal(modal.id);
                      });
                    } else {
                      closeModal(modal.id);
                    }
                  }
                }}
                ref={index === modals.length - 1 ? modalRef : null}
                data-testid={`${modal.type}-confirm-button`}
              >
                {modal.confirmLabel}
              </button>
            </div>
          </div>
        </div>
      ))}
    </>
  );
}
