import clsx from "clsx";
import { useEffect, useState } from "react";

import { SearchDateRange } from "../../types/search";
import DefaultDateRange from "./DefaultDateRange";
import {
  DATE_RANGE_TYPE,
  dateRangeButtonList,
  DEFAULT_SELECT_ALL_VALUE,
} from "../../types/comboBoxOption";
import {
  formatDate,
  getMonthRange,
  getWeekRange,
  getYearRange,
} from "../../utils/dateUtils";

type Props = {
  value: SearchDateRange;
  onChange: (data: SearchDateRange) => void;
  labelName?: string;
  initButtonType?: string;
};

export default function DateRange({
  value,
  onChange,
  labelName,
  initButtonType = DEFAULT_SELECT_ALL_VALUE,
}: Props) {
  const [selectedDate, setSelectedDate] = useState<string>(
    DEFAULT_SELECT_ALL_VALUE
  );

  useEffect(() => {
    if (!value) {
      setSelectedDate(initButtonType);
      handleSelectDate(initButtonType);
    }
  }, [value]);

  const getDateByFormat = (date: Date) => {
    return formatDate(date, "yyyy-MM-dd");
  };

  const getRangeInfo = (type: string): { start: Date; end: Date } => {
    const currentDate = new Date();
    const returnValue = { start: currentDate, end: currentDate };

    switch (type) {
      case DATE_RANGE_TYPE.TODAY.toString():
        return returnValue;
      case DATE_RANGE_TYPE.WEEKLY.toString():
        return getWeekRange(currentDate);
      case DATE_RANGE_TYPE.MONTHLY.toString():
        return getMonthRange(currentDate);
      case DATE_RANGE_TYPE.YEARLY.toString():
        return getYearRange(currentDate);
      default:
        return returnValue;
    }
  };

  const handleSelectDate = (text: string) => {
    let startDate = "";
    let endDate = "";

    if (text !== DEFAULT_SELECT_ALL_VALUE) {
      const { start, end } = getRangeInfo(text);
      startDate = getDateByFormat(start);
      endDate = getDateByFormat(end);
    }

    onChange({ startDate, endDate });
  };

  return (
    <>
      <DefaultDateRange
        value={value}
        onChange={onChange}
        labelName={labelName}
      />
      <span className="pl-2.5">
        {dateRangeButtonList.map((item, index) => (
          <span key={index}>
            <button
              className={clsx([
                "h-[38px] w-[60px] text-label",
                {
                  "text-white bg-brand-primary-gray-100":
                    selectedDate === item.value.toString(),
                  "text-brand-text-black-disabled border border-brand-primary-gray-200 rounded-none":
                    selectedDate !== item.value.toString(),
                },
              ])}
              value={item.value}
              onClick={(e) => {
                setSelectedDate(e.currentTarget.value);
                handleSelectDate(e.currentTarget.value);
              }}
            >
              {item.label}
            </button>
          </span>
        ))}
      </span>
    </>
  );
}
