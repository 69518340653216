import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useMaintenance } from "../../hooks/useMaintenance";

import maintenanceIcon from "../../assets/img/maintenance.png";

type Props = {};

export default function Maintenance(props: Props) {
  const [maintenance, checkMaintenance] = useMaintenance();
  const navigate = useNavigate();

  useEffect(() => {
    checkMaintenance().then((isMaintenance) => {
      if (!isMaintenance) {
        navigate("/login");
      }
    });
  }, []);

  return maintenance.isMaintenance ? (
    <div className="min-w-[956px] h-full min-h-screen w-full bg-gray-100 flex items-center justify-center flex-col">
      <div className="w-[956px] h-[1014px] bg-bg-simbol flex flex-col items-center gap-[83px] pt-[341px]">
        <div className="flex flex-col items-center gap-14">
          <img
            src={maintenanceIcon}
            alt="maintenance-icon"
            className="h-[160px] w-[160px]"
          />
          <div className="flex flex-col items-center gap-6">
            <div className="flex items-center text-3xl font-semibold bg-sandi-gradient text-transparent bg-clip-text h-[29px]">
              시스템 점검중입니다
            </div>
            <div className="h-px w-[90px] bg-black" />
            <div className="flex flex-col items-center">
              <div className="flex flex-col gap-5 text-center text-base font-semibold">
                <div className="flex flex-col gap-2.5 whitespace-pre-line">
                  {maintenance.message}
                  <div>{`서비스 이용에 불편을 드려 죄송합니다.`}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
}
