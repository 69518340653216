export const KOREAN_MIN_LATITUDE = 33.0;
export const KOREAN_MAX_LATITUDE = 38.6;
export const KOREAN_MIN_LONGITUDE = 124.6;
export const KOREAN_MAX_LONGITUDE = 131.9;

export const MAX_COORDINATE_COUNT = 10;
export const MIN_COORDINATE_COUNT = 3;

export const getDefaultPolygon = (map: naver.maps.Map) => ({
  map,
  paths: [],
  fillColor: "#CFE7FF",
  fillOpacity: 0.3,
  strokeColor: "#ff0000",
  strokeOpacity: 0.6,
  strokeWeight: 3,
});

export const DEFAULT_LAT = 37.5466848;
export const DEFAULT_LNG = 127.0477368;
