import { useState } from "react";
import Tab, { TabInfo } from "../../../components/Tab";
import ParcelList from "./ParcelList";
import GoodsRequestList from "./GoodsRequestList";

type Props = {};

export default function ApplyService(props: Props) {
  const [activeTab, setActiveTab] = useState(0);

  const tabInfo: TabInfo[] = [
    { title: "사무용품 신청", page: <GoodsRequestList /> },
    { title: "무인택배 신청", page: <ParcelList /> },
  ];

  const handleTabClick = (tabIndex: number) => {
    setActiveTab(tabIndex);
  };

  return (
    <Tab tabInfo={tabInfo} activeTab={activeTab} onTabClick={handleTabClick} />
  );
}
