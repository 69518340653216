import { HttpStatusCode } from "axios";
import { useContext, useEffect, useState } from "react";

import {
  BANNER_LOCATION_OPTIONS,
  Banner,
  BannerLinkTypeEnum,
  BannerLocationEnum,
  BannerModifyType,
  radioBoxBannerExpose,
  radioBoxBannerLinkType,
} from "../../../../types/banner";
import { BuildingGroup, TagSelectedItem } from "../../../../types/building";
import { DASH } from "../../../../types/search";

import {
  validationFunctions,
  validationResultMessage,
} from "../../../../libs/validations";

import { PopupContext } from "../../../../contexts/Popup";
import { useModal } from "../../../../contexts/Modal";

import { getBanner, putBanner } from "../../../../services/managementService";

import DateTimePickerRange from "../../../DateTime/DateTimePickerRange";
import DefaultButton from "../../../DefaultButton";
import SingleImageUploader from "../../../FileUploader/ImageFileUploader/SingleImageFileUploader";
import FormRow from "../../../Form/Row";
import FormRowLabel from "../../../Form/RowLabel";
import DefaultInput from "../../../Input/DefaultInput";
import RadioButtonGroup from "../../../Input/RadioButtonGroup";
import Spinner from "../../../Spinner";
import TagSingleFilter from "../../../TagFilter/TagSingleFilter";
import { formatDateBanner } from "../../../DateTime/DateTimePicker";
import DefaultSelect from "../../../SelectBox/DefaultSelect";
import DefaultLabel from "../../../Input/DefaultLabel";

type Props = { bannerId: number };

export default function BannerDetailView({ bannerId }: Props) {
  const { showAlert, showConfirm, handleError } = useModal();
  const { closePopup, refreshAndClosePopup } = useContext(PopupContext);
  const [banner, setBanner] = useState<Partial<BannerModifyType>>({});
  const [selectedTag, setSelectedTag] = useState<TagSelectedItem | undefined>(
    undefined
  );
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    try {
      setIsLoading(true);

      const response = await getBanner(bannerId);

      if (response.status === HttpStatusCode.Ok) {
        setBanner({ bannerModifyDto: response.data });

        const { buildingId, buildingName, location } = response.data;

        if (location === BannerLocationEnum.BUILDING && buildingId) {
          setSelectedTag({ buildingId, buildingName } as BuildingGroup);
        }
      }
    } catch (err: any) {
      handleError(err, "조회");
      closePopup();
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleBannerChange = (data: Partial<BannerModifyType>) => {
    setBanner((prev) => ({
      uploadFile: "uploadFile" in data ? data.uploadFile : prev.uploadFile,
      bannerModifyDto: {
        ...prev.bannerModifyDto,
        ...data.bannerModifyDto,
      },
    }));
  };

  const putData = async () => {
    try {
      const response = await putBanner(bannerId, createFormData());

      if (response.status === HttpStatusCode.Ok) {
        showAlert("수정 되었습니다.");
        refreshAndClosePopup();
      }
    } catch (err: any) {
      handleError(err, "수정");
    }
  };

  const createFormData = () => {
    const formData = new FormData();

    banner.uploadFile && formData.append("uploadFile", banner.uploadFile);

    const location = banner.bannerModifyDto?.location;
    const buildingId =
      location === BannerLocationEnum.BUILDING
        ? banner.bannerModifyDto?.buildingId
        : undefined;

    const data: Partial<Banner> = {
      location,
      buildingId,
      title: banner.bannerModifyDto?.title,
      linkUrl: banner.bannerModifyDto?.linkUrl ?? "",
      linkType: banner.bannerModifyDto?.linkType,
      isExpose: banner.bannerModifyDto?.isExpose,
      exposeStartAt: banner.bannerModifyDto?.exposeStartAt,
      exposeEndAt: banner.bannerModifyDto?.exposeEndAt,
      sortOrder: banner.bannerModifyDto?.sortOrder,
    };
    formData.append("bannerModifyDto", JSON.stringify(data));

    return formData;
  };

  const handlePut = () => {
    const isImageUpload = Boolean(
      banner.bannerModifyDto?.file || banner.uploadFile
    );

    const validations = [
      validationFunctions.required(banner.bannerModifyDto?.title, "제목"),
      validationFunctions.numberRange(0, 1000)(
        banner.bannerModifyDto?.sortOrder,
        "노출 순서"
      ),
    ];

    if (!isLinkTypeNull()) {
      validations.push(
        validationFunctions.required(banner.bannerModifyDto?.linkUrl, "링크")
      );
    }

    if (!isImageUpload) {
      validations.push(
        validationFunctions.required(banner.uploadFile, "이미지")
      );
    }

    if (banner.bannerModifyDto?.location === BannerLocationEnum.BUILDING) {
      validations.push(validationFunctions.required(selectedTag, "건물"));
    }

    const errorMessage = validationResultMessage(validations);

    if (errorMessage) {
      showAlert(errorMessage);
    } else {
      showConfirm("배너를 수정 하시겠습니까?", putData);
    }
  };

  const isLinkTypeNull = () =>
    banner.bannerModifyDto?.linkType === BannerLinkTypeEnum.NULL;

  return (
    <div className="flex flex-col gap-5">
      <div className="min-w-fit flex flex-col">
        <FormRow>
          <FormRowLabel title="노출 위치">
            <DefaultSelect
              value={banner.bannerModifyDto?.location}
              optionList={BANNER_LOCATION_OPTIONS}
              onChange={(value: string) => {
                let data: Partial<Banner> = { location: value };

                if (value !== BannerLocationEnum.BUILDING) {
                  setSelectedTag(undefined);
                  data = { ...data, buildingId: undefined };
                }

                handleBannerChange({ bannerModifyDto: data });
              }}
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="제목" isRequired>
            <DefaultInput
              value={banner.bannerModifyDto?.title}
              onChange={(value: string) => {
                handleBannerChange({ bannerModifyDto: { title: value } });
              }}
              maxLength={50}
              hideMaxLength
              placeholder="제목을 입력해주세요"
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="이미지" isRequired>
            <SingleImageUploader
              image={banner.uploadFile}
              onChange={(file: File) => {
                handleBannerChange({ uploadFile: file });
              }}
              defaultImage={banner.bannerModifyDto?.file}
              onDelete={() => {
                handleBannerChange({ bannerModifyDto: { file: undefined } });
              }}
              rightLabel="* 단건 등록"
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="링크" isRequired={!isLinkTypeNull()}>
            <DefaultInput
              value={banner.bannerModifyDto?.linkUrl}
              onChange={(value: string) => {
                handleBannerChange({ bannerModifyDto: { linkUrl: value } });
              }}
              disabled={isLinkTypeNull()}
              maxLength={200}
              hideMaxLength
              placeholder={!isLinkTypeNull() ? "링크를 입력해주세요" : ""}
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="링크 타입">
            <RadioButtonGroup
              options={radioBoxBannerLinkType}
              value={banner.bannerModifyDto?.linkType}
              onChange={(value: string) => {
                const data: Partial<Banner> = {
                  linkType: value,
                  ...(value === BannerLinkTypeEnum.NULL && {
                    linkUrl: undefined,
                  }),
                };
                handleBannerChange({ bannerModifyDto: data });
              }}
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="노출 기간">
            {banner.bannerModifyDto?.exposeStartAt &&
              banner.bannerModifyDto?.exposeEndAt && (
                <DateTimePickerRange
                  startPeriod={new Date(banner.bannerModifyDto.exposeStartAt)}
                  endPeriod={new Date(banner.bannerModifyDto.exposeEndAt)}
                  onStartPeriodChange={(date: Date) => {
                    handleBannerChange({
                      bannerModifyDto: {
                        exposeStartAt: formatDateBanner(date),
                      },
                    });
                  }}
                  onEndPeriodChange={(date: Date) => {
                    handleBannerChange({
                      bannerModifyDto: { exposeEndAt: formatDateBanner(date) },
                    });
                  }}
                />
              )}
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="노출 여부">
            <RadioButtonGroup
              options={radioBoxBannerExpose}
              value={banner.bannerModifyDto?.isExpose?.toString()}
              onChange={(value: string) => {
                handleBannerChange({
                  bannerModifyDto: {
                    isExpose: value === "true" ? true : false,
                  },
                });
              }}
            />
          </FormRowLabel>
          <FormRowLabel title="노출 순서" isRequired>
            <DefaultInput
              type="number"
              value={banner.bannerModifyDto?.sortOrder?.toString()}
              onChange={(value: string) => {
                handleBannerChange({
                  bannerModifyDto: {
                    sortOrder: value !== "" ? Number(value) : undefined,
                  },
                });
              }}
              maxLength={10}
              hideMaxLength
              placeholder="노출 순서를 입력해주세요"
            />
          </FormRowLabel>
        </FormRow>
        {banner.bannerModifyDto?.location === BannerLocationEnum.BUILDING && (
          <FormRow>
            <FormRowLabel title="노출 대상" isRequired>
              <TagSingleFilter
                selectType="building"
                selectedItem={selectedTag}
                onChangeBuilding={(item: BuildingGroup) => {
                  setSelectedTag(item);
                  if (item.buildingId) {
                    handleBannerChange({
                      bannerModifyDto: { buildingId: item.buildingId },
                    });
                  } else {
                    console.error("buildingId is not exist while selecting");
                  }
                }}
              />
            </FormRowLabel>
          </FormRow>
        )}
        <FormRow>
          <FormRowLabel title="최초 등록자">
            <DefaultLabel
              text={banner.bannerModifyDto?.createdByName ?? ""}
              masking="BO_BANNER_DETAIL_CREATED_BY"
              targetId={banner.bannerModifyDto?.createdBy ?? -1}
              property="NAME"
            />
          </FormRowLabel>
          <FormRowLabel title="최초 등록일">
            <DefaultLabel text={banner.bannerModifyDto?.createdAt ?? ""} />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="최종 수정자">
            <DefaultLabel
              text={banner.bannerModifyDto?.modifiedByName ?? ""}
              masking="BO_BANNER_DETAIL_MODIFIED_BY"
              targetId={banner.bannerModifyDto?.modifiedBy ?? -1}
              property="NAME"
            />
          </FormRowLabel>
          <FormRowLabel title="최종 수정일">
            <DefaultLabel text={banner.bannerModifyDto?.modifiedAt ?? DASH} />
          </FormRowLabel>
        </FormRow>
      </div>

      <div className="flex gap-2 justify-center">
        <DefaultButton onClick={closePopup}>닫기</DefaultButton>
        <DefaultButton color="primary" onClick={handlePut}>
          수정
        </DefaultButton>
      </div>

      {isLoading && <Spinner />}
    </div>
  );
}
