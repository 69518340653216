import { useEffect, useState } from "react";

import {
  Incommodity,
  IncommodityCategoryList,
  incommodityStatusMap,
} from "../../../types/incommodity";
import { ComboBoxType, ComponentType, DASH } from "../../../types/search";
import { BOOLEAN_TYPES } from "../../../types/comboBoxOption";
import { buildingGroupCategoryCodeValue } from "../../../types/building";

import { addDefaultOption } from "../../../utils/comboBoxUtils";

import { useUserContext } from "../../../contexts/User";
import { useModal } from "../../../contexts/Modal";

import {
  getIncommodityCategory,
  getIncommodityList,
  getIncommodityListExcel,
} from "../../../services/incommodityService";

import { TableColumnType } from "../../../components/Table";
import DefaultButton from "../../../components/DefaultButton";
import IncommodityDetailView from "../../../components/Incommodity/DetailView";
import IncommodityListTopRight from "../../../components/Incommodity/ListTopRight";
import ListPage from "../../../components/ListPage";
import Spinner from "../../../components/Spinner";

import { FiEdit2 } from "react-icons/fi";
import UseYesIcon from "../../../svgs/icons/UseYn/UseYesIcon";
import UseNoIcon from "../../../svgs/icons/UseYn/UseNoIcon";

type Props = {};

export default function IncommodityList(props: Props) {
  const { handleError } = useModal();
  const { getCategoryCodeByRole } = useUserContext();

  const [isLoading, setIsLoading] = useState(false);
  const [category, setCategory] = useState<IncommodityCategoryList>({});

  const columnInfo: TableColumnType<Incommodity>[] = [
    { header: "접수 번호", name: "centerIncommodityId", width: "id" },
    {
      header: "VOC 접수 번호",
      render: (item: Incommodity) => item.vocReceiptId ?? DASH,
      width: "id",
    },
    { header: "접수 채널", name: "channelName" },
    { header: "상태", name: "statusName", width: "100" },
    {
      header: "예약여부",
      render: (item: Incommodity) => (item.deadLine ? "예약" : "즉시처리"),
      width: "70",
    },
    { header: "카테고리", name: "categoryName" },
    { header: "건물", name: "buildingName" },
    { header: "층", name: "floorName" },
    { header: "상세 위치", name: "location" },
    { header: "신청자 회사", name: "companyName" },
    { header: "신청자 이름", name: "createdByName" },
    {
      header: "사진 접수 여부",
      render: (item: Incommodity) => (
        <div className="flex justify-center items-center">
          {item.isFile ? <UseYesIcon /> : <UseNoIcon />}
        </div>
      ),
      width: "icon",
    },
    {
      header: "만족도 평가 여부",
      render: (item: Incommodity) => (
        <div className="flex justify-center items-center">
          {item.isSatisfaction ? <UseYesIcon /> : <UseNoIcon />}
        </div>
      ),
      width: "icon",
    },
    { header: "접수일시", name: "createdAt", width: "date" },
    {
      header: "활동",
      render: (item: Incommodity, handler) => (
        <div className="w-full h-full flex gap-2 justify-center items-center">
          <DefaultButton
            size="tableIcon"
            color="blue"
            onClick={() => {
              if (handler?.popup) {
                handler.popup({
                  header: "접수 상세",
                  content: (
                    <IncommodityDetailView
                      centerIncommodityId={item.centerIncommodityId}
                    />
                  ),
                });
              }
            }}
          >
            <FiEdit2 />
          </DefaultButton>
        </div>
      ),
      width: "icon",
    },
  ];

  const fetchCategory = async () => {
    try {
      setIsLoading(true);

      const response = await getIncommodityCategory("");

      if (response.data) {
        setCategory(response.data);
      } else {
        throw new Error("Category load fail");
      }
    } catch (err: any) {
      handleError(err, "카테고리 조회");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCategory();
  }, []);

  const comboBoxChannel: ComboBoxType[] = [
    { value: "23", label: "모바일" },
    { value: "11", label: "도움마당" },
    { value: "12", label: "센터접수" },
    // { value: "22", label: "빌딩포탈(통근)" },
  ];

  const comboBoxStatus: ComboBoxType[] = [
    { value: incommodityStatusMap["receipt"].value, label: "요청" },
    { value: incommodityStatusMap["confirm"].value, label: "처리중" },
    { value: incommodityStatusMap["schedule"].value, label: "처리예정" },
    { value: incommodityStatusMap["complete"].value, label: "처리완료" },
    { value: incommodityStatusMap["cancel"].value, label: "취소" },
  ];

  const comboBoxReservationStatus: ComboBoxType[] = [
    { value: BOOLEAN_TYPES.FALSE, label: "즉시처리" },
    { value: BOOLEAN_TYPES.TRUE, label: "예약" },
  ];

  const comboBoxIsFile: ComboBoxType[] = [
    { value: BOOLEAN_TYPES.TRUE, label: "접수" },
    { value: BOOLEAN_TYPES.FALSE, label: "미접수" },
  ];

  const comboBoxIsSatisfaction: ComboBoxType[] = [
    { value: BOOLEAN_TYPES.TRUE, label: "평가" },
    { value: BOOLEAN_TYPES.FALSE, label: "미평가" },
  ];

  const componentList: ComponentType<Incommodity>[] = [
    {
      labelName: "접수일자",
      typeName: "dateRange",
      keyName: "createdAt",
      category: "date",
    },
    {
      labelName: "접수채널",
      typeName: "comboBox",
      keyName: "channel",
      comboBoxOptions: addDefaultOption(comboBoxChannel),
      category: "typeSearch",
    },
    {
      labelName: "상태",
      typeName: "comboBox",
      keyName: "status",
      comboBoxOptions: addDefaultOption(comboBoxStatus),
      category: "typeSearch",
    },
    {
      labelName: "예약 여부",
      typeName: "comboBox",
      keyName: "reservationStatus",
      comboBoxOptions: addDefaultOption(comboBoxReservationStatus),
      category: "typeSearch",
    },
    {
      labelName: "사진 접수 여부",
      typeName: "comboBox",
      keyName: "isFile",
      comboBoxOptions: addDefaultOption(comboBoxIsFile),
      category: "typeSearch",
    },
    {
      labelName: "만족도 평가 여부",
      typeName: "comboBox",
      keyName: "isSatisfaction",
      comboBoxOptions: addDefaultOption(comboBoxIsSatisfaction),
      category: "typeSearch",
    },
    {
      labelName: "포함 검색",
      typeName: "tagFilter",
      keyName: "tagFilter",
      category: "typeSearch",
      buildingGroupCategoryCode: getCategoryCodeByRole(
        buildingGroupCategoryCodeValue.INCOMMODITY
      ),
    },
    {
      labelName: "접수 번호",
      typeName: "number",
      keyName: "centerIncommodityId",
      category: "detailSearch",
      placeholder: "접수 번호를 입력해주세요",
    },
    {
      labelName: "VOC 접수 번호",
      typeName: "text",
      keyName: "vocId",
      category: "detailSearch",
      placeholder: "VOC 접수 번호를 입력해주세요",
    },
    {
      labelName: "카테고리",
      typeName: "incommodityCategory",
      keyName: "serviceTemplateId",
      incommodityCategory: {
        data: category,
        allOptionLabels: ["소분류 전체", "상세분류 전체"],
      },
      category: "detailSearch",
    },
    {
      labelName: "신청자 이름",
      typeName: "text",
      keyName: "createdByName",
      category: "detailSearch",
      placeholder: "신청자 이름을 입력해주세요",
    },
  ];

  return (
    <>
      {isLoading && <Spinner />}
      <ListPage
        getDataApi={getIncommodityList}
        keyId={(item: Incommodity) => item.centerIncommodityId}
        columnInfo={columnInfo}
        componentList={componentList}
        tableTitle="접수 목록"
        renderTopRight={<IncommodityListTopRight />}
        excelDownloadApi={getIncommodityListExcel}
        needExcelDownloadReason
      />
    </>
  );
}
