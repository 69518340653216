import { useContext } from "react";
import { PopupContext } from "../../../../contexts/Popup";
import DefaultButton from "../../../DefaultButton";
import TermsCreateForm from "../CreateForm";
import { ComboBoxType } from "../../../../types/search";

type Props = { category: ComboBoxType[] };

export default function TermsListTopRight({ category }: Props) {
  const { openPopup } = useContext(PopupContext);

  return (
    <DefaultButton
      size="tableTop"
      color="default"
      onClick={() =>
        openPopup({
          header: "약관 등록",
          content: <TermsCreateForm category={category} />,
        })
      }
    >
      약관 등록
    </DefaultButton>
  );
}
