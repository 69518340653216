import { AxiosResponse } from "axios";
import { ListResponse } from "../../../libs/request";
import { ComponentType } from "../../../types/search";
import DefaultButton from "../../DefaultButton";
import ListPage, { SearchCondition } from "../../ListPage";
import { TableColumnType } from "../../Table";
import { DEFAULT_PAGE_SIZE } from "../../../services/buildingService";
import { RightArrowIcon } from "../../TagSelector";

type Props<T> = {
  getDataApi: (
    searchCondition: SearchCondition<T>
  ) => Promise<AxiosResponse<ListResponse<T> | T[]>>;
  componentList?: ComponentType<T>[];
  tableComponentList?: ComponentType<T>[];
  columnInfo: TableColumnType<T>[];
  title?: string;
  selectedData: T[];
  hidePageSize?: boolean;
  hidePagination?: boolean;
  defaultPageSize?: number;
  pageLimit?: number;
  onSelectedDataChange: (data: T[]) => void;
  compareItem: (item: T) => (o: T) => boolean;
};

export default function MultiSelectTable<T>({
  getDataApi,
  componentList = [],
  tableComponentList = [],
  columnInfo,
  title,
  selectedData,
  hidePageSize = false,
  hidePagination = false,
  defaultPageSize = DEFAULT_PAGE_SIZE,
  pageLimit = 5,
  onSelectedDataChange,
  compareItem,
}: Props<T>) {
  const handleVisible = (item: T) => {
    return selectedData.some(compareItem(item));
  };

  const handleAdd = (item: T) => {
    onSelectedDataChange([item, ...selectedData]);
  };

  const handleRemove = (item: T) => {
    const data = selectedData.filter((selectedItem) => selectedItem !== item);
    onSelectedDataChange(data);
  };

  const renderAdd = (item: T) => {
    const visible = handleVisible(item);

    return (
      <div className="w-full h-3 flex gap-2 justify-center items-center">
        {!visible && (
          <DefaultButton
            onClick={() => {
              handleAdd(item);
            }}
          >
            추가
          </DefaultButton>
        )}
      </div>
    );
  };

  const renderRemove = (item: T) => (
    <div className="w-full h-3 flex gap-2 justify-center items-center">
      <DefaultButton
        onClick={() => {
          handleRemove(item);
        }}
      >
        삭제
      </DefaultButton>
    </div>
  );

  const headerAddAll = (data: T[]) => {
    return (
      <DefaultButton
        color="white"
        className="h-[22px] text-xs font-normal"
        onClick={() => {
          const newData = data.filter(
            (item) => !selectedData.some(compareItem(item))
          );

          onSelectedDataChange([...newData, ...selectedData]);
        }}
      >
        전체 선택
      </DefaultButton>
    );
  };

  const headerRemoveAll = () => {
    return (
      <DefaultButton
        color="white"
        className="h-[22px] text-xs font-normal"
        onClick={() => onSelectedDataChange([])}
      >
        전체 해제
      </DefaultButton>
    );
  };

  return (
    <div className="flex gap-2 w-full">
      <div className="min-w-[600px] w-full">
        <ListPage
          getDataApi={getDataApi}
          columnInfo={[
            ...columnInfo,
            {
              header: "활동",
              render: renderAdd,
              headerRender: headerAddAll,
            },
          ]}
          componentList={componentList}
          tableTitle={title}
          isTableScroll={true}
          hidePageSize={hidePageSize}
          hidePagination={hidePagination}
          defaultPageSize={defaultPageSize}
          pageLimit={pageLimit}
          tableComponentList={tableComponentList}
        />
      </div>
      <div className="flex items-center w-1/9">
        <RightArrowIcon />
      </div>
      <div className="min-w-[600px] w-full">
        <ListPage
          getDataApi={getDataApi}
          selectedData={selectedData}
          columnInfo={[
            ...columnInfo,
            {
              header: "활동",
              render: renderRemove,
              headerRender: headerRemoveAll,
            },
          ]}
          componentList={componentList}
          tableTitle={`선택 ${title}`}
          selectedMode={true}
          isTableScroll={true}
          hidePageSize={hidePageSize}
          hidePagination={hidePagination}
          defaultPageSize={defaultPageSize}
          pageLimit={pageLimit}
        />
      </div>
    </div>
  );
}
