import { useContext, useState } from "react";
import { PopupContext } from "../../../../contexts/Popup";
import {
  validationFunctions,
  validationResultMessage,
} from "../../../../libs/validations";
import {
  Building,
  BuildingFloor,
  initBuildingFloor,
} from "../../../../types/building";
import DefaultButton from "../../../DefaultButton";
import SingleImageUploader from "../../../FileUploader/ImageFileUploader/SingleImageFileUploader";
import FloorList from "../../../FloorList";
import FormHeader from "../../../Form/Header";
import FormRow from "../../../Form/Row";
import FormRowLabel from "../../../Form/RowLabel";
import DefaultInput from "../../../Input/DefaultInput";
import { postBuilding } from "../../../../services/buildingService";
import { HttpStatusCode } from "axios";
import { useModal } from "../../../../contexts/Modal";
import PermissionWrapper from "../../../PermissionWrapper";

type Props = {};

export default function BuildingCreateForm(props: Props) {
  const { showAlert, showConfirm, handleError } = useModal();
  const { closePopup, refreshAndClosePopup } = useContext(PopupContext);
  const [building, setBuilding] = useState<Partial<Building>>({});
  const [floorData, setFloorData] = useState<BuildingFloor[]>([
    { ...initBuildingFloor },
  ]);

  const handleBuildingChange =
    (property: keyof Building) => (data: string | number | File) => {
      setBuilding((prev) => {
        return { ...prev, [property]: data };
      });
    };

  const handlePost = () => {
    const floorNames = floorData.map((floor) => floor.floorName);

    const errorMessage = validationResultMessage([
      validationFunctions.required(building?.buildingName, "건물명"),
      validationFunctions.required(building?.helpDeskTel, "고객센터"),
      validationFunctions.required(building?.address, "주소"),
      validationFunctions.required(building?.lat, "위도"),
      validationFunctions.number(building?.lat, "위도"),
      validationFunctions.required(building?.lng, "경도"),
      validationFunctions.number(building?.lng, "경도"),
      validationFunctions.required(building?.gridX, "격자 X"),
      validationFunctions.number(building?.gridX, "격자 X"),
      validationFunctions.required(building?.gridY, "격자 Y"),
      validationFunctions.number(building?.gridY, "격자 Y"),
      ...(floorNames.length > 1
        ? [validationFunctions.required(floorNames, "층 정보")]
        : []),
    ]);

    if (errorMessage) {
      showAlert(errorMessage);
    } else {
      showConfirm("등록 하시겠습니까?", postData);
    }
  };

  const postData = async () => {
    try {
      const response = await postBuilding(createFormData());

      if (response.status === HttpStatusCode.Ok) {
        showAlert("등록 되었습니다.");
        refreshAndClosePopup();
      } else {
        throw new Error("Building post fail");
      }
    } catch (err: any) {
      handleError(err, "등록");
    }
  };

  const createFormData = () => {
    const formData = new FormData();

    const postData: Partial<Building> = {
      ...building,
      isActive: true,
      lat: Number(building.lat),
      lng: Number(building.lng),
      gridX: Number(building.gridX),
      gridY: Number(building.gridY),
      buildingFloorList: floorData.filter((floor) => floor.floorName),
    };

    building.imageFile && formData.append("imageFile", building.imageFile);
    formData.append("request", JSON.stringify(postData));

    return formData;
  };

  return (
    <div className="flex flex-col gap-5">
      <div className="flex flex-col min-w-[900px] max-h-[700px] overflow-y-auto">
        <FormHeader title="기본 정보" />
        <FormRow>
          <FormRowLabel title="건물명" isRequired>
            <DefaultInput
              value={building?.buildingName}
              onChange={handleBuildingChange("buildingName")}
              placeholder="건물명을 입력해주세요"
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="대표사진">
            <SingleImageUploader
              image={building.imageFile}
              onChange={(file: File) => {
                handleBuildingChange("imageFile")(file);
              }}
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="고객센터" isRequired>
            <DefaultInput
              type="tel"
              value={building?.helpDeskTel}
              onChange={handleBuildingChange("helpDeskTel")}
              placeholder="전화번호를 입력해주세요"
            />
          </FormRowLabel>
        </FormRow>

        <FormHeader
          title="상세 정보"
          right={
            <span className="text-sm">
              ※위경도 정보는 구글 지도서비스(
              <a
                href="https://map.google.com"
                target="_blank"
                className="underline"
              >
                map.google.com
              </a>
              )를 이용하여 검색할 수 있습니다
            </span>
          }
        />
        <FormRow>
          <FormRowLabel title="주소" isRequired>
            <DefaultInput
              value={building?.address}
              placeholder="주소를 입력해주세요"
              onChange={handleBuildingChange("address")}
              minWidth="w-full"
              maxLength={100}
              hideMaxLength
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="위경도" isRequired>
            <div className="flex gap-2">
              <DefaultInput
                value={building?.lat?.toString()}
                label="위도"
                placeholder="위도 입력"
                onChange={handleBuildingChange("lat")}
              />
              <DefaultInput
                value={building?.lng?.toString()}
                label="경도"
                placeholder="경도 입력"
                onChange={handleBuildingChange("lng")}
              />
            </div>
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="날씨기준 정보" isRequired>
            <div className="flex gap-2">
              <DefaultInput
                value={building?.gridX?.toString()}
                label="격자 X"
                placeholder="격자 X 입력"
                onChange={handleBuildingChange("gridX")}
              />
              <DefaultInput
                value={building?.gridY?.toString()}
                label="격자 Y"
                placeholder="격자 Y 입력"
                onChange={handleBuildingChange("gridY")}
              />
              <DefaultInput
                value={building?.airMonitoringStation}
                label="측정소"
                placeholder="측정소명을 입력해주세요"
                onChange={handleBuildingChange("airMonitoringStation")}
              />
            </div>
          </FormRowLabel>
        </FormRow>

        <FormHeader title="층 정보" />
        <FormRow>
          <FloorList data={floorData} onChange={setFloorData} />
        </FormRow>
      </div>

      <div className="flex justify-center gap-2">
        <DefaultButton onClick={closePopup}>닫기</DefaultButton>
        <PermissionWrapper>
          <DefaultButton color="primary" onClick={handlePost}>
            등록
          </DefaultButton>
        </PermissionWrapper>
      </div>
    </div>
  );
}
