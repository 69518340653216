import { useContext } from "react";

import { PopupContext } from "../../../../../contexts/Popup";
import DefaultButton from "../../../../DefaultButton";
import EmergencyBuildingGroupCreateForm from "../CreateForm";

type Props = {
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function EmergencyBuildingGroupListTopRight({
  setRefresh,
}: Props) {
  const { openPopup } = useContext(PopupContext);
  return (
    <>
      <DefaultButton
        size="tableTop"
        color="default"
        onClick={() =>
          openPopup({
            header: "비상대피 건물 그룹 등록",
            content: (
              <EmergencyBuildingGroupCreateForm setRefresh={setRefresh} />
            ),
          })
        }
        testId="add-emergency-group"
      >
        신규 등록
      </DefaultButton>
    </>
  );
}
