import clsx from "clsx";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { RouteInfo } from "../../libs/routes";

type Props = {
  currentPath: string;
  route: RouteInfo;
  parentPath: string;
  prefix?: React.ReactNode;
};

export default function LowerMenu({
  currentPath,
  route,
  parentPath,
  prefix,
}: Props) {
  const isLeaf = !route.children;
  const childPath = `${parentPath}${route.path ? `/${route.path}` : ""}`;
  const [open, setOpen] = useState<Boolean>(
    isLeaf || (currentPath.includes(childPath) && route.path) ? true : false
  );
  const navigate = useNavigate();

  const getTitleClassName = () => {
    if (isLeaf) {
      if (childPath === currentPath) {
        return "text-white bg-brand-primary-gray-140";
      } else {
        return "text-white text-opacity-80";
      }
    } else {
      if (open === false) {
        return "text-white text-opacity-80";
      } else {
        return "text-white text-opacity-80";
      }
    }
  };

  return (
    <>
      <div
        className={clsx(
          "transition-all pl-16 pr-[25px] py-2 h-[30px] w-full text-xs",
          getTitleClassName()
        )}
        data-testid="lower-menu-wrapper-div"
      >
        <div
          className="flex justify-between w-full hover:opacity-100 cursor-pointer"
          onClick={() => {
            if (isLeaf) {
              navigate(childPath);
            } else {
              setOpen((prev) => !prev);
            }
          }}
        >
          <div className={`flex items-center`}>
            {prefix}
            {route.title}
          </div>
          {!isLeaf && (
            <span>{open ? <LowerMenuMinus /> : <LowerMenuPlus />}</span>
          )}
        </div>
      </div>
      {open &&
        route.children?.map((child, index) => {
          return (
            <LowerMenu
              key={index}
              route={child}
              parentPath={childPath}
              currentPath={currentPath}
              prefix={<LowerMenuLeafPrefix />}
            />
          );
        })}
    </>
  );
}

export const LowerMenuLeafPrefix = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.99997 7.99997V8.00003"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
  </svg>
);

export const LowerMenuPlus = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="lower-menu-plus-icon"
  >
    <path
      d="M11.5355 7.99989H4.46447"
      stroke="#868687"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M8 4.46436V11.5354"
      stroke="#868687"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
  </svg>
);

export const LowerMenuMinus = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="lower-menu-minus-icon"
  >
    <path
      d="M11.5355 8.00013H4.46447"
      stroke="#868687"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
  </svg>
);
