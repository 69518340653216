import { useContext } from "react";
import { PopupContext } from "../../../../contexts/Popup";
import DefaultButton from "../../../DefaultButton";
import StoreCreateForm from "../CreateForm";
import { CategoryData } from "../../../../types/category";
import PermissionWrapper from "../../../PermissionWrapper";

type Props = { categoryData: CategoryData[] };

export default function StoreListTopRight({ categoryData }: Props) {
  const { openPopup } = useContext(PopupContext);

  return (
    <PermissionWrapper>
      <DefaultButton
        size="tableTop"
        color="default"
        onClick={() =>
          openPopup({
            header: "상점 등록",
            content: <StoreCreateForm categoryData={categoryData} />,
          })
        }
      >
        등록
      </DefaultButton>
    </PermissionWrapper>
  );
}
