import React, { ReactNode, useEffect, useState } from "react";
import clsx from "clsx";

import { useLockScroll } from "../../../contexts/LockScroll";

import Spinner from "../../Spinner";

type Props = {
  header?: ReactNode;
  content?: ReactNode;
  isOpen?: boolean;
  closeModal?: () => void;
  type?: string;
  onConfirm: () => Promise<void>;
  confirmLabel?: string;
  cancelLabel?: string;
  onCancel?: () => void;
};

export default function SimpleModal({
  header,
  content,
  isOpen,
  closeModal,
  onConfirm,
  onCancel,
  confirmLabel = "확인",
  cancelLabel = "취소",
  type = "confirm",
}: Props) {
  const { lockAndUnlockScroll } = useLockScroll();
  const [isLoading, setIsLoading] = useState(false);

  const handleConfirm = async () => {
    if (type === "confirm") {
      setIsLoading(true);
      try {
        await onConfirm();
      } catch (e) {
        console.error(e);
      } finally {
        closeModal?.();
        setIsLoading(false);
      }
    } else {
      closeModal?.();
    }
  };

  useEffect(() => {
    if (isOpen) {
      const contentElement = document.getElementById("common-popup-content");
      if (contentElement) {
        contentElement.scrollTop = 0;
      }

      return lockAndUnlockScroll();
    }
  }, [isOpen]);

  return (
    <>
      {isLoading && <Spinner />}
      <div
        className={clsx(
          "fixed inset-0 z-40 flex items-center justify-center bg-black bg-opacity-50",
          {
            hidden: !isOpen,
          }
        )}
        data-testid="modal-container"
      >
        <div
          className="absolute flex flex-col justify-between bg-white min-w-[420px] min-h-[210px] gap-6 p-6 transform -translate-x-1/2 -translate-y-1/2"
          style={{
            left: "50%",
            top: "50%",
          }}
          onClick={closeModal}
        >
          <div className="flex mt-1">
            <div className="w-full">
              <p className="text-xl font-semibold text-black">{header}</p>
              <div className="flex text-sm leading-6 mt-5">{content}</div>
            </div>
          </div>

          <div className="flex justify-center gap-2 mb-1">
            {type === "confirm" && (
              <button
                className="bg-brand-primary-gray-100 text-sm text-brand-neutral-50 font-medium min-w-[65px] min-h-[38px] bg-opacity-10 px-5"
                onClick={() => {
                  onCancel?.();
                }}
              >
                {cancelLabel}
              </button>
            )}
            <button
              className="bg-brand-primary-gray-100 text-brand-neutral-00 font-medium text-sm min-w-[65px] min-h-[38px] outline-none px-5"
              onClick={handleConfirm}
              data-testid="simple-modal-confirm-button"
            >
              {confirmLabel}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
