import { FileInfo } from "./file";

export type Service = {
  serviceId: string;
  serviceType: ServiceCategoryType;
  serviceTypeName: string;
  isActive: boolean;
  serviceName: string;
  sortOrder: number;
  serviceItemType: string;
  serviceItemTypeName: string;
  serviceDetailType: string;
  serviceTemplateId: number;
  target: ServiceType;
  useOnlyAuthorized: boolean;
  useCustomServiceName: boolean;
  useCustomIcon: boolean;
  serviceTemplateName: string;
  createdAt: string;
  modifiedAt: string;
  usedCount: number;
  fileInfo: {
    items: FileInfo[];
  };
  iconFile: File;
};

export type ServiceType = "BUILDING" | "COMPANY";

export const SERVICE_INPUT_TYPE = {
  NAME: "name",
  ICON: "icon",
  AUTH: "auth",
};

export type ServiceDetailType =
  | "name"
  | "nameIcon"
  | "nameIconAuth"
  | "nameAuth"
  | "auth";

export const serviceRenderMap: Record<string, ServiceDetailType> = {
  VOC: "nameAuth", // 불편신고
  MANAGER: "nameIcon", // 관리자 서비스
  SPACE: "nameIconAuth", // 공간 서비스
  REQUEST: "nameIconAuth", // 신청 서비스
  BUILDING_BBS: "auth", // 건물 게시판
  RENTAL: "nameIconAuth", // 대여 서비스
  SERVICE_ETC: "nameIcon",
};

export type ServiceCategoryType =
  | "RENTAL"
  | "SPACE"
  | "BUILDING_BBS"
  | "CAFETERIA"
  | "VOC"
  | "MONITORING"
  | "REQUEST"
  | "SERVICE_ETC"
  | "REQUEST"
  | "MANAGER"
  | "CONTRACT";

export const serviceCategoryMap: Record<ServiceCategoryType, string> = {
  RENTAL: "대여 서비스",
  SPACE: "공간 서비스",
  BUILDING_BBS: "건물 게시판",
  CAFETERIA: "구내식당",
  VOC: "불편신고",
  MONITORING: "모니터링",
  REQUEST: "신청 서비스",
  SERVICE_ETC: "기타 서비스",
  MANAGER: "관리자 서비스",
  CONTRACT: "계약 관리",
};

export type ServiceListType = "templateList" | "vocList" | "otherList";

export type ServiceList = {
  templateList: Service[];
  vocList: { [key: string]: Service[] };
  otherList: { [key: string]: Service[] };
};

export type ServiceGroup = {
  groupId: string;
  groupName: string;
  categoryCode: string;
  categoryName: string;
  buildingId: string;
  buildingName: string;
  createdAt: string;
  modifiedAt: string;
  groupType: string;
  items: ServiceGroupItem[];
};

export type ServiceGroupDto = {
  groupName: string;
  categoryCode: string;
  items: string[];
};

export type ServiceGroupCategory = {
  categoryCode: string;
  categoryName: string;
};

export type ServiceGroupItem = {
  itemId: string;
  itemName: string;
};
