type Props = {};

export default function CompanyMenuIcon(props: Props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.6">
        <path
          d="M9.5 13H14.5M5 5H19V9H5V5ZM6 9V18C6 18.5523 6.44772 19 7 19H17C17.5523 19 18 18.5523 18 18V9H6Z"
          stroke="white"
        />
      </g>
    </svg>
  );
}
