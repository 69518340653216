import { SearchCondition } from "../components/ListPage";
import request, { ListResponse } from "../libs/request";
import { MaskingType } from "../types/masking";
import { Member, MemberSelect, MemberVirtualNumber } from "../types/member";
import { getExcelDownloadApi } from "./commonService";

const MEMBER_API_PREFIX = "/v2/admin/member";

export const patchMemberCompanyVerification = (userId: number) =>
  request.patch<ListResponse<Member>>(
    MEMBER_API_PREFIX + `/${userId}/company/verification`
  );

export const getMemberList = (params: SearchCondition<Member>) => {
  const { buildingId, companyId, ...otherParams } = params;

  const newParams = {
    ...otherParams,
    ...(buildingId && { buildingId: buildingId.join(",") }),
    ...(companyId && { companyId: companyId.join(",") }),
  };

  return request.get<ListResponse<Member>>(MEMBER_API_PREFIX, {
    params: newParams,
  });
};

export const getMember = (userId: number) =>
  request.get<Member>(MEMBER_API_PREFIX + `/${userId}`);

export const getMemberExistence = (name: string, mobileNumber: string) =>
  request.get<MemberSelect>(MEMBER_API_PREFIX + `/existence`, {
    params: { name, mobileNumber },
  });

export const getMemberExcel = (params: SearchCondition<Member>) => {
  const { buildingId, companyId, ...otherParams } = params;

  const newParams = {
    ...otherParams,
    ...(buildingId && { buildingId: buildingId.join(",") }),
    ...(companyId && { companyId: companyId.join(",") }),
  };

  return getExcelDownloadApi(MEMBER_API_PREFIX + "/excel", newParams);
};

export const putMemberRole = (userId: number, roles: string[]) =>
  request.put(MEMBER_API_PREFIX + `/${userId}/role`, roles);

export const getMemberVirtualNumber = (memberId: number) =>
  request.get<MemberVirtualNumber>(
    MEMBER_API_PREFIX + `/${memberId}/virtual-number`
  );

export type MemberMaskingInformation = {
  name?: string;
  email?: string;
  mobileNumber?: string;
  birthday?: string;
};

export const getPersonalInformation = (memberId: number, params: any) => {
  return request.get<MemberMaskingInformation>(
    MEMBER_API_PREFIX + `/${memberId}/personal-information`,
    { params }
  );
};

export type MemberInfoAccessInformation = {
  logId: number;
  memberId: number;
  type: MaskingType;
  remoteIp4: string;
  createdAt: string;
  reason: string;
  accessProperty: string | null;
  accessTarget: string;
};

export const getMemberInfoAccessList = (params: any) =>
  request.get(MEMBER_API_PREFIX + "/log/list", { params });
