type Props = {};

export default function BoardMenuIcon(props: Props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.6">
        <path
          d="M4.75 14L4.39645 13.6464L4.25 13.7929V14H4.75ZM13.75 5L14.1036 4.64645C13.9083 4.45118 13.5917 4.45118 13.3964 4.64645L13.75 5ZM18.75 10L19.1036 10.3536C19.2988 10.1583 19.2988 9.84171 19.1036 9.64645L18.75 10ZM9.75 19V19.5H9.95711L10.1036 19.3536L9.75 19ZM4.75 19H4.25C4.25 19.2761 4.47386 19.5 4.75 19.5V19ZM5.10355 14.3536L14.1036 5.35355L13.3964 4.64645L4.39645 13.6464L5.10355 14.3536ZM13.3964 5.35355L18.3964 10.3536L19.1036 9.64645L14.1036 4.64645L13.3964 5.35355ZM18.3964 9.64645L9.39645 18.6464L10.1036 19.3536L19.1036 10.3536L18.3964 9.64645ZM9.75 18.5H4.75V19.5H9.75V18.5ZM5.25 19V14H4.25V19H5.25ZM10.3964 8.35355L15.3964 13.3536L16.1036 12.6464L11.1036 7.64645L10.3964 8.35355ZM12.25 19.5H19.25V18.5H12.25V19.5Z"
          fill="white"
        />
      </g>
    </svg>
  );
}
