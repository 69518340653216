import { AxiosHeaders, AxiosResponse } from "axios";
import { ListResponse } from "../libs/request";

export type Pageable = {
  offset: number;
  page: number;
  pageSize: number;
  sort: string | null;
  total: number;
  totalPages: number;
};

export const initPageable = {
  offset: 0,
  page: 0,
  pageSize: 0,
  sort: "",
  total: 0,
  totalPages: 0,
};

export const getSamplePageable = <T>(
  sampleData: T[],
  page: number,
  pageSize: number = 0
): AxiosResponse<ListResponse<T>> => {
  return {
    data: {
      items: sampleData.slice(
        (page - 1) * pageSize,
        (page - 1) * pageSize + pageSize
      ),
      pageable: {
        offset: 0,
        page,
        pageSize,
        sort: "",
        total: sampleData.length,
        totalPages: Math.ceil(sampleData.length / pageSize),
      },
    },
    status: 200,
    statusText: "OK",
    headers: {},
    config: {
      headers: new AxiosHeaders(),
    },
  };
};
