import { FileInfo } from "../../../../types/file";
import { ImageType } from "../../../../types/incommodity";
import MultiImageUploader from "../MultiImageFileUploader";

type Props = {
  maxFileSizeMB?: number;
  image?: File;
  onChange: (file: File) => void;
  defaultImage?: ImageType | FileInfo;
  onDelete?: (image: ImageType | FileInfo) => void;
  label?: string;
  rightLabel?: string;
  showDeleteButton?: boolean;
};

export default function SingleImageUploader({
  maxFileSizeMB = 10,
  image,
  onChange,
  defaultImage,
  onDelete,
  label,
  rightLabel,
  showDeleteButton = true,
}: Props) {
  return (
    <MultiImageUploader
      maxImages={1}
      maxFileSizeMB={maxFileSizeMB}
      images={image ? [image] : []}
      onChange={(value: File[]) => onChange(value[0])}
      defaultImages={defaultImage ? [defaultImage] : []}
      onDelete={onDelete}
      label={label}
      rightLabel={rightLabel}
      showDeleteButton={showDeleteButton}
    />
  );
}
