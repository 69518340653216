import { useEffect, useState } from "react";

import { ComboBoxType, ComponentType } from "../../../types/search";
import { ServiceGroup } from "../../../types/service";

import { addDefaultOption, toComboBoxType } from "../../../utils/comboBoxUtils";

import { useModal } from "../../../contexts/Modal";

import {
  getServiceGroupCategoryList,
  getServiceGroupList,
  getServiceGroupListExcel,
} from "../../../services/ServiceGroupService";

import { TableColumnType } from "../../../components/Table";
import DefaultButton from "../../../components/DefaultButton";
import ListPage from "../../../components/ListPage";
import Spinner from "../../../components/Spinner";
import ServiceGroupListTopRight from "../../../components/PageComponents/Service/ServiceGroupList/ListTopRight";
import ServiceGroupDetailView from "../../../components/PageComponents/Service/ServiceGroupList/DetailView";

type Props = {};

export default function ServiceGroupList(props: Props) {
  const { handleError } = useModal();
  const [isLoading, setIsLoading] = useState(false);
  const [categoryOptions, setCategoryOptions] = useState<ComboBoxType[]>([]);

  const columnInfo: TableColumnType<ServiceGroup>[] = [
    { header: "No.", defaultNo: true, width: "no" },
    { header: "서비스그룹 ID", name: "groupId" },
    { header: "서비스그룹명", name: "groupName" },
    { header: "서비스유형", name: "categoryName" },
    {
      header: "그룹관리",
      render: (item: ServiceGroup, handler) => (
        <div className="w-full h-full flex gap-2 justify-center items-center">
          <DefaultButton
            color="blue"
            size="tableButton"
            onClick={() => {
              if (handler?.popup) {
                handler.popup({
                  header: "서비스 그룹 관리",
                  content: (
                    <ServiceGroupDetailView
                      groupId={item.groupId}
                      groupName={item.groupName}
                      category={categoryOptions}
                    />
                  ),
                });
              }
            }}
          >
            관리
          </DefaultButton>
        </div>
      ),
      width: "button",
    },
  ];

  useEffect(() => {
    const fetchCategory = async () => {
      try {
        setIsLoading(true);

        const response = await getServiceGroupCategoryList();
        if (response.data) {
          const options = toComboBoxType(
            response.data,
            "categoryCode",
            "categoryName"
          );

          setCategoryOptions(options);
        }
      } catch (err: any) {
        handleError(err, "카테고리 조회");
      } finally {
        setIsLoading(false);
      }
    };

    fetchCategory();
  }, []);

  const componentList: ComponentType<ServiceGroup>[] = [
    {
      labelName: "등록일자",
      typeName: "dateRange",
      keyName: "createdAt",
      category: "date",
    },
    {
      labelName: "등록서비스 카테고리",
      typeName: "comboBox",
      keyName: "categoryCode",
      comboBoxOptions: addDefaultOption(categoryOptions),
      category: "typeSearch",
    },
    {
      labelName: "서비스그룹명",
      typeName: "text",
      keyName: "groupName",
      category: "detailSearch",
      placeholder: "서비스그룹명을 입력해주세요",
    },
    {
      labelName: "서비스그룹 ID",
      typeName: "text",
      keyName: "groupId",
      category: "detailSearch",
      placeholder: "서비스그룹 ID를 입력해주세요",
    },
    {
      labelName: "건물 ID",
      typeName: "text",
      keyName: "buildingId",
      category: "detailSearch",
      placeholder: "건물 ID를 입력해주세요",
    },
    {
      labelName: "건물명",
      typeName: "text",
      keyName: "buildingName",
      category: "detailSearch",
      placeholder: "건물명을 입력해주세요",
    },
  ];

  return (
    <>
      {isLoading && <Spinner />}
      <ListPage
        keyId={(item: ServiceGroup) => item.groupId}
        getDataApi={getServiceGroupList}
        excelDownloadApi={getServiceGroupListExcel}
        columnInfo={columnInfo}
        componentList={componentList}
        tableTitle="서비스그룹 목록 전체"
        renderTopRight={<ServiceGroupListTopRight category={categoryOptions} />}
      />
    </>
  );
}
