import { AxiosResponse, HttpStatusCode } from "axios";
import { useEffect, useState } from "react";

import { Incommodity, incommodityErrorCode } from "../../../types/incommodity";
import { CrudProcessType } from "../../../types/common";

import {
  validationFunctions,
  validationResultMessage,
} from "../../../libs/validations";

import { useModal } from "../../../contexts/Modal";

import {
  patchIncommoditySatisfaction,
  postIncommoditySatisfaction,
} from "../../../services/incommodityService";

import DefaultButton from "../../DefaultButton";
import DefaultTextArea from "../../DefaultTextArea";
import ListPage from "../../ListPage";
import Spinner from "../../Spinner";
import { TableColumnType } from "../../Table";
import DefaultLabel from "../../Input/DefaultLabel";

import StarPointTrueIcon from "../../../svgs/icons/StarPoint/StarPointTrue";
import StarPointFalseIcon from "../../../svgs/icons/StarPoint/StarPointFalse";

const SATISFACTION_REPLY_EMPTY_TEXT = "등록된 이용자 만족도 평가가 없습니다.";

type Props = {
  incommodity: Partial<Incommodity>;
  refreshData: () => void;
};

export default function SatisfactionReply({ incommodity, refreshData }: Props) {
  const { showAlert, showConfirm, handleError } = useModal();
  const [comment, setComment] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const handleCommentChange = (value: string) => {
    setComment(value);
  };

  useEffect(() => {
    if (incommodity.satisfactionReplyComment) {
      setComment(incommodity.satisfactionReplyComment);
    }
  }, [incommodity.satisfactionReplyComment]);

  const handleData = async (
    id: number,
    apiFunc: (id: number, comment: string) => Promise<AxiosResponse>,
    word: string
  ) => {
    try {
      setIsLoading(true);
      if (id) {
        const response = await apiFunc(id, comment);
        if (response.status === HttpStatusCode.Ok) {
          if (response.data.result === incommodityErrorCode.ERROR) {
            showAlert(response.data.message);
          } else {
            showAlert(`${word} 되었습니다.`);
          }
        } else {
          throw new Error("Failed to process comment");
        }
      } else {
        throw new Error("Invalid id value");
      }
    } catch (err: any) {
      handleError(err, word);
    } finally {
      refreshData();
      setIsLoading(false);
    }
  };

  const handleCommentProcess = (process: CrudProcessType) => {
    const word = process === "CREATE" ? "등록" : "수정";
    const id =
      process === "CREATE"
        ? incommodity.centerIncommodityId
        : incommodity.satisfactionReplyId;
    const api =
      process === "CREATE"
        ? postIncommoditySatisfaction
        : patchIncommoditySatisfaction;
    const errorMessage = validationResultMessage([
      validationFunctions.required(comment, "댓글"),
      validationFunctions.numberRange(10, 500)(comment.length ?? 0, "댓글"),
    ]);

    if (errorMessage) {
      showAlert(errorMessage);
    } else {
      showConfirm(`댓글을 ${word} 하시겠습니까?`, async () => {
        if (id) {
          handleData(id, api, word);
        } else {
          console.error("Invalid centerIncommodityId");
        }
      });
    }
  };

  const renderStarPoint = (point: number) => {
    return (
      <div className="flex items-center justify-center">
        {Array.from({ length: 5 }, (_, i) => (
          <span key={i}>
            {i < point ? <StarPointTrueIcon /> : <StarPointFalseIcon />}
          </span>
        ))}
      </div>
    );
  };

  const columnInfo: TableColumnType<Partial<Incommodity>>[] = [
    {
      header: "별점",
      render: (item: Partial<Incommodity>) =>
        renderStarPoint(item.satisfaction ?? 0),
    },
    { header: "작성일시", name: "satisfactionCreatedAt" },
    {
      header: "이름(작성자 ID)",
      render: (item: Partial<Incommodity>) => (
        <div className="flex items-center gap-1 justify-center">
          <DefaultLabel
            text={item.satisfactionCreatedByName ?? ""}
            masking={"BO_INCOMMODITY_DETAIL_SATISFIED_BY"}
            targetId={item.satisfactionCreatedBy ?? -1}
            property="NAME"
            prefix="이름 : "
            className="min-w-[0px]"
          />
          {item.satisfactionCreatedBy && `(${item.satisfactionCreatedBy})`}
        </div>
      ),
    },
    { header: "내용", name: "satisfactionComment" },
  ];

  const renderButton = () => {
    const selectedButton = Boolean(incommodity.satisfactionReplyId);

    return (
      <DefaultButton
        color="white"
        className="py-[11px] rounded-none h-full w-[92px] text-sm font-medium whitespace-nowrap"
        onClick={() => {
          selectedButton
            ? handleCommentProcess("UPDATE")
            : handleCommentProcess("CREATE");
        }}
      >
        댓글
        <br />
        {selectedButton ? "수정" : "등록"}
      </DefaultButton>
    );
  };

  return (
    <>
      {isLoading && <Spinner />}
      <div className="flex flex-col gap-3 w-full">
        <ListPage<Partial<Incommodity>>
          getDataApi={Promise.resolve}
          columnInfo={columnInfo}
          selectedMode
          selectedData={incommodity.satisfactionId ? [incommodity] : []}
          defaultContentText={SATISFACTION_REPLY_EMPTY_TEXT}
          onlyRenderTable
        />
        {(incommodity.satisfactionId ?? 0) !== 0 && (
          <div className="flex h-[100px]">
            <DefaultTextArea
              value={comment}
              onChange={handleCommentChange}
              maxLength={500}
              placeholder="사용자 평가에 대한 댓글을 남겨주세요 (10자 이상)"
            />
            {renderButton()}
          </div>
        )}
      </div>
    </>
  );
}
