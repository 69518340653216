import { useContext, useState } from "react";
import { useModal } from "../../../../contexts/Modal";
import { PopupContext } from "../../../../contexts/Popup";
import {
  validationFunctions,
  validationResultMessage,
} from "../../../../libs/validations";
import { EtcTerms } from "../../../../types/etc";
import { ComboBoxType } from "../../../../types/search";
import DateTimePicker from "../../../DateTime/DateTimePicker";
import DefaultButton from "../../../DefaultButton";
import DefaultTextArea from "../../../DefaultTextArea";
import FormRow from "../../../Form/Row";
import FormRowLabel from "../../../Form/RowLabel";
import DefaultInput from "../../../Input/DefaultInput";
import DefaultSelect from "../../../SelectBox/DefaultSelect";
import { formatDate } from "../../../../utils/dateUtils";
import { postEtcTerms } from "../../../../services/etcService";
import { HttpStatusCode } from "axios";
import {
  TERMS_GRADE_OPTIONS,
  TERMS_GRADE_TYPES,
} from "../../../../types/comboBoxOption";
import RadioButtonGroup from "../../../Input/RadioButtonGroup";

type Props = { category: ComboBoxType[] };

const getInitDate = () => {
  const calcDate = new Date();
  calcDate.setDate(calcDate.getDate() + 1);
  calcDate.setHours(0, 0, 0, 0);
  return calcDate;
};

export const TERMS_VERSION_REGEX =
  /^(?:[0-9]|[1-9][0-9])\.(?:[0-9]|[1-9][0-9])\.(?:[0-9]|[1-9][0-9])$/;
export const TERMS_VERSION_REGEX_COMMENT = "0~99.0~99.0~99";

export default function TermsCreateForm({ category }: Props) {
  const { showAlert, showConfirm, handleError } = useModal();
  const { closePopup, refreshAndClosePopup } = useContext(PopupContext);
  const [terms, setTerms] = useState<Partial<EtcTerms>>({
    grade: TERMS_GRADE_TYPES.MANDATORY,
  });
  const [date, setDate] = useState<Date>(getInitDate());

  const handleTermsChange = (key: keyof EtcTerms) => (value: any) => {
    setTerms((prev) => ({ ...prev, [key]: value }));
  };

  const handlePost = () => {
    const errorMessage = validationResultMessage([
      validationFunctions.required(terms.termsCategoryId, "약관 유형"),
      validationFunctions.matchesRegex(
        terms.version,
        TERMS_VERSION_REGEX,
        "약관 버전",
        TERMS_VERSION_REGEX_COMMENT
      ),
      validationFunctions.required(terms.name, "약관명"),
      validationFunctions.required(terms.content, "약관 내용"),
      ...(!date ? [validationFunctions.required(date, "약관 고지일시")] : []),
    ]);

    if (errorMessage) {
      showAlert(errorMessage);
    } else {
      showConfirm("등록 하시겠습니까?", async () => {
        try {
          const res = await postEtcTerms(createData());
          if (res.status === HttpStatusCode.Ok) {
            showAlert("등록 되었습니다.");
            refreshAndClosePopup();
          }
        } catch (err: any) {
          handleError(err, "등록");
        }
      });
    }
  };

  const createData = () => {
    const data: Partial<EtcTerms> = {
      termsCategoryId: terms.termsCategoryId,
      name: terms.name,
      description: terms.description,
      content: terms.content,
      postAt: formatDate(date, "yyyy/MM/dd HH:mm:ss"),
      version: terms.version,
      grade: terms.grade,
    };

    return data;
  };

  return (
    <div className="flex flex-col gap-5">
      <div className="flex flex-col min-w-[1000px] max-h-popup overflow-y-auto">
        <FormRow>
          <FormRowLabel title="약관 유형" isRequired>
            <DefaultSelect
              value={terms.termsCategoryId?.toString()}
              optionList={category}
              onChange={(value: string) => {
                const num = Number(value);
                if (isNaN(num)) {
                  showAlert(
                    "약관 유형 타입이 올바르지 않습니다. 관리자에게 문의해주세요."
                  );
                } else {
                  handleTermsChange("termsCategoryId")(num);
                }
              }}
              placeholder="선택"
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="약관 버전" isRequired>
            <DefaultInput
              value={terms.version}
              onChange={handleTermsChange("version")}
              placeholder="버전을 입력해주세요.  ex) 0.0.1"
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="필수 여부" isRequired>
            <RadioButtonGroup
              options={TERMS_GRADE_OPTIONS}
              value={terms.grade}
              onChange={handleTermsChange("grade")}
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="약관명" isRequired>
            <DefaultInput
              value={terms.name}
              onChange={handleTermsChange("name")}
              placeholder="약관명을 입력해주세요"
              minWidth="w-full"
              maxLength={50}
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="약관 설명">
            <DefaultInput
              value={terms.description}
              onChange={handleTermsChange("description")}
              placeholder="약관 설명을 입력해주세요"
              minWidth="w-full"
              maxLength={100}
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="약관 내용" isRequired>
            <DefaultTextArea
              rows={10}
              value={terms.content}
              onChange={handleTermsChange("content")}
              placeholder="약관 내용을 입력해주세요"
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="약관 고지일시" isRequired>
            <DateTimePicker
              date={date}
              onDateChange={setDate}
              minuteStep={30}
            />
          </FormRowLabel>
        </FormRow>
      </div>

      <div className="flex gap-2 justify-center">
        <DefaultButton onClick={closePopup}>닫기</DefaultButton>
        <DefaultButton color="primary" onClick={handlePost}>
          저장
        </DefaultButton>
      </div>
    </div>
  );
}
