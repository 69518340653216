import { useContext } from "react";
import { PopupContext } from "../../../contexts/Popup";
import DefaultButton from "../../DefaultButton";
import CompanyCreateForm from "../CreateForm";

type Props = {};

export default function CompanyListTopRight(props: Props) {
  const { openPopup } = useContext(PopupContext);
  return (
    <>
      <DefaultButton
        size="tableTop"
        color="default"
        onClick={() =>
          openPopup({ header: "회사 등록", content: <CompanyCreateForm /> })
        }
      >
        회사 등록
      </DefaultButton>
    </>
  );
}
