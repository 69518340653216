import DateTimePicker from "../DateTimePicker";

type Props = {
  startPeriod?: Date;
  endPeriod?: Date;
  onlyTime?: boolean;
  minuteStep?: number;
  startDate?: Date;
  endDate?: Date;
  onStartPeriodChange: (period: Date) => void;
  onEndPeriodChange: (period: Date) => void;
  testId?: string;
};

export default function DateTimePickerRange({
  startPeriod = new Date(),
  endPeriod = new Date(),
  onlyTime = false,
  minuteStep = 1,
  startDate,
  endDate,
  onStartPeriodChange,
  onEndPeriodChange,
  testId,
}: Props) {
  return (
    <div className="flex gap-2 items-center">
      <DateTimePicker
        date={startPeriod}
        maxDate={endPeriod}
        onlyTime={onlyTime}
        startDate={startDate}
        endDate={endDate}
        onDateChange={onStartPeriodChange}
        minuteStep={minuteStep}
        testId={`${testId}-date-time-picker-start`}
      />
      -
      <DateTimePicker
        date={endPeriod}
        minDate={startPeriod}
        onlyTime={onlyTime}
        startDate={startDate}
        endDate={endDate}
        onDateChange={onEndPeriodChange}
        minuteStep={minuteStep}
        testId={`${testId}-date-time-picker-end`}
      />
    </div>
  );
}
