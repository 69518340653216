import { HttpStatusCode } from "axios";
import { useContext, useState } from "react";

import {
  BANNER_LOCATION_OPTIONS,
  Banner,
  BannerCreateType,
  BannerLinkTypeEnum,
  BannerLocationEnum,
  radioBoxBannerLinkType,
} from "../../../../types/banner";
import { BuildingGroup, TagSelectedItem } from "../../../../types/building";

import {
  validationFunctions,
  validationResultMessage,
} from "../../../../libs/validations";
import { getToday } from "../../../../utils/dateUtils";

import { useModal } from "../../../../contexts/Modal";
import { PopupContext } from "../../../../contexts/Popup";
import { useUserContext } from "../../../../contexts/User";

import { postBanner } from "../../../../services/managementService";

import { formatDateBanner } from "../../../DateTime/DateTimePicker";
import DateTimePickerRange from "../../../DateTime/DateTimePickerRange";
import DefaultButton from "../../../DefaultButton";
import SingleImageUploader from "../../../FileUploader/ImageFileUploader/SingleImageFileUploader";
import FormRow from "../../../Form/Row";
import FormRowLabel from "../../../Form/RowLabel";
import DefaultInput from "../../../Input/DefaultInput";
import RadioButtonGroup from "../../../Input/RadioButtonGroup";
import DefaultSelect from "../../../SelectBox/DefaultSelect";
import TagSingleFilter from "../../../TagFilter/TagSingleFilter";

type Props = {};

export default function BannerCreateForm(props: Props) {
  const { userInfo } = useUserContext();
  const { showAlert, showConfirm, handleError } = useModal();
  const { closePopup, refreshAndClosePopup } = useContext(PopupContext);
  const [banner, setBanner] = useState<Partial<BannerCreateType>>({
    bannerCreateDto: {
      location: BannerLocationEnum.MAINROLL,
      linkType: BannerLinkTypeEnum.CURRENT,
      isExpose: true,
      buildingId: "",
      companyId: "",
    },
  });
  const [selectedTag, setSelectedTag] = useState<TagSelectedItem>();

  const handleBannerChange = (data: Partial<BannerCreateType>) => {
    setBanner((prev) => ({
      uploadFile: "uploadFile" in data ? data.uploadFile : prev.uploadFile,
      bannerCreateDto: {
        ...prev.bannerCreateDto,
        ...data.bannerCreateDto,
      },
    }));
  };

  const postData = async () => {
    try {
      const response = await postBanner(createFormData());

      if (response.status === HttpStatusCode.Ok) {
        showAlert("등록 되었습니다.");
        refreshAndClosePopup();
      }
    } catch (err: any) {
      handleError(err, "등록");
    }
  };

  const createFormData = () => {
    const formData = new FormData();

    banner.uploadFile && formData.append("uploadFile", banner.uploadFile);

    const location = banner.bannerCreateDto?.location;
    const buildingId =
      location === BannerLocationEnum.BUILDING
        ? banner.bannerCreateDto?.buildingId
        : undefined;

    const data: Partial<Banner> = {
      location,
      buildingId,
      title: banner.bannerCreateDto?.title,
      linkUrl: banner.bannerCreateDto?.linkUrl,
      linkType: banner.bannerCreateDto?.linkType,
      exposeStartAt: banner.bannerCreateDto?.exposeStartAt,
      exposeEndAt: banner.bannerCreateDto?.exposeEndAt,
      sortOrder: banner.bannerCreateDto?.sortOrder,
    };
    formData.append("bannerCreateDto", JSON.stringify(data));

    return formData;
  };

  const handlePost = () => {
    const validations = [
      validationFunctions.required(banner.bannerCreateDto?.title, "제목"),
      validationFunctions.required(banner.uploadFile, "이미지"),
      validationFunctions.required(
        banner.bannerCreateDto?.sortOrder,
        "노출 순서"
      ),
      validationFunctions.numberRange(0, 1000)(
        banner.bannerCreateDto?.sortOrder,
        "노출 순서"
      ),
    ];

    if (!isLinkTypeNull()) {
      validations.push(
        validationFunctions.required(banner.bannerCreateDto?.linkUrl, "링크")
      );
    }

    if (banner.bannerCreateDto?.location === BannerLocationEnum.BUILDING) {
      validations.push(validationFunctions.required(selectedTag, "건물"));
    }

    const errorMessage = validationResultMessage(validations);

    if (errorMessage) {
      showAlert(errorMessage);
    } else {
      showConfirm("배너를 등록 하시겠습니까?", postData);
    }
  };

  const isLinkTypeNull = () =>
    banner.bannerCreateDto?.linkType === BannerLinkTypeEnum.NULL;

  return (
    <div className="flex flex-col gap-5">
      <div className="flex flex-col max-h-popup overflow-y-auto">
        <FormRow>
          <FormRowLabel title="노출 위치">
            <DefaultSelect
              value={banner.bannerCreateDto?.location}
              optionList={BANNER_LOCATION_OPTIONS}
              onChange={(value: string) =>
                handleBannerChange({ bannerCreateDto: { location: value } })
              }
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="제목" isRequired>
            <DefaultInput
              value={banner.bannerCreateDto?.title}
              onChange={(value: string) => {
                handleBannerChange({ bannerCreateDto: { title: value } });
              }}
              placeholder="제목을 입력해주세요"
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="이미지" isRequired>
            <SingleImageUploader
              image={banner.uploadFile}
              onChange={(file: File) => {
                handleBannerChange({ uploadFile: file });
              }}
              rightLabel="* 단건 등록"
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="링크" isRequired={!isLinkTypeNull()}>
            <DefaultInput
              value={banner.bannerCreateDto?.linkUrl}
              onChange={(value: string) => {
                handleBannerChange({ bannerCreateDto: { linkUrl: value } });
              }}
              disabled={isLinkTypeNull()}
              placeholder={!isLinkTypeNull() ? "링크를 입력해주세요" : ""}
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="링크 타입">
            <RadioButtonGroup
              options={radioBoxBannerLinkType}
              value={banner.bannerCreateDto?.linkType}
              onChange={(value: string) => {
                const data: Partial<Banner> = {
                  linkType: value,
                  ...(value === BannerLinkTypeEnum.NULL && {
                    linkUrl: undefined,
                  }),
                };
                handleBannerChange({ bannerCreateDto: data });
              }}
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="노출 기간">
            <DateTimePickerRange
              startPeriod={
                banner.bannerCreateDto?.exposeStartAt
                  ? new Date(banner.bannerCreateDto.exposeStartAt)
                  : undefined
              }
              endPeriod={
                banner.bannerCreateDto?.exposeEndAt
                  ? new Date(banner.bannerCreateDto.exposeEndAt)
                  : undefined
              }
              onStartPeriodChange={(date: Date) => {
                handleBannerChange({
                  bannerCreateDto: { exposeStartAt: formatDateBanner(date) },
                });
              }}
              onEndPeriodChange={(date: Date) => {
                handleBannerChange({
                  bannerCreateDto: { exposeEndAt: formatDateBanner(date) },
                });
              }}
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="노출 순서" isRequired>
            <DefaultInput
              type="number"
              value={banner.bannerCreateDto?.sortOrder?.toString()}
              onChange={(value: string) => {
                handleBannerChange({
                  bannerCreateDto: {
                    sortOrder: value !== "" ? Number(value) : undefined,
                  },
                });
              }}
              placeholder="노출 순서를 입력해주세요"
            />
          </FormRowLabel>
        </FormRow>
        {banner.bannerCreateDto?.location === BannerLocationEnum.BUILDING && (
          <FormRow>
            <FormRowLabel title="노출 대상" isRequired>
              <TagSingleFilter
                selectType="building"
                selectedItem={selectedTag}
                onChangeBuilding={(item: BuildingGroup) => {
                  setSelectedTag(item);
                  if (item.buildingId) {
                    handleBannerChange({
                      bannerCreateDto: { buildingId: item.buildingId },
                    });
                  } else {
                    console.error("buildingId is not exist while selecting");
                  }
                }}
              />
            </FormRowLabel>
          </FormRow>
        )}
        <FormRow>
          <FormRowLabel title="최초 등록자">
            <DefaultInput value={userInfo?.name} disabled />
          </FormRowLabel>
          <FormRowLabel title="최초 등록일">
            <DefaultInput value={getToday()} disabled />
          </FormRowLabel>
        </FormRow>
      </div>
      <div className="flex gap-2 justify-center">
        <DefaultButton onClick={closePopup}>닫기</DefaultButton>
        <DefaultButton color="primary" onClick={handlePost}>
          저장
        </DefaultButton>
      </div>
    </div>
  );
}
