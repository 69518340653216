import React from "react";
import { Outlet, RouteObject, matchPath } from "react-router-dom";
import Layout from "../components/Layout";
import PostList from "../pages/Board/PostList";
import BuildingList from "../pages/Building/BuildingList";
import CompanyList from "../pages/Company/CompanyList";
import Login from "../pages/Login";
import NotFound from "../pages/NotFound";
import Maintenance from "../pages/Maintenance";
import BuildingCompanyList from "../pages/Building/BuildingCompanyList";
import IncommodityList from "../pages/Service/IncommodityList";
import ReportList from "../pages/Board/ReportList";
import BannerList from "../pages/Management/Banner/BannerList";
import StoreList from "../pages/Store/StoreList";
import BatchList, { BatchListRightInfo } from "../pages/System/Batch/BatchList";
import ToiletList from "../pages/IotDevice/Space/ToiletList";
import SpaceList from "../pages/Service/SpaceList";
import TempHumList from "../pages/IotDevice/Space/TempHumList";
import TempHumTagList from "../pages/IotDevice/Device/TempHumTagList";
import ToiletTagList from "../pages/IotDevice/Device/ToiletTagList";
import ServiceGroupList from "../pages/Service/ServiceGroupList";
import IotStatus from "../pages/IotDevice/IotStatus";
import ManagerList from "../pages/Management/Manager/ManagerList";
import MemberList from "../pages/Member/MemberList";
import SpaceReservation from "../pages/Service/SpaceReservation";
import Redirect from "../components/Redirect";
import HomeMenuIcon from "../svgs/icons/Sidebar/HomeMenuIcon";
import BuildingMenuIcon from "../svgs/icons/Sidebar/BuildingMenuIcon";
import CompanyMenuIcon from "../svgs/icons/Sidebar/CompanyMenuIcon";
import MemberMenuIcon from "../svgs/icons/Sidebar/MemberMenuIcon";
import BoardMenuIcon from "../svgs/icons/Sidebar/BoardMenuIcon";
import ServiceMenuIcon from "../svgs/icons/Sidebar/ServiceMenuIcon";
import SystemMenuIcon from "../svgs/icons/Sidebar/SystemMenuIcon";
import ManagementMenuIcon from "../svgs/icons/Sidebar/ManagementMenuIcon";
import IotMenuIcon from "../svgs/icons/Sidebar/IotMenuIcon";
import ApplyService from "../pages/Service/ApplyService";
import AppVersionList from "../pages/System/AppVersionList";
import GoodsRequestItemList from "../pages/Service/GoodsRequestItemList";
import GoodsRentalItemList from "../pages/Service/GoodsRentalItemList";
import GoodsRentalStockList from "../pages/Service/GoodsRentalStockList";
import GoodsRentalStockHistoryList from "../pages/Service/GoodsRentalStockHistoryList";
import TemplateList from "../pages/Service/TemplateList";
import GoodsRentalList from "../pages/Service/GoodsRentalList";
import LoggingLoginList from "../pages/System/Logging/LoginList";
import LoggingPermissionList from "../pages/System/Logging/PermissionList";
import TermsList from "../pages/Management/Terms/TermsList";
import { UserMenu } from "../services/userServices";
import MaskingList from "../pages/Management/Masking/List";
import EmergencyBuildingGroupList from "@/pages/Emergency/EmergencyBuildingGroupList";
import ShelterList from "@/pages/Emergency/ShelterList";
import EvacueeList from "@/pages/Emergency/EvacueeList";
import SituationList from "@/pages/Emergency/SituationList";
import GeoLocationList from "../pages/Management/GeoLocation/List";
import AccessList from "../pages/Management/Access/AccessList";
import Dashboard from "@/components/Dashboard";
import EmergencyMenuIcon from "@/svgs/icons/Sidebar/EmergencyMenuIcon";
import ExcelConverter from "@/pages/Emergency/Excel";
import Referer from "@/pages/Referer"; // todo - 임시
import DashboardLayout from "@/components/DashboardLayout";
import StatusHistoryList from "@/pages/Emergency/StatusHistoryList";

export type RouteInfo = Omit<RouteObject, "children"> & {
  title?: string;
  icon?: React.ReactNode;
  children?: RouteInfo[];
  hidden?: boolean;
  programId?: string;
  scope?: string;
  renderRight?: React.ReactNode;
};

export type MenuInfo = {
  programId: string;
  scope: string;
};

// API로부터 받은 userRoutes 데이터를 재귀적으로 탐색하여 모든 url을 배열로 만듭니다.
export const flattenProgramId = (menus: UserMenu[]): MenuInfo[] => {
  let ids: MenuInfo[] = [];
  menus.forEach((menu) => {
    ids.push({ programId: menu.programId, scope: menu.scope });
    if (menu.menuList) {
      ids = [...ids, ...flattenProgramId(menu.menuList)];
    }
  });
  return ids;
};

export const getVisibleRoutes = (routes: RouteInfo[]): RouteObject[] => {
  // `hidden` 속성이 true인 라우트를 제거합니다.
  const visibleRoutes = routes.filter((route) => !route.hidden);

  for (const route of visibleRoutes) {
    if (route.children) {
      route.children = getVisibleRoutes(route.children);
    }
  }

  return visibleRoutes as RouteObject[];
};

export function hideRoutesNotInProgramList(
  routes: RouteInfo[],
  programList: MenuInfo[]
): RouteInfo[] {
  return routes.map((route) => {
    const matchingProgram = programList.find(
      (item) => item.programId === route.programId
    );

    // `programId`가 있는 라우트의 경우, `programList`에 포함되어 있지 않으면 hidden 처리합니다.
    const updatedRoute: RouteInfo = {
      ...route,
      hidden: Boolean(route.programId) && Boolean(!matchingProgram),
      scope: matchingProgram?.scope,
    };

    if (route.children) {
      updatedRoute.children = hideRoutesNotInProgramList(
        route.children,
        programList
      );
    }

    return updatedRoute;
  });
}

export const findPathByProgramId = (
  routes: RouteInfo[],
  programId: string,
  parentPath: string
): string | null => {
  for (let route of routes) {
    const currentPath = route.path ? `${parentPath}/${route.path}` : parentPath;
    if (route.programId === programId) {
      return route.index && !route.path && parentPath
        ? parentPath
        : currentPath;
    }

    if (route.children) {
      const childPath = findPathByProgramId(
        route.children,
        programId,
        currentPath
      );
      if (childPath) {
        return childPath;
      }
    }
  }

  return null;
};

export const routes: RouteInfo[] = [
  {
    path: "",
    element: <Layout />,
    title: "HOME",
    children: [
      {
        index: true,
        element: <div>Home</div>,
        title: "Home",
        icon: <HomeMenuIcon />,
      },
      {
        path: "building",
        element: <Outlet />,
        title: "건물 관리",
        programId: "PGM10000",
        icon: <BuildingMenuIcon />,
        children: [
          {
            index: true,
            element: <BuildingList />,
            title: "건물 통합 관리",
            programId: "PGM10100",
          },
          {
            path: "building-company",
            element: <BuildingCompanyList />,
            title: "건물 입주사 관리",
            programId: "PGM10110",
          },
          {
            path: "building-store",
            element: <StoreList />,
            title: "상점 관리",
            programId: "PGM10200",
          },
          {
            path: "space-list",
            element: <SpaceList />,
            title: "예약 공간 정보 관리",
            programId: "PGM10300",
          },
          { path: "*", element: <Redirect /> },
        ],
      },
      {
        path: "company",
        element: <Outlet />,
        title: "회사 관리",
        programId: "PGM20000",
        icon: <CompanyMenuIcon />,
        children: [
          {
            path: "company-manage",
            element: <CompanyList />,
            title: "회사 관리",
            programId: "PGM20100",
          },
          { path: "*", element: <Redirect /> },
        ],
      },
      {
        path: "user",
        element: <Outlet />,
        title: "사용자 관리",
        programId: "PGM30000",
        icon: <MemberMenuIcon />,
        children: [
          {
            path: "user-list",
            element: <MemberList />,
            title: "회원 관리",
            programId: "PGM30100",
          },
          {
            path: "manager-list",
            element: <ManagerList />,
            title: "운영자 관리",
            programId: "PGM30200",
          },
          { path: "*", element: <Redirect /> },
        ],
      },
      {
        path: "board",
        element: <Outlet />,
        title: "게시판 관리",
        programId: "PGM40000",
        icon: <BoardMenuIcon />,
        children: [
          {
            path: "posts",
            element: <PostList />,
            title: "게시판 관리",
            programId: "PGM40100",
          },
          {
            path: "reported-posts",
            element: <ReportList />,
            title: "신고 게시글 관리",
            programId: "PGM40200",
          },
          { path: "*", element: <Redirect /> },
        ],
      },
      {
        path: "service",
        element: <Outlet />,
        title: "서비스 관리",
        programId: "PGM50000",
        icon: <ServiceMenuIcon />,
        children: [
          {
            path: "voc-list",
            element: <IncommodityList />,
            title: "신고 센터 관리",
            programId: "PGM50100",
          },
          {
            path: "space-list",
            element: <SpaceReservation />,
            title: "공간 예약 관리",
            programId: "PGM50200",
          },
          {
            path: "group-list",
            element: <ServiceGroupList />,
            title: "서비스 그룹 관리",
            programId: "PGM50300",
          },
          {
            path: "template-list",
            element: <TemplateList />,
            title: "템플릿 서비스 관리",
            programId: "PGM50400",
          },
          {
            path: "apply-service",
            element: <ApplyService />,
            title: "신청 서비스 관리",
            programId: "PGM50500",
          },
          {
            path: "goods-request-item",
            element: <GoodsRequestItemList />,
            title: "신청 물품 관리",
            programId: "PGM50510",
          },
          {
            path: "goods-rental",
            element: <GoodsRentalList />,
            title: "대여 이용 관리",
            programId: "PGM50600",
          },
          {
            path: "goods-rental-item",
            element: <GoodsRentalItemList />,
            title: "대여 물품 관리",
            programId: "PGM50610",
          },
          {
            path: "goods-rental-stock",
            element: <GoodsRentalStockList />,
            title: "대여 물품 연결 관리",
            programId: "PGM50620",
          },
          {
            path: "goods-history-list",
            element: <GoodsRentalStockHistoryList />,
            title: "대여 물품 재고 변경 이력",
            programId: "PGM50630",
          },
          { path: "*", element: <Redirect /> },
          // { title: "공간예약이용내역" },
          // { title: "서비스신청관리(사무용품)" },
          // { title: "서비스신청관리(택배/퀵)" },
          // { title: "서비스신청관리(무인택배)" },
          // { title: "서비스신청관리(케이터링)" },
          // { title: "화장실사용현황조회" },
          // { title: "우산빌려주세요관리(m)" },
          // { title: "물품빌려주세요(m)" },
          // { title: "화장실모니터링(m)" },
          // { title: "우산/물품이용내역" },
          // { title: "공기질모니터링(m)" },
          // { title: "비상대피관리(m)" },
        ],
      },
      {
        path: "system",
        element: <Outlet />,
        title: "시스템 관리",
        programId: "PGM60000",
        icon: <SystemMenuIcon />,
        children: [
          // {
          //   path: "holiday-list",
          //   element: <>공휴일관리</>,
          //   title: "공휴일관리(삭제예정)",
          // },
          {
            path: "batch-list",
            element: <BatchList />,
            title: "배치 관리",
            programId: "PGM60100",
            renderRight: <BatchListRightInfo />,
          },
          {
            path: "logging-login-list",
            element: <LoggingLoginList />,
            title: "로그인 이력",
            programId: "PGM60200",
          },
          {
            path: "logging-permission-list",
            element: <LoggingPermissionList />,
            title: "권한 부여 이력",
            programId: "PGM60300",
          },
          { path: "*", element: <Redirect /> },
        ],
      },
      {
        path: "management",
        element: <Outlet />,
        title: "운영 관리",
        programId: "PGM70000",
        icon: <ManagementMenuIcon />,
        children: [
          {
            path: "banner-list",
            element: <BannerList />,
            title: "배너 관리",
            programId: "PGM70100",
          },
          {
            path: "version-list",
            element: <AppVersionList />,
            title: "앱버전 관리",
            programId: "PGM70200",
          },
          {
            path: "terms-list",
            element: <TermsList />,
            title: "약관 관리",
            programId: "PGM70300",
          },
          {
            path: "member-access-log",
            element: <MaskingList />,
            title: "회원 정보 접근 이력 관리",
            programId: "PGM70600",
          },
          {
            path: "coordinates-access-log",
            element: <GeoLocationList />,
            title: "위치 정보 수집 이력 관리",
            programId: "PGM70700",
          },
          {
            path: "total-access-log",
            element: <AccessList />,
            title: "화면 접근 이력 관리",
            programId: "PGM70800",
          },
          { path: "*", element: <Redirect /> },
        ],
      },
      {
        path: "iotDevice",
        element: <Outlet />,
        title: "Iot 장치 관리",
        programId: "PGM80000",
        icon: <IotMenuIcon />,
        children: [
          {
            path: "temp-list",
            element: <TempHumList />,
            title: "온습도공기 공간 관리",
            programId: "PGM80100",
          },
          {
            path: "toilet-list",
            element: <ToiletList />,
            title: "화장실 공간 관리",
            programId: "PGM80200",
          },
          {
            path: "temp-tag-list",
            element: <TempHumTagList />,
            title: "온습도공기 TAG 관리",
            programId: "PGM80300",
          },
          {
            path: "toilet-tag-list",
            element: <ToiletTagList />,
            title: "화장실 TAG 관리",
            programId: "PGM80400",
          },
          {
            path: "iot-status",
            element: <IotStatus />,
            title: "IoT 장치 현황",
            programId: "PGM80500",
          },
          { path: "*", element: <Redirect /> },
        ],
      },
      {
        path: "emergency",
        element: <Outlet />,
        title: "비상대피 관리",
        programId: "PGM90000",
        icon: <EmergencyMenuIcon />,
        children: [
          {
            path: "emergency-building-group-list",
            element: <EmergencyBuildingGroupList />,
            title: "비상대피 건물 그룹 관리",
            programId: "PGM90100",
          },
          {
            path: "shelter-list",
            element: <ShelterList />,
            title: "비상대피 영역 관리",
            programId: "PGM90200",
          },
          {
            path: "evacuee-list",
            title: "비상대피 참여 인원 관리",
            element: <EvacueeList />,
            programId: "PGM90300",
          },
          {
            path: "situation-list",
            title: "비상대피 상황 관리",
            element: <SituationList />,
            programId: "PGM90400",
          },
          {
            path: "status-history-list",
            title: "상태변경 이력 관리",
            element: <StatusHistoryList />,
            programId: "PGM90400",
          },
          {
            path: ":id",
            element: <Outlet />,
            children: [
              {
                path: "dashboard",
                element: <Dashboard />,
              },
            ],
          },
          { path: "*", element: <Redirect /> },
          {
            path: "excel",
            element: <ExcelConverter />,
          },
        ],
      },
      { path: "*", element: <Redirect /> },
    ],
  },
  { path: "login", element: <Login /> },
  { path: "actuator", element: <div>status:ok</div> },
  { path: "maintenance", element: <Maintenance /> },
  { path: "referer", element: <Referer /> },
  { path: "notFound", element: <NotFound /> },
  // Dashboard 최상위로 올려서 side메뉴 안보이게 설정
  {
    path: ":id",
    element: <DashboardLayout />,
    children: [
      {
        path: "dashboard",
        element: <Dashboard />,
      },
    ],
  },
];

export const getInitRoutes = () => {
  return routes.map((route) => ({ ...route, children: [] }));
};

export const getSideRoute = (routes: RouteInfo[]) => {
  return routes?.[0].children?.filter((route) => route.path !== "*") ?? [];
};

export const getRouteTitles = (routes: RouteInfo[], pathnames: string[]) => {
  let route: RouteInfo[] = routes ?? [];
  const indexPath: string[] = [];

  const matchedPath = pathnames.map((pathname, index, array) => {
    const currentRoute: RouteInfo | undefined = route.find(
      (route) => route.path === pathname
    );
    if (currentRoute?.children) {
      route = currentRoute.children;
    } else {
      route = [];
    }

    if (array.length - 1 === index && currentRoute?.children) {
      const indexRoute = currentRoute.children.find(
        (route) => route.index === true
      );
      if (indexRoute && indexRoute.title) {
        indexPath.push(indexRoute.title);
      }
    }

    return currentRoute?.title ?? "";
  });

  return [...matchedPath, ...indexPath];
};

export const findRouteByPathname = (
  routes: RouteInfo[],
  pathname: string
): RouteInfo | undefined => {
  for (const route of routes) {
    if (route.path === pathname) {
      return route;
    }

    if (route.children) {
      const childRoute = findRouteByPathname(route.children, pathname);
      if (childRoute) {
        return childRoute;
      }
    }
  }

  return undefined;
};
