import { HttpStatusCode } from "axios";
import { useEffect, useState } from "react";
import DefaultButton from "../../../components/DefaultButton";
import ListPage from "../../../components/ListPage";
import Spinner from "../../../components/Spinner";
import { TableColumnType } from "../../../components/Table";
import {
  transformCategoryData,
  transformComboBox,
} from "../../../libs/category";
import {
  getCategoryList,
  getReportCategoryList,
  getReportList,
  getReportListExcel,
  patchReportHide,
  patchReportShow,
} from "../../../services/pinelifeService";
import { CategoryData, CategoryReturnField } from "../../../types/category";
import { PostContentType, Report } from "../../../types/post";
import { ComboBoxType, ComponentType, DASH } from "../../../types/search";
import {
  addDefaultOption,
  findComboBoxItem,
} from "../../../utils/comboBoxUtils";
import {
  CATEGORY_LEVEL_LABELS,
  DEFAULT_SELECT_ALL_VALUE,
} from "../../../types/comboBoxOption";
import { useModal } from "../../../contexts/Modal";
import { buildingGroupCategoryCodeValue } from "../../../types/building";

type Props = {};

export default function ReportList(props: Props) {
  const { showAlert, showConfirm, handleError } = useModal();
  const [lifeCategory, setLifeCategory] = useState<CategoryData[]>([]);
  const [reportCategory, setReportCategory] = useState<ComboBoxType[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const columnInfo: TableColumnType<Report>[] = [
    {
      header: "구분",
      name: "type",
      render: (item: Report) => (item.type === "post" ? "게시글" : "댓글"),
      width: "70",
    },
    { header: "신고된 ID", name: "reportedId" },
    { header: "대분류", name: "categoryNameL" },
    { header: "중분류", name: "categoryNameM" },
    { header: "소분류", name: "categoryNameS" },
    {
      header: "신고유형",
      render: (item: Report) =>
        findComboBoxItem(reportCategory, item.reportCategory)?.label ?? DASH,
    },
    { header: "제목", name: "reportedTitle" },
    {
      header: "작성자",
      render: (item: Report) => (
        <>
          <span>
            {`${item.reportedNickname}`}
            <br />
            {item.reportedEmail && `(${item.reportedEmail})`}
          </span>
        </>
      ),
    },
    {
      header: "신고자",
      render: (item: Report) => (
        <>
          <span>
            {`${item.createdNickname}`}
            <br />
            {item.createdEmail && `(${item.createdEmail})`}
          </span>
        </>
      ),
    },
    {
      header: "글 작성일시",
      name: "reportedAt",
      sortable: true,
      width: "date",
    },
    { header: "신고일시", name: "createdAt", sortable: true, width: "date" },
    {
      header: "숨김여부",
      render: (item: Report) => (
        <div className="w-full h-full flex gap-2 justify-center items-center">
          <DefaultButton
            size="tableIcon"
            color={item.hidden ? "gray" : "blue"}
            onClick={() =>
              handleHidden(
                item.type === "comment" ? "COMMENT" : "POST",
                item.reportedId,
                !item.hidden
              )
            }
            className="px-1.5 py-px min-w-[60px]"
          >
            {item.hidden ? "비노출" : "노출중"}
          </DefaultButton>
        </div>
      ),
      width: "button",
    },
  ];

  const handleHidden = (
    type: PostContentType,
    reportedId: number,
    hidden: boolean
  ) => {
    const question = hidden
      ? "글을 비노출 처리 하시겠습니까?"
      : "글을 노출 처리 하시겠습니까?";
    showConfirm(question, async () => {
      try {
        setIsLoading(true);
        const response = hidden
          ? await patchReportHide(type, reportedId)
          : await patchReportShow(type, reportedId);

        if (response.status === HttpStatusCode.Ok) {
          showAlert("처리 되었습니다.");
        }
      } catch (err: any) {
        handleError(err, "처리");
      } finally {
        setIsLoading(false);
      }
    });
  };

  useEffect(() => {
    const fetch = async () => {
      try {
        setIsLoading(true);
        const [lifeCategoryResponse, reportCategoryResponse] =
          await Promise.all([getCategoryList(), getReportCategoryList()]);

        if (lifeCategoryResponse.data) {
          setLifeCategory(
            transformCategoryData(
              lifeCategoryResponse.data,
              "lifeCategoryId",
              "alias",
              "name",
              "lifeCategoryList",
              undefined,
              undefined
            )
          );
        } else {
          throw new Error(
            "게시글 분류 정보를 불러오는 중 오류가 발생했습니다."
          );
        }

        if (reportCategoryResponse.data) {
          setReportCategory(transformComboBox(reportCategoryResponse.data));
        } else {
          throw new Error("신고 유형 정보를 불러오는 중 오류가 발생했습니다.");
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetch();
  }, []);

  const comboBoxReportType: ComboBoxType[] = [
    { value: DEFAULT_SELECT_ALL_VALUE, label: "전체" },
    { value: "post", label: "게시글" },
    { value: "comment", label: "댓글" },
  ];

  const comboBoxReportVisible: ComboBoxType[] = [
    { value: DEFAULT_SELECT_ALL_VALUE, label: "전체" },
    { value: "true", label: "노출" },
    { value: "false", label: "노출 안함" },
  ];

  const componentList: ComponentType<Report>[] = [
    {
      labelName: "신고일자",
      typeName: "dateRange",
      keyName: "createdAt",
      category: "date",
    },
    {
      labelName: "게시글 분류",
      typeName: "categoryComboBox",
      keyName: "category",
      category: "typeSearch",
      categoryData: {
        searchField: CategoryReturnField.ALIAS,
        optionData: lifeCategory,
        allOptionLabels: CATEGORY_LEVEL_LABELS,
      },
    },
    {
      labelName: "글 구분",
      typeName: "comboBox",
      keyName: "type",
      category: "typeSearch",
      comboBoxOptions: comboBoxReportType,
    },
    {
      labelName: "신고 유형",
      typeName: "comboBox",
      keyName: "reportCategory",
      category: "typeSearch",
      comboBoxOptions: addDefaultOption(reportCategory),
    },
    {
      labelName: "노출 여부",
      typeName: "comboBox",
      keyName: "isHidden",
      category: "typeSearch",
      comboBoxOptions: comboBoxReportVisible,
    },
    {
      labelName: "포함검색",
      typeName: "tagFilter",
      keyName: "tagFilter",
      category: "typeSearch",
      buildingGroupCategoryCode: buildingGroupCategoryCodeValue.POST,
    },
    {
      labelName: "글 ID",
      typeName: "text",
      keyName: "lifeReportId",
      category: "detailSearch",
      placeholder: "글 ID를 입력해주세요",
    },
    {
      labelName: "댓글 ID",
      typeName: "text",
      keyName: "lifeCommentId",
      category: "detailSearch",
      placeholder: "댓글 ID를 입력해주세요",
    },
    {
      labelName: "작성자 Email",
      typeName: "text",
      keyName: "reportedEmail",
      category: "detailSearch",
      placeholder: "작성자 Email를 입력해주세요",
    },
    {
      labelName: "작성자 별명",
      typeName: "text",
      keyName: "reportedNickname",
      category: "detailSearch",
      placeholder: "작성자 별명을 입력해주세요",
    },
    {
      labelName: "신고자 Email",
      typeName: "text",
      keyName: "createdEmail",
      category: "detailSearch",
      placeholder: "신고자 Email을 입력해주세요",
    },
    {
      labelName: "신고자 별명",
      typeName: "text",
      keyName: "createdNickname",
      category: "detailSearch",
      placeholder: "신고자 별명을 입력해주세요",
    },
  ];
  return (
    <>
      {!isLoading ? (
        <ListPage
          getDataApi={getReportList}
          excelDownloadApi={getReportListExcel}
          keyId={(item: Report) => item.lifeReportId}
          columnInfo={columnInfo}
          componentList={componentList}
          tableTitle="신고 목록"
          needExcelDownloadReason
        />
      ) : (
        <Spinner />
      )}
    </>
  );
}
