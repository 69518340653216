import { useEffect, useState } from "react";
import {
  getBuildingService,
  getBuildingServiceList,
  patchBuildingService,
  postBuildingService,
  putBuildingService,
} from "../../../../services/buildingService";
import { ServiceList } from "../../../../types/service";
import ServiceForm from "../../../ServiceManagement/ServiceForm";
import Spinner from "../../../Spinner";
import { useModal } from "../../../../contexts/Modal";

type Props = { buildingId: string };

export default function BuildingServiceForm({ buildingId }: Props) {
  const { handleError } = useModal();
  const [data, setData] = useState<Partial<ServiceList>>({});
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    try {
      setIsLoading(true);

      const response = await getBuildingServiceList(buildingId);

      if (response.data) {
        setData(response.data);
      }
    } catch (err: any) {
      handleError(err, "서비스 목록 조회");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {isLoading && <Spinner />}
      <ServiceForm
        id={buildingId}
        serviceType="BUILDING"
        data={Object.assign(
          data?.vocList ? { 불편신고: data?.vocList } : {},
          data?.otherList
        )}
        templateData={data?.templateList ?? []}
        refreshService={fetchData}
        patchService={patchBuildingService}
        getService={getBuildingService}
        createService={postBuildingService}
        updateService={putBuildingService}
      />
    </>
  );
}
