import React, { useCallback, useEffect, useState } from "react";
import clsx from "clsx";

import {
  ComponentCategory,
  ComponentCategoryMap,
  ComponentType,
  DataLoadedComponentEnum,
  InitialDataLoadedType,
  SearchDateRange,
  SearchDateRangeRadio,
} from "../../types/search";
import { DEFAULT_SELECT_ALL_VALUE } from "../../types/comboBoxOption";

import { validateAndSetSearchConditions } from "../../libs/validations";

import { useModal } from "../../contexts/Modal";

import DateRange from "../DateRange";
import DefaultButton from "../DefaultButton";
import DefaultInput from "../Input/DefaultInput";
import { SearchCondition } from "../ListPage";
import CategorySelect from "../SelectBox/CategorySelect";
import DefaultSelect from "../SelectBox/DefaultSelect";
import TagFilter, { SearchTagData } from "../TagFilter";
import DateRangeRadio from "../DateRange/DateRangeRadio";
import { BuildingAndFloor } from "../SelectBox/BuildingAndFloorSelect";
import IncommodityCategorySelect from "../Incommodity/CategorySelect";
import BuildingAndFloorSelect from "../SelectBox/BuildingAndFloorSelect";
import DefaultDateRange from "../DateRange/DefaultDateRange";
import DateTimeInput from "../DateTime/DateTimeInput";
import SearchAndSelect from "../SelectBox/SearchAndSelect";

type Props<T> = {
  initSearchCondition?: SearchCondition<T>;
  addSearchCondition?: SearchCondition<T>;
  setSearchCondition?: React.Dispatch<React.SetStateAction<SearchCondition<T>>>;
  componentList?: ComponentType<T>[];
  handleInitialDataLoadComplete?: () => void;
  reset?: boolean;
  isSmallButton?: boolean;
  onChange?: (search: SearchCondition<T>) => void;
};

export default function SearchBox<T>({
  onChange,
  initSearchCondition = {},
  addSearchCondition = {},
  setSearchCondition,
  componentList = [],
  handleInitialDataLoadComplete,
  reset = false,
  isSmallButton = false,
}: Props<T>): JSX.Element {
  const { showAlert } = useModal();
  const [search, setSearch] = useState<SearchCondition<T>>(
    initSearchCondition ?? {}
  );
  const [initialDataLoaded, setInitialDataLoaded] =
    useState<InitialDataLoadedType>({
      ...Object.values(DataLoadedComponentEnum).reduce((acc, type) => {
        if (componentList.some((component) => component.typeName === type)) {
          return { ...acc, [type]: false };
        }
        return acc;
      }, {} as InitialDataLoadedType),
      searchCondition: false,
    });

  const onInitialDataLoaded = (componentKey: DataLoadedComponentEnum) => {
    setInitialDataLoaded((prev) => {
      return { ...prev, [componentKey]: true };
    });
  };

  const filteredComponents = (
    componentList: ComponentType<T>[],
    category: ComponentCategory | undefined
  ) => {
    return componentList.filter((component) => component.category === category);
  };

  const categoryComponents = (
    category: ComponentCategory | undefined,
    index: number
  ) => {
    const components = filteredComponents(componentList, category);

    const label = category
      ? category === "date" && components.length > 0 && components[0].labelName
        ? components[0].labelName
        : ComponentCategoryMap[category]
      : undefined;

    if (components.length === 0) return null;

    return (
      <div
        key={index}
        className="flex flex-row items-center px-2.5 py-[14px] gap-1.5"
      >
        {label && <span className="min-w-label text-sm">{label}</span>}
        <div className="flex flex-1 flex-wrap gap-1.5">
          {components.map((component, index) => (
            <div key={index} className="flex flex-row items-center gap-1.5">
              {getComponent(component, onChange)}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderComponents = () => {
    const categories = Object.keys(ComponentCategoryMap) as ComponentCategory[];
    const validComponents = [
      ...categories.map((category, index) =>
        categoryComponents(category, index)
      ),
      categoryComponents(undefined, categories.length),
    ].filter((c) => c);

    const components = validComponents.map((component, index) => (
      <div
        key={index}
        className={clsx({
          "border-b": index !== validComponents.length - 1,
        })}
      >
        {component}
      </div>
    ));

    return components;
  };

  const getComponent = (
    component: ComponentType<T>,
    onChangeSearch?: (data: SearchCondition<T>) => void
  ) => {
    const {
      typeName,
      keyName,
      placeholder,
      comboBoxOptions,
      incommodityCategory,
      labelName,
      categoryData,
      showAllOption,
      buildingGroupCategoryCode,
      tagFilter,
      dateRangeType,
    } = component;
    const value = search[keyName];

    const onChange = (
      newValue:
        | string
        | SearchTagData
        | SearchDateRange
        | number[]
        | SearchDateRangeRadio
        | BuildingAndFloor
        | string[]
    ) => {
      setSearch((prev) => ({ ...prev, [keyName]: newValue }));
      onChangeSearch?.({
        ...search,
        [keyName]: newValue,
      });
    };

    switch (typeName) {
      case "number":
      case "text":
        return (
          <DefaultInput
            type={typeName}
            value={value}
            onChange={onChange}
            label={labelName}
            placeholder={placeholder}
          />
        );
      case "comboBox":
        return (
          <DefaultSelect
            value={value}
            onChange={onChange}
            optionList={comboBoxOptions}
            label={labelName}
            placeholder={placeholder}
            showAllOption={showAllOption}
          />
        );
      case "searchComboBox":
        return (
          <SearchAndSelect
            value={value}
            onChange={onChange}
            optionList={comboBoxOptions}
            label={labelName}
            placeholder={placeholder}
          />
        );
      case "date":
        return <DateTimeInput date={value} onDateTimeChange={onChange} />;
      case "dateRange":
        return (
          <DateRange
            value={value}
            onChange={onChange}
            initButtonType={dateRangeType}
          />
        );
      case "defaultDateRange":
        return (
          <DefaultDateRange
            value={value}
            onChange={onChange}
            labelName={labelName}
          />
        );
      case "dateRangeRadio":
        return (
          <DateRangeRadio
            value={value}
            onChange={onChange}
            options={comboBoxOptions}
          />
        );
      case "tagFilter":
        return (
          <TagFilter
            tagFilterType={tagFilter?.tagFilterType}
            tags={value}
            searchType={tagFilter?.tagFilterSearchType}
            setTags={onChange}
            label={labelName}
            categoryCode={buildingGroupCategoryCode}
          />
        );
      case "categoryComboBox":
        return (
          <CategorySelect
            value={value}
            onChange={onChange}
            categoryData={categoryData?.optionData}
            allOptionLabels={categoryData?.allOptionLabels}
            defaultOptions={categoryData?.defaultOptions}
            label={labelName}
          />
        );
      case "buildingAndFloor":
        return (
          <BuildingAndFloorSelect
            value={value}
            onChange={onChange}
            onInitialDataLoaded={onInitialDataLoaded}
            showAllOption={showAllOption}
            categoryCode={buildingGroupCategoryCode}
          />
        );
      case "incommodityCategory":
        return (
          <IncommodityCategorySelect
            value={value}
            data={incommodityCategory?.data ?? {}}
            onChange={onChange}
            label={labelName}
            allOptionLabels={incommodityCategory?.allOptionLabels}
          />
        );
      default:
        return null;
    }
  };

  const handleReset = () => {
    setSearch(initSearchCondition ?? {});
  };

  const isValid = () => {
    for (const component of componentList) {
      const value = search[component.keyName];
      const validations = component.validation ?? [];
      const name =
        component.labelName ??
        (component.category && ComponentCategoryMap[component.category]);
      const invalidValidation = validations.find(
        (validation) => !validation(value, name).isValid
      );
      if (invalidValidation) {
        showAlert(invalidValidation(value, name).errorMessage);
        return false;
      }
    }
    return true;
  };

  const addValidValue = (
    key: string,
    value: string | undefined,
    target: any
  ) => {
    if (value && value !== DEFAULT_SELECT_ALL_VALUE) {
      target[key] = value;
    }
  };

  const handleSearch = useCallback(
    (addSearch: SearchCondition<T> = {}) => {
      const searchParams: SearchCondition<T> = { ...search, ...addSearch };
      let newData = validateAndSetSearchConditions(
        searchParams,
        componentList,
        showAlert,
        addValidValue
      );

      if (newData === null) {
        return;
      }

      if (setSearchCondition) {
        setSearchCondition((prev) => {
          const { pageSize } = prev;
          return { page: 1, pageSize, ...newData } as SearchCondition<T>;
        });
      }
    },
    [componentList, search]
  );

  useEffect(() => {
    if (Object.keys(initSearchCondition).length > 0) {
      handleSearch();
    }
  }, [initSearchCondition]);

  useEffect(() => {
    if (Object.keys(addSearchCondition).length > 0) {
      setSearch((prev) => ({ ...prev, ...addSearchCondition }));
      handleSearch({ ...search, ...addSearchCondition });
    }
  }, [addSearchCondition]);

  useEffect(() => {
    if (
      initialDataLoaded[DataLoadedComponentEnum.SearchCondition] === false &&
      onInitialDataLoaded
    ) {
      onInitialDataLoaded(DataLoadedComponentEnum.SearchCondition);
    }

    if (setSearchCondition && initialDataLoaded) {
      if (
        Object.values(initialDataLoaded).every((value) => value) &&
        handleInitialDataLoadComplete
      ) {
        handleInitialDataLoadComplete();
        handleSearch();
      }
    }
  }, [initialDataLoaded]);

  useEffect(() => {
    if (reset) {
      setSearch(initSearchCondition ?? {});
    }
  }, [reset]);

  const getButtonClassName = clsx(
    "!text-white !bg-brand-primary-gray-100 !bg-opacity-40 min-w-[100px] rounded-none text-sm font-semibold",
    { "h-[38px]": isSmallButton },
    { "h-[100px]": !isSmallButton }
  );

  return (
    <>
      {componentList.length > 0 && (
        <div className="flex gap-5 py-2.5 px-5 bg-white rounded-[3px]">
          <div className="flex flex-col w-full">{renderComponents()}</div>
          <div className="flex flex-col justify-center min-w-fit items-end gap-2">
            <DefaultButton
              onClick={() => {
                if (isValid() === false) {
                  return;
                }
                handleSearch();
              }}
              className={getButtonClassName}
              testId="search"
            >
              조회
            </DefaultButton>
            <DefaultButton
              onClick={handleReset}
              className="!bg-brand-primary-gray-100 !bg-opacity-10 h-[38px] min-w-[100px] rounded-none text-sm font-semibold !text-brand-text-black-disabled"
            >
              초기화
            </DefaultButton>
          </div>
        </div>
      )}
    </>
  );
}
