import React, { useContext, useEffect, useState } from "react";
import { PopupContext, PopupHandler } from "../../contexts/Popup";
import { SearchCondition } from "../ListPage";
import SortButton from "./SortButton";
import { Pageable } from "../../types/pageable";
import { DASH } from "../../types/search";
import ExcelDownloadButton from "../Excel/ExcelDownloadButton";
import clsx from "clsx";
import WarningCircleIcon from "../../svgs/icons/Common/WarningCircle";
import { PostCategory } from "@/types/post";
import DefaultInput from "../Input/DefaultInput";
import DefaultButton from "../DefaultButton";
import { useModal } from "@/contexts/Modal";
import { buildingSearchType } from "../Board/PostList/CreateForm";
import {
  ServiceGroupingType,
  ServiceGroupingForMulti,
  SmallBuildingType,
} from "@/types/building";

const EMPTY_DATA_TEXT = "데이터가 없습니다.";

export interface TableColumnType<T> {
  header: string;
  name?: keyof T;
  render?: (
    item: T,
    handler: { popup: PopupHandler },
    rowIndex: number
  ) => React.ReactNode;
  sortable?: boolean;
  headerRender?: (data: T[]) => React.ReactNode;
  defaultNo?: boolean | { ascending: boolean };
  width?: ColumnWidth;
}

type ColumnWidth =
  | "no"
  | "date"
  | "id"
  | "tel"
  | "button"
  | "icon"
  | "number"
  | "yesOrNo"
  | "name"
  | "70"
  | "100"
  | "150"
  | "200";

const columnWidthMap: Record<ColumnWidth, string> = {
  no: "w-[90px]",
  date: "w-[100px]",
  id: "w-[110px]",
  tel: "w-[130px]",
  button: "w-[100px]",
  icon: "w-[85px]",
  number: "w-[100px]",
  yesOrNo: "w-[90px]",
  name: "w-[150px]",
  "70": "w-[70px]",
  "100": "w-[100px]",
  "150": "w-[150px]",
  "200": "w-[200px]",
};

export type TableHeight = "small" | "default" | "large";

const TableHeightMap = {
  small: "h-48",
  default: "h-96",
  large: "h-[446px]",
};

interface Props<T> {
  searchTerm?: string;
  data: T[];
  columnInfo: TableColumnType<T>[];
  keyId?: (item: T) => string | number;
  tableTitle?: string;
  searchCondition?: SearchCondition<T>;
  setSearchCondition?: React.Dispatch<React.SetStateAction<SearchCondition<T>>>;
  renderTopRight?: React.ReactNode;
  defaultPageSizeList?: number[];
  excelDownloadApi?: (searchCondition: SearchCondition<T>) => Promise<void>;
  excelUpload?: React.ReactNode;
  total?: number;
  listInfo?: React.ReactNode;
  hidePageSize?: boolean;
  onlyRenderTable?: boolean;
  pageable?: Pageable;
  isTableScroll?: boolean;
  tableHeight?: TableHeight;
  renderTableSearchBox?: React.ReactNode;
  defaultContentText?: string;
  needExcelDownloadReason?: boolean;
  postCategory?: PostCategory;
  setBuildingSearch?: React.Dispatch<React.SetStateAction<buildingSearchType>>;
  selectedMode?: boolean;
  selectedDataForMulti?: ServiceGroupingForMulti;
  handleRemoveForMulti?: (item: SmallBuildingType) => void;
  warningText?: string;
}

export default function Table<T>({
  tableTitle,
  data,
  columnInfo,
  keyId,
  searchCondition,
  setSearchCondition,
  renderTopRight,
  defaultPageSizeList,
  excelDownloadApi,
  excelUpload,
  total,
  listInfo,
  pageable,
  needExcelDownloadReason = false,
  hidePageSize = false,
  onlyRenderTable = false,
  isTableScroll = false,
  tableHeight = "default",
  renderTableSearchBox,
  defaultContentText = EMPTY_DATA_TEXT,
  postCategory,
  selectedMode = false,
  setBuildingSearch,
  selectedDataForMulti = {},
  handleRemoveForMulti,
  warningText,
}: Props<T>) {
  const { openPopup } = useContext(PopupContext);
  const { showAlert } = useModal();
  const [groupName, setGroupName] = useState<string>(""); // -> groupName
  const [buildingName, setBuildingName] = useState<string>(""); // -> buildingName
  const [expandedRows, setExpandedRows] = useState<string[]>([]);
  const [tableHeightState, setTableHeightState] =
    useState<TableHeight>(tableHeight);
  const renderTableHeader = postCategory
    ? postCategory === "multi"
      ? !selectedMode
        ? true
        : false
      : true
    : true;

  useEffect(() => {
    // multi 에서 오른쪽 부분에 해당함. 구조적인 문제 때문에 따로 높이를 맞춰주기 위함
    if (!renderTableHeader) {
      setTableHeightState("large");
    }
  }, [renderTableHeader]);

  const handleExpandRow = (id: string) => {
    if (expandedRows.includes(id)) {
      setExpandedRows(expandedRows.filter((rowId) => rowId !== id));
    } else {
      setExpandedRows([...expandedRows, id]);
    }
  };

  const getRowNumberString = (index: number, ascending: boolean = false) => {
    if (total && pageable) {
      const result = ascending
        ? index + 1 + (pageable.page - 1) * pageable.pageSize
        : total - (pageable.page - 1) * pageable.pageSize - index;
      return result.toLocaleString();
    }
    return null;
  };

  const getTdClassName = (columnWidth?: ColumnWidth) =>
    clsx(
      "border border-gray-300 border-l-0 border-r-0 min-h-[70px] text-sm py-5 max-w-[200px] break-words",
      columnWidth && columnWidthMap[columnWidth]
    );

  const renderHeader = (header: string) => {
    const lines = header.split("\n");
    return (
      <>
        {lines.map((line, index) => (
          <React.Fragment key={index}>
            {line}
            <br />
          </React.Fragment>
        ))}
      </>
    );
  };

  const handleClickSearchBuilding = () => {
    if (groupName === "" && buildingName === "")
      showAlert("서비스 그룹명 또는 건물명을 입력해주세요");

    if (setSearchCondition) {
      setSearchCondition((prev) => ({
        ...prev,
        groupName: groupName ?? null,
        buildingName: buildingName ?? null,
      }));
    }
    if (setBuildingSearch) {
      setBuildingSearch({
        groupName: groupName,
        buildingName: buildingName,
      });
    }
  };

  const handleClickRemoveAll = (item: ServiceGroupingType) => {
    const groupInfo = {
      groupId: item.groupId,
      groupName: item.groupName,
    };
    if (handleRemoveForMulti) {
      item.buildingList.forEach((building) => {
        const removeParam = {
          ...groupInfo,
          buildingId: building.buildingId,
          buildingName: building.buildingName,
        } as SmallBuildingType;

        handleRemoveForMulti(removeParam);
      });
    }
  };

  const renderExtendedRow = (item: ServiceGroupingType) => (
    <React.Fragment key={item.groupId}>
      <tr onClick={() => handleExpandRow(item.groupId)}>
        <td
          className={clsx(
            getTdClassName("200"),
            "font-bold text-left pl-6 cursor-pointer"
          )}
        >
          {item.groupName ?? "-"}
        </td>
        <td className={clsx(getTdClassName("150"), "cursor-pointer")}>
          <button
            className="text-right w-full pr-2"
            data-testid={`${item.groupId}-extend-button`}
          >
            {expandedRows.includes(item.groupId) ? "▼" : "►"}
          </button>
        </td>
        <td className={clsx(getTdClassName("button"))}>
          <div className="w-full h-3 flex gap-2 justify-center items-center">
            <DefaultButton
              color="white"
              className="h-[22px] text-xs font-normal"
              onClick={(e) => {
                e.stopPropagation();
                handleClickRemoveAll(item);
              }}
            >
              전체 해제
            </DefaultButton>
          </div>
        </td>
      </tr>
      {expandedRows.includes(item.groupId) && item.buildingList.length > 0 && (
        <tr className="min-h-[70px]">
          <td colSpan={3}>
            <table className="w-full">
              <tbody className="w-full">
                {item.buildingList.map((building, index) => (
                  <tr key={index} className="w-full">
                    <td className="w-full flex justify-between items-center px-6 py-2">
                      <span className="text-sm">{building.buildingName}</span>
                      <DefaultButton
                        onClick={
                          handleRemoveForMulti
                            ? () =>
                                handleRemoveForMulti({
                                  ...building,
                                  groupId: item.groupId,
                                  groupName: item.groupName,
                                } as SmallBuildingType)
                            : () => {}
                        }
                        className="mr-2"
                        testId={`${item.groupId}-${building.buildingId}`}
                      >
                        삭제
                      </DefaultButton>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </td>
        </tr>
      )}
    </React.Fragment>
  );

  return (
    <div className="w-full bg-white">
      {!onlyRenderTable && (
        <div className="flex flex-col">
          <div className="px-5 flex justify-between items-center h-[70px] gap-2.5">
            <div className="text-lg font-semibold flex items-center gap-2.5 flex-row flex-wrap">
              {tableTitle}
              <span
                className="rounded-full bg-neutral-85 px-2.5 text-xs text-white font-medium"
                data-testid="total-count-badge"
              >
                {total?.toLocaleString()}
              </span>
              {listInfo}
            </div>
            <div className="flex gap-2 items-center">
              {renderTableSearchBox}
              {renderTopRight}
              {excelUpload}
              {!hidePageSize && (
                <div>
                  <select
                    className="border border-gray-200 px-4 h-[30px] text-brand-text-black-disabled text-label"
                    value={searchCondition?.pageSize}
                    onChange={(e) => {
                      if (setSearchCondition) {
                        setSearchCondition((prev) => ({
                          ...prev,
                          page: 1,
                          pageSize: parseInt(e.target.value),
                        }));
                      }
                    }}
                  >
                    {defaultPageSizeList?.map((value, index) => (
                      <option key={index} value={value}>
                        {value}
                      </option>
                    ))}
                  </select>
                </div>
              )}

              {excelDownloadApi && (
                <ExcelDownloadButton
                  searchCondition={searchCondition}
                  excelDownloadApi={excelDownloadApi}
                  needReason={needExcelDownloadReason}
                />
              )}
            </div>
          </div>
          {warningText && (
            <div className="px-5 pb-2 text-red-500 font-normal text-sm">
              {warningText}
            </div>
          )}
        </div>
      )}
      {postCategory === "multi" && !selectedMode && (
        <div className="flex justify-between p-3">
          <DefaultInput
            value={groupName}
            placeholder="서비스 그룹명을 입력해주세요"
            onChange={setGroupName}
            minWidth="w-[250px]"
          />
          <DefaultInput
            value={buildingName}
            placeholder="건물명을 입력해주세요"
            onChange={setBuildingName}
            minWidth="w-[250px]"
          />
          <div className="flex items-center">
            <DefaultButton
              onClick={() => handleClickSearchBuilding()}
              testId="multi-search"
            >
              조회
            </DefaultButton>
          </div>
        </div>
      )}
      <div
        className={clsx(
          isTableScroll &&
            `${TableHeightMap[tableHeightState]} overflow-y-scroll`
        )}
      >
        <table className="w-full text-center">
          <thead className="bg-white">
            <tr className="h-10 text-xs text-neutral-60">
              {renderTableHeader &&
                columnInfo.map((column, index) => (
                  <th key={index} className="!font-normal">
                    <div className="flex items-center gap-2 justify-center min-w-fit">
                      {column.headerRender
                        ? column.headerRender(data)
                        : renderHeader(column.header)}
                      {column.sortable && column.name && (
                        <SortButton
                          name={column.name as keyof T}
                          searchCondition={searchCondition}
                          setSearchCondition={setSearchCondition}
                        />
                      )}
                    </div>
                  </th>
                ))}
            </tr>
          </thead>
          <tbody>
            {data.length > 0 && renderTableHeader
              ? data.map((item: T, rowIndex) => (
                  <tr
                    key={keyId?.(item) || (item as any).id || rowIndex}
                    className="min-h-[70px]"
                  >
                    {columnInfo.map((column, index) => (
                      <td key={index} className={getTdClassName(column.width)}>
                        {typeof column.defaultNo === "object"
                          ? getRowNumberString(
                              rowIndex,
                              column.defaultNo.ascending
                            )
                          : column.defaultNo
                          ? getRowNumberString(rowIndex)
                          : column.render
                          ? column.render(item, { popup: openPopup }, rowIndex)
                          : column.name
                          ? String(
                              item[column.name] && item[column.name] !== ""
                                ? item[column.name]
                                : DASH
                            )
                          : DASH}
                      </td>
                    ))}
                  </tr>
                ))
              : Object.keys(selectedDataForMulti).length > 0 &&
                !renderTableHeader
              ? Object.entries(selectedDataForMulti).map(([groupId, group]) => (
                  <React.Fragment key={groupId}>
                    {renderExtendedRow({
                      groupId,
                      groupName: group.groupName,
                      buildingList: group.buildingList,
                    } as ServiceGroupingType)}
                  </React.Fragment>
                ))
              : data.length === 0 && (
                  <tr>
                    <td
                      className="text-center py-[30px] text-gray-400"
                      colSpan={columnInfo.length}
                    >
                      <span className="flex flex-col gap-2.5 h-full justify-center items-center">
                        <WarningCircleIcon />
                        <span className="text-sm text-black text-opacity-60">
                          {defaultContentText}
                        </span>
                      </span>
                    </td>
                  </tr>
                )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
