import { HttpStatusCode } from "axios";
import { useContext, useEffect, useState } from "react";
import { useModal } from "../../../../../contexts/Modal";
import { PopupContext } from "../../../../../contexts/Popup";
import {
  validationFunctions,
  validationResultMessage,
} from "../../../../../libs/validations";
import {
  getSpaceReservation,
  postSpaceReservation,
  postSpaceReservationLongTerm,
} from "../../../../../services/spaceReservationService";
import {
  RESERVATION_SPACE_TYPES,
  RentalDevice,
  getGender,
} from "../../../../../types/space";
import {
  SpaceReservationCreateDto,
  SpaceReservationData,
  convertReservationTypeToLabel,
} from "../../../../../types/spaceReservation";
import { findComboBoxItem } from "../../../../../utils/comboBoxUtils";
import { isFalsyWeekDay } from "../../../../../utils/dateUtils";
import DefaultButton from "../../../../DefaultButton";
import DefaultTextArea from "../../../../DefaultTextArea";
import FormHeader from "../../../../Form/Header";
import FormRow from "../../../../Form/Row";
import FormRowLabel from "../../../../Form/RowLabel";
import DefaultInput from "../../../../Input/DefaultInput";
import DefaultLabel from "../../../../Input/DefaultLabel";
import Spinner from "../../../../Spinner";
import {
  dayOptions,
  getWeekDaysSelectedOption,
  initWeekValue,
} from "../../SpaceList/SelectTimeDays";
import AvailableTime from "../AvailableTime";
import DeviceTotalTable from "../DeviceTotalTable";
import PayTotalTable from "../PayTotalTable";
import { useUserContext } from "../../../../../contexts/User";
import { getLongTermItemList } from "../../../../../libs/spaceReservation";
import useMemberSelector from "../../../../../hooks/useMemberSelector";

const AVAILABLE_PEOPLE_MAX_COUNT = 9999;

type Props = {
  reservationSpaceId: string;
  reservationDate: string;
  onRefresh?: () => void;
};
export default function SpaceReservationCreateForm({
  reservationSpaceId,
  reservationDate,
  onRefresh,
}: Props) {
  const { closePopup } = useContext(PopupContext);
  const { showAlert, showConfirm, handleError } = useModal();
  const { userInfo } = useUserContext();

  const [spaceReservation, setSpaceReservation] = useState<
    Partial<SpaceReservationData>
  >({
    isSelfReservation: true,
    actualUserId: userInfo?.memberId,
    actualUserName: userInfo?.name,
    userTel: userInfo?.mobileNumber,
  });
  const [usePayment, setUsePayment] = useState(false);
  const [longTerm, setLongTerm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [{ member, isSelf, isConfirm }, renderMemberSelector] =
    useMemberSelector();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await getSpaceReservation(
          reservationSpaceId,
          reservationDate
        );

        if (response.status === HttpStatusCode.Ok) {
          let data = response.data;
          if (data.usePayment === false) {
            data = {
              ...data,
              rentalDeviceList: data.rentalDeviceList.map((item) => ({
                ...item,
                price: 0,
              })),
            };
          }

          setSpaceReservation((prev) => ({
            ...prev,
            ...data,
          }));
          setUsePayment(data.usePayment);
        } else {
          throw new Error("Failed to reservation data load");
        }
      } catch (err: any) {
        handleError(err, "조회");
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const postData = async () => {
    try {
      const response = longTerm
        ? await postSpaceReservationLongTerm(createLongTermData())
        : await postSpaceReservation(createData());
      if (response.status === HttpStatusCode.Ok) {
        showAlert("예약 되었습니다.");
        onRefresh?.();
        closePopup();
      } else {
        throw new Error("Failed to post data");
      }
    } catch (err: any) {
      handleError(err, "예약");
    }
  };

  const createData = () => {
    const data: Partial<SpaceReservationCreateDto> = {
      reservationDate,
      actualUserId: member.memberId,
      actualUserName: member.name,
      userTel: member.mobileNumber,
      isSelfReservation: isSelf,
      reservationSpaceId: spaceReservation.reservationSpaceId,
      reservationTimeStart: spaceReservation.reservationTimeStart,
      reservationTimeEnd: spaceReservation.reservationTimeEnd,
      beforeCleanTime: spaceReservation.beforeCleanTime,
      afterCleanTime: spaceReservation.afterCleanTime,
      purpose: spaceReservation.purpose,
      userCount: spaceReservation.userCount,
      totalSpaceDefaultPrice: spaceReservation.totalSpaceDefaultPrice,
      totalDeviceDefaultPrice: spaceReservation.totalDeviceDefaultPrice,
      vatDefaultPrice: spaceReservation.vatDefaultPrice,
      usedDepartment: spaceReservation.usedDepartment,
      rentalDeviceList: spaceReservation.rentalDeviceList
        ? spaceReservation.rentalDeviceList
            .filter((item) => item.checked === true)
            .map((item) => item.rentalDeviceId)
            .filter((id): id is number => id !== undefined)
        : [],
    };

    return data;
  };

  const createLongTermData = () => {
    const data: Partial<SpaceReservationCreateDto> = {
      actualUserId: member.memberId,
      actualUserName: member.name,
      userTel: member.mobileNumber,
      isSelfReservation: isSelf,
      reservationSpaceId: spaceReservation.reservationSpaceId,
      purpose: spaceReservation.purpose,
      userCount: spaceReservation.userCount,
      longTermReservationDateStart:
        spaceReservation.longTermReservationDateStart,
      longTermReservationDateEnd: spaceReservation.longTermReservationDateEnd,
      reservationTimeStart: spaceReservation.reservationTimeStart,
      reservationTimeEnd: spaceReservation.reservationTimeEnd,
      beforeCleanTime: spaceReservation.beforeCleanTime,
      afterCleanTime: spaceReservation.afterCleanTime,
      totalSpaceDefaultPrice: spaceReservation.totalSpaceDefaultPrice,
      totalDeviceDefaultPrice: spaceReservation.totalDeviceDefaultPrice,
      vatDefaultPrice: spaceReservation.vatDefaultPrice,
      usedDepartment: spaceReservation.usedDepartment,
      longTermReservationDayOfWeek:
        spaceReservation.longTermReservationDayOfWeek,
      rentalDeviceList: spaceReservation.rentalDeviceList
        ? spaceReservation.rentalDeviceList.flatMap((item) =>
            item.rentalDeviceId && item.checked ? [item.rentalDeviceId] : []
          )
        : [],
      longTermSpaceReservationItemList: getLongTermItemList(spaceReservation),
    };

    return data;
  };

  const handlePost = () => {
    if (isConfirm === false) {
      showAlert("회원 정보를 조회해주세요.");
      return;
    }

    const valid = [
      ...(!spaceReservation.isSelfReservation
        ? [
            validationFunctions.required(
              spaceReservation.actualUserName,
              "실사용자 이름"
            ),
            validationFunctions.required(
              spaceReservation.userTel,
              "실사용자 연락처"
            ),
          ]
        : []),
      validationFunctions.required(
        spaceReservation.reservationTimeStart,
        "예약시간"
      ),
      validationFunctions.required(
        spaceReservation.reservationTimeEnd,
        "예약시간"
      ),
      ...(longTerm
        ? [
            validationFunctions.required(
              spaceReservation.longTermReservationDateStart,
              "장기예약 시작날짜"
            ),
            validationFunctions.required(
              spaceReservation.longTermReservationDateEnd,
              "장기예약 종료날짜"
            ),
            ...(isFalsyWeekDay(spaceReservation.longTermReservationDayOfWeek)
              ? [validationFunctions.required("", "장기예약 요일선택")]
              : []),
          ]
        : []),
      validationFunctions.required(spaceReservation.userCount, "참여 인원"),
      validationFunctions.numberRange(
        spaceReservation.availablePeopleCountStart ?? 0,
        spaceReservation.availablePeopleCountEnd ?? AVAILABLE_PEOPLE_MAX_COUNT
      )(spaceReservation.userCount, "참여 인원"),
    ];

    if (usePayment) {
      valid.push(
        validationFunctions.required(spaceReservation.usedDepartment, "부서"),
        validationFunctions.required(spaceReservation.purpose, "이용 목적"),
        validationFunctions.numberRange(2, 100)(
          spaceReservation.purpose?.length ?? 0,
          "이용 목적"
        )
      );
    }

    const errorMessage = validationResultMessage(valid);
    if (errorMessage) {
      showAlert(errorMessage);
    } else {
      showConfirm("예약 하시겠습니까?", postData);
    }
  };

  const handleReservationChangeObject = (
    data: Partial<SpaceReservationData>
  ) => {
    setSpaceReservation((prev) => ({ ...prev, ...data }));
  };

  const handleDeviceChange = (list: RentalDevice[]) => {
    handleReservationChangeObject({ rentalDeviceList: list });
  };

  const renderAvailableTime = () => {
    let startDay = "";
    let endDay = "";

    if (spaceReservation?.availableDays && spaceReservation?.defaultPriceDays) {
      startDay =
        findComboBoxItem(
          dayOptions,
          getWeekDaysSelectedOption(spaceReservation.availableDays)
        )?.label ?? "";
      endDay =
        findComboBoxItem(
          dayOptions,
          getWeekDaysSelectedOption(spaceReservation.defaultPriceDays)
        )?.label ?? "";
    }

    const showerRoomCleanTimeText = `/ 이용 제한 시간 ${spaceReservation.blockTimeStart}~${spaceReservation.blockTimeEnd}`;

    return (
      <DefaultLabel
        label="운영시간"
        text={`${startDay} ${spaceReservation?.availableTimeStart ?? ""}~${
          spaceReservation?.availableTimeEnd ?? ""
        } 
        / 정가구간 ${endDay} ${spaceReservation?.defaultPriceTimeStart ?? ""}~${
          spaceReservation?.defaultPriceTimeEnd ?? ""
        }
        ${spaceReservation.useBlockTime ? showerRoomCleanTimeText : ""}`}
      />
    );
  };

  return (
    <div className="flex flex-col gap-5">
      <div className="min-w-[900px] max-h-[700px] overflow-y-auto">
        {isLoading && <Spinner />}
        <FormHeader title="예약 공간 정보" />
        <FormRow>
          <FormRowLabel title="위치">
            <DefaultLabel
              text={`${spaceReservation?.buildingName ?? ""} ${
                spaceReservation?.buildingFloorName ?? ""
              }`}
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="공간 정보">
            <div className="flex flex-col gap-2">
              <DefaultLabel
                label="공간명"
                text={spaceReservation?.reservationSpaceName}
              />
              <DefaultLabel
                label="공간 분류"
                testId={"space-type"}
                text={convertReservationTypeToLabel(
                  spaceReservation?.reservationSpaceType ??
                    RESERVATION_SPACE_TYPES.DEFAULT
                )}
              />
              {renderAvailableTime()}
              <DefaultLabel
                label="이용가능 성별"
                text={
                  spaceReservation?.availableGender &&
                  getGender(spaceReservation.availableGender)
                }
              />
              <DefaultLabel
                label="이용자 제한"
                text={`${spaceReservation?.availablePeopleCountStart ?? ""}~${
                  spaceReservation?.availablePeopleCountEnd ?? ""
                }명`}
              />
              <DefaultLabel
                label="이용 시간"
                text={`기본 ${
                  spaceReservation?.usableTimeStart ?? ""
                }분 / 최대 ${spaceReservation?.usableTimeEnd ?? ""}분(일일 ${
                  spaceReservation?.dailyLimit ?? ""
                }회 예약 제한)`}
              />
            </div>
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="공간 상세 정보">
            <div className="flex flex-col gap-2">
              <DefaultLabel
                label="담당자 정보"
                text={`${spaceReservation?.contactInfo ?? ""} / ${
                  spaceReservation?.contactNumber ?? ""
                }`}
              />
              <DefaultLabel
                label="공간 특징"
                text={spaceReservation?.reservationSpaceFeatures}
              />
              <DefaultLabel
                label="비고"
                text={spaceReservation?.reservationSpaceNote}
              />
            </div>
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="요금 정보">
            <div className="flex flex-col gap-2">
              <DefaultLabel
                label="요금 정책"
                text={spaceReservation?.usePayment ? "유료" : "무료"}
              />
              {spaceReservation?.usePayment && (
                <DefaultLabel
                  label="이용 요금"
                  text={`기본 ${
                    spaceReservation?.defaultPrice?.toLocaleString() ?? ""
                  }원 / 추가 ${
                    spaceReservation?.additionalPrice?.toLocaleString() ?? ""
                  }원`}
                />
              )}
            </div>
          </FormRowLabel>
        </FormRow>

        <FormHeader title="신청 내역" />
        <FormRow>
          <FormRowLabel title="신청자 정보">
            <DefaultLabel text={userInfo?.name} />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="실사용자 정보" isRequired>
            {renderMemberSelector}
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="이용 시간" isRequired>
            <AvailableTime
              longTerm={longTerm}
              onLongTermChange={setLongTerm}
              reservationDate={reservationDate}
              reservationInfo={spaceReservation}
              reservationStatus={spaceReservation.reservationStatus}
              longTermReservationDateStart={
                spaceReservation.longTermReservationDateStart ?? ""
              }
              longTermReservationDateEnd={
                spaceReservation.longTermReservationDateEnd ?? ""
              }
              longTermReservationDayOfWeek={
                spaceReservation.longTermReservationDayOfWeek ?? initWeekValue
              }
              enabledWeekDay={spaceReservation.availableDays}
              onChange={handleReservationChangeObject}
              hideLongTerm={
                spaceReservation.reservationSpaceType ===
                RESERVATION_SPACE_TYPES.SHOWER
              }
            />
          </FormRowLabel>
        </FormRow>
        <FormRow>
          <FormRowLabel title="이용 정보" isRequired={usePayment}>
            <div className="flex flex-col gap-2">
              <div className="flex gap-2">
                <DefaultInput
                  label="부서"
                  value={spaceReservation.usedDepartment ?? ""}
                  onChange={(value: string) =>
                    handleReservationChangeObject({ usedDepartment: value })
                  }
                  placeholder="실사용자 부서 정보 입력"
                />
                <DefaultInput
                  label="참여 인원"
                  type="number"
                  unitLabel="명"
                  value={spaceReservation.userCount}
                  onChange={(value: string) =>
                    handleReservationChangeObject({ userCount: value })
                  }
                  placeholder="0"
                  min={0}
                />
              </div>
              <div className="flex">
                <DefaultTextArea
                  label="이용 목적"
                  value={spaceReservation.purpose ?? ""}
                  onChange={(value: string) =>
                    handleReservationChangeObject({ purpose: value })
                  }
                  placeholder="이용목적을 2자 이상 입력하세요."
                  maxLength={100}
                  cols={50}
                />
              </div>
            </div>
          </FormRowLabel>
        </FormRow>

        <FormRow>
          <FormRowLabel title="기기 이용">
            <DeviceTotalTable
              rentalDeviceList={spaceReservation.rentalDeviceList ?? []}
              reservationTimeStart={spaceReservation.reservationTimeStart}
              reservationTimeEnd={spaceReservation.reservationTimeEnd}
              usePayment={spaceReservation?.usePayment}
              onDeviceChange={handleDeviceChange}
            />
          </FormRowLabel>
        </FormRow>

        {spaceReservation.usePayment && (
          <FormRow>
            <FormRowLabel title="결제예정 요금">
              <PayTotalTable
                reservationDate={reservationDate}
                spaceReservationData={spaceReservation}
                onChange={handleReservationChangeObject}
              />
            </FormRowLabel>
          </FormRow>
        )}
      </div>

      <div className="flex gap-2 justify-center">
        <DefaultButton onClick={closePopup}>닫기</DefaultButton>
        <DefaultButton color="primary" onClick={handlePost}>
          예약
        </DefaultButton>
      </div>
    </div>
  );
}
