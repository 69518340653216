import { useContext } from "react";
import { PopupContext } from "../../../../../../contexts/Popup";
import DefaultButton from "../../../../../DefaultButton";
import { TagType } from "../../../../../../types/tag";
import ToiletTagCreateForm from "../CreateForm";

type Props = { tagType: TagType[] };

export default function ToiletTagListTopRight({ tagType }: Props) {
  const { openPopup } = useContext(PopupContext);

  return (
    <>
      <DefaultButton
        size="tableTop"
        color="default"
        onClick={() =>
          openPopup({
            header: "태그 등록",
            content: <ToiletTagCreateForm tagType={tagType} />,
          })
        }
      >
        태그 등록
      </DefaultButton>
    </>
  );
}
